import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Login from './Components/Auth/Login';
import Testing from './Components/Testing';
import LandingPage from './Components/LandingPage/Landing';
import Extra from './Components/LandingPage/Extra';
import { ErrorBoundary } from "react-error-boundary";
import errorPageCrossIcon from '../src/assets/errorPageCrossIcon.png';
import { BASE_URL, mixPanelInIt, mixPanelTrack, post } from './Components/Services/Calls';
import ResetPassword from './Components/Auth/ResetPassword';
import MainPage from './Components/Home/MainPage';
import SignUp from './Components/Auth/SignUp';
import ForgotPassword from './Components/Auth/ForgotPassword';
import Verification from './Components/Auth/Verification';
import InviteLink from './Components/Home/InviteLink';




import NotInterested from './Components/Home/NotInterested';
import HelpPage from './Components/LandingPage/HelpPage';
import HelpSupport from './Components/LandingPage/HelpSupport';

// import '@firebase/polyfill';
function App() {

  const [mobileView, setMobileView]: any = useState<boolean>(
    window.innerWidth <= 768
  );

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768)
        setMobileView(true);
      else
        setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  useEffect(() => {
    mixPanelInIt()
    var firstTimeSiteOpen = localStorage.getItem("firstTimeSiteOpen");
      if (firstTimeSiteOpen == null) {
        console.log('data456')
        mixPanelTrack("firstTimeSiteOpen",{"firstTimeOpen":"firstTimeOpen"})
        localStorage.setItem("firstTimeSiteOpen", "1");
      }
  }, []);


  const [isLightMode, setIsLightMode]: any = useState(1);
  const [query, setQuery] = useState<string | null>("");
  const [resetPasswordStatus,setResetPasswordStatus] = useState("0");

  const [queryForVerification, setQueryForVerification] = useState<string | null>("");
  const [queryForShareCode, setQueryForShareCode] = useState<string | null>("");
  const [verificationStatus,setVerificationStatus] = useState("0");

  const [openAddAccountLogin, setOpenAddAccountLogin] = useState(false)
  const [openAddAccountSignup, setOpenAddAccountSignUp] = useState(false)

  useEffect(() => {
    var tempLightMode = localStorage.getItem("isLightMode");
      if (tempLightMode == null) {
        setIsLightMode(1)
      }
      else if (tempLightMode == "0") {
        setIsLightMode(0)
      }
      else if (tempLightMode == "1") {
        setIsLightMode(1)
      }
    try{
    let queryItem = new URLSearchParams(window.location.search).get("r");
    let queryItem1 = new URLSearchParams(window.location.search).get("v");
    let queryItem2 = new URLSearchParams(window.location.search).get("c");
    let queryItem3 = new URLSearchParams(window.location.search).get("n");
    let queryItem4 = new URLSearchParams(window.location.search).get("sh");
    if(queryItem4 && queryItem4!="" && queryItem4!="0")
    {
      localStorage.setItem("shareCheckData", queryItem4);
    }
    if(queryItem3 && queryItem3!="")
    {
      localStorage.setItem("notificationBackgroundData", queryItem3);
    }
    if(queryItem2 && queryItem2!="")
    {
      //localStorage.setItem("sharecode", queryItem2);
      //setQueryForShareCode(queryItem2)
    }
    if(queryItem1 && queryItem1!="")
    {
    setQueryForVerification(queryItem1);
    verifyRegisterEmail(queryItem1)
    console.log('queryItem1',queryItem1)
    }
    else
    {
      setVerificationStatus("2")
    }

    if(queryItem && queryItem!="")
    {
    setQuery(queryItem);
    ResetPasswordLinkVerify(queryItem)
    console.log('query123456',queryItem)
    }
    else
    {
      setResetPasswordStatus("2")
    }
  }
  catch(ex){
    console.log('location error',ex)
  }
  }, []);


  async function verifyRegisterEmail(code) {
    try {
      const data = {
        code:code,
      };
    //   setLoader(true)
      const response = await post(BASE_URL + '/verifyconfirmemaillink', data)
      console.log('response23',response)
      if(response.data.status == 'success'){
        // setLoader(false)
        setVerificationStatus("1")
      }
      else{
        // setLoader(false)
        setVerificationStatus("2")
      }

    } catch (ex) {
      console.log('response',ex)
    //   setLoader(false)
    setVerificationStatus("2")
    }
  };


  async function ResetPasswordLinkVerify(queryItem) {
    let json = {
      code : queryItem
    }
    try {
      const response = await post(BASE_URL + '/verifylink', json)
      console.log('response456', response)
      if(response.data.status == "success"){
        setResetPasswordStatus("1")
      }
      else{
        setResetPasswordStatus("2")
      }
    } catch (ex) {
      console.log('response9211', ex)
      setResetPasswordStatus("2")
    }
  }




function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert" style={{padding:50,display:'flex',justifyContent:'center',height:'100vh',flexDirection:'column'}}>
      {/* <h1>Error:</h1>
      <div style={{padding:20,margin:'20px 0px',border:'3px solid red',}}>
      <div style={{fontSize:16, color: "red" }}>{error.message}</div>
      <div style={{fontSize:16, color: "red" }}>{resetErrorBoundary}</div>
      </div> */}
      <div className='errorPageCrossIcon' style={{position: 'absolute',top:40,right:40,fontSize:20,cursor:'pointer'}} onClick={()=>window.location.reload()}>
       <img style={{width:15,height:15}} src={errorPageCrossIcon} alt="" />
      </div>
      <div style={{textAlign:'center'}}>
        <h3>We have been notified of the issue and are looking at it.</h3>
      </div>
        <div style={{textAlign:'center',marginTop:20,fontSize:20}}>
          <span style={{color:'var(--bs-link-color)',textDecoration: 'underline',cursor:'pointer'}} onClick={()=>window.location.reload()}>
            Click Here
          </span> &nbsp;
          <span>
            to reload page.
          </span>
        </div>
    </div>
  );
}

const logError = (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
  console.log('error: Error',error)
  console.log('info',info.componentStack)
  handleErrorCall(error,info.componentStack)
};

const handleErrorCall = async (error,info) => {
  let user = JSON.parse(localStorage.getItem('user') || '{}')

  try {
    const data = {
      title: error.message,
      desc: info,
      errorStackTrace: info,
      userid: user && user.id?user.id:"0",
    };
    console.log("data123456", data);
    const response = await post(BASE_URL + '/erroruser', data)
    console.log('response23',response)
    if(response.data.status == 'success'){
    }
    else{
    }

  } catch (ex) {
    console.log('response',ex)
  }
};


  const checkAuthLanding = (component: any) => {
    if (!localStorage.getItem("user") || !localStorage.getItem("token")) {
      return <Navigate to="/"/>;
    } 
    return component;
  };
  const checkAuthLogin = (component: any) => {
    if (!localStorage.getItem("user") || !localStorage.getItem("token")) {
      return component;
     // return <Navigate to="/"/>;
    } 
    return <Navigate to="/webapp"/>;
  };

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={logError}>
<BrowserRouter>
      <Routes>
        
{query != '' && query != null ?
      <Route path="/" element={<ResetPassword resetPasswordStatus={resetPasswordStatus} code={query} />}/>
      :
      <>
      {queryForVerification != '' && queryForVerification != null ?
      <Route path="/" element={<Verification verificationStatus={verificationStatus} />}/>
      :
      queryForShareCode != '' && queryForShareCode != null && !localStorage.getItem("token") ?
      <Route path="/" element={<Login openAddAccountLogin={openAddAccountLogin} 
                                      setOpenAddAccountLogin={setOpenAddAccountLogin} 
                                      openAddAccountSignup={openAddAccountSignup} 
                                      setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                                      mobileView={mobileView}
                                      />}/>
      :
      <>
      {resetPasswordStatus && resetPasswordStatus == '2'  ?  <Route path="/" element={checkAuthLogin(<MainPage />)} /> : null}
      </>
  }
      </>
    }





        {/* <Route path="/" element={checkAuthLogin(<MainPage />)} /> */}
        <Route path="/login" 
               element={
                 checkAuthLogin(
                 <Login openAddAccountLogin={openAddAccountLogin} 
                        setOpenAddAccountLogin={setOpenAddAccountLogin} 
                        openAddAccountSignup={openAddAccountSignup} 
                        setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                        mobileView={mobileView}  />
                 )} />
        {/* <Route path="/addAccount/login" element={<Login />} /> */}
        {/* <Route path="/addAccount/signup" element={<SignUp />} /> */}
        <Route path="/signup" 
               element={
                        checkAuthLogin(
                        <SignUp openAddAccountLogin={openAddAccountLogin} 
                                setOpenAddAccountLogin={setOpenAddAccountLogin} 
                                openAddAccountSignup={openAddAccountSignup} 
                                setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                                mobileView={mobileView}  />
                        )} />
        <Route path="/login/requestRestPassword" element={checkAuthLogin(<ForgotPassword  mobileView={mobileView}/>)} />
        <Route path="/webapp" 
               element={
                 checkAuthLanding(
                 <LandingPage openAddAccountLogin={openAddAccountLogin} 
                              setOpenAddAccountLogin={setOpenAddAccountLogin} 
                              openAddAccountSignup={openAddAccountSignup} 
                              setOpenAddAccountSignUp={setOpenAddAccountSignUp} isLightMode={isLightMode} setIsLightMode={setIsLightMode} />)} />
        {/* <Route path="/help" element={ checkAuthLanding(<HelpPage />)} /> */}
        <Route path="/help" element={ checkAuthLanding(<HelpSupport />)} />
        <Route path="/invite" element={<InviteLink />} />
        <Route path="/notInterested" element={<NotInterested />} />

        {/* <Route path="/resetPassword" element={<ResetPassword resetPasswordStatus={resetPasswordStatus} code={query}  />}/> */}
        {/* <Route path="/verifyEmail" element={<Verification verificationStatus={verificationStatus} />}/> */}


        <Route path="/Testing" element={<Testing />} />
        <Route path="/Extra" element={<Extra />} />
      </Routes>
    </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
