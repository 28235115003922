import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import './HomePage.css'
import { useNavigate } from 'react-router-dom';
import styles from '../Navbar/NavStyles'
import Sort from '../../assets/sort.png'
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { nanoid } from "nanoid";
import { getAnalytics } from "firebase/analytics";
import SideTaskBar from './SideTaskBar'
import Loading from '../../assets/loading.png'
import Whiteloading from '../../assets/whiteloading.png'
import Restore from '../../assets/restore.png'
import RestoreArrow from '../../assets/restoreArrow.png'
import SnackBarUndoIcon from '../../assets/snackBarUndo.png'
import AddTaskIcon from '../../assets/addTaskIcon.png'
import AddProjectIcon from '../../assets/addProjectIcon.png'
import DontAllowCheck from '../../assets/dontAllowCheck.png'
import BlueArrowIcon from '../../assets/blueArrowIcon.png'
import WhiteArrowIcon from '../../assets/whiteArrowIcon.png'
import BluePlusIcon from '../../assets/bluePlusIcon.png'
import LogOut from '../../assets/logOut.png'
import LogOutCurrentUserIcon from '../../assets/logOutCurrentUserIcon.png'
import RefreshGreyIcon from '../../assets/RefreshGreyIcon.png'
import GreyThreedots from '../../assets/greyThreedots.png'
// import {PullDownContent, ReleaseContent, RefreshContent, PullToRefresh} from "react-js-pull-to-refresh";
import PullToRefresh from 'react-simple-pull-to-refresh';
import Setting from '../../assets/setting.png'
import AddFlieIcon from '../../assets/paper-clip.png'
import DuplicateIcon from '../../assets/duplicate.png'
import ManageAccountIcon from '../../assets/manage-account.png'
import AddSwitchAccountIcon from '../../assets/addSwitchAccountIcon.png'
import ShareInvitationIcon from '../../assets/shareInvitationIcon.png'
import NotificationItemIcon from '../../assets/notificationItemIcon.png'
import RightBlackArrow from '../../assets/rightBlackArrow.png'
import AddTaskBarIcon from '../../assets/addTaskBarIcon.png'
import MenuMiddlePane from '../../assets/menuMiddlePane.png'
import Rightpaneback from '../../assets/rightpaneback.png'
import AvatarMiddlePane from '../../assets/avatarMiddlePane.png'
import RightWhiteArrow from '../../assets/rightWhiteArrow.png'
import Rename from '../../assets/rename.png'
import RedDelete from '../../assets/redDelete.png'
import ExitShareIcon from '../../assets/exitShareIcon.png'
import Delete from '../../assets/delete.png'
import Bell from '../../assets/bell.png'
import BellGreyIcon from '../../assets/bell1234.png'
import UnSelect from '../../assets/dry-clean.png'
import MobileCircle from '../../assets/mobileCircle.png'
import Select from '../../assets/check-mark.png'
import CheckCircleFill from '../../assets/check-circle-fill.png'
import TurnIntoHeading from '../../assets/turnIntoHeading.png'
import TurnIntoTask from '../../assets/turnIntoTask.png'
import AddGroupIcon from '../../assets/addGroupIcon.png'
import AddGroupIcon11 from '../../assets/addGroupIcon11.png'
import Remove from '../../assets/exit.png'
import GroupIconDark from '../../assets/groupIconDark.png'
import GroupIconDarkOpen from '../../assets/groupIconDarkOpen.png'
import GroupIcon from '../../assets/groupIcon.png'
import GroupIconOpen from '../../assets/groupIconOpen.png'
import RefreshIcon from '../../assets/refreshIcon.png'
import ShareIcon from '../../assets/shareIcon.png'
import StickyNote from '../../assets/sticky-notes.png'
import StickyNoteWhite from '../../assets/sticky-notes-white.png'
import UniqueIdIcon from '../../assets/uniqueId.png'
import Dot from '../../assets/dot.png'
import ThreeDots from '../../assets/three-dots.png'
import More from '../../assets/more.png'
import ConvertNote from '../../assets/convertNote.png'
import TaskNoteIcon from '../../assets/taskNote.png'
import SwitchCheckIcon from '../../assets/switchCheckIcon.png'
import CompletedArrowDown from '../../assets/completedArrowDown.png'
import LeftArrowWhiteIcon from '../../assets/leftArrowWhiteIcon.png'
import BlueBackArrow from '../../assets/blueBackArrow.png'
import ShareAddPersonWhiteIcon from '../../assets/shareAddPersonWhiteIcon.png'
import CrossIconImage from '../../assets/whiteCrossIconImage.png'
import RedDot from '../../assets/red-dot.png'
import ManageStuffLogo from '../../assets/manageStuffLogo.png'
import ShareQuestionIcon from '../../assets/shareQuestion.png'
import ReadOnly from '../../assets/read-only.png'

import { Workbox } from 'workbox-window';

import SharePeople1 from '../../assets/sharePeople1.png'
import ShareAddIcon from '../../assets/shareAddIcon.png'
import WhiteDots from '../../assets/WhiteDots.png'
import SharedListShowIcon from '../../assets/sharedListShowIcon.png'
import ListIcon from '../../assets/listIcon.png'
import listiconDark from '../../assets/listiconDark.png'
import AddCircleIcon from '../../assets/addCircleIcon.png'
import NewListDarkIcon from '../../assets/new_list_dark .png'
import ListIconActive from '../../assets/listIconActive.png'
import completedArrowIcon from '../../assets/completedIcon.png'
import TaskCommentIcon from '../../assets/taskCommentIcon.png'
import TaskCommentWhiteIcon from '../../assets/taskCommentWhiteIcon.png'
import BugIcon from '../../assets/bug.png'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Linkify from 'react-linkify'
import { LinkItUrl } from 'react-linkify-it';
import { BASE_URL, decData, encData, mixPanelTrack, post, postUser } from '../Services/Calls'
import Magnifier from '../../assets/magnifier.png'
import MagnifierWhite from '../../assets/magnifierWhite.png'
import NewSearchIcon from '../../assets/newSearchIcon.png'
import MarkerIcon from '../../assets/edit-Heading.png' /* 03/05 */
import SwitchLoader from '../../assets/authLoader.png' /* 03/05 */
import useContextMenu from './UseContextMenu'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import WhiteTrashIcon from '../../assets/whiteTrashIcon.png'
import NotificationReadIcon from '../../assets/notificationReadIcon.png'
import ShareDialogDivBgImg from '../../assets/shareDialogDivBgImg.png'
import EditHeadingIcon from '../../assets/edit-Heading.png'
import DrakLightModeIcon from '../../assets/brightness-and-contrast.png'
import DrakLightModeWhiteIcon from '../../assets/white-brightness-and-contrast.png'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
// import Paper from '@mui/material/Paper'
// import Popper from '@mui/material/Popper'
// import MenuItem from '@mui/material/MenuItem'
// import MenuList from '@mui/material/MenuList'
import Tooltip from '@mui/material/Tooltip';
import ReactSwitch from 'react-switch'
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import { Menu, MenuItem, Typography } from '@material-ui/core'
//208.109.65.169
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper, { PopperProps } from "@material-ui/core/Popper";
import { createStyles, makeStyles } from "@material-ui/core";


import CommentDeleteCrossIcon from '../../assets/commentDeleteCrossIcon.png'
import SwipeToDelete from 'react-swipe-to-delete-ios'


import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material'
import { NestedMenuItem as MuiNestedMenuItem } from 'mui-nested-menu'
import Dialog from '@mui/material/Dialog'

// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide, { SlideProps } from '@mui/material/Slide';
// import Grow, { GrowProps } from '@mui/material/Grow';
import { TransitionProps } from '@mui/material/transitions';


import { Base64 } from 'js-base64'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import PrinterIcon from '../../assets/printer.png'
import AddTaskNotification from '../../assets/addTaskNotification.png'
import helpIcon from '../../assets/help.png'
import AddAccount from './AddAccount';
import ManageAccount from './ManageAccount';
import AddListDialog from './AddListDialog';
import { COLOR, COLORDARK } from '../Services/constants/Colors';
import RenameListMobile from './RenameListMobile';
import IntroScreen from './IntroScreen';
import zIndex from '@material-ui/core/styles/zIndex';
import { spawn } from 'child_process';
import HelpSupport from './HelpSupport';
import AIDialogNew from './AIDialogNew';
import AIDialog from './AIDialog';
const firebaseConfig = {
  apiKey: "AIzaSyDodeKcZHRE3Ik8By_Ne1OkgwdrT1kzkOc",
  authDomain: "managestuffai.firebaseapp.com",
  projectId: "managestuffai",
  storageBucket: "managestuffai.appspot.com",
  messagingSenderId: "1008817288677",
  appId: "1:1008817288677:web:8201d68c505a09fe636b0c",
  measurementId: "G-Q1SZ78K06K"
};
/* const firebaseConfig = {
  apiKey: "AIzaSyDCwbpUtqEqzrZioPHwo9_t5QaWBNVMOHg",
  authDomain: "managestuff-d5ac2.firebaseapp.com",
  projectId: "managestuff-d5ac2",
  storageBucket: "managestuff-d5ac2.appspot.com",
  messagingSenderId: "731858657795",
  appId: "1:731858657795:web:492c35dcff255026e32436",
  measurementId: "G-WLLJ8XY7XD"
}; */
// Initialize Firebase
// const firebaseApp: any = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
// const messaging = getMessaging(firebaseApp);

interface IProps {
  openAddAccountLogin: any
  setOpenAddAccountLogin: any
  openAddAccountSignup: any
  setOpenAddAccountSignUp: any
  isLightMode: any
  setIsLightMode: any
}

function LandingPage({
  openAddAccountLogin,
  setOpenAddAccountLogin,
  openAddAccountSignup,
  setOpenAddAccountSignUp,
  isLightMode,
  setIsLightMode,
}: IProps) {
  const navigate = useNavigate();
  const {
    clicked,
    setClicked,
    points,
    setPoints,
    projectClicked,
    setProjectClicked,
    listClicked,
    setListClicked
  } = useContextMenu()

  const [taskMenuListpoints, setTaskMenuListpoints] = useState({
    x: 0,
    y: 0
  })
  /* addEventListener("focus", (event) => {
    console.log("focused")
    let user = JSON.parse(localStorage.getItem('notificationData') || '{}')
    console.log("focused45", user)
  }); */
  // onMessage(messaging, function (payload: any) {
  //   console.log("payload", payload.data.notification)
  //   const payload1 = JSON.parse(payload.data.notification);
  //   const notificationTitle = payload1.title;
  //   const notificationOptions = {
  //     body: payload1.body,
  //     customParam: payload1.customParam
  //   };
  //   console.log(notificationTitle, notificationOptions)
  //   let user = JSON.parse(localStorage.getItem('user') || '{}')
  //   console.log("userid456", user.id)
  //   getNotifications(user)
  //   getTask(user.id)
  //   //getProjectWithGroup(user.id, 0)
  //   if (!("Notification" in window)) {
  //     console.log("This browser does not support system notifications.");
  //   } else if (Notification.permission === "granted") {
  //     // If it's okay let's create a notification
  //     var notification = new Notification(notificationTitle, notificationOptions);
  //     const url = 'http://localhost:3000/webapp'
  //     notification.onclick = function (event: any) {
  //       event.preventDefault();
  //       console.log("event45", payload1.customParam)
  //       setOpenAllNotificationDialog(true)
  //       let clients: any;
  //       window.focus();
  //       //window.open(payload.notification.click_action , '_blank');
  //       notification.close();
  //     }
  //   }
  // });

  const [taskLeftClicked, setTaskLeftClicked] = useState(false)
  const [taskCompletedLeftClicked, setTaskCompletedLeftClicked] =
    useState(false)

  const [taskSectionOpen, setTaskSectionOpen] = useState(false)
  const [taskIndex, setTaskIndex] = useState()

  const [taskItem, setTaskItem]: any = useState({})
  const taskItemRef: any = useRef({})
  const [newTopPoints, setNewTopPoints]: any = useState(0)


  const [taskDrop, setTaskDrop] = useState(false)
  const [taskInputFocus, setTaskInputFocus] = useState(false)
  const [selectStatus, setSelectStatus] = useState(false)
  const [completeTaskShow, setCompleteTaskShow] = useState(false)
  const [settingDivShow, setSettingDivShow] = useState(false)
  const [addProject, setAddProject] = useState(false)
  const [addList, setAddList] = useState(false)
  const [aiDialogText, setAIDialogText] = useState(false)
  const [aiDialog, setAIDialog] = useState(false)
  const [isSortAlpha, setIsSortAlpha] = useState(true)
  const [isSortAllShareUser, setIsSortAllShareUser] = useState(true)
  const [addGroupList, setAddGroupList] = useState(false) /* 09/05 */
  const [moveTaskDivShow, setMoveTaskDivShow] = useState(false) /* 09/05 */
  const [taskInputValue, setTaskInputValue] = useState('')
  const [shareListInputValue, setShareListInputValue] = useState('')
  const [projectsInputValue, setProjectsInputValue] = useState('')
  const [projectsNewListInputValue, setProjectsNewListInputValue] = useState('')
  const [groupInputValue, setGroupInputValue] = useState('')
  const [searchBarInputValue, setSearchBarInputValue] = useState('')
  const [countNum, setCountNum] = useState(0)
  const [taskUserActivity, setTaskUserActivity]: any = useState([])
  const [userId, setUserId] = useState('')
  const [selectedProjectID, setSelectedProjectID] = useState('')
  const [selectedProjectGruopID, setSelectedProjectGruopID] = useState('')
  const [selectedProjectName, setSelectedProjectName] = useState('')
  const [userData, setUserData]: any = useState({})
  const [switchUserData, setSwitchUserData]: any = useState([])
  const [notInterestedData, setNotInterestedData]: any = useState([])
  const [firstName, setFirstName]: any = useState()
  const [lastCommentDivShow, setLastCommentDivShow] = React.useState(false)
  const [userListDivShow, setUserListDivShow] = React.useState(false)
  const commentDivRef: any = useRef(null)
  const [lastName, setLastName]: any = useState()
  const [email, setEmail]: any = useState()

  const [nameLetters, setNameLetters]: any = useState()
  const [dataItem, setDataItem]: any = useState({})
  const [dataTrashItem, setDataTrashItem]: any = useState({})
  const [restoreTrashItemList, setRestoreTrashItemList]: any = useState({})
  const [listItem, setListItem]: any = useState({})
  const [groupItem, setGroupItem]: any = useState({})
  const [dataItemIndex, setDataItemIndex]: any = useState()
  const [projectData, setProjectData]: any = useState([])
  const [sharedListData, setSharedListData]: any = useState([])
  const [sharedUserList, setSharedUserList]: any = useState([])
  const [isShareApiDone, setIsShareApiDone]: any = useState(false)
  const [allUserShareData, setAllUserShareData]: any = useState([])
  const [allOtherUserShareData, setAllOtherUserShareData]: any = useState([])
  const [allInvitation, setAllInvitation]: any = useState([]);
  const groupDataRef: any = useRef([]);
  const projectDataRef: any = useRef([])
  const [allOtherInvitation, setAllOtherInvitation]: any = useState([])
  const [groupData, setGroupData]: any = useState([])
  const [taskData, setTaskData]: any = useState([])
  const [trashData, setTrashData]: any = useState([])
  const [allUser, setAllUser]: any = useState([])
  const [allUserFilter, setAllUserFilter]: any = useState([])
  const [allShareUser, setAllShareUser]: any = useState([])
  const [combineAllShareUser, setCombineAllShareUser]: any = useState([])
  const [taskMainData, setTaskMainData]: any = useState([])
  const [editGroup, setEditGroup]: any = useState({})
  const [editList, setEditList]: any = useState({})
  const [editSelectedList, setEditSelectedList]: any = useState(false)
  const [completedMenu, setCompletedMenu]: any = useState(false)
  const [colorPalette, setColorPalette]: any = useState(false) /* 03/05 */
  const [currentDate, setCurrentDate]: any = useState('') /* 03/05 */
  const [highlightFrom, setHighlightFrom]: any = useState('')
  const [windowWidth, setWindowWidth]: any = useState('')
  const [windowHeight, setWindowHeight]: any = useState('')
  const [projectListInput, setProjectListInput]: any = useState('')
  const [multiSnackbarMsg, setMultiSnackbarMsg]: any = useState('')
  // const [filteredResults, setFilteredResults] = useState([]);
  const [inviteLink, setInviteLink]: any = useState(false)
  const [duplicateListLoader, setDuplicateListLoader] = useState(false)
  const [duplicateGroupListLoader, setDuplicateGroupListLoader] = useState(false)
  const filteredResults: any = useRef([])
  const [selectedFolderItem, setSelectedFolderItem]: any = useState({});
  const [isTaskApiDone, setIsTaskApiDone]: any = useState(false);
  const [showIntroScreen, setShowIntroScreen]: any = useState(false);

  const [mobileView, setMobileView]: any = useState<boolean>(
    window.innerWidth <= 768
  );

  const [isDark, setIsDark]: any = useState(mobileView ? 1 : 0);

  const colorSchemes = isLightMode == 1 ? COLOR : COLORDARK;

  const [drawerMoveTaskTo, setDrawerMoveTaskTo]: any = useState<boolean>(false);
  const [renameListForMobile, setRenameListForMobile]: any = useState<boolean>(false);
  const [shareConfirmPopUpMobileDialog, setShareConfirmPopUpMobileDialog]: any = useState<boolean>(false);
  const [renameListInputValue, setRenameListInputValue] = useState('')
  const [renameListType, setRenameListType] = useState('')
  const [drawerMoveTaskToGroupList, setDrawerMoveTaskToGroupList]: any = useState<boolean>(false);

  const [hideShowLeftPane, setHideShowLeftPane] = useState<boolean>(false);
  const [hideShowMiddlePane, setHideShowMiddlePane] = useState<boolean>(false);
  const [hideShowRightPane, setHideShowRightPane] = useState<boolean>(false);
  const slideType = useRef("")
  const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
  const [refreshFocusLoader, setRefreshFocusLoader] = useState<boolean>(false);
  const [refreshLoader, setRefreshLoader] = useState<boolean>(false);
  const [refreshLoaderMiddle, setRefreshLoaderMiddle] = useState<boolean>(false);
  const [refreshLoaderRight, setRefreshLoaderRight] = useState<boolean>(false);
  const [switchLoader, setSwitchLoader] = useState<boolean>(false);
  const [isDragStart, setIsDragStart] = useState<boolean>(false);

  const [moveTaskListShow, setMoveTaskListShow] = useState<boolean>(false);
  const [moveGroupListShow, setMoveGroupListShow] = useState<boolean>(false);
  const [helpSupportPage, setHelpSupportPage] = useState<boolean>(false);

  const [floderOpenStatus, setFloderOpenStatus] = useState<boolean>(true);


  const [dataItemDateTime, setDataItemDateTime]: any = useState({});


  /* 03/05 */
  const [colors, setColors] = useState([
    { id: 1, colorCode: '#FF6900' },
    { id: 2, colorCode: '#FCB900' },
    { id: 3, colorCode: '#7BDCB5' },
    { id: 4, colorCode: '#00D084' },
    { id: 5, colorCode: '#8ED1FC' },
    { id: 6, colorCode: '#0693E3' },
    { id: 7, colorCode: '#ABB8C3' },
    { id: 8, colorCode: '#EB144C' },
    { id: 9, colorCode: '#F78DA7' },
    { id: 10, colorCode: '#9900EF' },
    { id: 11, colorCode: '#FFFF00' }
  ])
  /* 03/05 */
  const checked = useRef(false)
  const sideBarTaskNameInput: any = useRef()
  const sideBarTaskNoteInputRef: any = useRef()
  const TaskNameInputRef: any = useRef()
  const TitleHeadTaskNameInput: any = useRef()
  const listInputRef: any = useRef()
  const dataItemRef: any = useRef({})
  const subTaskInputRef: any = useRef(null)
  const searchBarRef: any = useRef(null)
  const listItemRef: any = useRef({})
  const timerRef: any = useRef(0);
  const preventRef: any = useRef(false);

  const [taskStepInputValue, setTaskStepInputValue] = useState('')

  const [open, setOpen] = React.useState(false)
  const [menuListOpen, setMenuListOpen] = React.useState(false)
  const [taskMenuListOpen, setTaskMenuListOpen] = React.useState(false)
  const [taskCompletedMenuListOpen, setTaskCompletedMenuListOpen] =
    React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const anchorMenuListRef = React.useRef<HTMLButtonElement>(null)
  const anchorTaskMenuListRef: any = React.useRef<HTMLButtonElement>(null)
  const anchorTaskCompletedMenuListRef: any =
    React.useRef<HTMLButtonElement>(null)
  const anchorCompletedMenuListRef = React.useRef<HTMLImageElement>(null)

  const [menuPosition, setMenuPosition] = useState<any>(null)
  const [muiMenuPosition, setMuiMenuPosition] = useState<any>(null)

  const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false)
  const [openNotificationListDialog, setOpenNotificationListDialog] = React.useState(false)

  const [openManageAccountDialog, setOpenManageAccountDialog] = React.useState(false)

  const [openAllNotificationDialog, setOpenAllNotificationDialog] = React.useState(false)
  const [allNotificationData, setAllNotificationData] = React.useState([])
  const [notificationUnreadCount, setNotificationUnreadCount] = React.useState("0")

  const [openSwitchAccountDialog, setOpenSwitchAccountDialog] = React.useState(false)
  const [openLearnMoreDialog, setOpenLearnMoreDialog] = React.useState(false)

  const [addNewListDialog, setAddNewListDialog] = React.useState(false)
  const [noLongerDialog, setNoLongerDialog] = React.useState(false)
  const [noLongerData, setNoLongerData]: any = React.useState({})

  const [openDialog, setOpenDialog] = React.useState(false)
  const [selectedShareType, setSelectedShareType]: any = React.useState("")
  const selectedShareTypeRef: any = useRef("")

  const pressTimeout: any = useRef(null);

  /*  const aiProcess = () => {
    let userInput="add tomatoes to the grocery list";
    if(userInput!="")
    {
     console.log("input",userInput.includes("add"))
    }
   }; */



  const handleLongPress = () => {
    // Implement what you want to do when the longpress event occurs
    console.log('Longpress event detected!');
    setEditSelectedList(true)
  };

  // Set up event handlers
  const handleMouseDown = () => {
    pressTimeout.current = setTimeout(handleLongPress, 300); // Adjust the duration as needed (e.g., 1000ms = 1 second)
  };

  const handleMouseUp = () => {
    if (pressTimeout.current) {
      clearTimeout(pressTimeout.current);
    }
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
    setSelectedShareType("project");
    selectedShareTypeRef.current = "project"
    setUserShareList(listItem)
    handleShareAllUser("project", listItem.id)
    if (listItem && listItem.userId && listItem.userId != userId) {
      getOtherProjectAllInvitations(listItem.id, listItem.orginalProjectGroupId);
    }
  }

  function checkUserShareList(projectGroupId, toUserEmail, tempAllUserShareData) {
    let status = false;
    for (let i = 0; i < tempAllUserShareData.length; i++) {
      if (selectedShareTypeRef.current == "project" && toUserEmail == tempAllUserShareData[i].toUserEmail && projectGroupId == tempAllUserShareData[i].projectGroupId && tempAllUserShareData[i].type == "group" && tempAllUserShareData[i].isAccept == "0") {
        status = true;
        break;
      }

    }
    return status;
  }
  function checkTempUserShareList(tempItem, projectGroupId, toUserEmail) {
    let status = false;
    for (let i = 0; i < tempItem.length; i++) {
      if (selectedShareTypeRef.current == "project" && toUserEmail == tempItem[i].toUserEmail && projectGroupId == tempItem[i].projectGroupId && tempItem[i].type == "group" && tempItem[i].isAccept == "0") {
        status = true;
        break;
      }

    }
    return status;

  }

  async function setUserShareList(shareListItem) {
    setIsShareApiDone(true);
    setIsSortAlpha(true)
    setSharedUserList([])
    let config = {
      userid: userId
    }
    let tempAllUserShareData: any = [];
    try {
      const apiData = await post(BASE_URL + '/getusershare', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data', apiData.data.data)
        let data = apiData.data.data
        for (let i = 0; i < data.length; i++) {
          data[i].toUserEmail = Base64.decode(data[i].toUserEmail)
          if (data[i].toUserName == "IA==") {
            data[i].toUserName = data[i].toUserEmail;
          }
          else {
            data[i].toUserName = Base64.decode(data[i].toUserName)
          }
          data[i].fromUserEmail = Base64.decode(data[i].fromUserEmail)
        }
        setAllUserShareData(data);
        tempAllUserShareData = data;
      }


      let tempItem: any = [];
      for (let i = 0; i < tempAllUserShareData.length; i++) {
        if (selectedShareTypeRef.current == "project" && shareListItem.id == tempAllUserShareData[i].projectId && tempAllUserShareData[i].type == selectedShareTypeRef.current) {
          if (selectedShareTypeRef.current == "project" && checkUserShareList(shareListItem.orginalProjectGroupId, tempAllUserShareData[i].toUserEmail, tempAllUserShareData)) {
            let tempData = { ...tempAllUserShareData[i] }
            tempData.isShowQue = true;
            tempItem.push(tempData);
          }
          else {
            tempItem.push(tempAllUserShareData[i]);
          }
        }

        if (selectedShareTypeRef.current == "project" && shareListItem.orginalProjectGroupId == tempAllUserShareData[i].projectGroupId && tempAllUserShareData[i].type == "group") {
          let tempData = { ...allUserShareData[i] }
          tempData.isShowQue = true;
          tempItem.push(tempData);
        }


        if (selectedShareTypeRef.current == "group" && shareListItem.id == tempAllUserShareData[i].projectGroupId && tempAllUserShareData[i].type == selectedShareTypeRef.current) {
          tempItem.push(tempAllUserShareData[i]);
        }
      }



      if (tempItem && tempItem.length > 0) {
        // tempItem.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
        //   : a.toUserEmail.localeCompare(b.toUserEmail));



        setSharedUserList(tempItem);
        console.log("tempItem45", tempItem)
      }




      let data: any = [...allUser]
      let tempAllInvitation = [...allInvitation];
      let mainFilterArray: any = [];
      let mainData: any = [];
      console.log('mainFilterArray12', data)
      console.log('mainFilterArray12', tempAllInvitation)
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let status = false;
          for (let j = 0; j < tempAllInvitation.length; j++) {
            if ((data[i].email == Base64.decode(tempAllInvitation[j].toUserEmail) || data[i].email == Base64.decode(tempAllInvitation[j].fromUserEmail))) {
              status = true;
              data[i].type = tempAllInvitation[j].type;
              data[i].projectId = tempAllInvitation[j].projectId;
              data[i].projectGroupId = tempAllInvitation[j].projectGroupId;
              data[i].toUserName = data[i].firstName + " " + data[i].lastName;
              data[i].toUserEmail = data[i].email;
              data[i].isAccept = "4";
              console.log('mainFilterArray121', tempAllInvitation[j])
              break;
            }
            /* if ((data[i].email == Base64.decode(tempAllInvitation[j].toUserEmail) || data[i].email == Base64.decode(tempAllInvitation[j].fromUserEmail))) {
              status = true;
              console.log('mainFilterArray123',tempAllInvitation[j])
              break;
            } */
          }
          if (status) {
            mainFilterArray.push(data[i]);
            console.log('mainFilterArray12', mainFilterArray)
            // console.log('mainFilterArray12',data[i])
          }
        }

        let indexs: any = []
        for (let i = 0; i < mainFilterArray.length; i++) {
          for (let j = 0; j < tempItem.length; j++) {
            if (mainFilterArray[i].email == tempItem[j].toUserEmail) {
              indexs.push(i)
              // mainFilterArray.splice(i,1);
              break;
            }

          }


        }

        for (let i = 0; i < mainFilterArray.length; i++) {
          if (!indexs.includes(i)) {
            mainData.push(mainFilterArray[i])
          }
        }
        console.log("indexs", indexs)


        // mainData.sort((a, b) => a.firstName.localeCompare(b.firstName));
        // mainData.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
        // : a.toUserEmail.localeCompare(b.toUserEmail));

        setAllShareUser(mainData)
      }

      let combineUserDataArray: any = []

      combineUserDataArray = [...tempItem, ...mainData];

      combineUserDataArray.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
        : a.toUserEmail.localeCompare(b.toUserEmail));

      console.log('combineUserDataArray', combineUserDataArray);
      setCombineAllShareUser(combineUserDataArray);

      setIsShareApiDone(false)
    } catch (ex) {
      setIsShareApiDone(false)
      console.log('response9211', ex)
    }

  }

  function sortShareDefaultUserByName(type) {
    console.log('tempAllShareUser123', allShareUser)
    let tempAllShareUser = [...allShareUser]
    if (type == "member") {
      if (isSortAllShareUser) {
        tempAllShareUser.sort((b, a) => a.firstName.localeCompare(b.firstName));
        setIsSortAllShareUser(false)
      }
      else {
        tempAllShareUser.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setIsSortAllShareUser(true)
      }
    }
    else {
      tempAllShareUser.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setIsSortAllShareUser(true)
    }
    setAllShareUser(tempAllShareUser)
    console.log('tempAllShareUser123', tempAllShareUser)
  }


  function sortShareUserByName() {

    if (!isSortAlpha) {
      let tempSharedUserList = [...combineAllShareUser]

      if (tempSharedUserList && tempSharedUserList.length > 0) {
        tempSharedUserList.sort((a, b) =>

          a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
            : a.toUserEmail.localeCompare(b.toUserEmail)

        );


        console.log('tempSharedUserList123', tempSharedUserList)
        setCombineAllShareUser(tempSharedUserList)
      }
      setIsSortAlpha(true)
    }
    else {
      let tempSharedUserList = [...combineAllShareUser]
      if (tempSharedUserList && tempSharedUserList.length > 0) {
        tempSharedUserList.sort((a, b) => a.isAccept - b.isAccept);
        console.log('tempSharedUserList123', tempSharedUserList)
        setCombineAllShareUser(tempSharedUserList)
        setIsSortAlpha(false)
      }
    }
  }

  function sortShareUserByStatus() {
    if (isSortAlpha) {
      let tempSharedUserList = [...combineAllShareUser]
      console.log('tempSharedUserList123', tempSharedUserList)
      if (tempSharedUserList && tempSharedUserList.length > 0) {
        tempSharedUserList.sort((a, b) => a.isAccept - b.isAccept);
        console.log('tempSharedUserList123', tempSharedUserList)
        setCombineAllShareUser(tempSharedUserList)
        setIsSortAlpha(false)
      }
    }
    else {
      let tempSharedUserList = [...combineAllShareUser]

      if (tempSharedUserList && tempSharedUserList.length > 0) {
        tempSharedUserList.sort((a, b) =>

          a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
            : a.toUserEmail.localeCompare(b.toUserEmail)

        );


        console.log('tempSharedUserList123', tempSharedUserList)
        setCombineAllShareUser(tempSharedUserList)
        setIsSortAlpha(true)
      }

    }
  }




  const handleCloseDialog = () => {
    setOpenDialog(false);
    setShareListInputValue('')
    setSharedListData([])
    setUserListDivShow(false)
    setInviteLink(false)
  }

  const getUser = async userId => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getusers', config)
      console.log('apiData45567', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data', apiData.data.data)
        // setAllUser(apiData.data.data)
        let data = apiData.data.data
        for (let i = 0; i < data.length; i++) {
          data[i].email = Base64.decode(data[i].email)
          data[i].firstName = Base64.decode(data[i].firstName)
          data[i].lastName = Base64.decode(data[i].lastName)
        }
        setAllUser(data)
        console.log('newData12', allUser)
        console.log('newData123', data)
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  const getTaskUserActivity = async userId => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    console.log('apiData45567', config)
    try {
      const apiData = await post(BASE_URL + '/gettaskuseractivity', config)
      console.log('apiData45567', apiData)
      if (apiData && apiData.data && apiData.data.data && apiData.data.data.length > 0) {
        setTaskUserActivity(apiData.data.data)
      }
      else if (apiData && apiData.data) {
        setTaskUserActivity(apiData.data.data)
      }
      console.log('apiData45567', taskUserActivity)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const updateTaskUserActivity = async (taskId) => {
    console.log('taskId2311', userId)
    let config = {
      taskId: taskId,
      userid: userId
    }
    console.log('apiData45567', config)
    try {
      const apiData = await post(BASE_URL + '/updatetaskuseractivity', config)
      console.log('apiData45567', apiData)
      if (apiData && apiData.data && apiData.data.data && apiData.data.data.length > 0) {
        setTaskUserActivity(apiData.data.data)
      }
      else if (apiData && apiData.data) {
        setTaskUserActivity(apiData.data.data)
      }
      console.log('apiData45567', taskUserActivity)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const updateCompletedTaskUserActivity = async (projectId) => {
    console.log('taskId2311', userId)
    let config = {
      projectId: projectId,
      userid: userId
    }
    console.log('apiData45567', config)
    try {
      const apiData = await post(BASE_URL + '/updatecompletedtaskuseractivity', config)
      console.log('apiData45567', apiData)
      if (apiData && apiData.data && apiData.data.data && apiData.data.data.length > 0) {
        setTaskUserActivity(apiData.data.data)
      }
      else if (apiData && apiData.data) {
        setTaskUserActivity(apiData.data.data)
      }
      console.log('apiData45567', taskUserActivity)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  function listUserActivityCount(projectId) {
    // return false;
    try {
      if (taskUserActivity.length == 0) {
        return false;
      }
      let tempTaskActivityData: any = []
      for (let i = 0; i < taskData[0].length; i++) {
        for (let j = 0; j < taskUserActivity.length; j++) {
          if (taskData[0][i].id == taskUserActivity[j].taskId && taskData[0][i].isTrash != '2' && taskData[0][i].taskHeading != '1' && (taskData[0][i].taskStatus == '0' || taskUserActivity[j].completeTaskStatus == '0')) {
            tempTaskActivityData.push(taskData[0][i].projectId)
          }
        }
      }
      if (tempTaskActivityData.includes(projectId)) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (ex) {

    }
  }

  function listUserActivityNotificationCount(projectId) {
    // return false;
    try {
      if (taskUserActivity.length == 0) {
        return false;
      }
      let tempTaskActivityData: any = []
      for (let i = 0; i < taskData[0].length; i++) {
        for (let j = 0; j < taskUserActivity.length; j++) {
          if (taskData[0][i].id == taskUserActivity[j].taskId && taskData[0][i].isTrash != '2' && taskData[0][i].taskHeading != '1' && taskUserActivity[j].type == 'notification') {
            tempTaskActivityData.push(taskData[0][i].projectId)
          }
        }
      }
      if (tempTaskActivityData.includes(projectId)) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (ex) {

    }
  }

  function groupUserActivityCount(projectGroupId) {
    // return false;
    try {
      if (taskUserActivity.length == 0) {
        return false;
      }
      let tempTaskActivityData: any = []
      let tempGroupActivityData: any = []
      for (let i = 0; i < taskData[0].length; i++) {
        for (let j = 0; j < taskUserActivity.length; j++) {
          if (taskData[0][i].id == taskUserActivity[j].taskId && taskData[0][i].isTrash != '2' && taskData[0][i].taskHeading != '1' && (taskData[0][i].taskStatus == '0' || taskUserActivity[j].completeTaskStatus == '0')) {
            tempTaskActivityData.push(taskData[0][i].projectId)
          }
        }
      }

      for (let i = 0; i < groupData.length; i++) {
        for (let j = 0; j < groupData[i].project.length; j++) {
          if (tempTaskActivityData.includes(groupData[i].project[j].id)) {
            tempGroupActivityData.push(groupData[i].id)
            break;
          }

        }
      }
      if (tempGroupActivityData.includes(projectGroupId)) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (ex) {

    }
  }



  function taskUserActivityCount(taskId) {
    let count = 0
    try {
      if (taskUserActivity.length == 0) {
        return 0;
      }
      let mainData: any = [...taskUserActivity]
      console.log('selectedProjectID', selectedProjectID)
      for (let i = 0; i < mainData.length; i++) {
        if (mainData[i].taskId == taskId && mainData[i].isTrash != '2' && mainData[i].taskHeading != '1') {
          count++
        }
      }
    }
    catch (ex) {

    }

    return count;
  }

  function taskUserActivityNotificationCount(taskId) {
    let count = 0
    try {
      if (taskUserActivity.length == 0) {
        return 0;
      }
      let mainData: any = [...taskUserActivity]
      console.log('selectedProjectID', selectedProjectID)
      for (let i = 0; i < mainData.length; i++) {
        if (mainData[i].taskId == taskId && mainData[i].isTrash != '2' && mainData[i].type == 'notification' && mainData[i].taskHeading != '1') {
          count++
        }
      }
    }
    catch (ex) {

    }
    return count;

  }


  function getTaskLoaction(item) {
    let projectLocationName = ''
    let groupLocationName = ''
    try {
      let tempSelectedData: any = {};
      let tempProjectData: any = [...projectData]
      let tempGroupData: any = [...groupData];
      for (let i = 0; i < tempProjectData.length; i++) {
        if (tempProjectData[i].id == item.projectId) {
          tempSelectedData = tempProjectData[i];
          projectLocationName = decData(tempProjectData[i].projectName, '1')
          break;
        }
      }

      for (let i = 0; i < tempGroupData.length; i++) {
        for (let j = 0; j < tempGroupData[i].project.length; j++) {
          if (tempGroupData[i].project[j].id == item.projectId) {
            tempSelectedData = tempGroupData[i].project[j];
            projectLocationName = decData(tempGroupData[i].project[j].projectName, '1')
            groupLocationName = decData(tempGroupData[i].groupName, '1')
            break;
          }
        }
      }
      console.log('tempSelectedData456', tempSelectedData)
    }
    catch (ex) {

    }
    if (projectLocationName != "")
      return (
        <span>
          {groupLocationName != '' ? groupLocationName + ' ' + '>' : null}&nbsp;{projectLocationName}
        </span>
      )
    else
      return "";
  }




  const handleRightClick = (event: React.MouseEvent, item) => {
    if (event.pageY > windowHeight - 180)
      setNewTopPoints(event.pageY - 60)
    else
      setNewTopPoints(event.pageY)
    if (menuPosition) {
      return
    }
    event.preventDefault()
    console.log('windowHeight', windowHeight)
    console.log('event.pageY', event.pageY)
    let p1 = windowHeight - event.pageY;
    let popperHeight = item.userid != userId ? item.taskNote == "1" ? 344 - 82 : item.taskHeading == "1" ? 164 : 344 : item.taskNote == "1" ? 470 - 82 : item.taskHeading == "1" ? 205 : 470;

    let p2 = popperHeight - p1;
    console.log('event.pageY', popperHeight - p1)

    let w1 = windowWidth - event.pageX;
    let w2 = 240 - w1;

    console.log('event.pageY451', p1)
    console.log('event.pageY452', p2)
    console.log('event.pageY453', windowHeight - event.pageY)
    console.log('event.pageY453', windowHeight - event.pageY)
    if (item && item.taskStatus && item.taskStatus == '1') {
      setMenuPosition({
        top: event.pageY,
        left: event.pageX >= (windowWidth - 240) ? event.pageX - w2 - 20 : event.pageX
      })
    }
    else {
      setMenuPosition({
        top: event.pageY >= (windowHeight - popperHeight) ? (event.pageY - 20) - p2 : event.pageY,
        left: event.pageX >= (windowWidth - 240) ? event.pageX - w2 - 20 : event.pageX
      })
    }
  }
  const handleRightMuiHover = (event: React.MouseEvent) => {
    if (muiMenuPosition) {
      return
    }
    event.preventDefault()
    // console.log('menuPosition',menuPosition)
    // console.log('windowWidth',windowWidth)
    let totalForWidth = windowWidth - 500
    let leftSide: any = null

    //console.log('windowWidth', windowWidth)
    console.log('totalForWidth windowWidth - 500', totalForWidth)
    console.log('menuPosition', menuPosition)
    console.log('menuPosition.left', menuPosition.left)
    console.log('menuPosition.left+200', menuPosition.left + 200)
    console.log('menuPosition.left-200', menuPosition.left - 200)

    // if(menuPosition.left > totalForWidth){
    //   leftSide = menuPosition.left - 240
    //   // console.log('totalForWidth',totalForWidth)
    //   // console.log('menuPosition',menuPosition)
    //   // console.log('menuPosition.left',menuPosition.left)
    //   // console.log('menuPosition.left+200',menuPosition.left + 200)
    // }
    // else{
    //   leftSide = menuPosition.left + 240
    //   // console.log('menuPosition',menuPosition)
    //   // console.log('menuPosition.left',menuPosition.left)
    //   // console.log('menuPosition.left-200',menuPosition.left - 200)
    // }
    if (windowWidth - 450 < menuPosition.left) {
      setMuiMenuPosition({
        top: event.pageY,
        left: windowWidth - 455
      })
    } else {
      setMuiMenuPosition({
        top: event.pageY,
        left:
          menuPosition.left > totalForWidth
            ? menuPosition.left
            : menuPosition.left + 240
      })
    }
  }

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleTaskMenuListToggle = () => {
    setTaskMenuListOpen(prevTaskMenuListOpen => !prevTaskMenuListOpen)
  }
  const handleTaskCompletedMenuListToggle = () => {
    setTaskCompletedMenuListOpen(
      prevTaskCompletedMenuListOpen => !prevTaskCompletedMenuListOpen
    )
  }

  const handleMenuListToggle = () => {
    setMenuListOpen(prevMenuListOpen => !prevMenuListOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }
  const handleMenuListClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorMenuListRef.current &&
      anchorMenuListRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setMenuListOpen(false)
  }
  const handleTaskMenuListClose: any = (
    event: Event | React.SyntheticEvent
  ) => {
    if (
      anchorTaskMenuListRef.current &&
      anchorTaskMenuListRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setTaskMenuListOpen(false)
  }
  const handleTaskCompletedMenuListClose: any = (
    event: Event | React.SyntheticEvent
  ) => {
    if (
      anchorTaskCompletedMenuListRef.current &&
      anchorTaskCompletedMenuListRef.current.contains(
        event.target as HTMLElement
      )
    ) {
      return
    }
    setTaskCompletedMenuListOpen(false)
  }

  // function handleListKeyDown (event: React.KeyboardEvent) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault()
  //     setOpen(false)
  //     setMenuListOpen(false)
  //     setTaskMenuListOpen(false)
  //     setTaskCompletedMenuListOpen(false)
  //   } else if (event.key === 'Escape') {
  //     setOpen(false)
  //     setMenuListOpen(false)
  //     setTaskMenuListOpen(false)
  //     setTaskCompletedMenuListOpen(false)
  //   }
  // }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }
    prevOpen.current = open
  }, [open])
  const prevMenuListOpen = React.useRef(menuListOpen)
  React.useEffect(() => {
    if (prevMenuListOpen.current === true && menuListOpen === false) {
      anchorMenuListRef.current!.focus()
    }
    prevMenuListOpen.current = menuListOpen
  }, [menuListOpen])
  const prevTaskMenuListOpen = React.useRef(taskMenuListOpen)
  React.useEffect(() => {
    if (prevTaskMenuListOpen.current === true && taskMenuListOpen === false) {
      anchorTaskMenuListRef.current?.focus()
    }
    prevTaskMenuListOpen.current = taskMenuListOpen
  }, [taskMenuListOpen])
  const prevTaskCompletedMenuListOpen = React.useRef(taskCompletedMenuListOpen)
  React.useEffect(() => {
    if (
      prevTaskCompletedMenuListOpen.current === true &&
      taskCompletedMenuListOpen === false
    ) {
      anchorTaskCompletedMenuListRef.current?.focus()
    }
    prevTaskCompletedMenuListOpen.current = taskCompletedMenuListOpen
  }, [taskCompletedMenuListOpen])

  //   const [items, setItems]: any = useState(taskData)
  const rearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr]

    const [removed] = arrCopy.splice(sourceIndex, 1)

    arrCopy.splice(destIndex, 0, removed)

    return arrCopy
  }

  // const onDragEnd = result => {
  //   console.log('result456', result)
  //   const { source, destination } = result

  //   if (!destination) {
  //     return
  //   }

  //   let data = taskData
  //   for (let i = 0; i < data.length; i++) {
  //     if (result.draggableId == data[i].id) {
  //       if (destination.droppableId == 1) {
  //         data[i].status = 0
  //         data[i].category = 1
  //       } else {
  //         data[i].status = 1
  //         data[i].category = 2
  //       }
  //     }
  //   }
  //   console.log('data123', data)
  //   setTaskData(data)

  //   if (destination.droppableId !== source.droppableId) {
  //     setTaskData(items =>
  //       items.map(
  //         item =>
  //           item.id === parseInt(result.draggableId)
  //             ? {
  //                 ...item,
  //                 category: parseInt(result.destination.droppableId)
  //               }
  //             : item,
  //         console.log('task9211')
  //       )
  //     )
  //   } else {

  //     setTaskData(rearangeArr(taskData, source.index, destination.index))
  //   }
  //   console.log('taskData123', taskData)
  // }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    // console.log('result000',list)
    const removed = result.splice(startIndex, 1)
    console.log('removed000', removed)
    result.splice(endIndex, 0, removed.pop())
    console.log('result000', result)

    return result
  }
  const reorderProjectList = (list, startIndex, endIndex) => {
    const result = list
    console.log('result998899', list)
    const removed = result.splice(startIndex, 1)
    console.log('removed000', removed)
    result.splice(endIndex, 0, removed.pop())
    console.log('result000', result)

    return result
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    console.log(
      'source, destination, droppableSource, droppableDestination',
      source,
      destination,
      droppableSource,
      droppableDestination
    )

    var destinationProjectGroup = droppableDestination.droppableId.split('-')
    var destinationProjectGroupId = destinationProjectGroup[1]
    console.log('destinationProjectGroupId', destinationProjectGroupId)
    const sourceClone = source
    const destClone = destination
    const removed = sourceClone.splice(droppableSource.index, 1)
    removed[0].projectGroupId = destinationProjectGroupId
    destClone.splice(droppableDestination.index, 0, removed[0])

    const result: any = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  function onDragMainEnd(result) {
    console.log('result456456', result)
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId

    if (sInd === dInd) {
      const items = reorder(taskData[sInd], source.index, destination.index)
      const newState: any = [...taskData]
      newState[sInd] = items
      /* console.log('newState[sInd]', newState[sInd])
      console.log('newSate45', newState) */
      setTaskData(newState)

      /*     console.log('newSate45', taskMainData) */
      setTaskMainData(newState[0])
      updateTaskOrder(newState[0])
    } else {
      /*  const result = move(taskData[sInd], taskData[dInd], source, destination)
      const newState = [...taskData]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      console.log('newState852', newState)
      console.log(
        'newState.filter(group => group.length)',
        newState.filter(group => group.length)
      )
      setTaskData(newState.filter(group => group.length)) */
    }
  }
  function getGroupUserId(id: any) {
    let userid = 0;
    let newgroupData = [...groupData]
    //console.log('newgroupdata',newgroupData)
    for (let i1 = 0; i1 < newgroupData.length; i1++) {
      if (newgroupData[i1].id == id) {
        userid = newgroupData[i1].userId;
        break;
      }
    }
    return userid;
  }
  async function onDragEnd(result) {
    setIsDragStart(false)
    console.log('result456', result)
    const { source, destination, draggableId } = result

    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd: any = source.droppableId
    const dInd: any = destination.droppableId
    const dragId: any = draggableId.split('-')
    /*   console.log('sInd', sInd)
      console.log('dInd', dInd) */

    if (sInd == 0 && dInd == 0) {
      console.log('case-1')
      if (sInd === dInd) {
        const items = reorder(taskData[sInd], source.index, destination.index)
        const newState: any = [...taskData]
        newState[sInd] = items
        /*   console.log('newState[sInd]', newState[sInd])
          console.log('newSate45', newState) */
        setTaskData(newState)

        /*  console.log('newSate45', taskMainData) */
        setTaskMainData(newState[0])
        updateTaskOrder(newState[0])
      } else {
        /*  const result = move(taskData[sInd], taskData[dInd], source, destination)
      const newState = [...taskData]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      console.log('newState852', newState)
      console.log(
        'newState.filter(group => group.length)',
        newState.filter(group => group.length)
      )
      setTaskData(newState.filter(group => group.length)) */
      }
    } else if (sInd == 'completedTaskId-0' && dInd == 'completedTaskId-0') {
      console.log('case-1.2')
      if (sInd === dInd) {
        const items = reorder(taskData[0], source.index, destination.index)
        const newState: any = [...taskData]
        newState[0] = items
        /*   console.log('newState[sInd]', newState[sInd])
          console.log('newSate45', newState) */
        setTaskData(newState)

        /*  console.log('newSate45', taskMainData) */
        setTaskMainData(newState[0])
        updateTaskOrder(newState[0])
      } else {
        /*  const result = move(taskData[sInd], taskData[dInd], source, destination)
      const newState = [...taskData]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      console.log('newState852', newState)
      console.log(
        'newState.filter(group => group.length)',
        newState.filter(group => group.length)
      )
      setTaskData(newState.filter(group => group.length)) */
      }
    } else if (sInd == 'mainGroup-1' && dInd == 'mainGroup-1') {
      console.log('case-2')

      const items = reorder(groupData, source.index, destination.index)
      let newState: any = [...groupData]
      newState = items
      setGroupData(newState)
      groupDataRef.current = newState;
      updateProjectAndProjectGroupOrder(projectData, newState, 0, 0)
      /*  console.log('newSate45', newState) */
    } else if (
      sInd == 'projectGroup-' + userData.defaultProjectID &&
      dInd == 'projectGroup-' + userData.defaultProjectID
    ) {
      console.log('case-3')

      const items = reorder(projectData, source.index, destination.index)
      let newState: any = [...projectData]
      newState = items
      setProjectData(newState)
      projectDataRef.current = newState
      updateProjectAndProjectGroupOrder(newState, groupData, 0, 0)
      /*  console.log('newSate45', newState) */
    } else if (sInd.includes('projectList-') && sInd == dInd) {
      console.log('case-4')
      /*   
         console.log('sInd.includes("projectList-")', sInd.includes('projectList-'), dInd.includes('projectList-')) */
      var sIndId = sInd.split('-')

      if (
        sInd == 'projectList-' + sIndId[1] &&
        dInd == 'projectList-' + sIndId[1]
      ) {
        let newState = [...groupData]
        for (let i = 0; i < newState.length; i++) {
          if (newState[i].id == sIndId[1]) {
            const items = reorder(
              newState[i].project,
              source.index,
              destination.index
            )
            newState[i].project = items
          }
        }
        setGroupData(newState)
        groupDataRef.current = newState
        updateProjectAndProjectGroupOrder(projectData, newState, 0, 0)
        /*    console.log('newSate45', newState) */
      }
    } else if (dragId[0] == 'dragprojectListid') {
      console.log('case-5')

      var dIndId = dInd.split('-')
      var sIndId = sInd.split('-')
      /*  console.log('dragprojectListid',dragId[1])
       console.log('dropprojecid',dIndId[1])
       console.log('sourceprojecid',sIndId[1]) */
      let movablaeListItem: any = {}
      let groupIndex: any = -1

      //for remove source
      if (sIndId[0] == 'projectGroup') {
        console.log('case51')
        let newprojectData = [...projectData]
        for (let i1 = 0; i1 < newprojectData.length; i1++) {
          if (newprojectData[i1].id == dragId[1]) {
            movablaeListItem = newprojectData[i1]
            newprojectData.splice(i1, 1)
            setProjectData(newprojectData)
            projectDataRef.current = newprojectData
            break
          }
        }
      } else {
        console.log('case52')

        let newgroupData = [...groupData]
        //console.log('newgroupdata',newgroupData)
        for (let i1 = 0; i1 < newgroupData.length; i1++) {
          for (let j = 0; j < newgroupData[i1].project.length; j++) {
            if (newgroupData[i1].project[j].id == dragId[1] && (userData.id != newgroupData[i1].project[j].userId)) {
              movablaeListItem = newgroupData[i1].project[j]
              /*  console.log('movablaeListItem1',newgroupData[i1].project[j]) */
              newgroupData[i1].project.splice(j, 1)
              console.log("5a")
              setGroupData(newgroupData)
              groupDataRef.current = newgroupData
              groupIndex = i1
              break
            }
            else if (newgroupData[i1].project[j].id == dragId[1] && userData.id == newgroupData[i1].project[j].userId && userData.id == getGroupUserId(dIndId[1])) {
              movablaeListItem = newgroupData[i1].project[j]
              console.log('case5b', newgroupData[i1].userId)
              console.log('case5b', getGroupUserId(dIndId[1]))
              console.log('case5b', userData.id)
              newgroupData[i1].project.splice(j, 1)
              setGroupData(newgroupData)
              groupDataRef.current = newgroupData
              groupIndex = i1
              break
            }
          }
        }
      }

      /* 
      console.log('movablaeListItem',movablaeListItem); */
      movablaeListItem.projectGroupId = dIndId[1]
      if (dIndId[0] == 'projectGroup') {
        console.log('case53')

        let newprojectData = [...projectData]
        newprojectData.splice(destination.index, 0, movablaeListItem)
        setProjectData(newprojectData)
        projectDataRef.current = newprojectData
        updateProjectAndProjectGroupOrder(newprojectData, groupData, 0, dragId[1])
      } else {
        console.log('case54')

        let newgroupData = [...groupData]
        for (let i1 = 0; i1 < newgroupData.length; i1++) {
          if (newgroupData[i1].id == dIndId[1] && movablaeListItem && movablaeListItem.userId) {
            newgroupData[i1].project.splice(
              destination.index,
              0,
              movablaeListItem
            )
            setGroupData(newgroupData)
            groupDataRef.current = newgroupData
            updateProjectAndProjectGroupOrder(projectData, newgroupData, dIndId[1], dragId[1])
            break
          }
        }
      }

      return
      let groupDataIndexArray = {}
      let newgroupData = [...groupData]
      for (let i = 0; i < newgroupData.length; i++) {
        if (newgroupData[i].id == dIndId[1]) {
          console.log('newState[i].project', newgroupData[i].project)
          groupDataIndexArray = newgroupData[i].project
        }
      }
      console.log('groupDataIndexArray', groupDataIndexArray)
      const result = move(projectData, groupDataIndexArray, source, destination)
      console.log('result996', result)
      return
      const newState = [...taskData]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      console.log('newState852', newState)
      console.log(
        'newState.filter(group => group.length)',
        newState.filter(group => group.length)
      )
      setTaskData(newState.filter(group => group.length))
    } else if (
      dragId[0] == 'dragid' &&
      (dInd.includes('list-') || dInd.includes('groupListItem-'))
    ) {
      console.log('case-6')
      let taskId = dragId[1]
      let newListId = dInd.split('-')
      console.log('taskId', taskId)
      console.log('newListId', newListId[1])
      let taskData1: any = [...taskData]
      let updateitem = {}
      let updateIndex = -1
      let status = 1;
      for (let i = 0; i < taskData1[0].length; i++) {
        if (taskData1[0][i].id == taskId && taskData1[0][i].userid == userData.id) {
          taskData1[0][i].projectId = newListId[1]
          updateitem = taskData1[0][i]
          updateIndex = i
          taskData1[0].splice(i, 1)
          taskData1[0].splice(0, 0, updateitem)
          status = 0;
        }
      }
      if (status == 0) {
        console.log('updateitem', updateitem)
        setTaskData(taskData1)

        try {
          const response = await post(BASE_URL + '/updatetaskproject', updateitem)
          console.log('updateTask', response)
          try {
            let json = { taskId: taskId };
            sendSocketMessage("update task", json)
          }
          catch (ex) {

          }
        } catch (ex) {
          console.log('response9211', ex)
        }
      }
    }
  }

  /* 10/05 */
  function onDragStart(result) {
    setIsDragStart(true)
    console.log('result456xyzxyz', result)
    console.log('result.type', result.type)
    const { source, draggableId } = result
    if (!source) {
      return
    }

    if (draggableId.includes('dragid-')) {
      let tempTaskData = [...taskData]
      let newTaskId = draggableId.split('-')
      for (let i = 0; i < tempTaskData[0].length; i++) {
        if (tempTaskData[0][i].id == newTaskId[1]) {
          setTaskItem(tempTaskData[0][i])
          taskItemRef.current = tempTaskData[0][i];
        }
      }
    }



    const dragId = draggableId
    console.log('dragId', dragId)
    if (dragId.includes('dragprojectid-')) {
      let data = [...groupData]
      let newGroupId = dragId.split('-')
      for (let i = 0; i < data.length; i++) {
        // if(data[i].id == newGroupId[1]){
        // console.log('data[i]',data[i]);
        data[i].open = false
        // }
      }
      setGroupData(data)
      groupDataRef.current = data
    }
  }
  function onDragUpdate(result) {
    console.log('xyxyxyxyxyx', result)
  }
  /* 10/05 */

  function onDragListEnd(result) {
    console.log('result4561', result)
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    if (sInd != dInd) {
      const items = reorder(projectData, source.index, destination.index)
      let newState: any = [...projectData]
      newState = items
      console.log('newState[sInd]', newState)
      console.log('newSate4545', newState)
      setProjectData(newState)
      projectDataRef.current = newState
      console.log('newSate45', projectData)
    }
  }

  function onDragProjectEnd(result) {
    console.log('result45612', result)
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    if (sInd != dInd) {
      const items = reorder(groupData, source.index, destination.index)
      let newState: any = [...groupData]
      newState = items
      console.log('newState[sInd]', newState)
      console.log('newSate4545', newState)
      setGroupData(newState)
      groupDataRef.current = newState
      console.log('newSate45', groupData)
    }
  }

  function onDragProjectListEnd(result) {
    console.log('result45612346', result)
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    console.log('sInd', sInd)
    console.log('dInd', dInd)
    if (sInd != dInd) {
      const items = reorderProjectList(
        groupData[sInd].project,
        source.index,
        destination.index
      )
      let newState: any = [...groupData]
      newState[sInd].project = items
      console.log('newState[sInd].project', newState[sInd].project)
      console.log('newSate45454545', newState)
      setGroupData(newState)
      groupDataRef.current = newState
      console.log('newSate45', groupData)
    }
  }
  const [isTokenFound, setTokenFound]: any = useState(false);

  async function registerToken(token) {
    let config = {
      token: token
    }
    try {
      const apiData = await post(BASE_URL + '/registertoken', config)
      console.log('apiData', apiData)
      let user = JSON.parse(localStorage.getItem('user') || '{}')
      switchAccountForNotification(user.id)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const timeInterVal: any = useRef();
  useEffect(() => {
    /* window.addEventListener('blur', function (event) {
      console.log('is visible');
      if(timeInterVal.current)
      {
      clearInterval(timeInterVal.current)
      }
      timeInterVal.current=setInterval(() => {
        console.log("Five Second is visible")
      }, 5000);
  
});
    window.addEventListener('focus', function (event) {
          console.log('not visible');
          if(timeInterVal.current)
          {
          clearInterval(timeInterVal.current)
          }
          timeInterVal.current=setInterval(() => {
            console.log("Two Second is visible")
          }, 2000);
      
  }); */
    /* timeInterVal.current=setInterval(() => {
      console.log("Five Second is visible")
    }, 5000);
    document.addEventListener('visibilitychange', function (event) {
      if (document.hidden) {
          console.log('not visible');
          if(timeInterVal.current)
          {
          clearInterval(timeInterVal.current)
          }
          timeInterVal.current=setInterval(() => {
            console.log("Two Second is visible")
          }, 2000);
      } else {
          console.log('is visible');
          if(timeInterVal.current)
          {
          clearInterval(timeInterVal.current)
          }
          timeInterVal.current=setInterval(() => {
            console.log("Five Second is visible")
          }, 5000);
      }
  }); */

  /* const state = document.visibilityState;

document.addEventListener('visibilitychange', () => {
  if(window.innerWidth<=768)
  refreshFocusFun()
  console.log("document45", document.visibilityState, window.innerWidth);
}); */
    if ('serviceWorker' in navigator) {

      const wb = new Workbox('service-worker.js');
      wb.addEventListener('waiting', (event) => {
        wb.addEventListener('controlling', (event) => {
          console.log('wb45', event)
          window.location.reload();
        });
        wb.messageSW({ type: 'SKIP_WAITING' });

      });
      wb.addEventListener('installed', event => {
        console.log('wb45', event)
        if (event.isUpdate) {
          window.location.reload();
        }
      });

      wb.register();
    }
    // try {
    //   getToken(messaging, { vapidKey: 'BBY4PfVHdNCzVvY6W_7woYXv0qmYMRDmIhTzjqAlWwfM6-LsiSo8HIJaomLtas3HaConPJhiMIVquDoTnTdqeE8' }).then((currentToken) => {
    //     if (currentToken) {
    //       console.log('current token for client: ', currentToken);
    //       setTokenFound(true);
    //       registerToken(currentToken)
    //       checkReminder()
    //       // Track the token -> client mapping, by sending to backend server
    //       // show on the UI that permission is secured
    //     } else {
    //       console.log('No registration token available. Request permission to generate one.');
    //       setTokenFound(false);
    //       // shows on the UI that permission is required 
    //     }
    //   }).catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //     // catch error while creating client token
    //   });


    //   getToken(setTokenFound);
    // }
    // catch (ex) {
    //   console.log("ex45", ex)
    // }

  }, [])

  async function checkReminder() {
    /* const current = new Date()
    const todayDate = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`
    
    let localStorageData=localStorage.getItem('reminderDate') || null
    if(localStorageData && localStorageData!=todayDate)
    {
      localStorage.setItem('reminderDate', todayDate)
      console.log('reminderDate1CallAPI', todayDate)
      updateToken()
    }
    else if(localStorageData && localStorageData==todayDate)
    {
      console.log('reminderDate2', todayDate)
    }
    else if(localStorageData == null)
    {
      localStorage.setItem('reminderDate', todayDate)
      console.log('reminderDate4CallAPI', todayDate)
      updateToken()
    } */
  }
  async function callReminder(token)
  {
    /* let config = {
      token: token
    }
    try {
      const apiData = await post(BASE_URL + '/checkReminder', config)
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    } */
  }
  function updateToken()
  {
    //callReminder('')
    // try {
    //   getToken(messaging, { vapidKey: 'BBY4PfVHdNCzVvY6W_7woYXv0qmYMRDmIhTzjqAlWwfM6-LsiSo8HIJaomLtas3HaConPJhiMIVquDoTnTdqeE8' }).then((currentToken) => {
    //     if (currentToken) {
    //       console.log('current token for client: ', currentToken);
    //       setTokenFound(true);
    //       registerToken(currentToken)
    //       callReminder(currentToken)
    //       // Track the token -> client mapping, by sending to backend server
    //       // show on the UI that permission is secured
    //     } else {
    //       console.log('No registration token available. Request permission to generate one.');
    //       setTokenFound(false);
    //       // shows on the UI that permission is required 
    //     }
    //   }).catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //     // catch error while creating client token
    //   });


    //   getToken(setTokenFound);
    // }
    // catch (ex) {
    //   console.log("ex45", ex)
    // }
  }
  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768)
        setMobileView(true);
      else
        setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  const taskDataRef = useRef([]);
  //const groupDataRef = useRef([]);
  useEffect(() => {
    if (mobileView) {
      setHideShowMiddlePane(true)
      setHideShowRightPane(true)
    }
    else {
      //setSearchBarOpen(true)
    }
  }, []);
  const uniqueIdRef = useRef(nanoid());
  function sendSocketMessage(type, data) {
    try {
      data.uniqueId = uniqueIdRef.current;
      var messageJSON = {
        message_type: type,
        message: data
      };
      webSocketRef.current.send(JSON.stringify(messageJSON));
    }
    catch (ex) {
      console.log("webSocketRefError", ex);
    }
  }

  function websocketInit() {
    webSocketRef.current = new WebSocket("wss://hizzy.com:8090/");
    webSocketRef.current.onopen = function (event) {
      console.log("webSocketRef", "Connection is established!");
    }
    webSocketRef.current.onmessage = function (event) {
      //console.log("webSocketRef",event.data);
      try {
        var Data = JSON.parse(event.data);
        console.log("webSocketRef", Data);
        let user = JSON.parse(localStorage.getItem('user') || '{}')
        if (user && user.id && Data.message && Data.message.uniqueId && Data.message.uniqueId != uniqueIdRef.current && document.activeElement!=sideBarTaskNoteInputRef.current) {
          console.log("webSocketRef", document.activeElement);
          if(Data.message.taskId)
          {
          getTaskSocket(user.id, false, Data.message.taskId);
          }
          else if(Data.message.taskid)
          {
          getTaskSocket(user.id, false, Data.message.taskid);
          }
          else{
            getTaskSocket(user.id, false, "0");
          }
          getProjectWithGroupSocket(user.id)
          getTaskUserActivity(user.id)
          if (Data.message_type && (Data.message_type == "addshare" || Data.message_type == "setinvitation")) {
            syncShare(user);
          }
          if (Data.message_type && (Data.message_type == "updateprofile" || Data.message_type == "updateprofile")) {
            getUserById(user.id);
          }
        }
        else if (Data.message && Data.message.uniqueId && Data.message.uniqueId == uniqueIdRef.current) {
          console.log("webSocketRef", "No Updatation");
        }
      }
      catch (ex) {
        console.log("webSocketRef", event.data);
      }
    };

    webSocketRef.current.onerror = function (event) {
      console.log("webSocketRefError");
    };
    webSocketRef.current.onclose = function (event) {
      websocketInit() 
      console.log("webSocketRefClose");
    };
  }
  const webSocketRef: any = useRef(null);
  useEffect(() => {
    websocketInit();
    /* webSocketRef.current = new WebSocket("wss://hizzy.com:8090/"); 
    webSocketRef.current.onopen = function(event) { 
     console.log("webSocketRef","Connection is established!");		
   }
   webSocketRef.current.onmessage = function(event) {
     
     try
     {
      var Data = JSON.parse(event.data);
     console.log("webSocketRef",Data);
     let user = JSON.parse(localStorage.getItem('user') || '{}')
     if(user && user.id && Data.message && Data.message.uniqueId && Data.message.uniqueId != uniqueIdRef.current)
     {
     getTask(user.id);
     getProjectWithGroupSocket(user.id)
     if(Data.message_type && (Data.message_type=="addshare" || Data.message_type=="setinvitation"))
     {
      syncShare(user);
     }
     if(Data.message_type && (Data.message_type=="updateprofile" || Data.message_type=="updateprofile"))
     {
      getUserById(user.id);
     }
     }
     else if(Data.message && Data.message.uniqueId && Data.message.uniqueId == uniqueIdRef.current)
     {
      console.log("webSocketRef","No Updatation");
     }
    }
    catch(ex)
    {
      console.log("webSocketRef",event.data);

    }
   };

   webSocketRef.current.onerror = function(event){ 
     console.log("webSocketRef","Connection error");
   };
   webSocketRef.current.onclose = function(event){
     console.log("webSocketRef","Connection Close");
   }; */
  }, []);
  async function syncShare(user) {
    getUserShare(user.id)
    await checkUserShare(user.id, user.email, user);
    await getTaskUserActivity(user.id)
    await getotherusershare(user.id)
    await getNotifications(user)
    await getUser(user.id)
    if (localStorage.getItem("shareCheckData") != null) {
      getUserShareById()
    }
    getNotInterested()
  }




  
  useEffect(() => {
    //checkReminder()
    let user = JSON.parse(localStorage.getItem('user') || '{}')
    setUserData(user)
    try {
      setFirstName(Base64.decode(user.firstName))
      setLastName(Base64.decode(user.lastName))
      setEmail(Base64.decode(user.email))
      if (user.firstName != "") {
        setNameLetters(
          Base64.decode(user.firstName).charAt(0) +
          Base64.decode(user.lastName).charAt(0)
        )
      }
      else {
        setNameLetters(
          Base64.decode(user.email).charAt(0)
        )
      }
    } catch (ex) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setNameLetters(user.firstName.charAt(0) + user.lastName.charAt(0))
    }

    (async () => {
      let userId = user.id
      console.log('user12', user)
      setUserId(userId)
      //getProjectWithGroup(userId, 0)
      getProjectWithGroupWithDefault(userId, 0, user.defaultProjectID)
      await getTask(userId)
      getUserShare(userId)
      if (user && user.defaultGroupID) {
        console.log('test456')
        await getUserById(user.id)
        /* if(user.defaultGroupID=="0")
        {
        getUserById(user.id)
        } */
      }
      else {
        await getUserById(user.id)
      }
      await checkUserShare(userId, user.email, user);
      await getTaskUserActivity(userId)
      await getotherusershare(userId)
      await getAllUserByIds()
      await getNotifications(user)
      await getUser(userId)
      if (localStorage.getItem("shareCheckData") != null) {
        getUserShareById()
      }
      getNotInterested()



      try {
        if (userId == "1" || userId == "2" || userId == "3" || userId == "50") {
        }
        else {
          mixPanelTrack("ProjectPageView", { "email": Base64.decode(user.email) })
        }
      }
      catch (ex) {

      }


      if ((localStorage.getItem("intro") == null || localStorage.getItem("intro") != null) && (taskDataRef.current.length > 0 || groupDataRef.current.length > 1)) {
        setShowIntroScreen(false);
      }
      else if (localStorage.getItem("intro") == null && window.innerWidth > 768) {
        setShowIntroScreen(true)
      }




    })();
    // 03/05
    const current = new Date()
    const todayDate = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`
    console.log('todayDate', todayDate)
    setCurrentDate(todayDate)
    // 03/05
    console.log('windowWidth', window.innerWidth)
    console.log('windowHeight', window.innerHeight)
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)



    if (
      user.defaultProjectID &&
      user.defaultProjectID == '' &&
      user.defaultProjectID == null
    ) {
      LogOutFun()
    }
    if (localStorage.getItem('project')) {
      let userListItem = JSON.parse(localStorage.getItem('project') || '{}')
      setListItem(userListItem)
      listItemRef.current = userListItem;
    } else {
      setSelectedProjectID(user.defaultProjectID)
    }

    if (user && user.isEmailSend && user.isEmailSend == "1") {
      verifyRegisterLink(user)
    }
    if (!localStorage.getItem("switchUserData")) {
      let tempSwitchData: any = [];
      let json = {
        userData: user,
        token: localStorage.getItem("token")
      };
      tempSwitchData.push(json);
      localStorage.setItem("switchUserData", JSON.stringify(tempSwitchData))
    }

    let tempSwitchUserData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
    setSwitchUserData(tempSwitchUserData)

  }, [])


  async function getUserShareById() {

    console.log('testShareCheck12', localStorage.getItem("shareCheckData"))

    let tempShareId = localStorage.getItem("shareCheckData");

    localStorage.removeItem("shareCheckData");

    let config = {
      shareId: tempShareId
    }
    try {
      const apiData = await post(BASE_URL + '/getusersharebyid', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {
        // console.log('apiData.data.data', apiData.data.data[0].status)
        if (apiData.data.data && apiData.data.data.length > 0) {
          setNoLongerData(apiData.data.data[0])
          if (apiData.data.data[0].status != "0") {
            setNoLongerDialog(true)
          }
        }
      }
    } catch (ex) {

    }
  }


  const getUserById = async userId => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getuserbyid', config)
      console.log('apiData455678', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data', apiData.data.data[0])
        localStorage.setItem("user", JSON.stringify(apiData.data.data[0]));
        setUserData(apiData.data.data[0])
        console.log('switchuserDAta45', switchUserData);
        if (!localStorage.getItem("switchUserData")) {
          let tempSwitchData: any = [];
          let json = {
            userData: apiData.data.data[0],
            token: localStorage.getItem("token")
          };
          tempSwitchData.push(json);
          localStorage.setItem("switchUserData", JSON.stringify(tempSwitchData))
          setSwitchUserData(tempSwitchData)
        }
        else {
          let tempSwitchUserData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
          for (let i = 0; i < tempSwitchUserData.length; i++) {
            if (tempSwitchUserData[i].userData.id == apiData.data.data[0].id) {
              tempSwitchUserData[i].userData = apiData.data.data[0]
              console.log('switchuserDAta4545', apiData.data.data[0]);
            }
          }
          setSwitchUserData(tempSwitchUserData)
          localStorage.setItem("switchUserData", JSON.stringify(tempSwitchUserData))
        }

        let user = JSON.parse(localStorage.getItem('user') || '{}')
        setUserData(user)
        try {
          setFirstName(Base64.decode(user.firstName))
          setLastName(Base64.decode(user.lastName))
          setEmail(Base64.decode(user.email))
          if (user.firstName != "") {
            setNameLetters(
              Base64.decode(user.firstName).charAt(0) +
              Base64.decode(user.lastName).charAt(0)
            )
          }
          else {
            setNameLetters(
              Base64.decode(user.email).charAt(0)
            )
          }
        } catch (ex) {
          setFirstName(user.firstName)
          setLastName(user.lastName)
          setEmail(user.email)
          setNameLetters(user.firstName.charAt(0) + user.lastName.charAt(0))
        }

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const getAllUserByIds = async () => {
    let tempSwitchUserData: any = JSON.parse(localStorage.getItem('switchUserData') || '[]')
    if (tempSwitchUserData.length == 0)
      return;
    let ids: any = "";
    for (let i = 0; i < tempSwitchUserData.length; i++) {
      if (i == 0) {
        ids = tempSwitchUserData[i].userData.id;
      }
      else {
        ids = ids + "," + tempSwitchUserData[i].userData.id
      }
    }

    let config = {
      userids: ids
    }
    try {
      const apiData = await post(BASE_URL + '/getalluserbyids', config)
      console.log('apiData455678', apiData)
      if (apiData.data.status == 'success') {
        if (apiData.data.data.length == 1) {
          let tempSwitchData: any = [];
          let json = {
            userData: apiData.data.data[0],
            token: localStorage.getItem("token")
          };
          tempSwitchData.push(json);
          localStorage.setItem("switchUserData", JSON.stringify(tempSwitchData))
          setSwitchUserData(tempSwitchData)
        }
        else {
          let resultAllUser = apiData.data.data;
          let tempSwitchUserData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
          for (let j = 0; j < resultAllUser.length; j++) {
            for (let i = 0; i < tempSwitchUserData.length; i++) {
              if (tempSwitchUserData[i].userData.id == resultAllUser[j].id) {
                tempSwitchUserData[i].userData = resultAllUser[j]
              }
            }
          }
          setSwitchUserData(tempSwitchUserData)
          localStorage.setItem("switchUserData", JSON.stringify(tempSwitchUserData))
        }

        let user = JSON.parse(localStorage.getItem('user') || '{}')
        setUserData(user)

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }





  async function LogOutFun() {

    let config = {
      userId: userId
    }
    try {
      const apiData = await post(BASE_URL + '/logout', config)
      console.log('apiData', apiData)
      var myCookie = localStorage.getItem("setCookie");
      var Intro = localStorage.getItem("intro");
      localStorage.clear();
      if (myCookie != null) {
        localStorage.setItem("setCookie", myCookie);
      }
      if (Intro != null) {
        localStorage.setItem("intro", Intro);
      }
    }
    catch (ex) {

    }

    //navigate('/')
    window.location.reload()
  }

  async function logOutAll(idJson: any) {
    let config = {
      userIdString: idJson.toString()
    }
    console.log('idJson45', config)
    try {
      const apiData = await post(BASE_URL + '/logoutall', config)
      console.log('apiData', apiData)
    }
    catch (ex) {

    }
  }


  function selectDefaultGroup() {
    if (userData && userData.defaultGroupID && userData.defaultGroupID != "0") {
      let tempGroupData = [...groupData]
      for (let i = 0; i < tempGroupData.length; i++) {
        if (tempGroupData[i].id == userData.defaultGroupID) {
          setSelectedFolderItem(tempGroupData[i])
          break;
        }
      }
    }
  }

  function showRenamePopUp(item, type) {

    setRenameListForMobile(true)
    if (type == "list") {
      setRenameListType("Rename List")
      setRenameListInputValue(decData(item.projectName, item.isEncrypt))
    }
    else if (type == "Rename Project") {
      setRenameListType("Rename Project")
      setRenameListInputValue(decData(item.groupName, item.isEncrypt))
    }
    else {
      console.log('test456')
      setRenameListType("New Project")
      setRenameListInputValue("")
    }

  }

  function selectGroupForNewList(item) {

    console.log('test1234', item)

    let tempGroupData = [...groupData]
    for (let i = 0; i < tempGroupData.length; i++) {
      if (tempGroupData[i].id == item.id) {
        setSelectedFolderItem(tempGroupData[i])
        break;
      }
    }
  }


  function getListToFolder(item) {

    console.log('test11', item);

    let tempGroupData = [...groupData]
    for (let i = 0; i < tempGroupData.length; i++) {
      if (tempGroupData[i].id == item.orginalProjectGroupId) {
        setSelectedFolderItem(tempGroupData[i])
        break;
      }
    }


  }



  function LogOutCurrentUserFun() {
    let tempSwitchUserData = [...switchUserData]
    for (let i = 0; i < tempSwitchUserData.length; i++) {
      if (tempSwitchUserData[i].userData.id == userId) {
        tempSwitchUserData.splice(i, 1)
      }
    }
    setSwitchUserData(tempSwitchUserData);
    localStorage.setItem("switchUserData", JSON.stringify(tempSwitchUserData))
    localStorage.removeItem("project")
    // console.log('tempSwitchUserData45',tempSwitchUserData)
    console.log('switchAccountItem45', tempSwitchUserData)
    localStorage.setItem("user", JSON.stringify(tempSwitchUserData[0].userData));
    localStorage.setItem("token", tempSwitchUserData[0].token);
    window.location.reload();
  }


  // function menuToggle () {
  //   if (menuOpen) {
  //     setMenuOpen(false)
  //   } else {
  //     setMenuOpen(true)
  //   }
  // }

  function searchBarToggle() {
    if (searchBarOpen) {
      setSearchBarOpen(false)
    } else {
      setSearchBarOpen(true)
    }
  }


  async function getNotifications(user) {
    try {
      const data = {
        userid: user.id,
      };
      //   setLoader(true)
      const response = await post(BASE_URL + '/getnotifications', data)
      console.log('response234512', response)
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        setAllNotificationData(response.data.data)
        setNotificationUnreadCount(response.data.unreadCount)
        console.log('response234512345', response.data.unreadCount)
        console.log('response234512345', response.data.data)
      }
      else {
      }
    } catch (ex) {
      console.log('response', ex)
    }
  };



  async function verifyRegisterLink(user) {
    try {
      const data = {
        userid: user.id,
        email: user.email
      };
      //   setLoader(true)
      const response = await post(BASE_URL + '/sendemailconfirmationlink', data)
      console.log('response2345', response)
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        localStorage.setItem("user", JSON.stringify(response.data.data[0]));
        // localStorage.setItem("user", JSON.stringify(response.data.data[0]));
      }
      else {
      }

    } catch (ex) {
      console.log('response', ex)
    }
  };


  async function updateTaskOrder(taskData) {
    let taskOrderIds: any = ''
    let completeTaskOrderIds: any = ''
    for (let i = 0; i < taskData.length; i++) {
      if (selectedProjectID == taskData[i].projectId) {
        if (parseInt(taskData[i].taskStatus) == 0) {
          if (taskOrderIds != '') taskOrderIds += ','
          taskOrderIds += taskData[i].id
        } else if (parseInt(taskData[i].taskStatus) == 1) {
          if (completeTaskOrderIds != '') completeTaskOrderIds += ','
          completeTaskOrderIds += taskData[i].id
        }
      }
    }

    console.log('Order of tasks Ids', taskOrderIds)
    console.log('Order of complete Task Ids', completeTaskOrderIds)
    console.log('Order of projectId', selectedProjectID)
    let config = {
      projectID: selectedProjectID,
      taskOrder: taskOrderIds,
      completedTaskOrder: completeTaskOrderIds
    }

    try {
      const apiData = await post(BASE_URL + '/changetaskorder', config)
      try {
        let json = {};
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function updateTaskOrderForAI(taskData, projectIdVal) {
    let taskOrderIds: any = ''
    let completeTaskOrderIds: any = ''
    for (let i = 0; i < taskData.length; i++) {
      if (projectIdVal == taskData[i].projectId) {
        if (parseInt(taskData[i].taskStatus) == 0) {
          if (taskOrderIds != '') taskOrderIds += ','
          taskOrderIds += taskData[i].id
        } else if (parseInt(taskData[i].taskStatus) == 1) {
          if (completeTaskOrderIds != '') completeTaskOrderIds += ','
          completeTaskOrderIds += taskData[i].id
        }
      }
    }

    console.log('Order of tasks Ids', taskOrderIds)
    console.log('Order of complete Task Ids', completeTaskOrderIds)
    console.log('Order of projectId', projectIdVal)
    let config = {
      projectID: projectIdVal,
      taskOrder: taskOrderIds,
      completedTaskOrder: completeTaskOrderIds
    }

    try {
      const apiData = await post(BASE_URL + '/changetaskorder', config)
      try {
        let json = {};
        sendSocketMessage("update task", json)
        await getTask(userId)
      }
      catch (ex) {

      }
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function updategroupprojectOrdersForAI(projectDataItems, groupIdVal) {
    let OrderIds: any = ''
    for (let i = 0; i < projectDataItems.length; i++) {
          if (OrderIds != '') OrderIds += ','
          OrderIds += projectDataItems[i].id
    }

    console.log('Order of project Ids', OrderIds)
    console.log('Order of groupId', groupIdVal)
    let config = {
      groupId: groupIdVal,
      groupProjectOrder: OrderIds
    }

    try {
      const apiData = await post(BASE_URL + '/changegroupprojectorders', config)
      try {
        let json = {};
        sendSocketMessage("update task", json)
        await getProjectWithGroup(userId, 0)
      }
      catch (ex) {

      }
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function updategroupOrdersForAI(groupDataItems) {
    let OrderIds: any = ''
    for (let i = 0; i < groupDataItems.length; i++) {
          if (OrderIds != '') OrderIds += ','
          OrderIds += groupDataItems[i].id
    }

    console.log('Order of project Ids', OrderIds)
    let config = {
      groupOrderIds: OrderIds
    }

    try {
      const apiData = await post(BASE_URL + '/changegrouporders', config)
      try {
        let json = {};
        sendSocketMessage("update task", json)
        await getProjectWithGroup(userId, 0)
      }
      catch (ex) {

      }
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function updateProjectAndProjectGroupOrder(pdata, gdata, dIndId, dragId) {
    let projectOrderIds: any = ''
    let groupOrderIds: any = ''
    let otherUserlistNot: any = '';

    let newgroupData = [...groupData]
    let newProjectData = [...projectData]
    let dragItemUserId = "0";

    for (let i = 0; i < newProjectData.length; i++) {
      if (newProjectData[i].id == dragId) {
        dragItemUserId = newProjectData[i].userId
        break
      }
    }

    for (let i1 = 0; i1 < newgroupData.length; i1++) {
      for (let i = 0; i < newgroupData[i1].project.length; i++) {
        if (newgroupData[i1].project[i].id == dragId) {
          dragItemUserId = newgroupData[i1].project[i].userId
          break
        }
      }
    }
    console.log("dragItemUserId", dragItemUserId)

    for (let i = 0; i < pdata.length; i++) {
      if (projectOrderIds != '') projectOrderIds += ','
      projectOrderIds += pdata[i].id
      if (userData.id == pdata[i].userId) {
        if (otherUserlistNot != '') otherUserlistNot += ','
        otherUserlistNot += pdata[i].id
      }
    }
    let groupProjectOrder = [
      { id: userData.defaultProjectID, orderIds: projectOrderIds, otherUserlistNot: otherUserlistNot }
    ]

    for (let i = 0; i < gdata.length; i++) {
      if (groupOrderIds != '') groupOrderIds += ','
      groupOrderIds += gdata[i].id

      let pOrderIds = ''
      let otherUserPlistNot: any = '';
      for (let j = 0; j < gdata[i].project.length; j++) {
        if (pOrderIds != '') pOrderIds += ','
        {
          console.log('asdfgh', gdata[i])
          pOrderIds += gdata[i].project[j].id
        }

        if (userData.id == gdata[i].project[j].userId) {
          if (otherUserPlistNot != '') otherUserPlistNot += ','
          otherUserPlistNot += gdata[i].project[j].id
        }
      }
      if (pOrderIds != '')
        groupProjectOrder.push({ id: gdata[i].id, orderIds: pOrderIds, otherUserlistNot: otherUserPlistNot })
    }

    console.log('pdataOrder', groupProjectOrder)
    console.log('gdataOrder', groupOrderIds)
    let config = {
      userId: userData.id,
      groupProjectOrder: groupProjectOrder,
      groupOrderIds: groupOrderIds,
      dragListId: dragItemUserId != userId ? dragId : "0",
      dropGroupId: dragItemUserId != userId ? dIndId : "0",
      fromUserId: dragItemUserId,
      toUserId: userId, toUserName: Base64.encode(Base64.decode(userData.firstName) + " " + Base64.decode(userData.lastName)), toUserEmail: userData.email
    }

    try {
      const apiData = await post(
        BASE_URL + '/changegroupandprojectorderv22',
        config
      )
      console.log('apiData', apiData)
      try {
        let json = {};
        sendSocketMessage("changegroupandprojectorder", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  function changeStatus(item: any) {
    // getTask(userId);
    if (item.taskStatus == '0') {
      item.taskStatus = '1'
    } else {
      item.taskStatus = '0'
    }
    let taskId = item.id
    let taskStatus = item.taskStatus
    completeTask(taskId, taskStatus)
    console.log('itemData', item)
    let itemData = item
    console.log('itemData1', itemData)

    let data: any = [...taskData]
    console.log('1234', data.length)

    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == itemData.id) {
        data[0][i].taskStatus = itemData.taskStatus
        let newtaskdata: any = []
        if (itemData.taskStatus == 0)
          newtaskdata = rearangeArr(data[0], i, 0)
        else newtaskdata = rearangeArr(data[0], i, 0)

        setTaskData([newtaskdata])
        updateTaskOrder(newtaskdata)
      }
    }

    CompletedStatusCount(data[0])
  }

  async function changeTrunIntoHeading(item) {
    console.log('item', item)
    console.log('dataitem', dataItem)
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == dataItem.id) {
        data[0][i].taskHeading = '1'
        data[0][i].taskNote = '0'
        data[0][i].taskBug = '0'
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/updatetask', dataItem)
      try {
        let json = { taskId: dataItem.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      console.log('response232323', response)
      addTaskUpdateByOtherUser(dataItem, 'turnIntoHeading')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function changeTrunIntoTask(item) {
    console.log('item', item)
    console.log('dataitem', dataItem)
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == dataItem.id) {
        data[0][i].taskHeading = '0'
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/updatetask', dataItem)
      try {
        let json = { taskId: dataItem.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      console.log('response232323', response)
      addTaskUpdateByOtherUser(dataItem, 'turnIntoTask')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function addListIntoProject(currentgroupData, isEdit = 1) {
    let allgroupData = [...groupData]

    console.log('test1', allgroupData)
    let config = {
      userId: userId,
      projectName: encData(
        projectsNewListInputValue != ''
          ? projectsNewListInputValue
          : 'Untitled List'
      ),
      projectGroupId: currentgroupData.id,
      isNote: 0
    }
    console.log('test2', config)

    try {
      // console.log('config', config)
      const apiData = await post(BASE_URL + '/addproject', config)
      if (apiData.data.status == 'success') {
        try {
          let json = { projectId: apiData.data.data[0].id };
          sendSocketMessage("addproject", json)
        }
        catch (ex) {

        }
        console.log('apiData', apiData.data.data[0])
        if (isEdit == 0) {
          setEditList({})
          console.log('test1145', apiData.data.data[0])
        }
        else {
          setEditList(apiData.data.data[0])
        }

        for (let i = 0; i < allgroupData.length; i++) {
          if (allgroupData[i].id == currentgroupData.id) {
            allgroupData[i].project.push(apiData.data.data[0])
            setGroupData(allgroupData)
            groupDataRef.current = allgroupData
          }
        }
        // getProjectWithGroup(userId, 0)
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function addListIntoProjectForAI(currentgroupData, listNameInput, isEdit = 1) {
    let allgroupData = [...groupData]

    console.log('test1', allgroupData)
    let config = {
      userId: userId,
      projectName: encData(listNameInput),
      projectGroupId: currentgroupData.id,
      isNote: 0
    }
    console.log('test2', config)

    try {
      // console.log('config', config)
      const apiData = await post(BASE_URL + '/addproject', config)
      if (apiData.data.status == 'success') {
        try {
          let json = { projectId: apiData.data.data[0].id };
          sendSocketMessage("addproject", json)
        }
        catch (ex) {

        }
        console.log('apiData', apiData.data.data[0])
        if (isEdit == 0) {
          setEditList({})
          console.log('test1145', apiData.data.data[0])
        }
        else {
          setEditList(apiData.data.data[0])
        }

        for (let i = 0; i < allgroupData.length; i++) {
          if (allgroupData[i].id == currentgroupData.id) {
            allgroupData[i].project.push(apiData.data.data[0])
            setGroupData(allgroupData)
            groupDataRef.current = allgroupData
          }
        }
        // getProjectWithGroup(userId, 0)
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function renameListForMobileFun() {
    // if (selectedProjectID == editListData.id) {
    //   setSelectedProjectName(editListData.projectName)
    // }
    if (renameListInputValue == "") {
      return;
    }
    let tempJsonItem = {}
    if (dataItem.orginalProjectGroupId == '0') {
      console.log('renameListInputValue1', renameListInputValue)
      let allListData = [...projectData]
      for (let i = 0; i < allListData.length; i++) {
        if (allListData[i].id == dataItem.id) {
          allListData[i].projectName = encData(renameListInputValue)
          tempJsonItem = allListData[i]
          setListItem(allListData[i])
          listItemRef.current = allListData[i]
          setSelectedProject(allListData[i].id, allListData[i].projectName)
          localStorage.setItem('project', JSON.stringify(allListData[i]))
          console.log('allListData12', allListData[i])
        }
      }
      console.log('allListData123', allListData)
      setProjectData(allListData)
      projectDataRef.current = allListData
    }
    else {
      let allGroupData = [...groupData]
      for (let i = 0; i < allGroupData.length; i++) {
        if (allGroupData[i].id == dataItem.orginalProjectGroupId) {
          for (let j = 0; j < allGroupData[i].project.length; j++) {
            if (
              allGroupData[i].project[j].id ==
              dataItem.id
            ) {
              allGroupData[i].project[j].projectName = encData(renameListInputValue)
              tempJsonItem = allGroupData[i].project[j]
              setListItem(allGroupData[i].project[j])
              listItemRef.current = allGroupData[i].project[j]
              setSelectedProject(allGroupData[i].project[j].id, allGroupData[i].project[j].projectName)
              localStorage.setItem('project', JSON.stringify(allGroupData[i].project[j]))
              setGroupData(allGroupData)
              groupDataRef.current = allGroupData
              break
            }
          }
        }
      }
    }

    try {
      const response = await post(BASE_URL + '/updateproject', tempJsonItem)
      console.log('response232323', response)
      try {
        let json = { projectId: dataItem.id };
        sendSocketMessage("updateproject", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function addNewProjectForMobileFun() {
    if (renameListInputValue == "") {
      return;
    }
    let config = {
      userId: userId,
      groupName: encData(renameListInputValue)
    }
    try {
      console.log('config', config)
      const apiData = await post(BASE_URL + '/addprojectgroup', config)
      console.log('apiData456', apiData)
      if (apiData.data.status == 'success' && apiData.data.data) {
        try {
          let json = { groupId: apiData.data.data[0].id };
          sendSocketMessage("addprojectgroup", json)
        }
        catch (ex) {

        }
        getProjectWithGroup(userId, 1)
        setSelectedFolderItem(apiData.data.data[0])
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function renameGroupForMobileFun() {
    if (renameListInputValue == "") {
      return;
    }
    let allgroupData = [...groupData]
    console.log("dataItem45", dataItem)
    let tempJsonItem = {};
    for (let i = 0; i < allgroupData.length; i++) {
      if ((allgroupData[i].id == dataItem.id)) {
        allgroupData[i].groupName = encData(renameListInputValue)
        tempJsonItem = allgroupData[i];
        allgroupData[i].isEncrypt = '1'
      }
    }
    setGroupData(allgroupData)
    groupDataRef.current = allgroupData


    try {
      const response = await post(
        BASE_URL + '/updateprojectgroup',
        tempJsonItem
      )
      console.log('response232323', response)
      try {
        let json = { groupId: dataItem.id };
        sendSocketMessage("updateprojectgroup", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }

  }


  async function renameList(editListData: any, i1) {
    setEditList({})
    if (selectedProjectID == editListData.id) {
      setSelectedProjectName(editListData.projectName)
    }

    if (editListData.orginalProjectGroupId == '0') {
      let allListData = [...projectData]
      allListData[i1].projectName = editListData.projectName
      allListData[i1].isEncrypt = editListData.isEncrypt
      setProjectData(allListData)
      projectDataRef.current = allListData
    } else {
      console.log('editListData', editListData)
      let allGroupData = [...groupData]
      for (let i = 0; i < allGroupData.length; i++) {
        if (allGroupData[i].id == editListData.orginalProjectGroupId) {
          for (let j = 0; j < allGroupData[i].project.length; j++) {
            if (
              allGroupData[i].project[j].orginalProjectGroupId ==
              editListData.orginalProjectGroupId
            ) {
              allGroupData[i].project[i1].projectName = editListData.projectName
              allGroupData[i].project[i1].isEncrypt = editListData.isEncrypt
              setGroupData(allGroupData)
              groupDataRef.current = allGroupData
              break
            }
          }
        }
      }
    }

    try {
      const response = await post(BASE_URL + '/updateproject', editListData)
      console.log('response232323', response)
      try {
        let json = { projectId: editListData.id };
        sendSocketMessage("updateproject", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function renameGroup(editGroupData, i1) {
    console.log('editGroupData', editGroupData)
    setEditGroup({})
    let allgroupData = [...groupData]

    /*   allgroupData[i1].groupName = editGroupData.groupName
      setGroupData(allgroupData) */

    if (i1 != -1) {
      allgroupData[i1].groupName = editGroupData.groupName
      allgroupData[i1].isEncrypt = '1'
      setGroupData(allgroupData)
      groupDataRef.current = allgroupData
    } else {
      for (let i = 0; i < allgroupData.length; i++) {
        if ((allgroupData[i].id = editGroupData.id)) {
          allgroupData[i].groupName = editGroupData.groupName
          allgroupData[i].isEncrypt = '1'
        }
      }
      setGroupData(allgroupData)
      groupDataRef.current = allgroupData
    }

    try {
      const response = await post(
        BASE_URL + '/updateprojectgroup',
        editGroupData
      )
      console.log('response232323', response)
      try {
        let json = { groupId: editGroupData.id };
        sendSocketMessage("updateprojectgroup", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
    setEditGroup({})
  }

  const taskDeleteItemRef: any = useRef({})

  function cheboxFun() {
    if (checked.current == true) {
      checked.current = false
    } else {
      checked.current = true
    }
    console.log('checked', checked)
  }

  const addDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      // alert('clicked no')
      onClose()
    }
    const handleClickedYes = () => {
      onClose()
      if (taskDeleteItemRef.current.isTrash == "1") {
        console.log('test4512')
        moveToTrash(taskDeleteItemRef.current)
      }
      else {
        deleteTask(taskDeleteItemRef.current)
      }
      // 03/05
      console.log('checked', checked)
      if (checked.current == true) {
        localStorage.setItem('date', currentDate)
      }
      // 03/05
    }
    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Delete Forever</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p>
              Are you sure you want to permanently delete task?
            </p>

            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedYes}>
                Delete
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Cancle</button>
            </div>
          </div>
        </div>

      </>
    )
  }

  const handleDeleteConfirm = (taskForDetele: any) => {
    taskDeleteItemRef.current = taskForDetele
    confirmAlert({ customUI: addDialog })
  }

  const DeleteAllCompleteTaskDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = () => {
      onClose()
      deleteAllCompletedTask()
    }
    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Delete all completed tasks.</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p>
              Are you sure you want to move to trash all completed task.
            </p>

            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedYes}>
                Delete
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Cancle</button>
            </div>
          </div>
        </div>

      </>
    )
  }

  const handleDeleteAllConfirm = () => {
    confirmAlert({ customUI: DeleteAllCompleteTaskDialog })
  }

  async function deleteAllCompletedTask() {
    const json = {
      projectId: selectedProjectID
    }
    try {
      let data: any = [...taskMainData]
      console.log('data', data)
      let deletei: any = []
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].projectId == selectedProjectID &&
          data[i].taskStatus == '1'
        ) {
          deletei.push(i)
          data.splice(i, 1)
          i--
        }
      }

      if (taskItem && taskItem.taskStatus && taskItem.taskStatus == '1')
        setTaskSectionOpen(false)
      setTaskMainData(data)
      setTaskData([data])

      const response = await post(BASE_URL + '/deletecompletetask', json)
      console.log('response232323', response)
      try {
        let json = {};
        sendSocketMessage("deletecompletetask", json)
      }
      catch (ex) {

      }

      if (response.data.status == 'success') {
        getTask(userId)
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function deleteTask(taskForDetele: any) {
    const json = {
      taskid: taskForDetele.id
    }
    try {
      const response = await post(BASE_URL + '/deletetask', json)
      console.log('response232323', response)
      console.log('taskItem.index', taskForDetele)
      if (response.data.status == 'success') {
        try {
          let json = {};
          sendSocketMessage("deletetask", json)
        }
        catch (ex) {

        }
        let data: any = [...taskMainData]
        console.log('data', data)
        let delData: any = []
        let del = -1
        let isOpenSideBar = taskSectionOpen
        if (taskItem && taskForDetele.id == taskItem.id)
          setTaskSectionOpen(false)
        try {
          for (let i = 0; i < data.length; i++) {
            if (data[i].id == taskForDetele.id) {
              data.splice(i, 1)
              delData = data[i]
              del = i
            }


            if (
              del != -1 &&
              delData.taskStatus == data[i].taskStatus &&
              selectedProjectID == data[i].projectId &&
              isOpenSideBar &&
              taskItem &&
              taskForDetele.id == taskItem.id
            ) {
              console.log('delData.taskStatus4545')
              setTaskItem(data[i])
              taskItemRef.current = data[i]
              setTaskSectionOpen(true)
              break
            }
          }
        }
        catch (ex) {

        }

        console.log('finaldata', data)
        getTask(userId)
        setTaskMainData(data)
        setTaskData([data])
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function moveToTrash(item) {
    console.log('item', item)
    console.log('dataitem', dataItem)
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        data[0][i].isTrash = '2'
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    setTaskSectionOpen(false)
    if (mobileView) {
      rightPaneMobileBack()
      // slideType.current="left";
    }
    try {
      const response = await post(BASE_URL + '/updatetask', item)
      try {
        let json = { taskId: item.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      getTrashTask(userId)
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function restoreTrashTask(item) {
    console.log('item', item)
    console.log('dataitem', dataItem)
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        data[0][i].isTrash = '1'
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/updatetask', item)
      try {
        let json = { taskId: item.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      console.log('response232323', response)
      getTask(userId);
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function restoreTask(item) {
    console.log('item3216', item)
    // console.log('dataitem', dataItem)
    // let data = [...taskData]
    // for (let i = 0; i < data[0].length; i++) {
    //   if (data[0][i].id == item.id) {
    //      data[0][i].isTrash = '2'
    //   }
    // }
    // setTaskData(data)
    // setTaskMainData(data[0])
    const json = {
      taskId: item.id,
    }
    try {
      const response = await post(BASE_URL + '/restoretask', json)
      console.log('response232323', response)
      getProjectWithGroup(userId, 1)
      getTask(userId);
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function restoreList(item) {
    console.log('item3216', item)
    // console.log('dataitem', dataItem)
    // let data = [...taskData]
    // for (let i = 0; i < data[0].length; i++) {
    //   if (data[0][i].id == item.id) {
    //      data[0][i].isTrash = '2'
    //   }
    // }
    // setTaskData(data)
    // setTaskMainData(data[0])
    const json = {
      projectId: item.id,
    }
    try {
      const response = await post(BASE_URL + '/restoreproject', json)
      console.log('response232323', response)
      await getProjectWithGroup(userId, 0)
      setSelectedProject(Base64.encode("1"), 'Trash')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function duplicateTask(item) {
    console.log('item45', item);
    let tempTaskName = item.taskName + " (copy)";
    if (item.isEncrypt == "1") {
      tempTaskName = encData(decData(item.taskName, item.isEncrypt) + " (copy)");
    }
    try {
      const data = {
        taskId: item.id,
        taskName: tempTaskName
      }

      const response = await post(BASE_URL + '/duplicatetask', data)
      console.log('response23', response)
      if (response.data.status == 'success') {
        try {
          let json = { taskId: item.id };
          sendSocketMessage("duplicate task", json)
        }
        catch (ex) {

        }
        getTask(userId)

        /* 
             let data: any = [...taskData]
             console.log('1234', data.length)
              data.push(response.data.data[0])
         
                new  newtaskdata = rearangeArr(data[0], data[0].length - 1, 0)
         
            
        
         */
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function duplicateList(item) {
    console.log('item45', item);
    let tempListName = item.projectName + " (copy)";
    if (item.isEncrypt == "1") {
      tempListName = encData(decData(item.projectName, item.isEncrypt) + " (copy)");
    }
    try {
      const data = {
        id: item.id,
        projectName: tempListName
      }
      console.log('data', data)
      if (item.orginalProjectGroupId == "0") {
        setDuplicateListLoader(true)
      }
      else {
        setDuplicateGroupListLoader(true)
      }
      const response = await post(BASE_URL + '/duplicatelist', data)
      console.log('response23', response)
      if (response.data.status == 'success') {
        try {
          let json = { projectId: item.id };
          sendSocketMessage("duplicatelist", json)
        }
        catch (ex) {

        }
        getTask(userId)
        getProjectWithGroup(userId, 0)
        setDuplicateListLoader(false)
        setDuplicateGroupListLoader(false)

      } else {
        setDuplicateListLoader(false)
        setDuplicateGroupListLoader(false)
      }
    } catch (ex) {
      console.log('response9211', ex)
      setDuplicateListLoader(false)
      setDuplicateGroupListLoader(false)
    }
  }

  async function showTaskId(item) {
    if (item.showTaskId == '0') {
      item.showTaskId = '1'
    } else {
      item.showTaskId = '0'
    }
    localStorage.setItem('project', JSON.stringify(item))
    try {
      const response = await post(BASE_URL + '/updateproject', item)
      console.log('response232323', response)
      try {
        let json = { projectId: item.id };
        sendSocketMessage("updateproject", json)
      }
      catch (ex) {

      }
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        let tempItem = response.data.data[0]
        console.log('tempItem', tempItem)
        getProjectWithGroup(userId, 0)
        localStorage.setItem('project', JSON.stringify(tempItem))
        return
        if (tempItem && tempItem.taskName) {
          let data = [...taskData]
          for (let i = 0; i < data[0].length; i++) {
            if (data[0][i].id == item.id) {
              /* if (item.taskShowId == '0') {
          data[0][i].taskShowId = '1'
        } else {
          data[0][i].taskShowId = '0'
        } */
              data[0][i].showTaskId = tempItem.taskShowId
              data[0][i].taskKey = tempItem.taskKey
            }
          }
          setTaskData(data)
          setTaskMainData(data[0])
        }
      }
      //getTask(userId)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  const ReadOnlyListRef: any = useRef({})

  const DeleteReadOnlyListDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      onClose()
      await readOnlyFun(ReadOnlyListRef.current)
      getTask(userId)
    }

    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Read only</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p>
              You will still be able to modify tasks, however, anyone you share this list with will not be able to modify (add/remove tasks etc.).
            </p>

            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 155 }}
                onClick={handleClickedYes}>
                Make Read Only
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Cancle</button>
            </div>
          </div>
        </div>

      </>
    )
  }

  const handleReadOnlyConfirm = item => {
    console.log('item4556', item)
    ReadOnlyListRef.current = item
    confirmAlert({ customUI: DeleteReadOnlyListDialog })
  }


  async function readOnlyFun(item) {
    console.log('item751', item)
    if (item.readOnlyStatus == '0') {
      item.readOnlyStatus = '1'
    } else {
      item.readOnlyStatus = '0'
    }
    console.log('item75', item)
    localStorage.setItem('project', JSON.stringify(item))
    try {
      const response = await post(BASE_URL + '/updateproject', item)
      console.log('response232323', response)
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        try {
          let json = { projectId: item.id };
          sendSocketMessage("updateproject", json)
        }
        catch (ex) {

        }
        let tempItem = response.data.data[0]
        console.log('tempItem', tempItem)
        getProjectWithGroup(userId, 0)
        localStorage.setItem('project', JSON.stringify(tempItem))
        return
        if (tempItem && tempItem.taskName) {
          let data = [...taskData]
          for (let i = 0; i < data[0].length; i++) {
            if (data[0][i].id == item.id) {
              /* if (item.taskShowId == '0') {
          data[0][i].taskShowId = '1'
        } else {
          data[0][i].taskShowId = '0'
        } */
              data[0][i].readOnlyStatus = tempItem.readOnlyStatus
              // data[0][i].taskKey = tempItem.taskKey
            }
          }
          setTaskData(data)
          setTaskMainData(data[0])
        }
      }
      //getTask(userId)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  function addTaskToList() {
    console.log('taskInputValue', taskInputValue)
    if (taskInputValue != '') {
      postTask(taskInputValue, selectedProjectID)

      // let json: any = {
      //   id: taskData.length + 1,
      //   taskTitle: taskInputValue,
      //   task: [],
      //   status: 0,
      //   category: 1
      // }
      // let data = [...taskData]
      // data.push(json)
      // setTaskData(data)
      // console.log('data', data)
      // console.log('taskData456', taskData)
      setTaskInputValue('')
    }
  }
  async function addProjectToList() {
    console.log('projectsInputValue', projectsInputValue)
    if (projectsInputValue != '') {
      let config = {
        userId: userId,
        projectName: encData(projectsInputValue)
      }
      try {
        console.log('config', config)
        const apiData = await post(BASE_URL + '/addproject', config)
        console.log('apiData', apiData)
        if (apiData.data.status == 'success') {
          try {
            let json = { projectId: apiData.data.data[0].id };
            sendSocketMessage("addproject", json)
          }
          catch (ex) {

          }
          setProjectsInputValue('')
          getProjectWithGroup(userId, 0)
        } else {
        }
      } catch (ex) {
        console.log('response9211', ex)
      }
    }
  }
  async function setSelectedProject(projectId, projectname) {
    setSelectedProjectID(projectId)
    setSelectedProjectName(projectname)
  }

  async function addGroups() {
    let config = {
      userId: userId,
      groupName: encData(groupInputValue)
    }
    try {
      console.log('config', config)
      const apiData = await post(BASE_URL + '/addprojectgroup', config)
      console.log('apiData456', apiData)
      if (apiData.data.status == 'success' && apiData.data.data) {
        try {
          let json = { groupId: apiData.data.data[0].id };
          sendSocketMessage("addprojectgroup", json)
        }
        catch (ex) {

        }
        getProjectWithGroup(userId, 1)
        setSelectedFolderItem(apiData.data.data[0])
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function addGroupsForAI(groupNameVal) {
    let config = {
      userId: userId,
      groupName: encData(groupNameVal)
    }
    try {
      console.log('config', config)
      const apiData = await post(BASE_URL + '/addprojectgroup', config)
      console.log('apiData456', apiData)
      if (apiData.data.status == 'success' && apiData.data.data) {
        try {
          let json = { groupId: apiData.data.data[0].id };
          sendSocketMessage("addprojectgroup", json)
        }
        catch (ex) {

        }
      await  getProjectWithGroup(userId, 1)
        setSelectedFolderItem(apiData.data.data[0])
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  function openGroup(item) {
    if (item.open) {
      item.open = false
    } else {
      item.open = true
    }
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].open = item.open
      }
    }
    setGroupData(data)
    groupDataRef.current = data
    console.log('item445', item)
  }
  function openGroupOnNewListAdd(item) {
    if (item.open) {
      item.open = true
    } else {
      item.open = true
    }
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].open = item.open
      }
    }
    setGroupData(data)
    groupDataRef.current = data
    console.log('item445', item)
  }
  function addProjectNewList(item) {
    if (item.addNewList) {
      item.addNewList = false
      setAddGroupList(false)
    } else {
      item.addNewList = true
      setAddGroupList(true)
    }
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].addNewList = item.addNewList
      }
    }
    setGroupData(data)
    groupDataRef.current = data
    console.log('item445', item)
  }
  function openRightList(item) {
    console.log('item1010', item)
  }

  function deleteProject(item: any) {
    let mainData = [...projectData]
    for (let i = 0; i < mainData.length; i++) {
      if (mainData[i].id == item.id) {
        // console.log(
        //   'taskItem.task[i].slice(0,1)',
        //   mainData[i].splice(i, 1)
        // )
        // console.log('taskItem123', mainData[i])
        mainData.splice(i, 1)
      }
    }
    setProjectData(mainData)
    projectDataRef.current = mainData
  }

  const handleShareAllUser = (type, id) => {

  }




  const handleChangeShareInput = (event: { target: { value: React.SetStateAction<string> } }) => {
    console.log('e.target.value11', event.target.value)
    console.log('e.target.value.length11', event.target.value.length)
    if (event.target.value != '') {
      setUserListDivShow(true)
    }
    else {
      setUserListDivShow(false)
    }
    setShareListInputValue(event.target.value)
    let data: any = [...allUser]
    let newData: any = []
    let eventValue: any = event.target.value
    if (event.target.value !== '') {
      for (let i = 0; i < data.lengthl; i++) {
        data[i].email = Base64.decode(data[i].email)
      }
      newData.push(data)
      console.log('newData12', newData)
      console.log('newData123', data)
      const filteredData: any = data.filter(item => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(eventValue.toLowerCase())
      })
      console.log('filteredData123', filteredData)
      let tempAllInvitation = [...allInvitation];
      let mainFilterArray: any = [];
      for (let i = 0; i < filteredData.length; i++) {
        let status = false;
        for (let j = 0; j < tempAllInvitation.length; j++) {
          if (filteredData[i].email == Base64.decode(tempAllInvitation[j].toUserEmail) || filteredData[i].email == Base64.decode(tempAllInvitation[j].fromUserEmail)) {
            status = true;
            break;
          }
        }
        if (status) {
          mainFilterArray.push(filteredData[i]);
        }

      }
      setAllUserFilter(mainFilterArray)
      console.log('mainFilterArray12', mainFilterArray)
    } else {
      setAllUserFilter([]);
    }
  }

  const handleKeyDownShareInput = (
    event: { key: any; target: any }
  ) => {
    if (event.key === 'Enter') {
      if (mobileView) {
        setShareConfirmPopUpMobileDialog(true)
      }
      personClick()
      return;
    }
  }
  function personClick() {
    if (shareListInputValue == "") {
      return;
    }
    if (allUserFilter.length > 0) {
      shareListUser(allUserFilter[0])
    }
    else {
      let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (!pattern.test(shareListInputValue)) {
        // alert("Invalid email format");
        setMultiSnackbarMsg('Invalid email format')
        // handleClickCopiedSnackBar(SlideTransition)
        handleClickMultiSnackBar(SlideTransition)

      }
      else {
        let val = shareListInputValue;
        let item = { email: shareListInputValue.toLowerCase(), firstName: "", lastName: "" };
        shareListUser(item);
        setShareListInputValue("");
      }
    }
  }

  function shareListUser(item: any) {
    setShareListInputValue('')
    setUserListDivShow(false)

    let tempSharedData: any = []
    let tempSharedData1 = [...sharedListData]
    let status = "0";
    let tempSharedUserList = [...sharedUserList];

    let tempAllShareUser = [...allShareUser]
    for (let i = 0; i < tempAllShareUser.length; i++) {
      if (tempAllShareUser[i].email == item.email) {
        tempAllShareUser.splice(i, 1);
      }
    }
    tempAllShareUser.sort((a, b) => a.firstName.localeCompare(b.firstName));
    setAllShareUser(tempAllShareUser);

    let tempItem: any = {
      "fromUserId": userData.id, "toUserId": "0",
      "fromUserEmail": Base64.decode(userData.email),
      "toUserEmail": item.email, "toUserName": item.firstName ? item.firstName + " " + item.lastName : item.email, "type": selectedShareTypeRef.current,
      "projectId": selectedShareTypeRef.current == "project" ? listItem.id : "0",
      "projectGroupId": selectedShareTypeRef.current == "group" ? groupItem.id : "0", "taskId": "0", "isAccept": "1"
    };
    if (tempSharedData1.length > 0 || tempSharedUserList.length > 0) {
      console.log('tempSharedData', tempSharedData1)
      for (let i = 0; i < tempSharedData1.length; i++) {
        if (tempSharedData1[i].toUserEmail == item.email) {
          status = "1"
        }

      }

      for (let j = 0; j < tempSharedUserList.length; j++) {
        if (tempSharedUserList[j].toUserEmail == item.email) {
          status = "1"
        }

      }

      if (status == "0") {
        tempSharedData1.push(tempItem)
      }
      else {
        setMultiSnackbarMsg('Email already in invite list.')
        handleClickMultiSnackBar(SlideTransition)
      }
      setSharedListData(tempSharedData1)



    }
    else {
      console.log('test5')
      tempSharedData.push(tempItem)
      setSharedListData(tempSharedData)
    }

  }

  function handleRemoveShareListUser(item) {
    var tempSharedData = [...sharedListData]
    for (let i = 0; i < tempSharedData.length; i++) {
      if (tempSharedData[i].toUserEmail == item.toUserEmail) {
        tempSharedData.splice(i, 1)
      }
    }
    setSharedListData(tempSharedData)
    console.log('tempSharedData', tempSharedData)
  }

  function handleDeleteShareListUser(item) {

    var tempSharedData = [...sharedUserList]

    for (let i = 0; i < tempSharedData.length; i++) {
      if (tempSharedData[i].toUserEmail == item.toUserEmail) {
        tempSharedData.splice(i, 1)
      }
    }
    setSharedUserList(tempSharedData)
    // if (tempSharedData && tempSharedData.length > 0) {
    //   tempSharedData.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
    //     : a.toUserEmail.localeCompare(b.toUserEmail));
    // }


    console.log('item567', item)

    let data: any = [...allUser]
    let tempAllShareUser = [...allShareUser]
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].email.toLowerCase() == item.toUserEmail.toLowerCase()) {
          tempAllShareUser.splice(0, 0, data[i])
          break;
        }
      }
    }

    // tempAllShareUser.sort((a, b) => a.firstName.localeCompare(b.firstName));
    setAllShareUser(tempAllShareUser)

    let tempAllShareData1: any = [...tempSharedData, ...tempAllShareUser]
    if (!isSortAlpha) {
      tempAllShareData1.sort((a, b) => a.isAccept - b.isAccept);
    }
    else {
      tempAllShareData1.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
        : a.toUserEmail.localeCompare(b.toUserEmail));
    }
    setCombineAllShareUser(tempAllShareData1)

    // allShareUser
    deleteUserShare([item]);
  }

  const deleteUserShare = async (data: any) => {
    setMultiSnackbarMsg('Invitation removed...')
    // handleClickInviteRemoveSnackBar(SlideTransition)
    handleClickMultiSnackBar(SlideTransition)
    let item = [...data]

    try {
      const response = await post(BASE_URL + '/deleteshare', { shareData: data })
      console.log('response23', response)
      if (response.data.status == 'success') {
        try {
          let json = {};
          sendSocketMessage("setinvitation", json)
        }
        catch (ex) {

        }
        getUserShare(userId);
        try {
          getAllInvitations(userId, userData.email)
        }
        catch (ex) {

        }
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  function inviteShareUser(item) {
    let tempAllShareUser = [...allShareUser]
    for (let i = 0; i < tempAllShareUser.length; i++) {
      if (tempAllShareUser[i].id == item.id) {
        tempAllShareUser.splice(i, 1);
      }
    }
    tempAllShareUser.sort((a, b) => a.firstName.localeCompare(b.firstName));
    setAllShareUser(tempAllShareUser);
    setMultiSnackbarMsg('Invited')
    handleClickMultiSnackBar(SlideTransition)

    console.log("test45451rewrwerwr", item)

    let tempItemdata = [{
      "fromUserId": userData.id, "toUserId": item.id, "fromUserEmail": Base64.decode(userData.email),
      "toUserEmail": item.email, "toUserName": item.firstName + " " + item.lastName, "type": selectedShareTypeRef.current,
      "projectId": selectedShareTypeRef.current == "project" ? listItem.id : "0", "projectGroupId": selectedShareTypeRef.current == "group" ? groupItem.id : "0", "pname": selectedShareTypeRef.current == "project" ? decData(listItem.projectName, listItem.isEncrypt) :
        selectedShareTypeRef.current == "group" ? decData(groupItem.groupName, groupItem.isEncrypt) : "", "taskId": "0", "isAccept": "1"
    }]


    if (sharedUserList && sharedUserList.length > 0) {
      let tempSharedUserList = [...sharedUserList]
      let tempsharedListData = [...tempItemdata]

      let tempData: any = []

      tempData = [...tempsharedListData, ...tempSharedUserList]
      if (tempData && tempData.length > 0) {
        tempData.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
          : a.toUserEmail.localeCompare(b.toUserEmail));
      }

      setSharedUserList(tempData)
      let tempAllShareData1: any = [...tempData, ...tempAllShareUser]

      if (!isSortAlpha) {
        tempAllShareData1.sort((a, b) => a.isAccept - b.isAccept);
      }
      else {
        tempAllShareData1.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
          : a.toUserEmail.localeCompare(b.toUserEmail));
      }
      setCombineAllShareUser(tempAllShareData1)
      console.log("test4545", tempsharedListData)
      console.log("test45451", tempSharedUserList)
      postUserShare(tempsharedListData)
      //postShareEmail(tempsharedListData1, selectedShareTypeRef.current=="group"? "project":"list")

    }
    else {
      let tempsharedListData = [...tempItemdata]
      if (tempsharedListData && tempsharedListData.length > 0) {
        tempsharedListData.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
          : a.toUserEmail.localeCompare(b.toUserEmail));
        setSharedUserList(tempsharedListData)
        let tempAllShareData1: any = [...tempsharedListData, ...tempAllShareUser]
        if (!isSortAlpha) {
          tempAllShareData1.sort((a, b) => a.isAccept - b.isAccept);
        }
        else {
          tempAllShareData1.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
            : a.toUserEmail.localeCompare(b.toUserEmail));
        }
        setCombineAllShareUser(tempAllShareData1)
        postUserShare(tempsharedListData)
      }



      //postShareEmail(tempsharedListData1, selectedShareTypeRef.current=="group"? "project":"list")

    }
  }



  function inviteUser() {
    setShareListInputValue('')
    setSharedListData([])




    if (sharedUserList && sharedUserList.length > 0) {
      let tempSharedUserList = [...combineAllShareUser]
      let tempsharedListData = [...sharedListData]

      let tempData: any = []

      tempData = [...tempsharedListData, ...tempSharedUserList]
      if (tempData && tempData.length > 0) {
        if (!isSortAlpha) {
          tempData.sort((a, b) => a.isAccept - b.isAccept);
        }
        else {
          tempData.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
            : a.toUserEmail.localeCompare(b.toUserEmail));
        }
      }


      setCombineAllShareUser(tempData)

      console.log("test4545", tempsharedListData)
      console.log("test45451", tempSharedUserList)
      let tempsharedListData1 = [...sharedListData]
      postUserShare(tempsharedListData1)

      if (tempsharedListData1.length > 0) {
        if (checkEmailIsNotIntrested(tempsharedListData1[0].toUserEmail)) {
          setMultiSnackbarMsg('User Not Intrested.')
        }
        else {
          setMultiSnackbarMsg('Invited')
        }
        handleClickMultiSnackBar(SlideTransition)
      }
      //postShareEmail(tempsharedListData1, selectedShareTypeRef.current=="group"? "project":"list")

    }
    else {
      let tempsharedListData = [...combineAllShareUser]
      if (tempsharedListData && tempsharedListData.length > 0) {
        if (!isSortAlpha) {
          tempsharedListData.sort((a, b) => a.isAccept - b.isAccept);
        }
        else {
          tempsharedListData.sort((a, b) => a.toUserName.trim() != '' ? a.toUserName.localeCompare(b.toUserName)
            : a.toUserEmail.localeCompare(b.toUserEmail));
        }
      }
      setCombineAllShareUser(tempsharedListData)
      let tempsharedListData1 = [...sharedListData]
      console.log("test45451", JSON.stringify(tempsharedListData1))
      postUserShare(tempsharedListData1)
      if (tempsharedListData1.length > 0) {
        if (checkEmailIsNotIntrested(tempsharedListData1[0].toUserEmail)) {
          setMultiSnackbarMsg('User Not Intrested.')
        }
        else {
          setMultiSnackbarMsg('Invited')
        }
        handleClickMultiSnackBar(SlideTransition)
      }
      //postShareEmail(tempsharedListData1, selectedShareTypeRef.current=="group"? "project":"list")

    }
  }

  function checkEmailIsNotIntrested(email) {
    let tempNotInterestedData = [...notInterestedData]
    let status = false;
    for (let i = 0; i < tempNotInterestedData.length; i++) {
      if (Base64.decode(tempNotInterestedData[i].toUserEmail) == email) {
        status = true;
        break;
      }
    }
    return status;
  }



  function checkEmailIsOnMs(email) {
    let status = false;
    let tempData = [...allUser];
    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i].email.toLowerCase() == email.toLowerCase()) {
        status = true;
        break;
      }
    }
    return status;
  }
  const postShareEmail = async (data: any, type, shareId: any) => {



    try {
      let emailIds: any = "";
      let emailMSIds: any = "";
      for (let i = 0; i < data.length; i++) {
        if (checkEmailIsOnMs(data[i].toUserEmail)) {
          emailMSIds = emailMSIds + data[i].toUserEmail + ",";
        }
        else {
          emailIds = emailIds + data[i].toUserEmail + ",";
        }
      }
      var len = emailIds.length;
      if (emailIds.substr(len - 1, 1) == ",") {
        emailIds = emailIds.substring(0, len - 1);
      }
      var len = emailMSIds.length;
      if (emailMSIds.substr(len - 1, 1) == ",") {
        emailMSIds = emailMSIds.substring(0, len - 1);
      }
      let code: any = {
        "fromUserId": userData.id, "toUserId": "0",
        "toUserEmail": "", "type": type,
        "projectId": data[0].projectId,
        "projectGroupId": data[0].projectGroupId, "taskId": "0", "isAccept": "1"
      };
      console.log("emailIds45", emailIds)
      console.log("emailIds4556", emailMSIds)
      console.log("emailIds455645", code)
      let codeJsonString = JSON.stringify(code);
      let config = {
        emailIds: emailIds, emailMSIds: emailMSIds, name: firstName + " " + lastName, fromUserEmail: email, pname: selectedShareTypeRef.current == "group" ? decData(groupItem.groupName, groupItem.isEncrypt) :
          decData(listItem.projectName, listItem.isEncrypt), type: type, notInterestedcode: Base64.encode(codeJsonString),
        shareId: shareId
      }
      console.log("emailIds45", config)
      const response = await post(BASE_URL + '/sendshareemailv2', config)
      console.log('response23', response)
      /* if (response.data.status == 'success') {
      } else {
      } */
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const postShareStatus = async (data: any) => {

    try {
      const response = await post(BASE_URL + '/setinvitation', data)
      console.log('response23', response)
      if (response.data.status == 'success') {
        try {
          let json = {};
          sendSocketMessage("setinvitation", json)
        }
        catch (ex) {

        }
        getTask(userId)
        getProjectWithGroup(userId, 0)
        //getUserShare(userId);
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const postUserShare = async (data: any) => {
    let item = [...data]
    console.log('response2345', item)
    try {
      const response = await post(BASE_URL + '/addshare', { shareData: item })
      console.log('response23', response)
      if (response.data.status == 'success') {
        try {
          let json = {};
          sendSocketMessage("addshare", json)
        }
        catch (ex) {

        }

        console.log('shareId45', response.data.shareId)

        getUserShare(userId);
        try {
          getAllInvitations(userId, userData.email)
        }
        catch (ex) {

        }
        if (!checkEmailIsNotIntrested(item[0].toUserEmail)) {
          postShareEmail(data, selectedShareTypeRef.current == "group" ? "project" : "list", response.data.shareId)
        }
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    // console.log('e.target.value', event.target.value)
    // console.log('e.target.value.length', event.target.value.length)
    setTaskInputValue(event.target.value)
  }
  const handleChangeForProject = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setProjectsInputValue(event.target.value)
  }
  const handleChangeForProjectNewList = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setProjectsNewListInputValue(event.target.value)
  }


  const handleChangeForGroup = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setGroupInputValue(event.target.value)
  }

  const handleKeyDown = (event: { key: any }) => {
    if (event.key === 'Enter') {
      addTaskToList()
      setTaskSectionOpen(false)
      console.log('User pressed: ', event.key)
    }
  }
  const handleKeyDownForProject = (event: { key: any; target: any }) => {
    if (event.key === 'Enter') {
      if (event.target.value != '') {
        addProjectToList()
        setProjectsInputValue('')
        setAddList(false)
        console.log('User pressed: ', event.key)
      }
    }
  }
  const handleKeyDownForProjectNewList = (
    event: { key: any; target: any },
    item
  ) => {
    if (event.key === 'Enter') {
      if (event.target.value != '') {
        addListIntoProject(item, 0)
        setProjectsNewListInputValue('')
        item.addNewList = false
        console.log('User pressed: ', event.key)
      }
    }
  }
  const handleBlurProjectNewList = (event: any, item) => {
    if (event.target.value != '') {
      addListIntoProject(item, 0)
      setProjectsNewListInputValue('')
      item.addNewList = false
    } else {
      setProjectsNewListInputValue('')
      item.addNewList = false
    }
  }
  const handleBlurForList = (event: any) => {
    if (event.target.value != '') {
      addProjectToList()
      setProjectsInputValue('')
      setAddList(false)
    } else {
      setProjectsInputValue('')
      setAddList(false)
    }
  }

  const handleBlurForProject = (event: any) => {
    if (event.target.value != '') {
      addGroups()
      setGroupInputValue('')
      setAddProject(false)
    } else {
      setGroupInputValue('')
      setAddProject(false)
    }
  }
  const handleKeyDownForGroup = (event: { key: any; target: any }) => {
    if (event.key === 'Enter') {
      if (event.target.value != '') {
        addGroups()
        setGroupInputValue('')
        setAddProject(false)
        console.log('User pressed: ', event.target.value)
      }

      //sideMenuUpperDivRef.current.scrollTop = sideMenuUpperDivRef.current.scrollHeight;
      //sideMenuUpperDivRef.current?.scrollIntoView();
      /* let valule:any= document.getElementsByClassName('sideMenuUpperDiv');
      valule.scrollTo(0,0); */
    }
  }

  function openTask(item: any, index: any) {
    if (taskSectionOpen) {
      setTaskSectionOpen(false)
    } else {
      setTaskSectionOpen(true)
      setTaskIndex(index)
      console.log('taskIndex', taskIndex)
      console.log('index', index)
      setTaskItem(item)
      taskItemRef.current = item;
    }
  }

  function statusCount(item: any) {
    let mainData = [...item.task]
    let count = 0
    for (let i = 0; i < mainData.length; i++) {
      if (mainData[i].status == 1) {
        count++
      }
    }
    return count
  }
  function getTaskCountOfProject(projectID: any) {
    let count = 0
    if (taskData.length > 0) {
      let taskdata1 = [...taskData]

      for (let i = 0; i < taskdata1[0].length; i++) {
        if (parseInt(taskdata1[0][i].projectId) == parseInt(projectID) && taskdata1[0][i].isTrash == "1") {
          count++
        }
      }
    }

    return count
  }
  function getUndoneTaskCountOfProject(projectID: any) {
    let count = 0
    if (taskData.length > 0) {
      let taskdata1 = [...taskData]

      for (let i = 0; i < taskdata1[0].length; i++) {
        if (
          parseInt(taskdata1[0][i].projectId) == parseInt(projectID) &&
          taskdata1[0][i].taskStatus == '0' && taskdata1[0][i].isTrash == "1"
        ) {
          count++
        }
      }
    }

    return count
  }

  function completeTaskList() {
    updateCompletedTaskUserActivity(listItem.id)
    if (completeTaskShow) {
      setCompleteTaskShow(false)
    } else {
      setCompleteTaskShow(true)
    }
  }

  const postTask = async (taskInputValue1, selectedListId) => {
    // for get task order
    let taskOrderIds: any = ''
    let completeTaskOrderIds: any = ''
    if (taskData && taskData.length > 0) {
      if (taskData[0] && taskData[0].length > 0) {
        for (let i = 0; i < taskData[0].length; i++) {
          if (selectedListId == taskData[0][i].projectId) {
            if (parseInt(taskData[0][i].taskStatus) == 0) {
              if (taskOrderIds != '') taskOrderIds += ','
              taskOrderIds += taskData[0][i].id
            } else if (parseInt(taskData[0][i].taskStatus) == 1) {
              if (completeTaskOrderIds != '') completeTaskOrderIds += ','
              completeTaskOrderIds += taskData[0][i].id
            }
          }
        }
      }
    }

    console.log('Order of tasks Ids', taskOrderIds)
    console.log('Order of complete Task Ids', completeTaskOrderIds)

    // for get task order

    try {
      console.log('CryptoJS1145', encData(taskInputValue1))
      const data = {
        title: encData(taskInputValue1),
        userid: listItem.userId,
        taskorder: 0,
        projectId: selectedListId,
        taskOrderIds: taskOrderIds,
        completedTaskOrderIds: completeTaskOrderIds,
        taskNote: listItem.isNote && listItem.isNote == "1" ? 1 : 0,
        createdUserId: userId
      }
      console.log('data123456', data)
      const response = await post(BASE_URL + '/addtaskv2', data)
      console.log('response455623', response)
      if (response.data.status == 'success') {
        if (response.data && response.data.data && response.data.data.length > 0) {
          try {
            let json = { taskId: response.data.data[0].id };
            sendSocketMessage("add task", json)
          }
          catch (ex) {

          }
          try {
            addTaskUpdateByOtherUser(response.data.data[0], 'addTask')
            let tempTaskdata = [...taskData];

            console.log("task4556", response.data.data[0])

            console.log("tempTaskdata[0]", tempTaskdata[0])
            tempTaskdata[0].splice(0, 0, response.data.data[0]);
            console.log("tempTaskdata[0]", tempTaskdata[0])
            setTaskData(tempTaskdata);
            setTaskMainData(tempTaskdata[0]);
            taskAddScrollRef.current.scrollTop = 0
          }
          catch (ex) { }
          //await getTask(userId)
          let tempProjectName = ""
          let tempProjectId = response.data.data[0].projectId;
          let tempProjectGroupId = "0";
          let tempGroupData = [...groupData];
          for (let i = 0; i < tempGroupData.length; i++) {
            for (let j = 0; j < tempGroupData[i].project.length; j++) {
              if (tempGroupData[i].project[j].id == tempProjectId) {
                tempProjectGroupId = tempGroupData[i].id;
                tempProjectName = tempGroupData[i].project[j].projectName;
                break;
              }
            }
          }
          let tempProjectData = [...projectData];
          for (let i = 0; i < tempProjectData.length; i++) {
            if (tempProjectData[i].id == tempProjectId) {
              tempProjectName = tempProjectData[i].projectName;
              break;
            }
          }

          let tempTaskId = response.data.data[0].id;
          let tempTaskName = response.data.data[0].taskName;
          let tempTaskUserId = response.data.data[0].userid;
          console.log("data4556", tempTaskId)
          console.log("data45561", tempProjectId)
          console.log("data45562", tempProjectGroupId)
          console.log("data45562", tempProjectName)

          const config = {
            taskUserId: tempTaskUserId,
            currentUserId: userId,
            projectId: tempProjectId,
            projectGroupId: tempProjectGroupId,
            taskId: tempTaskId,
            taskName: decData(tempTaskName, "1"),
            userName: firstName + " " + lastName,
            type: "project",
            subType: "add task",
            projectName: tempProjectName != "" ? decData(tempProjectName, "1") : ""
          }
          console.log('test856', config);
          const response1 = await post(BASE_URL + '/notificationtask', config)
          console.log('response19', response1)



        }
        /* getTask(userId)
        try {
          taskAddScrollRef.current.scrollTop = 0
        }
        catch (ex) { } */
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  function getExitStatus(dataItem) {
    let status = false;
    if (allInvitation && allInvitation.length > 0) {
      let tempData = [...allInvitation];
      if (dataItem && dataItem.id) {


        for (let i = 0; i < tempData.length; i++) {
          console.log("dataItem45", tempData[i].projectId + "==" + dataItem.id)
          //console.log("dataItem452", dataItem.id)
          if (dataItem.id == tempData[i].projectId && tempData[i].isAccept == "0") {
            status = true;
          }
        }
      }
    }
    return status;
  }


  function getShareCount(type) {
    let count = 0;
    if (allUserShareData && allUserShareData.length > 0) {
      let tempData = [...allUserShareData];
      if (listItem && listItem.id) {
        for (let i = 0; i < tempData.length; i++) {
          if (tempData[i].type == type && listItem.id == tempData[i].projectId && tempData[i].isAccept == 0) {
            count++;
          }
        }
        for (let i = 0; i < tempData.length; i++) {
          if (tempData[i].type == "group" && listItem.orginalProjectGroupId && listItem.orginalProjectGroupId == tempData[i].projectGroupId && tempData[i].isAccept == 0) {
            count++;
          }
        }
      }
    }
    return count;
  }
  function getOtherShareCount(type) {
    let count = 0;
    if (allOtherUserShareData && allOtherUserShareData.length > 0) {
      let tempData = [...allOtherUserShareData];
      if (listItem && listItem.id) {
        for (let i = 0; i < tempData.length; i++) {
          if (tempData[i].type == type && listItem.id == tempData[i].projectId && tempData[i].isAccept == 0) {
            count++;
          }
        }
        for (let i = 0; i < tempData.length; i++) {
          if (tempData[i].type == "group" && listItem.orginalProjectGroupId && listItem.orginalProjectGroupId == tempData[i].projectGroupId && tempData[i].isAccept == 0) {
            count++;
          }
        }
      }
    }
    return count;
  }

  function getShareByIdCount(type, listItem1) {
    let count = 0;
    if (allUserShareData && allUserShareData.length > 0) {
      let tempData = [...allUserShareData];
      if (listItem1 && listItem1.id) {
        if (type == "project") {
          for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].type == type && listItem1.id == tempData[i].projectId && tempData[i].isAccept == 0) {
              count++;
            }
          }
          for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].type == "group" && listItem1.orginalProjectGroupId && listItem1.orginalProjectGroupId == tempData[i].projectGroupId && tempData[i].isAccept == 0) {
              count++;
            }
          }
        }
        else if (type == "group") {
          for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].type == type && listItem1.id == tempData[i].projectGroupId && tempData[i].isAccept == 0) {
              count++;
            }
          }
        }


      }
    }
    return count;

  }

  function getMemberCount() {
    let count = 0;
    if (sharedUserList && sharedUserList.length > 0) {
      let tempData = [...sharedUserList];
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].isAccept == 0) {
          count++;
        }
      }
    }
    return count;
  }




  const getUserShare = async (userId) => {
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getusershare', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data', apiData.data.data)
        let data = apiData.data.data
        for (let i = 0; i < data.length; i++) {
          data[i].toUserEmail = Base64.decode(data[i].toUserEmail)
          data[i].toUserName = Base64.decode(data[i].toUserName)
          data[i].fromUserEmail = Base64.decode(data[i].fromUserEmail)
        }
        setAllUserShareData(data);
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const getotherusershare = async (userId) => {
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getotherusershare', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data', apiData.data.data)
        let data = apiData.data.data
        for (let i = 0; i < data.length; i++) {
          data[i].toUserEmail = Base64.decode(data[i].toUserEmail)
          data[i].toUserName = Base64.decode(data[i].toUserName)
          data[i].fromUserEmail = Base64.decode(data[i].fromUserEmail)
        }
        setAllOtherUserShareData(data);
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  const getNotInterested = async () => {

    try {
      const apiData = await post(BASE_URL + '/getnotinterested', {})
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data123', apiData.data.data)
        setNotInterestedData(apiData.data.data)
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  /* const postUserShare = async (data:any) => {
    let item=[...data]

    try {
      const response = await post(BASE_URL + '/addshare', {shareData:item})
      console.log('response23', response)
      if (response.data.status == 'success') {
        getUserShare(userId);
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  } */
  const checkUserShare = async (userId, email, user) => {
    if (localStorage.getItem('sharecode') != null) {


      console.log("sharecode", localStorage.getItem('sharecode'))
      let tempShareCode: any = localStorage.getItem('sharecode')
      let code: any = Base64.decode(tempShareCode);
      console.log("sharecode", JSON.parse(code))
      let item = JSON.parse(code);
      item.toUserEmail = Base64.decode(email);
      item.isAccept = "0";
      item.toUserEmail = Base64.decode(user.email)
      item.toUserId = user.id
      item.toUserName = Base64.decode(user.firstName) + " " + Base64.decode(user.lastName);
      console.log("sharecode", item)
      await postUserShare([item]);
      getProjectWithGroup(user.id, 0)
      getTask(user.id)
      localStorage.removeItem('sharecode');
      //http://localhost:3000/invite?c=eyJmcm9tVXNlcklkIjoiNTAiLCJ0b1VzZXJJZCI6IjAiLCJmcm9tVXNlckVtYWlsIjoiZ2FqZW5kcmFndXB0YTAxMEBnbWFpbC5jb20iLCJ0b1VzZXJFbWFpbCI6IiIsInRvVXNlck5hbWUiOiIiLCJ0eXBlIjoicHJvamVjdCIsInByb2plY3RJZCI6IjY1NSIsInBuYW1lIjoidGVzdDQ1IiwicHJvamVjdEdyb3VwSWQiOiIwIiwidGFza0lkIjoiMCIsImlzQWNjZXB0IjoiMSJ9
    }
    let config = {
      userid: userId,
      userEmail: email
    }
    try {
      const apiData11 = await post(BASE_URL + '/getallshareinvitationsv2', config)
      if (apiData11.data && apiData11.data.status && apiData11.data.status == 'success') {
        setAllInvitation(apiData11.data.data);
      }
      if (localStorage.getItem('notificationBackgroundData') == null) {
        const apiData = await post(BASE_URL + '/checkshare', config)
        console.log('apiData4556', apiData)
        if (apiData.data.status == 'success') {
          console.log('apiData.data.data455', apiData.data.data)
          if (apiData.data && apiData.data.data.length > 0) {
            setTimeout(() => {
              setOpenNotificationListDialog(true);
            }, 100);
            let data = apiData.data.data
          }

        }
      }
      else {
        let notificationBackgroundData = localStorage.getItem('notificationBackgroundData')
        if (notificationBackgroundData && notificationBackgroundData != "") {
          setOpenAllNotificationDialog(true)
          localStorage.removeItem('notificationBackgroundData')
        }
        else {
          setOpenAllNotificationDialog(false)
          localStorage.removeItem('notificationBackgroundData')
        }
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const getAllInvitations = async (userId, email) => {

    let config = {
      userid: userId,
      userEmail: email
    }
    try {
      const apiData11 = await post(BASE_URL + '/getallshareinvitationsv2', config)
      if (apiData11.data && apiData11.data.status && apiData11.data.status == 'success') {
        setAllInvitation(apiData11.data.data);
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const getOtherGroupAllInvitations = async (groupId) => {

    let config = {
      groupId: groupId
    }
    try {
      setIsShareApiDone(true)
      setAllOtherInvitation([]);
      const apiData11 = await post(BASE_URL + '/getallothersharegroupinvitations', config)
      if (apiData11.data && apiData11.data.status && apiData11.data.status == 'success') {
        setAllOtherInvitation(apiData11.data.data);
        console.log('apiData11.data.data', apiData11.data.data)
      }
      setIsShareApiDone(false)
    } catch (ex) {
      setIsShareApiDone(false)
      console.log('response9211', ex)
    }
  }
  const getOtherProjectAllInvitations = async (projectId, groupId) => {

    let config = {
      projectId: projectId,
      groupId: groupId
    }
    try {
      setIsShareApiDone(true)
      setAllOtherInvitation([]);
      const apiData11 = await post(BASE_URL + '/getallothershareprojectinvitations', config)
      if (apiData11.data && apiData11.data.status && apiData11.data.status == 'success') {
        setAllOtherInvitation(apiData11.data.data);
        console.log('apiData11.data.data', apiData11.data.data)
      }
      setIsShareApiDone(false)
    } catch (ex) {
      setIsShareApiDone(false)
      console.log('response9211', ex)
    }
  }
  const getTaskSocket = async (userId, isNote = false, taskId) => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      getTrashTask(userId)
      checkReminder()
      const apiData = await post(BASE_URL + '/gettasksv2', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {

        console.log('apiData.data.data', apiData.data.data)
        if (apiData.data.data && apiData.data.data.length > 0) {

          CompletedStatusCount(apiData.data.data)

          let tempTask = apiData.data.data
          for (let i = 0; i < tempTask.length; i++) {
            if (tempTask[i].subTaskData && tempTask[i].subTaskData != '') {
              try {
                tempTask[i].task = JSON.parse(tempTask[i].subTaskData)
              } catch (ex) {
                tempTask[i].task = []
              }
            } else {
              tempTask[i].task = []
            }
          }

          /* if (taskItem && taskItem.id) {
            for (let i = 0; i < tempTask.length; i++) {
              if (tempTask[i].id == taskItem.id) {
                if (!isNote) {
                  setTaskItem(tempTask[i])
                  taskItemRef.current=tempTask[i];
                }
              }
            }
          } */
          if (taskItemRef.current && taskItemRef.current.id) {
            for (let i = 0; i < tempTask.length; i++) {
              if (tempTask[i].id == taskItemRef.current.id && taskItemRef.current.id==taskId) {
                if (!isNote) {
                  setTaskItem(tempTask[i])
                  taskItemRef.current = tempTask[i];
                }
              }
            }
          }
          taskDataRef.current = tempTask;
          setTaskData([tempTask])
          setTaskMainData(tempTask)
        }
      }
      setIsTaskApiDone(true)
    } catch (ex) {
      setIsTaskApiDone(true)
      console.log('response9211', ex)
    }
  }
  const getTask = async (userId, isNote = false) => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      getTrashTask(userId)
      checkReminder()
      const apiData = await post(BASE_URL + '/gettasksv2', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {

        console.log('apiData.data.data', apiData.data.data)
        if (apiData.data.data && apiData.data.data.length > 0) {

          CompletedStatusCount(apiData.data.data)

          let tempTask = apiData.data.data
          for (let i = 0; i < tempTask.length; i++) {
            if (tempTask[i].subTaskData && tempTask[i].subTaskData != '') {
              try {
                tempTask[i].task = JSON.parse(tempTask[i].subTaskData)
              } catch (ex) {
                tempTask[i].task = []
              }
            } else {
              tempTask[i].task = []
            }
          }

          /* if (taskItem && taskItem.id) {
            for (let i = 0; i < tempTask.length; i++) {
              if (tempTask[i].id == taskItem.id) {
                if (!isNote) {
                  setTaskItem(tempTask[i])
                  taskItemRef.current=tempTask[i];
                }
              }
            }
          } */
          if (taskItemRef.current && taskItemRef.current.id) {
            for (let i = 0; i < tempTask.length; i++) {
              if (tempTask[i].id == taskItemRef.current.id) {
                if (!isNote) {
                  setTaskItem(tempTask[i])
                  taskItemRef.current = tempTask[i];
                }
              }
            }
          }
          taskDataRef.current = tempTask;
          setTaskData([tempTask])
          setTaskMainData(tempTask)
        }
      }
      setIsTaskApiDone(true)
    } catch (ex) {
      setIsTaskApiDone(true)
      console.log('response9211', ex)
    }
  }

  const getTrashTask = async userId => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/gettrashtasks', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data45', apiData.data.data)
        // CompletedStatusCount(apiData.data.data)
        let tempTrashTask = apiData.data.data
        setTrashData(tempTrashTask)
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const postAI = async (inputText) => {
    console.log(inputText);
    let impacts = inputText;
    const result: any = impacts.split("\n").map((impact, index) => (
      encData(`${impact.trim()}`)
    ));

    let tempData: any = [];
    if (result && result.length > 1) {
      for (let i = 0; i < result.length; i++) {
        if (result[i] != "") {
          tempData.push(result[i]);
        }
      }
    }

    console.log("result45", result);
    if (tempData.length > 1) {
      postTaskList(tempData);


      setTimeout(() => {
        setTaskInputValue('')
      }, 200);
    }
    setTaskSectionOpen(false)

  }
  const postTaskList = async (tasklist) => {
    // for get task order
    let taskOrderIds: any = ''
    let completeTaskOrderIds: any = ''
    if (taskData && taskData.length > 0) {
      if (taskData[0] && taskData[0].length > 0) {
        for (let i = 0; i < taskData[0].length; i++) {
          if (selectedProjectID == taskData[0][i].projectId) {
            if (parseInt(taskData[0][i].taskStatus) == 0) {
              if (taskOrderIds != '') taskOrderIds += ','
              taskOrderIds += taskData[0][i].id
            } else if (parseInt(taskData[0][i].taskStatus) == 1) {
              if (completeTaskOrderIds != '') completeTaskOrderIds += ','
              completeTaskOrderIds += taskData[0][i].id
            }
          }
        }
      }
    }

    console.log('Order of tasks Ids', taskOrderIds)
    console.log('Order of complete Task Ids', completeTaskOrderIds)

    // for get task order

    try {
      const data = {
        tasklist: tasklist,
        userid: listItem.userId,
        taskorder: 0,
        projectId: selectedProjectID,
        taskOrderIds: taskOrderIds,
        completedTaskOrderIds: completeTaskOrderIds,
        taskNote: listItem.isNote && listItem.isNote == "1" ? 1 : 0,
        createdUserId: userId
      }
      console.log('data123456', data)
      const response = await post(BASE_URL + '/addtasklistv2', data)
      console.log('response23', response)
      if (response.data.status == 'success') {
        if (response.data && response.data.data && response.data.data.length > 0) {
          try {
            let json = { taskId: response.data.data[0].id };
            sendSocketMessage("add task", json)
          }
          catch (ex) {

          }
          for (let i = 0; i < response.data.data.length; i++) {
            addTaskUpdateByOtherUser(response.data.data[i], 'addTask')
          }
        }
        getTask(userId)
        try {
          taskAddScrollRef.current.scrollTop = 0
        }
        catch (ex) { }
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }




  function checkGroupOpen(id) {
    let data = [...groupDataRef.current]
    let status = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == id && data[i].open && data[i].open == true) {
        status = true
        break;
      }
    }
    return status;
  }
  const getProjectWithGroupSocket = async (userId) => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getprojectwithgroupv2222', config)
      console.log('apiData', apiData)
      if (apiData.data.status == 'success') {
        setProjectData(apiData.data.projects)
        projectDataRef.current = apiData.data.projects
        let data = apiData.data.projectsGroup
        for (let i = 0; i < data.length; i++) {
          data[i].open = checkGroupOpen(data[i].id)
        }
        setGroupData(data)
        groupDataRef.current = data

        ////////
        if (listItemRef.current && listItemRef.current.id) {
          let tempProjectName = ""
          let tempProjectId = listItemRef.current.id;
          let tempGroupData = [...groupDataRef.current];
          for (let i = 0; i < tempGroupData.length; i++) {
            for (let j = 0; j < tempGroupData[i].project.length; j++) {
              if (tempGroupData[i].project[j].id == tempProjectId) {
                setListItem(tempGroupData[i].project[j])
                tempProjectName = tempGroupData[i].project[j].projectName;
                setSelectedProjectName(tempProjectName)
                localStorage.setItem('project', JSON.stringify(tempGroupData[i].project[j]))
                break;
              }
            }
          }
          let tempProjectData = [...projectDataRef.current];
          for (let i = 0; i < tempProjectData.length; i++) {
            if (tempProjectData[i].id == tempProjectId) {
              setListItem(tempProjectData[i])
              tempProjectName = tempProjectData[i].projectName;
              setSelectedProjectName(tempProjectName)
              localStorage.setItem('project', JSON.stringify(tempProjectData[i]))
              break;
            }
          }
        }
        ///////


      }

    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const getProjectWithGroupWithDefault = async (
    userId,
    isScroll,
    defaultProjectID
  ) => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getprojectwithgroupv2222', config)
      console.log('apiData', apiData)
      if (apiData.data.status == 'success') {
        setProjectData(apiData.data.projects)
        projectDataRef.current = apiData.data.projects

        if (
          localStorage.getItem('project') &&
          localStorage.getItem('project') != null
        ) {
          let project = JSON.parse(localStorage.getItem('project') || '{}')
          console.log('project111222', project)
          if (project.projectGroupId != 0) {
            let data = apiData.data.projectsGroup
            for (let i = 0; i < data.length; i++) {
              if (userId == project.userId) {
                if (data[i].id == project.orginalProjectGroupId) {
                  data[i].open = true
                  setSelectedProjectGruopID(
                    data[i].id
                  )
                  setSelectedFolderItem(data[i])
                  console.log('xxyyzz', data[i])
                  console.log('xxyyzzdata', data)
                }
              }
              else {
                if (data[i].id == project.projectGroupId) {
                  data[i].open = true
                  setSelectedProjectGruopID(
                    data[i].id
                  )
                  setSelectedFolderItem(data[i])
                  console.log('xxyyzz', data[i])
                  console.log('xxyyzzdata', data)
                }
              }
            }
            groupDataRef.current = data;
            setGroupData(data)
            groupDataRef.current = data
            setSelectedProject(project.id, project.projectName)
          }
          else {
            setSelectedProject(project.id, project.projectName)
            setGroupData(apiData.data.projectsGroup)
            groupDataRef.current = apiData.data.projectsGroup
          }
          let user = JSON.parse(localStorage.getItem('user') || '{}')

          // setSelectedProject(user.defaultProjectID, 'Tasks')
          if (isScroll == 1) {
            setTimeout(() => {
              sideMenuUpperDivRef.current.scrollTop =
                sideMenuUpperDivRef.current.scrollHeight + 50
            }, 200)
          }
        } else {
          let data = apiData.data.projectsGroup
          for (let i = 0; i < data.length; i++) {
            if (data[i].isDefaultGroup == "0") {
              data[i].open = true
            }
            for (let j = 0; j < data[i].project.length; j++) {
              if (data[i].project[j].isDefaultProject == "0") {
                setSelectedProject(data[i].project[j].id, data[i].project[j].projectName)
                setListItem(data[i].project[j])
                listItemRef.current = data[i].project[j]
              }
            }
          }
          setGroupData(data)
          groupDataRef.current = data
          // setSelectedProject(project.id, project.projectName)
          // setGroupData(apiData.data.projectsGroup)
          let tempData = apiData.data.projects
          for (let i = 0; i <= tempData.length; i++) {
            if (tempData[i].id == defaultProjectID) {
              setSelectedProject(tempData[i].id, tempData[i].projectName)
              setListItem(tempData[i])
              listItemRef.current = tempData[i]
            }
          }
        }
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const getProjectWithGroup = async (userId, isScroll) => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/getprojectwithgroupv2222', config)
      console.log('apiData', apiData)
      if (apiData.data.status == 'success') {
        setProjectData(apiData.data.projects)
        projectDataRef.current = apiData.data.projects

        if (
          localStorage.getItem('project') &&
          localStorage.getItem('project') != null
        ) {
          let project = JSON.parse(localStorage.getItem('project') || '{}')
          console.log('project111222', project)

          if (project.projectGroupId != 0) {
            let data = apiData.data.projectsGroup
            for (let i = 0; i < data.length; i++) {
              if (userId == project.userId) {
                if (data[i].id == project.orginalProjectGroupId) {
                  data[i].open = true
                  setSelectedProjectGruopID(
                    data[i].id
                  )
                  setSelectedFolderItem(data[i])
                  console.log('xxyyzz', data[i])
                  console.log('xxyyzzdata', data)
                }
              }
              else {
                if (data[i].id == project.projectGroupId) {
                  data[i].open = true
                  setSelectedProjectGruopID(
                    data[i].id
                  )
                  setSelectedFolderItem(data[i])
                  console.log('xxyyzz', data[i])
                  console.log('xxyyzzdata', data)
                }
              }
            }
            setGroupData(data)
            groupDataRef.current = data
            if (selectedProjectName && selectedProjectName == 'Trash') {

            }
            else {
              setSelectedProject(project.id, project.projectName)
            }
          } else {
            if (selectedProjectName && selectedProjectName == 'Trash') {

            }
            else {
              setSelectedProject(project.id, project.projectName)
            }
            setGroupData(apiData.data.projectsGroup)
            groupDataRef.current = apiData.data.projectsGroup
          }
          let user = JSON.parse(localStorage.getItem('user') || '{}')

          // setSelectedProject(user.defaultProjectID, 'Tasks')
          if (isScroll == 1) {
            setTimeout(() => {
              sideMenuUpperDivRef.current.scrollTop =
                sideMenuUpperDivRef.current.scrollHeight + 50
            }, 200)
          }
        } else {
          setGroupData(apiData.data.projectsGroup)
          groupDataRef.current = apiData.data.projectsGroup
        }
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function unGroupProject(item) {
    console.log('item12', item)
    const json = {
      projectgroupid: item.id
    }
    try {
      console.log('json', json)

      const response = await post(BASE_URL + '/ungroupproject', json)
      console.log('response232323', response)
      if (response.data.status == 'success') {
        let data: any = [...groupData]
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == item.id) {
            let list1: any = [...projectData]
            for (let j = 0; j < data[i].project.length; j++) {
              data[i].project[j].projectGroupId = 0
              list1.push(data[i].project[j])
            }
            setProjectData(list1)
            projectDataRef.current = list1
            data[i].project = []
            setGroupData(data)
            groupDataRef.current = data
          }
        }
        setGroupData(data)
        groupDataRef.current = data
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const groupDeleteItemRef: any = useRef({})

  const addDialog1 = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
      console.log('groupDeleteItemRef', groupDeleteItemRef.current)
    }
    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Delete Folder</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p style={{ fontSize: 14 }}>
              Folder&nbsp;<span style={{ fontWeight: 700, fontFamily: 'ManRopeBold' }}>"
                {decData(
                  groupDeleteItemRef.current.groupName,
                  groupDeleteItemRef.current.isEncrypt
                )}
                "</span>&nbsp;can be deleted after all lists in this folder are deleted or moved.
            </p>

            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'center' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedNo}>
                Ok
              </button>
            </div>

          </div>
        </div>
      </>
    )
  }
  function deleteGroupUnderList(item) {
    let status = false;
    for (let i = 0; i < item.project.length; i++) {
      if (item.project[i].isTrash == "2") {

      }
      else {
        status = true;
      }
    }
    if (status) {
      groupDeleteItemRef.current = item
      confirmAlert({ customUI: addDialog1 })
    }
    else {
      DeleteProjectGroupRef.current = item;
      confirmAlert({ customUI: DeleteProjectGroupDialog })
    }
  }

  const DeleteProjectGroupRef: any = useRef({})

  const DeleteProjectGroupDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = () => {
      onClose()
      deleteGroup1(DeleteProjectGroupRef.current)
    }
    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Move Folder to Trash</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p>
              <span style={{ fontWeight: 700, fontFamily: 'ManRopeBold' }}>"
                {decData(
                  DeleteProjectGroupRef.current.groupName,
                  DeleteProjectGroupRef.current.isEncrypt
                )}
                "</span>&nbsp;will be move to trash.
            </p>

            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedYes}>
                Ok
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Cancle</button>
            </div>

          </div>
        </div>
      </>
    )
  }

  const deleteGroup = item => {
    DeleteProjectGroupRef.current = item
    confirmAlert({ customUI: DeleteProjectGroupDialog })
  }

  async function deleteGroup1(item) {
    console.log('item12', item)
    const json = {
      projectgroupid: item.id
    }
    try {
      console.log('json', json)
      const response = await post(BASE_URL + '/deleteprojectgroup', json)
      console.log('response232323', response)
      if (response.data.status == 'success') {
        try {
          let json = { groupId: item.id };
          sendSocketMessage("deleteprojectgroup", json)
        }
        catch (ex) {

        }
        let data: any = [...groupData]
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == item.id) {
            data.splice(i, 1)
          }
        }
        setGroupData(data)
        groupDataRef.current = data
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function moveProjectToTrash(item) {
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isTrash = "2"
      }
    }
    setGroupData(data)
    groupDataRef.current = data
    try {
      const response = await post(BASE_URL + '/updateprojectgroup', item)
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function restoreProject(item) {
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isTrash = "1"
      }
    }
    setGroupData(data)
    groupDataRef.current = data
    try {
      const response = await post(BASE_URL + '/updateprojectgroup', item)
      console.log('response232323', response)
      try {
        let json = { grouptId: item.id };
        sendSocketMessage("updateprojectgroup", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function deleteProjectListItem(item) {
    console.log('item12', item)
    const json = {
      projectid: item.id
    }
    try {
      console.log('json', json)

      const response = await post(BASE_URL + '/deleteproject', json)
      console.log('response232323', response)
      if (response.data.status == 'success') {
        try {
          let json = { projectId: item.id };
          sendSocketMessage("deleteproject", json)
        }
        catch (ex) {

        }
        if (item.orginalProjectGroupId == '0') {
          let data: any = [...projectData]
          for (let i = 0; i < data.length; i++) {
            if (data[i].id == item.id) {
              if (item.id == selectedProjectID) {
                if (i + 1 == data.length) {
                  setSelectedProject(data[i - 1].id, data[i - 1].projectName)
                  // setTitleHeading(data[i - 1].projectName)
                } else {
                  setSelectedProject(data[i + 1].id, data[i + 1].projectName)
                  // setTitleHeading(data[i + 1].projectName)
                }
              }
              data.splice(i, 1)
            }
          }
          setProjectData(data)
          projectDataRef.current = data
        } else {
          let data: any = [...groupData]
          for (let i = 0; i < data.length; i++) {
            console.log('setgroupdata', data[i].id + ',' + item.orginalProjectGroupId)
            if (data[i].id == item.orginalProjectGroupId) {
              for (let j = 0; j < data[i].project.length; j++) {
                if (data[i].project[j].id == item.id) {
                  data[i].project.splice(j, 1)
                }
              }
            }
          }
          setGroupData(data)
          groupDataRef.current = data
          console.log('setgroupdata', data)
        }
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function moveListToTrash(item) {

    if (item.orginalProjectGroupId == '0') {
      let data = [...projectData]
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == item.id) {
          data[i].isTrash = "2"
        }
      }
      console.log('data1010', data)
      setProjectData(data)
      projectDataRef.current = data
    } else {
      let data: any = [...groupData]
      for (let i = 0; i < data.length; i++) {
        console.log('setgroupdata', data[i].id + ',' + item.orginalProjectGroupId)
        if (data[i].id == item.orginalProjectGroupId) {
          for (let j = 0; j < data[i].project.length; j++) {
            if (data[i].project[j].id == item.id) {
              data[i].project[j].isTrash = "2"
            }
          }
        }
      }
    }
    try {
      const response = await post(BASE_URL + '/updateproject', item)
      try {
        let json = { projectId: item.id };
        sendSocketMessage("updateproject", json)
      }
      catch (ex) {

      }
      getProjectWithGroup(userId, 0)
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  function CompletedStatusCount(value) {
    let mainData: any = [...value]
    let count = 0
    console.log('selectedProjectID', selectedProjectID)
    for (let i = 0; i < mainData.length; i++) {
      if (mainData[i].taskStatus == '1') {
        count++
      }
    }
    console.log('mainData', mainData)
    console.log('counttest', count)
    // return count
    setCountNum(count)
  }

  function getCompletedCount() {
    let mainData: any = taskMainData
    let count = 0
    // console.log('selectedProjectID', selectedProjectID)
    for (let i = 0; i < mainData.length; i++) {
      if (
        mainData[i].taskStatus == '1' && mainData[i].isTrash == '1' &&
        mainData[i].projectId == selectedProjectID
      ) {
        count++
      }
    }

    return count
  }

  const completeTask = async (taskId, taskStatus) => {
    console.log('taskId2311', taskId)
    let config = {
      taskid: taskId,
      status: taskStatus
    }

    try {
      const apiData = await post(BASE_URL + '/completetask', config)
      console.log('apiData', apiData)
      try {
        let json = { taskId: taskId };
        sendSocketMessage("complete task", json)
      }
      catch (ex) {

      }
      // if (apiData.data.status == 'success') {
      //   console.log('apiData.data.data', apiData.data.data)
      //   setTaskData([apiData.data.data])
      // }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const inputFileRef: any = useRef()

  const DeleteListRef: any = useRef({})

  const DeleteListDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      onClose()
      await deleteProjectListItem(DeleteListRef.current)
      getTask(userId)
    }
    var undoneTaskCount = getUndoneTaskCountOfProject(DeleteListRef.current.id)
    return (
      <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
        {/* <h5 style={{ fontWeight: 700, color: colorSchemes.FontBlackColor, marginBottom: 15 }}>
          Move List to Trash
        </h5> */}
        <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
          <div style={{ width: "70%", textAlign: "left" }}>
            <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Move List to Trash</h4></div>
          <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
          </div>
        </div>

        <div className='add-dialog-inner-div'>
          {undoneTaskCount > 0 ? (
            <p>
              All tasks must be marked as done/completed before a list can be
              moved to trash.
            </p>
          ) : (
            <>
              {DeleteListRef.current.isDefaultProject == '0' && DeleteListRef.current.isDefaultProject == '1' ? (
                <p >Default List can not be Deleted.</p>
              ) : (
                <>
                  <p>
                    <span style={{ fontWeight: 700, fontFamily: 'ManRopeBold' }}>"
                      {decData(
                        DeleteListRef.current.projectName,
                        DeleteListRef.current.isEncrypt
                      )}
                      "</span>&nbsp;will be move to trash.
                  </p>
                </>
              )}
            </>
          )}
          <div>
            {undoneTaskCount > 0 ||
              DeleteListRef.current.isDefaultProject == '0' && DeleteListRef.current.isDefaultProject == '1' ? (
              <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'center' }}>
                <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                  onClick={handleClickedNo}>
                  Ok
                </button>
              </div>
            ) : (
              <>
                <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
                  <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                    onClick={handleClickedYes}>
                    Ok
                  </button>
                  <button style={{
                    background: '#fff',
                    color: "#3068C8",
                    border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
                  }}
                    onClick={handleClickedNo}>
                    Cancle
                  </button>
                </div>
              </>
            )}
          </div>

        </div>
      </div>
    )
  }

  const handleProjectDeleteConfirm = item => {
    console.log('item4556', item)
    DeleteListRef.current = item
    confirmAlert({ customUI: DeleteListDialog })
  }



  const exitProjectShareApi = async (data: any) => {
    setMultiSnackbarMsg('Exited...')
    // handleClickInviteRemoveSnackBar(SlideTransition)
    handleClickMultiSnackBar(SlideTransition)

    // isset($data->fromUserId) &&  isset($data->toUserId) &&  isset($data->projectId)

    let config = {
      fromUserId: data.userId,
      toUserId: userId,
      projectId: data.id
    }

    console.log('exitListItem4545', config)
    try {
      const response = await post(BASE_URL + '/exitproject', config)
      console.log('response23', response)
      getTask(userId)
      getProjectWithGroup(userId, 0)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const handleProjectExitConfirm = item => {
    console.log('item4556', item)
    DeleteListRef.current = item
    confirmAlert({ customUI: ExitShareListDialog })
  }

  const ExitShareListDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      onClose()
      console.log('exitListItem45', DeleteListRef.current)
      exitProjectShareApi(DeleteListRef.current)
      handleCloseDialog()
    }

    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Exit list</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p>
              Did you want to remove yourself from this&nbsp;<span style={{ fontWeight: 700, fontFamily: 'ManRopeBold' }}>
                "
                {decData(
                  DeleteListRef.current.projectName,
                  DeleteListRef.current.isEncrypt
                )}
                "</span>&nbsp;list? If so, you will no longer be able to see it.
            </p>

            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 155 }}
                onClick={handleClickedYes}>
                Yes, remove me
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Cancle</button>
            </div>
          </div>
        </div>
      </>
    )
  }


  const exitShareApi = async (data: any) => {
    setMultiSnackbarMsg('Exited...')
    // handleClickInviteRemoveSnackBar(SlideTransition)
    handleClickMultiSnackBar(SlideTransition)

    // isset($data->fromUserId) &&  isset($data->toUserId) &&  isset($data->projectGroupId)

    let config = {
      fromUserId: data.userId,
      toUserId: userId,
      projectGroupId: data.id
    }

    console.log('exitListItem4545', config)
    try {
      const response = await post(BASE_URL + '/exitgroup', config)
      console.log('response23', response)
      getTask(userId)
      getProjectWithGroup(userId, 0)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  const handleGroupExitConfirm = item => {
    console.log('item4556', item)
    DeleteListRef.current = item
    confirmAlert({ customUI: ExitShareGroupDialog })
  }

  const ExitShareGroupDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      onClose()
      console.log('exitListItem4545', DeleteListRef.current)
      exitShareApi(DeleteListRef.current)
      handleCloseDialog()
    }

    return (
      <>


        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Exit folder</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p>
              Did you want to remove yourself from this&nbsp;<span style={{ fontWeight: 700, fontFamily: 'ManRopeBold' }}>
                "
                {decData(
                  DeleteListRef.current.groupName,
                  DeleteListRef.current.isEncrypt
                )}
                "</span>&nbsp;folder? If so, you will no longer be able to see it.
            </p>

            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 155 }}
                onClick={handleClickedYes}>
                Yes, remove me
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Cancle</button>
            </div>
          </div>
        </div>
      </>
    )
  }



  const revokeShareListRef: any = useRef({})
  const handleRevokeConfirm = item => {
    console.log('item4556', item)
    revokeShareListRef.current = item
    confirmAlert({ customUI: RevokeShareDialog })
  }

  const RevokeShareDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      onClose()
      console.log('exitListItem4545', revokeShareListRef.current)
      handleDeleteShareListUser(revokeShareListRef.current)
      // exitShareApi(DeleteListRef.current)
    }

    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Revoke Access</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            {revokeShareListRef.current.isAccept == 1 ?
              <p >
                Do you want to revoke this invite?
              </p>
              :
              <p >
                Do you want to remove this user from this list?
              </p>
            }
            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedYes}>
                Yes
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>No</button>
            </div>
          </div>
        </div>
      </>
    )
  }




  const handleTaskExistsConfirm = () => {
    confirmAlert({ customUI: taskExistsDialog })
  }

  const taskExistsDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    // const handleClickedYes = async () => {
    //   onClose()
    //   console.log('exitListItem4545', revokeShareListRef.current)
    //   handleDeleteShareListUser(revokeShareListRef.current)
    //   // exitShareApi(DeleteListRef.current)
    // }

    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Task Exists</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p >
              This task no longer exists.
            </p>
            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'center' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedNo}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }





  const DeleteEmptyDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      let tempTaskdata = [...taskData];
      let tempTaskIds = "";
      for (let i = 0; i < tempTaskdata[0].length; i++) {
        if (tempTaskdata[0][i].isTrash == "2") {
          tempTaskIds = tempTaskIds + tempTaskdata[0][i].id + ",";
        }
      }



      let tempProjectData = [...projectData]
      let tempProjectIds = "";
      for (let i = 0; i < tempProjectData.length; i++) {
        if (tempProjectData[i].isTrash == "2") {
          tempProjectIds = tempProjectIds + tempProjectData[i].id + ",";
        }
      }


      let tempGroupData = [...groupData]
      let tempGrpoupIds = "";
      for (let i = 0; i < tempGroupData.length; i++) {
        if (tempGroupData[i].isTrash == "2") {
          tempGrpoupIds = tempGrpoupIds + tempGroupData[i].id + ",";
        }
        for (let j = 0; j < tempGroupData[i].project.length; j++) {
          if (tempGroupData[i].project[j].isTrash == "2") {
            tempProjectIds = tempProjectIds + tempGroupData[i].project[j].id + ",";
          }
        }
      }
      tempProjectIds = tempProjectIds.substring(0, tempProjectIds.length - 1);
      tempGrpoupIds = tempGrpoupIds.substring(0, tempGrpoupIds.length - 1);
      tempTaskIds = tempTaskIds.substring(0, tempTaskIds.length - 1);

      let json = {
        taskIds: tempTaskIds,
        projectIds: tempProjectIds,
        groupIds: tempGrpoupIds
      }
      try {
        const response = await post(BASE_URL + '/emptytrash', json)
        console.log('response232323', response)
        getTask(userId)
        await getProjectWithGroup(userId, 0)
        setSelectedProject(Base64.encode("1"), 'Trash')
      } catch (ex) {
        console.log('response9211', ex)
      }

      console.log("tempProjectIds", tempProjectIds.substring(0, tempProjectIds.length - 1))
      console.log("tempGrpoupIds", tempGrpoupIds.substring(0, tempGrpoupIds.length - 1))
      console.log("tempTaskIds", tempTaskIds.substring(0, tempTaskIds.length - 1))

      onClose()
    }

    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Clear Trash</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p style={{ marginBottom: 5 }}>
              Tasks will be permanently deleted.
            </p>
            <p>
              Are you sure you want to clear trash?
            </p>
            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedYes}>
                Confirm
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Close</button>
            </div>
          </div>
        </div>

      </>
    )
  }

  const handleEmptyTrashConfirm = () => {
    confirmAlert({ customUI: DeleteEmptyDialog })
  }

  async function handleChangeProjectName(e) {
    if (e.target.value == "") {
      setSelectedProject(selectedProjectID, selectedProjectName)
      if (listItem.orginalProjectGroupId != 0) {
        let data = [...groupData]
        let editList: any = {}
        let index = -1
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].project.length; j++) {
            if (data[i].project[j].id == selectedProjectID) {
              editList = data[i].project[j]
              index = j
              break
            }
          }
        }
        setListItem(editList)
        listItemRef.current = editList
      }
      else {
        let data = [...projectData]
        let editList: any = {}
        let index = -1
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == selectedProjectID) {
            editList = data[i]
            index = i
            break
          }
        }
        setListItem(editList)
        listItemRef.current = editList
      }
      return;
    }
    console.log('listitem', listItem)
    if (listItem.orginalProjectGroupId != 0) {
      let data = [...groupData]
      let editList: any = {}
      let index = -1
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].project.length; j++) {
          if (data[i].project[j].id == selectedProjectID) {
            editList = data[i].project[j]
            index = j
            break
          }
        }
      }
      editList.projectName = encData(e.target.value)
      editList.isEncrypt = '1'
      console.log('editList45', editList)
      console.log('editList45', index)
      if (e.target.value.trim() !== "") {
        renameList(editList, index)
        console.log('editList4512', editList)
      }
      else {
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].project.length; j++) {
            if (data[i].project[j].id == selectedProjectID) {
              // editList = data[i].project[j]
              // index = j
              setSelectedProjectName(data[i].project[j].projectName)
              break
            }
          }
        }
        setSelectedProject(selectedProjectID, selectedProjectName)
        console.log('editList45121', selectedProjectID, selectedProjectName)
        console.log('editList45121', decData(selectedProjectName, "1"))
      }
      // renameList(editList, index)
      localStorage.setItem('project', JSON.stringify(editList))
    } else {
      let data = [...projectData]
      let editList: any = {}
      let index = -1
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == selectedProjectID) {
          editList = data[i]
          index = i
          break
        }
      }
      editList.projectName = encData(e.target.value)
      editList.isEncrypt = '1'
      console.log('editList45', editList)
      console.log('editList45', index)
      if (e.target.value.trim() !== "") {
        renameList(editList, index)
        console.log('editList4512', editList)
      }
      else {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == selectedProjectID) {
            setSelectedProjectName(data[i].projectName)
            // editList = data[i]
            // index = i
            break
          }
        }
        setSelectedProject(selectedProjectID, selectedProjectName)
        console.log('editList45121', selectedProjectID, selectedProjectName)
        console.log('editList45121', decData(selectedProjectName, "1"))
      }
      // renameList(editList, index)
      localStorage.setItem('project', JSON.stringify(editList))
    }
    // updateTask(taskItem)
  }
  const handleProjectNameKeyDown = e => {
    if (e.keyCode !== 8 && e.target.innerText.length >= 64) {
      e.preventDefault()
    }

    if (e.keyCode === 13) {
      e.target.blur()
      console.log('listitem', listItem)
      if (listItem.orginalProjectGroupId != 0) {
        let data = [...groupData]
        let editList: any = {}
        let index = -1
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].project.length; j++) {
            if (data[i].project[j].id == selectedProjectID) {
              editList = data[i].project[j]
              index = j
              break
            }
          }
        }
        editList.projectName = encData(e.target.innerText)
        editList.isEncrypt = '1'
        console.log('editList45', editList)
        console.log('editList45', index)
        renameList(editList, index)
        localStorage.setItem('project', JSON.stringify(editList))
      } else {
        let data = [...projectData]
        let editList: any = {}
        let index = -1
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == selectedProjectID) {
            editList = data[i]
            index = i
            break
          }
        }
        editList.projectName = encData(e.target.innerText)
        editList.isEncrypt = '1'
        console.log('editList45', editList)
        console.log('editList45', index)
        renameList(editList, index)
        localStorage.setItem('project', JSON.stringify(editList))
      }
    }
  }

  const sideMenuUpperDivRef: any = useRef()
  const taskAddScrollRef: any = useRef(null)
  // const taskAddScrollValue: any = useRef(0)
  const [taskAddScrollValue, setTaskAddScrollValue]: any = useState(0)

  async function groupHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem', dataItem)
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == dataItem.id) {
        data[i].color = item.colorCode
      }
    }
    setGroupData(data)
    groupDataRef.current = data
    try {
      const response = await post(BASE_URL + '/updateprojectgroup', dataItem)
      try {
        let json = { groupId: dataItem.id };
        sendSocketMessage("updateprojectgroup", json)
      }
      catch (ex) {

      }
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function listHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem23', dataItem)
    let data = [...projectData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == dataItem.id) {
        data[i].color = item.colorCode
      }
    }
    console.log('data1010', data)
    setProjectData(data)
    projectDataRef.current = data
    try {
      const response = await post(BASE_URL + '/updateproject', dataItem)
      console.log('response232323', response)
      try {
        let json = { projectId: dataItem.id };
        sendSocketMessage("updateproject", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function projectListHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem', dataItem)
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].project.length; j++) {
        if (data[i].project[j].id == dataItem.id) {
          data[i].project[j].color = item.colorCode
        }
      }
    }
    setGroupData(data)
    groupDataRef.current = data
    try {
      const response = await post(BASE_URL + '/updateproject', dataItem)
      console.log('response232323', response)
      try {
        let json = { projectId: dataItem.id };
        sendSocketMessage("updateproject", json)
      }
      catch (ex) {

      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function taskHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('taskData123', taskData)

    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem', dataItem)
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == dataItem.id) {
        data[0][i].color = item.colorCode
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/updatetask', dataItem)
      try {
        let json = { taskId: dataItem.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      console.log('response232323', response)
      addTaskUpdateByOtherUser(dataItem, 'highlight')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function bugFun(item) {
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        if (item.taskBug == '0') {
          data[0][i].taskBug = '1'
          data[0][i].taskHeading = '0'
          data[0][i].taskNote = '0'
        } else {
          data[0][i].taskBug = '0'
        }
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/updatetask', item)
      console.log('response232323', response)
      try {
        let json = { taskId: item.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      addTaskUpdateByOtherUser(item, 'bug')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function convertNote(item) {
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        if (item.taskNote == '0') {
          data[0][i].taskNote = '1'
          data[0][i].taskBug = '0'
          data[0][i].taskHeading = '0'
        } else {
          data[0][i].taskNote = '0'
        }
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/updatetask', item)
      console.log('response232323', response)
      try {
        let json = { taskId: item.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      addTaskUpdateByOtherUser(item, 'note')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function handleChangeTaskName(e, item) {
    setEditList({})
    console.log('item11', item)
    console.log('e.target.innerText', e.target.innerText)
    e.target.blur()
    forEditTextTaskName(item)
    let data: any = [...taskData]

    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        data[0][i].taskName = encData(e.target.innerText)
        data[0][i].isEncrypt = '1'
      }
    }
    setTaskMainData(data[0])
    setTaskData(data)
    console.log('data1144', data)
    updateTask(item)
  }
  const handleTaskNameKeyDown = (e, item) => {
    if (item.taskHeading == 0) {
      if (e.keyCode !== 8 && e.target.innerText.length >= 255) {
        e.preventDefault()
      }
    } else {
      if (e.keyCode !== 8 && e.target.innerText.length >= 100) {
        e.preventDefault()
      }
    }
    if (e.keyCode === 13) {
      setEditList({})
      e.target.blur()
      console.log('item00', item)
      // item.editTextTaskName = false
      handleChangeTaskName(e, item)
    }
  }

  async function updateTask(taskItem) {
    try {
      const response = await post(BASE_URL + '/updatetask', taskItem)
      console.log('updateTask', response)
      try {
        let json = { taskId: taskItem.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      addTaskUpdateByOtherUser(taskItem, 'updateTaskName')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function addTaskUpdateByOtherUser(item, changeType) {
    // isset($data->taskId) && isset($data->taskUserId) && isset($data->userId) && isset($data->type)

    let tempProjectGroupId = "0";
    let tempGroupData = [...groupData];
    for (let i = 0; i < tempGroupData.length; i++) {
      for (let j = 0; j < tempGroupData[i].project.length; j++) {
        if (tempGroupData[i].project[j].id == item.projectId) {
          tempProjectGroupId = tempGroupData[i].id;
          break;
        }
      }
    }


    if (item.userid != userId) {
      let json = {
        taskId: item.id,
        taskUserId: item.userid,
        userId: userId,
        type: changeType,
        projectId: item.projectId,
        projectGroupId: tempProjectGroupId
      }
      console.log('json123', json)

      let data: any = [...taskMainData]
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == item.id) {
          data[i].isTaskUpdate = "1"
        }
      }
      setTaskMainData(data)
      setTaskData([data])


      try {
        const response = await post(BASE_URL + '/addtaskupdatebyotheruser', json)
        try {
          let json = { taskId: item.id };
          sendSocketMessage("add task", json)
        }
        catch (ex) {

        }
        getTaskUserActivity(userId)
        console.log('updateTask', response)
      } catch (ex) {
        console.log('response9211', ex)
      }
    }
  }


  async function addTaskUpdateByCurrentUser(item, changeType) {
    // isset($data->taskId) && isset($data->taskUserId) && isset($data->userId) && isset($data->type)

    let tempProjectGroupId = "0";
    let tempGroupData = [...groupData];
    for (let i = 0; i < tempGroupData.length; i++) {
      for (let j = 0; j < tempGroupData[i].project.length; j++) {
        if (tempGroupData[i].project[j].id == item.projectId) {
          tempProjectGroupId = tempGroupData[i].id;
          break;
        }
      }
    }

    let json = {
      taskId: item.id,
      taskUserId: item.userid,
      userId: userId,
      type: changeType,
      projectId: item.projectId,
      projectGroupId: tempProjectGroupId
    }
    console.log('json123', json)

    let data: any = [...taskMainData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isTaskUpdate = "1"
      }
    }
    setTaskMainData(data)
    setTaskData([data])


    try {
      const response = await post(BASE_URL + '/addtaskupdatebycurrentuser', json)
      try {
        let json = { taskId: item.id };
        sendSocketMessage("add task", json)
      }
      catch (ex) {

      }
      getTaskUserActivity(userId)
      console.log('updateTask', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }





  function forEditTextTaskName(item) {
    if (item.editTextTaskName) {
      item.editTextTaskName = false
    } else {
      item.editTextTaskName = true
    }
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        data[0][i].editTextTaskName = true
        console.log('data[0][i]', data[0][i])
      } else {
        data[0][i].editTextTaskName = false
      }
    }
    console.log('data45', data)

    setTaskData(data)
    setTaskMainData(data[0])
  }
  function forEditTextTaskNameFalse(item) {
    if (item.editTextTaskName) {
      item.editTextTaskName = false
    }
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      data[0][i].editTextTaskName = false
    }
    console.log('data45', data)
    setTaskData(data)
    setTaskMainData(data[0])
  }

  /* 09/05 */
  async function moveTaskToList(groupItem, dataItem) {
    console.log('groupItem', groupItem)
    console.log('dataItem', dataItem)
    let taskData1: any = [...taskData]
    let updateitem = dataItem
    let updateIndex = -1
    for (let i = 0; i < taskData1[0].length; i++) {
      if (taskData1[0][i].id == dataItem.id) {
        taskData1[0][i].projectId = groupItem.id
        updateitem = taskData1[0][i]
        updateIndex = i
        taskData1[0].splice(i, 1)
        taskData1[0].splice(0, 0, updateitem)
      }
    }

    console.log('updateitem', updateitem)
    setTaskData(taskData1)
    try {
      const response = await post(BASE_URL + '/updatetaskproject', updateitem)
      try {
        let json = { taskId: dataItem.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      console.log('updateTask', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function moveListToGroup(groupItem, dataItem) {
    console.log('groupItem', groupItem)
    console.log('dataItem', dataItem)
    setMenuListOpen(false)

    let tempProjectOrder: any = "";

    for (let i = 0; i < groupItem.project.length; i++) {
      if (i == 0) {
        tempProjectOrder = groupItem.project[i].id;
      }
      else {
        tempProjectOrder = tempProjectOrder + "," + groupItem.project[i].id;
      }
    }

    if (tempProjectOrder == "") {
      tempProjectOrder = dataItem.id;
    }
    else {
      tempProjectOrder = dataItem.id + "," + tempProjectOrder;
    }
    console.log('tempProjectOrder', tempProjectOrder)
    console.log('groupItem.projectOrder', groupItem.projectOrder)
    try {

      let json: any = {
        userId: userId,
        groupProjectOrder: tempProjectOrder,
        groupId: groupItem.id,
        projectId: dataItem.id,
      }
      console.log('responseList', json)
      //return;
      const response = await post(BASE_URL + '/movelisttogroup', json)
      console.log('responseList', response)
      try {
        let json = { projectId: dataItem.id };
        sendSocketMessage("movelisttogroup", json)
      }
      catch (ex) {

      }
      setMultiSnackbarMsg('List moved successfully.')
      handleClickMultiSnackBar(SlideTransition)
      try {
        let tempDataItem: any = { ...dataItem }
        tempDataItem.projectGroupId = groupItem.id
        tempDataItem.orginalProjectGroupId = groupItem.id
        setDataItem(tempDataItem)
        setListItem(tempDataItem)
        listItemRef.current = tempDataItem
        setSelectedProject(tempDataItem.id, tempDataItem.projectName)
        localStorage.setItem('project', JSON.stringify(tempDataItem))
      }
      catch (ex) {

      }
      getProjectWithGroup(userId, 0)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  /* 09/05 */

  function handleDimension(pageX, pageY) {
    // console.log('pageX',pageX)
    // console.log('pageY',pageY)
    // console.log('WindowWidth',windowWidth)
    // console.log('windowHeight',windowHeight)
    let totalForWidth = windowWidth - 240
    // let totalForHeight = windowHeight - 310
    let totalForHeight = windowHeight - 100
    if (pageY > totalForHeight && pageX > totalForWidth) {
      setTaskMenuListpoints({
        x: totalForWidth,
        y: totalForHeight
      })
    } else if (pageX > totalForWidth) {
      setTaskMenuListpoints({
        x: totalForWidth,
        y: pageY
      })
    } else if (pageY > totalForHeight) {
      setTaskMenuListpoints({
        x: pageX,
        y: totalForHeight
      })
    } else {
      setTaskMenuListpoints({
        x: pageX,
        y: pageY
      })
    }
  }

  // const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    cursor: isDragging ? 'all-scroll' : 'pointer',
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    // borderTop: isDragging ? '2px solid #787878' : '2px solid transparent',
    // change background colour if dragging
    // background: isDragging ? 'rgb(206,214,217)' : 'transparent',
    // boxShadow: isDragging ?  'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' : '',
    // width : isDragging ? '250px' : 'auto',

    // styles we need to apply on draggables
    ...draggableStyle
  })
  const getListStyle = isDraggingOver => ({
    borderBottom: isDraggingOver
      ? '2px solid #787878'
      : '2px solid transparent',
    borderTop: isDraggingOver ? '2px solid #787878' : '2px solid transparent',
    borderRight: isDraggingOver ? '2px solid transparent' : 'none',
    marginLeft: isDraggingOver ? '12px' : '',
    transition: isDraggingOver ? 'none' : 'none',
    background: isDraggingOver ? '#FFFFFF66' : 'transparent',
    color: isDraggingOver ? '#fff' : '#fff',
    height: mobileView ? 48 : 33
  })
  const getGroupStyle = isDraggingOver => ({
    borderBottom: isDraggingOver
      ? '2px solid #787878'
      : '2px solid transparent',
    borderTop: isDraggingOver ? '2px solid #787878' : '2px solid transparent',
    transition: isDraggingOver ? 'none' : 'none',
    background: isDraggingOver ? '#FFFFFF66' : 'transparent',
    height: mobileView ? 48 : 35
  })
  const getGroupListStyle = isDraggingOver => ({
    borderBottom: isDraggingOver
      ? '2px solid #787878'
      : '2px solid transparent',
    borderTop: isDraggingOver ? '2px solid #787878' : '2px solid transparent',
    transition: isDraggingOver ? 'none' : 'none',
    background: isDraggingOver ? 'rgb(34, 56, 83)' : 'transparent'
    // height: 35
  })

  function mouseEnter(item) {

    let data = [...projectData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isShowThreeDots = true
      }
    }
    setProjectData(data)
    projectDataRef.current = data
  }

  function mouseLeave(item) {
    let data = [...projectData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isShowThreeDots = false
      }
    }
    setProjectData(data)
    projectDataRef.current = data
  }

  function mouseEnterForGroup(item) {

    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isShowThreeDots = true
      }
    }
    setGroupData(data)
    groupDataRef.current = data
  }

  function mouseLeaveForGroup(item) {
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isShowThreeDots = false
      }
    }
    setGroupData(data)
    groupDataRef.current = data
  }

  function mouseEnterForGroupList(item, pitem) {
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        for (let j = 0; j < data[i].project.length; j++) {
          if (data[i].project[j].id == pitem.id) {
            data[i].project[j].isShowThreeDots = true
          }
        }
      }
    }
    setGroupData(data)
    groupDataRef.current = data
  }

  function mouseLeaveForGroupList(item, pitem) {
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        for (let j = 0; j < data[i].project.length; j++) {
          if (data[i].project[j].id == pitem.id) {
            data[i].project[j].isShowThreeDots = false
          }
        }
      }
    }
    setGroupData(data)
    groupDataRef.current = data
  }

  const searchProject = searchValue => {
    setProjectListInput(searchValue)
    let data: any = [...projectData]
    if (projectListInput !== '') {
      const filteredData: any = data.filter(item => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(projectListInput.toLowerCase())
      })
      console.log('filteredData', filteredData)
      filteredResults.current = filteredData
      // setFilteredResults(filteredData)
    } else {
      // setFilteredResults(data)
      filteredResults.current = data
    }
  }

  function onContentEditableLinksMouseDown(e) {
    console.log('e.target.closest', e)
    if (e.target.href && e.button === 0) {
      window.open(e.target.href)
    }
  }

  const handleChangeInviteLink = val => {
    setInviteLink(val)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenuList = Boolean(anchorEl)
  const handleClickMenuList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenuList = () => {
    setAnchorEl(null)
  }

  const [trashAnchorEl, setTrashAnchorEl] = React.useState<null | HTMLElement>(null)
  const openTrashMenuList = Boolean(trashAnchorEl)
  const handleClickTrashMenuList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTrashAnchorEl(event.currentTarget)
  }
  const handleCloseTrashMenuList = () => {
    setTrashAnchorEl(null)
  }

  const [completeAnchorEl, setCompleteAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const openCompleteMenuList = Boolean(completeAnchorEl)
  const handleClickCompleteMenuList = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setCompleteAnchorEl(event.currentTarget)
  }
  const handleCloseCompleteMenuList = () => {
    setCompleteAnchorEl(null)
  }

  const tawkMessengerRef: any = useRef()
  const onLoadChat = () => {
    // tawkMessengerRef?.current?.hideWidget()
    //tawkMessengerRef.current.toggle();
  }



  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }


  function handleClickMultiSnackBar(Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>,) {
    setOpenMultiSnackBar({
      open: true,
      Transition,
    });
  };

  const [openMultiSnackBar, setOpenMultiSnackBar] = React.useState<{ open: boolean; Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>; }>({
    open: false,
    Transition: Fade,
  });

  function handleCloseMultiSnackBar() {
    setOpenMultiSnackBar({ ...openMultiSnackBar, open: false, });
  };







  function handleClickSnack(Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>,) {
    setOpenSnackBar({
      open: true,
      Transition,
    });
  };

  const [openSnackBar, setOpenSnackBar] = React.useState<{ open: boolean; Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>; }>({
    open: false,
    Transition: Fade,
  });

  function handleCloseSnack() {
    setOpenSnackBar({ ...openSnackBar, open: false, });
  };

  const action = (
    <React.Fragment>
      <img style={{ width: 22, cursor: 'pointer' }} onClick={() => { console.log('snack45'); handleCloseSnack(); restoreTrashTask(dataItem) }} src={SnackBarUndoIcon} alt="" />
    </React.Fragment>
  );
  const snackMsg = (
    <div style={{ display: 'flex' }}>
      <span className='oneLine-ellipsis' style={{ maxWidth: '20ch', marginRight: 2 }}>{decData(dataTrashItem.taskName, dataTrashItem.isEncrypt)} </span> Moved to trash
    </div>
  );



  function handleClickSwipeSnack(Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>,) {
    setOpenSwipeSnackBar({
      open: true,
      Transition,
    });
  };

  const [openSwipeSnackBar, setOpenSwipeSnackBar] = React.useState<{ open: boolean; Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>; }>({
    open: false,
    Transition: Fade,
  });

  function handleCloseSwipeSnack() {
    setOpenSwipeSnackBar({ ...openSwipeSnackBar, open: false, });
  };

  const swipeAction = (
    <React.Fragment>
      <img style={{ width: 22, cursor: 'pointer' }} onClick={() => { console.log('snack45'); handleCloseSwipeSnack(); restoreTrashTask(dataItemRef.current) }} src={SnackBarUndoIcon} alt="" />
    </React.Fragment>
  );
  const swipeSnackMsg = (
    <div style={{ display: 'flex' }}>
      <span className='oneLine-ellipsis' style={{ maxWidth: '20ch', marginRight: 2 }}>{decData(dataTrashItem.taskName, dataTrashItem.isEncrypt)} </span> Moved to trash
    </div>
  );



  function handleClickTrashSnack(Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>,) {
    setOpenTrashSnackBar({
      open: true,
      Transition,
    });
    console.log('list890', dataItem)
    // setRestoreTrashItemListName
    let tempSelectedData: any = {};
    let tempProjectData: any = [...projectData]
    let tempGroupData: any = [...groupData];
    for (let i = 0; i < tempProjectData.length; i++) {
      if (tempProjectData[i].id == dataItem.projectId) {
        tempSelectedData = tempProjectData[i];
        break;
      }
    }

    for (let i = 0; i < tempGroupData.length; i++) {
      for (let j = 0; j < tempGroupData[i].project.length; j++) {
        if (tempGroupData[i].project[j].id == dataItem.projectId) {
          tempSelectedData = tempGroupData[i].project[j];
          break;
        }
      }
    }

    if (tempSelectedData && tempSelectedData.id) {

    }
    else {
      let tempProjectId = userData.defaultProjectID;
      for (let i = 0; i < tempProjectData.length; i++) {
        if (tempProjectData[i].id == tempProjectId) {
          tempSelectedData = tempProjectData[i];
          break;
        }
      }
    }
    setRestoreTrashItemList(tempSelectedData)
    console.log("list891", tempSelectedData);

  };

  const [openTrashSnackBar, setOpenTrashSnackBar] = React.useState<{ open: boolean; Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>; }>({
    open: false,
    Transition: Fade,
  });

  function handleCloseTrashSnack() {
    setOpenTrashSnackBar({ ...openTrashSnackBar, open: false, });
  };

  // const trashAction = (
  //   <React.Fragment>
  //    <img style={{width:22,cursor:'pointer'}} onClick={()=>{console.log('snack45');handleCloseTrashSnack();restoreTrashTask(dataItem)}} src={SnackBarUndoIcon} alt="" />
  //   </React.Fragment>
  // );
  const trashSnackMsg = (
    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { setSelectedProject(restoreTrashItemList.id, restoreTrashItemList.projectName); setListItem({ ...restoreTrashItemList }); listItemRef.current = { ...restoreTrashItemList } }}>
      <span>Restored to {decData(restoreTrashItemList.projectName, restoreTrashItemList.isEncrypt)} <img style={{ width: 12 }} src={RestoreArrow} alt="" /></span>
    </div>
  );



  function SearchFun(e) {
    setSearchBarInputValue(e.target.value)
    if (e.target.value != '') {
      setCompleteTaskShow(true)
    }
    else {
      setCompleteTaskShow(false)
    }
  }



  function timeSince(date: any) {
    try {
      /* var startTime = new Date(date);
      return moment(startTime).format('MMM Do YYYY, hh:mm A'); */

      let currentDate: any = new Date(new Date().toISOString());
      let pastData: any = new Date(date);
      // console.log("currentDate", currentDate);
      // console.log("pastDate", pastData);
      var seconds = Math.floor((currentDate - pastData) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      //return Math.floor(seconds) + " seconds";
      return "Just Now";
    }
    catch (ex) {
      return "";

    }
  }


  async function handleClickNotificationItem(item) {
    console.log('item45', item)





    let tempGroupData = [...groupData]
    for (let i = 0; i < tempGroupData.length; i++) {
      for (let j = 0; j < tempGroupData[i].project.length; j++) {
        if (tempGroupData[i].project[j].id == item.projectId) {
          setListItem(tempGroupData[i].project[j])
          listItemRef.current = tempGroupData[i].project[j]
          localStorage.setItem('project', JSON.stringify(tempGroupData[i].project[j]))
          if (tempGroupData[i].project[j].orginalProjectGroupId != "0") {
            // openGroup(tempGroupData[i])
            tempGroupData[i].open = true
            let data = [...groupData]
            for (let i = 0; i < data.length; i++) {
              if (data[i].id == tempGroupData[i].id) {
                data[i].open = tempGroupData[i].open
              }
            }
            setGroupData(data)
            groupDataRef.current = data
            console.log('item445', tempGroupData[i])

          }
          break;
        }
      }
    }
    let tempProjectData = [...projectData]
    for (let i = 0; i < tempProjectData.length; i++) {
      if (tempProjectData[i].id == item.projectId) {
        setListItem(tempProjectData[i])
        listItemRef.current = tempProjectData[i]
        localStorage.setItem('project', JSON.stringify(tempProjectData[i]))
        break;
      }
    }

    let tempTaskData = [...taskData]
    let status = false
    for (let i = 0; i < tempTaskData[0].length; i++) {
      if (tempTaskData[0][i].id == item.taskId && tempTaskData[0][i].isTrash != '2') {
        setTaskItem(tempTaskData[0][i])
        taskItemRef.current = tempTaskData[0][i];
        addTaskUpdateByCurrentUser(tempTaskData[0][i], 'notification task')
        setTaskSectionOpen(true)
        status = true;
        break;
      }
    }
    if (status) {
      setSelectedProject(item.projectId, item.projectName)
      setOpenAllNotificationDialog(false)
      console.log('item45')
    }
    else {
      handleTaskExistsConfirm()
      console.log('item45')
    }

    try {
      const config = {
        notificationId: item.id
      }
      const response = await post(BASE_URL + '/marksingleread', config)
      getNotifications(userData)
      console.log('marksingleread', response)
    } catch (ex) {
      console.log('response9211', ex)
    }


  }

  async function refreshFun(pane: any) {
    if (pane == 'left') {
      setRefreshLoader(true)
    }
    if (pane == 'middle') {
      setRefreshLoaderMiddle(true)
    }
    if (pane == 'right') {
      setRefreshLoaderRight(true)
    }
    //  window.location.reload();
    await getTask(userId);
    await getProjectWithGroup(userId, 0);
    getUserById(userId)
    getAllUserByIds()
    getTaskUserActivity(userId)
    getUserShare(userId)
    setRefreshLoader(false)
    setRefreshLoaderMiddle(false)
    setRefreshLoaderRight(false)
  }
  async function refreshFocusFun() {
      setRefreshFocusLoader(true)
    //  window.location.reload();
    await getTask(userId);
    await getProjectWithGroup(userId, 0);
    getUserById(userId)
    getAllUserByIds()
    getTaskUserActivity(userId)
    getUserShare(userId)
    setRefreshFocusLoader(false)
    setRefreshLoader(false)
    setRefreshLoaderMiddle(false)
    setRefreshLoaderRight(false)
  }


  function leftPaneMobile() {
    if (mobileView) {
      setTimeout(() => {
        setHideShowLeftPane(true)
      }, 350);
      setHideShowMiddlePane(false)
      slideType.current = "middle"
      setTaskAddScrollValue(0);
      // getTask(userId)
      // getProjectWithGroup(userId, 0)
    }
    else {
    }
  }
  function middlePaneMobile() {
    if (mobileView) {
      setHideShowRightPane(false)
      setTimeout(() => {
        setHideShowMiddlePane(true)
      }, 350);
      slideType.current = "right"
      // setTaskAddScrollValue(0);
      // getTask(userId)
      // getProjectWithGroup(userId, 0)
    }
    else {
    }
  }
  function middlePaneMobileBack() {
    if (mobileView) {
      setTimeout(() => {
        setHideShowMiddlePane(true);
      }, 350);
      setHideShowLeftPane(false);
      slideType.current = "leftback"
      setTaskAddScrollValue(0);
      // getTask(userId)
      // getProjectWithGroup(userId, 0)
    }
    else {
    }
  }
  function rightPaneMobileBack() {
    if (mobileView) {
      setTimeout(() => {
        setHideShowRightPane(true)
      }, 350);
      setHideShowMiddlePane(false)
      slideType.current = "middleback";
      // setTaskAddScrollValue(0);
      // getTask(userId)
      // getProjectWithGroup(userId, 0)
    }
  }



  async function markAsRead() {

    try {
      const config = {
        userId: userId
      }
      const response = await post(BASE_URL + '/markallread', config)
      setMultiSnackbarMsg('All notifications marked as read')
      handleClickMultiSnackBar(SlideTransition)
      setNotificationUnreadCount("0")
      getNotifications(userData)
      console.log('markallread', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function deleteSingleNotification(item, index) {

    try {
      console.log('item', item)
      const config = {
        userid: userId,
        notificationId: item.id
      }
      let tempData = [...allNotificationData]
      tempData.splice(index, 1);
      setAllNotificationData(tempData)
      setMultiSnackbarMsg('Notifications delete successfully!')
      handleClickMultiSnackBar(SlideTransition)
      console.log('config', config);
      const response = await post(BASE_URL + '/deletenotification', config)

      if (response && response.data && response.data.data && response.data.data.length > 0) {
        setAllNotificationData(response.data.data)
        setNotificationUnreadCount(response.data.unreadCount)
        console.log('response234512345', response.data.unreadCount)
        console.log('response234512345', response.data.data)
      }
      else {
        setAllNotificationData([])
        setNotificationUnreadCount("0")
      }
      //setNotificationUnreadCount("0")
      //getNotifications(userData)
      console.log('markallread', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function deleteAllNotificaton() {

    try {
      const config = {
        userid: userId
      }
      console.log('config', config);
      setAllNotificationData([])
      setMultiSnackbarMsg('All notifications delete successfully!')
      handleClickMultiSnackBar(SlideTransition)
      const response = await post(BASE_URL + '/deleteallnotifications', config)
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        setAllNotificationData(response.data.data)
        setNotificationUnreadCount(response.data.unreadCount)
        console.log('response234512345', response.data.unreadCount)
        console.log('response234512345', response.data.data)
      }
      else {
        setAllNotificationData([])
        setNotificationUnreadCount("0")
      }
      console.log('markallread', response)
      /* setMultiSnackbarMsg('All notifications delete successfully!')
      handleClickMultiSnackBar(SlideTransition) */
      //setNotificationUnreadCount("0")
      //getNotifications(userData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  const handleDeleteAllNotiConfirm = () => {
    confirmAlert({ customUI: deleteAllNotiDialog })
  }

  const deleteAllNotiDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      onClose()
    }
    const handleClickedYes = async () => {
      await deleteAllNotificaton()
      //setAllNotificationData([])
      onClose()
    }

    return (
      <>
        <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "10px" }}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}> Delete All Notifications</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
            </div>
          </div>
          <div className='add-dialog-inner-div'>
            <p>
              Are you sure, You want to delete all notifications.
            </p>
            <div className='add-dialog-buttons' style={{ marginTop: 25, justifyContent: 'space-between' }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedYes}>
                Ok
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedNo}>Cancle</button>
            </div>
          </div>
        </div>
      </>
    )
  }






  function switchAccount(item) {
    setOpenSwitchAccountDialog(false)
    setSwitchLoader(true)
    console.log('switchAccountItem45', item)
    localStorage.removeItem("project")
    localStorage.setItem("user", JSON.stringify(item.userData));
    localStorage.setItem("token", item.token);
    window.location.reload();
  }

  function switchAccountForNotification(currentUserId) {
    // console.log('switchAccountItem45', item)
    // switchUserData
    let idJson: any = [];
    let switchData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
    console.log('idJson45', switchData)
    for (let i = 0; i < switchData.length; i++) {
      if (switchData[i].userData.id != currentUserId) {
        idJson.push(switchData[i].userData.id);
      }
    }
    console.log('idJson45', idJson.toString());
    logOutAll(idJson)
  }



  const shareConfirmPopUpMobile = (sharedListData) => {

    return (
      <div>
        <Dialog
          open={shareConfirmPopUpMobileDialog}
          onClose={() => { setShareConfirmPopUpMobileDialog(false) }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <ClickAwayListener onClickAway={() => { setSharedListData([]); setOpenDialog(true); setShareConfirmPopUpMobileDialog(false) }}>
            <div className='switchDialogMainDiv' style={{ textAlign: 'center', background: colorSchemes.DrawerBackColor }}>
              <div style={{ textAlign: 'end', width: '100%' }}>
                <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setSharedListData([]); setOpenDialog(true); setShareConfirmPopUpMobileDialog(false) }} src={CloseMenuListIcon} alt='' />
              </div>
              <div className="manageAccountContainer" style={{ marginTop: -24 }}>
                <h4 style={{ color: colorSchemes.FontBlackColor }}>Share {selectedShareType == "project" ? "list" : "Folder"}</h4>
                <div>
                  <div style={{ padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 20, marginTop: 30, fontSize: 18 }}>
                    <span style={{ color: colorSchemes.FontSemiGrey, textAlign: 'center' }}>Click invite below to send an invite email to:</span>
                    {sharedListData.map((item, index) => (
                      <span style={{ fontSize: 18, fontWeight: 600, color: colorSchemes.FontSemiGrey }}>{item.toUserEmail}</span>
                    ))}
                  </div>
                  <div className='shareDialogInviteBtn' style={{ display: 'flex', marginTop: 40, justifyContent: 'flex-end', gap: 0, marginBottom: 0 }}>
                    <button
                      style={{
                        background: '#fff',
                        color: '#666',
                        border: '0.1px solid rgba(0, 0, 0, 0.3)',
                      }}
                      onClick={() => { setSharedListData([]); setOpenDialog(true); setShareConfirmPopUpMobileDialog(false) }}
                    >
                      Back
                    </button>
                    <button
                      style={{ background: 'rgb(71,114,250)', color: '#fff' }}
                      onClick={() => { inviteUser(); setOpenDialog(true); setShareConfirmPopUpMobileDialog(false) }}
                    >
                      Invite
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </Dialog>
      </div>
    )
  }










  const useStyles = makeStyles((theme) =>
    createStyles({
      active: {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
      }
    })
  );

  type RecursiveMenuItemProps = MenuItemProps & {
    button?: true;
    label: string;
    icon: any;
    arrowIcon: any;
  } & Pick<PopperProps, "placement">;
  const RecursiveMenuItem = (props: RecursiveMenuItemProps) => {
    const classes = useStyles();
    const [openPopperList, setOpenPopperList] = useState(false);
    const ref = useRef<HTMLLIElement | null>(null);

    return (
      <MenuItem
        {...props}
        ref={ref}
        className={openPopperList ? classes.active : ""}
        onMouseEnter={() => { setOpenPopperList(true); console.log('openPopperList45', openPopperList) }}
        // onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        onMouseLeave={() => { setOpenPopperList(false); console.log('openPopperList45', openPopperList) }}
        style={{ padding: 0 }}
      >
        <div className='settingModalItems ' style={mobileView ? { padding: '6px 16px', gap: 10, fontSize: 12 } : {}}>
          <img
            style={{
              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
              width: 14,
              cursor: 'pointer',
              marginRight: 2
            }}
            src={mobileView ? props.icon/* GroupIconDark */ : props.icon}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <span className='oneLine-ellipsis' style={mobileView ? { fontSize: 14 } : {}}>{props.label}</span>
            <img
              style={{
                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                width: 13,
                cursor: 'pointer',
                marginRight: 2
              }}
              src={mobileView ? props.arrowIcon/* RightWhiteArrow */ : props.arrowIcon}
            />
          </div>
        </div>
        <Popper
          anchorEl={ref.current}
          open={openPopperList}
          placement={props.placement ?? "right"}
          modifiers={{
            flip: {
              enabled: true
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "viewport"
            }
          }}
          style={{ zIndex: 999 }}
        >
          {props.children}
          {/* <div style={{fontSize:18,color:'black'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores suscipit doloremque blanditiis nobis cum, earum quasi, nulla dolorem hic atque error, minima iusto quibusdam repellendus! Voluptatibus ipsam quo recusandae cumque!ˀ</div> */}
        </Popper>
      </MenuItem>
    );
  };


  const RecursiveMenu = () => {
    return (
      <div style={{ display: "flex" }}>
        {!mobileView ?
          <Paper elevation={4}>
            <MenuList /*autoFocus={true}*/ style={{ minWidth: 240, maxWidth: 240, maxHeight: windowHeight - 100, overflowY: "auto", backgroundColor: "#fff" }}>
              {/* <MenuItem >text</MenuItem> */}
              {projectData &&
                projectData.length > 0 &&
                projectData.map((item: any, i: any) => (
                  <MenuItem
                    className={
                      item.id == selectedProjectID
                        ? 'settingModalItems moveListItemActive'
                        : 'settingModalItems  moveListItem'
                    }
                    onClick={() => {
                      moveTaskToList(item, dataItem)
                      setMenuPosition(null)
                      handleCloseMenuList()
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: 10
                      }}
                    >
                      {item.id == selectedProjectID ? (
                        <img
                          src={ListIconActive}
                          alt=''
                        />
                      ) : (
                        <img src={isDark == 1 ? ListIconActive : ListIcon} alt='' />
                      )}
                      <span>
                        {decData(
                          item.projectName,
                          item.isEncrypt
                        )}
                      </span>
                    </div>
                    {item.id == selectedProjectID ? (
                      <div>
                        <img
                          src={DontAllowCheck}
                          alt=''
                        />
                      </div>
                    ) : null}
                  </MenuItem>
                ))}

              {groupData &&
                groupData.length > 0 &&
                groupData.map((item: any, i: any) => (
                  <RecursiveMenuItem autoFocus={false} label={decData(item.groupName, item.isEncrypt)} icon={GroupIcon} arrowIcon={RightBlackArrow}>
                    <RecursiveMenu2 project={item.project} />
                  </RecursiveMenuItem>
                ))}
            </MenuList>
          </Paper>
          :
          <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: 999, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <ClickAwayListener onClickAway={(e) => { setMoveTaskListShow(false) }}>
              <div>
                <Paper elevation={4}>
                  <MenuList /*autoFocus={true}*/ style={{ minWidth: 180, maxWidth: 180, maxHeight: windowHeight - 100, overflowY: "auto"/* , background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey */ }}>
                    {/* <MenuItem >text</MenuItem> */}
                    {projectData &&
                      projectData.length > 0 &&
                      projectData.map((item: any, i: any) => (
                        <MenuItem
                          className={
                            item.id == selectedProjectID
                              ? 'settingModalItems moveListItemActive'
                              : 'settingModalItems  moveListItem'
                          }
                          onClick={() => {
                            moveTaskToList(item, dataItem)
                            setMenuPosition(null)
                            handleCloseMenuList()
                            setMoveTaskListShow(false)
                          }}

                        >
                          <div
                            style={{
                              display: 'flex',
                              gap: 10,
                              alignItems: 'center'
                            }}
                          >
                            {item.id == selectedProjectID ? (
                              <img
                                src={ListIconActive}
                                alt=''
                              />
                            ) : (
                              <img src={isDark == 1 ? ListIconActive : ListIcon} alt='' />
                            )}
                            <span style={{ fontSize: 14 }} className='oneLine-ellipsis'>
                              {decData(
                                item.projectName,
                                item.isEncrypt
                              )}
                            </span>
                          </div>
                          {item.id == selectedProjectID ? (
                            <div>
                              <img
                                src={DontAllowCheck}
                                alt=''
                              />
                            </div>
                          ) : null}
                        </MenuItem>
                      ))}

                    {groupData &&
                      groupData.length > 0 &&
                      groupData.map((item: any, i: any) => (
                        <RecursiveMenuItem autoFocus={false} label={decData(item.groupName, item.isEncrypt)} icon={GroupIcon} arrowIcon={RightBlackArrow}>
                          <RecursiveMenu2 project={item.project} />
                        </RecursiveMenuItem>
                      ))}
                  </MenuList>
                </Paper>
              </div>
            </ClickAwayListener>
          </div>
        }
      </div>
    );
  };

  const RecursiveMenu2 = (project) => {
    console.log('project', project)
    return (
      <>
        {mobileView ?
          <div style={{ zIndex: 999, display: 'flex', justifyContent: 'flex-start' }}>
            <div>
              <Paper elevation={4} style={{/* background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey */ }}>
                <MenuList /*autoFocus={true}*/ style={{ minWidth: 180, maxWidth: 180, maxHeight: windowHeight - 100, overflowY: "auto"/* , background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey */ }}>
                  {/* <MenuItem >text</MenuItem> */}
                  {project && project.project &&
                    project.project.length > 0 &&
                    project.project.map((item: any, i: any) => (
                      <MenuItem
                        className={
                          item.id == selectedProjectID
                            ? 'settingModalItems moveListItemActive'
                            : 'settingModalItems  moveListItem'
                        }
                        onClick={() => {
                          moveTaskToList(item, dataItem)
                          setMenuPosition(null)
                          handleCloseMenuList()
                          if (mobileView) {
                            setMoveTaskListShow(false)
                            setDrawerMoveTaskTo(false)
                          }
                        }}
                        style={{ padding: '6px 16px' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 10
                          }}
                        >
                          {item.id == selectedProjectID ? (
                            <img
                              src={ListIconActive}
                              alt=''
                            />
                          ) : (
                            <img src={isDark == 1 ? ListIconActive : ListIcon} alt='' />
                          )}
                          <span style={{ fontSize: 14 }}>
                            {decData(
                              item.projectName,
                              item.isEncrypt
                            )}
                          </span>
                        </div>
                        {item.id == selectedProjectID ? (
                          <div>
                            <img
                              src={DontAllowCheck}
                              alt=''
                            />
                          </div>
                        ) : null}
                      </MenuItem>
                    ))}
                </MenuList>
              </Paper>
            </div>
          </div>
          :
          <div style={{ display: "flex", width: mobileView ? '100%' : 'auto' }}>
            <Paper elevation={mobileView ? 0 : 4} style={{ width: mobileView ? '100%' : 'auto' }}>
              <MenuList /*autoFocus={true}*/ style={{ minWidth: mobileView ? '100%' : 240, maxWidth: mobileView ? '100%' : 240, maxHeight: mobileView ? 'auto' : windowHeight - 100, overflowY: "auto", background: colorSchemes.DrawerBackColor, color: colorSchemes.FontSemiGrey, padding: mobileView ? 0 : '' }}>
                {/* <MenuItem>Point 1</MenuItem> */}
                {project && project.project &&
                  project.project.length > 0 &&
                  project.project.map((item: any, i: any) => (
                    <MenuItem
                      className={
                        item.id == selectedProjectID
                          ? 'settingModalItems moveListItemActive'
                          : 'settingModalItems  moveListItem'
                      }
                      onClick={() => {
                        moveTaskToList(item, dataItem)
                        setMenuPosition(null)
                        handleCloseMenuList()
                        if (mobileView) {
                          setDrawerMoveTaskTo(false)
                        }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: 10
                        }}
                      >
                        {item.id == selectedProjectID ? (
                          <img
                            src={ListIconActive}
                            alt=''
                          />
                        ) : (
                          <img src={isDark == 1 ? ListIconActive : ListIcon} alt='' />
                        )}
                        <span>
                          {decData(
                            item.projectName,
                            item.isEncrypt
                          )}
                        </span>
                      </div>
                      {item.id == selectedProjectID ? (
                        <div>
                          <img
                            src={DontAllowCheck}
                            alt=''
                          />
                        </div>
                      ) : null}
                    </MenuItem>
                  ))}
              </MenuList>
            </Paper>
          </div>
        }
      </>
    );
  };


  const RecursiveMenuList = () => {
    return (
      <div style={{ display: "flex" }}>
        {!mobileView ?
          <Paper elevation={4}>
            <MenuList /*autoFocus={true}*/ style={{ minWidth: 240, maxWidth: 240, maxHeight: windowHeight - 100, overflowY: "auto", backgroundColor: "#fff" }}>
              {/* <MenuItem >text</MenuItem> */}
              {groupData &&
                groupData.length > 0 &&
                groupData.map((item: any, i: any) => (
                  <>
                    {item && dataItem && dataItem.orginalProjectGroupId && item.userId == userId && item.id != dataItem.orginalProjectGroupId &&
                      <MenuItem
                        className={
                          item.id == selectedProjectID
                            ? 'settingModalItems moveListItemActive'
                            : 'settingModalItems  moveListItem'
                        }
                        onClick={() => {
                          // moveTaskToList(item, dataItem)
                          moveListToGroup(item, dataItem)
                          setMenuPosition(null)
                          handleCloseMenuList()
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 10
                          }}
                        >
                          {item.id == selectedProjectID ? (
                            <img
                              src={GroupIcon}
                              alt=''
                            />
                          ) : (
                            <img src={isDark == 1 ? GroupIcon : GroupIcon} alt='' />
                          )}
                          <span>
                            {decData(item.groupName, item.isEncrypt)}
                          </span>
                        </div>
                        {item.id == selectedProjectID ? (
                          <div>
                            <img
                              src={DontAllowCheck}
                              alt=''
                            />
                          </div>
                        ) : null}
                      </MenuItem>
                    }
                  </>
                ))}

              {/* {groupData &&
                groupData.length > 0 &&
                groupData.map((item: any, i: any) => (
                  <RecursiveMenuItem autoFocus={false} label={decData(item.groupName, item.isEncrypt)} icon={GroupIcon} arrowIcon={RightBlackArrow}>
                    <RecursiveMenu2 project={item.project} />
                  </RecursiveMenuItem>
                ))} */}
            </MenuList>
          </Paper>
          :
          <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: 999, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <ClickAwayListener onClickAway={(e) => { setMoveGroupListShow(false) }}>
              <div>
                <Paper elevation={4}>
                  <MenuList /*autoFocus={true}*/ style={{ minWidth: 240, maxWidth: 240, maxHeight: windowHeight - 100, overflowY: "auto", backgroundColor: "#fff" }}>
                    {/* <MenuItem >text</MenuItem> */}
                    {groupData &&
                      groupData.length > 0 &&
                      groupData.map((item: any, i: any) => (
                        <>
                          {item && dataItem && dataItem.orginalProjectGroupId && item.userId == userId && item.id != dataItem.orginalProjectGroupId &&
                            <MenuItem
                              className={
                                item.id == selectedProjectID
                                  ? 'settingModalItems moveListItemActive'
                                  : 'settingModalItems  moveListItem'
                              }
                              onClick={() => {
                                // moveTaskToList(item, dataItem)
                                moveListToGroup(item, dataItem)
                                setMenuPosition(null)
                                handleCloseMenuList()
                                setMoveGroupListShow(false)
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10
                                }}
                              >
                                {item.id == selectedProjectID ? (
                                  <img
                                    src={GroupIcon}
                                    alt=''
                                  />
                                ) : (
                                  <img src={isDark == 1 ? GroupIcon : GroupIcon} alt='' />
                                )}
                                <span>
                                  {decData(item.groupName, item.isEncrypt)}
                                </span>
                              </div>
                              {item.id == selectedProjectID ? (
                                <div>
                                  <img
                                    src={DontAllowCheck}
                                    alt=''
                                  />
                                </div>
                              ) : null}
                            </MenuItem>
                          }
                        </>
                      ))}

                    {/* {groupData &&
                groupData.length > 0 &&
                groupData.map((item: any, i: any) => (
                  <RecursiveMenuItem autoFocus={false} label={decData(item.groupName, item.isEncrypt)} icon={GroupIcon} arrowIcon={RightBlackArrow}>
                    <RecursiveMenu2 project={item.project} />
                  </RecursiveMenuItem>
                ))} */}
                  </MenuList>
                </Paper>
              </div>
            </ClickAwayListener>
          </div>
        }
      </div>
    );
  };

  return (
    <div>
      {/* <TawkMessengerReact
        ref={tawkMessengerRef}
        propertyId='6469407274285f0ec46ca932'
        widgetId='1h0ti7fk6'
        onLoad={onLoadChat}
      /> */}


      {switchLoader &&
        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0,0,0,0.6)', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 22 }}>
            <img className="loading-spinner" style={{ width: mobileView ? '65px' : '80px', height: mobileView ? '65px' : '80px' }} src={Whiteloading} alt="" />
            <div style={{ fontSize: 16, fontWeight: 600, color: 'darkgray' }}>Switching Account...</div>
          </div>
        </div>
      }



      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackBar.open}
        onClose={handleCloseSnack}
        TransitionComponent={openSnackBar.Transition}
        message={snackMsg}
        action={action}
        key={openSnackBar.Transition.name}
        autoHideDuration={3000}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSwipeSnackBar.open}
        onClose={handleCloseSwipeSnack}
        TransitionComponent={openSwipeSnackBar.Transition}
        message={swipeSnackMsg}
        action={swipeAction}
        key={openSwipeSnackBar.Transition.name}
        autoHideDuration={3000}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openTrashSnackBar.open}
        onClose={handleCloseTrashSnack}
        TransitionComponent={openTrashSnackBar.Transition}
        message={trashSnackMsg}
        // action={trashAction}
        key={openTrashSnackBar.Transition.name}
        autoHideDuration={3000}
        className='trashSnackBar'
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openMultiSnackBar.open}
        onClose={handleCloseMultiSnackBar}
        TransitionComponent={openMultiSnackBar.Transition}
        message={multiSnackbarMsg}
        key={openMultiSnackBar.Transition.name}
        autoHideDuration={3000}
        className='InviteSnackBar1'
      />




      <div style={{ display: 'flex' }}>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
        >
          {!hideShowLeftPane &&
            <>
              <div style={{ minWidth: mobileView ? '100%' : 280, maxWidth: mobileView ? '100%' : 280, height: '100%' }}
                className={slideType.current != "" ? slideType.current == "leftback" ? 'slide-left' : 'moveToLeft' : ""}
              >
                <div style={{ ...styles.mainMenuDiv, background: colorSchemes.WebLeftPaneBg, padding: mobileView ? 0 : '0px 0px 8px 0px' }}>
                  <div className='sideMenuUpperDiv'>

                    <div>
                      {!mobileView ?
                        // <div style={mobileView ? styles.manageStuffHeadingMobile : styles.manageStuffHeading}>Manage Stuff</div>
                        <div style={{ padding: '15px 8px', display: 'flex', /* justifyContent: 'center', */ alignItems: 'center', paddingBottom: 10 }}>
                          <img style={{ width: 150 }} src={ManageStuffLogo} alt="" />
                        </div>
                        :
                        <div style={{width:"100%", display:"flex"}}>
                        <div style={{ padding: '15px 8px', display: 'flex', /* justifyContent: 'center', */ alignItems: 'center', paddingBottom: 10 }}>
                          <img style={{ width: 150 }} src={ManageStuffLogo} alt="" />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', /* marginTop: -2, */ gap: 10, width:"calc(100% - 180px)", justifyContent: "flex-end" }} >
                                
                                  <div className='settingButton' style={{ width: 22, height: 22, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { searchBarToggle() }}>
                                    <img style={styles.searchBarIcon} src={/* isDark == 1 ? MagnifierWhite : */ NewSearchIcon} />
                                  </div>
                                
                                <Tooltip title="Refresh">
                                  <div className='settingButton' style={{ width: 22, height: 22, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); refreshFun('left'); setHelpSupportPage(false) }}>
                                    <img style={{ width: 18, cursor: 'pointer', padding: 1 }} src={RefreshIcon} alt="" />
                                  </div>
                                </Tooltip>
                                <Tooltip title="Notifications">
                                  <div className='settingButton' onClick={() => { setOpenAllNotificationDialog(true); setHelpSupportPage(false) }}>
                                    {/* <img style={{width: 16,cursor:'pointer', transform: 'scaleX(-1)'}}   src={isDark == 1 ? MagnifierWhite : Magnifier} onClick={() => { searchBarToggle() }} alt="" />*/}
                                    {/* <div className='settingModalItems notificationsBellDiv'> */}
                                    <img style={{ width: 20, cursor: 'pointer', padding: 1, marginTop: -4 }} src={Bell} alt='' />
                                    {notificationUnreadCount != "0" ?
                                      <div className='notiUnreadCount'>
                                        <span style={{ padding: notificationUnreadCount.length > 1 ? '2px 4px' : '1px 5px' }}>{notificationUnreadCount}</span>
                                      </div>
                                      : null
                                    }

                                    {/* </div> */}
                                  </div>

                                </Tooltip>

                              </div>
                        </div>
                      }

                      <div className='MainProfileDiv'>
                        {/* <div style={{ width: mobileView ? "10%" : '15%' }} onClick={() => { if (mobileView) handleToggle() }}>
                        <div className='userAvtarDiv'>
                          {nameLetters}
                        </div>
                      </div> */}
                        <div className='userDetailDiv' style={mobileView ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' } : { width: '100%' }} >
                          <div style={{ width: '100%' }}>
                            <div onClick={() => { if (mobileView) handleToggle() }}
                              style={{
                                fontSize: mobileView ? 20 : 14,
                                /*  fontWeight:mobileView ?'500':'bold', */
                                // color: colorSchemes.WebFontBlackcolo,
                                color: '#fff',
                                fontFamily: mobileView ? 'ManRopeBold' : 'ManRopeBold',
                              }}
                            >
                              {firstName} {lastName}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 15 }} >
                              <div style={{ display: 'flex', alignItems: 'center' }} className='oneLine-ellipsis'>
                                <div style={{ fontSize: mobileView ? 14 : 12, color: '#fff'/* colorSchemes.WebFontGreyColor */, fontFamily: mobileView ? 'ManRope' : 'ManRope' }} className='oneLine-ellipsis' onClick={() => { if (mobileView) handleToggle() }} >{email}</div>
                                <div style={mobileView ? { marginLeft: 5 } : { marginLeft: 5 }}>
                                  <Tooltip title="Settings" >
                                    <Button
                                      ref={anchorRef}
                                      id='composition-button'
                                      aria-controls={
                                        open ? 'composition-menu' : undefined
                                      }
                                      aria-expanded={open ? 'true' : undefined}
                                      aria-haspopup='true'
                                      // onClick={()=>{tawkMessengerRef.current.toggle();}/* handleToggle */}
                                      onClick={handleToggle}
                                      className='settingButton'
                                      style={{
                                        height: 20,/*  border: '1px solid red', */width: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: mobileView ? 18 : 18,
                                          // height: mobileView ? 18 : 16
                                        }}
                                        src={WhiteDots}
                                        alt=''
                                      />
                                    </Button>
                                  </Tooltip>
                                  {!mobileView ?
                                    <Popper
                                      style={{ zIndex: 999 }}
                                      open={open}
                                      anchorEl={anchorRef.current}
                                      role={undefined}
                                      placement='bottom'
                                      transition
                                      disablePortal
                                      className='popperWidth'
                                    >
                                      {({ TransitionProps, placement }) => (
                                        <Grow
                                          {...TransitionProps}
                                          style={{
                                            transformOrigin:
                                              placement === 'bottom'
                                                ? 'left top'
                                                : 'left bottom'
                                          }}
                                        >
                                          <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                              <MenuList
                                                autoFocusItem={open}
                                                id='composition-menu'
                                                aria-labelledby='composition-button'
                                              // onKeyDown={handleListKeyDown}
                                              >
                                                <div
                                                  className='closeIconDiv'
                                                  onClick={handleClose}
                                                >
                                                  <img src={CloseMenuListIcon} alt='' />
                                                </div>
                                                <MenuItem /* className="customCursor" */
                                                  onClick={(e) => { handleClose(e); refreshFun('left'); setHelpSupportPage(false) }}
                                                  style={{ padding: 0 }}
                                                >
                                                  <div className='settingModalItems'>
                                                    <img src={RefreshGreyIcon} alt='' />
                                                    <span>Refresh</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem /* className="customCursor" */
                                                  onClick={(e) => { handleClose(e); setOpenAllNotificationDialog(true); setHelpSupportPage(false) }}
                                                  style={{ padding: 0 }}
                                                >
                                                  <div className='settingModalItems '>
                                                    <img src={BellGreyIcon} alt='' />
                                                    <span>Notifications</span>
                                                    {notificationUnreadCount != "0" ?
                                                      <div className='notiUnreadCount' style={{ marginTop: -16, marginLeft: 4 }}>
                                                        <span style={{ padding: notificationUnreadCount.length > 1 ? '2px 4px' : '1px 5px' }}>{notificationUnreadCount}</span>
                                                      </div>
                                                      : null
                                                    }
                                                  </div>
                                                </MenuItem>
                                                {isTokenFound == false &&
                                                  <MenuItem
                                                    onClick={(e) => { handleClose(e); setOpenNotificationDialog(true); setHelpSupportPage(false) }}
                                                    style={{ padding: 0 }}
                                                  >
                                                    <div className='settingModalItems'>
                                                      <img src={BellGreyIcon} alt='' />
                                                      <span>Allow notification</span>
                                                    </div>
                                                  </MenuItem>
                                                }
                                                <MenuItem
                                                  onClick={(e) => { handleClose(e); setOpenSwitchAccountDialog(true); setHelpSupportPage(false) }}
                                                  style={{ padding: 0 }}
                                                >
                                                  <div className='settingModalItems'>
                                                    <img src={AddSwitchAccountIcon} alt='' />
                                                    <span>Add/Switch Accounts</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => { handleClose(e); searchBarToggle(); searchBarRef?.current?.focus(); setSearchBarInputValue(''); setHelpSupportPage(false) }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {/* <img style={{width: 16,cursor:'pointer', transform: 'scaleX(-1)'}}   src={isDark == 1 ? MagnifierWhite : Magnifier} onClick={() => { searchBarToggle() }} alt="" />*/}
                                                  <div className='settingModalItems'>
                                                    <img src={MagnifierWhite} alt='' />
                                                    <span>Search</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => {
                                                    handleClose(e); setHelpSupportPage(false);
                                                    if (isLightMode == 1) {
                                                      setIsLightMode(0)
                                                      localStorage.setItem("isLightMode", "0");
                                                    }
                                                    else {
                                                      setIsLightMode(1)
                                                      localStorage.setItem("isLightMode", "1");
                                                    }
                                                  }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {/* <img style={{width: 16,cursor:'pointer', transform: 'scaleX(-1)'}}   src={isDark == 1 ? MagnifierWhite : Magnifier} onClick={() => { searchBarToggle() }} alt="" />*/}
                                                  <div className='settingModalItems'>
                                                    <img src={DrakLightModeIcon} alt='' />
                                                    {isLightMode == 1 ? <span>Dark Mode</span> : <span>Light Mode</span>}
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => { handleClose(e); setOpenNotificationListDialog(true); setHelpSupportPage(false) }}
                                                  style={{ padding: 0 }}
                                                >
                                                  <div className='settingModalItems'>
                                                    <img src={ShareInvitationIcon} alt='' />
                                                    <span>Invitations/Contacts</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e: any) => {
                                                    handleClose(e)
                                                    setHelpSupportPage(false);

                                                    try {
                                                      taskAddScrollRef.current.scrollTop = 0
                                                    }
                                                    catch (ex) { }
                                                    setSelectedProject(Base64.encode("1"), 'Trash')
                                                    setListItem({})
                                                    listItemRef.current = {}
                                                    // console.log('item123', item)
                                                    // setTitleHeading(item.projectName)
                                                    setSelectedProjectGruopID('')
                                                    // localStorage.setItem(
                                                    //   'project',
                                                    //   JSON.stringify(item)
                                                    // )
                                                    setTaskSectionOpen(false)
                                                    TitleHeadTaskNameInput?.current?.blur()
                                                    TaskNameInputRef?.current?.blur()
                                                    listInputRef?.current?.blur()
                                                    setTimeout(() => {
                                                      setSelectedProject(Base64.encode("1"), 'Trash')
                                                    }, 10)
                                                    setEditList({})
                                                    leftPaneMobile()

                                                  }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img
                                                      style={{ width: 17 }}
                                                      src={Delete}
                                                      alt=''
                                                    />
                                                    <span>View Trash</span>
                                                  </div>
                                                </MenuItem>
                                                {/* <MenuItem
                                                  className='customCursor'
                                                  onClick={(e) => { handleClose(e);setHelpSupportPage(false)}}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems customCursor'>
                                                    <img src={Setting} alt='' />
                                                    <span>Settings</span>
                                                  </div>
                                                </MenuItem> */}
                                                <MenuItem /* className="customCursor" */
                                                  onClick={(e) => { handleClose(e); setOpenManageAccountDialog(true); setHelpSupportPage(false) }}
                                                  style={{ padding: 0 }}
                                                >
                                                  <div className='settingModalItems '>
                                                    <img src={ManageAccountIcon} alt='' />
                                                    <span>Manage Account</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e: any) => {
                                                    // tawkMessengerRef?.current?.toggle()
                                                    //navigate('/help')
                                                    setHelpSupportPage(true)
                                                    handleClose(e)
                                                  }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img
                                                      style={{ width: 17 }}
                                                      src={helpIcon}
                                                      alt=''
                                                    />
                                                    <span>Help</span>
                                                  </div>
                                                </MenuItem>
                                                {switchUserData && switchUserData.length > 1 &&
                                                  <MenuItem
                                                    onClick={handleClose}
                                                    style={{ padding: 0 }}
                                                  >
                                                    <div
                                                      className='settingModalItems'
                                                      onClick={() => LogOutCurrentUserFun()}
                                                    >
                                                      <img
                                                        style={{ cursor: 'pointer' }}
                                                        src={LogOutCurrentUserIcon}
                                                      />
                                                      <span>Log out as current user</span>
                                                    </div>
                                                  </MenuItem>
                                                }
                                                <MenuItem
                                                  onClick={handleClose}
                                                  style={{ padding: 0 }}
                                                >
                                                  <div
                                                    className='settingModalItems'
                                                    onClick={() => LogOutFun()}
                                                  >
                                                    <img
                                                      style={{ cursor: 'pointer' }}
                                                      src={LogOut}
                                                    />
                                                    {switchUserData && switchUserData.length > 1 ?
                                                      <span>Log out all account</span>
                                                      :
                                                      <span>Log out</span>
                                                    }
                                                  </div>
                                                </MenuItem>
                                              </MenuList>
                                            </ClickAwayListener>
                                          </Paper>
                                        </Grow>
                                      )}
                                    </Popper>
                                    :
                                    <SwipeableDrawer
                                      anchor="bottom"
                                      open={open}
                                      onClose={() => setOpen(false)}
                                      onOpen={() => setOpen(true)}
                                      className='mainDrawerContainer'
                                    >
                                      <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                                        <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                                          <div ></div>
                                        </div>
                                        <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                                          <div style={{ width: '20%' }}></div>
                                          <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                            Account Menu
                                          </div>
                                          <div className='drawerHeadingDone' onClick={handleClose}>Done</div>
                                        </div>
                                        <div className='notificationDialogMainDiv' style={{ background: !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : colorSchemes.MiddlePaneBackground }} >
                                          <MenuList
                                            autoFocusItem={open}
                                            id='composition-menu'
                                            aria-labelledby='composition-button'
                                            style={{ paddingTop: 0, background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}
                                          // onKeyDown={handleListKeyDown}
                                          >
                                            {/* <div
                                      className='closeIconDiv'
                                      onClick={handleClose}
                                    >
                                      <span>Done</span>
                                    </div> */}
                                            {/* <div className='drawerIcon'>
                                          <div ></div>
                                        </div>
                                        <div className='drawerHeading'>
                                          <div style={{ width: '20%' }}></div>
                                          <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                            Account Menu
                                          </div>
                                          <div className='drawerHeadingDone' onClick={handleClose}>Done</div>
                                        </div> */}

                                            <MenuItem /* className="customCursor" */
                                              onClick={(e) => { handleClose(e); refreshFun('left') }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems '>
                                                <img src={RefreshGreyIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                <span>Refresh</span>
                                              </div>
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e) => { handleClose(e); setOpenAllNotificationDialog(true) }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems'>
                                                <img src={BellGreyIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                <span>Notifications</span>
                                                {notificationUnreadCount != "0" ?
                                                  <div className='notiUnreadCount' style={{ marginTop: -20, marginLeft: 6 }}>
                                                    <span style={{ padding: notificationUnreadCount.length > 1 ? '2px 4px' : '1px 5px' }}>{notificationUnreadCount}</span>
                                                  </div>
                                                  : null
                                                }

                                              </div>
                                            </MenuItem>
                                            {isTokenFound == false &&
                                              <MenuItem
                                                onClick={(e) => { handleClose(e); setOpenNotificationDialog(true) }}
                                                style={{ padding: 0 }}
                                              >
                                                <div className='settingModalItems'>
                                                  <img src={BellGreyIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                  <span>Allow notification</span>
                                                </div>
                                              </MenuItem>
                                            }
                                            <MenuItem
                                              onClick={(e) => { handleClose(e); setOpenSwitchAccountDialog(true) }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems'>
                                                <img src={AddSwitchAccountIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                <span>Add/Switch Accounts</span>
                                              </div>
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e) => { handleClose(e); searchBarToggle() }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems'>
                                                <img src={MagnifierWhite} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                <span>Search</span>
                                              </div>
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e) => {/*  handleClose(e); */setHelpSupportPage(false);
                                                if (isLightMode == 1) {
                                                  setIsLightMode(0)
                                                  localStorage.setItem("isLightMode", "0");
                                                }
                                                else {
                                                  setIsLightMode(1)
                                                  localStorage.setItem("isLightMode", "1");
                                                }
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems'>
                                                {isLightMode == 1 ? <img src={DrakLightModeIcon} alt='' /> : <img src={DrakLightModeWhiteIcon} alt='' />}
                                                {isLightMode == 1 ? <span>Dark Mode</span> : <span>Light Mode</span>}
                                              </div>
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e) => { handleClose(e); setOpenNotificationListDialog(true) }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems'>
                                                <img src={ShareInvitationIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                <span>Invitations/Contacts</span>
                                              </div>
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e: any) => {
                                                handleClose(e)
                                                try {
                                                  taskAddScrollRef.current.scrollTop = 0
                                                }
                                                catch (ex) { }
                                                setSelectedProject(Base64.encode("1"), 'Trash')
                                                setListItem({})
                                                listItemRef.current = {}
                                                // console.log('item123', item)
                                                // setTitleHeading(item.projectName)
                                                setSelectedProjectGruopID('')
                                                // localStorage.setItem(
                                                //   'project',
                                                //   JSON.stringify(item)
                                                // )
                                                setTaskSectionOpen(false)
                                                TitleHeadTaskNameInput?.current?.blur()
                                                TaskNameInputRef?.current?.blur()
                                                listInputRef?.current?.blur()
                                                setTimeout(() => {
                                                  setSelectedProject(Base64.encode("1"), 'Trash')
                                                }, 10)
                                                setEditList({})
                                                leftPaneMobile()
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              {' '}
                                              <div className='settingModalItems'>
                                                <img
                                                  style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)', width: 17 }}
                                                  src={Delete}
                                                  alt=''
                                                />
                                                <span>View Trash</span>
                                              </div>
                                            </MenuItem>
                                            {/* <MenuItem
                                              className='customCursor'
                                              onClick={handleClose}
                                              style={{ padding: 0 }}
                                            >
                                              {' '}
                                              <div className='settingModalItems customCursor'>
                                                <img src={Setting} alt='' style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)'}}/>
                                                <span>Settings</span>
                                              </div>
                                            </MenuItem> */}
                                            <MenuItem /* className="customCursor" */
                                              onClick={(e) => { handleClose(e); setOpenManageAccountDialog(true) }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems '>
                                                <img src={ManageAccountIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                <span>Manage Account</span>
                                              </div>
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e: any) => {
                                                // tawkMessengerRef?.current?.toggle()
                                                navigate('/help')
                                                handleClose(e)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              {' '}
                                              <div className='settingModalItems'>
                                                <img
                                                  style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)', width: 17 }}
                                                  src={helpIcon}
                                                  alt=''
                                                />
                                                <span>Help</span>
                                              </div>
                                            </MenuItem>
                                            {switchUserData && switchUserData.length > 1 &&
                                              <MenuItem
                                                onClick={handleClose}
                                                style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)', padding: 0 }}
                                              >
                                                <div
                                                  className='settingModalItems'
                                                  onClick={() => LogOutCurrentUserFun()}
                                                >
                                                  <img
                                                    style={{ cursor: 'pointer' }}
                                                    src={LogOutCurrentUserIcon}
                                                  />
                                                  <span>Log out as current user</span>
                                                </div>
                                              </MenuItem>
                                            }
                                            <MenuItem
                                              onClick={handleClose}
                                              style={{ padding: 0, paddingBottom: 40 }}
                                            >
                                              <div
                                                className='settingModalItems'
                                                onClick={() => LogOutFun()}
                                              >
                                                <img
                                                  style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)', cursor: 'pointer' }}
                                                  src={LogOut}
                                                />
                                                {switchUserData && switchUserData.length > 1 ?
                                                  <span>Log out all account</span>
                                                  :
                                                  <span>Log out</span>
                                                }
                                              </div>
                                            </MenuItem>
                                          </MenuList>
                                        </div>
                                      </div>
                                    </SwipeableDrawer>
                                  }
                                </div>
                              </div>
                              {/* {!mobileView && */}



                              <div style={{ display: 'flex', alignItems: 'center', marginTop: -2, gap: 10 }} >
                                {/* {mobileView &&
                                  <div className='settingButton' style={{ width: 22, height: 22, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { searchBarToggle() }}>
                                    <img style={styles.searchBarIcon} src={NewSearchIcon} />
                                  </div>
                                } */}
                               {!mobileView && <Tooltip title="Refresh">
                                  <div className='settingButton' style={{ width: 22, height: 22, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); refreshFun('left'); setHelpSupportPage(false) }}>
                                    <img style={{ width: 18, cursor: 'pointer', padding: 1 }} src={RefreshIcon} alt="" />
                                  </div>
                                </Tooltip>
                                }
                                {!mobileView && <Tooltip title="Notifications">
                                  <div className='settingButton' onClick={() => { setOpenAllNotificationDialog(true); setHelpSupportPage(false) }}>
                                    {/* <img style={{width: 16,cursor:'pointer', transform: 'scaleX(-1)'}}   src={isDark == 1 ? MagnifierWhite : Magnifier} onClick={() => { searchBarToggle() }} alt="" />*/}
                                    {/* <div className='settingModalItems notificationsBellDiv'> */}
                                    <img style={{ width: 20, cursor: 'pointer', padding: 1, marginTop: -4 }} src={Bell} alt='' />
                                    {notificationUnreadCount != "0" ?
                                      <div className='notiUnreadCount'>
                                        <span style={{ padding: notificationUnreadCount.length > 1 ? '2px 4px' : '1px 5px' }}>{notificationUnreadCount}</span>
                                      </div>
                                      : null
                                    }

                                    {/* </div> */}
                                  </div>

                                </Tooltip>
                                }

                              </div>

                              {/* } */}
                            </div>
                          </div>
                          {/* {mobileView &&
                            <div style={{ position: 'absolute', right: '90px', top: '20px', zIndex: 2 }}>
                              <div className='settingButton' style={{ display: 'flex' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); refreshFun('left') }}>
                                <img style={{ width: 16, cursor: 'pointer' }} src={RefreshIcon} alt="" />
                              </div>
                            </div>
                          } */}
                          {/* <div>
                            {mobileView &&
                              <img onClick={() => { searchBarToggle() }} style={styles.searchBarIcon} src={isDark == 1 ? MagnifierWhite : Magnifier} />
                            }
                          </div> */}
                        </div>
                      </div>







                      {!mobileView ?
                        <Dialog
                          open={openNotificationDialog}
                          onClose={() => setOpenNotificationDialog(false)}
                          aria-labelledby='alert-dialog-title'
                          aria-describedby='alert-dialog-description'
                        >
                          <ClickAwayListener onClickAway={() => setOpenNotificationDialog(false)}>
                            <div className='notificationDialogMainDiv1' style={{ textAlign: 'center' }}>
                              <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                                <div style={{ width: "70%", textAlign: "left" }}>
                                  <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Allow Notification</h4></div>
                                <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                  <img style={{ width: 12, cursor: 'pointer' }} onClick={() => setOpenNotificationDialog(false)} src={CloseMenuListIcon} alt='' />
                                </div>
                              </div>

                              {/* <div  style={{ textAlign: 'end', width: '100%' }}>
                                <img style={{ width: 16, cursor: 'pointer' }} onClick={() => setOpenNotificationDialog(false)} src={CloseMenuListIcon} alt='' />
                              </div> */}
                              <div className="newDialogInnerDiv" style={{ overflow: 'hidden', padding: '100px 20px' }}>
                                <div>
                                  {/* <h4 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>
                                    Allow Notification
                                  </h4> */}
                                  Notifications blocked. Please enable them in your browser setting.
                                </div>
                              </div>
                            </div>
                          </ClickAwayListener>
                        </Dialog>
                        :
                        <SwipeableDrawer
                          anchor="bottom"
                          open={openNotificationDialog}
                          onClose={() => setOpenNotificationDialog(false)}
                          onOpen={() => setOpenNotificationDialog(true)}
                          className='mainDrawerContainer'
                        >
                          <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                            <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div ></div>
                            </div>
                            <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div style={{ width: '20%' }}></div>
                              <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                Allow Notification
                              </div>
                              <div className='drawerHeadingDone' onClick={() => setOpenNotificationDialog(false)}>Done</div>
                            </div>
                            <div className='notificationDialogMainDiv1' style={{ textAlign: 'center', background: !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                              {/* <div style={{ textAlign: 'end', width: '100%' }}>
                            <img style={{ width: 16, cursor: 'pointer' }} onClick={() => setOpenNotificationDialog(false)} src={CloseMenuListIcon} alt='' />
                          </div> */}
                              <div>
                                <div style={{ marginBottom: 30, fontSize: 18 }}>
                                  {/* <h4 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>
                                Allow Notification
                              </h4> */}
                                  Notifications blocked. Please enable them in your browser setting.
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwipeableDrawer>
                      }

                      {!mobileView ?
                        <Dialog
                          open={noLongerDialog}
                          onClose={() => setNoLongerDialog(false)}
                          aria-labelledby='alert-dialog-title'
                          aria-describedby='alert-dialog-description'
                          style={{ zIndex: 999 }}
                        >
                          <ClickAwayListener onClickAway={() => setNoLongerDialog(false)}>
                            <div className='notificationDialogMainDiv1' style={{ textAlign: 'center' }}>
                              <div style={{ textAlign: 'end', width: '100%' }}>
                                <img style={{ width: 16, cursor: 'pointer' }} onClick={() => setNoLongerDialog(false)} src={CloseMenuListIcon} alt='' />
                              </div>
                              <div>
                                <div style={{ marginTop: -15 }}>
                                  <h4 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>
                                    Share Notification
                                  </h4>
                                  You are no longer shared on this {noLongerData && noLongerData.type && noLongerData.type == "project" ? "list" : "folder"}
                                </div>
                              </div>
                            </div>
                          </ClickAwayListener>
                        </Dialog>
                        :
                        <SwipeableDrawer
                          anchor="bottom"
                          open={noLongerDialog}
                          onClose={() => setNoLongerDialog(false)}
                          onOpen={() => setNoLongerDialog(true)}
                          className='mainDrawerContainer'
                        >
                          <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontSemiGrey }}>
                            <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div ></div>
                            </div>
                            <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div style={{ width: '20%' }}></div>
                              <div style={{ width: '100%', textAlign: 'center', fontSize: 16, fontWeight: '600' }}>
                                Share Notification
                              </div>
                              <div className='drawerHeadingDone' onClick={() => setNoLongerDialog(false)}>Done</div>
                            </div>
                            <div className='notificationDialogMainDiv1' style={{ textAlign: 'center', background: !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                              <div>
                                <div style={{ marginBottom: 30 }}>
                                  You are no longer shared on this list
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwipeableDrawer>
                      }




                      {!mobileView ?
                        <Dialog
                          open={openAllNotificationDialog}
                          onClose={() => setOpenAllNotificationDialog(false)}
                          aria-labelledby='alert-dialog-title'
                          aria-describedby='alert-dialog-description'
                        >
                          <ClickAwayListener onClickAway={() => setOpenAllNotificationDialog(false)}>
                            <div className='notificationDialogMainDiv1' style={{ textAlign: 'center', minWidth: '80%', maxWidth: '80%', maxHeight: 650 }}>
                              <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                                <div style={{ width: "70%", textAlign: "left" }}>
                                  <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Notifications</h4></div>
                                <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                  <img style={{ width: 12, cursor: 'pointer' }} onClick={() => setOpenAllNotificationDialog(false)} src={CloseMenuListIcon} alt='' />
                                </div>
                              </div>
                              <div className="newDialogInnerDiv" style={{ maxHeight: 550 }}>
                                <div >
                                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginBottom: 15 }}>
                                    {notificationUnreadCount && notificationUnreadCount != "0" && allNotificationData && allNotificationData.length > 0 &&
                                      <div className='markAllAsRead'>
                                        <span onClick={() => markAsRead()}>Mark all as read</span>
                                      </div>
                                    }
                                    {allNotificationData && allNotificationData.length > 0 &&
                                      <div className='markAllAsRead'>
                                        <span onClick={() => handleDeleteAllNotiConfirm()}>Delete all</span>
                                      </div>
                                    }
                                  </div>

                                  {allNotificationData && allNotificationData.length > 0 ?
                                    <>
                                      {allNotificationData.map((item: any, index: any) => (
                                        <div>
                                          {item && item.subType && item.subType == "add task" &&
                                            <div className="notificationsItem" >
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                {/* <img style={{width:18,marginRight:15,marginTop:5}} src={AddTaskNotification} alt="" /> */}
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 20 }}>
                                                  <img style={{ width: 40 }} src={NotificationItemIcon} alt="" />
                                                </div>
                                                {/* <div style={mobileView ? {} : { width: '8%' }}>
                                              {item && item.firstName && <div className='userAvtarDiv2'>
                                                {Base64.decode(item.firstName).charAt(0)}
                                              </div>}
                                            </div> */}
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span>&nbsp; added task <span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span> in &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.projectName, "1")}"</span>&nbsp; list. <br /><span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                              {/* <div style={{fontSize:12,float:'right',color:'#6478c7'}}>
                      <img src={CommentDeleteCrossIcon} style={{cursor:'pointer',width:10,marginLeft:10,marginTop:-1}} onClick={()=>{deleteSingleNotification(item, index)}} />
                    </div> */}
                                            </div>
                                          }
                                          {item && item.subType && item.subType == "add comment" &&
                                            <div className="notificationsItem" >
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                {/* <img style={{width:18,marginRight:15,marginTop:5}} src={AddTaskNotification} alt="" /> */}
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 20 }}>
                                                  <img style={{ width: 40 }} src={NotificationItemIcon} alt="" />
                                                </div>

                                                {/* <div style={mobileView ? {} : { width: '8%' }}>
                                              {item && item.firstName && <div className='userAvtarDiv2'>
                                                {Base64.decode(item.firstName).charAt(0)}
                                              </div>}
                                            </div> */}
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span>&nbsp; added comment in &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span>. <br />
                                                    <span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                            </div>
                                          }
                                          {item && item.subType && item.subType == "update note" &&
                                            <div className="notificationsItem" >
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                {/* <img style={{width:18,marginRight:15,marginTop:5}} src={AddTaskNotification} alt="" /> */}
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 20 }}>
                                                  <img style={{ width: 40 }} src={NotificationItemIcon} alt="" />
                                                </div>
                                                {/* <div style={mobileView ? {} : { width: '8%' }}>
                                              {item && item.firstName && <div className='userAvtarDiv2'>
                                                {Base64.decode(item.firstName).charAt(0)}
                                              </div>}
                                            </div> */}
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span>&nbsp; updated note in &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span>. <br />
                                                    <span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                            </div>
                                          }
                                          {item && item.subType && item.subType == "Reminder task" &&
                                            <div className="notificationsItem" >
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                {/* <img style={{width:18,marginRight:15,marginTop:5}} src={AddTaskNotification} alt="" /> */}
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 20 }}>
                                                  <img style={{ width: 40 }} src={NotificationItemIcon} alt="" />
                                                </div>
                                                {/* <div style={mobileView ? {} : { width: '8%' }}>
                                              {item && item.firstName && <div className='userAvtarDiv2'>
                                                {Base64.decode(item.firstName).charAt(0)}
                                              </div>}
                                            </div> */}
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span>&nbsp; set reminder in on &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span>. <br />
                                                    <span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      ))}
                                    </> :
                                    <div>
                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300, color: 'darkgray', fontSize: 20, fontWeight: 600 }}>
                                        No notificatons yet!
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </ClickAwayListener>
                        </Dialog>
                        :
                        <SwipeableDrawer
                          anchor="bottom"
                          open={openAllNotificationDialog}
                          onClose={() => setOpenAllNotificationDialog(false)}
                          onOpen={() => setOpenAllNotificationDialog(true)}
                          className='mainDrawerContainer'
                        >
                          <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                            <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div ></div>
                            </div>
                            <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div style={{ width: '20%' }}></div>
                              <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                Notifications
                              </div>
                              <div className='drawerHeadingDone' onClick={() => setOpenAllNotificationDialog(false)}>Done</div>
                            </div>
                            <div className='notificationDialogMainDiv1' style={{ textAlign: 'center', background: !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>

                              <div>
                                <div>

                                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginBottom: 10 }}>
                                    {notificationUnreadCount && notificationUnreadCount != "0" && allNotificationData && allNotificationData.length > 0 &&
                                      <div className='markAllAsRead'>
                                        <span onClick={() => markAsRead()}>Mark all as read</span>
                                      </div>
                                    }
                                    {allNotificationData && allNotificationData.length > 0 &&
                                      <div className='markAllAsRead'>
                                        <span onClick={() => handleDeleteAllNotiConfirm()}>Delete all</span>
                                      </div>
                                    }
                                  </div>


                                  {allNotificationData && allNotificationData.length > 0 ?
                                    <>
                                      {allNotificationData.map((item: any, index: any) => (
                                        <div>
                                          {item && item.subType && item.subType == "add task" &&
                                            <div className={isLightMode ? "notificationsItem" : "notificationsItemDark"} >
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 10 }}>
                                                  <img style={{ width: 35 }} src={NotificationItemIcon} alt="" />
                                                </div>
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span> added task <span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span> in &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.projectName, "1")}"</span>&nbsp; list.<br />
                                                    <span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                            </div>
                                          }
                                          {item && item.subType && item.subType == "add comment" &&
                                            <div className={isLightMode ? "notificationsItem" : "notificationsItemDark"} >
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                {/* <img style={{width:18,marginRight:15,marginTop:5}} src={AddTaskNotification} alt="" /> */}
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 10 }}>
                                                  <img style={{ width: 35 }} src={NotificationItemIcon} alt="" />
                                                </div>
                                                {/* <div style={mobileView ? {} : { width: '8%' }}>
                                              {item && item.firstName && <div className='userAvtarDiv2'>
                                                {Base64.decode(item.firstName).charAt(0)}
                                              </div>}
                                            </div> */}
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span> added comment in &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span>.<br />
                                                    <span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                            </div>
                                          }
                                          {item && item.subType && item.subType == "update note" &&
                                            <div className={isLightMode ? "notificationsItem" : "notificationsItemDark"}>
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                {/* <img style={{width:18,marginRight:15,marginTop:5}} src={AddTaskNotification} alt="" /> */}
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 10 }}>
                                                  <img style={{ width: 35 }} src={NotificationItemIcon} alt="" />
                                                </div>
                                                {/* <div style={mobileView ? {} : { width: '8%' }}>
                                              {item && item.firstName && <div className='userAvtarDiv2'>
                                                {Base64.decode(item.firstName).charAt(0)}
                                              </div>}
                                            </div> */}
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span> updated note in &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span>.<br />
                                                    <span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                            </div>
                                          }
                                          {item && item.subType && item.subType == "Reminder task" &&
                                            <div className={isLightMode ? "notificationsItem" : "notificationsItemDark"}>
                                              <div className='notificationListItem' style={{ padding: 0, borderBottom: 0 }} onClick={() => handleClickNotificationItem(item)}>
                                                {/* <img style={{width:18,marginRight:15,marginTop:5}} src={AddTaskNotification} alt="" /> */}
                                                <div style={{ width: 10 }}>
                                                  {item && item.isRead && item.isRead == "1" &&
                                                    <img style={{ width: 10 }} src={NotificationReadIcon} alt="" />
                                                  }
                                                </div>
                                                <div style={{ marginRight: 10 }}>
                                                  <img style={{ width: 35 }} src={NotificationItemIcon} alt="" />
                                                </div>
                                                {/* <div style={mobileView ? {} : { width: '8%' }}>
                                              {item && item.firstName && <div className='userAvtarDiv2'>
                                                {Base64.decode(item.firstName).charAt(0)}
                                              </div>}
                                            </div> */}
                                                <div style={{ textAlign: 'left' }}>
                                                  <div>
                                                    <span style={{ fontWeight: 600, color: 'rgb(99, 120, 198)' }}>{item.userName}</span> set reminder on &nbsp;<span style={{ fontWeight: 600 }}>"{decData(item.taskName, "1")}"</span>.<br />
                                                    <span style={{ fontSize: 13, fontWeight: 400, color: 'gray' }}>{timeSince(item.createdDateFormated)}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                                                <img style={{ cursor: 'pointer', width: 10, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { deleteSingleNotification(item, index) }} />
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      ))}
                                    </> :
                                    <div>
                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300, color: colorSchemes.FontSemiGrey, fontSize: 20, fontWeight: 600 }}>
                                        No notificatons yet!
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwipeableDrawer>
                      }


                      {!mobileView ?
                        <Dialog
                          open={openSwitchAccountDialog}
                          onClose={() => setOpenSwitchAccountDialog(false)}
                          aria-labelledby='alert-dialog-title'
                          aria-describedby='alert-dialog-description'
                        >
                          <ClickAwayListener onClickAway={() => setOpenSwitchAccountDialog(false)}>
                            <div className='switchDialogMainDiv' style={{ textAlign: 'center', maxWidth: 600, minWidth: 600 }}>
                              <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                                <div style={{ width: "70%", textAlign: "left" }}>
                                  <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Switch Accounts</h4></div>
                                <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                  <img style={{ width: 12, cursor: 'pointer' }} onClick={() => setOpenSwitchAccountDialog(false)} src={CloseMenuListIcon} alt='' />
                                </div>
                              </div>
                              {/* <div style={{ textAlign: 'end', width: '100%' }}>
                                <img style={{ width: 16, cursor: 'pointer' }} onClick={() => setOpenSwitchAccountDialog(false)} src={CloseMenuListIcon} alt='' />
                              </div> */}
                              <div className="newDialogInnerDiv" style={{ maxHeight: 400, padding: '25px 50px' }}>
                                <div>
                                  {/* <h3 style={{ textAlign: 'center', fontWeight: '600', marginBottom: 20 }}>
                                    Switch Accounts
                                  </h3> */}

                                  <div>

                                    {switchUserData && switchUserData.length > 0 && switchUserData.map((item, index) => (

                                      <div className='MainSwitchProfileDiv' onClick={() => switchAccount(item)}>

                                        <div style={{ width: '10%' }}>
                                          {item && item.userData && item.userData.id == userId &&
                                            <div className='switchAccountActive'>
                                              <img style={{ width: 20 }} src={SwitchCheckIcon} alt="" />
                                            </div>
                                          }
                                        </div>
                                        <div style={{ width: '12%' }}>

                                          {item && item.userData && item.userData.firstName && item.userData.firstName != "" ?
                                            <div className='switchAvtarDiv'>
                                              {Base64.decode(item.userData.firstName).charAt(0) + Base64.decode(item.userData.lastName).charAt(0)}
                                            </div>
                                            :
                                            <div className='switchAvtarDiv'>
                                              {Base64.decode(item.userData.email).charAt(0)}
                                            </div>
                                          }
                                        </div>
                                        <div className='swicthDetailDiv'>
                                          <div
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                              color: '#464645',
                                              textAlign: 'left'
                                            }}
                                          >
                                            {Base64.decode(item.userData.firstName)} {Base64.decode(item.userData.lastName)}
                                          </div>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ fontSize: 12 }}>{Base64.decode(item.userData.email)}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}

                                    <div className='MainSwitchProfileDiv' onClick={() => /* navigate('/addAccount/login') */ { setOpenAddAccountLogin(true); setOpenSwitchAccountDialog(false) }}>
                                      <div style={{ width: '10%' }}>
                                      </div>
                                      <div style={{ width: '12%' }}>
                                        <img style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)', width: 22 }} src={AddProjectIcon} alt="" />
                                      </div>
                                      <div className='swicthDetailDiv' style={{ textAlign: 'left' }}>
                                        Add account
                                      </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                      <div className='closeSwitchDialogBtn' onClick={() => setOpenSwitchAccountDialog(false)}>
                                        Close
                                      </div>
                                    </div>
                                    <div style={{ marginTop: 12, fontSize: 15 }}>
                                      <span className='makeLinkLearn' onClick={() => { setOpenLearnMoreDialog(true); setOpenSwitchAccountDialog(false) }}>Learn more</span>&nbsp;&nbsp;about when to add accounts.
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </ClickAwayListener>
                        </Dialog>
                        :
                        <SwipeableDrawer
                          anchor="bottom"
                          open={openSwitchAccountDialog}
                          onClose={() => setOpenSwitchAccountDialog(false)}
                          onOpen={() => setOpenSwitchAccountDialog(true)}
                          className='mainDrawerContainer'
                        >
                          <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                            <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div ></div>
                            </div>
                            <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div style={{ width: '20%' }}></div>
                              <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                Switch Accounts
                              </div>
                              <div className='drawerHeadingDone' onClick={() => setOpenSwitchAccountDialog(false)}>Done</div>
                            </div>
                            <div className='switchDialogMainDiv' style={{ textAlign: 'center', background: !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                              {/* <div style={{ textAlign: 'end', width: '100%' }}>
                            <img style={{ width: 16, cursor: 'pointer' }} onClick={() => setOpenSwitchAccountDialog(false)} src={CloseMenuListIcon} alt='' />
                          </div> */}
                              <div>
                                <div>
                                  {/* <h3 style={{ textAlign: 'center', fontWeight: '600', marginBottom: 20 }}>
                                Switch Accounts
                              </h3> */}

                                  <div>

                                    {switchUserData && switchUserData.length > 0 && switchUserData.map((item, index) => (

                                      <div className={isLightMode ? "MainSwitchProfileDiv" : "MainSwitchProfileDivDark"} onClick={() => { switchAccount(item) }}>

                                        <div style={{ width: '10%' }}>
                                          {item && item.userData && item.userData.id == userId &&
                                            <div className='switchAccountActive'>
                                              <img style={{ width: 15 }} src={SwitchCheckIcon} alt="" />
                                            </div>
                                          }
                                        </div>
                                        <div style={{ width: '12%' }}>

                                          {item && item.userData && item.userData.firstName && item.userData.firstName != "" ?
                                            <div className='switchAvtarDiv'>
                                              {Base64.decode(item.userData.firstName).charAt(0) + Base64.decode(item.userData.lastName).charAt(0)}
                                            </div>
                                            :
                                            <div className='switchAvtarDiv'>
                                              {Base64.decode(item.userData.email).charAt(0)}
                                            </div>
                                          }
                                        </div>
                                        <div className='swicthDetailDiv'>
                                          <div
                                            style={{
                                              fontSize: 15,
                                              fontFamily: 'HelveticaSemiBold',
                                              color: colorSchemes.FontColor,
                                              textAlign: 'left'
                                            }}
                                          >
                                            {Base64.decode(item.userData.firstName)} {Base64.decode(item.userData.lastName)}
                                          </div>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ fontSize: 12 }}>{Base64.decode(item.userData.email)}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}

                                    <div className={isLightMode ? "MainSwitchProfileDiv" : "MainSwitchProfileDivDark"} onClick={() => /* navigate('/addAccount/login') */ { setOpenAddAccountLogin(true); setOpenSwitchAccountDialog(false) }}>
                                      <div style={{ width: '10%' }}>
                                      </div>
                                      <div style={{ width: '12%' }}>
                                        <img style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)', width: 22 }} src={AddProjectIcon} alt="" />
                                      </div>
                                      <div className='swicthDetailDiv' style={{ textAlign: 'left' }}>
                                        Add account
                                      </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                      <div className='closeSwitchDialogBtn' style={{ color: colorSchemes.FontSemiGrey }} onClick={() => setOpenSwitchAccountDialog(false)}>
                                        Close
                                      </div>
                                    </div>
                                    <div style={{ marginTop: 12, fontSize: 15 }}>
                                      <span className='makeLinkLearn' onClick={() => { setOpenLearnMoreDialog(true); setOpenSwitchAccountDialog(false) }}>Learn more</span> about when to add accounts.
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </SwipeableDrawer>
                      }



                      {openAddAccountLogin &&
                        <AddAccount
                          openAddAccountLogin={openAddAccountLogin}
                          setOpenAddAccountLogin={setOpenAddAccountLogin}
                          openAddAccountSignup={openAddAccountSignup}
                          setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                          setOpenSwitchAccountDialog={setOpenSwitchAccountDialog}
                          mobileView={mobileView}
                          isDark={isDark}
                          colorSchemes={colorSchemes}
                        />
                      }

                      {openManageAccountDialog &&
                        <ManageAccount
                          openManageAccountDialog={openManageAccountDialog}
                          setOpenManageAccountDialog={setOpenManageAccountDialog}
                          userData={userData}
                          setMultiSnackbarMsg={setMultiSnackbarMsg}
                          handleClickMultiSnackBar={handleClickMultiSnackBar}
                          SlideTransition={SlideTransition}
                          setUserData={setUserData}
                          setEmailLandingPage={setEmail}
                          setFirstName={setFirstName}
                          setLastName={setLastName}
                          mobileView={mobileView}
                          colorSchemes={colorSchemes}
                          isDark={isDark}
                          setSwitchUserData={setSwitchUserData}
                          sendSocketMessage={sendSocketMessage}
                        />
                      }


                      {!mobileView ?
                        <Dialog
                          open={openLearnMoreDialog}
                          onClose={() => { setOpenLearnMoreDialog(false); setOpenSwitchAccountDialog(true) }}
                          aria-labelledby='alert-dialog-title'
                          aria-describedby='alert-dialog-description'
                        >
                          <ClickAwayListener onClickAway={() => { setOpenLearnMoreDialog(false); setOpenSwitchAccountDialog(true) }}>
                            <div className='switchDialogMainDiv' style={{ textAlign: 'center' }}>
                              <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                                <div style={{ width: "70%", textAlign: "left" }}>
                                  <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Use Switch Accounts</h4></div>
                                <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                  <img style={{ width: 12, cursor: 'pointer' }} onClick={() => { setOpenLearnMoreDialog(false); setOpenSwitchAccountDialog(true) }} src={CloseMenuListIcon} alt='' />
                                </div>
                              </div>
                              {/* <div style={{ textAlign: 'end', width: '100%' }}>
                                <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setOpenLearnMoreDialog(false); setOpenSwitchAccountDialog(true) }} src={CloseMenuListIcon} alt='' />
                              </div> */}
                              <div style={{ textAlign: 'left', fontSize: 17, padding: '15px 30px 30px 30px' }}>
                                {/* <span style={{ fontWeight: 'bold' }}>Use Switch Accounts</span>   <br /> */}
                                Switch Accounts allows you to add and switch between ManageStuff accounts without having to sign out and in.
                                <br /><br />
                                <span style={{ fontWeight: 'bold' }}>Important:</span> To prevent others from accessing your ManageStuff accounts, we recommend that you don't use this feature on shared devices and computers.
                              </div>
                            </div>
                          </ClickAwayListener>
                        </Dialog>
                        :
                        <SwipeableDrawer
                          anchor="bottom"
                          open={openLearnMoreDialog}
                          onClose={() => { setOpenLearnMoreDialog(false); setOpenSwitchAccountDialog(true) }}
                          onOpen={() => setOpenLearnMoreDialog(true)}
                          className='mainDrawerContainer'
                        >
                          <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                            <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div ></div>
                            </div>
                            <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                              <div style={{ width: '20%' }}></div>
                              <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                Learn More
                              </div>
                              <div className='drawerHeadingDone' onClick={() => { setOpenLearnMoreDialog(false); setOpenSwitchAccountDialog(true) }}>Done</div>
                            </div>
                            <div className='switchDialogMainDiv' style={{ textAlign: 'center', padding: '15px 30px 30px 30px', background: !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                              {/* <div style={{ textAlign: 'end', width: '100%' }}>
                            <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setOpenLearnMoreDialog(false); setOpenSwitchAccountDialog(true) }} src={CloseMenuListIcon} alt='' />
                          </div> */}
                              <div style={{ textAlign: 'left', fontSize: 15 }}>
                                <span style={{ fontWeight: 'bold' }}>Use Switch Accounts</span>   <br />
                                Switch Accounts allows you to add and switch between ManageStuff accounts without having to sign out and in.
                                <br /><br />
                                <span style={{ fontWeight: 'bold' }}>Important:</span> To prevent others from accessing your ManageStuff accounts, we recommend that you don't use this feature on shared devices and computers.
                              </div>
                            </div>
                          </div>
                        </SwipeableDrawer>
                      }


                      {searchBarOpen &&
                        <div
                          style={{
                            ...styles.menuItems,
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            margin: 10,
                            width: mobileView ? '' : '92%',
                            borderRadius: 4,
                            background: '#fff' /* colorSchemes.WebSearchBackColor */
                          }}
                        >
                          <input
                            ref={searchBarRef}
                            style={{
                              ...styles.searchBarInput,
                              background: '#fff' /* colorSchemes.WebSearchBackColor */, color: '#000' /* colorSchemes.WebFontSemiGrey */
                            }}
                            className='searchBarInputCss'
                            type='text'
                            name='name'
                            placeholder='Search....'
                            autoFocus={true}
                            value={searchBarInputValue}
                            onChange={(e) => { console.log('test45', e); SearchFun(e) }}
                          />
                          {!mobileView &&
                            <img style={styles.searchBarIcon} src={Magnifier} />
                          }
                        </div>
                      }


                      <div
                        className='projectWithGroup'
                        style={{ height: mobileView ? !searchBarOpen ? window.innerHeight - 200 : window.innerHeight - 280.7 : !searchBarOpen ? window.innerHeight - 200 : window.innerHeight - 250.7, }}
                        ref={sideMenuUpperDivRef}
                      >
                        {refreshLoader &&
                          <div style={{ height: 125, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 8 }}>
                            {!mobileView ?
                              <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
                              :
                              <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
                            }
                            <div style={{ fontSize: 16, fontWeight: 600, color: 'darkgray' }}>Refreshing...</div>
                          </div>
                        }
                        {refreshFocusLoader &&
                          <div style={{ height: 125, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 8 }}>
                            {!mobileView ?
                              <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
                              :
                              <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
                            }
                            <div style={{ fontSize: 16, fontWeight: 600, color: 'darkgray' }}>Syncing...</div>
                          </div>
                        }



                        <Droppable
                          key={'projectGroup-' + userData.defaultProjectID}
                          droppableId={`${'projectGroup-' + userData.defaultProjectID
                            }`}
                          type={`droppableSubItem`}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={mobileView?{marginLeft:"25px"}: {}}
                              // style={{backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey'}}
                              {...provided.droppableProps}
                            >
                              {projectData &&
                                projectData.length > 0 &&
                                projectData.map((item: any, i: any) => (
                                  <>
                                    {item.isTrash != "2" &&
                                      <Draggable
                                        key={`drag-list-id-${item.id}`}
                                        draggableId={`dragprojectListid-${item.id}`}
                                        index={i}
                                        type={`droppableSubItem`}
                                      >
                                        {(provided, snapshot1) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot1.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              style={{
                                                transform: snapshot1.isDragging
                                                  ? 'scale(0.85)'
                                                  : 'scale(1)',
                                                background: snapshot1.isDragging
                                                  ? '#FFFFFF66'
                                                  : 'transparent',
                                                boxShadow: snapshot1.isDragging
                                                  ? 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px'
                                                  : '',
                                                // opacity: snapshot.isDragging
                                                //   ? 0.7
                                                //   : 'inherit'
                                              }}
                                            >
                                              <Droppable
                                                key={'list-' + item.id}
                                                droppableId={`${'list-' + item.id}`}
                                                type={`list`}
                                                style={{ transition: 'none' }}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    // style={{backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey'}}
                                                    style={getListStyle(
                                                      snapshot.isDraggingOver
                                                    )}
                                                    {...provided.droppableProps}
                                                  >
                                                    <>
                                                      <div
                                                        style={{ background: selectedProjectID == item.id ? snapshot1.isDragging ? 'transparent' : isLightMode? "#FFFFFF66":'rgb(58, 83, 113)' : item.color, borderRadius: selectedProjectID == item.id ? mobileView ? "0px 4px 4px 0px" : "4px 0px 0px 4px" : "0px" }}
                                                        onClick={() => {
                                                          setHelpSupportPage(false)
                                                          setCompleteTaskShow(false)
                                                          setSearchBarInputValue('')
                                                          try {
                                                            taskAddScrollRef.current.scrollTop = 0
                                                          }
                                                          catch (ex) { }
                                                          setSelectedProject(
                                                            item.id,
                                                            item.projectName
                                                          )
                                                          leftPaneMobile()
                                                          setEditSelectedList(false)
                                                          try {
                                                            if (listItem && listItem.id && listItem.id != item.id) {
                                                              if (getTaskCountOfProject(item.id) > 0) {
                                                                if (taskData.length > 0) {
                                                                  let taskdata1 = [...taskData]

                                                                  for (let i = 0; i < taskdata1[0].length; i++) {
                                                                    if (parseInt(taskdata1[0][i].projectId) == parseInt(item.id) && taskdata1[0][i].isTrash != "2" && taskdata1[0][i].taskHeading != "1") {
                                                                      setTaskItem(taskdata1[0][i]);
                                                                      taskItemRef.current = taskdata1[0][i];
                                                                      setTaskSectionOpen(true)
                                                                      break;
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                              else {
                                                                setTaskSectionOpen(false)
                                                              }
                                                            }
                                                          }
                                                          catch (ex) { }
                                                          setListItem(item)
                                                          listItemRef.current = item
                                                          if (userId == item.userId) {
                                                            getListToFolder(item)
                                                          }
                                                          console.log('item123', item)
                                                          // setTitleHeading(item.projectName)
                                                          setSelectedProjectGruopID('')
                                                          localStorage.setItem(
                                                            'project',
                                                            JSON.stringify(item)
                                                          )
                                                          TitleHeadTaskNameInput?.current?.blur()
                                                          TaskNameInputRef?.current?.blur()
                                                          listInputRef?.current?.blur()
                                                          setTimeout(() => {
                                                            setSelectedProject(
                                                              item.id,
                                                              item.projectName
                                                            )
                                                          }, 10)
                                                          setEditList({})
                                                        }}
                                                        className={
                                                          selectedProjectID == item.id
                                                            ? 'addTaskStepsActive'
                                                            : 'addTaskSteps'
                                                        }
                                                        onContextMenu={e => {
                                                          e.preventDefault()
                                                          setClicked(false)
                                                          setProjectClicked(false)
                                                          setListClicked(true)
                                                          setPoints({
                                                            x: e.pageX,
                                                            y: e.pageY
                                                          })
                                                          setDataItem(item)
                                                        }}
                                                        onMouseEnter={() => {
                                                          try {
                                                            sideBarTaskNoteInputRef?.current?.blur()
                                                          }
                                                          catch (ex) {

                                                          }
                                                          mouseEnter(item)
                                                        }}
                                                        onMouseLeave={() =>
                                                          mouseLeave(item)
                                                        }
                                                      >
                                                        <div
                                                          className={
                                                            snapshot.isDraggingOver
                                                              ? 'taskStepTitleRowForBorder'
                                                              : selectedProjectID == item.id
                                                                ? 'taskStepTitleRow' : isLightMode? 'taskStepTitleRow groupLists' : 'taskStepTitleRow groupLists groupListsDark'
                                                            /* snapshot.isDraggingOver
                                                              ? 'taskStepTitleRowForBorder'
                                                              : 'taskStepTitleRow' */
                                                          }
                                                        // style={{ color: colorSchemes.WebFontWhite }}
                                                        >
                                                          {item.isNote && item.isNote == 1 ?
                                                            <img
                                                              // onClick={() => deleteProject(item)}
                                                              style={{
                                                                width: 16,
                                                                height: 16,
                                                                cursor: 'pointer'
                                                              }}
                                                              src={TaskNoteIcon}
                                                            />
                                                            :
                                                            <img
                                                              // onClick={() => deleteProject(item)}
                                                              style={{
                                                                width: 16,
                                                                height: 16,
                                                                cursor: 'pointer'
                                                              }}
                                                              src={mobileView ? listiconDark : listiconDark}
                                                            />
                                                          }
                                                          {/*  {item.userId != userId ?
                                                            <img
                                                              // onClick={() => deleteProject(item)}
                                                              style={{
                                                                width: 15,
                                                                height: 15,
                                                                cursor: 'pointer',
                                                                position: 'absolute',
                                                                marginLeft: 10,
                                                                marginTop: 8,
                                                              }}
                                                              src={SharedListShowIcon}
                                                            />
                                                            : null} */}
                                                          {/* <div style={{width:8,height:8,background:'#fff',borderRadius:50}}>

                                                                                </div> */}

                                                          {listUserActivityCount(item.id) &&
                                                            <>
                                                              <img style={{ width: 9, position: 'absolute', marginLeft: mobileView?'24px':'25px' }} className={listUserActivityNotificationCount(item.id) ? 'blinking' : ''} src={RedDot} alt="" />
                                                            </>
                                                          }

                                                          <div style={item.userId != userId ? { flex: 1 } : { flex: 1 }}>
                                                            {editList?.id == item.id ? (
                                                              <input
                                                                // ref={listInputRef}
                                                                autoFocus={true}
                                                                style={{ width: 150 }}
                                                                maxLength={64}
                                                                onChange={e => {
                                                                  let editListData = {
                                                                    ...editList
                                                                  }
                                                                  editListData.projectName =
                                                                    encData(
                                                                      e.target.value
                                                                    )
                                                                  editListData.isEncrypt =
                                                                    '1'

                                                                  setEditList(
                                                                    editListData
                                                                  )
                                                                }}
                                                                value={decData(
                                                                  editList.projectName,
                                                                  editList.isEncrypt
                                                                )}
                                                                onBlur={e => {
                                                                  console.log(
                                                                    'asdfghj',
                                                                    e
                                                                  )
                                                                  if (e.target.value.trim() !== "") {
                                                                    renameList(editList, i)
                                                                  }
                                                                  else {
                                                                    setEditList({})
                                                                  }
                                                                  // renameList(editList, i)
                                                                }}
                                                                onKeyDown={(
                                                                  event: any
                                                                ) => {
                                                                  if (
                                                                    event.key === 'Enter'
                                                                  ) {
                                                                    if (event.target.value.trim() !== "") {
                                                                      renameList(
                                                                        editList,
                                                                        i
                                                                      )
                                                                    }
                                                                    else {
                                                                      setEditList({})
                                                                    }
                                                                    console.log(
                                                                      'User pressed: ',
                                                                      event.key
                                                                    )
                                                                  }
                                                                }}
                                                              />
                                                            ) :
                                                              <span className='oneLine-ellipsis' style={item.userId != userId ? { fontStyle: "italic" } : {}}>
                                                                {decData(
                                                                  item.projectName,
                                                                  item.isEncrypt
                                                                ).substring(
                                                                  0,
                                                                  64
                                                                )
                                                                }{(item.userId != userId || getShareByIdCount("project", item) > 0) ? <i style={{
                                                                  textTransform: "lowercase",
                                                                  fontSize: mobileView ? "16px" : "12px",
                                                                  opacity: .6,
                                                                  fontFamily: 'HelveticaNarrowBold', fontWeight: "normal", paddingRight: "2px"
                                                                }}> (Shared)</i> : ""}

                                                              </span>
                                                            }
                                                            {/* {(item.userId != userId || getShareByIdCount("project", item)>0) &&  <span style={{fontStyle:"italic", marginLeft:5, paddingRight:2, fontSize:11}}>(Shared)</span>} */}
                                                          </div>
                                                          {!mobileView ?
                                                            <div className='listTaskCountdiv'>
                                                              {item.isShowThreeDots /* && (item.userId==userId || getTaskCountOfProject(item.id) >0) */ ? (
                                                                <div
                                                                  className='threeDots'
                                                                  onClick={e => {
                                                                    e.stopPropagation()
                                                                    e.preventDefault()
                                                                    setClicked(false)
                                                                    setProjectClicked(false)
                                                                    setListClicked(true)
                                                                    setPoints({
                                                                      x: e.pageX,
                                                                      y: e.pageY
                                                                    })
                                                                    setDataItem(item)
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{ width: 15.5 }}
                                                                    //src={ThreeDots}
                                                                    src={WhiteDots}
                                                                    alt=''
                                                                  />
                                                                </div>
                                                              ) : (
                                                                <span>
                                                                  {getTaskCountOfProject(
                                                                    item.id
                                                                  )}
                                                                </span>
                                                              )}
                                                            </div>
                                                            :
                                                            <div className='listTaskCountdiv' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                                                              { /* (item.userId==userId || getTaskCountOfProject(item.id) >0) && */  <div
                                                                className='threeDots'
                                                                onClick={e => {
                                                                  e.stopPropagation()
                                                                  e.preventDefault()
                                                                  setClicked(false)
                                                                  setProjectClicked(false)
                                                                  setListClicked(true)
                                                                  setPoints({
                                                                    x: e.pageX,
                                                                    y: e.pageY
                                                                  })
                                                                  setDataItem(item)
                                                                }}
                                                              >
                                                                <img
                                                                  style={{ width: 18 }}
                                                                  //src={ThreeDots}
                                                                  src={WhiteDots}
                                                                  alt=''
                                                                />
                                                              </div>}
                                                              <div style={{ width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <span>
                                                                  {getTaskCountOfProject(
                                                                    item.id
                                                                  )}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          }
                                                        </div>
                                                      </div>
                                                    </>
                                                    {provided.placeholder}
                                                  </div>
                                                )}
                                              </Droppable>
                                            </div>
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Draggable>
                                    }
                                  </>
                                ))}
                              {duplicateListLoader &&
                                <div className='addTaskSteps' >
                                  <div className='taskStepTitleRow' style={{ color: colorSchemes.FontSemiGrey }}>
                                    <img
                                      style={{
                                        width: 16,
                                        height: 16,
                                        cursor: 'pointer'
                                      }}
                                      src={mobileView ? listiconDark : ListIcon}
                                    />
                                    <div style={{ flex: 1 }}>
                                      Duplicate list...
                                      <img className="loading-spinner" style={{ width: 12, height: 12, marginLeft: 8 }} src={Loading} alt="" />
                                    </div>
                                  </div>
                                </div>

                              }
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>

                        {/* <div className='mainFolderDiv' onClick={() => { setFloderOpenStatus(!floderOpenStatus) }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 8 }}>
                            <img src={BlueArrowIcon} style={{ width: floderOpenStatus ? 10 : 10, transform: floderOpenStatus ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
                            <img
                              style={{
                                // width: item.open ? 20 : 19,
                                width: floderOpenStatus ? 20 : 20,
                                cursor: 'pointer'
                              }}
                              src={floderOpenStatus ? GroupIconOpen : GroupIcon}
                            />
                            <div style={{ color: '#3068C8', fontSize: 14, fontFamily: 'ManRopeSemiBold' }}>
                              Folders
                            </div>
                          </div>
                          <img src={BluePlusIcon} style={{ width: 16 }} />
                        </div> */}

                        {/* 03/05 */}

                        {/* {floderOpenStatus && */}
                        <Droppable
                          key={'mainGroup-' + 1}
                          droppableId={`${'mainGroup-' + 1}`}
                          type='droppableGroup'
                        // type={`droppableSubItem`}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              // style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}
                            >
                              {groupData &&
                                groupData.length > 0 &&
                                groupData.map((item: any, i: any) => (
                                  <>
                                    <>
                                      {item.isTrash != "2" &&
                                        <Draggable
                                          type='droppableGroup'
                                          // type={`droppableSubItem`}
                                          key={`drag-project-id-${item.id}`}
                                          draggableId={`dragprojectid-${item.id}`}
                                          index={i}
                                        >
                                          {(provided, snapshot1) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            // style={getItemStyle(
                                            //   snapshot.isDragging,
                                            //   provided.draggableProps.style
                                            // )}
                                            >
                                              <div
                                                style={{
                                                  transform: snapshot1.isDragging
                                                    ? 'scale(0.85)'
                                                    : 'scale(1)',
                                                  background: snapshot1.isDragging
                                                    ?  '#FFFFFF66'
                                                    : 'transparent',
                                                  boxShadow: snapshot1.isDragging
                                                    ? 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px'
                                                    : '',
                                                  // opacity: snapshot.isDragging
                                                  //   ? 0.7
                                                  //   : 'inherit'
                                                }}
                                              >
                                                <Droppable
                                                  key={'group-' + 1}
                                                  droppableId={`${'group-' + item.id}`}
                                                  type='droppableSubItem'
                                                >
                                                  {(provided1111, snapshot) => (
                                                    <div
                                                      ref={provided1111.innerRef}
                                                      style={getGroupStyle(
                                                        snapshot.isDraggingOver
                                                      )}
                                                      {...provided1111.droppableProps}
                                                    >
                                                      <div>
                                                        <div

                                                          id={'group' + item.id}
                                                          onClick={() => {
                                                            openGroup(item)
                                                            // setSelectedProjectGruopID(
                                                            //   item.id
                                                            // )
                                                            setEditList({})
                                                            // setSelectedProjectID('')
                                                            // setSelectedProjectName('')
                                                            // setTitleHeading('')
                                                            // setTaskSectionOpen(false)
                                                            listInputRef?.current?.blur()
                                                            TaskNameInputRef?.current?.blur()
                                                          }}
                                                          className={/* selectedProjectGruopID == item.id ? 'addTaskStepsActive' : */ 'addTaskSteps'}
                                                          style={{
                                                            background: item.color != 'transparent' ? item.color : selectedProjectGruopID == item.id ? snapshot1.isDragging ? 'transparent' : mobileView ? '' : isLightMode? '#FFFFFF66':'rgb(58, 83, 113)' /* colorSchemes.WebActiveBlack */ : item.color,
                                                            marginLeft: 0
                                                            // borderRadius: selectedProjectGruopID == item.id ? '4px 0px 0px 4px' : ''
                                                          }}
                                                          /* 03/05 */
                                                          // onContextMenu={(e) => {
                                                          //   e.preventDefault(); // prevent the default behaviour when right clicked
                                                          //   console.log("Right Click");
                                                          //   openRightList(item)
                                                          // }}
                                                          onContextMenu={e => {
                                                            e.preventDefault()
                                                            setProjectClicked(false)
                                                            setListClicked(false)
                                                            setClicked(true)
                                                            setPoints({
                                                              x: e.pageX,
                                                              y: e.pageY
                                                            })
                                                            setDataItem(item)
                                                            openRightList(item)
                                                            // console.log("Right Click", e.pageX, e.pageY);
                                                          }}

                                                          onMouseEnter={() => {
                                                            try {
                                                              sideBarTaskNoteInputRef?.current?.blur()
                                                            }
                                                            catch (ex) {

                                                            }
                                                            mouseEnterForGroup(item)
                                                          }}
                                                          onMouseLeave={() => mouseLeaveForGroup(item)}

                                                        >
                                                          <div className='taskStepTitleRow' style={mobileView ? {} : { borderRight: '2px solid transparent' }}>
                                                            <img src={WhiteArrowIcon} style={{ width: item.open ? mobileView?16: 15 : mobileView?16:15, transform: item.open ? 'rotate(0deg)' : 'rotate(-90deg)', marginRight:mobileView?'-16px':'-17px' }} />

                                                            <img
                                                              style={{
                                                                width: item.open ? 20 : 19,
                                                                /* height: 16, */
                                                                cursor: 'pointer'
                                                              }}
                                                              src={isDark == 1 && mobileView ? item.open ? GroupIconOpen : GroupIcon : item.open ? GroupIconOpen : GroupIcon}
                                                            />
                                                            {/* {item.userId != userId ?
                                                                <img
                                                                  style={{
                                                                    width: 15,
                                                                    height: 15,
                                                                    cursor: 'pointer',
                                                                    position: 'absolute',
                                                                    marginLeft: 10,
                                                                    marginTop: 8,
                                                                  }}
                                                                  src={SharedListShowIcon}
                                                                />
                                                                : null} */}

                                                            {groupUserActivityCount(item.id) &&
                                                              <>
                                                                <img style={{ width: 9, position: 'absolute', marginLeft: mobileView?'50px': '47px' }} src={RedDot} alt="" />
                                                              </>
                                                            }

                                                            <div className='groupTitleRow '>
                                                              <div className="oneLine-ellipsis" style={item.userId != userId ? {} : {}}>
                                                                {editGroup?.id ==
                                                                  item.id ? (
                                                                  <input
                                                                    style={{ width: 150 }}
                                                                    maxLength={64}
                                                                    onChange={e => {
                                                                      let editGroupData =
                                                                      {
                                                                        ...editGroup
                                                                      }
                                                                      editGroupData.groupName =
                                                                        encData(
                                                                          e.target.value
                                                                        )
                                                                      editGroupData.isEncrypt =
                                                                        '1'
                                                                      setEditGroup(
                                                                        editGroupData
                                                                      )
                                                                    }}
                                                                    value={decData(
                                                                      editGroup.groupName,
                                                                      editGroup.isEncrypt
                                                                    )}
                                                                    onBlur={e => {
                                                                      console.log(
                                                                        'asdfghj',
                                                                        e
                                                                      )
                                                                      renameGroup(
                                                                        editGroup,
                                                                        i
                                                                      )
                                                                    }}
                                                                    onKeyDown={(
                                                                      event: any
                                                                    ) => {
                                                                      if (
                                                                        event.key ===
                                                                        'Enter'
                                                                      ) {
                                                                        renameGroup(
                                                                          editGroup,
                                                                          i
                                                                        )
                                                                        console.log(
                                                                          'User pressed: ',
                                                                          event.key
                                                                        )
                                                                      }
                                                                    }}
                                                                    autoFocus={true}
                                                                  />
                                                                ) :

                                                                  <span className='oneLine-ellipsis' style={item.userId != userId ? { fontStyle: "italic" } : {}}>
                                                                    {decData(
                                                                      item.groupName,
                                                                      item.isEncrypt
                                                                    ).substring(
                                                                      0,
                                                                      64
                                                                    )
                                                                    }{(item.userId != userId || getShareByIdCount("group", item) > 0) ? <i style={{
                                                                      textTransform: "lowercase",
                                                                      fontSize: mobileView ? "16px" : "12px",
                                                                      opacity: .6,
                                                                      fontWeight: "normal",
                                                                      fontFamily: 'HelveticaNarrowBold', paddingRight: "2px"
                                                                    }}> (Shared)</i> : ""}

                                                                  </span>


                                                                }
                                                                {/* {(item.userId != userId || getShareByIdCount("group", item)>0) &&  <span style={{fontStyle:"italic", marginLeft:5, paddingRight:2, fontSize:11}}>(Shared)</span>} */}
                                                              </div>

                                                              {!mobileView ?
                                                                <div className='listTaskCountdiv'>
                                                                  {item.isShowThreeDots /* && item.userId==userId */ ? (
                                                                    <div
                                                                      className='threeDots'
                                                                      onClick={e => {
                                                                        e.stopPropagation()
                                                                        e.preventDefault()
                                                                        setProjectClicked(false)
                                                                        setListClicked(false)
                                                                        setClicked(true)
                                                                        setPoints({
                                                                          x: e.pageX,
                                                                          y: e.pageY
                                                                        })
                                                                        setDataItem(item)
                                                                        openRightList(item)
                                                                      }}
                                                                    >
                                                                      <img
                                                                        style={{
                                                                          width: 15.5
                                                                        }}
                                                                        //src={ThreeDots}
                                                                        src={WhiteDots}
                                                                        alt=''
                                                                      />
                                                                    </div>
                                                                  ) : (
                                                                    <span>
                                                                      {item.project.length}
                                                                    </span>
                                                                  )}
                                                                </div>
                                                                :
                                                                <div className='listTaskCountdiv' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                                                                  {item.open /* && item.userId==userId */ ?
                                                                    <div
                                                                      className='threeDots'
                                                                      onClick={e => {
                                                                        e.stopPropagation()
                                                                        e.preventDefault()
                                                                        setProjectClicked(false)
                                                                        setListClicked(false)
                                                                        setClicked(true)
                                                                        setPoints({
                                                                          x: e.pageX,
                                                                          y: e.pageY
                                                                        })
                                                                        setDataItem(item)
                                                                        openRightList(item)
                                                                      }}
                                                                    >
                                                                      <img
                                                                        style={{
                                                                          width: 20
                                                                        }}
                                                                        //src={ThreeDots}
                                                                        src={WhiteDots}
                                                                        alt=''
                                                                      />
                                                                    </div>
                                                                    : null
                                                                  }
                                                                  <div style={{ width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <span>
                                                                      {item.project.length}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              }



                                                              {/* <img
                                                                className={
                                                                  item.open
                                                                    ? 'groupDownArrow'
                                                                    : 'groupUpArrow'
                                                                }
                                                                src={completedArrowIcon}
                                                              /> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      {provided1111.placeholder}
                                                    </div>
                                                  )}
                                                </Droppable>
                                              </div>
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Draggable>

                                      }
                                      <>
                                        {item.open && (
                                          <>

                                            <Droppable
                                              key={'projectList-' + item.id}
                                              droppableId={`${'projectList-' + item.id
                                                }`}
                                              type={`droppableSubItem`}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  // style={getGroupListStyle(snapshot.isDraggingOver)}
                                                  {...provided.droppableProps}
                                                  style={mobileView ? { borderLeft: '2px solid rgba(228, 228, 228,0.9)', marginLeft: 30 } : { marginLeft: 10, /* borderLeft: '2px solid rgba(228, 228, 228,0.9)' */ }}
                                                >
                                                  {item.project &&
                                                    item.project.length > 0 &&
                                                    item.project.map(
                                                      (pitem: any, i: any) => (
                                                        <>
                                                          {pitem.isTrash != "2" &&
                                                            <Draggable
                                                              type={`droppableSubItem`}
                                                              key={`drag-projectList-id-${pitem.id}`}
                                                              draggableId={`dragprojectListid-${pitem.id}`}
                                                              index={i}
                                                            >
                                                              {(provided, snapshot1) => (
                                                                <div
                                                                  ref={provided.innerRef}
                                                                  {...provided.draggableProps}
                                                                  {...provided.dragHandleProps}
                                                                // style={getItemStyle(
                                                                //   snapshot.isDragging,
                                                                //   provided.draggableProps.style
                                                                // )}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      transform:
                                                                        snapshot1.isDragging
                                                                          ? 'scale(0.85)'
                                                                          : 'scale(1)',
                                                                      background:
                                                                        snapshot1.isDragging
                                                                          ? '#FFFFFF66'
                                                                          : 'transparent',
                                                                      boxShadow:
                                                                        snapshot1.isDragging
                                                                          ? 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px'
                                                                          : '',
                                                                      // opacity:
                                                                      //   snapshot1.isDragging
                                                                      //     ? 0.7
                                                                      //     : 'inherit'
                                                                    }}
                                                                  >
                                                                    <Droppable
                                                                      key={
                                                                        'groupListItem-' +
                                                                        pitem.id
                                                                      }
                                                                      droppableId={`${'groupListItem-' +
                                                                        pitem.id
                                                                        }`}
                                                                      type={`list`}
                                                                    >
                                                                      {(
                                                                        provided,
                                                                        snapshot
                                                                      ) => (
                                                                        <div
                                                                          ref={
                                                                            provided.innerRef
                                                                          }
                                                                          // style={getListStyle(snapshot.isDraggingOver)}
                                                                          style={getListStyle(
                                                                            snapshot.isDraggingOver
                                                                          )}
                                                                          {...provided.droppableProps}
                                                                        >
                                                                          <div
                                                                            // style={{ background: selectedProjectID == pitem.id ? colorSchemes.WebActiveBlack : pitem.color }}
                                                                            id={"list" + pitem.id}
                                                                            onClick={() => {
                                                                              console.log('click');
                                                                              setHelpSupportPage(false)
                                                                              setCompleteTaskShow(false)
                                                                              setSearchBarInputValue('')
                                                                              try {
                                                                                taskAddScrollRef.current.scrollTop = 0
                                                                              }
                                                                              catch (ex) { }
                                                                              setSelectedProject(pitem.id, pitem.projectName)
                                                                              leftPaneMobile()
                                                                              setEditSelectedList(false)
                                                                              try {
                                                                                if (listItem && listItem.id && listItem.id != pitem.id) {
                                                                                  if (getTaskCountOfProject(pitem.id) > 0) {
                                                                                    if (taskData.length > 0) {
                                                                                      let taskdata1 = [...taskData]

                                                                                      for (let i = 0; i < taskdata1[0].length; i++) {
                                                                                        if (parseInt(taskdata1[0][i].projectId) == parseInt(pitem.id) && taskdata1[0][i].isTrash != "2" && taskdata1[0][i].taskHeading != "1") {
                                                                                          setTaskItem(taskdata1[0][i]);
                                                                                          taskItemRef.current = taskdata1[0][i];
                                                                                          setTaskSectionOpen(true)
                                                                                          break;
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                  else {
                                                                                    setTaskSectionOpen(false)
                                                                                  }
                                                                                }
                                                                              }
                                                                              catch (ex) {
                                                                                console.log("ex56", ex)
                                                                              }
                                                                              setListItem(pitem)
                                                                              listItemRef.current = pitem
                                                                              if (userId == pitem.userId) {
                                                                                getListToFolder(pitem)
                                                                              }
                                                                              console.log(
                                                                                'pitem',
                                                                                pitem
                                                                              )
                                                                              // setTitleHeading(
                                                                              //   pitem.projectName
                                                                              // )
                                                                              // setSelectedProjectGruopID(
                                                                              //   ''
                                                                              // )
                                                                              setSelectedProjectGruopID(
                                                                                item.id
                                                                              )
                                                                              localStorage.setItem(
                                                                                'project',
                                                                                JSON.stringify(
                                                                                  pitem
                                                                                )
                                                                              )
                                                                              setEditList(
                                                                                {}
                                                                              )
                                                                              TitleHeadTaskNameInput?.current?.blur()
                                                                              TaskNameInputRef?.current?.blur()
                                                                              listInputRef?.current?.blur()
                                                                              setTimeout(
                                                                                () => {
                                                                                  setSelectedProject(
                                                                                    pitem.id,
                                                                                    pitem.projectName
                                                                                  )
                                                                                },
                                                                                10
                                                                              )
                                                                            }}
                                                                            onContextMenu={e => {
                                                                              e.preventDefault()
                                                                              setClicked(
                                                                                false
                                                                              )
                                                                              setListClicked(
                                                                                false
                                                                              )
                                                                              setProjectClicked(
                                                                                true
                                                                              )
                                                                              setPoints({
                                                                                x: e.pageX,
                                                                                y: e.pageY
                                                                              })
                                                                              setDataItem(
                                                                                pitem
                                                                              )
                                                                            }}
                                                                            onMouseEnter={() => {
                                                                              try {
                                                                                sideBarTaskNoteInputRef?.current?.blur()
                                                                              }
                                                                              catch (ex) {

                                                                              }
                                                                              mouseEnterForGroupList(
                                                                                item,
                                                                                pitem
                                                                              )
                                                                            }
                                                                            }
                                                                            onMouseLeave={() =>
                                                                              mouseLeaveForGroupList(
                                                                                item,
                                                                                pitem
                                                                              )
                                                                            }
                                                                          >
                                                                            <div
                                                                              className={
                                                                                snapshot.isDraggingOver
                                                                                  ? 'taskStepTitleRowForBorder'
                                                                                  : selectedProjectID == pitem.id
                                                                                    ? 'taskStepTitleRow groupListsActive' : isLightMode ?'taskStepTitleRow groupLists':'taskStepTitleRow groupLists groupListsDark'
                                                                              }

                                                                              style={snapshot1.isDragging && selectedProjectID == pitem.id ? { background: 'transparent', color: '#fff' } :selectedProjectID == pitem.id? {background:isLightMode?'#FFFFFF66':selectedProjectID == pitem.id?'rgb(58, 83, 113)':'#FFFFFF66'}:{}}
                                                                            >
                                                                              {pitem.isNote && pitem.isNote == 1 ?
                                                                                <img
                                                                                  style={{
                                                                                    width: 16,
                                                                                    height: 16,
                                                                                    cursor: 'pointer', marginLeft:mobileView?20:'unset'
                                                                                  }}
                                                                                  src={TaskNoteIcon}
                                                                                />
                                                                                :
                                                                                <img
                                                                                  style={{
                                                                                    width: 16,
                                                                                    height: 16,
                                                                                    cursor: 'pointer', marginLeft:mobileView?20:'unset'
                                                                                  }}
                                                                                  src={mobileView ? listiconDark : listiconDark}
                                                                                />
                                                                              }
                                                                              {/* <div style={{width:8,height:8,background:'#fff',borderRadius:50}}>

                                                                                </div> */}
                                                                              {/* {pitem.userId != userId ?
                                                                                  <img
                                                                                    style={{
                                                                                      width: 15,
                                                                                      height: 15,
                                                                                      cursor: 'pointer',
                                                                                      position: 'absolute',
                                                                                      marginLeft: 10,
                                                                                      marginTop: 8,
                                                                                    }}
                                                                                    src={SharedListShowIcon}
                                                                                  />
                                                                                  : null} */}

                                                                              {listUserActivityCount(pitem.id) &&
                                                                                <>
                                                                                  <img style={{ width: 9, position: 'absolute', marginLeft: mobileView?'44.5px':'23.5px' }} className={listUserActivityNotificationCount(pitem.id) ? 'blinking' : ''} src={RedDot} alt="" />
                                                                                </>
                                                                              }

                                                                              <div


                                                                                style={pitem.userId != userId ? { flex: 1, marginLeft: 2 } : { flex: 1, marginLeft: 2 }}
                                                                              >
                                                                                {editList?.id ==
                                                                                  pitem.id ? (
                                                                                  <input
                                                                                    // ref={
                                                                                    //   listInputRef
                                                                                    // }
                                                                                    style={{
                                                                                      width:
                                                                                        '100%'
                                                                                    }}
                                                                                    maxLength={64}
                                                                                    onChange={e => {
                                                                                      let editListData =
                                                                                      {
                                                                                        ...editList
                                                                                      }
                                                                                      editListData.projectName =
                                                                                        encData(
                                                                                          e
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      editListData.isEncrypt =
                                                                                        '1'
                                                                                      setEditList(
                                                                                        editListData
                                                                                      )
                                                                                    }}
                                                                                    value={decData(
                                                                                      editList.projectName,
                                                                                      editList.isEncrypt
                                                                                    )}
                                                                                    onBlur={e => {
                                                                                      console.log(
                                                                                        'asdfghj',
                                                                                        e
                                                                                      )
                                                                                      if (e.target.value.trim() !== "") {
                                                                                        renameList(
                                                                                          editList,
                                                                                          i
                                                                                        )
                                                                                      }
                                                                                      else {
                                                                                        setEditList({})
                                                                                      }
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                      event: any
                                                                                    ) => {
                                                                                      if (
                                                                                        event.key ===
                                                                                        'Enter'
                                                                                      ) {
                                                                                        if (event.target.value.trim() !== "") {
                                                                                          renameList(
                                                                                            editList,
                                                                                            i
                                                                                          )
                                                                                        }
                                                                                        else {
                                                                                          setEditList({})
                                                                                        }
                                                                                        console.log(
                                                                                          'User pressed: ',
                                                                                          event.key
                                                                                        )
                                                                                      }
                                                                                    }}
                                                                                    autoFocus={
                                                                                      true
                                                                                    }
                                                                                  />
                                                                                ) :
                                                                                  <>
                                                                                    <div className="listTooltip">
                                                                                      <span className='oneLine-ellipsis' style={pitem.userId != userId ? { fontStyle: "italic" } : {}}>
                                                                                        {decData(
                                                                                          pitem.projectName,
                                                                                          pitem.isEncrypt
                                                                                        ).substring(0, 64)
                                                                                        }{(pitem.userId != userId || getShareByIdCount("project", pitem) > 0) ? <i style={{
                                                                                          textTransform: "lowercase",
                                                                                          fontSize: mobileView ? "16px" : "12px",
                                                                                          opacity: .6,
                                                                                          fontFamily: 'HelveticaNarrowBold', fontWeight: "normal", paddingRight: "2px"
                                                                                        }}> (Shared)</i> : ""}
                                                                                        {/* {(pitem.userId != userId || getShareByIdCount("project", pitem)>0) &&  <span style={{fontStyle:"italic", marginLeft:5, paddingRight:2, fontSize:11}}>(Shared)</span>} */}
                                                                                      </span>
                                                                                      {pitem && pitem.projectName && decData(pitem.projectName, pitem.isEncrypt).length > 14 ? <span className="listTooltiptext">{decData(pitem.projectName, pitem.isEncrypt).substring(0, 64)}</span> : null}
                                                                                    </div>
                                                                                  </>
                                                                                }

                                                                              </div>
                                                                              {!mobileView ?
                                                                                <div className='listTaskCountdiv'>
                                                                                  {pitem.isShowThreeDots /* && (pitem.userId==userId || getTaskCountOfProject(pitem.id) >0) */ ? (
                                                                                    <div
                                                                                      className='threeDots'
                                                                                      onClick={e => {
                                                                                        e.stopPropagation()
                                                                                        e.preventDefault()
                                                                                        setClicked(
                                                                                          false
                                                                                        )
                                                                                        setListClicked(
                                                                                          false
                                                                                        )
                                                                                        setProjectClicked(
                                                                                          true
                                                                                        )
                                                                                        setPoints(
                                                                                          {
                                                                                            x: e.pageX,
                                                                                            y: e.pageY
                                                                                          }
                                                                                        )
                                                                                        setDataItem(
                                                                                          pitem
                                                                                        )
                                                                                      }}
                                                                                    >
                                                                                      <img
                                                                                        style={{
                                                                                          width: 15.5
                                                                                        }}
                                                                                        //src={ThreeDots}
                                                                                        src={WhiteDots}
                                                                                        alt=''
                                                                                      />
                                                                                    </div>
                                                                                  ) : (
                                                                                    <span>
                                                                                      {getTaskCountOfProject(
                                                                                        pitem.id
                                                                                      )}
                                                                                    </span>
                                                                                  )}
                                                                                </div>
                                                                                :
                                                                                <div className='listTaskCountdiv' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                                                                                  {(pitem.userId == userId /* || getTaskCountOfProject(pitem.id) >0 */) && <div
                                                                                    className='threeDots'
                                                                                    onClick={e => {
                                                                                      e.stopPropagation()
                                                                                      e.preventDefault()
                                                                                      setClicked(
                                                                                        false
                                                                                      )
                                                                                      setListClicked(
                                                                                        false
                                                                                      )
                                                                                      setProjectClicked(
                                                                                        true
                                                                                      )
                                                                                      setPoints(
                                                                                        {
                                                                                          x: e.pageX,
                                                                                          y: e.pageY
                                                                                        }
                                                                                      )
                                                                                      setDataItem(
                                                                                        pitem
                                                                                      )
                                                                                    }}
                                                                                  >
                                                                                    <img
                                                                                      style={{
                                                                                        width: 20
                                                                                      }}
                                                                                      //src={ThreeDots}
                                                                                      src={WhiteDots}
                                                                                      alt=''
                                                                                    />
                                                                                  </div>}
                                                                                  <div style={{ width: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <span>
                                                                                      {getTaskCountOfProject(
                                                                                        pitem.id
                                                                                      )}
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                          {
                                                                            provided.placeholder
                                                                          }
                                                                        </div>
                                                                      )}
                                                                    </Droppable>
                                                                  </div>
                                                                  {provided.placeholder}
                                                                </div>
                                                              )}

                                                            </Draggable>
                                                          }
                                                        </>
                                                      )
                                                    )}

                                                  <>
                                                    {duplicateGroupListLoader &&
                                                      <>
                                                        {dataItem.projectGroupId == item.id &&

                                                          <div className='groupLists' >
                                                            <div className='taskStepTitleRow' style={{ color: colorSchemes.FontSemiGrey }}>
                                                              <img
                                                                style={{
                                                                  width: 16,
                                                                  height: 16,
                                                                  cursor:
                                                                    'pointer'
                                                                }}
                                                                src={mobileView ? listiconDark : ListIcon
                                                                }
                                                              />

                                                              <div style={{ flex: 1 }}>
                                                                Duplicate list...
                                                                <img className="loading-spinner" style={{ width: 12, height: 12, marginLeft: 8 }} src={Loading} alt="" />
                                                              </div>

                                                            </div>
                                                          </div>
                                                        }
                                                      </>

                                                    }
                                                  </>
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>


                                            {item.project.length > 0 ? (
                                              <>
                                                {item.addNewList && addGroupList ? (
                                                  <div
                                                    style={{ backgroundColor: "#ECF1FF", marginLeft: 12 }}
                                                    className='groupLists'
                                                    onBlur={() => {
                                                      setAddGroupList(false)
                                                      item.addNewList = false
                                                    }}
                                                  >
                                                    <div
                                                      className='taskStepTitleRow'
                                                      style={{ color: colorSchemes.FontSemiGrey }}
                                                    >
                                                      <img
                                                        style={{
                                                          width: 16,
                                                          height: 16,
                                                          cursor: 'pointer'
                                                        }}
                                                        src={mobileView ? listiconDark : ListIcon}
                                                      />

                                                      <div style={{ flex: 1 }}>
                                                        <input
                                                          className='addProjectNewListInput1'
                                                          type='text'
                                                          name='name'
                                                          placeholder='Untitled List'
                                                          maxLength={64}
                                                          onBlur={e => {
                                                            handleBlurProjectNewList(
                                                              e,
                                                              item
                                                            )
                                                          }}
                                                          autoComplete='off'
                                                          autoFocus={true}
                                                          value={
                                                            projectsNewListInputValue
                                                          }
                                                          onChange={
                                                            handleChangeForProjectNewList
                                                          }
                                                          // onKeyDown={handleKeyDownForProjectNewList}
                                                          onKeyDown={e => {
                                                            handleKeyDownForProjectNewList(
                                                              e,
                                                              item
                                                            )
                                                          }}
                                                        />
                                                      </div>
                                                      <div className='listTaskCountdiv'>
                                                        <span>
                                                          0
                                                        </span>
                                                      </div>
                                                      {/* {projectsNewListInputValue.length >
                                                0 && (
                                                <div
                                                  className='addBtn'
                                                  onClick={() => {
                                                    addListIntoProject(item, 0)
                                                    setProjectsNewListInputValue(
                                                      ''
                                                    )
                                                    item.addNewList = false
                                                  }}
                                                >
                                                  Add
                                                </div>
                                              )} */}
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </>
                                            ) : (
                                              <>
                                                {item.addNewList && addGroupList ? (
                                                  <div
                                                    className={isDark == 1 ? 'groupLists' : 'groupLists '}
                                                    onBlur={() => {
                                                      setAddGroupList(false)
                                                      item.addNewList = false
                                                    }}
                                                    style={{ background: colorSchemes.WebSearchBackColor }}
                                                  >
                                                    <div
                                                      className='taskStepTitleRow'
                                                      style={{ color: colorSchemes.FontSemiGrey, gap: 6 }}
                                                    >
                                                      <img
                                                        style={{
                                                          filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                                          width: 12,
                                                          height: 12,
                                                          cursor: 'pointer'
                                                        }}
                                                        src={AddProjectIcon}
                                                      />

                                                      <div style={{ flex: 1 }}>
                                                        <input
                                                          className='addProjectNewListInput'
                                                          style={{ background: colorSchemes.WebSearchBackColor, color: colorSchemes.WebFontSemiGrey }}
                                                          type='text'
                                                          name='name'
                                                          placeholder='Untitled List'
                                                          maxLength={64}
                                                          onBlur={e => {
                                                            handleBlurProjectNewList(
                                                              e,
                                                              item
                                                            )
                                                          }}
                                                          autoComplete='off'
                                                          autoFocus={true}
                                                          value={
                                                            projectsNewListInputValue
                                                          }
                                                          onChange={
                                                            handleChangeForProjectNewList
                                                          }
                                                          // onKeyDown={handleKeyDownForProjectNewList}
                                                          onKeyDown={e => {
                                                            handleKeyDownForProjectNewList(
                                                              e,
                                                              item
                                                            )
                                                          }}
                                                        />
                                                      </div>
                                                      {projectsNewListInputValue.length >
                                                        0 && (
                                                          <div
                                                            className='addBtn'
                                                            onClick={() => {
                                                              addListIntoProject(item, 0)
                                                              setProjectsNewListInputValue(
                                                                ''
                                                              )
                                                              item.addNewList = false
                                                            }}
                                                            style={{ color: colorSchemes.WebFontSemiGrey }}
                                                          >
                                                            Add
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className={isDark == 1 ? 'groupLists' : 'groupLists '}
                                                    // onClick={() => {
                                                    //   addProjectNewList(item)
                                                    // }}
                                                    style={{ background: 'transparent', cursor: 'default', marginLeft: 0 }}
                                                  >
                                                    <div
                                                      className='taskStepTitleRow'
                                                      style={{ color: colorSchemes.WebFontSemiGrey, gap: 8, cursor: 'default' }}
                                                    >
                                                      <img
                                                        style={{
                                                          width: 20,
                                                          height: 20,
                                                          cursor: 'default'
                                                        }}
                                                        src={AddTaskBarIcon}
                                                      />
                                                      {/* <div style={{width:8,height:8,background:'#fff',borderRadius:50}}></div> */}

                                                      <div style={{ flex: 1, color: colorSchemes.WebFontSemiGrey }}>
                                                        New list, click 3 dot menu on folder
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    </>
                                  </>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        {/* } */}




                      </div>
                    </div>
                    {/* <div className='addTaskSteps'
                      onClick={() => {
                        try {
                          taskAddScrollRef.current.scrollTop = 0
                        }
                        catch (ex) { }
                        setSelectedProject(Base64.encode("1"), 'Trash')
                        setListItem({})
                        // console.log('item123', item)
                        // setTitleHeading(item.projectName)
                        setSelectedProjectGruopID('')
                        // localStorage.setItem(
                        //   'project',
                        //   JSON.stringify(item)
                        // )
                        setTaskSectionOpen(false)
                        TitleHeadTaskNameInput?.current?.blur()
                        TaskNameInputRef?.current?.blur()
                        listInputRef?.current?.blur()
                        setTimeout(() => {
                          setSelectedProject(Base64.encode("1"), 'Trash')
                        }, 10)
                        setEditList({})
                        leftPaneMobile()
                      }}
                    // className={selectedProjectID == encData('1')? 'addTaskStepsActive': 'addTaskSteps'}
                    // onContextMenu={e => {
                    //   e.preventDefault()
                    //   setClicked(false)
                    //   setProjectClicked(false)
                    //   setListClicked(true)
                    //   setPoints({
                    //     x: e.pageX,
                    //     y: e.pageY
                    //   })
                    //   setDataItem(item)
                    // }}
                    // onMouseEnter={() =>
                    //   mouseEnter(item)
                    // }
                    // onMouseLeave={() =>
                    //   mouseLeave(item)
                    // }
                    > */}
                    {/* <div className='taskStepTitleRow' style={{ color: colorSchemes.WebFontSemiGrey, }}>
                        <img
                          style={{
                            width: 20,
                            height: 20,
                            cursor: 'pointer'
                          }}
                          src={Delete}
                        />
                        <div style={{ flex: 1, fontWeight: 600 }}>
                          Trash
                        </div>
                      </div> */}
                    {/* </div> */}
                  </div>

                  <div
                    style={{
                      marginTop: 5
                      // display: 'flex',
                      // borderTopWidth: 0.5,
                      // borderTopStyle: 'solid',
                      // borderTopColor: 'lightgray',
                      // position: 'absolute',
                      // bottom: 0,
                      // flexDirection: 'column',
                      // width: mobileView ? '100%' : 'auto',
                      // paddingBottom: mobileView ? '40px' : '18px',
                      // zIndex: showIntroScreen ? 2 : 0,
                    }}
                  >
                    {/* {addList && (
                  <div
                    className='addTaskDrop'
                    style={{ marginLeft: 0, marginRight: 0, paddingRight: 0 }}
                  >
                    <img
                      style={{
                        width: 15,
                        height: 15,
                        cursor: 'pointer',
                        marginRight: 6
                      }}
                      src={AddProjectIcon}
                    />
                    <input
                      className='addProjectInput'
                      type='text'
                      name='name'
                      placeholder='Untitled List'
                      maxLength={64}
                      onBlur={handleBlurForList}
                      autoComplete='off'
                      autoFocus={true}
                      value={projectsInputValue}
                      onChange={handleChangeForProject}
                      onKeyDown={handleKeyDownForProject}
                    />
                    {projectsInputValue.length > 0 && (
                      <div
                        className='addBtn'
                        onClick={() => {
                          addProjectToList()
                          setProjectsInputValue('')
                          setAddList(false)
                        }} 
                      >
                        Add
                      </div>
                    )}
                  </div>
                )} */}
                    {addProject && (
                      <div
                        className='addTaskDrop'
                        style={{ marginLeft: 0, marginRight: 0, paddingRight: 0, background: colorSchemes.SearchBackColor, color: colorSchemes.FontSemiGrey }}
                      >
                        <img
                          style={{
                            width: 16,
                            /* height: 16, */
                            cursor: 'pointer',
                            marginRight: 6
                          }}
                          src={isDark == 1 && mobileView ? GroupIconDark : GroupIcon}
                        />
                        <input
                          className='addProjectInput'
                          style={{ color: colorSchemes.FontSemiGrey }}
                          type='text'
                          name='name'
                          placeholder='Untitled Project'
                          maxLength={64}
                          onBlur={handleBlurForProject}
                          autoComplete='off'
                          autoFocus={true}
                          value={groupInputValue}
                          onChange={handleChangeForGroup}
                          onKeyDown={handleKeyDownForGroup}
                        />
                        {groupInputValue.length > 0 && (
                          <div
                            className='addBtn'
                            onClick={() => {
                              addGroups()
                              setGroupInputValue('')
                              setAddProject(false)
                            }}
                          >
                            Add
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        // borderTopWidth: 0.5,
                        // borderTopStyle: 'solid',
                        // borderTopColor: 'lightgray',
                        justifyContent: 'space-between',
                        // minWidth: 280,
                        padding: mobileView ? '0px 12px' : '0px 12px',
                        alignItems: 'center',
                        // background:showIntroScreen ? 'white' : ''
                      }}
                    >

                      <div
                        className='newListAndGroup'
                        onClick={() => {
                          setHelpSupportPage(false);
                          setSearchBarInputValue('')
                          if (!showIntroScreen) {
                            if (mobileView) {
                              showRenamePopUp({}, "project")
                            }
                            else {
                              // setAddProject(true)
                              showRenamePopUp({}, "project")
                            }
                          }
                        }}
                        style={{
                          gap: 10
                        }}
                      >
                        <img
                          style={{ width: 20,/*  height: 20, */ cursor: 'pointer' }}
                          src={AddGroupIcon}
                        />
                        <div style={{ color: colorSchemes.WebListProject, fontSize: mobileView ? 16 : 13, fontWeight: mobileView ? '600' : '400', fontFamily: mobileView ? 'Helvetica' : '' }}>
                          New Folder
                        </div>
                      </div>

                      <div
                        className='newListAndGroup'
                        style={{
                          gap: 10
                        }}
                        onClick={() => { setHelpSupportPage(false); if (!showIntroScreen) { setAddList(true); setAddNewListDialog(true); selectDefaultGroup(); setSearchBarInputValue(''); } }}
                      >
                        <img
                          style={{ width: 20, cursor: 'pointer', marginTop: 3 }}
                          src={NewListDarkIcon}
                        />
                        {/* <img
                        style={{ width: 12, height: 12, cursor: 'pointer', position: 'absolute', marginLeft: -45, marginTop: 8 }}
                        src={AddCircleIcon}
                      /> */}
                        <div style={{ color: colorSchemes.WebListProject, fontSize: mobileView ? 16 : 13, fontWeight: mobileView ? '600' : '400', fontFamily: mobileView ? 'Helvetica' : '' }}>
                          New List
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 03/05 */}
              {!mobileView ?
                <>
                  {projectClicked && (
                    <div
                      className='rightListDiv'
                      style={{
                        top: points.y,
                        left: points.x
                      }} /* top={points.y} left={points.x} */
                    >
                      <div
                        className='closeIconDiv'
                        onClick={() => setProjectClicked(false)}
                      >
                        <img src={CloseMenuListIcon} alt='' />
                      </div>
                      {dataItem.userId == userId ? <>
                        <ul>
                          <li
                            onClick={() => {
                              setEditList(dataItem)
                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            Rename List
                          </li>

                          <li
                            onClick={() => {
                              // setEditList(dataItem)
                              duplicateList(dataItem)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={DuplicateIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                            Duplicate List
                          </li>
                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setProjectClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            setOpenDialog(true)
                            setSelectedShareType("project");
                            selectedShareTypeRef.current = "project"
                            setUserShareList(dataItem)
                            if (dataItem.userId != userId) {
                              getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                            }
                          }}>
                            <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            Share list
                          </li>
                          <li
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setProjectClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              showTaskId(dataItem)
                            }}
                          >
                            <img src={UniqueIdIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            {dataItem && dataItem.showTaskId == "0" ?
                              <span>Show task ID</span>
                              :
                              <span>Hide task ID</span>
                            }
                          </li>
                          <li
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setProjectClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              // showTaskId(dataItem)
                              if (dataItem && dataItem.readOnlyStatus == "0") {
                                handleReadOnlyConfirm(dataItem)
                              }
                              else {
                                readOnlyFun(dataItem)
                              }
                            }}
                          >
                            <img src={ReadOnly} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            {dataItem && dataItem.readOnlyStatus == "0" ?
                              <span>Read only</span>
                              :
                              <span>Remove read only</span>
                            }
                          </li>
                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setProjectClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            leftPaneMobile()
                            setTimeout(() => {
                              window.print();
                            }, 200);
                          }}>
                            <img
                              style={{
                                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={PrinterIcon}
                              alt=''
                            />
                            Print List
                          </li>
                          {/* <li className='customCursor'>
                                  <img
                                    style={{
                                      width: 14,
                                      marginLeft: 3,
                                      marginRight: 2
                                    }}
                                    src={Remove}
                                    alt=''
                                  />
                                  Remove from project
                                </li> */}
                          {/* <li className='customCursor'>
                            <img
                              style={{
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={AddProjectIcon}
                              alt=''
                            />
                            Move List to
                          </li> */}
                          <RecursiveMenuItem autoFocus={false} label="Move list to" icon={AddGroupIcon} arrowIcon={RightBlackArrow}>
                            {/* <RecursiveMenu /> */}
                            <RecursiveMenuList />
                          </RecursiveMenuItem>
                          {/* <li
                          onClick={() => {
                            setColorPalette(true)
                            setHighlightFrom('pitem')
                          }}
                        >
                          <img src={MarkerIcon} alt='' />
                          Highlight list
                        </li> */}
                          <li
                            style={{
                              color: 'red',
                              borderTopWidth: 0.5,
                              borderTopColor: 'lightgray',
                              borderTopStyle: 'solid'
                            }}
                            onClick={() => handleProjectDeleteConfirm(dataItem)}
                          >
                            <img src={RedDelete} alt='' />
                            Move List to Trash
                          </li>
                        </ul>
                      </>
                        :
                        <>
                          <ul>
                            <li onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setProjectClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              setOpenDialog(true)
                              setSelectedShareType("project");
                              selectedShareTypeRef.current = "project"
                              setUserShareList(dataItem)
                              if (dataItem.userId != userId) {
                                getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                              }
                            }}>
                              <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                              Shared With
                            </li>
                            <li onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setProjectClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              leftPaneMobile()
                              setTimeout(() => {
                                window.print();
                              }, 200);
                            }}>
                              <img
                                style={{
                                  filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                  width: 14,
                                  marginLeft: 3,
                                  marginRight: 2
                                }}
                                src={PrinterIcon}
                                alt=''
                              />
                              Print List
                            </li>
                            {/* {getExitStatus(dataItem) &&      <li
                                    style={{
                                      color: 'red',
                                      borderTopWidth: 0.5,
                                      borderTopColor: 'lightgray',
                                      borderTopStyle: 'solid'
                                    }}
                                    onClick={() => handleProjectExitConfirm(dataItem)}
                                  >
                                    <img src={ExitShareIcon} alt='' />
                                    Exit
                                  </li>
} */}
                          </ul>

                        </>
                      }
                    </div>
                  )}
                </>
                :
                <SwipeableDrawer
                  anchor="bottom"
                  open={projectClicked}
                  onClose={() => setProjectClicked(false)}
                  onOpen={() => setProjectClicked(true)}
                  className='mainDrawerContainer'
                >
                  <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }} >
                    <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div></div>
                    </div>
                    <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div style={{ width: '20%' }}></div>
                      <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                        List Options
                      </div>
                      <div className='drawerHeadingDone' onClick={() => setProjectClicked(false)}>Done</div>
                    </div>
                    {/* <div
                            className='closeIconDiv'
                            onClick={() => setProjectClicked(false)}
                          >
                            <img src={CloseMenuListIcon} alt='' />
                          </div> */}
                    {dataItem.userId == userId ? <>
                      <ul>
                        <li
                          onClick={() => {
                            // setEditList(dataItem)
                            showRenamePopUp(dataItem, "list")
                            console.log('projectname', dataItem)
                          }}
                        >
                          <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          Rename List
                        </li>

                        <li
                          onClick={() => {
                            // setEditList(dataItem)
                            duplicateList(dataItem)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            console.log('projectname', dataItem)
                          }}
                        >
                          <img src={DuplicateIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                          Duplicate List
                        </li>
                        <li onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          setProjectClicked(false)
                          setSelectedProject(dataItem.id, dataItem.projectName)
                          setListItem(dataItem)
                          listItemRef.current = dataItem
                          localStorage.setItem('project', JSON.stringify(dataItem))
                          setOpenDialog(true)
                          setSelectedShareType("project");
                          selectedShareTypeRef.current = "project"
                          setUserShareList(dataItem)
                          if (dataItem.userId != userId) {
                            getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                          }
                        }}>
                          <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          Share list
                        </li>
                        <li
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setProjectClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            showTaskId(dataItem)
                          }}
                        >
                          <img src={UniqueIdIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          {dataItem && dataItem.showTaskId == "0" ?
                            <span>Show task ID</span>
                            :
                            <span>Hide task ID</span>
                          }
                        </li>
                        <li
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setProjectClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            if (dataItem && dataItem.readOnlyStatus == "0") {
                              handleReadOnlyConfirm(dataItem)
                            }
                            else {
                              readOnlyFun(dataItem)
                            }
                          }}
                        >
                          <img src={ReadOnly} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          {dataItem && dataItem.readOnlyStatus == "0" ?
                            <span>Read only</span>
                            :
                            <span>Remove read only</span>
                          }
                        </li>
                        <li onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          setProjectClicked(false)
                          setSelectedProject(dataItem.id, dataItem.projectName)
                          setListItem(dataItem)
                          listItemRef.current = dataItem
                          localStorage.setItem('project', JSON.stringify(dataItem))
                          leftPaneMobile()
                          setTimeout(() => {
                            window.print();
                          }, 200);
                        }}>
                          <img
                            style={{
                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                              width: 14,
                              marginLeft: 3,
                              marginRight: 2
                            }}
                            src={PrinterIcon}
                            alt=''
                          />
                          Print List
                        </li>
                        {/* <li className='customCursor'>
                                <img
                                  style={{
                                    width: 14,
                                    marginLeft: 3,
                                    marginRight: 2
                                  }}
                                  src={Remove}
                                  alt=''
                                />
                                Remove from project
                              </li> */}
                        <li onClick={() => { setMoveGroupListShow(true) }}>
                          <img
                            style={{
                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                              width: 14,
                              marginLeft: 3,
                              marginRight: 2
                            }}
                            src={AddProjectIcon}
                            alt=''
                          />
                          Move List to
                        </li>
                        {/* <li
                          onClick={() => {
                            setColorPalette(true)
                            setHighlightFrom('pitem')
                          }}
                        >
                          <img src={MarkerIcon} alt='' />
                          Highlight list
                        </li> */}
                        <li
                          style={{
                            color: 'red',
                            /* borderTopWidth: 0.5,
                            borderTopColor: 'lightgray',
                            borderTopStyle: 'solid' */
                          }}
                          onClick={() => handleProjectDeleteConfirm(dataItem)}
                        >
                          <img src={RedDelete} alt='' />
                          Move List to Trash
                        </li>
                      </ul>
                    </>
                      :
                      <>
                        <ul>

                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setProjectClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            setOpenDialog(true)
                            setSelectedShareType("project");
                            selectedShareTypeRef.current = "project"
                            setUserShareList(dataItem)
                            if (dataItem.userId != userId) {
                              getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                            }
                          }}>
                            <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                            Shared With
                          </li>
                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setProjectClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            leftPaneMobile()
                            setTimeout(() => {
                              window.print();
                            }, 200);
                          }}>
                            <img
                              style={{
                                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={PrinterIcon}
                              alt=''
                            />
                            Print List
                          </li>
                          {/* {getExitStatus(dataItem) &&
                                <li
                                  style={{
                                    color: 'red',
                                    borderTopWidth: 0.5,
                                    borderTopColor: 'lightgray',
                                    borderTopStyle: 'solid'
                                  }}
                                  onClick={() => handleProjectExitConfirm(dataItem)}
                                >
                                  <img src={ExitShareIcon} alt='' />
                                  Exit
                                </li>
} */}
                        </ul>

                      </>
                    }
                  </div>
                </SwipeableDrawer>
              }


              {!mobileView ?
                <>
                  {listClicked && (
                    <div
                      className='rightListDiv'
                      style={{
                        top: points.y,
                        left: points.x
                      }}
                    >

                      <div
                        className='closeIconDiv'
                        onClick={() => setListClicked(false)}
                      >
                        <img src={CloseMenuListIcon} alt='' />
                      </div>
                      {dataItem.userId == userId ? <>
                        <ul>
                          <li
                            onClick={() => {
                              setEditList(dataItem)
                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            Rename List
                          </li>



                          <li
                            onClick={() => {
                              // setEditList(dataItem)
                              duplicateList(dataItem)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={DuplicateIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                            Duplicate List
                          </li>
                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setListClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            //handleClickOpenDialog()
                            setOpenDialog(true)
                            setSelectedShareType("project");
                            selectedShareTypeRef.current = "project"
                            setUserShareList(dataItem)
                            if (dataItem.userId != userId) {
                              getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                            }
                            // showTaskId(dataItem)
                          }}
                          >
                            <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            Share list
                          </li>
                          <li
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setListClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              showTaskId(dataItem)
                            }}
                          >
                            <img src={UniqueIdIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            {dataItem && dataItem.showTaskId == "0" ?
                              <span>Show task ID</span>
                              :
                              <span>Hide task ID</span>
                            }
                          </li>
                          <li
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setListClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem;
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              if (dataItem && dataItem.readOnlyStatus == "0") {
                                handleReadOnlyConfirm(dataItem)
                              }
                              else {
                                readOnlyFun(dataItem)
                              }
                              console.log('dataItem678', dataItem)
                            }}
                          >
                            <img src={ReadOnly} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                            {dataItem.readOnlyStatus == "0" ?
                              <span>Read only</span>
                              :
                              <span>Remove read only</span>
                            }
                          </li>
                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setListClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            leftPaneMobile()
                            setTimeout(() => {
                              window.print();
                            }, 200);
                          }}>
                            <img
                              style={{
                                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={PrinterIcon}
                              alt=''
                            />
                            Print List
                          </li>
                          {/* <li className='customCursor'>
                                  <img
                                    style={{
                                      width: 14,
                                      marginLeft: 3,
                                      marginRight: 2
                                    }}
                                    src={Remove}
                                    alt=''
                                  />
                                  Remove from project
                                </li> */}
                          {/* <li className='customCursor'>
                            <img
                              style={{
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={AddProjectIcon}
                              alt=''
                            />
                            Move List to
                          </li> */}
                          <RecursiveMenuItem autoFocus={false} label="Move list to" icon={AddGroupIcon} arrowIcon={RightBlackArrow}>
                            {/* <RecursiveMenu /> */}
                            <RecursiveMenuList />
                          </RecursiveMenuItem>
                          {/* <li
                          onClick={() => {
                            setColorPalette(true)
                            setHighlightFrom('list')
                          }}
                        >
                          <img src={MarkerIcon} alt='' />
                          Highlight list
                        </li> */}
                          <li
                            style={{
                              color: 'red',
                              borderTopWidth: 0.5,
                              borderTopColor: 'lightgray',
                              borderTopStyle: 'solid'
                            }}
                            onClick={() => handleProjectDeleteConfirm(dataItem)}
                          >
                            <img src={RedDelete} alt='' />
                            Move List to Trash
                          </li>
                        </ul>
                      </>
                        :
                        <>
                          <ul>

                            <li onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setListClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem;
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              //handleClickOpenDialog()
                              setOpenDialog(true)
                              setSelectedShareType("project");
                              selectedShareTypeRef.current = "project"
                              setUserShareList(dataItem)
                              if (dataItem.userId != userId) {
                                getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                              }
                              // showTaskId(dataItem)
                            }}
                            >
                              <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                              Shared With
                            </li>
                            <li onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setListClicked(false)
                              setSelectedProject(dataItem.id, dataItem.projectName)
                              setListItem(dataItem)
                              listItemRef.current = dataItem
                              localStorage.setItem('project', JSON.stringify(dataItem))
                              leftPaneMobile()
                              setTimeout(() => {
                                window.print();
                              }, 200);
                            }}>
                              <img
                                style={{
                                  filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                  width: 14,
                                  marginLeft: 3,
                                  marginRight: 2
                                }}
                                src={PrinterIcon}
                                alt=''
                              />
                              Print List
                            </li>
                            {/* {getExitStatus(dataItem) &&
                                  <li
                                    style={{
                                      color: 'red',
                                      borderTopWidth: 0.5,
                                      borderTopColor: 'lightgray',
                                      borderTopStyle: 'solid'
                                    }}
                                    onClick={() => handleProjectExitConfirm(dataItem)}
                                  >
                                    <img src={ExitShareIcon} alt='' />
                                    Exit
                                  </li>
} */}
                          </ul>
                        </>
                      }
                    </div>
                  )}
                </>
                :
                <SwipeableDrawer
                  anchor="bottom"
                  open={listClicked}
                  onClose={() => setListClicked(false)}
                  onOpen={() => setListClicked(true)}
                  className='mainDrawerContainer'
                /* style={{background:colorSchemes.DrawerBackColor}} */
                >
                  <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }} >
                    <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div ></div>
                    </div>
                    <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div style={{ width: '20%' }}></div>
                      <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                        List Options
                      </div>
                      <div className='drawerHeadingDone' onClick={handleClose}>Done</div>
                    </div>
                    {/* <div
                            className='closeIconDiv'
                            onClick={() => setListClicked(false)}
                          >
                            <img src={CloseMenuListIcon} alt='' />
                          </div> */}
                    {dataItem.userId == userId ? <>
                      <ul>
                        <li
                          onClick={() => {
                            showRenamePopUp(dataItem, "list")
                            console.log('projectname', dataItem)
                          }}
                        >
                          <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          Rename List
                        </li>



                        <li
                          onClick={() => {
                            // setEditList(dataItem)
                            duplicateList(dataItem)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            console.log('projectname', dataItem)
                          }}
                        >
                          <img src={DuplicateIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                          Duplicate List
                        </li>
                        <li onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          setListClicked(false)
                          setSelectedProject(dataItem.id, dataItem.projectName)
                          setListItem(dataItem)
                          listItemRef.current = dataItem
                          localStorage.setItem('project', JSON.stringify(dataItem))
                          //handleClickOpenDialog()
                          setOpenDialog(true)
                          setSelectedShareType("project");
                          selectedShareTypeRef.current = "project"
                          setUserShareList(dataItem)
                          // showTaskId(dataItem)
                          if (dataItem.userId != userId) {
                            getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                          }
                        }}
                        >
                          <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          Share list
                        </li>
                        <li
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setListClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            showTaskId(dataItem)
                          }}
                        >
                          <img src={UniqueIdIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          {dataItem && dataItem.showTaskId == "0" ?
                            <span>Show task ID</span>
                            :
                            <span>Hide task ID</span>
                          }
                        </li>
                        <li
                          onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setListClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            if (dataItem && dataItem.readOnlyStatus == "0") {
                              handleReadOnlyConfirm(dataItem)
                            }
                            else {
                              readOnlyFun(dataItem)
                            }
                          }}
                        >
                          <img src={ReadOnly} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                          {dataItem && dataItem.readOnlyStatus == "0" ?
                            <span>Read only</span>
                            :
                            <span>Remove read only</span>
                          }
                        </li>
                        <li onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          setListClicked(false)
                          setSelectedProject(dataItem.id, dataItem.projectName)
                          setListItem(dataItem)
                          listItemRef.current = dataItem
                          localStorage.setItem('project', JSON.stringify(dataItem))
                          leftPaneMobile()
                          setTimeout(() => {
                            window.print();
                          }, 200);
                        }}>
                          <img
                            style={{
                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                              width: 14,
                              marginLeft: 3,
                              marginRight: 2
                            }}
                            src={PrinterIcon}
                            alt=''
                          />
                          Print List
                        </li>
                        {/* <li className='customCursor'>
                                <img
                                  style={{
                                    width: 14,
                                    marginLeft: 3,
                                    marginRight: 2
                                  }}
                                  src={Remove}
                                  alt=''
                                />
                                Remove from project
                              </li> */}
                        <li onClick={() => { setMoveGroupListShow(true) }}>
                          <img
                            style={{
                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                              width: 14,
                              marginLeft: 3,
                              marginRight: 2
                            }}
                            src={AddProjectIcon}
                            alt=''
                          />
                          Move List to
                        </li>
                        {/* <li
                          onClick={() => {
                            setColorPalette(true)
                            setHighlightFrom('list')
                          }}
                        >
                          <img src={MarkerIcon} alt='' />
                          Highlight list
                        </li> */}
                        <li
                          style={{
                            color: 'red',
                            /* borderTopWidth: 0.5,
                            borderTopColor: 'lightgray',
                            borderTopStyle: 'solid' */
                          }}
                          onClick={() => handleProjectDeleteConfirm(dataItem)}
                        >
                          <img src={RedDelete} alt='' />
                          Move List to Trash
                        </li>
                      </ul>
                    </>
                      :
                      <>
                        <ul>

                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setListClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem;
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            //handleClickOpenDialog()
                            setOpenDialog(true)
                            setSelectedShareType("project");
                            selectedShareTypeRef.current = "project"
                            setUserShareList(dataItem)
                            // showTaskId(dataItem)
                            if (dataItem.userId != userId) {
                              getOtherProjectAllInvitations(dataItem.id, dataItem.orginalProjectGroupId);
                            }
                          }}
                          >
                            <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                            Shared With
                          </li>
                          <li onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setListClicked(false)
                            setSelectedProject(dataItem.id, dataItem.projectName)
                            setListItem(dataItem)
                            listItemRef.current = dataItem
                            localStorage.setItem('project', JSON.stringify(dataItem))
                            leftPaneMobile()
                            setTimeout(() => {
                              window.print();
                            }, 200);
                          }}>
                            <img
                              style={{
                                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={PrinterIcon}
                              alt=''
                            />
                            Print List
                          </li>
                          {/* {getExitStatus(dataItem) &&
                                <li
                                  style={{
                                    color: 'red',
                                    borderTopWidth: 0.5,
                                    borderTopColor: 'lightgray',
                                    borderTopStyle: 'solid'
                                  }}
                                  onClick={() => handleProjectExitConfirm(dataItem)}
                                >
                                  <img src={ExitShareIcon} alt='' />
                                  Exit
                                </li>
} */}
                        </ul>
                      </>
                    }
                  </div>
                </SwipeableDrawer>
              }


              {!mobileView ?
                <>
                  {clicked && (
                    <div
                      className='rightListDiv'
                      style={{
                        top: points.y,
                        left: points.x
                      }} /* top={points.y} left={points.x} */
                    >
                      <div
                        className='closeIconDiv'
                        onClick={() => setClicked(false)}
                      >
                        <img src={CloseMenuListIcon} alt='' />
                      </div>
                      <ul>
                        {dataItem.userId == userId ?
                          <>
                            <li
                              onClick={() => {
                                console.log('projectname1', dataItem)
                                //addListIntoProject(dataItem)
                                // openGroupOnNewListAdd(dataItem)
                                // addProjectNewList(dataItem)
                                setAddList(true); setAddNewListDialog(true);
                                selectGroupForNewList(dataItem)
                              }}
                            >
                              <img
                                style={{
                                  filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                  width: 14,
                                  marginLeft: 3,
                                  marginRight: 2
                                }}
                                src={AddProjectIcon}
                                alt=''
                              />
                              New list to folder
                            </li>
                            <li
                              onClick={() => {
                                setEditGroup(dataItem)
                                console.log('projectname', dataItem)
                              }}
                            >
                              <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                              Rename folder
                            </li>
                          </>
                          : null}


                        {dataItem.userId != userId ?
                          <>
                            <li
                              onClick={() => {
                                setGroupItem(dataItem)
                                setOpenDialog(true)
                                setSelectedShareType("group");
                                selectedShareTypeRef.current = "group"
                                setUserShareList(dataItem)
                                console.log('groupShare1', dataItem)
                                handleShareAllUser("group", dataItem.id)
                                if (dataItem.userId != userId) {
                                  getOtherGroupAllInvitations(dataItem.id)
                                }
                              }}
                            >
                              <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                              Shared With
                            </li>
                            {/* <li
                                  style={{
                                    color: 'red',
                                    borderTopWidth: 0.5,
                                    borderTopColor: 'lightgray',
                                    borderTopStyle: 'solid'
                                  }}
                                  onClick={() => handleGroupExitConfirm(dataItem)}
                                >
                                  <img src={ExitShareIcon} alt='' />
                                  Exit
                                </li> */}
                          </>
                          : null}

                        {dataItem.userId == userId ?
                          <>
                            <li
                              onClick={() => {
                                setGroupItem(dataItem)
                                setOpenDialog(true)
                                setSelectedShareType("group");
                                selectedShareTypeRef.current = "group"
                                setUserShareList(dataItem)
                                console.log('groupShare1', dataItem)
                                handleShareAllUser("group", dataItem.id)
                                if (dataItem.userId != userId) {
                                  getOtherGroupAllInvitations(dataItem.id)
                                }
                              }}
                            >
                              <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                              Share folder
                            </li>
                            {/* <li
                          onClick={() => {
                            console.log('projectname1', dataItem)
                            setColorPalette(true)
                            setHighlightFrom('project')
                          }}
                        >
                          <img
                            src={MarkerIcon}
                            alt=''
                          />
                          Highlight project
                        </li> */}
                            {dataItem.project && dataItem.project.length > 0 ? (
                              <li
                                style={{
                                  color: 'red',
                                  borderTopWidth: 0.5,
                                  borderTopColor: 'lightgray',
                                  borderTopStyle: 'solid'
                                }}
                                // onClick={() => unGroupProject(dataItem)}
                                onClick={() => deleteGroupUnderList(dataItem)}
                              >
                                {/* <img src={UnGroupListIcon} alt='' /> */}
                                {/* Unproject Lists */}
                                <img src={RedDelete} alt='' />
                                Move Folder to Trash
                              </li>
                            ) : (
                              <li
                                style={{
                                  color: 'red',
                                  borderTopWidth: 0.5,
                                  borderTopColor: 'lightgray',
                                  borderTopStyle: 'solid'
                                }}
                                onClick={() => deleteGroupUnderList(dataItem)/* deleteGroup(dataItem) */}
                              >
                                <img src={RedDelete} alt='' />
                                Move Folder to Trash
                              </li>
                            )}
                          </>
                          : null}
                      </ul>
                    </div>
                  )}
                </>
                :
                <SwipeableDrawer
                  anchor="bottom"
                  open={clicked}
                  onClose={() => setClicked(false)}
                  onOpen={() => setClicked(true)}
                  className='mainDrawerContainer'
                >
                  <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }} >
                    <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div></div>
                    </div>
                    <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div style={{ width: '20%' }}></div>
                      <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                        Folder Options
                      </div>
                      <div className='drawerHeadingDone' onClick={() => setClicked(false)}>Done</div>
                    </div>
                    {/* <div
                            className='closeIconDiv'
                            onClick={() => setClicked(false)}
                          >
                            <img src={CloseMenuListIcon} alt='' />
                          </div> */}
                    <ul>
                      {dataItem.userId == userId ?
                        <>
                          <li
                            onClick={() => {
                              console.log('projectname1', dataItem)
                              //addListIntoProject(dataItem)

                              // openGroupOnNewListAdd(dataItem)
                              // addProjectNewList(dataItem)

                              setAddList(true); setAddNewListDialog(true);
                              selectGroupForNewList(dataItem)
                            }}
                          >
                            <img
                              style={{
                                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={AddProjectIcon}
                              alt=''
                            />
                            New list to folder
                          </li>
                          <li
                            onClick={() => {
                              /* setEditGroup(dataItem) */
                              showRenamePopUp(dataItem, "Rename Project")
                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                            Rename folder
                          </li>
                        </>
                        : null}


                      {dataItem.userId != userId ?
                        <>
                          <li
                            onClick={() => {
                              setGroupItem(dataItem)
                              setOpenDialog(true)
                              setSelectedShareType("group");
                              selectedShareTypeRef.current = "group"
                              setUserShareList(dataItem)
                              console.log('groupShare1', dataItem)
                              handleShareAllUser("group", dataItem.id)
                              if (dataItem.userId != userId) {
                                getOtherGroupAllInvitations(dataItem.id)
                              }
                            }}
                          >
                            <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                            Shared With
                          </li>
                          {/* <li
                                style={{
                                  color: 'red',
                                  borderTopWidth: 0.5,
                                  borderTopColor: 'lightgray',
                                  borderTopStyle: 'solid'
                                }}
                                onClick={() => handleGroupExitConfirm(dataItem)}
                              >
                                <img src={ExitShareIcon} alt='' />
                                Exit
                              </li> */}
                        </>
                        : null}

                      {dataItem.userId == userId ?
                        <>
                          <li
                            onClick={() => {
                              setGroupItem(dataItem)
                              setOpenDialog(true)
                              setSelectedShareType("group");
                              selectedShareTypeRef.current = "group"
                              setUserShareList(dataItem)
                              console.log('groupShare1', dataItem)
                              handleShareAllUser("group", dataItem.id)
                              if (dataItem.userId != userId) {
                                getOtherGroupAllInvitations(dataItem.id)
                              }
                            }}
                          >
                            <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                            Share folder
                          </li>
                          {/* <li
                          onClick={() => {
                            console.log('projectname1', dataItem)
                            setColorPalette(true)
                            setHighlightFrom('project')
                          }}
                        >
                          <img
                            src={MarkerIcon}
                            alt=''
                          />
                          Highlight project
                        </li> */}
                          {dataItem.project && dataItem.project.length > 0 ? (
                            <li
                              style={{
                                color: 'red',
                                /* borderTopWidth: 0.5,
                                borderTopColor: 'lightgray',
                                borderTopStyle: 'solid' */
                              }}
                              // onClick={() => unGroupProject(dataItem)}
                              onClick={() => deleteGroupUnderList(dataItem)}
                            >
                              {/* <img src={UnGroupListIcon} alt='' /> */}
                              {/* Unproject Lists */}
                              <img src={RedDelete} alt='' />
                              Move Folder to Trash
                            </li>
                          ) : (
                            <li
                              style={{
                                color: 'red',
                                /* borderTopWidth: 0.5,
                                borderTopColor: 'lightgray',
                                borderTopStyle: 'solid' */
                              }}
                              onClick={() => deleteGroupUnderList(dataItem)/* deleteGroup(dataItem) */}
                            >
                              <img src={RedDelete} alt='' />
                              Move Folder to Trash
                            </li>
                          )}
                        </>
                        : null}
                    </ul>
                  </div>
                </SwipeableDrawer>
              }
              {/* 03/05 */}
            </>
          }


          {addList &&
            <AddListDialog
              addNewListDialog={addNewListDialog}
              setAddNewListDialog={setAddNewListDialog}
              groupData={groupData}
              setGroupData={setGroupData}
              groupDataRef={groupDataRef}
              groupInputValue={groupInputValue}
              setGroupInputValue={setGroupInputValue}
              handleChangeForGroup={handleChangeForGroup}
              handleKeyDownForGroup={handleKeyDownForGroup}
              addGroups={addGroups}
              selectedFolderItem={selectedFolderItem}
              setSelectedFolderItem={setSelectedFolderItem}
              projectsInputValue={projectsInputValue}
              handleChangeForProject={handleChangeForProject}
              handleKeyDownForProject={handleKeyDownForProject}
              addProjectToList={addProjectToList}
              setProjectsInputValue={setProjectsInputValue}
              userId={userId}
              getProjectWithGroup={getProjectWithGroup}
              projectData={projectData}
              userData={userData}
              isDark={isDark}
              colorSchemes={colorSchemes}
              mobileView={mobileView}
              sendSocketMessage={sendSocketMessage}
              isLightMode={isLightMode}
            />
          }

          {aiDialogText &&
            <AIDialog
            aiDialog={aiDialogText}
              setAIDialog ={setAIDialogText}              
              userId={userId}
              getProjectWithGroup={getProjectWithGroup}
              projectData={projectData}
              userData={userData}
              isDark={isDark}
              colorSchemes={colorSchemes}
              mobileView={mobileView}
              sendSocketMessage={sendSocketMessage}
              isLightMode={isLightMode}
            />
          }


          {aiDialog &&
            <AIDialogNew
              aiDialog={aiDialog}
              setAIDialog={setAIDialog}
              userId={userId}
              getProjectWithGroup={getProjectWithGroup}
              projectData={projectData}
              userData={userData}
              isDark={isDark}
              colorSchemes={colorSchemes}
              mobileView={mobileView}
              sendSocketMessage={sendSocketMessage}
              isLightMode={isLightMode}
              setTaskInputValue={setTaskInputValue}
              groupData={groupData}
              taskData={taskData}
              postTask={postTask}
              moveTaskToList={moveTaskToList}
              addListIntoProjectForAI={addListIntoProjectForAI}
              updateTaskOrderForAI={updateTaskOrderForAI}
              updategroupprojectOrdersForAI={updategroupprojectOrdersForAI}
              updategroupOrdersForAI={updategroupOrdersForAI}
              addGroupsForAI={addGroupsForAI}

            />
          }



          {/* {mobileView && */}
          <RenameListMobile
            renameListForMobile={renameListForMobile}
            setRenameListForMobile={setRenameListForMobile}
            renameListInputValue={renameListInputValue}
            setRenameListInputValue={setRenameListInputValue}
            dataItem={dataItem}
            renameListForMobileFun={renameListForMobileFun}
            renameListType={renameListType}
            addNewProjectForMobileFun={addNewProjectForMobileFun}
            renameGroupForMobileFun={renameGroupForMobileFun}
            isDark={isDark}
            colorSchemes={colorSchemes}
            mobileView={mobileView}
            isLightMode={isLightMode}
          />
          {/* } */}

          {/* {!hideShowMiddlePane && */}
          {!helpSupportPage ?
            <>
              <div
                id='print'
                style={{
                  // padding: 20,
                  // width:
                  //   menuOpen && taskSectionOpen
                  //     ? '60%'
                  //     : menuOpen || taskSectionOpen
                  //     ? '80%'
                  //     : '100%'
                  width: !hideShowMiddlePane ? '100%' : '0%',
                  // background: colorSchemes.MiddlePaneBackground,
                  // background: colorSchemes.WebMiddlePaneBg,
                  background: colorSchemes.MiddlePaneBackground,
                  // marginTop: '-6.5vh',
                  zIndex: 1,
                  height: !hideShowMiddlePane ? '100vh' : '0vh',
                  // borderTopLeftRadius: mobileView ? 0 : 12
                }}
                className={slideType.current == "middle" ? 'slide-right' : slideType.current == "right" ? 'moveToLeft' : slideType.current == "middleback" ? 'slide-left' : 'moveToRight'}

              >
                {selectedProjectID != '' /* && selectedProjectName != '' */ && (
                  <>

                    <div>
                      <div>
                        {searchBarInputValue != '' ? null :
                          <div

                            style={{
                              position: 'absolute',
                              // background: colorSchemes.MiddlePaneBlurBackground,
                              // background: colorSchemes.WebMiddlePaneBlurBg,
                              background: colorSchemes.MiddlePaneBackground,

                              // width:'100%',
                              width: mobileView ? "100%" : taskSectionOpen
                                ? 'calc(100% - 280px - 370px)'
                                : 'calc(100% - 280px)',
                              top: 0,
                              backdropFilter: 'blur(3.5px)',
                              zIndex: 1,
                              borderTopLeftRadius: mobileView ? 0 : 12,
                            }}
                          >
                            {mobileView &&
                              <div className='middlePaneTopDiv' style={{ background: colorSchemes.WebLeftPaneBg }}>
                                {/* <div style={{width:40,display:'flex',justifyContent:'center',alignItems:'center'}} onClick={() => { middlePaneMobileBack() }}>
                            <img style={{width:24}} src={MenuMiddlePane} />
                            </div> */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',gap:25 }} onClick={() => { middlePaneMobileBack() }}>
                                  <img style={{ width: 24 }} src={Rightpaneback} />
                                  <div className='aiButton' style={{ justifyContent: 'center', background:isLightMode?"rgb(23, 45, 70)":"#3068C8" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAIDialog(true) }}>
                                    Ai
                                  </div>
                                </div>
                                <div style={{ fontSize: 20, fontWeight: 700, fontFamily: 'ManRopeBold', color: '#fff', textAlign: 'center' }}>
                                  <div>List Detail</div>
                                  <div  style={{ fontSize: 16, fontWeight: 700, fontFamily: 'ManRopeBold', color: '#fff', textAlign: 'center',display:'flex'}}>
                                    (<span className="oneLine-ellipsis" style={{wordBreak: 'break-all', marginLeft: 0}}>
                                      {decData(
                                        selectedProjectName,
                                        listItem &&
                                          listItem.isEncrypt &&
                                          listItem.isEncrypt == '1'
                                          ? '1'
                                          : '0'
                                      ).substring(
                                        0,
                                        64
                                      )
                                    }</span>)
                                  </div>
                                </div>
                                <div style={{display:'flex',justifyContent:selectedProjectName != 'Trash' ?'space-between':'flex-end',alignItems:'center',gap:10 }}>
                                  {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && listItem.userId == userId &&

                                    <>
                                      {/* {!mobileView ? */}
                                      <Tooltip title="Click to Share" >
                                        <div
                                          className='shareDiv'
                                          style={{ backgroundColor: colorSchemes.WebLeftPaneBg }}
                                          onClick={() => handleClickOpenDialog()}
                                        >
                                          <img style={{ width: mobileView ? 17 : 20 }} src={SharePeople1} />
                                          {getShareCount("project") > 0 ?
                                            <div className="shareCountDiv">
                                              {getShareCount("project")}
                                            </div>
                                            :
                                            null
                                          }
                                        </div>
                                      </Tooltip>
                                      {/* :
  null
} */}
                                    </>

                                  }
                                  {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && listItem.userId != userId &&

                                    <>
                                      {/* {!mobileView ? */}
                                      <Tooltip title="Click to Share" >
                                        <div
                                          className='shareDiv'
                                          // style={{ padding: "4px 5px 4px 2px" }}
                                          onClick={() => handleClickOpenDialog()}
                                        >
                                          <img style={{ width: mobileView ? 17 : 20 }} src={SharePeople1} />
                                          {getOtherShareCount("project") > 0 ?
                                            <div className="shareCountDiv">
                                              {getOtherShareCount("project")}
                                            </div>
                                            :
                                            // <img className="shareCountDiv" style={{ background: '#cdd4ed', border: 'none' }} src={ShareAddIcon} alt="" />
                                            null
                                          }
                                        </div>
                                      </Tooltip>
                                      {/* :
  null
} */}
                                    </>

                                  }
                                  <Button
                                    ref={anchorMenuListRef}
                                    id='composition-button'
                                    aria-controls={
                                      menuListOpen ? 'composition-menu' : undefined
                                    }
                                    aria-expanded={menuListOpen ? 'true' : undefined}
                                    aria-haspopup='true'
                                    onClick={() => { handleMenuListToggle(); setDataItem(listItem) }}
                                    className='settingButton whiteDots'
                                  >
                                    <img style={{ width: isLightMode ? 20 : 20 }} src={isLightMode ? /* GreyThreedots */WhiteDots : WhiteDots} />
                                  </Button>
                                </div>
                              </div>
                            }
                            <div className='MainUpperDiv' style={mobileView?{padding:10}:{}}>
                              {/* {mobileView &&
                              <div onClick={() => { middlePaneMobileBack() }} style={{ color: '#798dda', fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: 12, marginBottom: 3 }} src={BlueBackArrow} alt="" />&nbsp;<span style={{ minWidth: 35 }} >Lists</span>
                              </div>
                            } */}
                            {!mobileView &&
                              <div className='titleHeadMenu '>
                                <div>
                                  <div style={{
                                    // display: mobileView ? taskAddScrollValue>20?'flex':'none' : 'inherit',
                                    display: mobileView ? taskAddScrollValue > 20 ? 'flex' : 'flex' : 'inherit',
                                    fontWeight: 700,
                                    // color: !mobileView ? '#fff' : '#798dda',
                                    color: colorSchemes.FontColor,
                                    // marginLeft: 5,
                                    // paddingLeft: 5,
                                    paddingRight: mobileView ? 0 : 5,
                                    fontSize: mobileView ? "20px" : '24px',
                                    fontFamily: 'ManRopeBold',
                                    wordBreak: mobileView ? 'break-all' : 'initial',
                                  }}
                                    // className={taskAddScrollValue>20?'oneLine-ellipsis-list-name':''}
                                    className='oneLine-ellipsis-list-name'

                                    onClick={() => setEditSelectedList(true)}
                                  >
                                    {listItem.userId == userId && editSelectedList ? (
                                      <input
                                        ref={listInputRef}
                                        autoFocus={true}
                                        style={{
                                          width: decData(listItem.projectName, "1").length + 1 + "ch",
                                          background: 'transparent',
                                          border: "none",
                                          color: colorSchemes.FontColor /* '#172D46' */, fontSize: '1.4rem', fontWeight: 600, paddingLeft: 5,
                                        }}
                                        maxLength={64}
                                        onChange={e => {
                                          let editListData = {
                                            ...listItem
                                          }
                                          editListData.projectName =
                                            encData(
                                              e.target.value
                                            )
                                          editListData.isEncrypt =
                                            '1'

                                          setListItem(
                                            editListData
                                          )
                                          listItemRef.current = editListData
                                        }}
                                        value={decData(
                                          listItem.projectName,
                                          listItem.isEncrypt
                                        )}
                                        onBlur={e => {
                                          console.log(
                                            'asdfghj',
                                            e
                                          )

                                          // if(e.target.value.trim() == ""){
                                          //   setSelectedProjectName(listItem.projectName)
                                          // }
                                          // else{
                                          handleChangeProjectName(e)
                                          setEditSelectedList(false)
                                          // }

                                        }}
                                        onKeyDown={(
                                          event: any
                                        ) => {
                                          if (
                                            event.key === 'Enter'
                                          ) {
                                            handleChangeProjectName(event)
                                            setEditSelectedList(false)
                                            console.log('test4567')
                                          }
                                        }}
                                      />
                                    ) : (
                                      decData(
                                        selectedProjectName,
                                        listItem &&
                                          listItem.isEncrypt &&
                                          listItem.isEncrypt == '1'
                                          ? '1'
                                          : '0'
                                      ).substring(
                                        0,
                                        64
                                      )
                                    )}
                                  </div>

                                </div>
                                {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && listItem.userId == userId &&

                                  <>
                                    {/* {!mobileView ? */}
                                    <Tooltip title="Click to Share" >
                                      <div
                                        className='shareDiv'
                                        style={{ backgroundColor: colorSchemes.WebLeftPaneBg }}
                                        onClick={() => handleClickOpenDialog()}
                                      >
                                        <img style={{ width: mobileView ? 17 : 20 }} src={SharePeople1} />
                                        {getShareCount("project") > 0 ?
                                          <div className="shareCountDiv">
                                            {getShareCount("project")}
                                          </div>
                                          :
                                          null
                                        }
                                      </div>
                                    </Tooltip>
                                    {/* :
                                    null
                                  } */}
                                  </>

                                }
                                {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && listItem.userId != userId &&

                                  <>
                                    {/* {!mobileView ? */}
                                    <Tooltip title="Click to Share" >
                                      <div
                                        className='shareDiv'
                                        // style={{ padding: "4px 5px 4px 2px" }}
                                        onClick={() => handleClickOpenDialog()}
                                      >
                                        <img style={{ width: mobileView ? 17 : 20 }} src={SharePeople1} />
                                        {getOtherShareCount("project") > 0 ?
                                          <div className="shareCountDiv">
                                            {getOtherShareCount("project")}
                                          </div>
                                          :
                                          // <img className="shareCountDiv" style={{ background: '#cdd4ed', border: 'none' }} src={ShareAddIcon} alt="" />
                                          null
                                        }
                                      </div>
                                    </Tooltip>
                                    {/* :
                                    null
                                  } */}
                                  </>

                                }

                              </div>

}
                              <div className='MainUpperRightDiv' style={{ paddingTop: mobileView ? 0 : 0 }}>

                                {!mobileView &&
                                  <div /* className='aiButton' */ style={{    padding: "4px 11px",
                                    minWidth: "auto",
                                    display: "flex",
                                    borderRadius: "8px",
                                    backgroundColor: "rgb(48, 104, 200)",
                                    height: "40px",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "900"}} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAIDialogText(true) }}>
                                    Ai Demo
                                  </div>
                                }
                                {!mobileView &&
                                  <div className='aiButton' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAIDialog(true) }}>
                                    Ai
                                  </div>
                                }
                                {!mobileView && !hideShowMiddlePane &&
                                  <div /* style={{position: 'absolute',right: '100px',marginTop:6, zIndex: 2}} */>
                                    <div className='settingButton' style={{ display: 'flex' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); refreshFun('middle') }}>
                                      <img style={{ width: isLightMode ? 28 : 18, cursor: 'pointer' }} src={isLightMode ? RefreshGreyIcon : RefreshIcon} alt="" />
                                    </div>
                                  </div>
                                }

                                {/*  {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && listItem.userId == userId &&
                                <>
                                  {mobileView ?
                                    <div
                                      style={{ padding: "4px", display: 'unset' }}
                                      onClick={() => handleClickOpenDialog()}
                                    >
                                      {getShareCount("project") > 0 ?
                                        <>
                                          <img style={{ width: 20 }} src={ShareAddPersonWhiteIcon} alt="" />
                                          <div className="shareCountDiv">
                                            {getShareCount("project")}
                                          </div>
                                        </>
                                        :
                                        <img style={{ width: 20 }} src={ShareAddPersonWhiteIcon} alt="" />
                                      }
                                    </div>
                                    : null
                                  }
                                </>
                              }
                              {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && listItem.userId != userId &&
                                <>
                                  {mobileView ?
                                    <div
                                      style={{ padding: "4px", display: 'unset' }}
                                      onClick={() => handleClickOpenDialog()}
                                    >
                                      {getOtherShareCount("project") > 0 ?
                                        <>
                                          <img style={{ width: 20 }} src={ShareAddPersonWhiteIcon} alt="" />
                                          <div className="shareCountDiv">
                                            {getOtherShareCount("project")}
                                          </div>
                                        </>
                                        :
                                        <img style={{ width: 20 }} src={ShareAddPersonWhiteIcon} alt="" />
                                      }
                                    </div>
                                    : null
                                  }
                                </>
                              } */}
                                <div>
                                  {!mobileView &&
                                    <Button
                                      ref={anchorMenuListRef}
                                      id='composition-button'
                                      aria-controls={
                                        menuListOpen ? 'composition-menu' : undefined
                                      }
                                      aria-expanded={menuListOpen ? 'true' : undefined}
                                      aria-haspopup='true'
                                      onClick={() => { handleMenuListToggle(); setDataItem(listItem) }}
                                      className='settingButton whiteDots'
                                    >
                                      <img style={{ width: isLightMode ? 28 : 20 }} src={isLightMode ? GreyThreedots : WhiteDots} />
                                    </Button>
                                  }
                                  {!mobileView ?
                                    <Popper
                                      id='listPopper'
                                      style={{ zIndex: 999 }}
                                      open={menuListOpen}
                                      anchorEl={anchorMenuListRef.current}
                                      role={undefined}
                                      placement='bottom'
                                      transition
                                      disablePortal
                                      className='popperWidth'
                                    >
                                      {({ TransitionProps, placement }) => (
                                        <Grow
                                          {...TransitionProps}
                                          style={{
                                            transformOrigin:
                                              placement === 'bottom'
                                                ? 'left top'
                                                : 'left bottom'
                                          }}
                                        >
                                          <Paper>
                                            <ClickAwayListener
                                              onClickAway={handleMenuListClose}
                                            >
                                              {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' ?
                                                <MenuList
                                                  autoFocusItem={menuListOpen}
                                                  id='composition-menu'
                                                  aria-labelledby='composition-button'
                                                // onKeyDown={handleListKeyDown}
                                                >
                                                  <div
                                                    className='closeIconDiv'
                                                    onClick={handleMenuListClose}
                                                  >
                                                    <img src={CloseMenuListIcon} alt='' />
                                                  </div>
                                                  {listItem.userId == userId ?
                                                    <>
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          handleMenuListClose(e);
                                                          setTimeout(() => {
                                                            setEditSelectedList(true)
                                                          }, 100);
                                                        }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        <div className='settingModalItems'>
                                                          <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                          <span>Rename list</span>
                                                        </div>
                                                      </MenuItem>
                                                      {/* <MenuItem
                                                      className='customCursor'
                                                      onClick={handleMenuListClose}
                                                      style={{ padding: 0 }}
                                                    >
                                                      {' '}
                                                      <div className='settingModalItems customCursor'>
                                                        <img src={AddGroupIcon} alt='' />
                                                        <span>Move list to...</span>
                                                      </div>
                                                    </MenuItem> */}
                                                      {/* {dataItem.userid != userId ? null : */}
                                                      <RecursiveMenuItem autoFocus={false} label="Move list to..." icon={AddGroupIcon} arrowIcon={RightBlackArrow}>
                                                        {/* <RecursiveMenu /> */}
                                                        <RecursiveMenuList />
                                                      </RecursiveMenuItem>
                                                      {/* } */}
                                                      {/* <MenuItem
                                            className='customCursor'
                                            onClick={handleMenuListClose}
                                            style={{ padding: 0 }}
                                          >
                                            {' '}
                                            <div className='settingModalItems customCursor'>
                                              <img src={Remove} alt='' />
                                              <span>Remove from project</span>
                                            </div>
                                          </MenuItem> */}
                                                      <MenuItem
                                                        className='customCursor'
                                                        onClick={handleMenuListClose}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems customCursor'>
                                                          <img src={Sort} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                          <span>Sort by</span>
                                                        </div>
                                                      </MenuItem>

                                                      <MenuItem
                                                        onClick={(e) => {
                                                          handleMenuListClose(e);
                                                          setSelectedProject(listItem.id, listItem.projectName)
                                                          let tempItem = { ...listItem };
                                                          console.log("listItem45", listItem)
                                                          if (tempItem.showTaskId == '0') {
                                                            tempItem.showTaskId = '1'
                                                          } else {
                                                            tempItem.showTaskId = '0'
                                                          }
                                                          setListItem(listItem);
                                                          listItemRef.current = listItem
                                                          setDataItem(tempItem);
                                                          showTaskId(listItem)
                                                        }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems'>
                                                          <img src={UniqueIdIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                          {listItem && listItem.showTaskId == 0 ?
                                                            <span>Show task ID</span>
                                                            :
                                                            <span>Hide task ID</span>
                                                          }
                                                        </div>
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          handleMenuListClose(e);
                                                          setSelectedProject(listItem.id, listItem.projectName)
                                                          let tempItem = { ...listItem };
                                                          console.log("listItem45", listItem)
                                                          if (tempItem.readOnlyStatus == '0') {
                                                            tempItem.readOnlyStatus = '1'
                                                          } else {
                                                            tempItem.readOnlyStatus = '0'
                                                          }
                                                          setListItem(listItem);
                                                          listItemRef.current = listItem
                                                          setDataItem(tempItem);
                                                          if (listItem && listItem.readOnlyStatus == "0") {
                                                            handleReadOnlyConfirm(listItem)
                                                          }
                                                          else {
                                                            readOnlyFun(listItem)
                                                          }
                                                        }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems'>
                                                          <img src={ReadOnly} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                          {listItem && listItem.readOnlyStatus == 0 ?
                                                            <span>Read only</span>
                                                            :
                                                            <span>Remove read only</span>
                                                          }
                                                        </div>
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={(e) => { handleMenuListClose(e); duplicateList(listItem) }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems'>
                                                          <img src={DuplicateIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                          <span>Duplicate list</span>
                                                        </div>
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={(e) => { handleMenuListClose(e); handleClickOpenDialog() }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems'>
                                                          <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                          <span>Share List</span>
                                                        </div>
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          handleMenuListClose(e);
                                                          window.print()
                                                        }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems'>
                                                          <img
                                                            style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)', width: 20 }}
                                                            src={PrinterIcon}
                                                            alt=''
                                                          />
                                                          <span>Print</span>
                                                        </div>
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={handleMenuListClose}
                                                        style={{ padding: 0 }}
                                                      >
                                                        <div
                                                          style={{
                                                            color: 'red',
                                                            borderTopWidth: 0.5,
                                                            borderTopColor: 'lightgray',
                                                            borderTopStyle: 'solid'
                                                          }}
                                                          onClick={() =>
                                                            handleProjectDeleteConfirm(listItem)
                                                          }
                                                          className='settingModalItems'
                                                        >
                                                          <img src={RedDelete} alt='' />
                                                          <span>Move List to Trash</span>
                                                        </div>
                                                      </MenuItem>
                                                    </>
                                                    :
                                                    <>

                                                      <MenuItem
                                                        onClick={(e) => { handleMenuListClose(e); handleClickOpenDialog() }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems'>
                                                          <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                          <span>Shared With</span>
                                                        </div>
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={(e) => {
                                                          handleMenuListClose(e);
                                                          window.print()
                                                        }}
                                                        style={{ padding: 0 }}
                                                      >
                                                        {' '}
                                                        <div className='settingModalItems'>
                                                          <img
                                                            style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)', width: 20 }}
                                                            src={PrinterIcon}
                                                            alt=''
                                                          />
                                                          <span>Print</span>
                                                        </div>
                                                      </MenuItem>
                                                      {/* {getExitStatus(listItem) &&
                                            <MenuItem
                                              onClick={(e) => {
                                                handleMenuListClose(e);
                                                handleProjectExitConfirm(listItem)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <div
                                                style={{
                                                  color: 'red',
                                                  borderTopWidth: 0.5,
                                                  borderTopColor: 'lightgray',
                                                  borderTopStyle: 'solid'
                                                }}
                                                className='settingModalItems'
                                              >
                                                <img src={ExitShareIcon} alt='' />
                                                <span>Exit list</span>
                                              </div>
                                            </MenuItem>
} */}
                                                    </>
                                                  }
                                                </MenuList>
                                                :
                                                <MenuList
                                                  autoFocusItem={menuListOpen}
                                                  id='composition-menu'
                                                  aria-labelledby='composition-button'
                                                // onKeyDown={handleListKeyDown}
                                                >
                                                  <div
                                                    className='closeIconDiv'
                                                    onClick={handleMenuListClose}
                                                  >
                                                    <img src={CloseMenuListIcon} alt='' />
                                                  </div>
                                                  <MenuItem
                                                    onClick={handleMenuListClose}
                                                    style={{ padding: 0 }}
                                                  >
                                                    <div
                                                      style={{
                                                        color: 'red',
                                                      }}
                                                      onClick={() => {
                                                        handleEmptyTrashConfirm()
                                                        setSelectedProject(Base64.encode("1"), 'Trash')
                                                      }
                                                      }
                                                      className='settingModalItems'
                                                    >
                                                      <img src={RedDelete} alt='' />
                                                      <span>Clear trash</span>
                                                    </div>
                                                  </MenuItem>
                                                </MenuList>
                                              }
                                            </ClickAwayListener>
                                          </Paper>
                                        </Grow>
                                      )}
                                    </Popper>
                                    :
                                    <SwipeableDrawer
                                      anchor="bottom"
                                      open={menuListOpen}
                                      onClose={() => setMenuListOpen(false)}
                                      onOpen={() => setMenuListOpen(true)}
                                      className='mainDrawerContainer'

                                    >
                                      <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                                        <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                                          <div ></div>
                                        </div>
                                        <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                                          <div style={{ width: '20%' }}></div>
                                          <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                            List Options
                                          </div>
                                          <div className='drawerHeadingDone' onClick={handleMenuListClose}>Done</div>
                                        </div>
                                        {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' ?
                                          <MenuList
                                            autoFocusItem={menuListOpen}
                                            id='composition-menu'
                                            aria-labelledby='composition-button'
                                          // onKeyDown={handleListKeyDown}
                                          >
                                            {/* <div
                                        className='closeIconDiv'
                                        onClick={handleMenuListClose}
                                      >
                                        <img src={CloseMenuListIcon} alt='' />
                                      </div> */}
                                      <MenuItem /* className="customCursor" */
                                              onClick={(e) => { handleMenuListClose(e); refreshFun('middle') }}
                                              style={{ padding: 0 }}
                                            >
                                              <div className='settingModalItems '>
                                                <img src={RefreshGreyIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                <span>Refresh</span>
                                              </div>
                                            </MenuItem>
                                            {listItem.userId == userId ?
                                              <>                                  
                                                <MenuItem
                                                  onClick={(e) => {
                                                    handleMenuListClose(e);
                                                    showRenamePopUp(dataItem, "list")
                                                    //   setTimeout(() => {
                                                    //   setEditSelectedList(true)
                                                    //   setTimeout(() => {
                                                    //   listInputRef?.current?.focus();
                                                    // }, 200);
                                                    //   listInputRef?.current?.click();
                                                    //   }, 100);
                                                  }}
                                                  style={{ padding: 0 }}
                                                >
                                                  <div className='settingModalItems'>
                                                    <img src={Rename} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                    <span>Rename list</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  className='customCursor'
                                                  onClick={(e) => { handleMenuListClose(e); setMoveGroupListShow(true) }}
                                                  style={{ padding: 0 }}
                                                >

                                                  <div className='settingModalItems customCursor'>
                                                    <img src={AddGroupIcon} alt='' />
                                                    <span>Move list to...</span>
                                                  </div>
                                                </MenuItem>
                                                {/* <MenuItem
                                    className='customCursor'
                                    onClick={handleMenuListClose}
                                    style={{ padding: 0 }}
                                  >
                                    {' '}
                                    <div className='settingModalItems customCursor'>
                                      <img src={Remove} alt='' />
                                      <span>Remove from project</span>
                                    </div>
                                  </MenuItem> */}
                                                <MenuItem
                                                  className='customCursor'
                                                  onClick={handleMenuListClose}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems customCursor'>
                                                    <img src={Sort} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                    <span>Sort by</span>
                                                  </div>
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={(e) => {
                                                    handleMenuListClose(e);
                                                    setSelectedProject(listItem.id, listItem.projectName)
                                                    let tempItem = { ...listItem };
                                                    console.log("listItem45", listItem)
                                                    if (tempItem.showTaskId == '0') {
                                                      tempItem.showTaskId = '1'
                                                    } else {
                                                      tempItem.showTaskId = '0'
                                                    }
                                                    setListItem(listItem);
                                                    listItemRef.current = listItem
                                                    setDataItem(tempItem);
                                                    showTaskId(listItem)
                                                  }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img src={UniqueIdIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                    {listItem && listItem.showTaskId == 0 ?
                                                      <span>Show task ID</span>
                                                      :
                                                      <span>Hide task ID</span>
                                                    }
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => {
                                                    handleMenuListClose(e);
                                                    setSelectedProject(listItem.id, listItem.projectName)
                                                    let tempItem = { ...listItem };
                                                    console.log("listItem45", listItem)
                                                    if (tempItem.readOnlyStatus == '0') {
                                                      tempItem.readOnlyStatus = '1'
                                                    } else {
                                                      tempItem.readOnlyStatus = '0'
                                                    }
                                                    setListItem(listItem);
                                                    listItemRef.current = listItem
                                                    setDataItem(tempItem);
                                                    if (listItem && listItem.readOnlyStatus == "0") {
                                                      handleReadOnlyConfirm(listItem)
                                                    }
                                                    else {
                                                      readOnlyFun(listItem)
                                                    }
                                                  }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img src={ReadOnly} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                    {listItem && listItem.readOnlyStatus == '0' ?
                                                      <span>Read only</span>
                                                      :
                                                      <span>Remove read only</span>
                                                    }
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => { handleMenuListClose(e); duplicateList(listItem) }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img src={DuplicateIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                    <span>Duplicate list</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => { handleMenuListClose(e); handleClickOpenDialog() }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)' }} />
                                                    <span>Share List</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => {
                                                    handleMenuListClose(e);
                                                    window.print()
                                                  }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img
                                                      style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)', width: 20 }}
                                                      src={PrinterIcon}
                                                      alt=''
                                                    />
                                                    <span>Print</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={handleMenuListClose}
                                                  style={{ padding: 0, paddingBottom: 40 }}
                                                >
                                                  <div
                                                    style={{
                                                      color: 'red',
                                                      /* borderTopWidth: 0.5,
                                                      borderTopColor: 'lightgray',
                                                      borderTopStyle: 'solid' */
                                                    }}
                                                    onClick={() =>
                                                      handleProjectDeleteConfirm(listItem)
                                                    }
                                                    className='settingModalItems'
                                                  >
                                                    <img src={RedDelete} alt='' />
                                                    <span>Move List to Trash</span>
                                                  </div>
                                                </MenuItem>
                                              </>
                                              :
                                              <>

                                                <MenuItem
                                                  onClick={(e) => { handleMenuListClose(e); handleClickOpenDialog() }}
                                                  style={{ padding: 0 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img src={ShareIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                                    <span>Shared With</span>
                                                  </div>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={(e) => {
                                                    handleMenuListClose(e);
                                                    window.print()
                                                  }}
                                                  style={{ padding: 0, paddingBottom: 40 }}
                                                >
                                                  {' '}
                                                  <div className='settingModalItems'>
                                                    <img
                                                      style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)', width: 20 }}
                                                      src={PrinterIcon}
                                                      alt=''
                                                    />
                                                    <span>Print</span>
                                                  </div>
                                                </MenuItem>
                                                {/* {getExitStatus(listItem) &&
                                      <MenuItem
                                        onClick={(e) => {
                                          handleMenuListClose(e);
                                          handleProjectExitConfirm(listItem)
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <div
                                          style={{
                                            color: 'red',
                                            borderTopWidth: 0.5,
                                            borderTopColor: 'lightgray',
                                            borderTopStyle: 'solid'
                                          }}
                                          className='settingModalItems'
                                        >
                                          <img src={ExitShareIcon} alt='' />
                                          <span>Exit list</span>
                                        </div>
                                      </MenuItem>
} */}
                                              </>
                                            }
                                          </MenuList>
                                          :
                                          <MenuList
                                            autoFocusItem={menuListOpen}
                                            id='composition-menu'
                                            aria-labelledby='composition-button'
                                          // onKeyDown={handleListKeyDown}
                                          >
                                            {/* <div
                                        className='closeIconDiv'
                                        onClick={handleMenuListClose}
                                      >
                                        <img src={CloseMenuListIcon} alt='' />
                                      </div> */}
                                            <MenuItem
                                              onClick={handleMenuListClose}
                                              style={{ padding: 0, paddingBottom: 40 }}
                                            >
                                              <div
                                                style={{
                                                  color: 'red',
                                                }}
                                                onClick={() => {
                                                  handleEmptyTrashConfirm()
                                                  setSelectedProject(Base64.encode("1"), 'Trash')
                                                }
                                                }
                                                className='settingModalItems'
                                              >
                                                <img src={RedDelete} alt='' />
                                                <span>Clear trash</span>
                                              </div>
                                            </MenuItem>
                                          </MenuList>
                                        }
                                      </div>
                                    </SwipeableDrawer>
                                  }
                                </div>
                              </div>
                            </div>
                            {mobileView &&
                              <>
                                {/* <div style={{
                      display:taskAddScrollValue<=20?'flex':'none',
                          fontWeight: 800,
                          color: !mobileView ? '#fff' : '#798dda', 
                          marginLeft: 8,
                          paddingLeft: 5,
                          paddingRight: 5,
                          paddingBottom:8,
                          fontSize: mobileView ? "32px" : '1.4rem',
                          fontFamily:'Helvetica',
                          marginTop:-10
                        }}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onTouchStart={handleMouseDown}
                        onTouchEnd={handleMouseUp}
                        >
                          {listItem.userId == userId && editSelectedList ? (
                            <input
                              ref={listInputRef}
                              autoFocus={true}
                              style={{
                                width: decData(listItem.projectName, "1").length + 1 + "ch",
                                background: 'transparent',
                                border: "none",
                                color: 'white', fontSize: '1.4rem', fontWeight: 600, paddingLeft: 5,
                              }}
                              maxLength={64}
                              onChange={e => {
                                let editListData = {
                                  ...listItem
                                }
                                editListData.projectName =
                                  encData(
                                    e.target.value
                                  )
                                editListData.isEncrypt =
                                  '1'

                                setListItem(
                                  editListData
                                )
                              }}
                              value={decData(
                                listItem.projectName,
                                listItem.isEncrypt
                              )}
                              onBlur={e => {
                                console.log(
                                  'asdfghj',
                                  e
                                )
                                handleChangeProjectName(e)
                                setEditSelectedList(false)
                              }}
                              onKeyDown={(
                                event: any
                              ) => {
                                if (
                                  event.key === 'Enter'
                                ) {
                                  handleChangeProjectName(event)
                                  setEditSelectedList(false)
                                  console.log('test4567')
                                }
                              }}
                            />
                          ) : (
                            decData(
                              selectedProjectName,
                              listItem &&
                                listItem.isEncrypt &&
                                listItem.isEncrypt == '1'
                                ? '1'
                                : '0'
                            ).substring(
                              0,
                              64
                            )
                          )}
                        </div> */}
                                {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && searchBarInputValue == '' && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                  <div
                                    id='taskBar'
                                    style={{
                                      // position: mobileView?'fixed':'absolute',
                                      // background: showIntroScreen? "" : colorSchemes.MiddlePaneBlurBackground,
                                      // background: showIntroScreen ? "" : colorSchemes.WebMiddlePaneBlurBg,
                                      width: mobileView ? "100%" : taskSectionOpen
                                        ? 'calc(100% - 280px - 370px)'
                                        : 'calc(100% - 280px)',
                                      // bottom: mobileView ? 0 : 0,
                                      backdropFilter: 'blur(3.5px)',
                                      padding: mobileView ? '0px 16px 10px 16px' : '10px 15px 15px 20px',
                                      zIndex: 1
                                    }}
                                  >
                                    <div
                                      className='addTask'

                                      /* style={{ marginTop: 12,position:'absolute',bottom:15,width:taskSectionOpen?  'calc(100% - 320px - 300px)' : 'calc(100% - 320px)', }} */
                                      style={{
                                        width: '100%',
                                        backgroundColor: mobileView ? colorSchemes.WebLeftPaneBg /* taskInputFocus ? colorSchemes.DrawerBackColor : colorSchemes.AddTaskBackground */ : colorSchemes.WebLeftPaneBg,
                                        // borderRadius: mobileView ? taskInputFocus ? '0' : '' : '',
                                        // borderTopLeftRadius: mobileView ? taskInputFocus ? '10' : '' : '',
                                        // borderTopRightRadius: mobileView ? taskInputFocus ? '10' : '' : '',
                                      }}
                                    >

                                      {!mobileView ?
                                        <img
                                          style={{ width: 14, height: 14, cursor: 'pointer' }}
                                          src={AddTaskIcon}
                                        />
                                        :
                                        <>
                                          {taskInputFocus ?
                                            <>
                                              {isDark == 1 ? null
                                                /* <img
                                                  style={{ width: 12, height: 12, cursor: 'pointer', transform: "rotate(45deg)" }}
                                                  src={CrossIconImage}
                                                /> */
                                                :

                                                <img
                                                  style={{ width: 14, height: 14, cursor: 'pointer' }}
                                                  src={AddTaskIcon}
                                                />
                                              }

                                            </>
                                            :

                                            <img
                                              style={{ width: 12, height: 12, cursor: 'pointer', transform: "rotate(45deg)" }}
                                              src={CrossIconImage}
                                            />
                                          }
                                        </>
                                      }
                                      <input
                                        className={isDark == 1 ? 'addTaskInputDark' : 'addTaskInput'}
                                        style={mobileView ? taskInputFocus ? { fontSize: 18, color: '#fff' /* colorSchemes.FontBlackColor */ } : { fontSize: 18, color: colorSchemes.SearchBackColor } : {}}
                                        type='text'
                                        name='name'
                                        placeholder={listItem.isNote && listItem.isNote == "1" ? taskInputFocus ? "" : 'Add a Note' : taskInputFocus ? "" : 'Add a Task'}
                                        // onClick={() => setTaskDrop(true)}
                                        onFocus={() => { if (mobileView && !showIntroScreen) { setTaskInputFocus(true) } }}
                                        onBlur={() => { if (mobileView && !showIntroScreen) { setTaskInputFocus(false); addTaskToList() } }}
                                        value={taskInputValue}
                                        onChange={(e) => { if (!showIntroScreen) { handleChange(e) } }}
                                        autoComplete='off'
                                        onKeyDown={(e) => { if (!showIntroScreen) { handleKeyDown(e) } }}
                                        maxLength={255}
                                        onPaste={(e) => {
                                          if (!showIntroScreen) {
                                            console.log(e.clipboardData.getData('Text'));
                                            let impacts = e.clipboardData.getData('Text');
                                            const result: any = impacts.split("\n").map((impact, index) => (
                                              encData(`${impact.trim()}`)
                                            ));

                                            let tempData: any = [];
                                            if (result && result.length > 1) {
                                              for (let i = 0; i < result.length; i++) {
                                                if (result[i] != "") {
                                                  tempData.push(result[i]);
                                                }
                                              }
                                            }

                                            console.log("result45", result);
                                            if (tempData.length > 1) {
                                              postTaskList(tempData);


                                              setTimeout(() => {
                                                setTaskInputValue('')
                                              }, 200);
                                            }
                                            setTaskSectionOpen(false)
                                          }
                                        }
                                        }
                                      />
                                      {taskInputValue.length > 0 && !showIntroScreen && (
                                        <div>
                                          <div className='addBtn' style={mobileView ? taskInputFocus ? { color: '#fff' /* colorSchemes.FontSemiGrey */, padding: '2px 6px' } : { color: '#fff' /* colorSchemes.FontSemiGrey */, padding: '2px 6px' } : {}} onClick={() => { addTaskToList(); setTaskSectionOpen(false) }}>
                                            Add
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                }
                              </>
                            }
                          </div>
                        }

                      </div>



                      <div >
                        <div className='taskListDiv' ref={taskAddScrollRef} onScroll={() => {
                          if (mobileView) {
                            if (taskAddScrollRef.current.scrollTop >= 10 && taskAddScrollRef.current.scrollTop <= 100)
                              setTaskAddScrollValue(taskAddScrollRef.current.scrollTop);
                            else if (taskAddScrollRef.current.scrollTop < 10)
                              setTaskAddScrollValue(taskAddScrollRef.current.scrollTop);
                            console.log('test 11 ', taskAddScrollRef.current.scrollTop)
                          }
                        }}>
                          {searchBarInputValue != '' ? null :
                            <div style={{ height: !mobileView ? '75px' : selectedProjectName != 'Trash' ? '160px' : '95px' }}></div>
                          }

                          {(!isTaskApiDone || taskData.length > 0 || groupData.length > 1) ? null :
                            <>
                              {localStorage.getItem("intro") != "1" && showIntroScreen &&
                                <IntroScreen setShowIntroScreen={setShowIntroScreen} />
                              }

                              {!showIntroScreen &&
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250 }}>
                                  <div className='noTaskDataDiv'>
                                    To begin, you can add a folder or list, click the icons on the bottom left pane. To add a task, type it in the field below.
                                  </div>
                                </div>
                              }
                            </>
                          }

                          {/* <DragDropContext onDragEnd={onDragEnd}> */}
                          {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' ?
                            null
                            :
                            <>
                              <h4 style={{
                                color: 'white',
                                fontSize: 18,
                                fontWeight: "bold",
                                marginLeft: 15, textAlign: 'center'
                              }}>
                                Anything older than 30 days is purged
                              </h4>
                              {/* <h3 style={{color:'white',
    fontSize: 20,
    fontWeight: "bold",
    marginLeft:15}}>Tasks</h3> */}
                            </>
                          }

                          {refreshLoaderMiddle &&
                            <div style={{ height: 125, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 8 }}>
                              {isLightMode == 1 ?
                                <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Loading} alt="" />
                                :
                                <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
                              }
                              <div style={{ fontSize: 16, fontWeight: 600, color: colorSchemes.FontColor }}>Refreshing...</div>
                            </div>
                          }
                          {refreshFocusLoader &&
                          <div style={{ height: 125, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 8 }}>
                          {isLightMode == 1 ?
                            <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Loading} alt="" />
                            :
                            <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
                          }
                          <div style={{ fontSize: 16, fontWeight: 600, color: colorSchemes.FontColor }}>Syncing...</div>
                        </div>
                        }


                          {searchBarInputValue == '' ?
                            <>
                              {taskData &&
                                taskData.length > 0 && taskData.map((item1, index1) => (
                                  <Droppable
                                    key={index1}
                                    droppableId={`${index1}`}
                                    type={`list`}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        // style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                      >
                                        {item1.map((item, index) => (
                                          <>
                                            <Draggable
                                              type={`list`}
                                              key={`drag-id-${item.id}`}
                                              draggableId={`dragid-${item.id}`}
                                              index={index}
                                              isDragDisabled={listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? false : true}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                // style={getItemStyle(
                                                //   snapshot.isDragging,
                                                //   provided.draggableProps.style
                                                // )}
                                                >
                                                  {item.taskStatus == '0' &&
                                                    selectedProjectID == item.projectId && (
                                                      <>
                                                        {item.isTrash != '2' &&
                                                          <div>

                                                            <div
                                                              className={
                                                                item.color != 'white'
                                                                  ? 'taskListChangeHover'
                                                                  : 'taskList'
                                                              }
                                                              onClick={() => {
                                                                if (editList?.id == item?.id) {
                                                                  return;
                                                                }
                                                                subTaskInputRef?.current?.blur()
                                                                setTaskStepInputValue('')
                                                                timerRef.current = setTimeout(() => {
                                                                  if (!preventRef.current) {
                                                                    sideBarTaskNameInput?.current?.blur()
                                                                    sideBarTaskNoteInputRef?.current?.blur()
                                                                    if (
                                                                      editList?.id != item?.id
                                                                    ) {
                                                                      TaskNameInputRef?.current?.blur()
                                                                    }
                                                                    setTaskItem({})
                                                                    taskItemRef.current = {};
                                                                    //setTimeout(() => {
                                                                    /*  let tempItem={...taskItem}
                                                                     tempItem.name=null;
                                                                     setTaskItem(tempItem); */
                                                                    setTimeout(() => {
                                                                      setTaskItem(item)
                                                                      taskItemRef.current = item;
                                                                      console.log("taskItem852", item)
                                                                    }, 1);
                                                                    // }, 1);
                                                                    try {
                                                                      setLastCommentDivShow(false);
                                                                      setTimeout(() => {
                                                                        commentDivRef.current.scrollTop = 5;
                                                                        commentDivRef.current.scrollTop = 0;
                                                                      }, 200);

                                                                    }
                                                                    catch (ex) {

                                                                    }
                                                                    updateTaskUserActivity(item.id)
                                                                    setTaskIndex(index)
                                                                    setTaskSectionOpen(true)

                                                                    if (mobileView) {
                                                                      middlePaneMobile()
                                                                    }

                                                                    // if (item.taskHeading == 0) {
                                                                    //   setTaskSectionOpen(true)
                                                                    // } else {
                                                                    //   setTaskSectionOpen(false)
                                                                    // }
                                                                    listInputRef?.current?.blur()
                                                                    // forEditTextTaskName(item) /* 11/05 */
                                                                    // setEditList(item) /* 11/05 */
                                                                  }
                                                                }, 200);
                                                              }}
                                                              onDoubleClick={e => {
                                                                clearTimeout(timerRef.current);
                                                                preventRef.current = true;
                                                                setEditList(item)
                                                                sideBarTaskNameInput?.current?.blur()
                                                                sideBarTaskNoteInputRef?.current?.blur()
                                                                if (
                                                                  editList?.id != item?.id
                                                                ) {
                                                                  TaskNameInputRef?.current?.blur()
                                                                }


                                                                setTaskItem({})
                                                                taskItemRef.current = {}
                                                                setTimeout(() => {
                                                                  setTaskItem(item)
                                                                  taskItemRef.current = item;
                                                                }, 1);

                                                                setTaskIndex(index)
                                                                if (item.taskHeading == 0) {
                                                                  setTaskSectionOpen(true)
                                                                } else {
                                                                  setTaskSectionOpen(false)
                                                                }
                                                                listInputRef?.current?.blur()

                                                                setTimeout(() => {
                                                                  preventRef.current = false;
                                                                  TaskNameInputRef?.current?.focus()
                                                                  try {
                                                                    let sel: any =
                                                                      window.getSelection()
                                                                    let range =
                                                                      document.createRange()

                                                                    range.setStart(
                                                                      TaskNameInputRef
                                                                        ?.current
                                                                        ?.childNodes[0],
                                                                      item.taskName.length
                                                                    )
                                                                    range.collapse(true)
                                                                    sel.removeAllRanges()
                                                                    sel.addRange(range)
                                                                  } catch (ex) { }
                                                                }, 100)
                                                              }}
                                                              style={{
                                                                // background: !mobileView ? snapshot.isDragging ? colorSchemes.DrawerBackColor : item.color != 'white' && taskItem?.id != item.id  ? item.color : taskItem?.id == item.id ? colorSchemes.taskActive : colorSchemes.DrawerBackColor : snapshot.isDragging ? colorSchemes.DrawerBackColor : item.color != 'white'? item.color : colorSchemes.DrawerBackColor,

                                                                background: snapshot.isDragging ? !mobileView || isLightMode?'#fff': 'rgb(33, 33, 33)'/* colorSchemes.DrawerBackColor */ : item.color != 'white' ? item.color : /* taskItem?.id == item.id ? colorSchemes.taskActive : */ !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : "rgb(33, 33, 33)",

                                                                border: !mobileView ? /* item.color != 'white' && */ taskItem?.id == item.id ? '2px solid black' : '2px solid transparent' : 'none',


                                                                width: snapshot.isDragging ? 280 : 'auto',
                                                                color: !mobileView ? '#172D46' : colorSchemes.FontColor /* taskItem?.id == item.id ? colorSchemes.FontBlackColor : colorSchemes.FontBlackColor */,
                                                              }}
                                                              ref={anchorTaskMenuListRef}
                                                              // id='composition-button'
                                                              /* aria-controls={
                                                                  taskMenuListOpen
                                                                    ? 'composition-menu'
                                                                    : undefined
                                                                }
                                                                aria-expanded={
                                                                  taskMenuListOpen
                                                                    ? 'true'
                                                                    : undefined
                                                                } */
                                                              // aria-haspopup='false'
                                                              id='basic-button'
                                                              aria-controls={
                                                                openMenuList
                                                                  ? 'basic-menu'
                                                                  : undefined
                                                              }
                                                              aria-haspopup='true'
                                                              aria-expanded={
                                                                openMenuList
                                                                  ? 'true'
                                                                  : undefined
                                                              }
                                                              // onClick={handleClick}
                                                              onContextMenu={(e: any) => {
                                                                e.preventDefault()
                                                                handleClickMenuList(e)
                                                                //e.stopPropagation()
                                                                handleRightClick(e, item)
                                                                subTaskInputRef?.current?.blur()
                                                                setTaskStepInputValue('')
                                                                //setTaskItem({})
                                                                setTimeout(() => {
                                                                  setTaskItem(item)
                                                                  taskItemRef.current = item
                                                                }, 1);
                                                                try {
                                                                  setLastCommentDivShow(false);
                                                                  setTimeout(() => {
                                                                    commentDivRef.current.scrollTop = 5;
                                                                    commentDivRef.current.scrollTop = 0;
                                                                  }, 200);

                                                                }
                                                                catch (ex) {

                                                                }

                                                                try {
                                                                  setDataItemDateTime(new Date(item.createdDateFormated))
                                                                }
                                                                catch (ex) {

                                                                }

                                                                setTaskIndex(index)
                                                                forEditTextTaskNameFalse(item)
                                                                setTaskCompletedLeftClicked(
                                                                  false
                                                                )
                                                                // setTaskSectionOpen(true)
                                                                if (taskLeftClicked) {
                                                                  setTaskLeftClicked(false)
                                                                } else {
                                                                  setTaskLeftClicked(true)
                                                                }
                                                                // handleTaskMenuListToggle()

                                                                // handleDimension(
                                                                //   e.pageX,
                                                                //   e.pageY
                                                                // )

                                                                setDataItem(item)
                                                                setDataItemIndex(index)
                                                                setColorPalette(false)
                                                              }}
                                                            >
                                                              {listItem && listItem.readOnlyStatus == '0' &&
                                                                <>
                                                                  {item.taskNote == '0' ? (
                                                                    <>
                                                                      {item.taskHeading == 0 && (
                                                                        <img
                                                                          onClick={event => {
                                                                            event.stopPropagation()
                                                                            changeStatus(item)
                                                                            addTaskUpdateByOtherUser(item, 'completed task')
                                                                            //updateTaskUserActivity(item.id)
                                                                          }}
                                                                          style={{
                                                                            width: !mobileView ? 16 : 16,
                                                                            cursor: 'pointer'
                                                                          }}
                                                                          src={!mobileView ? UnSelect : UnSelect}
                                                                        />
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <img
                                                                      // onClick={event => {
                                                                      //   event.stopPropagation()
                                                                      //   changeStatus(item)
                                                                      // }}
                                                                      style={{
                                                                        width: !mobileView ? 16 : 16
                                                                        // cursor: 'pointer'
                                                                      }}
                                                                      src={TaskNoteIcon}
                                                                    />
                                                                  )}
                                                                </>
                                                              }
                                                              <div className='taskLine'>
                                                                <div
                                                                  style={{ width: '100%' }}
                                                                >

                                                                  {editList?.id ==
                                                                    item?.id ? (
                                                                    <div
                                                                      ref={TaskNameInputRef}
                                                                      style={{
                                                                        width: '100%',
                                                                        textAlign:
                                                                          item.taskHeading ==
                                                                            0
                                                                            ? 'inherit'
                                                                            : 'center',
                                                                      }}
                                                                      className={item.color != 'white' ? item.taskHeading == 0 ? 'editTextTaskName textOutLine' : 'editTextTaskHeadingName textOutLine'
                                                                        : item.taskHeading == 0 ? 'editTextTaskName' : 'editTextTaskHeadingName'}

                                                                      onMouseDown={e =>
                                                                        onContentEditableLinksMouseDown(
                                                                          e
                                                                        )
                                                                      }
                                                                      autoFocus={true}
                                                                      // onChange={e => {
                                                                      //   let editListData = {...editList}
                                                                      //   editListData.taskName = e.currentTarget.textContent
                                                                      //   setEditList(editListData)
                                                                      // }}
                                                                      // value={editList.taskName}
                                                                      onBlur={e => {
                                                                        console.log(
                                                                          'asdfghj',
                                                                          e
                                                                        )
                                                                        handleChangeTaskName(
                                                                          e,
                                                                          editList
                                                                        )
                                                                      }}
                                                                      onKeyDown={e => {
                                                                        handleTaskNameKeyDown(
                                                                          e,
                                                                          editList
                                                                        )
                                                                      }}
                                                                      contentEditable
                                                                      suppressContentEditableWarning={
                                                                        true
                                                                      }
                                                                      // maxLength={255}
                                                                      onInput={(e: any) => {
                                                                        if (
                                                                          item.taskHeading ==
                                                                          0
                                                                        ) {
                                                                          if (
                                                                            e.keyCode !==
                                                                            8 &&
                                                                            e.currentTarget
                                                                              .textContent
                                                                              .length >= 255
                                                                          ) {
                                                                            console.log(
                                                                              'Text inside div',
                                                                              e
                                                                                .currentTarget
                                                                                .textContent
                                                                            )
                                                                            e.preventDefault()
                                                                          }
                                                                        } else {
                                                                          if (
                                                                            e.keyCode !==
                                                                            8 &&
                                                                            e.currentTarget
                                                                              .textContent
                                                                              .length >= 100
                                                                          ) {
                                                                            console.log(
                                                                              'Text inside div',
                                                                              e
                                                                                .currentTarget
                                                                                .textContent
                                                                            )
                                                                            e.preventDefault()
                                                                          }
                                                                        }
                                                                      }}
                                                                    >
                                                                      {item.taskHeading ==
                                                                        0 ? (
                                                                        <span className={item.color != 'white' ? 'textOutLine' : ''}>
                                                                          {decData(
                                                                            item.taskName,
                                                                            item.isEncrypt
                                                                          ).substring(
                                                                            0,
                                                                            255
                                                                          )}
                                                                        </span>
                                                                      ) : (

                                                                        <span className={item.color != 'white' ? 'textOutLine' : ''}>
                                                                          {decData(
                                                                            item.taskName,
                                                                            item.isEncrypt
                                                                          ).substring(
                                                                            0,
                                                                            100
                                                                          )}
                                                                        </span>

                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <Linkify>
                                                                      <div
                                                                        className={
                                                                          item.taskHeading ==
                                                                            0
                                                                            ? snapshot.isDragging
                                                                              ? 'editTextTaskName oneLine-ellipsis'
                                                                              : 'editTextTaskName'
                                                                            : snapshot.isDragging
                                                                              ? 'editTextTaskHeadingName oneLine-ellipsis'
                                                                              : 'editTextTaskHeadingName'
                                                                        }
                                                                        onMouseDown={e =>
                                                                          onContentEditableLinksMouseDown(
                                                                            e
                                                                          )
                                                                        }
                                                                        style={{
                                                                          paddingRight: 20,
                                                                          textAlign:
                                                                            item.taskHeading ==
                                                                              0
                                                                              ? 'inherit'
                                                                              : 'center'
                                                                        }}
                                                                      >
                                                                        {item.taskHeading ==
                                                                          0 ? (
                                                                          <div>
                                                                            {item.isTaskUpdate == '1' && taskUserActivityCount(item.id) > 0 &&
                                                                              <>
                                                                                <img style={{ width: 15 }} className={taskUserActivityNotificationCount(item.id) > 0 ? 'blinking' : ''} src={RedDot} alt="" />
                                                                                &nbsp;
                                                                              </>
                                                                            }
                                                                            {item.taskBug ==
                                                                              '1' && (
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      'rgb(100, 120, 199)',
                                                                                    fontWeight:
                                                                                      'bold'
                                                                                  }}
                                                                                >
                                                                                  (BUG){' '}
                                                                                </span>
                                                                              )}
                                                                            <span className={item.color != 'white' ? 'textOutLine' : ''} >
                                                                              {decData(
                                                                                item.taskName,
                                                                                item.isEncrypt
                                                                              ).substring(
                                                                                0,
                                                                                255
                                                                              )}
                                                                            </span>
                                                                            {listItem && listItem.showTaskId == '1' &&
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(100, 120, 199)',
                                                                                  fontWeight:
                                                                                    'bold'
                                                                                }}
                                                                              >
                                                                                &nbsp;({item.taskKey}){' '}
                                                                              </span>
                                                                            }
                                                                          </div>
                                                                        ) : (
                                                                          <div>
                                                                            {item.isTaskUpdate == '1' && taskUserActivityCount(item.id) > 0 &&
                                                                              <>
                                                                                <img style={{ width: 15 }} className={taskUserActivityNotificationCount(item.id) > 0 ? 'blinking' : ''} src={RedDot} alt="" />
                                                                                &nbsp;
                                                                              </>
                                                                            }
                                                                            <span className={item.color != 'white' ? 'textOutLine' : ''}>
                                                                              ***{decData(
                                                                                item.taskName,
                                                                                item.isEncrypt
                                                                              ).substring(
                                                                                0,
                                                                                100
                                                                              )}***
                                                                            </span>
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </Linkify>
                                                                  )}


                                                                  <div
                                                                    style={{
                                                                      display: 'flex',
                                                                      alignItems: 'center',
                                                                      color: item.color != 'white' ? '#212529' : !mobileView ? 'rgba(23, 45, 70, 1)' : colorSchemes.FontColor /* colorSchemes.FontSemiGrey */,
                                                                    }}
                                                                  >
                                                                    {item.taskNote == 0 &&
                                                                      item.taskHeading == 0 && (
                                                                        <>
                                                                          {item.task && item.task.length >
                                                                            0 && (
                                                                              <div
                                                                                style={{
                                                                                  display: 'flex',
                                                                                  justifyContent:
                                                                                    'center',
                                                                                  alignItems:
                                                                                    'center',
                                                                                  gap: 6,
                                                                                  marginRight: 6
                                                                                }}
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    fontSize: mobileView ? 16 : 12,
                                                                                    fontWeight: 400
                                                                                  }}
                                                                                >
                                                                                  {statusCount(
                                                                                    item
                                                                                  )}{' '}
                                                                                  of{' '}
                                                                                  {
                                                                                    item.task
                                                                                      .length
                                                                                  }
                                                                                </div>
                                                                                {(item && item.notes && item.notes.length > 0 || item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                                  <img
                                                                                    style={{
                                                                                      width: 4,
                                                                                      height: 4
                                                                                    }}
                                                                                    src={Dot}
                                                                                    alt=''
                                                                                  />
                                                                                )}
                                                                              </div>
                                                                            )}
                                                                        </>
                                                                      )}

                                                                    {item.taskNote == 0 &&
                                                                      item.taskHeading == 0 && (
                                                                        <>
                                                                          {item.notes && item.notes.length >
                                                                            0 && (
                                                                              <div
                                                                                style={{
                                                                                  fontSize: mobileView ? 16 : 12,
                                                                                  fontWeight: 400,
                                                                                  display: 'flex',
                                                                                  alignItems:
                                                                                    'center',
                                                                                  gap: 6,
                                                                                  marginRight: 6
                                                                                }}
                                                                              >
                                                                                <img
                                                                                  style={{
                                                                                    filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                                                                    width: 12,
                                                                                    height: 12
                                                                                  }}
                                                                                  src={!mobileView ? StickyNote : mobileView && isLightMode ? StickyNote : StickyNoteWhite}
                                                                                  alt=''
                                                                                />
                                                                                {(item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                                  <img
                                                                                    style={{
                                                                                      width: 4,
                                                                                      height: 4
                                                                                    }}
                                                                                    src={Dot}
                                                                                    alt=''
                                                                                  />
                                                                                )}{' '}
                                                                              </div>
                                                                            )}
                                                                        </>
                                                                      )}

                                                                    {item.taskNote == 0 &&
                                                                      item.taskHeading == 0 && (
                                                                        <>
                                                                          {item && item.comments && item.comments.length > 0 && (
                                                                            <div
                                                                              style={{
                                                                                fontSize: mobileView ? 16 : 12,
                                                                                fontWeight: 400,
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                  'center',
                                                                                gap: 6,
                                                                                marginRight: 6
                                                                              }}
                                                                            >
                                                                              <img
                                                                                style={{
                                                                                  width: 12,
                                                                                  height: 12
                                                                                }}
                                                                                src={!mobileView ? TaskCommentIcon : mobileView && isLightMode ? TaskCommentIcon : TaskCommentWhiteIcon}
                                                                                alt=''
                                                                              />
                                                                              {item && item.img && item.img.length > 0 && (
                                                                                <img
                                                                                  style={{
                                                                                    width: 4,
                                                                                    height: 4
                                                                                  }}
                                                                                  src={Dot}
                                                                                  alt=''
                                                                                />
                                                                              )}{' '}
                                                                            </div>
                                                                          )}
                                                                        </>
                                                                      )}


                                                                    {item.taskHeading == 0 && (
                                                                      <>
                                                                        {item.img.length >
                                                                          0 && (
                                                                            <div
                                                                              style={{
                                                                                fontSize: mobileView ? 16 : 12,
                                                                                fontWeight: 400,
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                  'center',
                                                                                gap: 5
                                                                              }}
                                                                            >
                                                                              <img
                                                                                style={{
                                                                                  filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                                                                  width: 13,
                                                                                  height: 13
                                                                                }}
                                                                                src={
                                                                                  AddFlieIcon
                                                                                }
                                                                                alt=''
                                                                              />
                                                                              <span>
                                                                                Files attached
                                                                              </span>
                                                                            </div>
                                                                          )}
                                                                      </>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                                <div className='taskLineImages'>
                                                                  {/* <img
                                                onClick={() =>
                                                  openTask(item, index)
                                                }
                                                style={{
                                                  cursor: 'pointer'
                                                }}
                                                src={More}
                                              /> */}
                                                                  {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                                                    <div id='more'>
                                                                      <Button
                                                                        ref={
                                                                          anchorTaskMenuListRef
                                                                        }
                                                                        id='basic-button'
                                                                        aria-controls={
                                                                          openMenuList
                                                                            ? 'basic-menu'
                                                                            : undefined
                                                                        }
                                                                        aria-haspopup='true'
                                                                        aria-expanded={
                                                                          openMenuList
                                                                            ? 'true'
                                                                            : undefined
                                                                        }
                                                                        onClick={e => {
                                                                          e.stopPropagation()
                                                                          handleClickMenuList(e)
                                                                          setTimeout(() => {
                                                                            setTaskItem(item)
                                                                            taskItemRef.current = item
                                                                          }, 1);
                                                                           try {
                                                                            setLastCommentDivShow(false);
                                                                            setTimeout(() => {
                                                                              commentDivRef.current.scrollTop = 5;
                                                                              commentDivRef.current.scrollTop = 0;
                                                                            }, 200);

                                                                          }
                                                                          catch (ex) {

                                                                          }
                                                                          setTaskIndex(index)
                                                                          forEditTextTaskNameFalse(
                                                                            item
                                                                          )
                                                                          
                                                                          setTaskCompletedLeftClicked(
                                                                            false
                                                                          )
                                                                          if (taskLeftClicked) {
                                                                            setTaskLeftClicked(
                                                                              false
                                                                            )
                                                                          } else {
                                                                            setTaskLeftClicked(
                                                                              true
                                                                            )
                                                                            handleRightClick(e, item)
                                                                          }

                                                                         try {
                                                                           console.log("item.createdDateFormated",item)
                                                                            setDataItemDateTime(new Date(item.createdDateFormated))
                                                                          }
                                                                          catch (ex) {
                                                                          }

                                                                          
                                                                          setDataItem(item)
                                                                          setDataItemIndex(
                                                                            index
                                                                          )
                                                                          setColorPalette(false)
                                                                        }}
                                                                        
                                                                        className='settingButton whiteDots'
                                                                      >
                                                                        {/* <img style={{ width: 22 }} src={WhiteDots} /> */}
                                                                        <img
                                                                          style={{
                                                                            width: 24,
                                                                            cursor: 'pointer'
                                                                          }}
                                                                          src={More}
                                                                        />
                                                                      </Button>
                                                                    </div>
                                                                  }
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        }
                                                      </>
                                                    )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Draggable>

                                          </>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                ))}
                            </>
                            :
                            <>
                              {taskData &&
                                taskData.length > 0 && taskData.map((item1, index1) => (
                                  <>
                                    {item1.map((item, index) => (
                                      <>
                                        {item.taskStatus == '0' && item.taskName && searchBarInputValue && decData(item.taskName, item.isEncrypt).toLowerCase().includes(searchBarInputValue) && (
                                          <>
                                            {item.isTrash != '2' && item.taskHeading == 0 && getTaskLoaction(item) && getTaskLoaction(item) != "" &&
                                              <div>

                                                <div
                                                  className={
                                                    item.color != 'white'
                                                      ? 'taskListChangeHover'
                                                      : 'taskList'
                                                  }
                                                  onClick={() => {
                                                    if (editList?.id == item?.id) {
                                                      return;
                                                    }
                                                    subTaskInputRef?.current?.blur()
                                                    setTaskStepInputValue('')
                                                    timerRef.current = setTimeout(() => {
                                                      if (!preventRef.current) {
                                                        sideBarTaskNameInput?.current?.blur()
                                                        sideBarTaskNoteInputRef?.current?.blur()
                                                        if (
                                                          editList?.id != item?.id
                                                        ) {
                                                          TaskNameInputRef?.current?.blur()
                                                        }
                                                        setTaskItem({})
                                                        taskItemRef.current = {}
                                                        setTimeout(() => {
                                                          setTaskItem(item)
                                                          taskItemRef.current = item
                                                          console.log("taskItem852", item)
                                                        }, 1);
                                                        try {
                                                          setLastCommentDivShow(false);
                                                          setTimeout(() => {
                                                            commentDivRef.current.scrollTop = 5;
                                                            commentDivRef.current.scrollTop = 0;
                                                          }, 200);

                                                        }
                                                        catch (ex) {

                                                        }
                                                        updateTaskUserActivity(item.id)
                                                        setTaskIndex(index)
                                                        setTaskSectionOpen(true)

                                                        if (mobileView) {
                                                          middlePaneMobile()
                                                        }
                                                        listInputRef?.current?.blur()
                                                      }
                                                    }, 200);
                                                  }}
                                                  onDoubleClick={e => {
                                                    clearTimeout(timerRef.current);
                                                    preventRef.current = true;
                                                    setEditList(item)
                                                    sideBarTaskNameInput?.current?.blur()
                                                    sideBarTaskNoteInputRef?.current?.blur()
                                                    if (
                                                      editList?.id != item?.id
                                                    ) {
                                                      TaskNameInputRef?.current?.blur()
                                                    }


                                                    setTaskItem({})
                                                    taskItemRef.current = {}
                                                    setTimeout(() => {
                                                      setTaskItem(item)
                                                      taskItemRef.current = item
                                                    }, 1);

                                                    setTaskIndex(index)
                                                    if (item.taskHeading == 0) {
                                                      setTaskSectionOpen(true)
                                                    } else {
                                                      setTaskSectionOpen(false)
                                                    }
                                                    listInputRef?.current?.blur()

                                                    setTimeout(() => {
                                                      preventRef.current = false;
                                                      TaskNameInputRef?.current?.focus()
                                                      try {
                                                        let sel: any =
                                                          window.getSelection()
                                                        let range =
                                                          document.createRange()

                                                        range.setStart(
                                                          TaskNameInputRef
                                                            ?.current
                                                            ?.childNodes[0],
                                                          item.taskName.length
                                                        )
                                                        range.collapse(true)
                                                        sel.removeAllRanges()
                                                        sel.addRange(range)
                                                      } catch (ex) { }
                                                    }, 100)
                                                  }}
                                                  style={{
                                                    background: item.color != 'white' ? item.color :/*  taskItem?.id == item.id ? colorSchemes.taskActive : */ /* colorSchemes.DrawerBackColor */ !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : "#000",

                                                    border: !mobileView ? /* item.color != 'white' && */ taskItem?.id == item.id ? '2px solid black' : '2px solid transparent' : 'none',


                                                    width: 'auto',
                                                    color: !mobileView ? '#172D46' : colorSchemes.FontColor /* taskItem?.id == item.id ? colorSchemes.FontBlackColor : colorSchemes.FontBlackColor */,
                                                  }}
                                                  ref={anchorTaskMenuListRef}
                                                  id='basic-button'
                                                  aria-controls={
                                                    openMenuList
                                                      ? 'basic-menu'
                                                      : undefined
                                                  }
                                                  aria-haspopup='true'
                                                  aria-expanded={
                                                    openMenuList
                                                      ? 'true'
                                                      : undefined
                                                  }
                                                  onContextMenu={(e: any) => {
                                                    e.preventDefault()
                                                    handleClickMenuList(e)
                                                    handleRightClick(e, item)
                                                    subTaskInputRef?.current?.blur()
                                                    setTaskStepInputValue('')
                                                    setTimeout(() => {
                                                      setTaskItem(item)
                                                      taskItemRef.current = item
                                                    }, 1);
                                                    try {
                                                      setLastCommentDivShow(false);
                                                      setTimeout(() => {
                                                        commentDivRef.current.scrollTop = 5;
                                                        commentDivRef.current.scrollTop = 0;
                                                      }, 200);

                                                    }
                                                    catch (ex) {

                                                    }

                                                    try {
                                                      setDataItemDateTime(new Date(item.createdDateFormated))
                                                    }
                                                    catch (ex) {

                                                    }

                                                    setTaskIndex(index)
                                                    forEditTextTaskNameFalse(item)
                                                    setTaskCompletedLeftClicked(
                                                      false
                                                    )
                                                    if (taskLeftClicked) {
                                                      setTaskLeftClicked(false)
                                                    } else {
                                                      setTaskLeftClicked(true)
                                                    }

                                                    setDataItem(item)
                                                    setDataItemIndex(index)
                                                    setColorPalette(false)
                                                  }}
                                                >

                                                  {item.taskNote == '0' ? (
                                                    <>
                                                      {item.taskHeading == 0 && (
                                                        <img
                                                          onClick={event => {
                                                            event.stopPropagation()
                                                            changeStatus(item)
                                                            addTaskUpdateByOtherUser(item, 'completed task')
                                                          }}
                                                          style={{
                                                            width: !mobileView ? 16 : 16,
                                                            cursor: 'pointer'
                                                          }}
                                                          src={!mobileView ? UnSelect : UnSelect}
                                                        />
                                                      )}
                                                    </>
                                                  ) : (
                                                    <img
                                                      style={{
                                                        width: !mobileView ? 16 : 16
                                                      }}
                                                      src={TaskNoteIcon}
                                                    />
                                                  )}
                                                  <div className='taskLine'>
                                                    <div
                                                      style={{ width: '100%' }}
                                                    >

                                                      {editList?.id ==
                                                        item?.id ? (
                                                        <div
                                                          ref={TaskNameInputRef}
                                                          style={{
                                                            width: '100%',
                                                            textAlign:
                                                              item.taskHeading ==
                                                                0
                                                                ? 'inherit'
                                                                : 'center',
                                                          }}
                                                          className={item.color != 'white' ? item.taskHeading == 0 ? 'editTextTaskName textOutLine' : 'editTextTaskHeadingName textOutLine'
                                                            : item.taskHeading == 0 ? 'editTextTaskName' : 'editTextTaskHeadingName'}

                                                          onMouseDown={e =>
                                                            onContentEditableLinksMouseDown(
                                                              e
                                                            )
                                                          }
                                                          autoFocus={true}
                                                          onBlur={e => {
                                                            console.log(
                                                              'asdfghj',
                                                              e
                                                            )
                                                            handleChangeTaskName(
                                                              e,
                                                              editList
                                                            )
                                                          }}
                                                          onKeyDown={e => {
                                                            handleTaskNameKeyDown(
                                                              e,
                                                              editList
                                                            )
                                                          }}
                                                          contentEditable
                                                          suppressContentEditableWarning={
                                                            true
                                                          }
                                                          onInput={(e: any) => {
                                                            if (
                                                              item.taskHeading ==
                                                              0
                                                            ) {
                                                              if (
                                                                e.keyCode !==
                                                                8 &&
                                                                e.currentTarget
                                                                  .textContent
                                                                  .length >= 255
                                                              ) {
                                                                console.log(
                                                                  'Text inside div',
                                                                  e
                                                                    .currentTarget
                                                                    .textContent
                                                                )
                                                                e.preventDefault()
                                                              }
                                                            } else {
                                                              if (
                                                                e.keyCode !==
                                                                8 &&
                                                                e.currentTarget
                                                                  .textContent
                                                                  .length >= 100
                                                              ) {
                                                                console.log(
                                                                  'Text inside div',
                                                                  e
                                                                    .currentTarget
                                                                    .textContent
                                                                )
                                                                e.preventDefault()
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          {item.taskHeading ==
                                                            0 ? (
                                                            <span className={item.color != 'white' ? 'textOutLine' : ''}>
                                                              {decData(
                                                                item.taskName,
                                                                item.isEncrypt
                                                              ).substring(
                                                                0,
                                                                255
                                                              )}
                                                            </span>
                                                          ) : (

                                                            <span className={item.color != 'white' ? 'textOutLine' : ''}>
                                                              {decData(
                                                                item.taskName,
                                                                item.isEncrypt
                                                              ).substring(
                                                                0,
                                                                100
                                                              )}
                                                            </span>

                                                          )}
                                                        </div>
                                                      ) : (
                                                        <Linkify>
                                                          <div
                                                            className={
                                                              item.taskHeading == 0 ? 'editTextTaskName' : 'editTextTaskHeadingName'
                                                            }
                                                            onMouseDown={e =>
                                                              onContentEditableLinksMouseDown(
                                                                e
                                                              )
                                                            }
                                                            style={{
                                                              paddingRight: 20,
                                                              textAlign:
                                                                item.taskHeading ==
                                                                  0
                                                                  ? 'inherit'
                                                                  : 'center'
                                                            }}
                                                          >
                                                            {item.taskHeading ==
                                                              0 ? (
                                                              <div>
                                                                {item.isTaskUpdate == '1' && taskUserActivityCount(item.id) > 0 &&
                                                                  <>
                                                                    <img style={{ width: 15 }} className={taskUserActivityNotificationCount(item.id) > 0 ? 'blinking' : ''} src={RedDot} alt="" />
                                                                    &nbsp;
                                                                  </>
                                                                }
                                                                {item.taskBug ==
                                                                  '1' && (
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          'rgb(100, 120, 199)',
                                                                        fontWeight:
                                                                          'bold'
                                                                      }}
                                                                    >
                                                                      (BUG){' '}
                                                                    </span>
                                                                  )}
                                                                <span className={item.color != 'white' ? 'textOutLine' : ''} >
                                                                  {decData(
                                                                    item.taskName,
                                                                    item.isEncrypt
                                                                  ).substring(
                                                                    0,
                                                                    255
                                                                  )}
                                                                </span>
                                                                {listItem && listItem.showTaskId == '1' &&
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        'rgb(100, 120, 199)',
                                                                      fontWeight:
                                                                        'bold'
                                                                    }}
                                                                  >
                                                                    &nbsp;({item.taskKey}){' '}
                                                                  </span>
                                                                }
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                {item.isTaskUpdate == '1' && taskUserActivityCount(item.id) > 0 &&
                                                                  <>
                                                                    <img style={{ width: 15 }} className={taskUserActivityNotificationCount(item.id) > 0 ? 'blinking' : ''} src={RedDot} alt="" />
                                                                    &nbsp;
                                                                  </>
                                                                }
                                                                <span className={item.color != 'white' ? 'textOutLine' : ''}>
                                                                  {decData(
                                                                    item.taskName,
                                                                    item.isEncrypt
                                                                  ).substring(
                                                                    0,
                                                                    100
                                                                  )}
                                                                </span>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </Linkify>
                                                      )}


                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          color: item.color != 'white' ? '#212529' : !mobileView ? 'rgba(23, 45, 70, 1)' : colorSchemes.FontColor /* colorSchemes.FontSemiGrey */,
                                                        }}
                                                      >

                                                        <>
                                                          <div
                                                            style={{
                                                              fontSize: mobileView ? 16 : 12,
                                                              fontWeight: 400,
                                                              display: 'flex',
                                                              alignItems:
                                                                'center',
                                                              gap: 5
                                                            }}
                                                          >
                                                            <span>
                                                              {getTaskLoaction(item)}
                                                            </span>
                                                            {(item.task && item.task.length > 0 && item.taskNote == 0) || (item.notes && item.notes.length > 0 && item.taskNote == 0) || (item && item.comments && item.comments.length > 0 && item.taskNote == 0) || (item.img && item.img.length > 0 && item.taskNote == 0) ?
                                                              <img
                                                                style={{
                                                                  width: 4,
                                                                  height: 4,
                                                                  marginRight: 6
                                                                }}
                                                                src={Dot}
                                                                alt=''
                                                              />
                                                              : null}
                                                          </div>
                                                        </>
                                                        {item.taskNote == 0 &&
                                                          item.taskHeading == 0 && (
                                                            <>
                                                              {item.task && item.task.length >
                                                                0 && (
                                                                  <div
                                                                    style={{
                                                                      display: 'flex',
                                                                      justifyContent:
                                                                        'center',
                                                                      alignItems:
                                                                        'center',
                                                                      gap: 6,
                                                                      marginRight: 6
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        fontSize: mobileView ? 16 : 12,
                                                                        fontWeight: 400
                                                                      }}
                                                                    >
                                                                      {statusCount(
                                                                        item
                                                                      )}{' '}
                                                                      of{' '}
                                                                      {
                                                                        item.task
                                                                          .length
                                                                      }
                                                                    </div>
                                                                    {(item && item.notes && item.notes.length > 0 || item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                      <img
                                                                        style={{
                                                                          width: 4,
                                                                          height: 4
                                                                        }}
                                                                        src={Dot}
                                                                        alt=''
                                                                      />
                                                                    )}
                                                                  </div>
                                                                )}
                                                            </>
                                                          )}

                                                        {item.taskNote == 0 &&
                                                          item.taskHeading == 0 && (
                                                            <>
                                                              {item.notes && item.notes.length >
                                                                0 && (
                                                                  <div
                                                                    style={{
                                                                      fontSize: mobileView ? 16 : 12,
                                                                      fontWeight: 400,
                                                                      display: 'flex',
                                                                      alignItems:
                                                                        'center',
                                                                      gap: 6,
                                                                      marginRight: 6
                                                                    }}
                                                                  >
                                                                    <img
                                                                      style={{
                                                                        filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                                                        width: 12,
                                                                        height: 12
                                                                      }}
                                                                      src={!mobileView ? StickyNote : mobileView && isLightMode ? StickyNote : StickyNoteWhite}
                                                                      alt=''
                                                                    />
                                                                    {(item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                      <img
                                                                        style={{
                                                                          width: 4,
                                                                          height: 4
                                                                        }}
                                                                        src={Dot}
                                                                        alt=''
                                                                      />
                                                                    )}{' '}
                                                                  </div>
                                                                )}
                                                            </>
                                                          )}


                                                        {item.taskNote == 0 &&
                                                          item.taskHeading == 0 && (
                                                            <>
                                                              {item && item.comments && item.comments.length > 0 && (
                                                                <div
                                                                  style={{
                                                                    fontSize: mobileView ? 16 : 12,
                                                                    fontWeight: 400,
                                                                    display: 'flex',
                                                                    alignItems:
                                                                      'center',
                                                                    gap: 6,
                                                                    marginRight: 6
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      width: 12,
                                                                      height: 12
                                                                    }}
                                                                    src={!mobileView ? TaskCommentIcon : mobileView && isLightMode ? TaskCommentIcon : TaskCommentWhiteIcon}
                                                                    alt=''
                                                                  />
                                                                  {item && item.img && item.img.length > 0 && (
                                                                    <img
                                                                      style={{
                                                                        width: 4,
                                                                        height: 4
                                                                      }}
                                                                      src={Dot}
                                                                      alt=''
                                                                    />
                                                                  )}{' '}
                                                                </div>
                                                              )}
                                                            </>
                                                          )}


                                                        {item.taskHeading == 0 && (
                                                          <>
                                                            {item.img.length >
                                                              0 && (
                                                                <div
                                                                  style={{
                                                                    fontSize: mobileView ? 16 : 12,
                                                                    fontWeight: 400,
                                                                    display: 'flex',
                                                                    alignItems:
                                                                      'center',
                                                                    gap: 5
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                                                      width: 13,
                                                                      height: 13
                                                                    }}
                                                                    src={
                                                                      AddFlieIcon
                                                                    }
                                                                    alt=''
                                                                  />
                                                                  <span>
                                                                    Files attached
                                                                  </span>
                                                                </div>
                                                              )}
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className='taskLineImages'>
                                                      <div id='more'>
                                                        <Button
                                                          ref={
                                                            anchorTaskMenuListRef
                                                          }
                                                          id='basic-button'
                                                          aria-controls={
                                                            openMenuList
                                                              ? 'basic-menu'
                                                              : undefined
                                                          }
                                                          aria-haspopup='true'
                                                          aria-expanded={
                                                            openMenuList
                                                              ? 'true'
                                                              : undefined
                                                          }
                                                          onClick={e => {
                                                            e.stopPropagation()
                                                            handleClickMenuList(e)
                                                            setTimeout(() => {
                                                              setTaskItem(item)
                                                              taskItemRef.current = item
                                                            }, 1);
                                                            try {
                                                              setLastCommentDivShow(false);
                                                              setTimeout(() => {
                                                                commentDivRef.current.scrollTop = 5;
                                                                commentDivRef.current.scrollTop = 0;
                                                              }, 200);

                                                            }
                                                            catch (ex) {

                                                            }
                                                            setTaskIndex(index)
                                                            forEditTextTaskNameFalse(
                                                              item
                                                            )
                                                            setTaskCompletedLeftClicked(
                                                              false
                                                            )
                                                            if (taskLeftClicked) {
                                                              setTaskLeftClicked(
                                                                false
                                                              )
                                                            } else {
                                                              setTaskLeftClicked(
                                                                true
                                                              )
                                                              handleRightClick(e, item)
                                                            }

                                                            try {
                                                              setDataItemDateTime(new Date(item.createdDateFormated))
                                                            }
                                                            catch (ex) {

                                                            }
                                                            setDataItem(item)
                                                            setDataItemIndex(
                                                              index
                                                            )
                                                            setColorPalette(false)
                                                          }}
                                                          className='settingButton whiteDots'
                                                        >
                                                          <img
                                                            style={{
                                                              width: 24,
                                                              cursor: 'pointer'
                                                            }}
                                                            src={More}
                                                          />
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                          </>
                                        )}

                                      </>
                                    ))}
                                  </>
                                ))}
                            </>
                          }

                          {selectedProjectID == Base64.encode("1") && selectedProjectName == 'Trash' &&
                            <>
                              {trashData && trashData.length > 0 && trashData.map((item, index) => (
                                <div>
                                  <div
                                    className={
                                      item.color != 'white'
                                        ? 'taskListChangeHover'
                                        : 'taskList'
                                    }
                                    onClick={() => {
                                      sideBarTaskNameInput?.current?.blur()
                                      sideBarTaskNoteInputRef?.current?.blur()
                                      subTaskInputRef?.current?.blur()
                                      setTaskStepInputValue('')
                                      if (
                                        editList?.id != item?.id
                                      ) {
                                        TaskNameInputRef?.current?.blur()
                                      }
                                      setTaskItem({})
                                      taskItemRef.current = {}
                                      setTimeout(() => {
                                        setTaskItem(item)
                                        taskItemRef.current = item
                                      }, 1);
                                      try {
                                        setLastCommentDivShow(false);
                                        setTimeout(() => {
                                          commentDivRef.current.scrollTop = 5;
                                          commentDivRef.current.scrollTop = 0;
                                        }, 200);

                                      }
                                      catch (ex) {

                                      }
                                      setTaskIndex(index)
                                      // if (item.taskHeading == 0) {
                                      setTaskSectionOpen(true)
                                      // } else {
                                      //   setTaskSectionOpen(false)
                                      // }
                                      if (mobileView) {
                                        middlePaneMobile()
                                      }
                                      listInputRef?.current?.blur()
                                      // forEditTextTaskName(item) /* 11/05 */
                                      // setEditList(item) /* 11/05 */
                                    }}
                                    // onDoubleClick={e => {
                                    //   setEditList(item)
                                    //   setTimeout(() => {
                                    //     TaskNameInputRef?.current?.focus()
                                    //     try {
                                    //       let sel: any =
                                    //         window.getSelection()
                                    //       let range =
                                    //         document.createRange()

                                    //       range.setStart(
                                    //         TaskNameInputRef
                                    //           ?.current
                                    //           ?.childNodes[0],
                                    //         item.taskName.length
                                    //       )
                                    //       range.collapse(true)
                                    //       sel.removeAllRanges()
                                    //       sel.addRange(range)
                                    //     } catch (ex) {}
                                    //   }, 100)
                                    // }}
                                    style={{

                                      // background: snapshot.isDragging ? '#fff' : item.color != 'white' ? item.color : !mobileView? '#fff':mobileView && isLightMode?'#fff':"#000",

                                      // border: !mobileView ?  taskItem?.id == item.id ? '2px solid black' : '2px solid transparent' : 'none',


                                      // width: snapshot.isDragging ? 280 : 'auto',
                                      // color: !mobileView?'#172D46':colorSchemes.FontColor 


                                      background: item.color != 'white' ? item.color : !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : "rgb(33, 33, 33)",


                                      // background: item.color != 'white' ? item.color : taskItem?.id == item.id ? '#dadce6' : colorSchemes.DrawerBackColor,

                                      border: !mobileView ? /* item.color != 'white' && */ taskItem?.id == item.id ? '2px solid black' : '2px solid transparent' : 'none',

                                      // background:
                                      //   item.color != 'white'
                                      //     ? item.color
                                      //     : taskItem?.id == item.id
                                      //       ? '#dadce6'
                                      //       : item.color,
                                      width: 'auto',
                                      color: taskItem?.id == item.id ? colorSchemes.FontColor : !mobileView ? '#172D46' : colorSchemes.FontColor,
                                    }}
                                    ref={anchorTaskMenuListRef}
                                    // id='composition-button'
                                    /* aria-controls={
                                        taskMenuListOpen
                                          ? 'composition-menu'
                                          : undefined
                                      }
                                      aria-expanded={
                                        taskMenuListOpen
                                          ? 'true'
                                          : undefined
                                      } */
                                    // aria-haspopup='false'
                                    id='basic-button'
                                    aria-controls={
                                      openMenuList
                                        ? 'basic-menu'
                                        : undefined
                                    }
                                    aria-haspopup='true'
                                    aria-expanded={
                                      openMenuList
                                        ? 'true'
                                        : undefined
                                    }
                                    // onClick={handleClick}
                                    onContextMenu={(e: any) => {
                                      e.preventDefault()
                                      handleClickMenuList(e)
                                      //e.stopPropagation()
                                      handleRightClick(e, item)
                                      subTaskInputRef?.current?.blur()
                                      setTaskStepInputValue('')
                                      //setTaskItem({})
                                      setTimeout(() => {
                                        setTaskItem(item)
                                        taskItemRef.current = item
                                      }, 1);
                                      try {
                                        setLastCommentDivShow(false);
                                        setTimeout(() => {
                                          commentDivRef.current.scrollTop = 5;
                                          commentDivRef.current.scrollTop = 0;
                                        }, 200);

                                      }
                                      catch (ex) {

                                      }
                                      setTaskIndex(index)
                                      forEditTextTaskNameFalse(item)
                                      setTaskCompletedLeftClicked(
                                        false
                                      )
                                      // setTaskSectionOpen(true)
                                      if (taskLeftClicked) {
                                        setTaskLeftClicked(false)
                                      } else {
                                        setTaskLeftClicked(true)
                                      }
                                      // handleTaskMenuListToggle()

                                      // handleDimension(
                                      //   e.pageX,
                                      //   e.pageY
                                      // )

                                      setDataItem(item)
                                      setDataItemIndex(index)
                                      setColorPalette(false)
                                    }}
                                  >
                                    {item.taskStatus == '0' ? <>
                                      {item.taskNote == '0' ? (
                                        <>
                                          {item.taskHeading == 0 && (
                                            <img
                                              // onClick={event => {
                                              //   event.stopPropagation()
                                              //   changeStatus(item)
                                              // }}
                                              className='customCursor'
                                              style={{
                                                width: !mobileView ? 16 : 23,
                                                cursor: 'pointer'
                                              }}
                                              src={!mobileView ? UnSelect : UnSelect}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          // onClick={event => {
                                          //   event.stopPropagation()
                                          //   changeStatus(item)
                                          // }}
                                          className='customCursor'
                                          style={{
                                            width: !mobileView ? 16 : 23
                                            // cursor: 'pointer'
                                          }}
                                          src={TaskNoteIcon}
                                        />
                                      )}
                                    </>
                                      :
                                      <>
                                        {item.taskNote == '0' ? (
                                          <>
                                            {item.taskHeading == 0 && (
                                              <img
                                                // onClick={event => {
                                                //   event.stopPropagation()
                                                //   changeStatus(item)
                                                // }}
                                                className='customCursor'
                                                style={{
                                                  width: !mobileView ? 16 : 23,
                                                  cursor: 'pointer'
                                                }}
                                                src={Select}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <img
                                            // onClick={event => {
                                            //   event.stopPropagation()
                                            //   changeStatus(item)
                                            // }}
                                            className='customCursor'
                                            style={{
                                              width: !mobileView ? 16 : 23
                                              // cursor: 'pointer'
                                            }}
                                            src={TaskNoteIcon}
                                          />
                                        )}
                                      </>
                                    }
                                    <div className='taskLine'>
                                      <div
                                        style={{ width: '100%' }}
                                        className={item.color != 'white' ? 'textOutLine' : ''}
                                      >
                                        <LinkItUrl>
                                          {editList?.id ==
                                            item?.id ? (
                                            <div
                                              ref={TaskNameInputRef}
                                              style={{
                                                width: '100%',
                                                textDecoration: item.taskStatus == "0" ? 'inherit' : 'line-through',
                                                textAlign:
                                                  item.taskHeading ==
                                                    0
                                                    ? 'inherit'
                                                    : 'center'
                                              }}
                                              className={
                                                item.taskHeading ==
                                                  0
                                                  ? 'editTextTaskName'
                                                  : 'editTextTaskHeadingName'
                                              }
                                              onMouseDown={e =>
                                                onContentEditableLinksMouseDown(
                                                  e
                                                )
                                              }
                                              autoFocus={true}
                                              // onChange={e => {
                                              //   let editListData = {...editList}
                                              //   editListData.taskName = e.currentTarget.textContent
                                              //   setEditList(editListData)
                                              // }}
                                              // value={editList.taskName}
                                              onBlur={e => {
                                                console.log(
                                                  'asdfghj',
                                                  e
                                                )
                                                handleChangeTaskName(
                                                  e,
                                                  editList
                                                )
                                              }}
                                              onKeyDown={e => {
                                                handleTaskNameKeyDown(
                                                  e,
                                                  editList
                                                )
                                              }}
                                              contentEditable
                                              suppressContentEditableWarning={
                                                true
                                              }
                                              // maxLength={255}
                                              onInput={(e: any) => {
                                                if (
                                                  item.taskHeading ==
                                                  0
                                                ) {
                                                  if (
                                                    e.keyCode !==
                                                    8 &&
                                                    e.currentTarget
                                                      .textContent
                                                      .length >= 255
                                                  ) {
                                                    console.log(
                                                      'Text inside div',
                                                      e
                                                        .currentTarget
                                                        .textContent
                                                    )
                                                    e.preventDefault()
                                                  }
                                                } else {
                                                  if (
                                                    e.keyCode !==
                                                    8 &&
                                                    e.currentTarget
                                                      .textContent
                                                      .length >= 100
                                                  ) {
                                                    console.log(
                                                      'Text inside div',
                                                      e
                                                        .currentTarget
                                                        .textContent
                                                    )
                                                    e.preventDefault()
                                                  }
                                                }
                                              }}
                                            >
                                              {item.taskHeading ==
                                                0 ? (
                                                <>
                                                  {decData(
                                                    item.taskName,
                                                    item.isEncrypt
                                                  ).substring(
                                                    0,
                                                    255
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {decData(
                                                    item.taskName,
                                                    item.isEncrypt
                                                  ).substring(
                                                    0,
                                                    100
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                item.taskHeading ==
                                                  0
                                                  ? 'editTextTaskName'
                                                  : 'editTextTaskHeadingName'
                                              }
                                              onMouseDown={e =>
                                                onContentEditableLinksMouseDown(
                                                  e
                                                )
                                              }
                                              style={{
                                                paddingRight: 20,
                                                textDecoration: item.taskStatus == "0" ? 'inherit' : 'line-through',
                                                textAlign:
                                                  item.taskHeading ==
                                                    0
                                                    ? 'inherit'
                                                    : 'center'
                                              }}
                                            >
                                              {item.taskHeading ==
                                                0 ? (
                                                <>
                                                  {item.taskBug ==
                                                    '1' && (
                                                      <span
                                                        style={{
                                                          color:
                                                            'rgb(100, 120, 199)',
                                                          fontWeight:
                                                            'bold'
                                                        }}
                                                      >
                                                        (BUG){' '}
                                                      </span>
                                                    )}
                                                  {decData(
                                                    item.taskName,
                                                    item.isEncrypt
                                                  ).substring(
                                                    0,
                                                    255
                                                  )}
                                                  {listItem && listItem.showTaskId == '1' &&
                                                    <span
                                                      style={{
                                                        color:
                                                          'rgb(100, 120, 199)',
                                                        fontWeight:
                                                          'bold'
                                                      }}
                                                    >
                                                      &nbsp;({item.taskKey}){' '}
                                                    </span>
                                                  }
                                                </>
                                              ) : (
                                                <>
                                                  {decData(
                                                    item.taskName,
                                                    item.isEncrypt
                                                  ).substring(
                                                    0,
                                                    100
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          )}
                                        </LinkItUrl>

                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: item.color != 'white' ? '#212529' : !mobileView ? 'rgba(23, 45, 70, 1)' : colorSchemes.FontColor /* colorSchemes.FontSemiGrey */,
                                          }}
                                        >
                                          {item.taskNote == 0 &&
                                            item.taskHeading == 0 && (
                                              <>
                                                {item.task && item.task.length >
                                                  0 && (
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                          'center',
                                                        alignItems:
                                                          'center',
                                                        gap: 6,
                                                        marginRight: 6
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          fontSize: mobileView ? 16 : 12,
                                                          fontWeight: 400
                                                        }}
                                                      >
                                                        {statusCount(
                                                          item
                                                        )}{' '}
                                                        of{' '}
                                                        {
                                                          item.task
                                                            .length
                                                        }
                                                      </div>
                                                      {(item.notes
                                                        .length > 0 ||
                                                        item.img
                                                          .length >
                                                        0) && (
                                                          <img
                                                            style={{
                                                              width: 4,
                                                              height: 4
                                                            }}
                                                            src={Dot}
                                                            alt=''
                                                          />
                                                        )}
                                                    </div>
                                                  )}
                                              </>
                                            )}

                                          {item.taskNote == 0 &&
                                            item.taskHeading == 0 && (
                                              <>
                                                {item.notes && item.notes.length >
                                                  0 && (
                                                    <div
                                                      style={{
                                                        fontSize: mobileView ? 16 : 12,
                                                        fontWeight: 400,
                                                        display: 'flex',
                                                        alignItems:
                                                          'center',
                                                        gap: 6,
                                                        marginRight: 6
                                                      }}
                                                    >
                                                      <img
                                                        style={{
                                                          filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                                          width: 12,
                                                          height: 12
                                                        }}
                                                        src={!mobileView ? StickyNote : mobileView && isLightMode ? StickyNote : StickyNoteWhite}
                                                        alt=''
                                                      />
                                                      {item.img.length >
                                                        0 && (
                                                          <img
                                                            style={{
                                                              width: 4,
                                                              height: 4
                                                            }}
                                                            src={Dot}
                                                            alt=''
                                                          />
                                                        )}{' '}
                                                    </div>
                                                  )}
                                              </>
                                            )}

                                          {item.taskHeading == 0 && (
                                            <>
                                              {item.img.length >
                                                0 && (
                                                  <div
                                                    style={{
                                                      fontSize: mobileView ? 16 : 12,
                                                      fontWeight: 400,
                                                      display: 'flex',
                                                      alignItems:
                                                        'center',
                                                      gap: 5
                                                    }}
                                                  >
                                                    <img
                                                      style={{
                                                        filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                                        width: 13,
                                                        height: 13
                                                      }}
                                                      src={
                                                        AddFlieIcon
                                                      }
                                                      alt=''
                                                    />
                                                    <span>
                                                      Files attached
                                                    </span>
                                                  </div>
                                                )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className='taskLineImages'>
                                        {/* <img
                                                onClick={() =>
                                                  openTask(item, index)
                                                }
                                                style={{
                                                  cursor: 'pointer'
                                                }}
                                                src={More}
                                              /> */}
                                        <div id='more'>
                                          <Button
                                            ref={
                                              anchorTaskMenuListRef
                                            }
                                            id='basic-button'
                                            aria-controls={
                                              openMenuList
                                                ? 'basic-menu'
                                                : undefined
                                            }
                                            aria-haspopup='true'
                                            aria-expanded={
                                              openMenuList
                                                ? 'true'
                                                : undefined
                                            }
                                            onClick={e => {
                                              e.stopPropagation()
                                              handleClickMenuList(e)
                                              //setTaskItem({})
                                              setTimeout(() => {
                                                setTaskItem(item)
                                                taskItemRef.current = item
                                              }, 1);
                                              try {
                                                setLastCommentDivShow(false);
                                                setTimeout(() => {
                                                  commentDivRef.current.scrollTop = 5;
                                                  commentDivRef.current.scrollTop = 0;
                                                }, 200);

                                              }
                                              catch (ex) {

                                              }
                                              setTaskIndex(index)
                                              forEditTextTaskNameFalse(
                                                item
                                              )
                                              // setTaskSectionOpen(true)
                                              setTaskCompletedLeftClicked(
                                                false
                                              )
                                              if (taskLeftClicked) {
                                                setTaskLeftClicked(
                                                  false
                                                )
                                              } else {
                                                setTaskLeftClicked(
                                                  true
                                                )
                                                handleRightClick(e, item)
                                              }
                                              //handleTaskMenuListToggle()

                                              // handleDimension(
                                              //   e.pageX,
                                              //   e.pageY
                                              // )

                                              setDataItem(item)
                                              setDataItemIndex(
                                                index
                                              )
                                              setColorPalette(false)
                                            }}
                                            // onDoubleClick={() =>
                                            //   forEditTextTaskName(
                                            //     item
                                            //   )
                                            // }
                                            className='settingButton whiteDots'
                                          >
                                            {/* <img style={{ width: 22 }} src={WhiteDots} /> */}
                                            <img
                                              style={{
                                                width: 24,
                                                cursor: 'pointer'
                                              }}
                                              src={More}
                                            />
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              ))}
                            </>
                          }



                          {/* </DragDropContext> */}



                          <>
                            <MuiMenu
                              id='basic-trash-menu'
                              anchorEl={trashAnchorEl}
                              open={openTrashMenuList}
                              // onClose={handleCloseMenuList}
                              MenuListProps={{
                                'aria-labelledby': 'basic-trash-button'
                              }}
                              onContextMenu={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                setMenuPosition(null)
                                handleCloseTrashMenuList()
                              }}
                              onClose={e => {
                                setMenuPosition(null)
                                handleCloseTrashMenuList()
                              }}
                              anchorReference='anchorPosition'
                              anchorPosition={menuPosition}
                            >
                              <div
                                className='closeIconDiv'
                                onClick={e => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setMenuPosition(null)
                                  handleCloseTrashMenuList()
                                }}
                              >
                                <img src={CloseMenuListIcon} alt='' />
                              </div>
                              {dataItem && dataItem.project && dataItem.groupName ?
                                <>
                                  <MuiMenuItem
                                    onClick={e => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      setMenuPosition(null)
                                      handleCloseTrashMenuList()
                                      // restoreList(dataItem)
                                      restoreProject(dataItem)
                                      setMenuPosition(null)
                                    }}
                                    style={{
                                      padding: 0
                                    }}
                                  >
                                    <div className='settingModalItems '>
                                      <img
                                        style={{
                                          width: 16,
                                          cursor: 'pointer'
                                        }}
                                        src={Restore}
                                      />
                                      <span>Restore folder</span>
                                    </div>
                                  </MuiMenuItem>
                                  <MuiMenuItem
                                    onClick={e => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      setMenuPosition(null)
                                      deleteGroup(dataItem)
                                      handleCloseTrashMenuList()
                                    }}
                                    style={{
                                      padding: 0
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: 'red',
                                        borderTopWidth: 0.5,
                                        borderTopColor: 'lightgray',
                                        borderTopStyle: 'solid'
                                      }}
                                      onClick={() => {
                                        handleDeleteConfirm(dataItem)
                                      }}
                                      className='settingModalItems'
                                    >
                                      <img src={RedDelete} alt='' />
                                      <span>Move Folder to Trash</span>
                                    </div>
                                  </MuiMenuItem>
                                </>
                                :
                                <>
                                  <MuiMenuItem
                                    onClick={e => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      setMenuPosition(null)
                                      handleCloseTrashMenuList()
                                      restoreList(dataItem)
                                      setMenuPosition(null)
                                    }}
                                    style={{
                                      padding: 0
                                    }}
                                  >
                                    <div className='settingModalItems '>
                                      <img
                                        style={{
                                          width: 16,
                                          cursor: 'pointer'
                                        }}
                                        src={Restore}
                                      />
                                      <span>Restore list</span>
                                    </div>
                                  </MuiMenuItem>
                                  <MuiMenuItem
                                    onClick={e => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      setMenuPosition(null)
                                      handleCloseTrashMenuList()
                                    }}
                                    style={{
                                      padding: 0
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: 'red',
                                        borderTopWidth: 0.5,
                                        borderTopColor: 'lightgray',
                                        borderTopStyle: 'solid'
                                      }}
                                      onClick={() =>
                                        handleProjectDeleteConfirm(dataItem)
                                      }
                                      className='settingModalItems'
                                    >
                                      <img src={RedDelete} alt='' />
                                      <span>Move List to Trash</span>
                                    </div>
                                  </MuiMenuItem>
                                </>
                              }
                            </MuiMenu>

                          </>

                          {getCompletedCount() > 0 && (
                            <>
                              {taskData &&
                                taskData.length > 0 &&
                                taskData.map((item1, index1) => (
                                  <div>
                                    {searchBarInputValue != '' ? null :
                                      <div className='completedTaskDiv' style={{ marginTop: 25 }}>
                                        <div
                                          className='completeTag' style={{ background: colorSchemes.WebLeftPaneBg }}
                                          onClick={() => completeTaskList()}
                                        >
                                          <img
                                            className={
                                              completeTaskShow
                                                ? 'rightArrow'
                                                : 'downArrow'
                                            }
                                            style={{ cursor: 'pointer', width: 24 }}
                                            src={CompletedArrowDown}
                                          />
                                          Completed<span style={{ fontWeight: "normal", color: "#fff" }}>({getCompletedCount()})</span>
                                        </div>
                                        {/* listItem.userId == userId ? */
                                          <div className='settingButton' style={{ borderRadius: 4 }}>
                                            <img
                                              ref={anchorCompletedMenuListRef}
                                              onClick={event => {
                                                event.stopPropagation()
                                                setCompletedMenu(true)
                                              }}
                                              style={{
                                                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                                width: 22,
                                                cursor: 'pointer',
                                                // marginLeft: 10
                                              }}
                                              src={GreyThreedots}
                                            />
                                          </div>
                                        /* :
                                        null */}
                                        {completedMenu && (
                                          <Popper
                                            style={{
                                              top: taskMenuListpoints.y + 10,
                                              left: taskMenuListpoints.x - 180,
                                              zIndex: 99999,
                                              minWidth: 260,
                                              maxWidth: 311,

                                            }}
                                            open={completedMenu}
                                            anchorEl={
                                              anchorCompletedMenuListRef.current
                                            }
                                            role={undefined}
                                            placement='bottom-end'
                                            transition
                                          // className='popperWidth'
                                          >
                                            {({ TransitionProps, placement }) => (
                                              <Grow
                                                {...TransitionProps}
                                              // style={{
                                              //   transformOrigin:
                                              //     placement === 'bottom'
                                              //       ? 'left top'
                                              //       : 'left bottom'
                                              // }}
                                              >
                                                <Paper style={{ background: colorSchemes.DrawerBackColor }}>
                                                  <ClickAwayListener
                                                    onClickAway={e => {
                                                      e.preventDefault()
                                                      setCompletedMenu(false)
                                                      /*  item.isListOpen =
                                                   false */
                                                      handleTaskMenuListClose(e)
                                                    }}
                                                  >
                                                    <MenuList
                                                      autoFocusItem={taskMenuListOpen}
                                                      id='composition-menu'
                                                      aria-labelledby='composition-button'
                                                    // onKeyDown={handleListKeyDown}
                                                    >
                                                      <div
                                                        className='closeIconDiv'
                                                        onClick={e => {
                                                          e.stopPropagation()
                                                          e.preventDefault()
                                                          setCompletedMenu(false)
                                                          /*  item.isListOpen =
                                                       false */
                                                          handleTaskMenuListClose(e)
                                                        }}
                                                      >
                                                        <img
                                                          src={CloseMenuListIcon}
                                                          alt=''
                                                        />
                                                      </div>

                                                      <MenuItem
                                                        onClick={e => {
                                                          e.stopPropagation()
                                                          e.preventDefault()

                                                          setCompletedMenu(false)

                                                          /*   item.isListOpen =
                                                        false */
                                                          handleTaskMenuListClose(e)
                                                        }}
                                                        style={{
                                                          padding: 0
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            color: 'red'
                                                          }}
                                                          onClick={() => {
                                                            handleDeleteAllConfirm()
                                                          }}
                                                          className='settingModalItems '
                                                        >
                                                          <img src={RedDelete} alt='' />
                                                          <span>
                                                            Move completed task to trash {/* Delete Completed Task */}
                                                          </span>
                                                        </div>
                                                      </MenuItem>
                                                    </MenuList>
                                                  </ClickAwayListener>
                                                </Paper>
                                              </Grow>
                                            )}
                                          </Popper>
                                        )}
                                      </div>
                                    }
                                    {completeTaskShow && (
                                      <>
                                        {searchBarInputValue == '' ?
                                          <Droppable
                                            key={index1}
                                            droppableId={`completedTaskId-${index1}`}
                                            type={`completedTask`}
                                          >
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                // style={getListStyle(snapshot.isDraggingOver)}
                                                {...provided.droppableProps}
                                              >
                                                {item1.map((item, index) => (
                                                  <>

                                                    <Draggable
                                                      type={`completedTask`}
                                                      key={`completedTaskDragId-${item.id}`}
                                                      draggableId={`completedTaskDragId-${item.id}`}
                                                      index={index}
                                                      isDragDisabled={listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? false : true}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <div
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        // style={getItemStyle(
                                                        //   snapshot.isDragging,
                                                        //   provided.draggableProps.style
                                                        // )}
                                                        >
                                                          {item.taskStatus == '1' &&
                                                            selectedProjectID ==
                                                            item.projectId && (

                                                              <>
                                                                {item.isTrash != '2' &&
                                                                  <div>

                                                                    <div
                                                                      className='taskList'
                                                                      onClick={() => {
                                                                        try {
                                                                          sideBarTaskNameInput?.current?.blur()
                                                                          sideBarTaskNoteInputRef?.current?.blur()
                                                                        }
                                                                        catch (ex) {

                                                                        }
                                                                        setTaskItem({})
                                                                        taskItemRef.current = {}
                                                                        setTimeout(() => {
                                                                          setTaskItem(item)
                                                                          taskItemRef.current = item
                                                                        }, 1);
                                                                        try {
                                                                          setLastCommentDivShow(false);
                                                                          setTimeout(() => {
                                                                            commentDivRef.current.scrollTop = 5;
                                                                            commentDivRef.current.scrollTop = 0;
                                                                          }, 200);
                                                                        }
                                                                        catch (ex) {

                                                                        }
                                                                        updateTaskUserActivity(item.id)
                                                                        setTaskIndex(index)
                                                                        setTaskSectionOpen(
                                                                          true
                                                                        )
                                                                        if (mobileView) {
                                                                          middlePaneMobile()
                                                                        }
                                                                        listInputRef?.current?.blur()
                                                                        subTaskInputRef?.current?.blur()
                                                                        setTaskStepInputValue('')
                                                                      }}
                                                                      style={{
                                                                        // background: !mobileView ? snapshot.isDragging ? colorSchemes.DrawerBackColor : item.color != 'white' && taskItem?.id != item.id ? item.color : taskItem?.id == item.id ? colorSchemes.taskActive : colorSchemes.DrawerBackColor :  snapshot.isDragging ? colorSchemes.DrawerBackColor : item.color != 'white'? item.color : colorSchemes.DrawerBackColor,

                                                                        background: snapshot.isDragging ? !mobileView || isLightMode? '#fff':'rgb(33, 33, 33)'/* colorSchemes.DrawerBackColor */ : item.color != 'white' ? item.color : /* taskItem?.id == item.id ? colorSchemes.taskActive : */ /* colorSchemes.DrawerBackColor */ !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : "rgb(33, 33, 33)",


                                                                        border: !mobileView ? /* item.color != 'white' && */ taskItem?.id == item.id ? '2px solid black' : '2px solid transparent' : 'none',

                                                                        width:
                                                                          snapshot.isDragging
                                                                            ? 280
                                                                            : 'auto',

                                                                        color: /* taskItem?.id == item.id ? colorSchemes.FontBlackColor : colorSchemes.FontBlackColor */ !mobileView ? '#172D46' : colorSchemes.FontColor

                                                                      }}
                                                                      // style={{backgroundColor:taskItem?.id == item.id ?'#dadce6':'',paddingLeft:22}}
                                                                      ref={
                                                                        anchorTaskCompletedMenuListRef
                                                                      }
                                                                      // id='composition-button'
                                                                      // aria-controls={
                                                                      //   taskCompletedMenuListOpen
                                                                      //     ? 'composition-menu'
                                                                      //     : undefined
                                                                      // }
                                                                      // aria-expanded={
                                                                      //   taskCompletedMenuListOpen
                                                                      //     ? 'true'
                                                                      //     : undefined
                                                                      // }
                                                                      // aria-haspopup='true'
                                                                      id='basic-complete-button'
                                                                      aria-controls={
                                                                        openCompleteMenuList
                                                                          ? 'basic-complete-menu'
                                                                          : undefined
                                                                      }
                                                                      aria-haspopup='true'
                                                                      aria-expanded={
                                                                        openCompleteMenuList
                                                                          ? 'true'
                                                                          : undefined
                                                                      }
                                                                      onContextMenu={(
                                                                        e: any
                                                                      ) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                        try {
                                                                          sideBarTaskNameInput?.current?.blur()
                                                                          sideBarTaskNoteInputRef?.current?.blur()
                                                                        }
                                                                        catch (ex) {

                                                                        }
                                                                        //setTaskItem({})
                                                                        setTimeout(() => {
                                                                          setTaskItem(item)
                                                                          taskItemRef.current = item
                                                                        }, 1);
                                                                        try {
                                                                          setLastCommentDivShow(false);
                                                                          setTimeout(() => {
                                                                            commentDivRef.current.scrollTop = 5;
                                                                            commentDivRef.current.scrollTop = 0;
                                                                          }, 200);

                                                                        }
                                                                        catch (ex) {

                                                                        }
                                                                        setTaskIndex(index)
                                                                        handleClickCompleteMenuList(
                                                                          e
                                                                        )
                                                                        //e.stopPropagation()
                                                                        handleRightClick(e, item)
                                                                        // setTaskSectionOpen(true)
                                                                        setTaskLeftClicked(
                                                                          false
                                                                        )
                                                                        if (
                                                                          taskCompletedLeftClicked
                                                                        ) {
                                                                          setTaskCompletedLeftClicked(
                                                                            false
                                                                          )
                                                                        } else {
                                                                          setTaskCompletedLeftClicked(
                                                                            true
                                                                          )
                                                                        }
                                                                        // handleTaskCompletedMenuListToggle()

                                                                        // handleDimension(
                                                                        //   e.pageX,
                                                                        //   e.pageY
                                                                        // )
                                                                        try {
                                                                          setDataItemDateTime(new Date(item.createdDateFormated))
                                                                        }
                                                                        catch (ex) {

                                                                        }
                                                                        subTaskInputRef?.current?.blur()
                                                                        setTaskStepInputValue('')
                                                                        setDataItem(item)
                                                                        setDataItemIndex(
                                                                          index
                                                                        )
                                                                      }}
                                                                    >
                                                                      {listItem && listItem.readOnlyStatus == '0' &&
                                                                        <>
                                                                          {item.taskNote ==
                                                                            '0' ? (
                                                                            <>
                                                                              {item.taskHeading ==
                                                                                0 && (
                                                                                  <img
                                                                                    onClick={event => {
                                                                                      event.stopPropagation()
                                                                                      changeStatus(
                                                                                        item
                                                                                      )
                                                                                    }}
                                                                                    style={{
                                                                                      width: !mobileView ? 16 : 16,
                                                                                      cursor:
                                                                                        'pointer'
                                                                                    }}
                                                                                    src={Select}
                                                                                  />
                                                                                )}
                                                                            </>
                                                                          ) : (
                                                                            <img
                                                                              // onClick={event => {
                                                                              //   event.stopPropagation()
                                                                              //   changeStatus(item)
                                                                              // }}
                                                                              style={{
                                                                                width: !mobileView ? 16 : 16
                                                                                // cursor: 'pointer'
                                                                              }}
                                                                              src={TaskNoteIcon}
                                                                            />
                                                                          )}
                                                                        </>
                                                                      }
                                                                      {/* {item.id} {item.name} */}
                                                                      <div className='taskLine'>
                                                                        <div
                                                                          style={{
                                                                            width: '100%',
                                                                            paddingLeft: 5
                                                                          }}
                                                                        >
                                                                          <div
                                                                            className={
                                                                              snapshot.isDragging
                                                                                ? 'editTextTaskName oneLine-ellipsis'
                                                                                : 'editTextTaskName'
                                                                            }
                                                                            style={{
                                                                              paddingRight: 20
                                                                              // fontWeight:600
                                                                            }}
                                                                          >
                                                                            {/* {item.taskName.substring(0,100)} */}
                                                                            {item.isTaskUpdate == '1' && taskUserActivityCount(item.id) > 0 &&
                                                                              <>
                                                                                <img style={{ width: 15 }} className={taskUserActivityNotificationCount(item.id) > 0 ? 'blinking' : ''} src={RedDot} alt="" />
                                                                                &nbsp;
                                                                              </>
                                                                            }

                                                                            <span style={{ textDecoration: 'line-through' }} className={item.color != 'white' ? 'textOutLine' : ''}>
                                                                              {decData(
                                                                                item.taskName,
                                                                                item.isEncrypt
                                                                              ).substring(
                                                                                0,
                                                                                255
                                                                              )}
                                                                            </span>
                                                                            {listItem && listItem.showTaskId == '1' &&
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(100, 120, 199)',
                                                                                  fontWeight:
                                                                                    'bold'
                                                                                }}
                                                                              >
                                                                                &nbsp;({item.taskKey}){' '}
                                                                              </span>
                                                                            }
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              display: 'flex',
                                                                              alignItems: 'center',
                                                                              color: item.color != 'white' ? '#212529' : !mobileView ? 'rgba(23, 45, 70, 1)' : colorSchemes.FontColor /* colorSchemes.FontSemiGrey */,
                                                                            }}
                                                                          >
                                                                            {item.taskNote == 0 &&
                                                                              item.taskHeading == 0 && (
                                                                                <>
                                                                                  {item.task && item.task.length >
                                                                                    0 && (
                                                                                      <div
                                                                                        style={{
                                                                                          display: 'flex',
                                                                                          justifyContent:
                                                                                            'center',
                                                                                          alignItems:
                                                                                            'center',
                                                                                          gap: 6,
                                                                                          marginRight: 6
                                                                                        }}
                                                                                      >
                                                                                        <div
                                                                                          style={{
                                                                                            fontSize: mobileView ? 16 : 12,
                                                                                            fontWeight: 400
                                                                                          }}
                                                                                        >
                                                                                          {statusCount(
                                                                                            item
                                                                                          )}{' '}
                                                                                          of{' '}
                                                                                          {
                                                                                            item.task
                                                                                              .length
                                                                                          }
                                                                                        </div>
                                                                                        {(item && item.notes && item.notes.length > 0 || item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                                          <img
                                                                                            style={{
                                                                                              width: 4,
                                                                                              height: 4
                                                                                            }}
                                                                                            src={Dot}
                                                                                            alt=''
                                                                                          />
                                                                                        )}
                                                                                      </div>
                                                                                    )}
                                                                                </>
                                                                              )}

                                                                            {item.taskNote == 0 &&
                                                                              item.taskHeading == 0 && (
                                                                                <>
                                                                                  {item.notes && item.notes.length >
                                                                                    0 && (
                                                                                      <div
                                                                                        style={{
                                                                                          fontSize: mobileView ? 16 : 12,
                                                                                          fontWeight: 400,
                                                                                          display: 'flex',
                                                                                          alignItems:
                                                                                            'center',
                                                                                          gap: 6,
                                                                                          marginRight: 6
                                                                                        }}
                                                                                      >
                                                                                        <img
                                                                                          style={{
                                                                                            filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                                                                            width: 12,
                                                                                            height: 12
                                                                                          }}
                                                                                          src={!mobileView ? StickyNote : mobileView && isLightMode ? StickyNote : StickyNoteWhite}
                                                                                          alt=''
                                                                                        />
                                                                                        {(item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                                          <img
                                                                                            style={{
                                                                                              width: 4,
                                                                                              height: 4
                                                                                            }}
                                                                                            src={Dot}
                                                                                            alt=''
                                                                                          />
                                                                                        )}{' '}
                                                                                      </div>
                                                                                    )}
                                                                                </>
                                                                              )}

                                                                            {item.taskNote == 0 &&
                                                                              item.taskHeading == 0 && (
                                                                                <>
                                                                                  {item && item.comments && item.comments.length > 0 && (
                                                                                    <div
                                                                                      style={{
                                                                                        fontSize: mobileView ? 16 : 12,
                                                                                        fontWeight: 400,
                                                                                        display: 'flex',
                                                                                        alignItems:
                                                                                          'center',
                                                                                        gap: 6,
                                                                                        marginRight: 6
                                                                                      }}
                                                                                    >
                                                                                      <img
                                                                                        style={{
                                                                                          width: 12,
                                                                                          height: 12
                                                                                        }}
                                                                                        src={!mobileView ? TaskCommentIcon : mobileView && isLightMode ? TaskCommentIcon : TaskCommentWhiteIcon}
                                                                                        alt=''
                                                                                      />
                                                                                      {item && item.img && item.img.length > 0 && (
                                                                                        <img
                                                                                          style={{
                                                                                            width: 4,
                                                                                            height: 4
                                                                                          }}
                                                                                          src={Dot}
                                                                                          alt=''
                                                                                        />
                                                                                      )}{' '}
                                                                                    </div>
                                                                                  )}
                                                                                </>
                                                                              )}


                                                                            {item.taskHeading == 0 && (
                                                                              <>
                                                                                {item.img.length >
                                                                                  0 && (
                                                                                    <div
                                                                                      style={{
                                                                                        fontSize: mobileView ? 16 : 12,
                                                                                        fontWeight: 400,
                                                                                        display: 'flex',
                                                                                        alignItems:
                                                                                          'center',
                                                                                        gap: 5
                                                                                      }}
                                                                                    >
                                                                                      <img
                                                                                        style={{
                                                                                          filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                                                                          width: 13,
                                                                                          height: 13
                                                                                        }}
                                                                                        src={
                                                                                          AddFlieIcon
                                                                                        }
                                                                                        alt=''
                                                                                      />
                                                                                      <span>
                                                                                        Files attached
                                                                                      </span>
                                                                                    </div>
                                                                                  )}
                                                                              </>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                        <div className='taskLineImages'>
                                                                          {/* <img
                                                onClick={() =>
                                                  openTask(item, index)
                                                }
                                                style={{
                                                  cursor: 'pointer'
                                                }}
                                                src={More}
                                              /> */}
                                                                          {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                                                            <div id='more'>
                                                                              <Button
                                                                                ref={
                                                                                  anchorTaskCompletedMenuListRef
                                                                                }
                                                                                id='basic-complete-button'
                                                                                aria-controls={
                                                                                  openCompleteMenuList
                                                                                    ? 'basic-complete-menu'
                                                                                    : undefined
                                                                                }
                                                                                aria-haspopup='true'
                                                                                aria-expanded={
                                                                                  openCompleteMenuList
                                                                                    ? 'true'
                                                                                    : undefined
                                                                                }
                                                                                onClick={e => {
                                                                                  e.stopPropagation()
                                                                                  //setTaskItem({})
                                                                                  setTimeout(() => {
                                                                                    setTaskItem(item)
                                                                                    taskItemRef.current = item
                                                                                  }, 1);
                                                                                  try {
                                                                                    setLastCommentDivShow(false);
                                                                                    setTimeout(() => {
                                                                                      commentDivRef.current.scrollTop = 5;
                                                                                      commentDivRef.current.scrollTop = 0;
                                                                                    }, 200);

                                                                                  }
                                                                                  catch (ex) {

                                                                                  }
                                                                                  setTaskIndex(
                                                                                    index
                                                                                  )
                                                                                  handleClickCompleteMenuList(
                                                                                    e
                                                                                  )
                                                                                  //e.stopPropagation()
                                                                                  handleRightClick(e, item)
                                                                                  // setTaskSectionOpen(true)
                                                                                  setTaskLeftClicked(
                                                                                    false
                                                                                  )
                                                                                  if (
                                                                                    taskCompletedLeftClicked
                                                                                  ) {
                                                                                    setTaskCompletedLeftClicked(
                                                                                      false
                                                                                    )
                                                                                  } else {
                                                                                    setTaskCompletedLeftClicked(
                                                                                      true
                                                                                    )
                                                                                  }
                                                                                  // handleTaskCompletedMenuListToggle()

                                                                                  // handleDimension(
                                                                                  //   e.pageX,
                                                                                  //   e.pageY
                                                                                  // )
                                                                                  try {
                                                                                    setDataItemDateTime(new Date(item.createdDateFormated))
                                                                                  }
                                                                                  catch (ex) {

                                                                                  }
                                                                                  setDataItem(
                                                                                    item
                                                                                  )
                                                                                  setDataItemIndex(
                                                                                    index
                                                                                  )
                                                                                }}
                                                                                className='settingButton whiteDots'
                                                                              >
                                                                                {/* <img style={{ width: 22 }} src={WhiteDots} /> */}
                                                                                <img
                                                                                  style={{
                                                                                    width: 24,
                                                                                    cursor:
                                                                                      'pointer'
                                                                                  }}
                                                                                  src={More}
                                                                                />
                                                                              </Button>
                                                                            </div>
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                }
                                                              </>
                                                            )}
                                                          {provided.placeholder}
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  </>
                                                ))}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                          :
                                          <>
                                            {item1.map((item, index) => (
                                              <>
                                                {item.taskStatus == '1' && item.taskName && searchBarInputValue && decData(item.taskName, item.isEncrypt).toLowerCase().includes(searchBarInputValue) && (
                                                  <>
                                                    {item.isTrash != '2' && item.taskHeading == 0 && getTaskLoaction(item) && getTaskLoaction(item) != "" &&
                                                      <div>
                                                        <div
                                                          className='taskList'
                                                          onClick={() => {
                                                            setTaskItem({})
                                                            taskItemRef.current = {}
                                                            setTimeout(() => {
                                                              setTaskItem(item)
                                                              taskItemRef.current = item
                                                            }, 1);
                                                            try {
                                                              setLastCommentDivShow(false);
                                                              setTimeout(() => {
                                                                commentDivRef.current.scrollTop = 5;
                                                                commentDivRef.current.scrollTop = 0;
                                                              }, 200);
                                                            }
                                                            catch (ex) {

                                                            }
                                                            updateTaskUserActivity(item.id)
                                                            setTaskIndex(index)
                                                            setTaskSectionOpen(
                                                              true
                                                            )
                                                            if (mobileView) {
                                                              middlePaneMobile()
                                                            }
                                                            listInputRef?.current?.blur()
                                                            subTaskInputRef?.current?.blur()
                                                            setTaskStepInputValue('')
                                                          }}
                                                          style={{

                                                            background: item.color != 'white' ? item.color : taskItem?.id == item.id ? colorSchemes.taskActive : colorSchemes.DrawerBackColor,


                                                            border: !mobileView ? item.color != 'white' && taskItem?.id == item.id ? '2px solid black' : '2px solid transparent' : 'none',

                                                            width: 'auto',

                                                            color: taskItem?.id == item.id ? colorSchemes.FontBlackColor : colorSchemes.FontBlackColor

                                                          }}
                                                          ref={
                                                            anchorTaskCompletedMenuListRef
                                                          }
                                                          id='basic-complete-button'
                                                          aria-controls={
                                                            openCompleteMenuList
                                                              ? 'basic-complete-menu'
                                                              : undefined
                                                          }
                                                          aria-haspopup='true'
                                                          aria-expanded={
                                                            openCompleteMenuList
                                                              ? 'true'
                                                              : undefined
                                                          }
                                                          onContextMenu={(
                                                            e: any
                                                          ) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            setTimeout(() => {
                                                              setTaskItem(item)
                                                              taskItemRef.current = item
                                                            }, 1);
                                                            try {
                                                              setLastCommentDivShow(false);
                                                              setTimeout(() => {
                                                                commentDivRef.current.scrollTop = 5;
                                                                commentDivRef.current.scrollTop = 0;
                                                              }, 200);

                                                            }
                                                            catch (ex) {

                                                            }
                                                            setTaskIndex(index)
                                                            handleClickCompleteMenuList(
                                                              e
                                                            )
                                                            handleRightClick(e, item)
                                                            setTaskLeftClicked(
                                                              false
                                                            )
                                                            if (
                                                              taskCompletedLeftClicked
                                                            ) {
                                                              setTaskCompletedLeftClicked(
                                                                false
                                                              )
                                                            } else {
                                                              setTaskCompletedLeftClicked(
                                                                true
                                                              )
                                                            }
                                                            try {
                                                              setDataItemDateTime(new Date(item.createdDateFormated))
                                                            }
                                                            catch (ex) {

                                                            }
                                                            subTaskInputRef?.current?.blur()
                                                            setTaskStepInputValue('')
                                                            setDataItem(item)
                                                            setDataItemIndex(
                                                              index
                                                            )
                                                          }}
                                                        >
                                                          {item.taskNote ==
                                                            '0' ? (
                                                            <>
                                                              {item.taskHeading ==
                                                                0 && (
                                                                  <img
                                                                    onClick={event => {
                                                                      event.stopPropagation()
                                                                      changeStatus(
                                                                        item
                                                                      )
                                                                    }}
                                                                    style={{
                                                                      width: !mobileView ? 16 : 23,
                                                                      cursor:
                                                                        'pointer'
                                                                    }}
                                                                    src={Select}
                                                                  />
                                                                )}
                                                            </>
                                                          ) : (
                                                            <img
                                                              style={{
                                                                width: !mobileView ? 16 : 23
                                                              }}
                                                              src={TaskNoteIcon}
                                                            />
                                                          )}
                                                          <div className='taskLine'>
                                                            <div
                                                              style={{
                                                                width: '100%',
                                                                paddingLeft: 5
                                                              }}
                                                            >
                                                              <div
                                                                className='editTextTaskName'
                                                                style={{
                                                                  paddingRight: 20
                                                                }}
                                                              >
                                                                {item.isTaskUpdate == '1' && taskUserActivityCount(item.id) > 0 &&
                                                                  <>
                                                                    <img style={{ width: 15 }} className={taskUserActivityNotificationCount(item.id) > 0 ? 'blinking' : ''} src={RedDot} alt="" />
                                                                    &nbsp;
                                                                  </>
                                                                }

                                                                <span style={{ textDecoration: 'line-through' }} className={item.color != 'white' ? 'textOutLine' : ''}>
                                                                  {decData(
                                                                    item.taskName,
                                                                    item.isEncrypt
                                                                  ).substring(
                                                                    0,
                                                                    255
                                                                  )}
                                                                </span>
                                                                {listItem && listItem.showTaskId == '1' &&
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        'rgb(100, 120, 199)',
                                                                      fontWeight:
                                                                        'bold'
                                                                    }}
                                                                  >
                                                                    &nbsp;({item.taskKey}){' '}
                                                                  </span>
                                                                }
                                                              </div>


                                                              <div
                                                                style={{
                                                                  display: 'flex',
                                                                  alignItems: 'center',
                                                                  color: item.color != 'white' ? '#212529' : colorSchemes.FontSemiGrey,
                                                                }}
                                                              >

                                                                <div
                                                                  style={{
                                                                    fontSize: mobileView ? 16 : 12,
                                                                    fontWeight: 400,
                                                                    display: 'flex',
                                                                    alignItems:
                                                                      'center',
                                                                    gap: 5
                                                                  }}
                                                                >
                                                                  <span>
                                                                    {getTaskLoaction(item)}
                                                                  </span>
                                                                  {(item.task && item.task.length > 0 && item.taskNote == 0) || (item.notes && item.notes.length > 0 && item.taskNote == 0) || (item && item.comments && item.comments.length > 0 && item.taskNote == 0) || (item.img && item.img.length > 0 && item.taskNote == 0) ?
                                                                    <img
                                                                      style={{
                                                                        width: 4,
                                                                        height: 4,
                                                                        marginRight: 6
                                                                      }}
                                                                      src={Dot}
                                                                      alt=''
                                                                    />
                                                                    : null}
                                                                </div>

                                                                {item.taskNote == 0 &&
                                                                  item.taskHeading == 0 && (
                                                                    <>
                                                                      {item.task && item.task.length >
                                                                        0 && (
                                                                          <div
                                                                            style={{
                                                                              display: 'flex',
                                                                              justifyContent:
                                                                                'center',
                                                                              alignItems:
                                                                                'center',
                                                                              gap: 6,
                                                                              marginRight: 6
                                                                            }}
                                                                          >
                                                                            <div
                                                                              style={{
                                                                                fontSize: mobileView ? 16 : 12,
                                                                                fontWeight: 400
                                                                              }}
                                                                            >
                                                                              {statusCount(
                                                                                item
                                                                              )}{' '}
                                                                              of{' '}
                                                                              {
                                                                                item.task
                                                                                  .length
                                                                              }
                                                                            </div>
                                                                            {(item && item.notes && item.notes.length > 0 || item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                              <img
                                                                                style={{
                                                                                  width: 4,
                                                                                  height: 4
                                                                                }}
                                                                                src={Dot}
                                                                                alt=''
                                                                              />
                                                                            )}
                                                                          </div>
                                                                        )}
                                                                    </>
                                                                  )}

                                                                {item.taskNote == 0 &&
                                                                  item.taskHeading == 0 && (
                                                                    <>
                                                                      {item.notes && item.notes.length >
                                                                        0 && (
                                                                          <div
                                                                            style={{
                                                                              fontSize: mobileView ? 16 : 12,
                                                                              fontWeight: 400,
                                                                              display: 'flex',
                                                                              alignItems:
                                                                                'center',
                                                                              gap: 6,
                                                                              marginRight: 6
                                                                            }}
                                                                          >
                                                                            <img
                                                                              style={{
                                                                                filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                                                                width: 12,
                                                                                height: 12
                                                                              }}
                                                                              src={!mobileView ? StickyNote : mobileView && isLightMode ? StickyNote : StickyNoteWhite}
                                                                              alt=''
                                                                            />
                                                                            {(item && item.comments && item.comments.length > 0 || item && item.img && item.img.length > 0) && (
                                                                              <img
                                                                                style={{
                                                                                  width: 4,
                                                                                  height: 4
                                                                                }}
                                                                                src={Dot}
                                                                                alt=''
                                                                              />
                                                                            )}{' '}
                                                                          </div>
                                                                        )}
                                                                    </>
                                                                  )}

                                                                {item.taskNote == 0 &&
                                                                  item.taskHeading == 0 && (
                                                                    <>
                                                                      {item && item.comments && item.comments.length > 0 && (
                                                                        <div
                                                                          style={{
                                                                            fontSize: mobileView ? 16 : 12,
                                                                            fontWeight: 400,
                                                                            display: 'flex',
                                                                            alignItems:
                                                                              'center',
                                                                            gap: 6,
                                                                            marginRight: 6
                                                                          }}
                                                                        >
                                                                          <img
                                                                            style={{
                                                                              width: 12,
                                                                              height: 12
                                                                            }}
                                                                            src={!mobileView ? TaskCommentIcon : mobileView && isLightMode ? TaskCommentIcon : TaskCommentWhiteIcon}
                                                                            alt=''
                                                                          />
                                                                          {item && item.img && item.img.length > 0 && (
                                                                            <img
                                                                              style={{
                                                                                width: 4,
                                                                                height: 4
                                                                              }}
                                                                              src={Dot}
                                                                              alt=''
                                                                            />
                                                                          )}{' '}
                                                                        </div>
                                                                      )}
                                                                    </>
                                                                  )}


                                                                {item.taskHeading == 0 && (
                                                                  <>
                                                                    {item.img.length >
                                                                      0 && (
                                                                        <div
                                                                          style={{
                                                                            fontSize: mobileView ? 16 : 12,
                                                                            fontWeight: 400,
                                                                            display: 'flex',
                                                                            alignItems:
                                                                              'center',
                                                                            gap: 5
                                                                          }}
                                                                        >
                                                                          <img
                                                                            style={{
                                                                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                                                              width: 13,
                                                                              height: 13
                                                                            }}
                                                                            src={
                                                                              AddFlieIcon
                                                                            }
                                                                            alt=''
                                                                          />
                                                                          <span>
                                                                            Files attached
                                                                          </span>
                                                                        </div>
                                                                      )}
                                                                  </>
                                                                )}
                                                              </div>

                                                            </div>
                                                            <div className='taskLineImages'>
                                                              <div id='more'>
                                                                <Button
                                                                  ref={
                                                                    anchorTaskCompletedMenuListRef
                                                                  }
                                                                  id='basic-complete-button'
                                                                  aria-controls={
                                                                    openCompleteMenuList
                                                                      ? 'basic-complete-menu'
                                                                      : undefined
                                                                  }
                                                                  aria-haspopup='true'
                                                                  aria-expanded={
                                                                    openCompleteMenuList
                                                                      ? 'true'
                                                                      : undefined
                                                                  }
                                                                  onClick={e => {
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                      setTaskItem(item)
                                                                      taskItemRef.current = item
                                                                    }, 1);
                                                                    try {
                                                                      setLastCommentDivShow(false);
                                                                      setTimeout(() => {
                                                                        commentDivRef.current.scrollTop = 5;
                                                                        commentDivRef.current.scrollTop = 0;
                                                                      }, 200);

                                                                    }
                                                                    catch (ex) {

                                                                    }
                                                                    setTaskIndex(
                                                                      index
                                                                    )
                                                                    handleClickCompleteMenuList(
                                                                      e
                                                                    )
                                                                    handleRightClick(e, item)
                                                                    setTaskLeftClicked(
                                                                      false
                                                                    )
                                                                    if (
                                                                      taskCompletedLeftClicked
                                                                    ) {
                                                                      setTaskCompletedLeftClicked(
                                                                        false
                                                                      )
                                                                    } else {
                                                                      setTaskCompletedLeftClicked(
                                                                        true
                                                                      )
                                                                    }
                                                                    try {
                                                                      setDataItemDateTime(new Date(item.createdDateFormated))
                                                                    }
                                                                    catch (ex) {

                                                                    }
                                                                    setDataItem(
                                                                      item
                                                                    )
                                                                    setDataItemIndex(
                                                                      index
                                                                    )
                                                                  }}
                                                                  className='settingButton whiteDots'
                                                                >
                                                                  <img
                                                                    style={{
                                                                      width: 24,
                                                                      cursor:
                                                                        'pointer'
                                                                    }}
                                                                    src={More}
                                                                  />
                                                                </Button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    }
                                                  </>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        }
                                      </>
                                    )}
                                  </div>
                                ))}


                              {!mobileView ?
                                <>
                                  {taskCompletedLeftClicked && (
                                    <MuiMenu
                                      id='basic-complete-menu'
                                      anchorEl={completeAnchorEl}
                                      open={openCompleteMenuList}
                                      // onClose={handleCloseMenuList}
                                      MenuListProps={{
                                        'aria-labelledby': 'basic-complete-button'
                                      }}
                                      onContextMenu={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskCompletedLeftClicked(false)
                                        setMenuPosition(null)
                                        handleCloseCompleteMenuList()
                                      }}
                                      onClose={e => {
                                        setTaskCompletedLeftClicked(false)
                                        setMenuPosition(null)
                                        handleCloseCompleteMenuList()
                                      }}
                                      anchorReference='anchorPosition'
                                      anchorPosition={menuPosition}
                                    >
                                      <div
                                        className='closeIconDiv'
                                        onClick={e => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          setTaskCompletedLeftClicked(false)
                                          setMenuPosition(null)
                                          handleCloseCompleteMenuList()
                                        }}
                                      >
                                        <img src={CloseMenuListIcon} alt='' />
                                      </div>
                                      {dataItem.userid == userId ?
                                        <>
                                          <MuiMenuItem
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              changeStatus(dataItem)
                                              //inputFileRef?.current?.click()
                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div className='settingModalItems '>
                                              <img
                                                style={{
                                                  width: 16,
                                                  cursor: 'pointer'
                                                }}
                                                src={Select}
                                              />
                                              {dataItem.taskNote == 0 ?
                                                <span>Undo task</span>
                                                :
                                                <span>Undo note</span>
                                              }
                                            </div>
                                          </MuiMenuItem>
                                          {dataItemDateTime &&
                                            <div style={{ fontSize: 12, display: 'flex', justifyContent: 'flex-end', margin: 4, marginTop: -4 }}>
                                              {dataItem.createdUserId != "0" ?
                                                <span>{Base64.decode(dataItem.userFirstName)}&nbsp;{Base64.decode(dataItem.userLastName)}</span>
                                                :
                                                <span>{Base64.decode(dataItem.taskUserFirstName)}&nbsp;{Base64.decode(dataItem.taskUserLastName)}</span>
                                              }&nbsp;- {dataItemDateTime.toLocaleDateString('en-US', {
                                                month: 'numeric',
                                                day: '2-digit',
                                                year: '2-digit',
                                              })} - {dataItemDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                                            </div>
                                          }
                                          <MuiMenuItem
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              handleClickSnack(SlideTransition);
                                              // if (
                                              //   currentDate == localStorage.getItem('date')
                                              // ) {
                                              //   if(dataItem.isTrash != "2"){
                                              moveToTrash(dataItem)
                                              setDataTrashItem(dataItem)
                                              //   }
                                              //   else{
                                              //   deleteTask(dataItem)
                                              // }
                                              // } else {
                                              //   handleDeleteConfirm(dataItem)
                                              // }

                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div
                                              style={{
                                                color: 'red',
                                                borderTopWidth: 0.5,
                                                borderTopColor: 'lightgray',
                                                borderTopStyle: 'solid'
                                              }}
                                              className='settingModalItems '
                                            >
                                              <img src={RedDelete} alt='' />
                                              {dataItem.taskNote == 0 ?
                                                <span>Move to Trash{/* Delete task */}</span>
                                                :
                                                <span>Move to Trash{/* Delete note */}</span>
                                              }

                                            </div>
                                          </MuiMenuItem>
                                        </>
                                        :
                                        <>
                                          <MuiMenuItem
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              changeStatus(dataItem)
                                              //inputFileRef?.current?.click()
                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div className='settingModalItems '>
                                              <img
                                                style={{
                                                  width: 16,
                                                  cursor: 'pointer'
                                                }}
                                                src={Select}
                                              />
                                              {dataItem.taskNote == 0 ?
                                                <span>Undo task</span>
                                                :
                                                <span>Undo note</span>
                                              }
                                            </div>
                                          </MuiMenuItem>
                                          {dataItemDateTime &&
                                            <div style={{ fontSize: 12, display: 'flex', justifyContent: 'flex-end', margin: 4, marginTop: -4 }}>
                                              {dataItem.createdUserId != "0" ?
                                                <span>{Base64.decode(dataItem.userFirstName)}&nbsp;{Base64.decode(dataItem.userLastName)}</span>
                                                :
                                                <span>{Base64.decode(dataItem.taskUserFirstName)}&nbsp;{Base64.decode(dataItem.taskUserLastName)}</span>
                                              }&nbsp;- {dataItemDateTime.toLocaleDateString('en-US', {
                                                month: 'numeric',
                                                day: '2-digit',
                                                year: '2-digit',
                                              })} - {dataItemDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                                            </div>
                                          }
                                          <MuiMenuItem
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              handleClickSnack(SlideTransition);
                                              // if (
                                              //   currentDate == localStorage.getItem('date')
                                              // ) {
                                              //   if(dataItem.isTrash != "2"){
                                              moveToTrash(dataItem)
                                              setDataTrashItem(dataItem)
                                              //   }
                                              //   else{
                                              //   deleteTask(dataItem)
                                              // }
                                              // } else {
                                              //   handleDeleteConfirm(dataItem)
                                              // }

                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div
                                              style={{
                                                color: 'red',
                                                borderTopWidth: 0.5,
                                                borderTopColor: 'lightgray',
                                                borderTopStyle: 'solid'
                                              }}
                                              className='settingModalItems '
                                            >
                                              <img src={RedDelete} alt='' />
                                              {dataItem.taskNote == 0 ?
                                                <span>Move to Trash{/* Delete task */}</span>
                                                :
                                                <span>Move to Trash{/* Delete note */}</span>
                                              }

                                            </div>
                                          </MuiMenuItem>
                                        </>
                                      }
                                    </MuiMenu>
                                  )}
                                </>
                                :
                                <SwipeableDrawer
                                  anchor="bottom"
                                  open={taskCompletedLeftClicked}
                                  onClose={() => setTaskCompletedLeftClicked(false)}
                                  onOpen={() => setTaskCompletedLeftClicked(true)}
                                  className='mainDrawerContainer'
                                >
                                  <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                                    <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                                      <div ></div>
                                    </div>
                                    <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                                      <div style={{ width: '20%' }}></div>
                                      <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                                        Task Options
                                      </div>
                                      <div className='drawerHeadingDone' onClick={() => setTaskCompletedLeftClicked(false)}>Done</div>
                                    </div>
                                    <div style={{ background: colorSchemes.DrawerBackColor, color: colorSchemes.FontSemiGrey, paddingBottom: 40 }}>
                                      {listItem.userId == userId ?
                                        <>
                                          <div
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              changeStatus(dataItem)
                                              //inputFileRef?.current?.click()
                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div className='settingModalItems '>
                                              <img
                                                style={{
                                                  width: 16,
                                                  cursor: 'pointer'
                                                }}
                                                src={Select}
                                              />
                                              {dataItem.taskNote == 0 ?
                                                <span>Undo task</span>
                                                :
                                                <span>Undo note</span>
                                              }
                                            </div>
                                          </div>

                                          <div
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              handleClickSnack(SlideTransition);
                                              // if (
                                              //   currentDate == localStorage.getItem('date')
                                              // ) {
                                              //   if(dataItem.isTrash != "2"){
                                              moveToTrash(dataItem)
                                              setDataTrashItem(dataItem)
                                              //   }
                                              //   else{
                                              //   deleteTask(dataItem)
                                              // }
                                              // } else {
                                              //   handleDeleteConfirm(dataItem)
                                              // }

                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div
                                              style={{
                                                color: 'red',
                                                // borderTopWidth: 0.5,
                                                // borderTopColor: 'lightgray',
                                                // borderTopStyle: 'solid'
                                              }}
                                              className='settingModalItems '
                                            >
                                              <img src={RedDelete} alt='' />
                                              {dataItem.taskNote == 0 ?
                                                <span>Move to Trash{/* Delete task */}</span>
                                                :
                                                <span>Move to Trash{/* Delete note */}</span>
                                              }

                                            </div>
                                          </div>
                                        </>
                                        :
                                        <>
                                          <div
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              changeStatus(dataItem)
                                              //inputFileRef?.current?.click()
                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div className='settingModalItems '>
                                              <img
                                                style={{
                                                  width: 16,
                                                  cursor: 'pointer'
                                                }}
                                                src={Select}
                                              />
                                              {dataItem.taskNote == 0 ?
                                                <span>Undo task</span>
                                                :
                                                <span>Undo note</span>
                                              }
                                            </div>
                                          </div>
                                          <div
                                            onClick={e => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                              setTaskCompletedLeftClicked(false)
                                              setMenuPosition(null)
                                              handleCloseCompleteMenuList()
                                              handleClickSnack(SlideTransition);
                                              // if (
                                              //   currentDate == localStorage.getItem('date')
                                              // ) {
                                              //   if(dataItem.isTrash != "2"){
                                              moveToTrash(dataItem)
                                              setDataTrashItem(dataItem)
                                              //   }
                                              //   else{
                                              //   deleteTask(dataItem)
                                              // }
                                              // } else {
                                              //   handleDeleteConfirm(dataItem)
                                              // }

                                            }}
                                            style={{
                                              padding: 0
                                            }}
                                          >
                                            <div
                                              style={{
                                                color: 'red',
                                                // borderTopWidth: 0.5,
                                                // borderTopColor: 'lightgray',
                                                // borderTopStyle: 'solid'
                                              }}
                                              className='settingModalItems '
                                            >
                                              <img src={RedDelete} alt='' />
                                              {dataItem.taskNote == 0 ?
                                                <span>Move to Trash{/* Delete task */}</span>
                                                :
                                                <span>Move to Trash{/* Delete note */}</span>
                                              }

                                            </div>
                                          </div>
                                        </>
                                      }
                                    </div>
                                  </div>
                                </SwipeableDrawer>
                              }
                            </>
                          )}
                          {searchBarInputValue != '' ? null :
                            <div style={{ height: '200px' }}></div>
                          }
                        </div>
                      </div>
                    </div>
                    {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' && searchBarInputValue == '' && !mobileView && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                      <div
                        id='taskBar'
                        style={{
                          position: mobileView ? 'fixed' : 'absolute',
                          // background: showIntroScreen? "" : colorSchemes.MiddlePaneBlurBackground,
                          // background: showIntroScreen ? "" : colorSchemes.WebMiddlePaneBlurBg,
                          background: colorSchemes.MiddlePaneBackground,
                          width: mobileView ? "100%" : taskSectionOpen
                            ? 'calc(100% - 280px - 370px)'
                            : 'calc(100% - 280px)',
                          bottom: mobileView ? 0 : 0,
                          backdropFilter: 'blur(3.5px)',
                          padding: mobileView ? taskInputFocus ? '0px' : '10px 10px 50px 10px' : '16px 16px 40px 16px',
                          zIndex: 1
                        }}
                      >
                        <div
                          className='addTask'

                          /* style={{ marginTop: 12,position:'absolute',bottom:15,width:taskSectionOpen?  'calc(100% - 320px - 300px)' : 'calc(100% - 320px)', }} */
                          style={{
                            width: '100%',
                            // backgroundColor: mobileView ? taskInputFocus ? colorSchemes.DrawerBackColor : colorSchemes.AddTaskBackground : '#fff',
                            background: colorSchemes.WebLeftPaneBg,
                            borderRadius: mobileView ? taskInputFocus ? '0' : '' : '',
                            borderTopLeftRadius: mobileView ? taskInputFocus ? '10' : '' : '',
                            borderTopRightRadius: mobileView ? taskInputFocus ? '10' : '' : '',
                          }}
                        >

                          {!mobileView ?
                            <img
                              style={{ width: 20, height: 20, cursor: 'pointer' }}
                              src={AddTaskBarIcon}
                            />
                            :
                            <>
                              {taskInputFocus ?
                                <>
                                  {isDark == 1 ?
                                    <img
                                      style={{ width: 20, height: 20, cursor: 'pointer'/* , transform: "rotate(45deg)" */ }}
                                      src={AddTaskBarIcon}
                                    />
                                    :

                                    <img
                                      style={{ width: 20, height: 20, cursor: 'pointer' }}
                                      src={AddTaskBarIcon}
                                    />
                                  }

                                </>
                                :

                                <img
                                  style={{ width: 20, height: 20, cursor: 'pointer'/* , transform: "rotate(45deg)" */ }}
                                  src={AddTaskBarIcon}
                                />
                              }
                            </>
                          }
                          <input
                            className={isDark == 1 ? 'addTaskInputDark' : 'addTaskInput'}
                            style={mobileView ? taskInputFocus ? { fontSize: 18, color: colorSchemes.FontBlackColor } : { fontSize: 18, color: '#fff' /* colorSchemes.SearchBackColor */ } : {}}
                            type='text'
                            name='name'
                            placeholder={listItem.isNote && listItem.isNote == "1" ? 'Add a Note' : 'Add a Task'}
                            // onClick={() => setTaskDrop(true)}
                            onFocus={() => { if (mobileView && !showIntroScreen) { setTaskInputFocus(true) } }}
                            onBlur={() => { if (mobileView && !showIntroScreen) { setTaskInputFocus(false); addTaskToList() } }}
                            value={taskInputValue}
                            onChange={(e) => { if (!showIntroScreen) { handleChange(e) } }}
                            autoComplete='off'
                            onKeyDown={(e) => { if (!showIntroScreen) { handleKeyDown(e) } }}
                            maxLength={255}
                            onPaste={(e) => {
                              if (!showIntroScreen) {
                                console.log(e.clipboardData.getData('Text'));
                                let impacts = e.clipboardData.getData('Text');
                                const result: any = impacts.split("\n").map((impact, index) => (
                                  encData(`${impact.trim()}`)
                                ));

                                let tempData: any = [];
                                if (result && result.length > 1) {
                                  for (let i = 0; i < result.length; i++) {
                                    if (result[i] != "") {
                                      tempData.push(result[i]);
                                    }
                                  }
                                }

                                console.log("result45", result);
                                if (tempData.length > 1) {
                                  postTaskList(tempData);


                                  setTimeout(() => {
                                    setTaskInputValue('')
                                  }, 200);
                                }
                                setTaskSectionOpen(false)
                              }
                            }
                            }
                          />
                          {taskInputValue.length > 0 && !showIntroScreen && (
                            <div>
                              <div className='addBtn' style={mobileView ? taskInputFocus ? { color: colorSchemes.FontSemiGrey, padding: '2px 6px' } : { color: colorSchemes.FontSemiGrey, padding: '2px 6px' } : {}} onClick={() => { addTaskToList(); setTaskSectionOpen(false) }}>
                                Add
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  </>
                )}
              </div>
              {!mobileView ?
                <>
                  {taskLeftClicked && (
                    <div style={{ position: "fixed", left: 0, top: 0, right: 0, bottom: 0, zIndex: 999 }}
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setTaskLeftClicked(false)
                        handleTaskMenuListClose(e)
                        setMenuPosition(null)
                        handleCloseMenuList()
                      }}
                      onContextMenu={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setTaskLeftClicked(false)
                        handleTaskMenuListClose(e)
                        setMenuPosition(null)
                        handleCloseMenuList()
                      }}>
                      <div style={{ display: "flex", top: menuPosition.top, left: menuPosition.left, position: 'absolute' }}>
                        <Paper elevation={4} >
                          {/* <MuiMenu
                          id='basic-menu'
                          anchorEl={anchorEl}
                          open={openMenuList}
                          // onClose={handleCloseMenuList}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button'
                          }}
                          onContextMenu={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setTaskLeftClicked(false)
                            handleTaskMenuListClose(e)
                            setMenuPosition(null)
                            handleCloseMenuList()
                          }}
                          onClose={e => {
                            handleTaskMenuListClose(e)
                            setMenuPosition(null)
                            setTaskLeftClicked(false)
                            handleCloseMenuList()
                          }}
                          anchorReference='anchorPosition'
                          anchorPosition={menuPosition}
                        > */}
                          <div
                            className='closeIconDiv'
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setTaskLeftClicked(false)
                              handleTaskMenuListClose(e)
                              setMenuPosition(null)
                              handleCloseMenuList()
                            }}
                          >
                            <img src={CloseMenuListIcon} alt='' />
                          </div>
                          <MenuList /*autoFocus={true}*/ style={{ minWidth: 240, maxWidth: 240 }}>
                            {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' ?
                              <>
                                {dataItem.taskHeading == 0 ? (
                                  <>
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        changeStatus(dataItem)
                                        setMenuPosition(null)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            width: 16,
                                            cursor: 'pointer'
                                          }}
                                          src={CheckCircleFill}
                                        />
                                        <span>Mark as done</span>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        if (!taskSectionOpen) {
                                          setTimeout(() => {
                                            setTaskItem(dataItem)
                                            taskItemRef.current = dataItem
                                          }, 1);
                                        }

                                        try {
                                          setLastCommentDivShow(false);
                                          setTimeout(() => {
                                            commentDivRef.current.scrollTop = 5;
                                            commentDivRef.current.scrollTop = 0;
                                          }, 200);

                                        }
                                        catch (ex) {

                                        }
                                        setTaskIndex(dataItemIndex)
                                        setTaskSectionOpen(true)
                                        setMenuPosition(null)
                                        setTimeout(() => {
                                          if (inputFileRef.current)
                                            inputFileRef.current.click()
                                          else {
                                            setTimeout(() => {
                                              inputFileRef.current.click()
                                            }, 100)
                                          }
                                        }, 200)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                            width: 16,
                                            cursor: 'pointer'
                                          }}
                                          src={AddFlieIcon}
                                        />
                                        <span>Add files</span>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        setTaskItem({})
                                        taskItemRef.current = {}
                                        setTimeout(() => {
                                          setTaskItem(dataItem)
                                          taskItemRef.current = dataItem
                                        }, 1);
                                        try {
                                          setLastCommentDivShow(false);
                                          setTimeout(() => {
                                            commentDivRef.current.scrollTop = 5;
                                            commentDivRef.current.scrollTop = 0;
                                          }, 200);

                                        }
                                        catch (ex) {

                                        }
                                        setTaskIndex(dataItemIndex)
                                        setTaskSectionOpen(true)
                                        setMenuPosition(null)
                                        updateTaskUserActivity(dataItem.id)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                            width: 14,
                                            height: 14
                                          }}
                                          src={StickyNote}
                                          alt=''
                                        />
                                        <span>Details</span>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        clearTimeout(timerRef.current);
                                        preventRef.current = true;
                                        setEditList(dataItem)

                                        if (
                                          editList?.id != dataItem?.id
                                        ) {
                                          TaskNameInputRef?.current?.blur()
                                        }


                                        setTaskItem({})
                                        taskItemRef.current = {}
                                        setTimeout(() => {
                                          setTaskItem(dataItem)
                                          taskItemRef.current = dataItem
                                        }, 1);

                                        // setTaskIndex(index)
                                        // if (dataItem.taskHeading == 0) {
                                        //   setTaskSectionOpen(true)
                                        // } else {
                                        //   setTaskSectionOpen(false)
                                        // }
                                        listInputRef?.current?.blur()

                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        setMenuPosition(null)

                                        setTimeout(() => {
                                          preventRef.current = false;
                                          TaskNameInputRef?.current?.focus()
                                          try {
                                            let sel: any =
                                              window.getSelection()
                                            let range =
                                              document.createRange()

                                            range.setStart(
                                              TaskNameInputRef
                                                ?.current
                                                ?.childNodes[0],
                                              dataItem.taskName.length
                                            )
                                            range.collapse(true)
                                            sel.removeAllRanges()
                                            sel.addRange(range)
                                          } catch (ex) { }
                                        }, 100)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            width: 14,
                                            height: 14
                                          }}
                                          src={EditHeadingIcon}
                                          alt=''
                                        />
                                        <span>Edit task</span>
                                      </div>
                                    </MenuItem>
                                    {dataItem.userid != userId ? null :
                                      <MenuItem
                                        onClick={e => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          setTaskLeftClicked(false)
                                          handleTaskMenuListClose(e)
                                          duplicateTask(dataItem)
                                          setMenuPosition(null)
                                        }}
                                        style={{
                                          padding: 0
                                        }}
                                      >
                                        <div className='settingModalItems '>
                                          <img
                                            style={{
                                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                              width: 16,
                                              cursor: 'pointer'
                                            }}
                                            src={DuplicateIcon}
                                          />
                                          {dataItem.taskNote == 0 ? (
                                            <span>Duplicate task</span>
                                          ) : (
                                            <span>Duplicate note</span>
                                          )}
                                        </div>
                                      </MenuItem>
                                    }
                                    {dataItem.taskNote == 0 ? (
                                      <MenuItem
                                        onClick={e => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          setTaskLeftClicked(false)
                                          handleTaskMenuListClose(e)
                                          // duplicateTask(dataItem)
                                          bugFun(dataItem)
                                          setMenuPosition(null)
                                        }}
                                        style={{
                                          padding: 0
                                        }}
                                      >
                                        <div className='settingModalItems '>
                                          <img
                                            style={{
                                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                              width: 16,
                                              cursor: 'pointer'
                                            }}
                                            src={BugIcon}
                                          />
                                          {dataItem.taskBug == '0' ? (
                                            <span>Bug</span>
                                          ) : (
                                            <span>Remove bug</span>
                                          )}
                                        </div>
                                      </MenuItem>
                                    ) : null}
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        // duplicateTask(dataItem)
                                        convertNote(dataItem)
                                        setMenuPosition(null)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                            width: 16,
                                            cursor: 'pointer'
                                          }}
                                          src={ConvertNote}
                                        />
                                        {dataItem.taskNote == '0' ? (
                                          <span>Convert to note</span>
                                        ) : (
                                          <span>Convert to task</span>
                                        )}
                                      </div>
                                    </MenuItem>
                                    {dataItem.taskNote == 0 ? (
                                      <MenuItem
                                        onClick={e => {
                                          e.stopPropagation()
                                          e.preventDefault()
                                          setTaskLeftClicked(false)
                                          handleTaskMenuListClose(e)
                                          // changeStatus(dataItem)
                                          changeTrunIntoHeading(dataItem)
                                          setMenuPosition(null)
                                          // setTaskSectionOpen(false)
                                        }}
                                        style={{
                                          padding: 0
                                        }}
                                      >
                                        <div className='settingModalItems '>
                                          <img
                                            style={{
                                              filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                              width: 14,
                                              cursor: 'pointer',
                                              marginRight: 2
                                            }}
                                            src={TurnIntoHeading}
                                          />
                                          <span>Make heading</span>
                                        </div>
                                      </MenuItem>
                                    ) : null}
                                    {dataItem.userid != userId ? null :
                                      <RecursiveMenuItem autoFocus={false} label={dataItem.taskNote == 0 ? "Move task to..." : "Move note to..."} icon={AddGroupIcon11} arrowIcon={RightBlackArrow}>
                                        <RecursiveMenu />
                                      </RecursiveMenuItem>
                                    }
                                  </>
                                ) : (
                                  <>
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        changeTrunIntoTask(dataItem)
                                        setMenuPosition(null)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            width: 16,
                                            cursor: 'pointer'
                                          }}
                                          src={TurnIntoTask}
                                        />
                                        <span>Turn into task</span>
                                      </div>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        // changeTrunIntoTask(dataItem)
                                        setEditList(dataItem)
                                        setTimeout(() => {
                                          TaskNameInputRef?.current?.focus()
                                          try {
                                            let sel: any = window.getSelection()
                                            let range = document.createRange()

                                            range.setStart(
                                              TaskNameInputRef?.current
                                                ?.childNodes[0],
                                              dataItem.taskName.length
                                            )
                                            range.collapse(true)
                                            sel.removeAllRanges()
                                            sel.addRange(range)
                                          } catch (ex) { }
                                        }, 100)
                                        setMenuPosition(null)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            width: 16,
                                            cursor: 'pointer'
                                          }}
                                          src={EditHeadingIcon}
                                        />
                                        <span>Edit heading</span>
                                      </div>
                                    </MenuItem>
                                  </>
                                )}
                                {/* <MenuItem
                                  className='customCursor'
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setColorPalette(true)
                                    setTaskLeftClicked(false)
                                    setPoints({
                                      x: e.pageX - 150,
                                      y: e.pageY
                                    })
                                    console.log('e.pageY', e.pageY, e.pageX)
                                    handleTaskMenuListClose(e)
                                    setHighlightFrom('task')
                                    setMenuPosition(null)
                                  }}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <div className='settingModalItems'>
                                    <img src={MarkerIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                                    {dataItem.taskNote == 0 ? (
                                      <>
                                        {dataItem.taskHeading == 0 ? (
                                          <span>Highlight task</span>
                                        ) : (
                                          <span>Highlight Heading</span>
                                        )}
                                      </>
                                    ) : (
                                      <span>Highlight note</span>
                                    )}
                                  </div>
                                </MenuItem> */}
                                <RecursiveMenuItem autoFocus={false} label={dataItem.taskNote == 0 ? dataItem.taskHeading == 0 ? "Highlight task" :  "Highlight Heading" : "Highlight note"} icon={MarkerIcon} arrowIcon={RightBlackArrow} onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setColorPalette(true)
                                    setTaskLeftClicked(false)
                                    setPoints({
                                      x: e.pageX - 150,
                                      y: e.pageY
                                    })
                                    console.log('e.pageY', e.pageY, e.pageX)
                                    handleTaskMenuListClose(e)
                                    setHighlightFrom('task')
                                    setMenuPosition(null)
                                  }} >
                                     <Paper elevation={4}>
            <MenuList  style={{ minWidth: 240, maxWidth: 240, maxHeight: windowHeight - 100, overflowY: "auto", backgroundColor: "#fff" }}>
                                <div className='highlightColorDiv' style={{background:'#fff'}}>
                    <span>Choose highlight color</span>
                    <div className='mainColorDiv'>
                      {colors &&
                        colors.map((item, index) => (
                          <div className='colorUpperDiv'>
                            <div
                              className='colorDiv'
                              style={{ background: item.colorCode }}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()                                
                                    setTaskLeftClicked(false)
                                setHighlightFrom('task')                                
                                  taskHighlightColor(item)
                                  //setTaskSectionOpen(true)
                              }}
                            ></div>
                          </div>
                        ))}
                      <div className='colorUpperDiv'>
                        <div
                          className='colorDiv'
                          style={{
                            background: 'transparent',
                            borderWidth: 1,
                            borderColor: '#000',
                            borderStyle: 'solid'
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                                e.preventDefault()
                                setTaskLeftClicked(false)
                                setHighlightFrom('task')                            
                              taskHighlightColor({
                                id: 12,
                                colorCode: 'white'
                              })
                              //setTaskSectionOpen(true)
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </MenuList>
                </Paper>
                                      </RecursiveMenuItem>
                                {dataItemDateTime &&
                                  <div style={{ fontSize: 12, display: 'flex', justifyContent: 'flex-end', margin: 4, marginTop: -4 }}>
                                    {dataItem.createdUserId != "0" ?
                                      <span>{Base64.decode(dataItem.userFirstName)}&nbsp;{Base64.decode(dataItem.userLastName)}</span>
                                      :
                                      <span>{Base64.decode(dataItem.taskUserFirstName)}&nbsp;{Base64.decode(dataItem.taskUserLastName)}</span>
                                    }&nbsp;- {dataItemDateTime.toLocaleDateString('en-US', {
                                      month: 'numeric',
                                      day: '2-digit',
                                      year: '2-digit',
                                    })} - {dataItemDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                                  </div>
                                }

                                {dataItem.userid != userId ? null :
                                  <MenuItem
                                    onClick={e => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                      setTaskLeftClicked(false)
                                      handleTaskMenuListClose(e)
                                      setMenuPosition(null)
                                    }}
                                    style={{
                                      padding: 0
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: 'red',
                                        borderTopWidth: 0.5,
                                        borderTopColor: 'lightgray',
                                        borderTopStyle: 'solid'
                                      }}
                                      onClick={() => {
                                        // handleClick(TransitionUp)
                                        handleClickSnack(SlideTransition);
                                        // setTimeout(() => {
                                        //   handleCloseSnack()
                                        // }, 4000);
                                        // if (
                                        //   currentDate == localStorage.getItem('date')
                                        // ) {
                                        // if(dataItem.isTrash != "2"){
                                        moveToTrash(dataItem)
                                        setDataTrashItem(dataItem)
                                        // }
                                        //   else{
                                        //   deleteTask(dataItem)
                                        // }
                                        // } else {
                                        //   handleDeleteConfirm(dataItem)
                                        // }
                                      }}
                                      className='settingModalItems '
                                    >
                                      <img src={RedDelete} alt='' />
                                      {dataItem.taskNote == 0 ? (
                                        <>
                                          {dataItem.taskHeading == 0 ? (
                                            <span>Move to Trash{/* Delete task */}</span>
                                          ) : (
                                            <span>Move to Trash{/* Delete Heading */}</span>
                                          )}
                                        </>
                                      ) : (
                                        <span>Delete note</span>
                                      )}
                                    </div>
                                  </MenuItem>
                                }
                              </>
                              :
                              <>
                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTaskLeftClicked(false)
                                    handleTaskMenuListClose(e)

                                    // changeTrunIntoTask(dataItem)
                                    handleClickTrashSnack(SlideTransition);
                                    restoreTask(dataItem)
                                    console.log('dataItem89', dataItem)
                                    setMenuPosition(null)
                                  }}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <div className='settingModalItems '>
                                    <img
                                      style={{
                                        width: 16,
                                        cursor: 'pointer'
                                      }}
                                      src={Restore}
                                    />
                                    {dataItem.taskNote == 0 ? (
                                      <>
                                        {dataItem.taskHeading == 0 ? (
                                          <span>Restore task</span>
                                        ) : (
                                          <span>Restore Heading</span>
                                        )}
                                      </>
                                    ) : (
                                      <span>Restore note</span>
                                    )}
                                  </div>
                                </MenuItem>
                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTaskLeftClicked(false)
                                    handleTaskMenuListClose(e)
                                    setMenuPosition(null)
                                  }}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <div
                                    style={{
                                      color: 'red',
                                      borderTopWidth: 0.5,
                                      borderTopColor: 'lightgray',
                                      borderTopStyle: 'solid'
                                    }}
                                    onClick={() => {
                                      // if (
                                      //   currentDate == localStorage.getItem('date')
                                      // ) {
                                      //   if(dataItem.isTrash != "2"){
                                      //     moveToTrash(dataItem)
                                      //   }
                                      //   else{
                                      //   deleteTask(dataItem)
                                      // }
                                      // } else {
                                      handleDeleteConfirm(dataItem)
                                      // }
                                    }}
                                    className='settingModalItems'
                                  >
                                    <img src={RedDelete} alt='' />
                                    {dataItem.taskNote == 0 ? (
                                      <>
                                        {dataItem.taskHeading == 0 ? (
                                          <span>Delete task forever</span>
                                        ) : (
                                          <span>Delete Heading forever</span>
                                        )}
                                      </>
                                    ) : (
                                      <span>Delete note forever</span>
                                    )}
                                  </div>
                                </MenuItem>
                              </>
                            }
                          </MenuList>
                        </Paper>
                      </div>
                      //  {/* </MuiMenu> */}
                    </div>
                  )}
                </>
                :
                <SwipeableDrawer
                  anchor="bottom"
                  open={taskLeftClicked}
                  onClose={() => setTaskLeftClicked(false)}
                  onOpen={() => setTaskLeftClicked(true)}
                  className='mainDrawerContainer'
                >
                  <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                    <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div ></div>
                    </div>
                    <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                      <div style={{ width: '20%' }}></div>
                      <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                        Task Options
                      </div>
                      <div className='drawerHeadingDone' onClick={() => setTaskLeftClicked(false)}>Done</div>
                    </div>
                    {/* <div
                            className='closeIconDiv'
                            onClick={() => {
                              setTaskLeftClicked(false)
                            }}
                          >
                            <img src={CloseMenuListIcon} alt='' />
                          </div> */}
                    <MenuList style={mobileView ? { padding: 0, background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor, paddingBottom: 40 } : {}} >
                      {selectedProjectID != Base64.encode("1") && selectedProjectName != 'Trash' ?
                        <>
                          {dataItem.taskHeading == 0 ? (
                            <>
                              <MenuItem
                                onClick={e => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setTaskLeftClicked(false)
                                  handleTaskMenuListClose(e)
                                  changeStatus(dataItem)
                                  setMenuPosition(null)
                                }}
                                style={{
                                  padding: 0
                                }}
                              >
                                <div className='settingModalItems '>
                                  <img
                                    style={{
                                      width: 16,
                                      cursor: 'pointer'
                                    }}
                                    src={CheckCircleFill}
                                  />
                                  <span>Mark as done</span>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={e => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setTaskLeftClicked(false)
                                  handleTaskMenuListClose(e)
                                  if (!taskSectionOpen) {
                                    setTimeout(() => {
                                      setTaskItem(dataItem)
                                      taskItemRef.current = dataItem
                                    }, 1);
                                  }
                                  try {
                                    setLastCommentDivShow(false);
                                    setTimeout(() => {
                                      commentDivRef.current.scrollTop = 5;
                                      commentDivRef.current.scrollTop = 0;
                                    }, 200);

                                  }
                                  catch (ex) {

                                  }
                                  setTaskIndex(dataItemIndex)
                                  setTaskSectionOpen(true)
                                  if (mobileView) {
                                    middlePaneMobile()
                                  }
                                  setMenuPosition(null)
                                  setTimeout(() => {
                                    if (inputFileRef.current)
                                      inputFileRef.current.click()
                                    else {
                                      setTimeout(() => {
                                        inputFileRef.current.click()
                                      }, 100)
                                    }
                                  }, 200)
                                }}
                                style={{
                                  padding: 0
                                }}
                              >
                                <div className='settingModalItems '>
                                  <img
                                    style={{
                                      filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(300%)',
                                      width: 16,
                                      cursor: 'pointer'
                                    }}
                                    src={AddFlieIcon}
                                  />
                                  <span>Add files</span>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={e => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setTaskLeftClicked(false)
                                  handleTaskMenuListClose(e)
                                  setTaskItem({})
                                  taskItemRef.current = {}
                                  setTimeout(() => {
                                    setTaskItem(dataItem)
                                    taskItemRef.current = dataItem
                                  }, 1);
                                  try {
                                    setLastCommentDivShow(false);
                                    setTimeout(() => {
                                      commentDivRef.current.scrollTop = 5;
                                      commentDivRef.current.scrollTop = 0;
                                    }, 200);

                                  }
                                  catch (ex) {

                                  }
                                  setTaskIndex(dataItemIndex)
                                  setTaskSectionOpen(true)
                                  if (mobileView) {
                                    middlePaneMobile()
                                  }
                                  setMenuPosition(null)
                                  updateTaskUserActivity(dataItem.id)
                                }}
                                style={{
                                  padding: 0
                                }}
                              >
                                <div className='settingModalItems '>
                                  <img
                                    style={{
                                      filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                      width: 14,
                                      height: 14
                                    }}
                                    src={StickyNote}
                                    alt=''
                                  />
                                  <span>Details</span>
                                </div>
                              </MenuItem>
                              {/* <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        clearTimeout(timerRef.current);
                                        preventRef.current = true;
                                        setEditList(dataItem)

                                        if (
                                          editList?.id != dataItem?.id
                                        ) {
                                          TaskNameInputRef?.current?.blur()
                                        }


                                        setTaskItem({})
                                        setTimeout(() => {
                                          setTaskItem(dataItem)
                                        }, 1);
                                        listInputRef?.current?.blur()

                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        setMenuPosition(null)

                                        setTimeout(() => {
                                          preventRef.current = false;
                                          TaskNameInputRef?.current?.focus()
                                          try {
                                            let sel: any =
                                              window.getSelection()
                                            let range =
                                              document.createRange()

                                            range.setStart(
                                              TaskNameInputRef
                                                ?.current
                                                ?.childNodes[0],
                                              dataItem.taskName.length
                                            )
                                            range.collapse(true)
                                            sel.removeAllRanges()
                                            sel.addRange(range)
                                          } catch (ex) { }
                                        }, 100)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            width: 14,
                                            height: 14
                                          }}
                                          src={EditHeadingIcon}
                                          alt=''
                                        />
                                        <span>Edit task</span>
                                      </div>
                                    </MenuItem> */}
                              {listItem.userId != userId ? null :
                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTaskLeftClicked(false)
                                    handleTaskMenuListClose(e)
                                    duplicateTask(dataItem)
                                    setMenuPosition(null)
                                  }}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <div className='settingModalItems '>
                                    <img
                                      style={{
                                        filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                        width: 16,
                                        cursor: 'pointer'
                                      }}
                                      src={DuplicateIcon}
                                    />
                                    {dataItem.taskNote == 0 ? (
                                      <span>Duplicate task</span>
                                    ) : (
                                      <span>Duplicate note</span>
                                    )}
                                  </div>
                                </MenuItem>
                              }
                              {dataItem.taskNote == 0 ? (
                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTaskLeftClicked(false)
                                    handleTaskMenuListClose(e)
                                    // duplicateTask(dataItem)
                                    bugFun(dataItem)
                                    setMenuPosition(null)
                                  }}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <div className='settingModalItems '>
                                    <img
                                      style={{
                                        filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                        width: 16,
                                        cursor: 'pointer'
                                      }}
                                      src={BugIcon}
                                    />
                                    {dataItem.taskBug == '0' ? (
                                      <span>Bug</span>
                                    ) : (
                                      <span>Remove bug</span>
                                    )}
                                  </div>
                                </MenuItem>
                              ) : null}
                              <MenuItem
                                onClick={e => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setTaskLeftClicked(false)
                                  handleTaskMenuListClose(e)
                                  // duplicateTask(dataItem)
                                  convertNote(dataItem)
                                  setMenuPosition(null)
                                }}
                                style={{
                                  padding: 0
                                }}
                              >
                                <div className='settingModalItems '>
                                  <img
                                    style={{
                                      filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                      width: 16,
                                      cursor: 'pointer'
                                    }}
                                    src={ConvertNote}
                                  />
                                  {dataItem.taskNote == '0' ? (
                                    <span>Convert to note</span>
                                  ) : (
                                    <span>Convert to task</span>
                                  )}
                                </div>
                              </MenuItem>
                              {dataItem.taskNote == 0 ? (
                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTaskLeftClicked(false)
                                    handleTaskMenuListClose(e)
                                    // changeStatus(dataItem)
                                    changeTrunIntoHeading(dataItem)
                                    setMenuPosition(null)
                                    // setTaskSectionOpen(false)
                                  }}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <div className='settingModalItems '>
                                    <img
                                      style={{
                                        filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                        width: 14,
                                        cursor: 'pointer',
                                        marginRight: 2
                                      }}
                                      src={TurnIntoHeading}
                                    />
                                    <span>Make heading</span>
                                  </div>
                                </MenuItem>
                              ) : null}
                              <>
                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTaskLeftClicked(false)
                                    handleTaskMenuListClose(e)
                                    setMenuPosition(null)
                                    // setDrawerMoveTaskTo(true)
                                    setMoveTaskListShow(true)
                                  }}
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <div className='settingModalItems '>
                                    <img
                                      style={{
                                        filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                        width: 14,
                                        cursor: 'pointer',
                                        marginRight: 2
                                      }}
                                      src={AddGroupIcon11}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                      <span>Move task to...</span>
                                      <img
                                        style={{
                                          filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)',
                                          width: 14,
                                          cursor: 'pointer',
                                          marginRight: 2
                                        }}
                                        src={RightBlackArrow}
                                      />
                                    </div>
                                  </div>
                                </MenuItem>
                                {/* {drawerMoveTaskTo &&
                                          <RecursiveMenu />
                                        } */}
                              </>
                            </>
                          ) : (
                            <>
                              <MenuItem
                                onClick={e => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setTaskLeftClicked(false)
                                  handleTaskMenuListClose(e)
                                  changeTrunIntoTask(dataItem)
                                  setMenuPosition(null)
                                }}
                                style={{
                                  padding: 0
                                }}
                              >
                                <div className='settingModalItems '>
                                  <img
                                    style={{
                                      width: 16,
                                      cursor: 'pointer'
                                    }}
                                    src={TurnIntoTask}
                                  />
                                  <span>Turn into task</span>
                                </div>
                              </MenuItem>
                              {/* <MenuItem
                                      onClick={e => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setTaskLeftClicked(false)
                                        handleTaskMenuListClose(e)
                                        setEditList(dataItem)
                                        setTimeout(() => {
                                          TaskNameInputRef?.current?.focus()
                                          try {
                                            let sel: any = window.getSelection()
                                            let range = document.createRange()

                                            range.setStart(
                                              TaskNameInputRef?.current
                                                ?.childNodes[0],
                                              dataItem.taskName.length
                                            )
                                            range.collapse(true)
                                            sel.removeAllRanges()
                                            sel.addRange(range)
                                          } catch (ex) { }
                                        }, 100)
                                        setMenuPosition(null)
                                      }}
                                      style={{
                                        padding: 0
                                      }}
                                    >
                                      <div className='settingModalItems '>
                                        <img
                                          style={{
                                            width: 16,
                                            cursor: 'pointer'
                                          }}
                                          src={EditHeadingIcon}
                                        />
                                        <span>Edit heading</span>
                                      </div>
                                    </MenuItem> */}
                            </>
                          )}
                          <MenuItem
                            className='customCursor'
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setColorPalette(true)
                              setTaskLeftClicked(false)
                              setPoints({
                                x: e.pageX - 150,
                                y: e.pageY
                              })
                              console.log('e.pageY', e.pageY, e.pageX)
                              handleTaskMenuListClose(e)
                              setHighlightFrom('task')
                              setMenuPosition(null)
                            }}
                            style={{
                              padding: 0
                            }}
                          >
                            <div className='settingModalItems'>
                              <img src={MarkerIcon} alt='' style={{ filter: !mobileView ? 'brightness(100%)' : mobileView && isLightMode ? 'brightness(100%)' : 'brightness(1000%)' }} />
                              {dataItem.taskNote == 0 ? (
                                <>
                                  {dataItem.taskHeading == 0 ? (
                                    <span>Highlight task</span>
                                  ) : (
                                    <span>Highlight Heading</span>
                                  )}
                                </>
                              ) : (
                                <span>Highlight note</span>
                              )}
                            </div>
                          </MenuItem>
                          {listItem.userId != userId ? null :
                            <MenuItem
                              onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                setTaskLeftClicked(false)
                                handleTaskMenuListClose(e)
                                setMenuPosition(null)
                              }}
                              style={{
                                padding: 0
                              }}
                            >
                              <div
                                style={{
                                  color: 'red',
                                  // borderTopWidth: 0.5,
                                  // borderTopColor: 'lightgray',
                                  // borderTopStyle: 'solid'
                                }}
                                onClick={() => {
                                  // handleClick(TransitionUp)
                                  handleClickSnack(SlideTransition);
                                  // setTimeout(() => {
                                  //   handleCloseSnack()
                                  // }, 4000);
                                  // if (
                                  //   currentDate == localStorage.getItem('date')
                                  // ) {
                                  // if(dataItem.isTrash != "2"){
                                  moveToTrash(dataItem)
                                  setDataTrashItem(dataItem)
                                  // }
                                  //   else{
                                  //   deleteTask(dataItem)
                                  // }
                                  // } else {
                                  //   handleDeleteConfirm(dataItem)
                                  // }
                                }}
                                className='settingModalItems '
                              >
                                <img src={RedDelete} alt='' />
                                {dataItem.taskNote == 0 ? (
                                  <>
                                    {dataItem.taskHeading == 0 ? (
                                      <span>Move to Trash{/* Delete task */}</span>
                                    ) : (
                                      <span>Move to Trash{/* Delete Heading */}</span>
                                    )}
                                  </>
                                ) : (
                                  <span>Delete note</span>
                                )}
                              </div>
                            </MenuItem>
                          }
                        </>
                        :
                        <>
                          <MenuItem
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setTaskLeftClicked(false)
                              handleTaskMenuListClose(e)

                              // changeTrunIntoTask(dataItem)
                              handleClickTrashSnack(SlideTransition);
                              restoreTask(dataItem)
                              console.log('dataItem89', dataItem)
                              setMenuPosition(null)
                            }}
                            style={{
                              padding: 0
                            }}
                          >
                            <div className='settingModalItems '>
                              <img
                                style={{
                                  width: 16,
                                  cursor: 'pointer'
                                }}
                                src={Restore}
                              />
                              {dataItem.taskNote == 0 ? (
                                <>
                                  {dataItem.taskHeading == 0 ? (
                                    <span>Restore task</span>
                                  ) : (
                                    <span>Restore Heading</span>
                                  )}
                                </>
                              ) : (
                                <span>Restore note</span>
                              )}
                            </div>
                          </MenuItem>
                          <MenuItem
                            onClick={e => {
                              e.stopPropagation()
                              e.preventDefault()
                              setTaskLeftClicked(false)
                              handleTaskMenuListClose(e)
                              setMenuPosition(null)
                            }}
                            style={{
                              padding: 0
                            }}
                          >
                            <div
                              style={{
                                color: 'red',
                                // borderTopWidth: 0.5,
                                // borderTopColor: 'lightgray',
                                // borderTopStyle: 'solid'
                              }}
                              onClick={() => {
                                // if (
                                //   currentDate == localStorage.getItem('date')
                                // ) {
                                //   if(dataItem.isTrash != "2"){
                                //     moveToTrash(dataItem)
                                //   }
                                //   else{
                                //   deleteTask(dataItem)
                                // }
                                // } else {
                                handleDeleteConfirm(dataItem)
                                // }
                              }}
                              className='settingModalItems'
                            >
                              <img src={RedDelete} alt='' />
                              {dataItem.taskNote == 0 ? (
                                <>
                                  {dataItem.taskHeading == 0 ? (
                                    <span>Delete task forever</span>
                                  ) : (
                                    <span>Delete Heading forever</span>
                                  )}
                                </>
                              ) : (
                                <span>Delete note forever</span>
                              )}
                            </div>
                          </MenuItem>
                        </>
                      }
                    </MenuList>
                  </div>
                </SwipeableDrawer>
              }
            </>
            :
            <HelpSupport />
          }
          {moveTaskListShow && <RecursiveMenu />}
          {moveGroupListShow && <RecursiveMenuList />}

          {/* } */}
        </DragDropContext>

        {!mobileView ?

          <Dialog
            open={openNotificationListDialog && !noLongerDialog}
            onClose={() => setOpenNotificationListDialog(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <ClickAwayListener onClickAway={() => setOpenNotificationListDialog(false)}>
              <div className='notificationDialogMainDiv1' style={{ textAlign: 'center', minWidth: '80%', maxWidth: '80%', maxHeight: 650 }}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                  <div style={{ width: "70%", textAlign: "left" }}>
                    <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Invitations/Contacts</h4></div>
                  <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <img style={{ width: 12, cursor: 'pointer' }} onClick={() => setOpenNotificationListDialog(false)} src={CloseMenuListIcon} alt='' />
                  </div>
                </div>

                <div className="newDialogInnerDiv" style={{ maxHeight: 550 }}>


                  {allInvitation && allInvitation.length > 0 ?
                    <>
                      {allInvitation.map((item, index) => (
                        <>
                          {item.fromUserId != userId ?
                            <div className="InvitationsContactsitemDiv" style={{ padding: mobileView ? '8px 5px' : '' }}>
                              <div style={{ textAlign: 'left', marginBottom: 8, fontSize: 15 }}>{item.firstName ? Base64.decode(item.firstName) + " " + Base64.decode(item.lastName) : ""} shared <span style={{ fontWeight: 600 }}>"{decData(item.type == "project" ? item.projectName : item.groupName, "1")}"</span> {item.type == "project" ? "list" : "folder"} with you.</div>
                              <div className='userListItem' style={{ cursor: "inherit", padding: 0, margin: 0, borderBottom: 'none' }} >
                                <div style={mobileView ? {} : { /* width: '8%' */marginRight: 15 }}>
                                  {item && item.firstName && item.firstName.trim() ? <div className='userAvtarDiv2'>
                                    {/* {firstName.charAt(0)}{lastName.charAt(0)} */}
                                    {Base64.decode(item.firstName).charAt(0)}
                                  </div> : <div className='userAvtarDiv2'>
                                    {item && item.fromUserEmail ? Base64.decode(item.fromUserEmail).charAt(0) : ""}
                                  </div>}
                                </div>


                                <div style={!mobileView ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' } : { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '90%', flexDirection: 'column' }}>
                                  <div>
                                    {item && item.toUserName ? <div
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: '#464645',
                                        textAlign: 'left'
                                      }}
                                    >
                                      {item.firstName ? Base64.decode(item.firstName) + " " + Base64.decode(item.lastName) : ""}
                                    </div> : null}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={{ fontSize: 12, marginTop: -3 }}>{Base64.decode(item.fromUserEmail)}</div>
                                    </div>
                                  </div>
                                  <div style={mobileView ? { margin: '15px 0px' } : { display: 'flex' }}>
                                    {item.isAccept == "1" ?
                                      <div style={{
                                        backgroundColor: item.isAccept == "0" ? "green" : "#3068C8",
                                        padding: "10px",
                                        borderRadius: "6px",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        marginLeft: "10px", cursor: "pointer",
                                        width: 125
                                      }} onClick={() => {
                                        let data: any = [...allInvitation];
                                        data[index].isAccept = "0";
                                        setAllInvitation(data);
                                        postShareStatus({ id: item.id, status: "0", toUserId: userData.id })

                                      }}>{item.isAccept == "0" ? "Accepted" : "Accept"}</div> : null}

                                    {item.isAccept == "0" ?
                                      <span style={{
                                        color: "rgb(100, 120, 199)",
                                        fontWeight: "bold",
                                      }}>Invite Accepted</span> : null}

                                    {item.isAccept == "1" ?
                                      <div style={{
                                        backgroundColor: item.isAccept == "2" ? "red" : "gray",
                                        padding: "10px",
                                        borderRadius: "6px",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        marginLeft: "10px", cursor: "pointer",
                                        width: 125
                                      }} onClick={() => {
                                        let data: any = [...allInvitation];
                                        data[index].isAccept = "2";
                                        setAllInvitation(data);
                                        postShareStatus({ id: item.id, status: "2", toUserId: userData.id })
                                      }}>{item.isAccept == "2" ? "Rejected" : "Reject"}</div> : null}

                                    {item.isAccept == "2" ?
                                      <span style={{
                                        color: "red",
                                        fontWeight: "bold"
                                      }}> Invite rejected</span> : null}
                                  </div>
                                  {/*  <img
                style={{
                  marginTop: 3,
                  width: 11,
                  height: 11,
                  cursor: 'pointer'
                }}
                src={CloseMenuListIcon}
                alt=''
                onClick={()=>handleDeleteShareListUser(item)}
              /> */}

                                </div>
                              </div>
                            </div>
                            :
                            <div className="InvitationsContactsitemDiv" style={{ padding: mobileView ? '8px 5px' : '', }}>
                              <div style={{ textAlign: 'left', marginBottom: 8, fontSize: 15 }}>You shared
                                <span style={{ fontWeight: 600 }}>"{decData(item.type == "project" ? item.projectName : item.groupName, "1")}"</span>
                                {item.type == "project" ? "list" : "folder"} with {Base64.decode(item.toUserName)}.</div>
                              <div className='userListItem' style={{ cursor: "inherit", padding: 0, margin: 0, borderBottom: 'none' }} >
                                <div style={mobileView ? {} : {/*  width: '8%' */marginRight: 15 }}>
                                  {item && item.toUserName && item.toUserName.trim() ? <div className='userAvtarDiv2'>
                                    {/* {firstName.charAt(0)}{lastName.charAt(0)} */}
                                    {Base64.decode(item.toUserName).charAt(0)}
                                  </div> : <div className='userAvtarDiv2'>
                                    {item && item.toUserEmail ? Base64.decode(item.toUserEmail).charAt(0) : ""}
                                  </div>}
                                </div>


                                <div style={!mobileView ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' } : { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '90%', flexDirection: 'column' }}>
                                  <div>
                                    {item && item.toUserName ? <div
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: '#464645',
                                        textAlign: 'left'
                                      }}
                                    >
                                      {Base64.decode(item.toUserName)}
                                    </div> : null}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={{ fontSize: 12, marginTop: -3 }}>{Base64.decode(item.toUserEmail)}</div>
                                    </div>
                                  </div>
                                  <div>
                                    {item.isAccept == "0" ?
                                      <span style={{
                                        color: "rgb(100, 120, 199)",
                                        fontWeight: "bold"
                                      }}  > Invite accepted</span> : null}

                                    {item.isAccept == "2" ?
                                      <span style={{
                                        color: "red",
                                        fontWeight: "bold"
                                      }}> Invite rejected</span> : null}

                                    {item.isAccept == "1" ?
                                      <>
                                        <span style={{
                                          fontSize: 13,

                                          color: "gray",
                                          fontWeight: "bold"
                                        }}> Invite sent...waiting</span>
                                        <span style={{
                                          color: "rgb(100, 120, 199)",
                                          fontWeight: "bold",
                                          marginLeft: "10px", cursor: "pointer"
                                        }} onClick={() => {
                                          setMultiSnackbarMsg('Invite resend successfully.')
                                          handleClickMultiSnackBar(SlideTransition)
                                          let tempItem = { ...item };
                                          tempItem.toUserEmail = Base64.decode(item.toUserEmail);
                                          postShareEmail([tempItem], item.type == "project" ? "list" : "folder", "0")
                                        }}
                                        ><u> Resend Invite</u></span> </> : null}
                                  </div>
                                  {/*  <img
style={{
marginTop: 3,
width: 11,
height: 11,
cursor: 'pointer'
}}
src={CloseMenuListIcon}
alt=''
onClick={()=>handleDeleteShareListUser(item)}
/> */}

                                </div>
                              </div>
                            </div>}
                        </>
                      ))}
                    </>
                    :
                    <div style={{ fontSize: 20, color: 'dimgray', marginTop: 30, marginBottom: 10 }}>
                      No invitations.
                    </div>
                  }



                </div>
              </div>
            </ClickAwayListener>
          </Dialog>
          :
          <SwipeableDrawer
            anchor="bottom"
            open={openNotificationListDialog && !noLongerDialog}
            onClose={() => setOpenNotificationListDialog(false)}
            onOpen={() => setOpenNotificationListDialog(true)}
            className='mainDrawerContainer'
          >
            <div style={{ background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
              <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                <div ></div>
              </div>
              <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                <div style={{ width: '20%' }}></div>
                <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                  Invitations/Contacts
                </div>
                <div className='drawerHeadingDone' onClick={() => setOpenNotificationListDialog(false)}>Done</div>
              </div>
              <div className='notificationDialogMainDiv' style={{ textAlign: 'center', background: !mobileView ? '#fff' : mobileView && isLightMode ? '#fff' : colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }}>
                {/* <div style={{ textAlign: 'end', width: '100%' }}>
                <img style={{ width: 16, cursor: 'pointer' }} onClick={() => setOpenNotificationListDialog(false)} src={CloseMenuListIcon} alt='' />
              </div> */}
                <div>
                  {/* <div style={{ marginTop: -15 }}>
                  <h4 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>
                    Invitations/Contacts
                  </h4>
                </div> */}


                  {allInvitation && allInvitation.length > 0 ?
                    <>
                      {allInvitation.map((item, index) => (
                        <>
                          {item.fromUserId != userId ?
                            <div className={isLightMode ? "InvitationsContactsitemDiv" : "InvitationsContactsitemDivDark"}>
                              <div style={{ textAlign: 'left', marginBottom: 0, fontSize: 15 }}>{item.firstName ? Base64.decode(item.firstName) + " " + Base64.decode(item.lastName) : ""} shared <span style={{ fontWeight: 600 }}>"{decData(item.type == "project" ? item.projectName : item.groupName, "1")}"</span> {item.type == "project" ? "list" : "folder"} with you.</div>
                              <div className='userListItem' style={{ cursor: "inherit", padding: 0, margin: 0, borderBottom: 'none' }} >
                                <div style={{ display: 'flex', gap: 10 }}>
                                  {item && item.firstName && item.firstName.trim() ?
                                    <div className='userAvtarDiv2' style={{ marginTop: 5 }}>
                                      {Base64.decode(item.firstName).charAt(0)}
                                    </div>
                                    :
                                    <div className='userAvtarDiv2' style={{ marginTop: 5 }}>
                                      {item && item.fromUserEmail ? Base64.decode(item.fromUserEmail).charAt(0) : ""}
                                    </div>
                                  }
                                  <div>
                                    {item && item.toUserName ? <div
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: colorSchemes.FontColor,
                                        textAlign: 'left'
                                      }}
                                    >
                                      {item.firstName ? Base64.decode(item.firstName) + " " + Base64.decode(item.lastName) : ""}
                                    </div> : null}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={{ fontSize: 12, marginTop: -3 }}>{Base64.decode(item.fromUserEmail)}</div>
                                    </div>
                                  </div>

                                </div>
                                {/* <div className='invitationItemMiddleDiv'></div> */}
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0px 15px' }}>
                                  {item.isAccept == "1" ?
                                    <span style={{
                                      backgroundColor: item.isAccept == "0" ? "green" : "#3068C8",
                                      borderRadius: "6px",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      marginLeft: "10px", cursor: "pointer",
                                      marginTop: 5,
                                      width: 120,
                                      height: 40,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }} onClick={() => {
                                      let data: any = [...allInvitation];
                                      data[index].isAccept = "0";
                                      setAllInvitation(data);
                                      postShareStatus({ id: item.id, status: "0", toUserId: userData.id })

                                    }}>{item.isAccept == "0" ? "Accepted" : "Accept"}</span> : null}

                                  {item.isAccept == "0" ?
                                    <span style={{
                                      color: "rgb(100, 120, 199)",
                                      fontWeight: "bold",
                                    }}>Invite Accepted</span> : null}

                                  {item.isAccept == "1" ?
                                    <span style={{
                                      backgroundColor: item.isAccept == "2" ? "red" : "gray",
                                      borderRadius: "6px",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      marginLeft: "10px", cursor: "pointer",
                                      marginTop: 5,
                                      width: 120,
                                      height: 40,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }} onClick={() => {
                                      let data: any = [...allInvitation];
                                      data[index].isAccept = "2";
                                      setAllInvitation(data);
                                      postShareStatus({ id: item.id, status: "2", toUserId: userData.id })
                                    }}>{item.isAccept == "2" ? "Rejected" : "Reject"}</span> : null}

                                  {item.isAccept == "2" ?
                                    <span style={{
                                      color: "red",
                                      fontWeight: "bold"
                                    }}> Invite rejected</span> : null}
                                </div>


                              </div>
                            </div>
                            :
                            <div className={isLightMode ? "InvitationsContactsitemDiv" : "InvitationsContactsitemDivDark"}>
                              <div style={{ textAlign: 'left', marginBottom: 8, fontSize: 15 }}>You shared
                                <span style={{ fontWeight: 600 }}>"{decData(item.type == "project" ? item.projectName : item.groupName, "1")}"</span>
                                {item.type == "project" ? "list" : "folder"} with {Base64.decode(item.toUserName)}.</div>
                              <div className='userListItem' style={{ cursor: "inherit", padding: 0, margin: 0, borderBottom: 'none' }} >
                                <div style={{ display: 'flex', gap: 10 }}>
                                  {item && item.toUserName && item.toUserName.trim() ?
                                    <div className='userAvtarDiv2' style={{ marginTop: 5 }}>
                                      {Base64.decode(item.toUserName).charAt(0)}
                                    </div>
                                    :
                                    <div className='userAvtarDiv2' style={{ marginTop: 5 }}>
                                      {item && item.toUserEmail ? Base64.decode(item.toUserEmail).charAt(0) : ""}
                                    </div>
                                  }
                                  <div>
                                    {item && item.toUserName ? <div
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: colorSchemes.FontColor,
                                        textAlign: 'left'
                                      }}
                                    >
                                      {Base64.decode(item.toUserName)}
                                    </div> : null}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div style={{ fontSize: 12, marginTop: -3 }}>{Base64.decode(item.toUserEmail)}</div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className='invitationItemMiddleDiv'></div> */}
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0px 15px' }}>
                                  {item.isAccept == "0" ?
                                    <span style={{
                                      color: "rgb(100, 120, 199)",
                                      fontWeight: "bold"
                                    }}  > Invite accepted</span> : null}

                                  {item.isAccept == "2" ?
                                    <span style={{
                                      color: "red",
                                      fontWeight: "bold"
                                    }}> Invite rejected</span> : null}

                                  {item.isAccept == "1" ?
                                    <>
                                      <span style={{
                                        fontSize: 13,

                                        color: "gray",
                                        fontWeight: "bold"
                                      }}> Invite sent...waiting</span>
                                      <span style={{
                                        color: "rgb(100, 120, 199)",
                                        fontWeight: "bold",
                                        marginLeft: "10px", cursor: "pointer"
                                      }} onClick={() => {
                                        setMultiSnackbarMsg('Invite resend successfully.')
                                        handleClickMultiSnackBar(SlideTransition)
                                        let tempItem = { ...item };
                                        tempItem.toUserEmail = Base64.decode(item.toUserEmail);
                                        postShareEmail([tempItem], item.type == "project" ? "list" : "folder", "0")
                                      }}
                                      ><u> Resend Invite</u></span> </> : null}
                                </div>


                              </div>
                            </div>}
                        </>
                      ))}
                    </>
                    :
                    <div style={{ fontSize: 20, color: colorSchemes.FontColor, marginTop: 30, marginBottom: 10 }}>
                      No invitations.
                    </div>
                  }



                </div>
              </div>
            </div>
          </SwipeableDrawer>
        }


        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          className='shareDialog'
        >
          <ClickAwayListener onClickAway={handleCloseDialog}>

            {(selectedShareType == "project" && listItem && listItem.userId == userId) || (selectedShareType == "group" && groupItem && groupItem.userId == userId) ?
              <div className='notificationDialogMainDiv1' style={mobileView ? { padding: 0 } : {}}>


                <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                  <div style={{ width: mobileView ? "90%" : "70%", textAlign: "left" }}>
                    <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Share&nbsp;{selectedShareType == "project" ? "list" : "Folder"}&nbsp;"{selectedShareType == "project" ? decData(selectedProjectName, listItem && listItem.isEncrypt && listItem.isEncrypt == '1' ? "1" : "0")
                      : decData(groupItem && groupItem.groupName ? groupItem.groupName : "", groupItem && groupItem.isEncrypt && groupItem.isEncrypt == '1' ? "1" : "0")}"</h4>
                  </div>
                  <div style={{ textAlign: 'end', width: mobileView ? "10%" : "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <img style={{ width: 12, cursor: 'pointer' }} onClick={handleCloseDialog} src={CloseMenuListIcon} alt='' />
                  </div>
                </div>

                <div className="newDialogInnerDiv" style={{ maxHeight: 560 }}>

                  {sharedListData && sharedListData.length > 0 ? null :
                    <div>
                      <div className='shareDialogInputAndIcon'>
                        <div style={{ width: '100%' }}>
                          {/* {sharedListData && sharedListData.length > 0 &&
                  <div style={{ padding: 2 }}>
                    {sharedListData.map((item, index) => (
                      <span className='emailChipDiv'>
                        <span>{item.toUserEmail}</span>
                        <img
                          style={{
                            marginTop: 3,
                            width: 9,
                            height: 9,
                            cursor: 'pointer'
                          }}
                          src={CloseMenuListIcon}
                          alt=''
                          onClick={() => handleRemoveShareListUser(item)}
                        />
                      </span>
                    ))}
                  </div>
                } */}
                          <input
                            className='shareDialogInput'
                            type='text'
                            name='name'
                            placeholder='Enter email'
                            autoFocus={true}
                            onChange={handleChangeShareInput}
                            onKeyDown={e => {
                              handleKeyDownShareInput(e)
                            }}
                            autoComplete="off"
                            value={shareListInputValue}
                          />
                        </div>
                        <div style={{ borderLeft: "1px solid rgb(118, 118, 118)", display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100 }} onClick={() => { personClick() }}>
                          <div className='SharePeopleIconDiv sendCodeBtnActive' style={{ height: '100%', borderRadius: 0, fontWeight: 600, opacity: shareListInputValue != "" ? 1 : 0.65 }}>
                            {/* <img style={{ width: 22 }} src={SharePeople1} /> */}
                            Send
                          </div>
                        </div>
                      </div>
                    </div>

                  }
                  {allUserFilter && allUserFilter.length > 0 && userListDivShow && !isShareApiDone &&
                    <div className='userListDiv'>
                      {allUserFilter && allUserFilter.length > 0 && allUserFilter.map((item: any, i: any) => (
                        <>
                          {userId != item.id && <div className='userListItem' onClick={() => shareListUser(item)}>
                            {/* <div style={{ width: '8%' }}>

                          {item && item.firstName ? <div className='userAvtarDiv2'>
                            {item.firstName.charAt(0)}{item.lastName.charAt(0)}
                          </div> : <div className='userAvtarDiv2'>
                            {item && item.email ? item.email.charAt(0) : ""}
                          </div>}
                        </div> */}
                            <div>
                              {item && item.firstName ? <div
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: '#464645'
                                }}
                              >
                                {item.firstName} {item.lastName}
                              </div> : null}
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ fontSize: 12, marginTop: -3 }}>{item.email}</div>
                              </div>
                            </div>
                          </div>}
                        </>
                      ))}

                    </div>
                  }

                  {sharedListData && sharedListData.length > 0 && !isShareApiDone ?
                    <>
                      <div style={{ padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 15, marginTop: 15 }}>
                        <span>Click invite below to send an invite email to:</span>
                        {sharedListData.map((item, index) => (
                          <span style={{ fontSize: 16, fontWeight: 600, color: '#000' }}>{item.toUserEmail}</span>
                        ))}
                      </div>
                      <div className='shareDialogInviteBtn' style={{ display: 'flex', marginTop: 40, justifyContent: 'flex-end', gap: 0 }}>
                        <button
                          style={{
                            background: '#fff',
                            color: '#3068C8',
                            border: '1px solid #3068C8',
                          }}
                          onClick={() => { setSharedListData([]) }}
                        >
                          Back
                        </button>
                        <button
                          style={{ background: '#3068C8', color: '#fff' }}
                          onClick={() => inviteUser()}
                        >
                          Invite
                        </button>
                      </div>
                    </>
                    : isShareApiDone ? null :
                      <>
                        {((sharedUserList && sharedUserList.length > 0) || (allShareUser && allShareUser.length > 0)) ?
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: mobileView ? 5 : 80, paddingLeft: mobileView ? 5 : 45 }}>
                            <div style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: 10, cursor: 'pointer', color: "rgb(71, 114, 250)", textDecoration: "underline" }}
                              onClick={() => { sortShareUserByName()/* ;sortShareDefaultUserByName("member")  */ }}>
                              Member {getMemberCount() > 0 ? <>({getMemberCount()})</> : null}
                            </div>
                            {/* {sharedUserList.length > 0 && */}
                            <div style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: 10, cursor: 'pointer', color: "rgb(71, 114, 250)", textDecoration: "underline" }} onClick={() => { sortShareUserByStatus()/* ;sortShareDefaultUserByName("status") */ }}>
                              Invite Status <img style={{ width: 12, height: 12, cursor: 'pointer', marginLeft: "2px" }} src={ShareQuestionIcon} alt="" />
                            </div>
                            {/* } */}
                          </div>
                          : null}

                        {!isShareApiDone && <div className={(sharedUserList && sharedUserList.length > 0) || (allShareUser && allShareUser.length > 0) ? "sharedUserListDiv" : ""}>
                          {(sharedUserList && sharedUserList.length > 0 || allShareUser && allShareUser.length > 0) ? <div className='userListItem newShareListItemDiv' style={{ cursor: "inherit" }} >
                            {/* <div style={{ width: '8%' }}>
                            <div className='userAvtarDiv2'>
                              {nameLetters}
                            </div>
                          </div> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: mobileView ? '100%' : '90%' }}>
                              <div>
                                <div
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: '#464645'
                                  }}
                                >
                                  {firstName} {lastName}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div style={{ fontSize: 12, marginTop: -3 }}>{email}</div>
                                </div>
                              </div>
                              <div>
                                <span>Owner</span>
                              </div>
                            </div>
                          </div> : null}
                          {combineAllShareUser && combineAllShareUser.map((item, index) => (
                            <>
                              {!item.userType ?
                                <>
                                  {item.isAccept != 2 && item.isAccept != 3 &&
                                    <div className='userListItem newShareListItemDiv' style={{ cursor: "inherit" }} >
                                      {/* <div style={{ width: '8%' }}>
                              {item && item.toUserName && item.toUserName.trim() ? <div className='userAvtarDiv2'>
                                {item.toUserName.charAt(0)}
                              </div> : <div className='userAvtarDiv2'>
                                {item && item.toUserEmail ? item.toUserEmail.charAt(0) : ""}
                              </div>}
                            </div> */}


                                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: mobileView ? '100%' : '90%' }}>
                                        <div>
                                          {item && item.firstName ? <div
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                              color: '#464645'
                                            }}
                                          >
                                            {Base64.decode(item.firstName)}{item.lastName ? " " + Base64.decode(item.lastName) : ""}
                                          </div> : item.toUserName ? <div
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                              color: '#464645'
                                            }}
                                          >
                                            {item.toUserName}
                                          </div> : null}
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ fontSize: 12, marginTop: -3 }}>{item.toUserEmail}</div>
                                          </div>
                                          {checkEmailIsNotIntrested(item.toUserEmail) &&
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <div style={{ fontSize: 12, color: 'red' }}>Not Intrested</div>
                                            </div>
                                          }
                                          {item.isShowQue &&
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <Tooltip title={<span style={{ fontSize: 13 }}>You invited this user to this folder, they will be added to each new/old list on this folder automatically. To change this, go to the folder options, revoke their invite then resend them an invite to any list.</span>} >
                                                <span style={{ fontSize: 12, cursor: 'pointer' }}>(user invited to folder) &nbsp; <img style={{ width: 12, height: 12, cursor: 'pointer' }} src={ShareQuestionIcon} alt="" /></span>
                                              </Tooltip>
                                            </div>
                                          }
                                        </div>
                                        {item.isShowQue ? <div className='acceptedShareList' style={{ color: "rgb(71, 114, 250)", textDecoration: "underline" }}>
                                          Accepted -{mobileView ? <br /> : ' '}Folder level
                                        </div> :
                                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: mobileView ? 0 : 15, flexDirection: mobileView ? 'column' : 'row' }}>
                                            {item.isAccept == 1 ?
                                              <div className='pendingShareList' style={{ color: "rgb(71, 114, 250)", textDecoration: "underline" }}>
                                                Pending
                                              </div>
                                              :
                                              item.isAccept == 2 ?
                                                // <div className='rejectedShareList'>
                                                //   Rejected
                                                // </div>
                                                <div className='shareDialogInviteBtn'>
                                                  <button
                                                    style={{ background: '#3068C8', color: '#fff', minWidth: mobileView ? '100px' : '' }}
                                                    onClick={() => {/* inviteUser() */inviteShareUser(item) }}
                                                  >
                                                    Invite
                                                  </button>
                                                </div>
                                                :
                                                item.isAccept == 3 ?
                                                  // <div className='exitedShareList'>
                                                  //   Exited
                                                  // </div>
                                                  <div className='shareDialogInviteBtn'>
                                                    <button
                                                      style={{ background: '#3068C8', color: '#fff', minWidth: mobileView ? '100px' : '' }}
                                                      onClick={() => {/* inviteUser() */inviteShareUser(item) }}
                                                    >
                                                      Invite
                                                    </button>
                                                  </div>
                                                  :
                                                  <div className='acceptedShareList' style={{ color: "rgb(71, 114, 250)", textDecoration: "underline" }}>
                                                    Accepted
                                                  </div>
                                            }

                                            {item.isAccept != 2 && item.isAccept != 3 &&
                                              <div className='revokeShareList' style={{ cursor: 'pointer', color: "rgb(71, 114, 250)", textDecoration: "underline" }} onClick={() => { handleRevokeConfirm(item) }}>
                                                {item.isAccept == 0 ? "Remove" : "Cancel"}
                                              </div>
                                            }

                                            {/* <img
                                  style={{
                                    marginTop: 3,
                                    width: 11,
                                    height: 11,
                                    cursor: 'pointer'
                                  }}
                                  src={CloseMenuListIcon}
                                  alt=''
                                  onClick={() => handleDeleteShareListUser(item)}
                                /> */}
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  }
                                </>
                                // {/* // ))} */}
                                :

                                // {/* // {allShareUser && allShareUser.length > 0 && allShareUser.map((item, index) => ( */}
                                <div className='userListItem newShareListItemDiv' style={{ cursor: "inherit" }} >
                                  {/* <div style={{ width: '8%' }}>

                              {item && item.firstName ? <div className='userAvtarDiv2'>
                            {item.firstName.charAt(0)}{item.lastName.charAt(0)}
                          </div> : <div className='userAvtarDiv2'>
                            {item && item.email ? item.email.charAt(0) : ""}
                          </div>}

                            </div> */}


                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: mobileView ? '100%' : '90%' }}>
                                    <div>
                                      {item && item.firstName ? <div
                                        style={{
                                          fontSize: 14,
                                          fontWeight: 600,
                                          color: '#464645'
                                        }}
                                      >
                                        {item.firstName} {item.lastName}
                                      </div> : null}
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: 12, marginTop: -3 }}>{item.email}</div>
                                      </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 25 }}>
                                      <div className='shareDialogInviteBtn'>
                                        <button
                                          style={{ background: '#3068C8', color: '#fff', minWidth: mobileView ? '100px' : '' }}
                                          onClick={() => {/* inviteUser() */inviteShareUser(item) }}
                                        >
                                          Invite
                                        </button>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              }
                            </>
                          ))}
                        </div>
                        }
                        {((sharedUserList && sharedUserList.length > 0) || (allShareUser && allShareUser.length > 0)) ?
                          <>

                          </>
                          :
                          isShareApiDone ? null : <div className='ShareDialogImgDiv'>
                            <img src={ShareDialogDivBgImg} alt='' />
                            <p>Invite friends to start collaboration.</p>
                          </div>
                        }

                        <div className='sharedialogFooter'>
                          <ReactSwitch
                            style={{ transform: 'scale(0.7)', display: 'none' }}
                            checked={inviteLink}
                            onChange={handleChangeInviteLink}
                          />
                          <span style={{ fontSize: 14 }}>Enable Invite Link</span>
                        </div>
                        {inviteLink && <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "rgba(0,0,0,.05)", padding: mobileView ? "5px" : "7px 20px 7px 20px" }}>

                          <div>
                            <span style={{ fontSize: mobileView ? 12 : 14 }}>Members who joined via Invite Link</span>
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{
                              fontSize: mobileView ? 12 : 14,
                              color: "rgb(71,114,250)",
                              fontWeight: "bold", cursor: "pointer"
                            }} onClick={() => {
                              let tempItem: any = Base64.encode(JSON.stringify({
                                "fromUserId": userData.id, "toUserId": "0",
                                "fromUserEmail": Base64.decode(userData.email),
                                "toUserEmail": "", "toUserName": "", "type": selectedShareTypeRef.current,
                                "projectId": selectedShareTypeRef.current == "project" ? listItem.id : "0",
                                "pname": selectedShareTypeRef.current == "project" ? decData(listItem.projectName, listItem.isEncrypt) :
                                  selectedShareTypeRef.current == "group" ? decData(groupItem.groupName, groupItem.isEncrypt) : "",
                                "projectGroupId": selectedShareTypeRef.current == "group" ? groupItem.id : "0", "taskId": "0", "isAccept": "1"
                              }));
                              navigator.clipboard.writeText("www.managestuff.ai/invite?c=" + tempItem);
                              setMultiSnackbarMsg('Copied...')
                              // handleClickCopiedSnackBar(SlideTransition)
                              handleClickMultiSnackBar(SlideTransition)
                            }}>Copy Link</span>
                          </div>

                        </div>}
                      </>
                  }
                  {isShareApiDone && <div style={{ textAlign: "center", marginTop: "10%", marginBottom: "10%", fontWeight: "bold" }}>Please Wait fetching details ....</div>
                  }
                </div>
              </div>
              :
              <div className='notificationDialogMainDiv1' style={mobileView ? { padding: 0 } : {}}>

                <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
                  <div style={{ width: mobileView ? '90%' : "70%", textAlign: "left" }}>
                    <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Share&nbsp;{selectedShareType == "project" ? "list" : "Folder"}&nbsp;"{selectedShareType == "project" ? decData(selectedProjectName, listItem && listItem.isEncrypt && listItem.isEncrypt == '1' ? "1" : "0")
                      : decData(groupItem && groupItem.groupName ? groupItem.groupName : "", groupItem && groupItem.isEncrypt && groupItem.isEncrypt == '1' ? "1" : "0")}"</h4>
                  </div>
                  <div style={{ textAlign: 'end', width: mobileView ? '10%' : "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <img style={{ width: 12, cursor: 'pointer' }} onClick={handleCloseDialog} src={CloseMenuListIcon} alt='' />
                  </div>
                </div>

                <div className="newDialogInnerDiv" style={{ maxHeight: 560 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: mobileView ? 5 : 80, paddingLeft: mobileView ? 5 : 45 }}>
                    <div style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: 10, }}>
                      Member {allOtherInvitation && allOtherInvitation.length > 0 ? <>({allOtherInvitation.length})</> : null}
                    </div>
                    <div style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: 10, textAlign: "right" }}>
                      Invite Status
                    </div>
                  </div>

                  {allUser && allUser.length > 0 && allUser.map((item, index) => (
                    <>
                      {(selectedShareType == "project" && listItem && listItem.userId == item.id) || (selectedShareType == "group" && groupItem && groupItem.userId == item.id) ? <div className='userListItem newShareListItemDiv' style={{ cursor: "inherit", padding: '8px 20px' }} >
                        {/* <div style={{ width: '8%' }}>
                          {item && item.firstName ? <div className='userAvtarDiv2'>
                            {item.firstName.charAt(0)}{item.lastName.charAt(0)}
                          </div> : <div className='userAvtarDiv2'>
                            {item && item.email ? item.email.charAt(0) : ""}
                          </div>}
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                          <div>
                            {item && item.firstName ? <div
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: '#464645'
                              }}
                            >
                              {item.firstName} {item.lastName}
                            </div> : null}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ fontSize: 12, marginTop: -3 }}>{item.email}</div>
                            </div>
                          </div>
                          <div>
                            <span>Owner</span>
                          </div>
                        </div>
                      </div>
                        : null}</>))}
                  {isShareApiDone && <div style={{ textAlign: "center", marginTop: "10%", marginBottom: "10%", fontWeight: "bold" }}>Please Wait fetching details ....</div>
                  }

                  {allOtherInvitation && allOtherInvitation.length > 0 && allOtherInvitation.map((item, index) => (
                    <>
                      <div className='userListItem newShareListItemDiv' style={{ cursor: "inherit", padding: '8px 20px' }} >
                        {/* <div style={{ width: '8%' }}>
                          {item && item.firstName ? <div className='userAvtarDiv2'>
                            {Base64.decode(item.firstName).charAt(0)}{Base64.decode(item.lastName).charAt(0)}
                          </div> : <div className='userAvtarDiv2'>
                            {item && item.email ? Base64.decode(item.email.charAt(0)) : ""}
                          </div>}
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                          <div>
                            {item && item.firstName ? <div
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: '#464645'
                              }}
                            >
                              {Base64.decode(item.firstName)} {Base64.decode(item.lastName)}
                            </div> : null}
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                              <div style={{ fontSize: 12, marginTop: -3 }}>{Base64.decode(item.email)}
                              </div>

                              {(item.projectId && item.projectId == "0" && selectedShareType == "project") &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span style={{ fontSize: 12, cursor: 'pointer' }}>(user invited to folder) &nbsp; <img style={{ width: 12, height: 12, cursor: 'default' }} src={ShareQuestionIcon} alt="" /></span>
                                </div>
                              }
                            </div>
                          </div>
                          <div>
                            <span>Member</span>
                            {item.toUserId == userId && ((item.projectId && item.projectId != "0" && selectedShareType == "project") || selectedShareType == "group") &&
                              <>
                                &nbsp; &nbsp;
                                <img
                                  style={{
                                    width: 12,
                                    height: 12,
                                    cursor: 'pointer'
                                  }}
                                  src={CloseMenuListIcon}
                                  alt=''
                                  onClick={() => {
                                    if (item.type == "group") {
                                      handleGroupExitConfirm(dataItem)
                                    }
                                    else {
                                      handleProjectExitConfirm(listItem)
                                    }
                                  }}
                                />
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </>))}


                  <div style={{
                    textAlign: "center",
                    margin: "30px 0px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    opacity: .5
                  }}>Only the owner of the {selectedShareType == "project" ? "list" : "Folder"} can share {selectedShareType == "project" ? "list" : "Folder"} with others.</div>
                </div>
              </div>
            }
          </ClickAwayListener>
        </Dialog>



        {!mobileView ?
          <>
            {colorPalette && (
              <ClickAwayListener
                onClickAway={e => {
                  e.preventDefault()
                  setColorPalette(false)
                }}
              >
                <div
                  className='rightListDiv'
                  style={{
                    top:
                      dataItem.taskHeading == 0
                        ? newTopPoints
                        : newTopPoints,
                    left: points.x
                  }} /* top={points.y} left={points.x} */
                >
                  <div
                    className='closeIconDiv'
                    onClick={() => setColorPalette(false)}
                  >
                    <img src={CloseMenuListIcon} alt='' />
                  </div>
                  <div className='highlightColorDiv'>
                    <span>Choose highlight color</span>
                    <div className='mainColorDiv'>
                      {colors &&
                        colors.map((item, index) => (
                          <div className='colorUpperDiv'>
                            <div
                              className='colorDiv'
                              style={{ background: item.colorCode }}
                              onClick={() => {
                                if (highlightFrom == 'list') {
                                  listHighlightColor(item)
                                }
                                if (highlightFrom == 'pitem') {
                                  projectListHighlightColor(item)
                                }
                                if (highlightFrom == 'project') {
                                  groupHighlightColor(item)
                                }
                                if (highlightFrom == 'task') {
                                  taskHighlightColor(item)
                                  setTaskSectionOpen(true)
                                }
                              }}
                            ></div>
                          </div>
                        ))}
                      <div className='colorUpperDiv'>
                        <div
                          className='colorDiv'
                          style={{
                            background: 'transparent',
                            borderWidth: 1,
                            borderColor: '#000',
                            borderStyle: 'solid'
                          }}
                          onClick={() => {
                            if (highlightFrom == 'list') {
                              listHighlightColor({
                                id: 12,
                                colorCode: 'transparent'
                              })
                            }
                            if (highlightFrom == 'pitem') {
                              projectListHighlightColor({
                                id: 12,
                                colorCode: 'transparent'
                              })
                            }
                            if (highlightFrom == 'project') {
                              groupHighlightColor({
                                id: 12,
                                colorCode: 'transparent'
                              })
                            }
                            if (highlightFrom == 'task') {
                              taskHighlightColor({
                                id: 12,
                                colorCode: 'white'
                              })
                              setTaskSectionOpen(true)
                            }
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </>
          :
          <SwipeableDrawer
            anchor="bottom"
            open={colorPalette}
            onClose={() => setColorPalette(false)}
            onOpen={() => setColorPalette(true)}
            className='mainDrawerContainer'
          >
            <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor, paddingBottom: 25 }} >
              <div className='drawerIcon' style={{ background: colorSchemes.WebLeftPaneBg }}>
                <div></div>
              </div>
              <div className='drawerHeading' style={{ background: colorSchemes.WebLeftPaneBg }}>
                <div style={{ width: '20%' }}></div>
                <div style={{ width: '100%', textAlign: 'center', fontSize: 16, fontWeight: '600' }}>
                  Choose highlight color
                </div>
                <div className='drawerHeadingDone' onClick={() => setColorPalette(false)}>Done</div>
              </div>
              <div className='highlightColorDiv'>
                <div className='mainColorDiv'>
                  {colors &&
                    colors.map((item, index) => (
                      <div className='colorUpperDiv'>
                        <div
                          className='colorDiv'
                          style={{ background: item.colorCode }}
                          onClick={() => {
                            if (highlightFrom == 'list') {
                              listHighlightColor(item)
                            }
                            if (highlightFrom == 'pitem') {
                              projectListHighlightColor(item)
                            }
                            if (highlightFrom == 'project') {
                              groupHighlightColor(item)
                            }
                            if (highlightFrom == 'task') {
                              taskHighlightColor(item)
                              setTaskSectionOpen(true)
                            }
                          }}
                        ></div>
                      </div>
                    ))}
                  <div className='colorUpperDiv'>
                    <div
                      className='colorDiv'
                      style={{
                        background: 'transparent',
                        borderWidth: 1,
                        borderColor: '#fff',
                        borderStyle: 'solid'
                      }}
                      onClick={() => {
                        if (highlightFrom == 'list') {
                          listHighlightColor({
                            id: 12,
                            colorCode: 'transparent'
                          })
                        }
                        if (highlightFrom == 'pitem') {
                          projectListHighlightColor({
                            id: 12,
                            colorCode: 'transparent'
                          })
                        }
                        if (highlightFrom == 'project') {
                          groupHighlightColor({
                            id: 12,
                            colorCode: 'transparent'
                          })
                        }
                        if (highlightFrom == 'task') {
                          taskHighlightColor({
                            id: 12,
                            colorCode: 'white'
                          })
                          setTaskSectionOpen(true)
                        }
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        }

        {!hideShowRightPane && taskSectionOpen && !helpSupportPage && (
          <div
            style={{
              /* padding: 20, */
              minWidth: mobileView ? '100%' : 370,
              maxWidth: mobileView ? '100%' : 370,
              paddingRight: mobileView ? 0 : 0,
              paddingTop: mobileView ? 0 : 24,
              paddingLeft: mobileView ? 0 : 8,
              // backgroundColor: 'rgb(100, 120, 199)'
              // backgroundColor: colorSchemes.WebMiddlePaneBg
              backgroundColor: colorSchemes.MiddlePaneBackground,
              // backgroundColor: '#E9E4E6'
            }}
          >
            <SideTaskBar
              taskSectionOpen={taskSectionOpen}
              setTaskSectionOpen={setTaskSectionOpen}
              setTaskIndex={setTaskIndex}
              taskIndex={taskIndex}
              setTaskItem={setTaskItem}
              taskItemRef={taskItemRef}
              taskItem={taskItem}
              taskData={taskData}
              setTaskData={setTaskData}
              changeStatus={changeStatus}
              setTaskMainData={setTaskMainData}
              taskMainData={taskMainData}
              selectedProjectID={selectedProjectID}
              setSelectedProjectID={setSelectedProjectID}
              inputFileRef={inputFileRef}
              sideBarTaskNameInput={sideBarTaskNameInput}
              sideBarTaskNoteInputRef={sideBarTaskNoteInputRef}
              handleDeleteConfirm={handleDeleteConfirm}
              moveToTrash={moveToTrash}
              getTask={getTask}
              userId={userId}
              firstName={firstName}
              lastName={lastName}
              nameLetters={nameLetters}
              commentDivRef={commentDivRef}
              lastCommentDivShow={lastCommentDivShow}
              setLastCommentDivShow={setLastCommentDivShow}
              handleClickSnack={handleClickSnack}
              SlideTransition={SlideTransition}
              setDataTrashItem={setDataTrashItem}
              setColorPalette={setColorPalette}
              setPoints={setPoints}
              setHighlightFrom={setHighlightFrom}
              setDataItem={setDataItem}
              groupData={groupData}
              projectData={projectData}
              mobileView={mobileView}
              listItem={listItem}
              selectedProjectName={selectedProjectName}
              rightPaneMobileBack={rightPaneMobileBack}
              isDark={isDark}
              colorSchemes={colorSchemes}
              slideType={slideType}
              addTaskUpdateByOtherUser={addTaskUpdateByOtherUser}
              getProjectWithGroup={getProjectWithGroup}
              isDragStart={isDragStart}
              setIsDragStart={setIsDragStart}
              refreshFocusLoader={refreshFocusLoader}
              refreshLoader={refreshLoader}
              setRefreshLoader={setRefreshLoader}
              refreshFun={refreshFun}
              subTaskInputRef={subTaskInputRef}
              taskStepInputValue={taskStepInputValue}
              setTaskStepInputValue={setTaskStepInputValue}
              refreshLoaderRight={refreshLoaderRight}
              sendSocketMessage={sendSocketMessage}
              isLightMode={isLightMode}
            />
          </div>
        )
        }
      </div >
    </div >
  )
}

export default LandingPage
