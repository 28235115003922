import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css'
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Mic from '../../assets/mic.png'
import AuthLoader from '../../assets/authLoader.png'
import CalendarIcon from '../../assets/calendar.png'
//import { setTimeout } from 'timers';
import { BASE_URL, decData, encData, mixPanelTrack, post, postUser } from '../Services/Calls'



interface IProps {
    showNotify:any;
    setShowNotify:any;
    mobileView:any;
    colorSchemes:any;
    isLightMode:any;
    taskItem:any;
    notifyStatus:any;
    setNotifyStatus:any;
    notifyOnceDate:any;
    setNotifyOnceDate:any;
    updateReminder:any
}


function NotifyDialog({ showNotify,setShowNotify,mobileView,colorSchemes,isLightMode,taskItem,notifyStatus,setNotifyStatus,setNotifyOnceDate,notifyOnceDate, updateReminder}:IProps) {
 
    const [errorMsg,setErrorMsg] = useState("")

    function getMinDate(){
        var dtToday = new Date();

        let month:any = dtToday.getMonth() + 1;
        let day:any = dtToday.getDate();
        let year:any = dtToday.getFullYear();
    
        if(month < 10)
            month = '0' + month.toString();
        if(day < 10)
            day = '0' + day.toString();
    
        let maxDate = year + '-' + month + '-' + day;    
        return maxDate;
    }

    function handleClickedUpdate(){
        if(notifyStatus != '0'){
        if(notifyStatus == '2' && notifyOnceDate == ''){
            setErrorMsg('Please Select Date')
            return;
        }
     
        setNotifyStatus("0")
        setErrorMsg('')
        setNotifyOnceDate("")
        setShowNotify(false);
        console.log('notifyData',{status:notifyStatus,date:notifyOnceDate})
        
        updateReminder(notifyStatus, notifyStatus=='1'?'':notifyOnceDate)
    }
    else{
      setErrorMsg('Please Select reminder Daily or Once')
            return;
    }
    }
    function handleClickedCancle(){
        setNotifyStatus("0")
        setErrorMsg('')
        setNotifyOnceDate("")
        setShowNotify(false);
    }



  return (
    <div>
      <Dialog
        open={showNotify}
        onClose={() => { handleClickedCancle() }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {/* <ClickAwayListener onClickAway={() => { setShowNotify(false); }}> */}
          <div className='switchDialogMainDivNew' style={{ textAlign: 'center', background: !mobileView ? colorSchemes.DrawerBackColor : mobileView && isLightMode ? colorSchemes.DrawerBackColor : colorSchemes.MiddlePaneBackground, maxHeight: "90vh" }}>

            <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: !mobileView ? "rgba(233, 236, 244, 1)" : mobileView && isLightMode ? "rgba(233, 236, 244, 1)" : "rgba(23, 45, 70, 0.9)" }}>
              <div style={{ width: "70%", textAlign: "left" }}>
                <h4 style={{ color: !mobileView ? '#172D46' : mobileView && isLightMode ? '#172D46' : colorSchemes.FontColor/* colorSchemes.FontBlackColor */, margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Task Reminder</h4></div>
              <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <img style={{ width: 12, cursor: 'pointer' }} onClick={() => { handleClickedCancle() }} src={CloseMenuListIcon} alt='' />
              </div>

            </div>




            <div className="manageAccountContainer" style={{ marginTop: 0,padding:'10px 20px' }}>
<div style={{fontSize:20,fontFamily:'ManRopeSemiBold',color:!mobileView ? '#172D46' : mobileView && isLightMode ? '#172D46' : colorSchemes.FontColor}}>
Task : - {decData(taskItem.taskName, taskItem.isEncrypt).substring(0, 255)}
</div>
<div style={{marginTop:15,display:'flex',justifyContent:'space-around',alignItems:'center',width:'90%',margin:'0 auto'}}>
<div className={notifyStatus == '1' ? "notifyInnerBtnsActive" : 'notifyInnerBtns'} onClick={()=>{setNotifyStatus("1");setErrorMsg('')}}>Daily</div>
<div style={{marginLeft:10}} className={notifyStatus == '2' ? "notifyInnerBtnsActive" : 'notifyInnerBtns'} onClick={()=>{setNotifyStatus("2")}}>Once</div>
</div>
            </div>


{notifyStatus == "2" &&
<div style={{fontSize:16,color:'#000',fontFamily:'ManRopeSemiBold',padding:'0 20px',display:'flex',gap:15,justifyContent:'center',alignItems:'center'}} >
   <div style={{color:!mobileView ? '#172D46' : mobileView && isLightMode ? '#172D46' : colorSchemes.FontColor}}>Select Date :- </div> 
   <input type='date'  min={getMinDate()} value={notifyOnceDate} onChange={(e)=>{setNotifyOnceDate(e.target.value);setErrorMsg("")}} />
</div>
}

{errorMsg != "" &&
<div style={{fontSize:16,fontFamily:'ManRope',color:'red',marginTop:5}}> 
{errorMsg}
</div>
}

{/* notifyStatus != "0" && */
            <div className='add-dialog-buttons' style={{marginTop:25, justifyContent: 'space-between',padding:20 }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={handleClickedUpdate}>
                Submit
              </button>
              <button style={{
                background: '#fff',
                color: "#3068C8",
                border: '0.1px solid #3068C8', width: 105, paddingTop: '11px', paddingBottom: '11px'
              }} onClick={handleClickedCancle}>Cancle</button>
            </div>
            }

          </div>
        {/* </ClickAwayListener> */}
      </Dialog>

     
    </div>
  );
}

export default NotifyDialog;
