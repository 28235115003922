import React,{ useState }  from 'react'

import { BASE_URL, post } from '../Services/Calls'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { COLOR, COLORDARK } from '../Services/constants/Colors';

interface IProps {
    verificationStatus:any
  }

function Verification ({verificationStatus}: IProps) {
  const navigate = useNavigate();
  const [mobileView, setMobileView]: any = useState<boolean>(
    window.innerWidth <= 768
  );
  const [isDark, setIsDark]: any = useState(mobileView ? 1 : 0);
const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;

useEffect(() => {
  function updateSize() {
    if (window.innerWidth <= 768)
      setMobileView(true);
    else
      setMobileView(false);
  }
  window.addEventListener("resize", updateSize);
}, [mobileView]);

//   const [status, setStatus] = useState("0");
//   const [queryForRegister, setQueryForRegister] = useState<string | null>("");

  useEffect(() => {
    /* var myCookie = localStorage.getItem("setCookie");
    localStorage.clear();
    if (myCookie != null) {
      localStorage.setItem("setCookie", myCookie);
    } */
  }, []);


//   async function verifyRegisterEmail(code) {
//     try {
//       const data = {
//         code:code,
//       };
//     //   setLoader(true)
//       const response = await post(BASE_URL + '/verifyconfirmemaillink', data)
//       console.log('response23',response)
//       if(response.data.status == 'success'){
//         // setLoader(false)
//         setStatus("1")
//       }
//       else{
//         // setLoader(false)
//         setStatus("2")
//       }

//     } catch (ex) {
//       console.log('response',ex)
//     //   setLoader(false)
//     setStatus("2")
//     }
//   };


  return (
    <div className='container-main'>
      <h2 style={{textAlign:'center',fontWeight:'bold',marginBottom:36 ,color:colorSchemes.FontBlackColor}}>ManageStuff</h2>
     <div className='auth-container-box' style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:colorSchemes.FontBlackColor, border:"1px solid "+colorSchemes.FontGreyColor}:{}}>
    <div>
       {verificationStatus == "1" ? 
       <div>
<div style={{padding: '40px 0',
    textAlign: 'center',
    fontSize: 14}}>
Verification Succeeded!
</div>
{!localStorage.getItem("token") ? <div style={{textAlign:'center',marginTop:20,fontSize:14}}>
  <span className='makeLink' onClick={() => {
    if(!localStorage.getItem("token"))
    {
      navigate("/login")
    }
    else{
      navigate("/webapp")
    }
  }} /* onClick={()=>window.open('https://www.managestuff.com/', "_self") } */>Back to Sign In</span>
</div>:<div style={{textAlign:'center',marginTop:20,fontSize:14}}>
  <span className='makeLink' onClick={() => {
    if(!localStorage.getItem("token"))
    {
      navigate("/login")
    }
    else{
      navigate("/webapp")
    }
    }} /* onClick={()=>window.open('https://www.managestuff.com/', "_self") } */>Click to continue</span>
</div>}
       </div>
:
<>
{verificationStatus == "2" &&
<div>
<div style={{padding: '40px 0',
    textAlign: 'center',
    fontSize: 14}}>
Verification Failed. The current link has expired.
</div>
{/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',gap:50}}>
<img style={{width:80}} src={ExpiredIcon} alt="" />
<h5 style={{textAlign:'center'}}>Your reset password link has been expired.</h5>
<button  className='authBtn' onClick={() => navigate("/login")} >
       Go To Login
        </button>*/}
      </div> 
    }
    </>
}   
    </div>
    </div>
    </div>

  )
}

export default Verification
