import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css'
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
// import Login from '../Auth/Login';
// import SignUp from '../Auth/SignUp';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import completedArrowIcon from '../../assets/completedIcon.png'
import ListIconActive from '../../assets/listIconActive.png'
import ListIcon from '../../assets/listIcon.png'
import GroupIconDark from '../../assets/groupIconDark.png'
import DontAllowCheck from '../../assets/dontAllowCheck.png'
import { BASE_URL, decData, encData, post } from '../Services/Calls';
import AddProjectIcon from '../../assets/addProjectIcon.png'
import FolderIcon from '../../assets/folderIcon.png'
import AddGroupIcon from '../../assets/addGroupIcon.png'
import GroupIcon from '../../assets/groupIcon.png'


interface IProps {
    renameListForMobile:any
    setRenameListForMobile:any
    renameListInputValue:any
    setRenameListInputValue:any
    renameListForMobileFun:any
    dataItem:any
    renameListType:any
    addNewProjectForMobileFun:any
    renameGroupForMobileFun:any
    colorSchemes:any
    isDark:any
    mobileView:any
    isLightMode:any
}


function RenameListMobile(
  {
    renameListForMobile,
    setRenameListForMobile,
    renameListInputValue,
    setRenameListInputValue,
    renameListForMobileFun,
    dataItem,
    renameListType,
    addNewProjectForMobileFun,
    renameGroupForMobileFun,
    isDark,
    colorSchemes, mobileView,
    isLightMode
  }: IProps
) {




  useEffect(() => {
    
  }, []);




  
  return (
    <div>
      <Dialog
        open={renameListForMobile}
        onClose={() => { setRenameListForMobile(false) }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <ClickAwayListener onClickAway={() => { setRenameListForMobile(false) }}>
          <div className='switchDialogMainDivNew' style={{ textAlign: 'center',background:!mobileView?colorSchemes.DrawerBackColor:mobileView && isLightMode?colorSchemes.DrawerBackColor:colorSchemes.MiddlePaneBackground }}>
            <div style={{        width: "100%", display: "flex", alignItems: "center" ,padding: "20px 20px", background:!mobileView? "rgba(233, 236, 244, 1)":mobileView && isLightMode?"rgba(233, 236, 244, 1)":"rgba(23, 45, 70, 0.9)" }}>
              <div style={{width: "70%", textAlign:"left"}}>
                <h4 style={{color:!mobileView?'#172D46':mobileView && isLightMode?'#172D46':colorSchemes.FontColor /* colorSchemes.FontBlackColor */, margin:0, fontSize:mobileView?"16px":"20px", fontFamily:"ManRopeBold" }}>{renameListType=="Rename Project" ? "Rename Folder" : renameListType=="New Project"? "New Folder":renameListType}</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems:"center"}}>
              <img style={{ width: 12, cursor: 'pointer'}} onClick={() => { setRenameListForMobile(false) }} src={CloseMenuListIcon} alt='' />
            </div>
            </div>
            <div className="manageAccountContainer" /* style={{ marginTop: -15 }} */>
              
              <div style={{padding: "20px 20px 15px 20px"}}>
                <div className='shareDialogInputAndIcon' style={{ width: '100%',borderColor:!mobileView?'rgba(16, 24, 40, 0.2)':mobileView && isLightMode?'rgba(16, 24, 40, 0.2)':colorSchemes.FontColor }}>
                <div style={{ width: '100%',display: 'flex',justifyContent: 'flex-start',alignItems: 'center',gap: 5,padding:mobileView?'13px 15px':'10px 7px',background:!mobileView?'#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground /* colorSchemes.MainBackGroundColor */, borderRadius: "8px"}}>
                  <div style={{position: "absolute", marginTop: "-61px", background:!mobileView?'#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground, padding: "0px 5px" ,fontSize: "14px", color:!mobileView? "#828B94":mobileView && isLightMode? "#828B94":colorSchemes.FontColor}}>Folder Name</div>
                {/* <img style={{width:16}} src={renameListType == "Rename List"?ListIcon:mobileView?GroupIconDark:GroupIcon} alt="" /> */}
                <input
                  className='shareDialogInput'
                  style={{background:!mobileView?'#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground /* colorSchemes.MainBackGroundColor */,color:!mobileView?'#172D46':mobileView && isLightMode?'#172D46':colorSchemes.FontColor/* colorSchemes.FontBlackColor */}}
                  type='text'
                  name='name'
                  placeholder={renameListType == "Rename List" ? 'Type list name here' : 'Enter folder name'}
                  autoFocus={renameListType == "Rename List" ? true : false}
                  value={renameListInputValue}
                  onChange={(e)=>setRenameListInputValue(e.target.value)}
                //   onBlur={()=> {if(renameListType == "Rename List")
                //   {
                //     renameListForMobileFun()
                // }
                // else{
                //     addNewProjectForMobileFun()
                // }
                // }
                // }
                  // onKeyDown={handleKeyDownForProject}
                  autoComplete='off'
                />
              </div>
              </div>

              </div>

              <div className='shareDialogInviteBtn' style={{ display: 'flex', marginTop:mobileView?18:25,justifyContent:'space-between', marginBottom:mobileView?28:45, padding: "0px 15px" }}>
                <button
                  style={{
                    background: '#fff' /* colorSchemes.MainBackGroundColor */,
                    color: "#3068C8",
                    border: '0.1px solid #3068C8',
                  }}
                  onClick={() => { setRenameListForMobile(false);}}
                >
                  Cancel
                </button>
                <button
                  style={{ background: '#3068C8', color: '#fff'/* ,opacity:projectsInputValue== "" ? 0.65 :1 */}}
                  onClick={()=>{ 
                    if(renameListType == "Rename List")
                  {
                    setRenameListForMobile(false);renameListForMobileFun()
                }
                else if(renameListType == "Rename Project")
                  {
                    setRenameListForMobile(false);renameGroupForMobileFun()
                }
                else{
                    setRenameListForMobile(false); addNewProjectForMobileFun()
                }
                    }}
                >
                  Create Folder
                </button>

              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Dialog>
    </div>
  );
}

export default RenameListMobile;
