import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import Login from '../Auth/Login';
import SignUp from '../Auth/SignUp';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


interface IProps {
    openAddAccountLogin: any
    setOpenAddAccountLogin: any
    openAddAccountSignup: any
    setOpenAddAccountSignUp: any
    setOpenSwitchAccountDialog: any
    mobileView: any
    isDark: any
    colorSchemes: any
}


function AddAccount({ 
    openAddAccountLogin,
    setOpenAddAccountLogin,
    openAddAccountSignup,
    setOpenAddAccountSignUp,
    setOpenSwitchAccountDialog,
    mobileView,
    isDark,
    colorSchemes
    }: IProps) {


//   const [verificationStatus,setVerificationStatus] = useState("0");

  useEffect(() => {
    
  }, []);


  return (
      <div>
        {!mobileView ?
<Dialog
                    open={openAddAccountLogin}
                    onClose={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <ClickAwayListener onClickAway={()=>{setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}>
                    <div className='switchDialogMainDiv' style={{ textAlign: 'center', maxHeight:"unset", maxWidth:"unset", margin:"0px auto", minWidth:"unset" }}>
                    <div style={{ textAlign: 'end', width: '100%' }}>

                    <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Switch Account</h4></div>
            <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <img style={{ width: 12, cursor: 'pointer' }} onClick={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}} src={CloseMenuListIcon}  alt='' />
            </div>
          </div>

                        {/* <img style={{ width: 16, cursor: 'pointer' }} onClick={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}} src={CloseMenuListIcon} alt='' /> */}
                      </div>
                      {!openAddAccountSignup ?
                      <Login openAddAccountLogin={openAddAccountLogin} 
                             setOpenAddAccountLogin={setOpenAddAccountLogin} 
                             openAddAccountSignup={openAddAccountSignup} 
                             setOpenAddAccountSignUp={setOpenAddAccountSignUp} 
                             mobileView={mobileView}
                             />
                      :
                      <SignUp openAddAccountLogin={openAddAccountLogin} 
                              setOpenAddAccountLogin={setOpenAddAccountLogin} 
                              openAddAccountSignup={openAddAccountSignup} 
                              setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                              mobileView={mobileView}
                      />
                      }
                    </div>
                    </ClickAwayListener>
                  </Dialog>
                  :
                  <SwipeableDrawer
        anchor="bottom"
        open={openAddAccountLogin}
        onClose={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}
        onOpen={() => setOpenAddAccountLogin(true)}
        className='mainDrawerContainer'
      >
        <div style={{background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey}}>
        <div className='drawerIcon' style={{background:colorSchemes.WebLeftPaneBg}}>
          <div ></div>
        </div>
        <div className='drawerHeading' style={{background:colorSchemes.WebLeftPaneBg}}>
          <div style={{ width: '20%' }}></div>
          <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
          Switch Accounts
          </div>
          <div className='drawerHeadingDone' onClick={()=>{setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}>Done</div>
        </div>
                    <div className='switchDialogMainDiv' style={{ textAlign: 'center',background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey, minHeight: "600px"}}>
                    {/* <div style={{ textAlign: 'end', width: '100%' }}>
                        <img style={{ width: 16, cursor: 'pointer' }} onClick={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}} src={CloseMenuListIcon} alt='' />
                      </div> */}
                      {!openAddAccountSignup ?
                      <Login openAddAccountLogin={openAddAccountLogin} 
                             setOpenAddAccountLogin={setOpenAddAccountLogin} 
                             openAddAccountSignup={openAddAccountSignup} 
                             setOpenAddAccountSignUp={setOpenAddAccountSignUp} 
                             mobileView={mobileView}
                             />
                      :
                      <SignUp openAddAccountLogin={openAddAccountLogin} 
                              setOpenAddAccountLogin={setOpenAddAccountLogin} 
                              openAddAccountSignup={openAddAccountSignup} 
                              setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                              mobileView={mobileView}
                      />
                      }
                    </div>
                    </div>
        </SwipeableDrawer>
    }
      </div>
    );
}

export default AddAccount;
