import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, Modal } from "@material-ui/core";
import "./Cookies.css";
import { COLOR, COLORDARK } from "../Services/constants/Colors";

const Cookies = () => {
  const [cookie, setCookie] = useState(true);
  const [mobileView, setMobileView]: any = useState<boolean>(
    window.innerWidth <= 768
  );
  const [isDark, setIsDark]: any = useState(mobileView ? 1 : 0);
const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;
  function Accept() {
    localStorage.setItem("setCookie", "0");
    setCookie(false);
  }
  function Decline() {
    localStorage.setItem("setCookie", "1");
    setCookie(false);
  }
  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768)
        setMobileView(true);
      else
        setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  return (
        <>
      {cookie ? (
        <div className={mobileView?'mobileCookieDiv':''}>
        <div className="mainCookieDiv" style={{backgroundColor:colorSchemes.DrawerBackColor,color:colorSchemes.FontBlackColor}}>
          <div className="cookieContent">
            <div className="contentDiv">
            <div>
              <span style={{fontSize: 12}}>We use cookies to offer you a better experience, analyze site
                traffic, and serve targeted advertisements. 
                By continuing to use this website, you consent to the use of
                cookies in accordance with our Cookie Policy.</span>
            </div>
            </div>
            <div className="Btn_div">
              <div className="accept" onClick={() => Accept()}>
                Accept
              </div>
              {/* <div className="decline" onClick={() => Decline()}>
                Decline
              </div> */}
            </div>
          </div>
        </div>
        </div>
      ) : null}
      </>
  );
};

export default Cookies;
