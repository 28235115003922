import React,{ useEffect, useState }  from 'react'
import styles from './AuthStyles'
import MainLogo from '../../assets/mainLogo1.png'
import NewMSLogo from '../../assets/newMSLogo.png'
import CharacterCactus from '../../assets/characterCactus.png'
import LeftArrow from '../../assets/left-arrow.png'
import AuthLoader from '../../assets/authLoader.png' 
import { BASE_URL, mixPanelTrack, post } from '../Services/Calls'
import RegisterSuccessIcon from '../../assets/registerSuccess.png' 
import { Base64 } from 'js-base64';
import NickNameIcon from '../../assets/nickName.png' 
import NickNameActiveIcon from '../../assets/nickNameActive.png' 
import { useNavigate } from 'react-router-dom';
import Cookies from '../Cookies/Cookies'
import { COLOR, COLORDARK } from '../Services/constants/Colors'

interface IProps {
  mobileView:any,
  }

function ForgotPassword ({
  mobileView,
  }: IProps) {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [resetMsg, setResetMsg] = useState(false);
  const [emailMsg, setEmailMsg] = useState<string>("");
  const [emailValue, setEmailValue] = useState("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [showAlreadyLine, setShowAlreadyLine] = useState(false);
  const [emailInputFocus, setEmailInputFocus] = useState(false);
  const [isDark, setIsDark]: any = useState(mobileView?1:0);
  const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;


  useEffect(()=>{
    mixPanelTrack("forgotPasswordView",{"forgotPasswordView":"forgotPasswordView"})
  },[])

  const handleResetPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const form_data:any = e.currentTarget.elements;
    
    try {

      if (form_data[0].value == "") {
        setEmailMsg("Can't be empty");
      }
      if (form_data[0].value != "") {
        let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!pattern.test(form_data[0].value)) {
        setEmailMsg("Invalid input");
        return;
        }
      }
      if (
        form_data[0].value == "" 
      ) {
        return;
      }
    
      let tempEmail=form_data[0].value
      tempEmail=tempEmail.toLowerCase();
      const data = {
        email: Base64.encode(tempEmail),
      };
      console.log("data123456", data);
      setEmailValue(tempEmail)      
      setLoader(true)
      const response = await post(BASE_URL + '/sendresetpasswordlink', data)
      console.log('response23',response)
      if(response.data.status == 'success'){
        setResetSuccess(true)
        setLoader(false)
        const response1 = await post(BASE_URL + '/sendresetpasswordlinkemail', data)
        if(response1.data.status == 'success'){
        }
         setLoader(false)
        setResetSuccess(true)
      }
      else{
        // setEmailMsg('Email ID not exist.')
        setResetSuccess(true)
         setLoader(false)
      }

    } catch (ex) {
      console.log('response',ex)
       setLoader(false)
    }
  };


  return (
    <div style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor,  height:"100vh", display:"flex", justifyContent:"center"}:{}}>
    <div className='container-main'
    style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:colorSchemes.FontBlackColor, margin:mobileView?"unset":"50px auto"}:{}}>
      {localStorage.getItem("setCookie") == null && <Cookies />}
      {/* <h2 style={{textAlign:'center',fontWeight:'bold',marginBottom:36}}>ManageStuff</h2> */}
      {(localStorage.getItem("token")==null) && mobileView ?
           <div style={{ textAlign: 'center', marginBottom:"5%", padding : "10% 0%", background:"rgba(48, 104, 200, 1)", width:"100%", 
           borderBottomLeftRadius:"20px", borderBottomRightRadius:"20px"}}>
            <img style={{ width: "180px" }} src={NewMSLogo} alt="" />
            </div>:null
        }
       <div className='auth-container-box' style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:colorSchemes.FontBlackColor, border:mobileView?"unset":"1px solid "+colorSchemes.FontGreyColor, height:mobileView?"unset": 600,width:mobileView?"unset":920}:{height:mobileView?"unset": 600,width:mobileView?"unset":920}}>
       {!resetSuccess ?
       <div style={{width:mobileView?"100%": "45%", padding: mobileView?"0px":"40px 50px 32px"}}>
         
         <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 50, fontSize:mobileView?20: 32,fontFamily:'ManRopeSemiBold' }}>Reset Password</h2>
        <form onSubmit={handleResetPasswordSubmit}>

        <div className={emailInputFocus?'inputContainerDivActive':'inputContainerDiv'}
        style={isDark==1 && emailInputFocus? {borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid #4772fa"}: isDark==1?{borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid "+colorSchemes.FontGreyColor}:{}}>
          <div style={{position: "absolute", marginTop: "-50px", background: "#fff", padding: "0px 5px" ,fontSize: "14px", color: emailInputFocus? '#4772fa' : "#828B94",marginLeft:10}}>Email *</div>
           {/* {emailInputFocus?
<img style={{width:20}} src={NickNameActiveIcon} alt="" />
:
<img style={{width:20}} src={NickNameIcon} alt="" />
}

 */}
<div style={{width:'100%'}}>
<input className='inputFeild' type="text" name="email" placeholder='Email' onChange={()=>{setAlreadyExist(false);setShowAlreadyLine(false)}}  onFocus={() => {setEmailMsg("");setErrorMsg("");setEmailInputFocus(true);setShowAlreadyLine(false)}} onBlur={(e)=>{setEmailInputFocus(false);
if (e.target.value != "") {
  let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  if (!pattern.test(e.target.value)) {
  setEmailMsg("Invalid input");
  }
}
}}
style={isDark==1? {backgroundColor:mobileView?"transparent":colorSchemes.DrawerBackColor, color:colorSchemes.FontBlackColor}:{}}/>
</div>
</div>
           <div className='errorMsgLine' style={{minHeight:30}}>
           {showAlreadyLine && <>
             {alreadyExist && 
             <span style={styles.inputErrorMsg}>Incorrect username or password</span>
                         }
                          </>}
            <span style={styles.inputErrorMsg}>{emailMsg}</span>
             </div>

      
        {loader ?
    <button className='authBtnLoader' style={{marginTop:5}}>
    Reset Password...
    </button>
  :
        <button className='authBtn' type="submit" style={{marginTop:5}}>
       Reset Password
        </button>
}

</form>
<div style={{textAlign:'center',marginTop:20,fontSize:14}}>
  <span className='makeLink' onClick={() => navigate("/login")}>Back to Sign In</span> | <span onClick={() => navigate("/signup")} className='makeLink'>Sign Up</span> 
</div>
       </div>
       :
       <div style={{width:mobileView?"100%": "45%", padding: mobileView?"0px":"40px 50px 32px"}}>
        <div className='sentEmailMsg'>
        If your email exists in our system, then an email with further instructions has been sent. <br />
(This may take a few minutes, check you junk mail folder)
        {/* An email with further instructions has been sent <br /> to {emailValue}. Please check. */}
        </div>
        <div className='makeLink' onClick={() => navigate("/login")} style={{textAlign:'center',paddingTop:30}}>
        Back to Sign In
        </div>
        </div>
}
<div style={{width: '15%',height: '100%',display: mobileView?'none': 'flex', justifyContent: 'flex-start',alignItems: 'flex-end'}}>
<img style={{/* width:"65%" */ scale:'0.9',marginBottom: '-25px', marginLeft: '-60px' }} src={CharacterCactus} alt="" />
</div>

            <div style={{display:mobileView?'none': 'block', backgroundColor:"green", width:"40%", height:"100%", background: "rgba(48, 104, 200, 1)", borderRadius:"40px"}}>
            <div style={{ textAlign: 'center', marginTop:"8%", marginBottom:"5%"}}><img style={{ /* width: 20 */ }} src={NewMSLogo} alt="" /></div>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color:"#ffffff", fontSize: 32 }}>Your Stuff, Your Way,</div>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color:"#ffffff", fontSize: 32 }}>Your Success</div>
            
            {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"flex-end"}} className="blue-box">
            <img style={{width:"65%" }} src={CharacterWorkingLaptop} alt="" />
            <img style={{ height:"70%", marginLeft:"5%"}} src={Cactus} alt="" />
            </div> */}
            </div>
          
    </div>
    </div>
  </div>
  )
}

export default ForgotPassword
