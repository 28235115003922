import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import introDot1 from '../../assets/introDot1.png'
import introDot2 from '../../assets/introDot2.png'

interface IProps { 
    setShowIntroScreen:any
}
function IntroScreen({setShowIntroScreen}: IProps) {

  const [mobileView, setMobileView]: any = useState<boolean>(
    window.innerWidth <= 768
  );

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768)
        setMobileView(true);
      else
        setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);



  const [introValue, setIntroValue] = useState<string | null>("0");
  
function readyToGo(){
    setShowIntroScreen(false)
    localStorage.setItem("intro","1")
}

function skipFun(){
    setIntroValue("3");
    setShowIntroScreen(false)
    localStorage.setItem("intro","1")
}


  return (
    <div style={{background:'rgba(0,0,0,0.7)',position:'fixed',top:0,bottom:0,left:0,right:0,zIndex:1}}>
        {introValue == "0" &&
  <div className="introContainer introLeft">
    <div className="introContent">
      <h5>Add List</h5> 
      <span className='introScreenSkip' onClick={()=>skipFun()}>Skip</span>
      <p>Welcome to ManageStuff! You can begin to add a new list to click on "New List".</p>
      <div className='introScreenDotDiv'>
       <img src={introDot1} alt="" />
       <img src={introDot2} alt="" />
       <img src={introDot2} alt="" />
      </div>
      <div className='introScreenBtn'>
      <button
                  style={{ background: 'rgb(71,114,250)', color: '#fff'}}
                  onClick={()=>{setIntroValue("1")}}
                >
                  Next
                </button>
                </div>
    </div>
  </div>
  }
        {introValue == "1" &&
  <div className="introContainer introLeft1">
    <div className="introContent">
      <h5>Add Project</h5>
      <span className='introScreenSkip' onClick={()=>skipFun()}>Skip</span>
      <p>You can begin to add a new project to click on "New Project".</p>
      <div className='introScreenDotDiv'>
       <img src={introDot2} alt="" />
       <img src={introDot1} alt="" />
       <img src={introDot2} alt="" />
      </div>
      <div className='introScreenBtn'>
      <button
                  style={{ background: 'rgb(71,114,250)', color: '#fff'}}
                  onClick={()=>{setIntroValue("2")}}
                >
                  Next
                </button>
                </div>
    </div>
  </div>
  }
        {introValue == "2" &&
  <div className="introContainer introLeft2">
    <div className="introContent">
      <h5>Create a task</h5>
      <p>Add a task in the field and press enter to save.</p>
      <div className='introScreenDotDiv'>
       <img src={introDot2} alt="" />
       <img src={introDot2} alt="" />
       <img src={introDot1} alt="" />
      </div>
      <div className='introScreenBtn'>
      <button
                  style={{ background: 'rgb(71,114,250)', color: '#fff'}}
                  onClick={()=>{setIntroValue("3");readyToGo()}}
                >
                  Ready to go
                </button>
                </div>
    </div>
  </div>
  }


    </div>
  );
}

export default IntroScreen;
