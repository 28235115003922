import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import './HomePage.css'
import styles from '../Navbar/NavStyles'
import UnSelect from '../../assets/dry-clean.png'
import CheckCircleFill from '../../assets/check-circle-fill.png'
import Select from '../../assets/check-mark.png'
import AddTaskIcon from '../../assets/addTaskIcon.png'
import AddProjectIcon from '../../assets/addProjectIcon.png'
import BlueAddIcon from '../../assets/blueAddIcon.png'
import Exit from '../../assets/exit.png'
import Delete from '../../assets/delete.png'
import Restore from '../../assets/restore.png'
import DeleteComment from '../../assets/deleteComment.png'
import WhiteDots from '../../assets/WhiteDots.png'
import BlueBackArrow from '../../assets/blueBackArrow.png'
import CrossIconImage from '../../assets/whiteCrossIconImage.png'
import TimeSelectBlackArrow from '../../assets/rightBlackArrow.png'
import completedArrowIcon from '../../assets/completedIcon.png'
import RefreshIcon from '../../assets/refreshIcon.png'
import Whiteloading from '../../assets/whiteloading.png'
import MobileCircle from '../../assets/mobileCircle.png'
import AvatarMiddlePane from '../../assets/avatarMiddlePane.png'
import Rightpaneback from '../../assets/rightpaneback.png'
import sunnyDay from '../../assets/sunny-day.png'
import Important from '../../assets/star.png'
import Planning from '../../assets/planning.png'
 import moment, { min } from 'moment'
import Assingned from '../../assets/assingned.png'
import Tasks from '../../assets/tasks.png'
import Calendar from '../../assets/calendar.png'
import RedDelete from '../../assets/redDelete.png'
import MarkerIcon from '../../assets/marker.png'
import Bell from '../../assets/bell.png'
import EditHeadingIcon from '../../assets/edit-Heading.png'
import TurnIntoTask from '../../assets/turnIntoTask.png'
import CommentSendIcon from '../../assets/commentSendIcon.png'
import Repeat from '../../assets/repeat.png'
import AddFlieIcon from '../../assets/paper-clip.png'
import RemindWatch from '../../assets/remindWatch.png'
import AttacthedFileIcon from '../../assets/attacthedFileIcon.png'
import PickerIcon from '../../assets/picker.png'
import CloseIcon from '../../assets/close.png'
import AddFileLogo from '../../assets/addFileLogo.png'
import Loading from '../../assets/loading.png'
import AlarmClock from '../../assets/alarm-clock.png'
import BellGreyIcon from '../../assets/bell1234.png'
import LeftArrowLightGreyIcon from '../../assets/leftArrowLightGreyIcon.png'
import { BASE_URL, FEKY, decData, encData, post, postUser } from '../Services/Calls'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import CommentDeleteCrossIcon from '../../assets/commentDeleteCrossIcon.png'
import BackGroundImgForNotes from '../../assets/backGroundImgForNotes.png'
import TaskNoteIcon from '../../assets/taskNote.png'
import MoreIcon from '../../assets/more.png'
import Linkify from 'react-linkify';
import { LinkItUrl } from 'react-linkify-it';
import Dialog from '@mui/material/Dialog'
import ImageViewer from './ImageViewer'
import CryptoJS from 'crypto-js'
import ThreeDots from '../../assets/three-dots.png'
import GreyThreedots from '../../assets/greyThreedots.png'
import RefreshGreyIcon from '../../assets/RefreshGreyIcon.png'
import RightPaneCloseIcon from '../../assets/rightPaneCloseIcon.png'
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Base64 } from 'js-base64'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import {PullDownContent, ReleaseContent, RefreshContent, PullToRefresh} from "react-js-pull-to-refresh";
import PullToRefresh from 'react-simple-pull-to-refresh';
import axios from 'axios'
import NotifyDialog from './NotifyDialog'


interface IProps {
  setTaskIndex: any
  taskIndex: any
  taskSectionOpen: any
  setTaskSectionOpen: any
  taskData: any
  setTaskData: any
  setTaskItem: any
  taskItemRef: any
  taskItem: any
  changeStatus: any
  setTaskMainData: any
  taskMainData: any
  selectedProjectID: any
  inputFileRef: any
  setSelectedProjectID: any
  sideBarTaskNameInput: any
  sideBarTaskNoteInputRef: any
  handleDeleteConfirm: any
  moveToTrash: any
  getTask: any
  userId: any
  firstName: any
  lastName: any
  nameLetters: any
  commentDivRef: any
  lastCommentDivShow: any
  setLastCommentDivShow: any
  handleClickSnack: any
  SlideTransition: any
  setDataTrashItem: any
  setColorPalette: any
  setPoints: any
  setHighlightFrom: any
  setDataItem: any
  groupData: any
  projectData: any
  mobileView: any
  listItem: any
  selectedProjectName: any
  rightPaneMobileBack: any
  isDark: any
  colorSchemes: any
  slideType: any
  addTaskUpdateByOtherUser: any
  getProjectWithGroup: any
  isDragStart: any
  setIsDragStart: any
  refreshLoader: any
  setRefreshLoader: any,
  refreshFun: any,
  subTaskInputRef: any,
  refreshFocusLoader: any,
  refreshLoaderRight: any,
  sendSocketMessage: any,
  taskStepInputValue: any,
  setTaskStepInputValue: any,
  isLightMode: any,
}

function SideTaskBar({
  // setTaskItem,
  taskIndex,
  sendSocketMessage,
  setTaskIndex,
  // taskItem,
  taskSectionOpen,
  setTaskSectionOpen,
  taskData,
  setTaskData,
  taskItemRef,
  setTaskItem,
  taskItem,
  changeStatus,
  setTaskMainData,
  taskMainData,
  selectedProjectID, inputFileRef,
  setSelectedProjectID,
  sideBarTaskNameInput,
  sideBarTaskNoteInputRef,
  handleDeleteConfirm,
  moveToTrash,
  getTask,
  userId,
  firstName,
  lastName,
  nameLetters,
  commentDivRef,
  lastCommentDivShow,
  setLastCommentDivShow,
  handleClickSnack,
  SlideTransition,
  setDataTrashItem,
  setColorPalette,
  setPoints,
  setHighlightFrom,
  setDataItem,
  groupData,
  projectData,
  mobileView,
  listItem,
  selectedProjectName,
  rightPaneMobileBack,
  isDark,
  colorSchemes,
  slideType,
  addTaskUpdateByOtherUser,
  getProjectWithGroup,
  isDragStart,
  setIsDragStart,
  refreshLoader,
  refreshFocusLoader,
  setRefreshLoader,
  refreshFun,
  subTaskInputRef,
  refreshLoaderRight,
  taskStepInputValue,
  setTaskStepInputValue,
  isLightMode
}: IProps) {


  const [menuOpen, setMenuOpen] = useState(false)
  const [taskStepAddBtn, setTaskStepAddBtn] = useState(false)
  const [commentSwipe, setCommentSwipe] = useState(false)
  const [uploadFileLoader, setUploadFileLoader] = useState(false)
  const [subTaskLeftClicked, setSubTaskLeftClicked] = useState(false)
  const [rightPaneMenu, setRightPaneMenu] = useState(false)
  const [remindMeMenu, setRemindMeMenu] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [addSubTaskDiv, setAddSubTaskDiv] = useState(false)
  const [dueDateDiv, setDueDateDiv] = useState(false)
  const [subTaskItem, setSubTaskItem] = useState<any>({})
  const [editSubTaskItem, setEditSubTaskItem] = useState<any>({})
  const [lastComment, setLastComment] = useState<any>({})
  const [textAreaValue, setTextAreaValue] = useState('')
  const [fileCollapse, setFileCollapse] = useState(false)
  const [commentCollapse, setCommentCollapse] = useState(true)
  const [showSelectTime, setShowSelectTime] = useState(false)
  const [commentTextArea, setCommentTextArea] = React.useState('')
  const [filesDrawer, setFilesDrawer] = useState(false)
  // const [addTaskNote, setAddTaskNote] = useState(taskItem.notes)
  // const [fileList, setFileList] = useState([])

  const imgURL: any = useRef('')
  // const fileUploadPercent:any = useRef('0')
  const fileTypeName: any = useRef('')
  const fileName: any = useRef('')
  const fileItem: any = useRef({})


  // const fileUploadingTime:any = useRef({})
  const [fileUploadingTime, setFileUploadingTime]: any = React.useState({})
  const [fileUploadPercent, setFileUploadPercent] = React.useState(0)

  const SubTaskNameInputRef: any = useRef()
  const timerRef: any = useRef(0);
  const preventRef: any = useRef(false);
  const commentDrawerDivRef: any = useRef(null)
  const calRef: any = useRef(null)
  //const commentDivRef:any = useRef(null)
  const mainCommentDivRef: any = useRef(null)
  const commentDivScrollTopRef: any = useRef(null)
  const commentDivScrollHeightRef: any = useRef(null)
  const [commentDivScrollTop, setCommentDivScrollTop]: any = useState(null)
  const [commentDivScrollHeight, setCommentDivScrollHeight]: any = useState(null)
  const [openImageViewer, setOpenImageViewer] = React.useState(false)
  const [showNotify, setShowNotify]: any = useState(false)
  const [notifyStatus, setNotifyStatus]: any = useState('0')
  const [notifyOnceDate, setNotifyOnceDate]: any = useState("")
  const [selectTimeOption, setSelectTimeOption]: any = useState("custom");
  const [minTodayDateTime, setMinTodayDateTime]: any = useState(new Date())
  const [todayDateTime, setTodayDateTime]: any = useState(new Date());
  const [TomorrowDateTime, setTomorrowDateTime]: any = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [remindDiv, setRemindDiv] = useState(false)
  const [hourVal, setHourVal]:any = useState(1)
  const [minuteVal, setMinuteVal]:any = useState(0)
  const [hourData, setHourData] = useState([{label:'01', value:1},{label:'02', value:2},{label:'03', value:3},{label:'04', value:4},{label:'05', value:5},{label:'06', value:6},{label:'07', value:7},{label:'08', value:8},{label:'09', value:9},{label:'10', value:10},{label:'11', value:11},{label:'12', value:12}, {label:'13', value:13}, {label:'14', value:14}, {label:'15', value:15}, {label:'16', value:16}, {label:'17', value:17},{label:'18', value:18}, {label:'19', value:19}, {label:'20', value:20}, {label:'21', value:21}, {label:'22', value:22}, {label:'23', value:23}, {label:'24', value:24}])
  const [minuteData, setMinuteData] = useState([{label:'00', value:0},{label:'15', value:15},{label:'30', value:30},{label:'45', value:45}])
  const [showCalendar, setShowCalendar] = useState(false)
  const [menuPosition, setMenuPosition] = useState<any>(null)
  const [remindMeMenuPosition, setRemindMeMenuPosition] = useState<any>(null)
  //const [lastCommentDivShow, setLastCommentDivShow] = React.useState(false)


  function getminHourValDate(){
    return minTodayDateTime.getHours();
  }
  function getminMinuteValDate(){
    var dtToday = new Date();
    return minTodayDateTime.getMinutes();
  }
  
  function getTodayDate(){
    var dtToday = new Date();

    let month:any = dtToday.getMonth() + 1;
    let day:any = dtToday.getDate();
    let year:any = dtToday.getFullYear();

    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();

    let maxDate = year + '-' + month + '-' + day;    
    return maxDate;
}

function getTomorrowDate(){
  var dtTomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  let month:any = dtTomorrow.getMonth() + 1;
  let day:any = dtTomorrow.getDate();
  let year:any = dtTomorrow.getFullYear();

  if(month < 10)
      month = '0' + month.toString();
  if(day < 10)
      day = '0' + day.toString();

  let maxDate = year + '-' + month + '-' + day;    
  return maxDate;
}



  const handleClickOpenImageViewer = () => {
    setOpenImageViewer(true)
  }
  const handleCloseImageViewer = () => {
    setOpenImageViewer(false)
  }

  useEffect(() => {
    //checkReminder()
    // console.log('taskData[taskIndex]', tset)
    console.log('taskData[taskIndex]', taskData[taskIndex])
    console.log('taskItem852', taskItem)
    /* if(mobileView){
      setAddSubTaskDiv(true)
    } */
  }, [])


  function addTaskStepToList() {
    console.log('taskStepInputValue', taskStepInputValue)
    if (taskStepInputValue != '') {
      console.log('taskItem', taskItem.task)
      let data = [...taskItem.task]
      let taskStepJson = {
        id: encData("1"),
        taskStepTitle: taskStepInputValue,
        status: "0"
      }
      data.push(taskStepJson)
      let json = {
        task: data,
      }
      setTaskStepInputValue('')
      setTaskStepAddBtn(false)
      let mainData = [...taskData]
      for (let i = 0; i < mainData[0].length; i++) {
        if (mainData[0][i].id == taskItem.id) {
          mainData[0][i].task = json.task
          mainData[0][i].subTaskData = JSON.stringify(json.task)
          console.log('mainData[0][i]', mainData[0][i])
          setTaskItem(mainData[0][i])
          taskItemRef.current = mainData[0][i];
          updateSubTask(mainData[0][i])
        }
      }
      setTaskData(mainData)
      console.log('mainData45', mainData)
    }
  }

  function changeTaskStepStatus(item) {
    console.log('item4545', item)
    if (item.status == "0") {
      item.status = "1"
    }
    else {
      item.status = "0"
    }
    let data: any = [...taskItem.task]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].status = item.status
      }
    }
    let mainData = [...taskData]
    for (let i = 0; i < mainData[0].length; i++) {
      if (mainData[0][i].id == taskItem.id) {
        mainData[0][i].task = data
        mainData[0][i].subTaskData = JSON.stringify(data)
        console.log('mainData[0][i]', mainData[0][i])
        setTaskItem(mainData[0][i])
        taskItemRef.current = mainData[0][i]
        updateSubTask(mainData[0][i])
      }
    }
    setTaskData(mainData)
    console.log('mainData4545', mainData)
  }


  async function updateSubTask(item) {
    try {
      const response = await post(BASE_URL + '/updatesubtask', item)
      try {
        let json = { taskId: taskItemRef.current.id };
        sendSocketMessage("update subtask", json)
      }
      catch (ex) {

      }
      console.log('response232323', response)
      setEditSubTaskItem({});
      getTask(userId, true)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function updateReminder(status, reminderDate, remindMeDateOriginal, remindMeDateISO, remindMeDateISOString, 
remindMeDateISOTime, remindMeDateOriginalTime) {
    try {
      let item:any={status:status, reminderDate:reminderDate, taskId:taskItemRef.current.id,
        remindMeDateOriginal:remindMeDateOriginal, remindMeDateISO:remindMeDateISO, remindMeDateISOString:remindMeDateISOString, 
        remindMeDateISOTime:remindMeDateISOTime, remindMeDateOriginalTime:remindMeDateOriginalTime}
      let tempItem:any={...taskItem};
      tempItem.remindMeStatus=status;
      if(reminderDate=='')
      {
      tempItem.remindMeDate=null;
      tempItem.remindMeDateOriginalTime=null;
      }
      else{
        tempItem.remindMeDate=reminderDate;
        tempItem.remindMeDateOriginalTime=remindMeDateOriginalTime;
      }
      taskItemRef.current=tempItem;
      setTaskItem(tempItem);
      const response = await post(BASE_URL + '/updatereminder', item)
      try {
        let json = { taskId: taskItemRef.current.id };
        sendSocketMessage("update subtask", json)
      }
      catch (ex) {

      }
      
      console.log('response232323', response)
      getTask(userId, true)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setTaskStepInputValue(event.target.value)
  }

  const handleKeyDown = (event: { key: any }) => {
    if (event.key === 'Enter') {
      addTaskStepToList()
      setAddSubTaskDiv(false)
      //   console.log('User pressed: ', event.key)
    }
  }


  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploadFileLoader(true)
    let mimeType = "image/png";
    if (e.target.files) {
      try {
        let tempFiles: any = e.target.files;
        for (var i = 0; i < tempFiles.length; i++) {
          mimeType = tempFiles[i].type;
          console.log("Filename: " + tempFiles[i].name);
          console.log("Type: " + tempFiles[i].type);
          console.log("Size: " + tempFiles[i].size + " bytes");

        }
      }
      catch (ex) {

      }

      var formData: any = new FormData()
      formData.append('taskImage', e.target.files[0])
      formData.append('taskid', taskItem.id)
      formData.append('fileType', mimeType)
      try {
        /* const axios = axios.create({
          baseURL: BASE_URL,
        }) */

        const response: any = await axios.post(BASE_URL + "/uploadtaskphoto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data: any) => {
            //Set the progress value to show the progress bar
            console.log("Percentage", Math.round((100 * data.loaded) / data.total))
            setFileUploadPercent(Math.round((100 * data.loaded) / data.total));
            setFileUploadingTime(taskItem)
            //console.log("Percentage", data)
          },
        })
        // const response = await postUser(
        //   BASE_URL + '/uploadtaskphoto',
        //   formData
        // )
        // console.log('response2323', response)
        if (response.data.status == 'success') {
          addTaskUpdateByOtherUser(taskItem, 'uploadFile')
          inputFileRef.current.value = "";
          try {
            let json: any = {};
            json.taskId = taskItem.id;
            sendSocketMessage("upload photo", json)
          }
          catch (ex) {

          }
          let data: any = [...taskMainData]
          for (let i = 0; i < data.length; i++) {
            if (data[i].id == taskItem.id) {
              // data[i].img.push(response.data.data[0])
              data[i].img.splice(0, 0, response.data.data[0]);
              setTaskItem(data[i])
              taskItemRef.current = data[i]
            }
          }
          setUploadFileLoader(false)
          // data[taskIndex].img.push(response.data.data[0])
          setTaskMainData(data)
          setTaskData([data])
        } else {
          setUploadFileLoader(false)
        }
      } catch (ex) {
        setUploadFileLoader(false)
        console.log('response9211', ex)
      }
    }
  }

  async function removeFiles(item) {
    console.log('item', item)
    console.log('taskItem', taskItem)
    let json: any = {
      imgId: item.id
    }
    try {
      const response = await post(BASE_URL + '/deletetaskimage', json)
      console.log('response232323', response)
      if (response.data.status == 'success') {
        try {
          json.taskId = taskItemRef.current.id;
          sendSocketMessage("delete file", json)
        }
        catch (ex) {

        }
        let data: any = [...taskMainData]
        for (let i = 0; i < data[taskIndex].img.length; i++) {
          if (data[taskIndex].img[i].id == item.id) {
            data[taskIndex].img.splice(i, 1)
            setTaskItem(data[taskIndex])
            taskItemRef.current = data[taskIndex];
          }
        }
        setTaskMainData(data)
        setTaskData([data])
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
    // for(let i=0;i<data[taskIndex].img.length;i++){
    //   if(data[taskIndex].img[i].id == item.id){
    //     data[taskIndex].img.splice(i,1);
    //   }
    // }
    // setTaskMainData(data)
    // setTaskData([data])
  }

  async function handleChangeText(event: any) {
    console.log('event.target.innerText', event.target.innerText)
    let data: any = [...taskMainData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == taskItem.id) {
        data[i].notes = event.target.innerText
      }
    }
    // setAddTaskNote(event.target.value);
    // console.log('data', data)
    setTaskMainData(data)
    setTaskData([data])
  }

  async function AddTaskNoteApi(note, id) {
    console.log('taskItem', taskItem)
    let json: any = {
      taskid: id,
      notes: note,
      isEncryptNotes: "1"
    }
    try {
      const response = await post(BASE_URL + '/updatetasknotes', json)
      console.log('response232323', response)
      sendNotificationTask("update note")
      try {
        let json1 = { taskId: id };
        //json.taskId = id;
        sendSocketMessage("update note", json1)
      }
      catch (ex) {

      }
      if (note != '') {
        addTaskUpdateByOtherUser(taskItem, 'updateTaskNote')
      }
      getTask(userId, true)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const DeleteAttachmentRef: any = useRef({})

  const handleClickConfirm = item => {
    DeleteAttachmentRef.current = item
    confirmAlert({ customUI: addDialogForAttachment })
  }

  const addDialogForAttachment = ({ onClose }) => {
    const handleClickedNo = () => {
      // alert('clicked no')
      onClose()
    }
    const handleClickedYes = () => {
      onClose()
      removeFiles(DeleteAttachmentRef.current)
    }
    return (
      <div className='add-dialog' style={{ backgroundColor: colorSchemes.DrawerBackColor, color: colorSchemes.FontBlackColor, borderRadius: "4px",padding:0 }}>
         <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: "rgba(233, 236, 244, 1)" }}>
              <div style={{ width: "70%", textAlign: "left" }}>
                <h4 style={{ color: '#172D46', margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>Delete Attachment</h4></div>
              <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <img style={{ width: 12, cursor: 'pointer' }} onClick={handleClickedNo} src={CloseMenuListIcon} alt='' />
              </div>
            </div>
        {/* <h5 style={{ fontWeight: 700, color: colorSchemes.FontBlackColor, marginBottom: 15 }}>Delete attachment</h5> */}
        <div style={{padding:'24px 20px'}}>
        <p style={{ fontSize: 16,color:'#172D46' }}><b>"{DeleteAttachmentRef.current.name}"</b> will be permanently deleted.</p>
        <div className='add-dialog-buttons' style={{ marginTop: 25,justifyContent:'space-between' }}>
          <button style={{background: 'rgb(71,114,250)', color: '#fff', /* border: '0.1px solid rgba(0, 0, 0, 0.3)', */paddingTop:'11px',paddingBottom:'11px'}} onClick={handleClickedYes}>Delete Attachment</button>
          <button style={{background: '#fff' ,
                          color: "#3068C8",
                          border: '0.1px solid #3068C8', width: 105,paddingTop:'11px',paddingBottom:'11px' }} onClick={handleClickedNo}>Cancle</button>
        </div>
        </div>
      </div>
    )
  }


  async function handleChangeTaskName(e) {
    let data: any = [...taskMainData]
    let item1 = {}
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == taskItem.id) {
        data[i].taskName = encData(e)
        data[i].isEncrypt = "1"
        item1 = data[i]
        setTaskItem(data[i])
        taskItemRef.current = data[i];
      }
    }
    setTaskMainData(data)
    setTaskData([data])
    updateTask(item1)
  }

  async function handleChangeSubTaskName(e, item) {

    console.log('subTaskItem', subTaskItem)
    let subTask = { ...taskItem };
    for (let i = 0; i < subTask.task.length; i++) {
      if (subTask.task[i].id == item.id) {
        subTask.task[i].taskStepTitle = e;
        subTask.subTaskData = JSON.stringify(subTask.task);
        updateSubTask(subTask)
      }
    }
    setTaskItem(subTask)
    taskItemRef.current = subTask;
    /*  let data: any = [...taskMainData]
     for (let i = 0; i < data.length; i++) {
       if (data[i].id == taskItem.id) {
         if()
         data[i].taskName = encData(e)
         data[i].isEncrypt= "1"
       }
     }
     setTaskMainData(data)
     setTaskData([data])
     updateTask(taskItem) */
  }

  async function handleChangeTaskNotes(e) {
    let note = e.trim();

    console.log('e', note)
    console.log('encData(e)', encData(note))
    let data: any = [...taskMainData]
    let id = ""
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == taskItem.id) {
        data[i].notes = note != "" ? encData(note) : note
        data[i].isEncryptNotes = "1"
        id = data[i].id
        console.log('data[i]', data[i]);
      }
    }
    // console.log('data[i]45',data);
    let tempTaskItem = { ...taskItem }
    tempTaskItem.notes = note != "" ? encData(note) : note
    tempTaskItem.isEncryptNotes = "1"
    setTaskItem(tempTaskItem)
    taskItemRef.current = tempTaskItem;

    console.log('tempTaskItem', tempTaskItem)
    //  setTaskMainData(data)
    //  setTaskData([data])
    AddTaskNoteApi(note != "" ? encData(note) : note, id);
  }
  const handleTaskNameKeyDown = e => {
    if (e.keyCode !== 8 && e.target.innerText.length >= 255) {
      e.preventDefault()
    }
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }
  const handleSubTaskNameKeyDown = e => {
    if (e.keyCode !== 8 && e.target.innerText.length >= 255) {
      e.preventDefault()
    }
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }
  const handleTaskNotesKeyDown = e => {
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }

  async function updateTask(taskItem) {
    try {
      const response = await post(BASE_URL + '/updatetask', taskItem)
      try {
        let json = { taskId: taskItemRef.current.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      console.log('updateTask', response)
      addTaskUpdateByOtherUser(taskItem, 'updateTaskName')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  function onContentEditableLinksMouseDown(e) {
    if (e.target.closest('[contentEditable="true"]') && e.target.href && e.button === 0)
      // window.location.href = e.target.href
      window.open(e.target.href)
  }


  const [subTaskAnchorEl, setSubTaskAnchorEl] = React.useState<null | HTMLElement>(null);
  const openSubTaskMenuList = Boolean(subTaskAnchorEl);
  const handleClickSubTaskMenuList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSubTaskAnchorEl(event.currentTarget);
  };
  const handleCloseSubTaskMenuList = () => {
    setSubTaskAnchorEl(null);
  };

  const [rightPaneAnchorEl, setRightPaneAnchorEl] = React.useState<null | HTMLElement>(null);
  const openRightPaneMenuList = Boolean(rightPaneAnchorEl);
  const handleClickRightPaneMenuList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRightPaneAnchorEl(event.currentTarget);
  };
  const handleCloseRightPaneMenuList = () => {
    setRightPaneAnchorEl(null);
  };

  const handleRightClick = (event: React.MouseEvent) => {
    if (menuPosition) {
      return
    }
    event.preventDefault()
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    })
  }


  const [remindMeAnchorEl, setRemindMeAnchorEl] = React.useState<null | HTMLElement>(null);
  const openReminMeMenuList = Boolean(remindMeAnchorEl);
  const handleClickRemindMeMenuList = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRemindMeAnchorEl(event.currentTarget);
  };
  const handleCloseRemindMeMenuList = () => {
    setRemindMeAnchorEl(null);
    setShowCalendar(false)
  };

  const handleRemindMeClick = (event: React.MouseEvent) => {
    if (remindMeMenuPosition) {
      return
    }
    event.preventDefault()
    setRemindMeMenuPosition({
      top: event.pageY,
      left: event.pageX
    })
  }


  const promoteSubTask = async (subTaskItem) => {
    console.log('subTaskItem', subTaskItem)
    let subTask = { ...taskItem };
    for (let i = 0; i < subTask.task.length; i++) {
      if (subTask.task[i].id == subTaskItem.id) {
        subTask.task.splice(i, 1);
        subTask.subTaskData = JSON.stringify(subTask.task);
        updateSubTask(subTask)
      }
    }
    setTaskItem(subTask)
    taskItemRef.current = subTask;

    let taskOrderIds: any = ''
    let completeTaskOrderIds: any = ''
    for (let i = 0; i < taskData[0].length; i++) {
      if (selectedProjectID == taskData[0][i].projectId) {
        if (parseInt(taskData[0][i].taskStatus) == 0) {
          if (taskOrderIds != '') taskOrderIds += ','
          taskOrderIds += taskData[0][i].id
        } else if (parseInt(taskData[0][i].taskStatus) == 1) {
          if (completeTaskOrderIds != '') completeTaskOrderIds += ','
          completeTaskOrderIds += taskData[0][i].id
        }
      }
    }

    try {
      const data = {
        title: encData(subTaskItem.taskStepTitle),
        userid: userId,
        taskorder: 0,
        projectId: selectedProjectID,
        taskOrderIds: taskOrderIds,
        completedTaskOrderIds: completeTaskOrderIds,
        taskNote: listItem.isNote && listItem.isNote == "1" ? 1 : 0,
        createdUserId: userId
      }
      console.log('data123456', data)
      const response = await post(BASE_URL + '/addtaskv2', data)
      try {
        let json = { taskId: taskItemRef.current.id };
        sendSocketMessage("promote to task", json)
      }
      catch (ex) {

      }
      console.log('response23', response)
      if (response.data.status == 'success') {
        getTask(userId)
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const deleteSubTask = async (subTaskItem) => {
    console.log('subTaskItem', subTaskItem)
    let subTask = { ...taskItem };
    for (let i = 0; i < subTask.task.length; i++) {
      if (subTask.task[i].id == subTaskItem.id) {
        subTask.task.splice(i, 1);
        subTask.subTaskData = JSON.stringify(subTask.task);
        updateSubTask(subTask)
      }
    }
    setTaskItem(subTask)
    taskItemRef.current = subTask
  }


  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    cursor: isDragging ? 'all-scroll' : 'pointer',
    // styles we need to apply on draggables
    ...draggableStyle
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    // console.log('result000',list)
    const removed = result.splice(startIndex, 1)
    console.log('removed000', removed)
    result.splice(endIndex, 0, removed.pop())
    console.log('result000', result)

    return result
  }


  function onDragStart(result) {
    setIsDragStart(true)
  }

  function onDragEnd(result) {
    setIsDragStart(false)
    console.log('result456', result)
    const { source, destination, draggableId } = result

    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd: any = source.droppableId
    const dInd: any = destination.droppableId
    console.log('sInd', sInd)
    console.log('dInd', dInd)
    if (sInd === dInd) {
      const items = reorder(taskItem.task, source.index, destination.index)
      const newState: any = { ...taskItem }
      newState.task = items
      newState.subTaskData = JSON.stringify(items)
      console.log('newSate45', newState)
      setTaskItem(newState)
      taskItemRef.current = newState;
      updateSubTask(newState)
      /*  console.log('newSate45', taskMainData) */
      // setTaskMainData(newState[0])
      // updateTaskOrder(newState[0])
    }

  }

  function reminderDate(date: any) {
    var startTime = new Date(date);
      return moment(startTime).format('MMM Do');
  }
  function timeSince(date: any) {
    try {
      /* var startTime = new Date(date);
      return moment(startTime).format('MMM Do YYYY, hh:mm A'); */

      let currentDate: any = new Date(new Date().toISOString());
      let pastData: any = new Date(date);
      console.log("currentDate", currentDate);
      console.log("pastDate", pastData);
      var seconds = Math.floor((currentDate - pastData) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      //return Math.floor(seconds) + " seconds";
      return "Just Now";
    }
    catch (ex) {
      return "";

    }
  }



  const handlePasteFile = async (e: any) => {
    if (e && e.name) {
      setUploadFileLoader(true)
      let mimeType = "image/png";
      try {

        mimeType = e.type;
        console.log("Filename: " + e.name);
        console.log("Type: " + e.type);
        console.log("Size: " + e.size + " bytes");

      }
      catch (ex) {

      }

      var formData: any = new FormData()
      formData.append('taskImage', e)
      formData.append('taskid', taskItem.id)
      formData.append('fileType', mimeType)
      try {

        const response: any = await axios.post(BASE_URL + "/uploadtaskphoto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data: any) => {
            //Set the progress value to show the progress bar
            console.log("Percentage", Math.round((100 * data.loaded) / data.total))
            setFileUploadPercent(Math.round((100 * data.loaded) / data.total));
            setFileUploadingTime(taskItem)
            //console.log("Percentage", data)
          },
        })

        // const response = await postUser(
        //   BASE_URL + '/uploadtaskphoto',
        //   formData
        // )
        // console.log('response2323', response)
        if (response.data.status == 'success') {
          addTaskUpdateByOtherUser(taskItem, 'uploadFile')
          inputFileRef.current.value = "";
          try {
            let json: any = {};
            json.taskId = taskItem.id;
            sendSocketMessage("upload photo", json)
          }
          catch (ex) {

          }
          let data: any = [...taskMainData]
          for (let i = 0; i < data.length; i++) {
            if (data[i].id == taskItem.id) {
              // data[i].img.push(response.data.data[0])
              data[i].img.splice(0, 0, response.data.data[0]);
              setTaskItem(data[i])
              taskItemRef.current = data[i];
            }
          }
          setUploadFileLoader(false)
          // data[taskIndex].img.push(response.data.data[0])
          setTaskMainData(data)
          setTaskData([data])
        } else {
          setUploadFileLoader(false)
        }
      } catch (ex) {
        setUploadFileLoader(false)
        console.log('response9211', ex)
      }

    }
  }

  async function AddComments(e, item) {
    if (e.keyCode === 13) {
      //  await addTaskComment(item);
      if (mobileView) {
        setCommentCollapse(true)
        e.target.blur()
      }
      else {
        setCommentCollapse(true)
        await addTaskComment(item);
      }
      //     console.log('comment45',commentTextArea)
      //     let tempCommentData = [...taskData]
      // for(let i=0;i<tempCommentData[0].length;i++){
      //   if(tempCommentData[0][i].id == item.id){

      //   }
      // }
    }
  }

  async function AddCommentsBlur(e, item) {

    if (mobileView) {
      e.target.blur()
      setCommentCollapse(true)
      await addTaskComment(item);
    }
    else {
      //  await addTaskComment(item);
    }

    // e.target.blur()
    //  await addTaskComment(item);

    //     console.log('comment45',commentTextArea)
    //     let tempCommentData = [...taskData]
    // for(let i=0;i<tempCommentData[0].length;i++){
    //   if(tempCommentData[0][i].id == item.id){

    //   }
    // }
  }



  async function addTaskComment(item) {
    console.log('taskStepInputValue', commentTextArea)
    setCommentCollapse(true)
    let tempTaskData = [...taskData]
    if (commentTextArea != '') {
      let commentJson: any = {
        "taskId": item.id,
        "comment": commentTextArea,
        "fromUserId": item.userid,
        "toUserId": userId
      }
      try {
        const response = await post(BASE_URL + '/addcomment', commentJson)
        console.log('responseXYZ', response)
        try {
          commentJson.taskId = item.id;
          sendSocketMessage("add comment", commentJson)
        }
        catch (ex) {

        }
        addTaskUpdateByOtherUser(taskItem, 'addComment')
        if (response && response.data && response.data.data && response.data.data.length > 0) {
          setCommentTextArea('')
          let lastElement = taskItem.comments[taskItem.comments.length - 1];
          setLastComment(lastElement)
          for (let i = 0; i < tempTaskData[0].length; i++) {
            if (tempTaskData[0][i].id == item.id) {
              tempTaskData[0][i].comments.push(response.data.data[0])
              setTaskItem(tempTaskData[0][i])
              taskItemRef.current = tempTaskData[0][i]
              console.log('tempTaskData[i]', tempTaskData[0][i])
            }
          }
          setTaskMainData(tempTaskData[0])
          setTaskData(tempTaskData)
          scrollToBottom()
          sendNotificationTask("add comment")
        }
        else {
          setCommentTextArea('')
        }
      }
      catch (ex) {
        setCommentTextArea('')
      }
    }
  }


  async function sendNotificationTask(notificationType) {
    let tempProjectName = ""
    let tempProjectId = taskItem.projectId;
    let tempProjectGroupId = "0";
    let tempGroupData = [...groupData];
    for (let i = 0; i < tempGroupData.length; i++) {
      for (let j = 0; j < tempGroupData[i].project.length; j++) {
        if (tempGroupData[i].project[j].id == tempProjectId) {
          tempProjectGroupId = tempGroupData[i].id;
          tempProjectName = tempGroupData[i].project[j].projectName;
          break;
        }
      }
    }
    let tempProjectData = [...projectData];
    for (let i = 0; i < tempProjectData.length; i++) {
      if (tempProjectData[i].id == tempProjectId) {
        tempProjectName = tempProjectData[i].projectName;
        break;
      }
    }


    const config = {
      taskUserId: taskItem.userid,
      currentUserId: userId,
      projectId: tempProjectId,
      projectGroupId: tempProjectGroupId,
      taskId: taskItem.id,
      taskName: decData(taskItem.taskName, "1"),
      userName: firstName + " " + lastName,
      type: "project",
      subType: notificationType,
      projectName: tempProjectName != "" ? decData(tempProjectName, "1") : ""
    }
    console.log('test856', config);
    const response1 = await post(BASE_URL + '/notificationtask', config)
    console.log('response19', response1)
  }


  async function removeComment(item) {

    let json: any = {
      commentId: item.id
    }
    console.log('item4585', item)
    let data: any = [...taskData]
    // for (let i = 0; i < data[0][taskIndex].comments.length; i++) {
    //   console.log('data[taskIndex].comments[i]',data[0][taskIndex].comments[i])
    //   if (data[0][taskIndex].comments[i].id == item.id) {
    //     data[0][taskIndex].comments.splice(i, 1)
    //     setTaskItem(data[0][taskIndex])
    //     console.log('data[taskIndex]',data[0][taskIndex])
    //   }
    // }

    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == taskItem.id) {
        for (let j = 0; j < data[0][i].comments.length; i++) {
          if (data[0][i].comments[j].id == item.id) {
            data[0][i].comments.splice(j, 1)
            setTaskItem(data[0][i])
            taskItemRef.current = data[0][i];
            console.log('data456', data[0][i])
          }
        }
      }
    }

    setTaskMainData(data[0])
    setTaskData(data)
    try {
      const response = await post(BASE_URL + '/deleteComment', json)
      console.log('response232323', response)
      try {
        json.taskId = taskItemRef.current.id;
        sendSocketMessage("delete comment", json)
      }
      catch (ex) {

      }
      getTask(userId)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  const listenToScroll = (height) => {
    try {
      let heightToHideFrom = 50;
      const winScroll = commentDivRef?.current?.scrollTop;
      if (height + winScroll > commentDivRef.current.scrollHeight - heightToHideFrom) {
        setLastCommentDivShow(false);
      } else {
        setLastCommentDivShow(true);
      }
    }
    catch (ex) {

    }
  };

  function scrollToBottom() {
    setTimeout(() => {
      commentDivRef.current.scrollTop =
        commentDivRef.current.scrollHeight
      if (commentDrawerDivRef.current)
        commentDrawerDivRef.current.scrollTop = commentDrawerDivRef.current.scrollHeight;
    }, 200)

    commentDivScrollTopRef.current = commentDivRef.current.scrollTop;
    commentDivScrollHeightRef.current = commentDivRef.current.scrollHeight;
    //commentDivRef.current?.scrollIntoView({ behavior: 'smooth' })
  }


  async function restoreTask(item) {
    console.log('item3216', item)
    const json = {
      taskId: item.id,
    }
    try {
      const response = await post(BASE_URL + '/restoretask', json)
      console.log('response232323', response)
      getTask(userId)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }


  async function changeTrunIntoTask(item) {
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        data[0][i].taskHeading = '0'
        setTaskItem(data[0][i])
        taskItemRef.current = data[0][i];
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/updatetask', item)
      console.log('response232323', response)
      try {
        let json = { taskId: taskItemRef.current.id };
        sendSocketMessage("update task", json)
      }
      catch (ex) {

      }
      addTaskUpdateByOtherUser(item, 'turnIntoTask')
    } catch (ex) {
      console.log('response9211', ex)
    }
  }





  return (
    <div className={slideType.current == "right" ? 'slide-right' : 'moveToRight'} style={!mobileView?styles.SideBarDiv: mobileView && isLightMode ? styles.SideBarDivMobile:styles.SideBarDivMobileDark}>

      {/* {!mobileView && 
            <> 
            <div >
            <div className='settingButton' style={{position: 'absolute',right: 60,top: 12,padding: '0px 10px',zIndex: 999,cursor: 'pointer'}} onClick={(e)=>{e.preventDefault();e.stopPropagation();refreshFun('right')}}>
    <img style={{width: 14,cursor:'pointer'}}   src={RefreshIcon} alt="" />
    </div>
      </div>
          <div
                                        className='sideBarCloseIconDiv'
                                        onClick={() => setTaskSectionOpen(false)}
                                      >
                                        <img src={CloseMenuListIcon} alt='' />
                                      </div>
                                      </>
                                      } */}

{mobileView &&
                          <div className='middlePaneTopDiv' style={{background:colorSchemes.WebLeftPaneBg}}>
                            <div style={{width:24,display:'flex',justifyContent:'center',alignItems:'center'}} onClick={() => { rightPaneMobileBack() }}>
                            <img style={{width:24}} src={Rightpaneback} />
                            </div>
                            <div style={{fontSize:20,fontWeight:700,fontFamily:'ManRopeBold',color:'#fff'}}>
                            Task Detail
                            </div>
                            <div style={{width:24}}>
                            {/* <img style={{width:32}} src={AvatarMiddlePane} /> */}
                            </div>
                          </div>
                          }


{!mobileView &&
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
          <div className='settingButton' style={{ borderRadius: 4 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); refreshFun('right') }}>
            <img
              style={{
                width: 22,
                cursor: 'pointer',
              }}
              src={!mobileView? RefreshGreyIcon:mobileView && isLightMode?RefreshGreyIcon:RefreshIcon}
            />
          </div>
          {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
            <>
              {taskItem && taskItem.taskNote != "1" && taskItem.taskHeading != "1" &&
                <div className='settingButton' style={{ borderRadius: 4 }}
                  onClick={(e: any) => {
                    handleClickRightPaneMenuList(e)
                    handleRightClick(e)
                    if (rightPaneMenu) {
                      setRightPaneMenu(false)
                    } else {
                      setRightPaneMenu(true)
                    }
                  }}
                  id="basic-right-pane-menu"
                  aria-controls={openRightPaneMenuList ? 'basic-right-pane' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openRightPaneMenuList ? 'true' : undefined}
                >
                  <img
                    style={{
                      width: !mobileView? 22:mobileView && isLightMode?22: 15,
                      cursor: 'pointer',
                      transform: "rotate(90deg)",
                    }}
                    src={!mobileView? GreyThreedots:mobileView && isLightMode?GreyThreedots:WhiteDots}
                  />
                </div>
              }
            </>
          }
          <div className='settingButton' style={{ borderRadius: 4 }} onClick={() => setTaskSectionOpen(false)}>
            <img
              style={{
                width: 22,
                cursor: 'pointer',
              }}
              src={RightPaneCloseIcon}
            />
          </div>
        </div>
      </div>
      }
      <div className='sidebarFirstDiv' style={mobileView ? {background:!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground ,padding:'24px 16px', color:colorSchemes.FontColor} : {}} /* style={{background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey}} */ ref={commentDivRef} /* onScroll={(e:any)=>{listenToScroll(e.currentTarget.getBoundingClientRect().height);}} */ >

       
        {refreshLoaderRight &&
          <div style={{ height: 125, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 8 }}>
            {!mobileView  ?
              <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Loading} alt="" />
              :mobileView && isLightMode?
              <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Loading} alt="" />:
              <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
            }
            <div style={{ fontSize: 16, fontWeight: 600, color: 'darkgray' }}>Refreshing...</div>
          </div>
        }
        {refreshFocusLoader &&
                          <div style={{ height: 125, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 8 }}>
                          {!mobileView  ?
                            <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Loading} alt="" />
                            :mobileView && isLightMode?
                            <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Loading} alt="" />:
                            <img className="loading-spinner" style={{ width: 40, height: 40 }} src={Whiteloading} alt="" />
                          }
                          <div style={{ fontSize: 16, fontWeight: 600, color: 'darkgray' }}>Syncing...</div>
                        </div>
                        }
          <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',marginBottom:12}}>
            <>
        <div className='taskTitleHead' /* style={{ padding: '0px 12px 5px 13px' }} */>
          {listItem && listItem.readOnlyStatus == '0' &&
            <div style={{display:'flex'}}>
              {taskItem && taskItem.taskHeading && taskItem.taskHeading == 0 ?
                <>
                  {taskItem && taskItem.taskNote && taskItem.taskNote == 0 ?
                    <>
                      {taskItem && taskItem.taskStatus && taskItem.taskStatus == 0 ? (
                        <img
                          onClick={() => { if (taskItem && taskItem.isTrash && taskItem.isTrash != "2") { changeStatus(taskItem); addTaskUpdateByOtherUser(taskItem, 'completed task'); } else { } }}
                          style={{ width: !mobileView ? 24 : 20, cursor: 'pointer'/* , marginTop: !mobileView ? 3 : 0 *//* , opacity: mobileView ? .75 : 1  */}}
                          src={!mobileView ? UnSelect : UnSelect}
                          className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                        />
                      ) : (
                        <img
                          onClick={() => { if (taskItem && taskItem.isTrash && taskItem.isTrash != "2") { changeStatus(taskItem); addTaskUpdateByOtherUser(taskItem, 'completed task') } else { } }}
                          style={{ width: !mobileView ? 24 : 20, cursor: 'pointer'/* , marginTop: !mobileView ? 3 : 0 */ }}
                          src={Select}
                          className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                        />
                      )}
                    </>
                    :
                    <img
                      // onClick={() => changeStatus(taskItem)}
                      style={{ width: 24, cursor: 'pointer' }}
                      src={TaskNoteIcon}
                    />
                  }
                </>
                : null}
            </div>
          }

          {taskItem && taskItem.taskName && (
            <LinkItUrl>
              <div
                ref={sideBarTaskNameInput}
                onBlur={e => {
                  console.log('test task 12', e.target.innerText)
                  handleChangeTaskName(e.target.innerText)
                }}
                onInput={(e: any) => {
                  if (e.keyCode !== 8 && e.currentTarget.textContent.length >= 255) {
                    console.log('Text inside div', e.currentTarget.textContent)
                    e.preventDefault()
                  }
                }
                }
                onMouseDown={e => onContentEditableLinksMouseDown(e)}
                onKeyDown={e => handleTaskNameKeyDown(e)}
                contentEditable={taskItem.isTrash != "2" && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? true : false}
                suppressContentEditableWarning={true}
                className='editTaskName'
                style={{
                  textDecoration:
                    taskItem && taskItem.taskStatus == 0 ? '' : 'line-through'
                }}
              >
                {decData(taskItem.taskName, taskItem.isEncrypt).substring(0, 255)}
              </div>
            </LinkItUrl>
          )}
           </div>
           </>
<div>
  {mobileView &&
<div className='MainUpperRightDiv' style={{ paddingTop: mobileView ? 0 : 0 }}>
                                <div>
                                  <div className='settingButton' style={{ display: 'flex' }} onClick={(e)=>{e.preventDefault();e.stopPropagation();refreshFun('right')}}>
                                    <img style={{ width: !mobileView? 28:mobileView && isLightMode?28:18, cursor: 'pointer' }} src={!mobileView? RefreshGreyIcon:mobileView && isLightMode?RefreshGreyIcon:RefreshIcon} alt="" />
                                  </div>
                                </div>
                                {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
          <>
            {taskItem && taskItem.taskNote != "1" && taskItem.taskHeading != "1" &&
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'/* , paddingBottom: 10 */ }}>
                <img src={!mobileView? GreyThreedots:mobileView && isLightMode?GreyThreedots:WhiteDots} alt="" style={{ width: !mobileView? 28:mobileView && isLightMode?28:18, cursor: 'pointer' }}
                  onClick={(e: any) => {
                    handleClickRightPaneMenuList(e)
                    handleRightClick(e)
                    if (rightPaneMenu) {
                      setRightPaneMenu(false)
                    } else {
                      setRightPaneMenu(true)
                    }
                  }}
                  id="basic-right-pane-menu"
                  aria-controls={openRightPaneMenuList ? 'basic-right-pane' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openRightPaneMenuList ? 'true' : undefined}


                />
              </div>
            }
          </>
        }
                              </div>
}
</div>
      
       
        </div>
       


        {taskItem && taskItem.taskHeading && taskItem.taskHeading == 0 ?
          <>

            {taskItem && taskItem.taskNote && taskItem.taskNote == 0 ?
              <div>
                <div className='subTaskMainDiv' style={{ marginLeft: 22, background:!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground }}>
                  <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                  >
                    <Droppable
                      key={taskItem.id}
                      droppableId={`${taskItem.id}`}
                      type={`list`}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          // style={getListStyle(snapshot.isDraggingOver)}
                          style={{  background:!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground  /* colorSchemes.DrawerBackColor */ }}
                          {...provided.droppableProps}
                        >
                          {taskData && taskItem && taskItem.task &&
                            taskItem.task.length > 0 &&
                            taskItem.task.map((item: any, i: any) => (
                              <Draggable
                                key={`sub-task-id-${item.id}`}
                                draggableId={`dragsubtaskid-${item.id}`}
                                index={i}
                                type={`droppableSubItem`}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <div className='addTaskSteps' style={{
                                      transform: snapshot.isDragging
                                        ? 'scale(0.85)'
                                        : 'scale(1)',
                                      background: snapshot.isDragging ? colorSchemes.DrawerBackColor : /* colorSchemes.DrawerBackColor */!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground,
                                      boxShadow: snapshot.isDragging
                                        ? 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px'
                                        : '',
                                      opacity: snapshot.isDragging
                                        ? 0.7
                                        : 'inherit',
                                      /* padding: '5px 13px', */ gap: 10,
                                      color: /* colorSchemes.ListFontColor */!mobileView?'#172D46':colorSchemes.FontColor 
                                    }}
                                      id="basic-sub-task-menu"
                                      aria-controls={openSubTaskMenuList ? 'basic-sub-task' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={openSubTaskMenuList ? 'true' : undefined}
                                      onContextMenu={(e: any) => {
                                        e.preventDefault()
                                        handleClickSubTaskMenuList(e)
                                        handleRightClick(e)
                                        if (subTaskLeftClicked) {
                                          setSubTaskLeftClicked(false)
                                        } else {
                                          setSubTaskLeftClicked(true)
                                        }
                                        setSubTaskItem(item)
                                      }}
                                      onClick={() => {
                                        // timerRef.current = setTimeout(() => {
                                        //   if (!preventRef.current) {
                                        //  SubTaskNameInputRef?.current?.blur()
                                        //  setEditSubTaskItem({})
                                        //   }
                                        // },200)
                                      }}
                                      onDoubleClick={(e) => {
                                        clearTimeout(timerRef.current);
                                        preventRef.current = true;
                                        console.log('test678')
                                        setEditSubTaskItem(item)
                                        if (
                                          editSubTaskItem?.id != item?.id
                                        ) {
                                          SubTaskNameInputRef?.current?.blur()
                                        }
                                        setTimeout(() => {
                                          preventRef.current = false;
                                          SubTaskNameInputRef?.current?.focus()
                                          try {
                                            let sel: any =
                                              window.getSelection()
                                            let range =
                                              document.createRange()

                                            range.setStart(
                                              SubTaskNameInputRef
                                                ?.current
                                                ?.childNodes[0],
                                              item.taskStepTitle.length
                                            )
                                            range.collapse(true)
                                            sel.removeAllRanges()
                                            sel.addRange(range)
                                          } catch (ex) { }
                                        }, 100)
                                      }}

                                    >
                                      
                                      <div className='taskStepTitleRow' style={{ display:"flex", color:!mobileView?'#172D46':colorSchemes.FontColor /* , justifyContent: 'space-between' */, fontWeight: mobileView ? 600 : 600,justifyContent:'space-between' }}>
                                        <div style={{display: 'flex',justifyContent: 'flex-start',alignItems: 'center',gap: 16}}>
                                      {listItem && listItem.readOnlyStatus == '0' &&
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          {item.status == "0" ? (
                                            <img
                                              onClick={() => { if (taskItem.isTrash != "2") { changeTaskStepStatus(item) } else { } }}
                                              style={{ width: !mobileView ? 16 : 16, cursor: 'pointer'/* , opacity: mobileView ? .75 : 1  */}}
                                              src={!mobileView ? UnSelect : UnSelect}
                                              className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                                            />
                                          ) : (
                                            <img
                                              onClick={() => { if (taskItem.isTrash != "2") { changeTaskStepStatus(item) } else { } }}
                                              style={{ width: !mobileView ? 16 : 16, cursor: 'pointer' }}
                                              src={Select}
                                              className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                                            />
                                          )}
                                        </div>
                                      }
                                        {/* <div
                  autoFocus={true}
                  style={{textDecoration:item.status == "0"?'inherit' :'line-through',}}>{item.taskStepTitle}</div> */}


                                        <LinkItUrl>
                                          {editSubTaskItem?.id == item?.id ? (
                                            <div
                                              ref={SubTaskNameInputRef}
                                              onBlur={e => {
                                                console.log('test task 12', e.target.innerText)
                                                handleChangeSubTaskName(e.target.innerText, item)

                                              }}
                                              onInput={(e: any) => {
                                                if (e.keyCode !== 8 && e.currentTarget.textContent.length >= 255) {
                                                  console.log('Text inside div', e.currentTarget.textContent)
                                                  e.preventDefault()
                                                }
                                              }
                                              }
                                              onMouseDown={e => onContentEditableLinksMouseDown(e)}
                                              onKeyDown={e => handleSubTaskNameKeyDown(e)}
                                              contentEditable={taskItem.isTrash != "2" ? true : false}
                                              suppressContentEditableWarning={true}
                                              className='editTaskName'
                                              style={{ textDecoration: item.status == "0" ? 'inherit' : 'line-through'/* , width:"70%" */ }}
                                            >
                                              {item.taskStepTitle}
                                            </div>
                                          )
                                            :
                                            <div
                                              className='editTaskName'
                                              style={{ textDecoration: item.status == "0" ? 'inherit' : 'line-through', /* color: mobileView ? '#bdbdbd' : '', */ /* width:"70%" */ }}
                                            >
                                              {item.taskStepTitle}
                                            </div>
                                          }
                                        </LinkItUrl>

                                        </div>
<div>
                                        {taskItem.isTrash != "2" && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ?
                                        <div style={{display:"flex", justifyContent:"flex-end"}}>  <img
                                            id="basic-sub-task-menu"
                                            aria-controls={openSubTaskMenuList ? 'basic-sub-task' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openSubTaskMenuList ? 'true' : undefined}
                                            onClick={(e: any) => {
                                              e.preventDefault()
                                              handleClickSubTaskMenuList(e)
                                              handleRightClick(e)
                                              if (subTaskLeftClicked) {
                                                setSubTaskLeftClicked(false)
                                              } else {
                                                setSubTaskLeftClicked(true)
                                              }
                                              setSubTaskItem(item)
                                            }}
                                            style={{ width:!mobileView? 20:mobileView && isLightMode?20: 15, cursor: 'pointer' }}
                                            src={!mobileView? ThreeDots:mobileView && isLightMode?ThreeDots:WhiteDots}
                                            alt=''
                                          /></div>
                                          :
                                         <div style={{display:"flex", justifyContent:"flex-end"}}> <img
                                            //  id="basic-sub-task-menu"
                                            //  aria-controls={openSubTaskMenuList ? 'basic-sub-task' : undefined}
                                            //  aria-haspopup="true"
                                            //  aria-expanded={openSubTaskMenuList ? 'true' : undefined}
                                            // onClick={(e:any)=>{
                                            //   e.preventDefault()
                                            //   handleClickSubTaskMenuList(e)
                                            //   handleRightClick(e)
                                            //   if (subTaskLeftClicked) {
                                            //     setSubTaskLeftClicked(false)
                                            //   } else {
                                            //     setSubTaskLeftClicked(true)
                                            //   }
                                            //   setSubTaskItem(item)
                                            // }}
                                            className='customCursor'
                                            style={{ width: !mobileView? 20:mobileView && isLightMode?20: 15, cursor: 'pointer' }}
                                            src={!mobileView? ThreeDots:mobileView && isLightMode?ThreeDots:WhiteDots}
                                            alt=''
                                          />
                                          </div>
                                        }
                                        </div>
                                      </div>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>



                </div>
                {(addSubTaskDiv || (taskItem && taskItem.task && taskItem.task.length > 0)) &&
                  <>
                    {taskItem.isTrash != "2" ?
                      <ClickAwayListener onClickAway={() => { if (!mobileView && taskStepInputValue == "") { setAddSubTaskDiv(false);/* setTaskStepInputValue('') */ } }}>
                        <div
                          className='addSubTask' style={{  background:!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground  }}
                        >
                          <div style={{ padding: mobileView ? '16px 16px' : '10px 16px', /* borderRight: isDark == 1 ? '3px solid #212121' : '3px solid #EDEEF0' */ }} onClick={() => { subTaskInputRef.current.focus() }}>
                            <img
                              style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 15, height: 15, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',transform:mobileView ? "rotate(45deg)":"" }}
                              src={mobileView ? CrossIconImage : AddProjectIcon}
                            />
                          </div>

                          <input
                            ref={subTaskInputRef}
                            className={!mobileView? 'addSubTaskInput':mobileView && isLightMode?'addSubTaskInput':"addSubTaskInput addSubTaskDarkInput"}
                            style={{ color: !mobileView?'#172D46':colorSchemes.FontColor  }}
                            type='text'
                            name='name'
                            placeholder='Add a subtask'
                            // onClick={() => setTaskStepAddBtn(true)}
                            value={taskStepInputValue}
                            onChange={handleChange}
                            autoComplete='off'
                            onKeyDown={handleKeyDown}
                            onBlur={() => { if (mobileView && taskStepInputValue == "") { setAddSubTaskDiv(false);/* setTaskStepInputValue('') */ } else if (mobileView) { addTaskStepToList(); subTaskInputRef.current.focus(); setAddSubTaskDiv(false) } else { addTaskStepToList() } }}
                            autoFocus={!mobileView ? addSubTaskDiv ? true : false : false}
                          />
                          {taskStepInputValue != '' && (
                            <div className='addBtn' onClick={() => { addTaskStepToList(); subTaskInputRef.current.focus(); setAddSubTaskDiv(false) }} style={{ marginRight: 5 }}>Add</div>
                          )}
                        </div>
                      </ClickAwayListener>
                      :
                      <div
                        className='addSubTask' style={{  background:!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.MiddlePaneBackground  }}
                      >
                        <div style={{ padding: mobileView ? '16px 16px' : '10px 16px', borderRight: isDark == 1 ? '3px solid #212121' : '3px solid #EDEEF0' }}  >
                        <img
                              style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 15, height: 15, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',transform:mobileView ? "rotate(45deg)":"" }}
                              src={mobileView ? CrossIconImage : AddProjectIcon}
                            />
                        </div>

                        <input
                          ref={subTaskInputRef}
                          className={!mobileView? 'addSubTaskInput customCursor':mobileView && isLightMode?'addSubTaskInput customCursor':"addSubTaskInput addSubTaskDarkInput customCursor"}
                          style={{ color: !mobileView?'#172D46':colorSchemes.FontColor  }}
                          type='text'
                          name='name'
                          placeholder='Add a subtask'
                          // onClick={() => setTaskStepAddBtn(true)}
                          value={taskStepInputValue}
                          // onChange={handleChange}
                          autoComplete='off'
                        // onKeyDown={handleKeyDown}
                        />
                        {taskStepInputValue != '' && (
                          <div className='addBtn' style={{ marginRight: 5 }}>Add</div>
                        )}
                      </div>
                    }
                  </>
                }


                {/* <div className='dueAndReminder customCursor' style={{background:colorSchemes.DrawerBackColor}}>
          <div style={mobileView?{borderBottom:'1px solid '+colorSchemes.DrawerBackColor}: {borderBottom:'0.5px solid #EDEEF0'}}>
          <img
            className='customCursor'
            style={{ width: 18, cursor: 'pointer' }}
            src={Calendar}
          />
          <div className='taskLine' style={{ fontSize:mobileView?17.5:14, fontWeight: 500,color:colorSchemes.FontSemiGrey}}>
            Set due date
          </div>
          </div>
          <div>
          <img
            className='customCursor'
            style={{ width: 18, cursor: 'pointer' }}
            src={AlarmClock}
          />
          <div className='taskLine' style={{ fontSize:mobileView?17.5:14, fontWeight: 500,color:colorSchemes.FontSemiGrey}}>
            Remind me
          </div>
          </div>
        </div> */}


                {/* {taskItem.MyDay ? (
          <div className='taskList'>
            <img style={{ width: 16, cursor: 'pointer' }} src={sunnyDay} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <div
                className='taskLine'
                style={{ fontSize: 14, fontWeight: 500 }}
              >
                Added to My Day
              </div>
              <img
                onClick={() => removeMyDay()}
                style={{ width: 12, height: 12, cursor: 'pointer' }}
                src={CloseIcon}
              />
            </div>
          </div>
        ) : (
          <div className='taskList' onClick={() => addMyDay()}>
            <img style={{ width: 16, cursor: 'pointer' }} src={sunnyDay} />
            <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
              Add to My Day
            </div>
          </div>
        )} */}

                {/*  <div className='taskList'>
          <img style={{ width: 16, cursor: 'pointer' }} src={Repeat} />
          <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
            Repeat
          </div>
        </div>
        <div className='taskList'>
          <img style={{ width: 16, cursor: 'pointer' }} src={PickerIcon} />
          <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
            Pick a Category
          </div>
        </div> */}

                <div
                  className='taskListForTaskNotes'
                  style={{ paddingRight: 8, background: !mobileView ? '#3068C81A' : mobileView && isLightMode ? '#3068C81A' : '#384d66'}}
                >
                  {/* <img style={{ width: 16, cursor: 'pointer' }} src={AddFlieIcon} /> */}

                  {/* {taskItem && (
              <LinkItUrl>
            <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
              <textarea
                className='textAreaInput'
                placeholder='Add note'
                value={taskItem.notes}
                onInput={handleChangeText}
                onBlur={() => AddTaskNoteApi()}
                rows={4}
              />
            </div>
              </LinkItUrl>
          )} */}


                  {taskItem && taskItem.taskName && (
                    <LinkItUrl>
                      <div style={{color:!mobileView?'#172D46':colorSchemes.FontColor, whiteSpace: "pre-wrap" }}

                        onPaste={(event: any) => {
                          console.log('onpastefromhtml')
                          event.preventDefault()

                          console.log("onpastefromhtml", event.clipboardData)
                          const text = event.clipboardData.getData('text/plain')
                          document.execCommand('insertHTML', false, text)

                          // When there's an paste event on our target DIV:
                          let cbPayload = [...(event.clipboardData || event.originalEvent.clipboardData).items];     // Capture the ClipboardEvent's eventData payload as an array

                          console.log("i45", event.clipboardData.items)

                          cbPayload = cbPayload.filter(i => /image/.test(i.type) || /video/.test(i.type) || /doc/.test(i.type) || /docx/.test(i.type) || /txt/.test(i.type) || /text/.test(i.type));                       // Strip out the non-image bits


                          if (!cbPayload.length || cbPayload.length === 0) return false;                      // If no image was present in the collection, bail.
                          console.log(cbPayload[0].getAsFile())
                          for (let i = 0; i < cbPayload.length; i++) {
                            handlePasteFile(cbPayload[i].getAsFile());
                          }
                          /* let reader = new FileReader();                                                     // Instantiate a FileReader...
                          reader.onload = (e) => {console.log(e.target.result)} // ... set its onLoad to render the event target's payload
                          reader.readAsDataURL(cbPayload[0].getAsFile());   */                                  // ... then read in the pasteboard image data as Base64


                        }}

                        ref={sideBarTaskNoteInputRef}
                        onBlur={e => {
                          // console.log('test task 12', e.target.innerText)
                          handleChangeTaskNotes(e.target.innerText)
                        }}
                        onMouseDown={e => onContentEditableLinksMouseDown(e)}
                        // onInput={e => handleChangeText(e)}
                        // onKeyDown={e => handleTaskNotesKeyDown(e)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            document.execCommand('insertLineBreak')
                            event.preventDefault()
                          }
                        }}
                        contentEditable={taskItem.isTrash != "2" && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? true : false}
                        suppressContentEditableWarning={true}
                        className='textAreaInputForTaskNote'
                        placeholder={listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? "Add a note..." : ""}
                        data-placeholder={listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? "Add a note..." : ""}
                      >
                        {decData(taskItem.notes, taskItem.isEncryptNotes)}
                      </div>
                    </LinkItUrl>
                  )}
                </div>



              <div className='dueAndReminder' style={{background:!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.WebLeftPaneBg, borderRadius:8,cursor:'pointer'}}
                    
                    id="basic-remind-pane-menu"
                  aria-controls={openReminMeMenuList ? 'basic-remind-pane' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openReminMeMenuList ? 'true' : undefined}

                    >
          <div>
          <img 
            style={{ width: 20, cursor: 'pointer', filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)' }}
            src={BellGreyIcon} onClick={(e: any)=>{  
              handleClickRemindMeMenuList(e)
              handleRemindMeClick(e)
              if (remindMeMenu) {
                    setRemindMeMenu(false)
                  } else {
                    setRemindMeMenu(true)
                    setMinTodayDateTime(new Date())
                  }}
                  }
          />
          <div className='taskLine' style={{ fontSize:mobileView?17.5:14, fontWeight: 500,color:!mobileView? '#000':mobileView && isLightMode?'#000':'#fff',cursor:'pointer' }} onClick={(e: any)=>{  
                handleClickRemindMeMenuList(e)
                handleRemindMeClick(e)
                if (remindMeMenu) {
                      setRemindMeMenu(false)
                    } else {
                      setRemindMeMenu(true)
                      setMinTodayDateTime(new Date())
                    }}
                    }>
         Remind Me<span style={{fontSize:14, color:"rgb(48, 104, 200)"}}>{taskItem.remindMeStatus == '2'? 'at '+reminderDate(taskItem.remindMeDate)+' '+taskItem.remindMeDateOriginalTime:''}
         </span>
          </div>
          {taskItem.remindMeStatus==2 && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: 10
                                    }}
                                    onClick={(e) => {e.preventDefault(); updateReminder('0', '','', '', '', '', '') }}
                                  >
                                    <img
                                      className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                                      style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 12, height: 12, cursor: taskItem.isTrash != "2" ? 'pointer' : 'inherit' }}
                                      src={CloseIcon}
                                    />
                                  </div>
                                }
          </div>
        </div>



                {taskItem.isTrash != "2" && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ?
                  <div className='rightPaneCardItems' style={{background:!mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.WebLeftPaneBg}} onClick={() => {
                    setTimeout(() => {
                      inputFileRef.current.click()
                    }, 1)
                  }}>
                    <img style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 24, cursor: 'pointer' }} src={!mobileView? AttacthedFileIcon:mobileView && isLightMode?AttacthedFileIcon:AddFlieIcon} />
                    <div className={isDark == 1 ? 'taskLine custom-file-input-dark' : 'taskLine custom-file-input'} style={{ fontSize: 14, fontWeight: 800/* ,color:colorSchemes.FontLightBlack */ }}>

                    </div>
                  </div>
                  :
                  null
                  /*  <div className='taskList customCursor' style={{ background:colorSchemes.DrawerBackColor,color:colorSchemes.FontBlackColor,marginTop:10, boxShadow:'0px 0.3px 1.9px rgba(0,0,0,0.1), 0px 0.6px 5.6px rgba(0,0,0,0.1)' }}>
           <img className="customCursor" style={{ width: 18}} src={AddFlieIcon} />
           <div className={isDark==1?'taskLine custom-file-input-dark customCursor':'taskLine custom-file-input customCursor'}  style={{ fontSize: 14, fontWeight: 800,color:colorSchemes.FontBlackColor }}>
           
           </div>
           </div> */
                }
                <input ref={inputFileRef} style={{ display: 'none' }}
                  type='file'
                  onChange={handleFileChange}
                />
                {/* 03/05 */}
                {uploadFileLoader && fileUploadingTime && fileUploadingTime.id == taskItem.id &&
                  <div className='fileNamesList' style={{ background: !mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.WebLeftPaneBg /* colorSchemes.MainBackGroundColor */, color: !mobileView?'#172D46':colorSchemes.FontColor  /* colorSchemes.FontSemiGrey */ }}>
                    <div className='fileNamesListInnerDiv' style={{ fontSize: 12, gap: 5 }}>
                      Uploading file....
                      <img className="loading-spinner" src={Loading} alt="" />
                      &nbsp;&nbsp; {fileUploadPercent} %
                    </div>
                  </div>
                }
                {/* 03/05 */}
                {mobileView && taskItem &&
                  taskItem.img &&
                  taskItem.img.length > 1 && <SwipeableDrawer
                    anchor="bottom"
                    open={filesDrawer}
                    onClose={() => setFilesDrawer(false)}
                    onOpen={() => setFilesDrawer(true)}
                    className='mainDrawerContainer'
                  /* style={{background:colorSchemes.DrawerBackColor}} */
                  >
                    <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.DrawerBackColor, color: 'rgba(23, 45, 70, 1)' /* colorSchemes.FontSemiGrey */, padding: "0px 0px 40px" }} >
                      <div className='drawerIcon' style={{background:colorSchemes.WebLeftPaneBg}}>
                        <div ></div>
                      </div>
                      <div className='drawerHeading' style={{background:colorSchemes.WebLeftPaneBg}}>
                        <div style={{ width: '20%' }}></div>
                        <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                          Files
                        </div>
                        <div className='drawerHeadingDone' onClick={() => setFilesDrawer(false)}>Done</div>
                      </div>
                      {mobileView && taskItem &&
                        taskItem.img &&
                        taskItem.img.length > 1 && taskItem.img.map((item: any, i: any) => (
                          <>
                            {/* i!=0 && */
                              <div
                                className='fileNamesList'
                                style={{ gap: 0, background: !mobileView? 'transparent':mobileView && isLightMode?'transparent':colorSchemes.WebLeftPaneBg, color: !mobileView?'#172D46':colorSchemes.FontColor  /* colorSchemes.FontSemiGrey */, marginBottom: "10px" }}
                              >
                                <div
                                  className='fileNamesListInnerDiv'
                                  onClick={() => {
                                    let signature = CryptoJS.AES.encrypt(JSON.stringify(item), FEKY).toString();
                                    console.log("CryptoJS", encodeURIComponent(signature))
                                    console.log(BASE_URL + "/fileview?file=" + encodeURIComponent(signature));
                                    console.log('item12', item);
                                    if (item && item.fileType && item.fileType.includes('image')) {
                                      imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                                      fileTypeName.current = 'image'
                                      fileName.current = item.name
                                      fileItem.current = item
                                      handleClickOpenImageViewer()
                                    }
                                    else if (item && item.fileType && item.fileType.includes('video')) {
                                      imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                                      fileTypeName.current = 'video'
                                      fileName.current = item.name
                                      fileItem.current = item
                                      handleClickOpenImageViewer()
                                    }
                                    else {
                                      window.open(BASE_URL + "/fileview?file=" + encodeURIComponent(signature))
                                    }
                                  }
                                  }
                                >
                                  <img
                                    // onClick={() => changeTaskStepStatus(item)}
                                    style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 18, cursor: 'pointer' }}
                                    src={AddFileLogo}
                                  />

                                  <div className='flieName'>{item.name}</div>
                                </div>
                                {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: 10
                                    }}
                                    onClick={() => { taskItem.isTrash != "2" ? handleClickConfirm(item) : console.log('test') }}
                                  >
                                    <img
                                      className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                                      style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 12, height: 12, cursor: taskItem.isTrash != "2" ? 'pointer' : 'inherit' }}
                                      src={CloseIcon}
                                    />
                                  </div>
                                }
                              </div>
                            }
                          </>
                        ))}
                    </div>
                  </SwipeableDrawer>
                }
                {taskItem &&
                  taskItem.img &&
                  taskItem.img.length == 1 &&

                  <div
                    className='fileNamesList'
                    style={{ gap: 0, background: !mobileView? 'transparent':mobileView && isLightMode?'transparent':colorSchemes.WebLeftPaneBg, color: !mobileView?'#172D46':colorSchemes.FontColor  /* colorSchemes.FontSemiGrey */ }}
                  >
                    <div
                      className='fileNamesListInnerDiv'
                      onClick={() => {
                        let signature = CryptoJS.AES.encrypt(JSON.stringify(taskItem.img[0]), FEKY).toString();
                        console.log("CryptoJS", encodeURIComponent(signature))
                        console.log(BASE_URL + "/fileview?file=" + encodeURIComponent(signature));
                        console.log('item12', taskItem.img[0]);
                        if (taskItem.img[0] && taskItem.img[0].fileType && taskItem.img[0].fileType.includes('image')) {
                          imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                          fileTypeName.current = 'image'
                          fileName.current = taskItem.img[0].name
                          fileItem.current = taskItem.img[0]
                          handleClickOpenImageViewer()
                        }
                        else if (taskItem.img[0] && taskItem.img[0].fileType && taskItem.img[0].fileType.includes('video')) {
                          imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                          fileTypeName.current = 'video'
                          fileName.current = taskItem.img[0].name
                          fileItem.current = taskItem.img[0]
                          handleClickOpenImageViewer()
                        }
                        else {
                          window.open(BASE_URL + "/fileview?file=" + encodeURIComponent(signature))
                        }
                      }
                      }
                    >
                      <img
                        // onClick={() => changeTaskStepStatus(item)}
                        style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 18, cursor: 'pointer' }}
                        src={AddFileLogo}
                      />
                      {/* <div className='flieName'>{file && `${file.name} - ${file.type}`}</div> */}
                      <div className='flieName'>{taskItem.img[0].name}</div>
                    </div>
                    {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: 10
                        }}
                        onClick={() => { taskItem.isTrash != "2" ? handleClickConfirm(taskItem.img[0]) : console.log('test') }}
                      >
                        <img
                          className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                          style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 12, height: 12, cursor: taskItem.isTrash != "2" ? 'pointer' : 'inherit' }}
                          src={CloseIcon}
                        />
                      </div>
                    }
                  </div>
                }

                {mobileView && taskItem &&
                  taskItem.img &&
                  taskItem.img.length > 1 && <div onClick={() => setFilesDrawer(true)}
                    style={{
                      gap: 0, background: mobileView ? 'transparent' : "transparent",
                      paddingLeft: "20px",
                      color: "rgb(100, 120, 199)",
                      fontWeight: "normal", marginTop: "15px", textDecoration: "underline",
                      marginBottom: "15px", cursor: "pointer"
                    }}>View {taskItem.img.length} files</div>}

                {!mobileView && taskItem &&
                  taskItem.img &&
                  taskItem.img.length > 1 && <div onClick={() => setFileCollapse(!fileCollapse)}
                    style={{
                      gap: 0, background: mobileView ? 'transparent' : 'transparent',
                      paddingLeft: "5px",
                      color: "rgb(100, 120, 199)",
                      fontWeight: "normal", marginTop: "10px",
                      marginBottom: "10px", alignItems: "center", display: "flex", cursor: "pointer"
                    }}>
                    <img style={{ width: 11, height: 15, transform: fileCollapse ? "rotate(270deg)" : "rotate(180deg)", marginRight: "5px" }}
                      /* className={
                        item.open
                          ? 'groupDownArrow'
                          : 'groupUpArrow'
                      } */
                      src={BlueBackArrow}
                    />View {taskItem.img.length} files</div>}


                {!mobileView && fileCollapse && taskItem &&
                  taskItem.img &&
                  taskItem.img.length > 1 && taskItem.img.map((item: any, i: any) => (
                    <div
                      className='fileNamesList'
                      style={{ gap: 0, background: !mobileView? 'transparent':mobileView && isLightMode?'transparent':colorSchemes.WebLeftPaneBg, color: !mobileView?'#172D46':colorSchemes.FontColor  }}
                    >
                      <div
                        className='fileNamesListInnerDiv'
                        onClick={() => {
                          let signature = CryptoJS.AES.encrypt(JSON.stringify(item), FEKY).toString();
                          console.log("CryptoJS", encodeURIComponent(signature))
                          console.log(BASE_URL + "/fileview?file=" + encodeURIComponent(signature));
                          console.log('item12', item);
                          if (item && item.fileType && item.fileType.includes('image')) {
                            imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                            fileTypeName.current = 'image'
                            fileName.current = item.name
                            fileItem.current = item
                            handleClickOpenImageViewer()
                          }
                          else if (item && item.fileType && item.fileType.includes('video')) {
                            imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                            fileTypeName.current = 'video'
                            fileName.current = item.name
                            fileItem.current = item
                            handleClickOpenImageViewer()
                          }
                          else {
                            window.open(BASE_URL + "/fileview?file=" + encodeURIComponent(signature))
                          }
                        }
                        }
                      >
                        <img
                          // onClick={() => changeTaskStepStatus(item)}
                          style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 18, cursor: 'pointer' }}
                          src={AddFileLogo}
                        />

                        <div className='flieName'>{item.name}</div>
                      </div>
                      {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 10
                          }}
                          onClick={() => { taskItem.isTrash != "2" ? handleClickConfirm(item) : console.log('test') }}
                        >
                          <img
                            className={taskItem.isTrash != "2" ? '' : 'customCursor'}
                            style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 12, height: 12, cursor: taskItem.isTrash != "2" ? 'pointer' : 'inherit' }}
                            src={CloseIcon}
                          />
                        </div>
                      }
                    </div>
                  ))}


                {taskItem && taskItem.comments && taskItem.comments.length == 0 &&
                  <div style={{ height: 50 }}></div>
                }
              </div>
              :
              <>
                {taskItem && taskItem.taskName && (
                  <div
                    className='taskListForTaskNotes'
                    style={{ paddingRight: 8, minHeight: 400, background: !mobileView ? '#3068C81A' : mobileView && isLightMode ? '#3068C81A' : '#384d66' }}
                  >
                    <LinkItUrl>
                      <div style={mobileView ? { /* background: colorSchemes.MainBackGroundColor, color: colorSchemes.FontBlackColor, */ minHeight: 400, whiteSpace: "pre-wrap" } : { whiteSpace: "pre-wrap", minHeight: 400, color: colorSchemes.FontBlackColor }}

                        onPaste={(event: any) => {
                          console.log('onpastefromhtml')
                          event.preventDefault()
                          console.log("onpastefromhtml", event.clipboardData)
                          const text = event.clipboardData.getData('text/plain')
                          document.execCommand('insertHTML', false, text)

                          // When there's an paste event on our target DIV:
                          let cbPayload = [...(event.clipboardData || event.originalEvent.clipboardData).items];     // Capture the ClipboardEvent's eventData payload as an array

                          console.log("i45", event.clipboardData.items)

                          cbPayload = cbPayload.filter(i => /image/.test(i.type) || /video/.test(i.type) || /doc/.test(i.type) || /docx/.test(i.type) || /txt/.test(i.type) || /text/.test(i.type));                       // Strip out the non-image bits


                          if (!cbPayload.length || cbPayload.length === 0) return false;                      // If no image was present in the collection, bail.
                          console.log(cbPayload[0].getAsFile())
                          for (let i = 0; i < cbPayload.length; i++) {
                            handlePasteFile(cbPayload[i].getAsFile());
                          }
                          /* let reader = new FileReader();                                                     // Instantiate a FileReader...
                          reader.onload = (e) => {console.log(e.target.result)} // ... set its onLoad to render the event target's payload
                          reader.readAsDataURL(cbPayload[0].getAsFile());   */                                  // ... then read in the pasteboard image data as Base64



                        }}

                        ref={sideBarTaskNoteInputRef}
                        onBlur={e => {
                          // console.log('test task 12', e.target.innerText)
                          handleChangeTaskNotes(e.target.innerText)
                        }}
                        onMouseDown={e => onContentEditableLinksMouseDown(e)}
                        // onInput={e => handleChangeText(e)}
                        // onKeyDown={e => handleTaskNotesKeyDown(e)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            document.execCommand('insertLineBreak')
                            event.preventDefault()
                          }
                        }}
                        contentEditable={taskItem.isTrash != "2" && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? true : false}
                        suppressContentEditableWarning={true}
                        className='textAreaInputForTaskNote'
                        placeholder={listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? "Write your ideas..." : ""}
                        data-placeholder={listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) ? "Write your ideas..." : ""}
                      >
                        {decData(taskItem.notes, taskItem.isEncryptNotes)}
                      </div>
                    </LinkItUrl>
                  </div>
                )}
                <input ref={inputFileRef} style={{ display: 'none' }}
                  type='file'
                  onChange={handleFileChange}
                />

                {/* 03/05 */}
                {uploadFileLoader && fileUploadingTime && fileUploadingTime.id == taskItem.id &&
                  <div className='fileNamesList' style={{ background:!mobileView? 'transparent':mobileView && isLightMode?'transparent':colorSchemes.WebLeftPaneBg, color: !mobileView?'#172D46':colorSchemes.FontColor  /* colorSchemes.FontSemiGrey */ }}>
                    <div className='fileNamesListInnerDiv' style={{ fontSize: 12, gap: 5 }}>
                      Uploading file....
                      <img className="loading-spinner" src={Loading} alt="" />
                      &nbsp;&nbsp; {fileUploadPercent} %
                    </div>
                  </div>
                }

                {/* 03/05 */}
                {taskItem &&
                  taskItem.img &&
                  taskItem.img.length > 0 &&
                  taskItem.img.map((item: any, i: any) => (
                    <div
                      className='fileNamesList'
                      style={{ gap: 0, background: !mobileView? 'transparent':mobileView && isLightMode?'transparent':colorSchemes.WebLeftPaneBg, color: !mobileView?'#172D46':colorSchemes.FontColor  }}
                    /* onClick={() => window.open(item.url)} */
                    >
                      <div
                        className='fileNamesListInnerDiv'

                        onClick={() => {
                          let signature = CryptoJS.AES.encrypt(JSON.stringify(item), FEKY).toString();
                          console.log("CryptoJS", encodeURIComponent(signature))
                          console.log(BASE_URL + "/fileview?file=" + encodeURIComponent(signature));
                          console.log('item12', item);
                          if (item && item.fileType && item.fileType.includes('image')) {
                            imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                            fileTypeName.current = 'image'
                            fileName.current = item.name
                            fileItem.current = item
                            handleClickOpenImageViewer()
                          }
                          else if (item && item.fileType && item.fileType.includes('video')) {
                            imgURL.current = BASE_URL + "/fileview?file=" + encodeURIComponent(signature)
                            fileTypeName.current = 'video'
                            fileName.current = item.name
                            fileItem.current = item
                            handleClickOpenImageViewer()
                          }
                          else {
                            window.open(BASE_URL + "/fileview?file=" + encodeURIComponent(signature))
                          }
                        }
                        }
                      >
                        <img
                          // onClick={() => changeTaskStepStatus(item)}
                          style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 18, cursor: 'pointer' }}
                          src={AddFileLogo}
                        />
                        {/* <div className='flieName'>{file && `${file.name} - ${file.type}`}</div> */}
                        <div className='flieName'>{item.name}</div>
                      </div>
                      {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 10
                          }}
                          onClick={() => handleClickConfirm(item)}
                        >
                          <img
                            style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 12, height: 12, cursor: 'pointer' }}
                            src={CloseIcon}
                          />
                        </div>
                      }
                    </div>
                  ))}

              </>
            }
            
          {/*   {mobileView && taskItem && taskItem.comments && taskItem.comments.length == 0 &&
              <div onClick={() => { setCommentSwipe(true) }} style={{
                padding: "5px 0px 5px 0px",
                marginTop: 15,
                fontSize: 16,
                color: mobileView ? 'rgb(100, 120, 199)' : '', textDecoration: "underline", cursor: "pointer"
              }}>
                Write/View Comments
              </div>} */}
            {taskItem && taskItem.comments && taskItem.comments.length > 0 &&
              <div style={{marginTop:24}}>
                {/* {!mobileView ?
                  null
                  :
                  <div onClick={() => { setCommentSwipe(true) }} style={{
                    padding: "5px 0px 5px 0px",
                    marginTop: 15,
                    fontSize: 16,
                    color: mobileView ? 'rgb(100, 120, 199)' : '', textDecoration: "underline", cursor: "pointer"
                  }}>
                    Write/View Comments ({taskItem.comments.length})
                  </div>
                } */}
                {/* !mobileView && */ commentCollapse && taskItem.comments.map((item: any, i: any) => (
                  <div className='MainCommentDiv' >

                    {/* <div className='userCommentDetailDiv'>
                      <div>
                     {item && item.firstName && item.firstName!="" ? <div style={{ fontSize: 12,color:colorSchemes.FontSemiGrey, textTransform:"capitalize" }}>
                        {Base64.decode(item.firstName)} {Base64.decode(item.lastName)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {timeSince(item.createdDateFormated)}
                      </div>:<div style={{ fontSize: 12,color:colorSchemes.FontSemiGrey}}>
                        {Base64.decode(item.email)} &nbsp; {timeSince(item.createdDateFormated)}
                      </div>}
                      
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          fontSize: 14,
                          color: colorSchemes.FontBlackColor,
                          wordBreak: 'break-word'
                        }}>{item.comment}</div>
                      </div>
                      </div>
                     
                      <div>
                        {item.toUserId == userId && 
                      <img style={{ cursor: 'pointer', width: 16 }} src={DeleteComment}  onClick={()=>{if(taskItem && taskItem.isTrash && taskItem.isTrash != "2"){removeComment(item)}else{}}} className={taskItem.isTrash != "2"?"":"customCursor"} />
                    }
                      </div>
                    
                    </div> */}
                    <div className='userCommentDetailDiv'>
                      <div style={{ width: '100%' }}>

                      {item.toUserId == userId && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                          <div style={{
                            display: 'flex',justifyContent: 'center',alignItems: 'center',width: 24,height: 24,borderRadius: '50%',float: 'right',background:'#EEEEEE',cursor: 'pointer'}}>
                            <img 
                             style={{ width: 12 }}
                             src={RightPaneCloseIcon} 
                             onClick={() => { if (taskItem && taskItem.isTrash && taskItem.isTrash != "2") { removeComment(item) } else { } }} 
                             className={taskItem.isTrash != "2" ? "" : "customCursor"} />
                          </div>
                        }
                        
                        <div>
                          {item && item.firstName && item.firstName != "" ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, marginBottom: 10 }}>
                              <div className='userNameLetterCommentDiv'>
                                {Base64.decode(item.firstName).charAt(0)}{/*  + Base64.decode(item.lastName).charAt(0)} */}
                              </div>
                              <span style={{ fontSize: 16, fontWeight: 600, fontFamily: 'ManRopeSemiBold' }}>{Base64.decode(item.firstName)} {Base64.decode(item.lastName)}</span>
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 12, marginBottom: 10 }}>
                              <div className='userNameLetterCommentDiv'>
                                {Base64.decode(item.email).charAt(0)}
                              </div>
                              <span style={{ fontSize: 16, fontWeight: 600, fontFamily: 'ManRopeSemiBold' }}>{Base64.decode(item.email)}</span>
                            </div>
                          }
                        </div>

                        <div style={{ fontSize: 13, fontWeight: 400, color: !mobileView?'#172D46':colorSchemes.FontColor , fontFamily: 'ManRope' }}>
                          {timeSince(item.createdDateFormated)}
                        </div>
                        <div style={{ fontSize: 14, color: !mobileView?'#172D46':colorSchemes.FontColor , wordBreak: 'break-word', textTransform: 'initial', fontFamily: 'ManRope', marginTop: 11 }}>
                          {item.comment}
                        </div>

                        

                        {/* {item && item.firstName && item.firstName != "" ? 
                        <div style={{ fontSize: 12, color: colorSchemes.FontSemiGrey, textTransform: "capitalize" }}>
                          <span style={{ fontSize: 14, color: colorSchemes.FontBlackColor, wordBreak: 'break-word', textTransform: 'initial' }}>{item.comment}</span>
                          &nbsp; &nbsp;&nbsp;&nbsp;
                          <span style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                            {item.toUserId == userId && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                              <img style={{ cursor: 'pointer', width: 7, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { if (taskItem && taskItem.isTrash && taskItem.isTrash != "2") { removeComment(item) } else { } }} className={taskItem.isTrash != "2" ? "" : "customCursor"} />
                            }
                          </span>
                        </div>
                          :
                          <div style={{ fontSize: 12, color: colorSchemes.FontSemiGrey }}>
                            <span style={{ color: '#6478c7' }}>{Base64.decode(item.email)} &nbsp;&nbsp;  {timeSince(item.createdDateFormated)}</span> <br />
                            <span style={{ fontSize: 14, color: colorSchemes.FontBlackColor, wordBreak: 'break-word', textTransform: 'initial' }}>{item.comment}</span>
                            &nbsp; &nbsp;
                            <span style={{ whiteSpace: 'nowrap', fontSize: 12, float: 'right', color: '#6478c7' }}>
                              {item.toUserId == userId && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                <img style={{ cursor: 'pointer', width: 7, marginLeft: 10, marginTop: -1 }} src={CommentDeleteCrossIcon} onClick={() => { if (taskItem && taskItem.isTrash && taskItem.isTrash != "2") { removeComment(item) } else { } }} className={taskItem.isTrash != "2" ? "" : "customCursor"} />
                              }
                            </span>
                          </div>} */}

                        {/*  <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          fontSize: 14,
                          color: colorSchemes.FontBlackColor,
                          wordBreak: 'break-word'
                        }}>{item.comment}</div>
                      </div> */}
                      </div>

                      {/*  <div>
                        {item.toUserId == userId && 
                      <img style={{ cursor: 'pointer', width: 16 }} src={DeleteComment}  onClick={()=>{if(taskItem && taskItem.isTrash && taskItem.isTrash != "2"){removeComment(item)}else{}}} className={taskItem.isTrash != "2"?"":"customCursor"} />
                    }
                      </div> */}

                    </div>







                  </div>
                ))}
                <div style={{ height: 50 }}></div>
              </div>
            }

          </>
          :
          taskItem && taskItem.taskName ?
            <div style={{ marginTop: 10 }}>
              <div className='dueAndReminder' style={{ cursor: 'pointer', background: !mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.WebLeftPaneBg /* colorSchemes.MainBackGroundColor */ }} onClick={() => { changeTrunIntoTask(taskItem) }}>
                <div>
                  <img
                    style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',width: 18, cursor: 'pointer' }}
                    src={TurnIntoTask}
                  />
                  <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
                    Turn into task
                  </div>
                </div>
              </div>
              {mobileView ? null : <div className='dueAndReminder' style={{ cursor: 'pointer', background: !mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.WebLeftPaneBg /* colorSchemes.MainBackGroundColor */ }} onClick={() => { sideBarTaskNameInput?.current?.focus() }}>
                <div>
                  <img
                    style={{ width: 18, cursor: 'pointer' }}
                    src={EditHeadingIcon}
                  />
                  <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
                    Edit heading
                  </div>
                </div>
              </div>}
              <div className='dueAndReminder' style={{ cursor: 'pointer', background: !mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.WebLeftPaneBg /* colorSchemes.MainBackGroundColor */ }} onClick={(e) => {
                setDataItem(taskItem)
                setColorPalette(true);
                setPoints({
                  x: e.pageX - 210,
                  y: e.pageY - 230
                })
                setHighlightFrom('task')
              }}>
                <div>
                  <img
                    style={{ filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)', width: 18, cursor: 'pointer' }}
                    src={MarkerIcon}
                  />
                  <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
                    Highlight heading
                  </div>
                </div>
              </div>
              <div className='dueAndReminder' style={{ cursor: 'pointer', background: !mobileView? '#fff':mobileView && isLightMode?'#fff':colorSchemes.WebLeftPaneBg /* colorSchemes.MainBackGroundColor */ }} onClick={() => { setDataTrashItem(taskItem); moveToTrash(taskItem); setTaskSectionOpen(false); handleClickSnack(SlideTransition) }}>
                <div>
                  <img
                    style={{ width: 18, cursor: 'pointer' }}
                    src={RedDelete}
                  />
                  <div className='taskLine' style={{ fontSize: 14, fontWeight: 500, color: 'red' }}>
                    Delete heading
                  </div>
                </div>
              </div>
            </div>
            : null
        }
      </div>


      {taskItem && taskItem.taskHeading && taskItem.taskHeading == 0 ?
        <>
          {taskItem && taskItem.taskNote && taskItem.taskNote == 0 &&
            <>
              {taskItem && taskItem.isTrash && taskItem.isTrash != "2" &&
                <div style={!mobileView && (commentCollapse || (taskItem.comments && taskItem.comments.length == 0)) ? { /* border: mobileView ? 'none' : '1px solid lightgray', padding: '15px 12px', position: 'absolute', bottom: 40, width: mobileView ? '100%' : 350, background: colorSchemes.MainBackGroundColor,  */ display:'flex',justifyContent:'flex-start',alignItems:'center',gap:12} : {display:'flex',justifyContent:'flex-start',alignItems:'center',gap:12,padding:'24px 16px',background:!mobileView? 'rgba(251, 251, 251, 1)':mobileView && isLightMode?'rgba(251, 251, 251, 1)':colorSchemes.WebLeftPaneBg ,position: 'absolute',bottom: '0',width: '100%'}}>


                  {/* <div>
      {taskItem && taskItem.comments && taskItem.comments.length > 0 && lastCommentDivShow &&
<div>
<div className='MainCommentDiv' style={{marginTop:5}} >

{taskItem.comments[taskItem.comments.length-1] && taskItem.comments[taskItem.comments.length-1].firstName && taskItem.comments[taskItem.comments.length-1].firstName!="" ?<div className='userNameLetterCommentDiv'>
                       {Base64.decode(taskItem.comments[taskItem.comments.length-1].firstName).charAt(0) + Base64.decode(taskItem.comments[taskItem.comments.length-1].lastName).charAt(0)}
                      </div>:<div className='userNameLetterCommentDiv'>
                       {Base64.decode(taskItem.comments[taskItem.comments.length-1].email).charAt(0)}
                      </div>}
                    <div className='userCommentDetailDiv'>
                      <div>
                      {taskItem.comments[taskItem.comments.length-1] && taskItem.comments[taskItem.comments.length-1].firstName && taskItem.comments[taskItem.comments.length-1].firstName!="" ? <div style={{ fontSize: 12,color:colorSchemes.FontSemiGrey, textTransform:"capitalize" }}>
                        {Base64.decode(taskItem.comments[taskItem.comments.length-1].firstName)} {Base64.decode(taskItem.comments[taskItem.comments.length-1].lastName)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {timeSince(taskItem.comments[taskItem.comments.length-1].createdDateFormated)}
                      </div>:<div style={{ fontSize: 12,color: colorSchemes.FontSemiGrey}}>
                        {Base64.decode(taskItem.comments[taskItem.comments.length-1].email)} &nbsp; {timeSince(taskItem.comments[taskItem.comments.length-1].createdDateFormated)}
                      </div>}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          fontSize: 14,
                          color: colorSchemes.FontBlackColor,
                          wordBreak: 'break-word'
                        }}>{taskItem.comments[taskItem.comments.length-1].comment}</div>
                      </div>
                      </div>
                     
                      <div>
                        <span style={{fontSize: 12,position: 'absolute',marginTop: -16,right:8,cursor:'pointer',color:colorSchemes.FontSemiGrey}} onClick={()=>scrollToBottom()}>More</span>
                      </div>
                    
                    </div>
                  </div>
</div>
}
      </div> */}

                  <SwipeableDrawer
                    anchor="bottom"
                    open={commentSwipe}
                    onClose={() => setCommentSwipe(false)}
                    onOpen={() => setCommentSwipe(true)}
                    className='mainDrawerContainer'
                  /* style={{background:colorSchemes.DrawerBackColor}} */
                  >
                    <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.DrawerBackColor, color: colorSchemes.FontSemiGrey }} >
                      <div className='drawerIcon' style={{background:colorSchemes.WebLeftPaneBg}}>
                        <div ></div>
                      </div>
                      <div className='drawerHeading' style={{background:colorSchemes.WebLeftPaneBg}}>
                        <div style={{ width: '20%' }}></div>
                        <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                          Comments
                        </div>
                        <div className='drawerHeadingDone' onClick={() => setCommentSwipe(false)}>Done</div>
                      </div>
                      <div style={{ maxHeight: "450px", overflow: "scroll", padding: "15px" }} ref={commentDrawerDivRef}>
                        {taskItem && taskItem.comments && taskItem.comments.length > 0 && taskItem.comments.map((item: any, i: any) => (
                          <div className='MainCommentDiv' >
                            {/* <div>
                    {item && item.firstName && item.firstName!="" ?<div className='userNameLetterCommentDiv'>
                       {Base64.decode(item.firstName).charAt(0) + Base64.decode(item.lastName).charAt(0)}
                      </div>:<div className='userNameLetterCommentDiv'>
                       {Base64.decode(item.email).charAt(0)}
                      </div>}
                    </div> */}
                            {/* <div className='userCommentDetailDiv'>
                      <div>
                     {item && item.firstName && item.firstName!="" ? <div style={{ fontSize: 12,color:colorSchemes.FontSemiGrey, textTransform:"capitalize" }}>
                        {Base64.decode(item.firstName)} {Base64.decode(item.lastName)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {timeSince(item.createdDateFormated)}
                      </div>:<div style={{ fontSize: 12,color:colorSchemes.FontSemiGrey}}>
                        {Base64.decode(item.email)} &nbsp; {timeSince(item.createdDateFormated)}
                      </div>}
                      
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          fontSize: 14,
                          color: colorSchemes.FontBlackColor,
                          wordBreak: 'break-word'
                        }}>{item.comment}</div>
                      </div>
                      </div>
                     
                      <div>
                        {item.toUserId == userId && 
                      <img style={{ cursor: 'pointer', width: 16 }} src={DeleteComment}  onClick={()=>{if(taskItem && taskItem.isTrash && taskItem.isTrash != "2"){removeComment(item)}else{}}} className={taskItem.isTrash != "2"?"":"customCursor"} />
                    }
                      </div>
                    
                    </div> */}
                            <div className='userCommentDetailDiv'>
                              <div style={{ width: '100%' }}>
                                {item && item.firstName && item.firstName != "" ? <div style={{ fontSize: 12, color: colorSchemes.FontSemiGrey/*  '#464645' */, textTransform: "capitalize" }}>
                                  <span style={{ color: '#6478c7' }}>{Base64.decode(item.firstName)} {Base64.decode(item.lastName)}</span>&nbsp; &nbsp;&nbsp;
                                  <span style={{ fontSize: 14, color: colorSchemes.FontBlackColor, wordBreak: 'break-word', textTransform: 'initial' }}>{item.comment}</span>
                                  &nbsp; &nbsp;
                                  <span style={{ whiteSpace: 'nowrap', float: 'right', color: '#6478c7' }}>
                                    {timeSince(item.createdDateFormated)}
                                    {item.toUserId == userId && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                      <img style={{ cursor: 'pointer', width: 9, marginLeft: 15 }} src={CommentDeleteCrossIcon} onClick={() => { if (taskItem && taskItem.isTrash && taskItem.isTrash != "2") { removeComment(item) } else { } }} className={taskItem.isTrash != "2" ? "" : "customCursor"} />
                                    }
                                  </span>
                                </div>
                                  :
                                  <div style={{ fontSize: 12, color: colorSchemes.FontSemiGrey/*  '#464645' */ }}>
                                    <span style={{ color: '#6478c7' }}>{Base64.decode(item.email)}</span> &nbsp;&nbsp;&nbsp;
                                    <span style={{ fontSize: 14, color: colorSchemes.FontBlackColor, wordBreak: 'break-word', textTransform: 'initial' }}>{item.comment}</span>
                                    &nbsp; &nbsp;
                                    <span style={{ whiteSpace: 'nowrap', float: 'right', color: '#6478c7' }}>
                                      {timeSince(item.createdDateFormated)}
                                      {item.toUserId == userId && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                                        <img style={{ cursor: 'pointer', width: 9, marginLeft: 15 }} src={CommentDeleteCrossIcon} onClick={() => { if (taskItem && taskItem.isTrash && taskItem.isTrash != "2") { removeComment(item) } else { } }} className={taskItem.isTrash != "2" ? "" : "customCursor"} />
                                      }
                                    </span>
                                  </div>}

                                {/*  <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                          fontSize: 14,
                          color: colorSchemes.FontBlackColor,
                          wordBreak: 'break-word'
                        }}>{item.comment}</div>
                      </div> */}
                              </div>

                              {/*  <div>
                        {item.toUserId == userId && 
                      <img style={{ cursor: 'pointer', width: 16 }} src={DeleteComment}  onClick={()=>{if(taskItem && taskItem.isTrash && taskItem.isTrash != "2"){removeComment(item)}else{}}} className={taskItem.isTrash != "2"?"":"customCursor"} />
                    }
                      </div> */}

                            </div>







                          </div>
                        ))}
                        </div>
                    </div>
                    {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                      <div
                        className="auto-grow-input"
                        style={{
                          display: 'inline-grid',
                          alignItems: 'stretch',
                          // border: '1px solid #ccc',
                          borderRadius: 4,
                          width: '100%',
                          // background: '#F3F3F3',
                          background: colorSchemes.DrawerBackColor,
                          color: colorSchemes.FontBlackColor,
                          maxHeight: 150,
                          overflowX: 'hidden',
                          overflowY: 'scroll', paddingBottom: "40px", padding: "25px 20px 70px 20px"
                        }}
                      >
                        <textarea
                          className='commentArea'
                          rows={1}
                          value={commentTextArea}
                          onChange={(event) => setCommentTextArea(event.target.value)}
                          style={{
                            gridArea: '1 / 1 / 2 / 2',
                            width: '100%',
                            padding: 0,
                            border: 'none',
                            color: !mobileView?'#172D46':colorSchemes.FontColor,
                          }}
                          placeholder='Write a comment...'
                          onBlur={(e) => { AddCommentsBlur(e, taskItem) }}
                          onKeyDown={(e) => AddComments(e, taskItem)}
                        />
                        <span
                          style={{
                            gridArea: '1 / 1 / 2 / 2',
                            visibility: 'hidden',
                            whiteSpace: 'pre-wrap',
                            fontSize: 14,
                            wordBreak: 'break-word'
                          }}
                        >
                          {commentTextArea}
                        </span>
                      </div>
                    }

                  </SwipeableDrawer>
                  {listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
                    <>
                      {/* !mobileView && */ (commentCollapse || (taskItem.comments && taskItem.comments.length == 0)) && <div
                        className="auto-grow-input"
                        style={{
                          display: 'inline-grid',
                          alignItems: 'stretch',
                          padding: '8px 14px',
                          // border: '1px solid #ccc',
                          borderRadius: 8,
                          border: '1px solid #D0D5DD',
                          width: '100%',
                          // background: '#F3F3F3',
                          // background: mobileView ? colorSchemes.DrawerBackColor : '#F3F3F3',
                          color: '#000' /* colorSchemes.FontBlackColor */,
                          maxHeight: 150,
                          overflowX: 'hidden',
                          overflowY: 'scroll',
                        }}
                      >
                        <textarea
                          className='commentArea'
                          rows={1}
                          value={commentTextArea}
                          onChange={(event) => setCommentTextArea(event.target.value)}
                          style={{
                            gridArea: '1 / 1 / 2 / 2',
                            width: '100%',
                            padding: 0,
                            border: 'none',
                            color: !mobileView?'#172D46':colorSchemes.FontColor /* colorSchemes.FontBlackColor */,
                          }}
                          placeholder='Write a comment... '
                          onBlur={(e) => { AddCommentsBlur(e, taskItem) }}
                          onKeyDown={(e) => AddComments(e, taskItem)}
                        />
                        <span
                          style={{
                            gridArea: '1 / 1 / 2 / 2',
                            visibility: 'hidden',
                            whiteSpace: 'pre-wrap',
                            fontSize: 14,
                            wordBreak: 'break-word'
                          }}
                        >
                          {commentTextArea}
                        </span>
                      </div>}

                      {/* {commentTextArea != '' && !mobileView && (
                        <div style={{ display: 'flex', justifyContent: 'end', padding: 5 }}>
                          <div className='addBtn' style={mobileView ? { color: colorSchemes.FontSemiGrey, padding: '2px 6px' } : { fontSize: 12 }} onClick={() => { addTaskComment(taskItem) }}>
                            Add
                          </div>
                        </div>
                      )} */}
                      <div style={{height:36,width:36,boxShadow: '0px 0px 16px 0px #00000014',borderRadius:8,background:'#fff',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={() => {if(!mobileView){addTaskComment(taskItem)}}}>
<img src={CommentSendIcon} style={{width:20}} />
</div>
                    </>}


                  {/* <div
          className='taskList'
          style={{ cursor: 'pointer', paddingRight: 8,background:'rgb(211,211,211,0.6)' }}
        >

          {taskItem && (
            <div className='taskLine' style={{ fontSize: 14, fontWeight: 500 }}>
             
              <textarea
                className='textAreaInput'
                placeholder='Add Comment...'
                rows={2}
               
              />
            </div>
          )}
         
        </div> */}
                </div>
              }
            </>
          }
        </>

        : null}
      {/* <div style={{display:'flex',justifyContent:taskItem && taskItem.isTrash == "2"?'space-between':'flex-end',alignItems:'center',height:40,width:'100%',padding:10,background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey}}>
{taskItem && taskItem.isTrash == "2" &&
        <div
          style={{display: 'flex',justifyContent: 'center',alignItems: 'center',gap: 8,cursor: 'pointer'}}
          onClick={() => {restoreTask(taskItem);setTaskSectionOpen(false)}}
        >
          <span>Restore</span>
          <img
            style={{ cursor: 'pointer', width: 18 }}
            src={Restore}
          />
        </div>
        }
{taskItem && taskItem.userid == userId &&
        <div>
          <img
            onClick={() => moveToTrash(taskItem)}
            style={{ cursor: 'pointer', width: 22 }}
            src={Delete}
          />
        </div>
        }    
</div> */}

   
      {/* {taskItem && taskItem.userid == userId && listItem && (listItem.userId == userId || (listItem.readOnlyStatus == '0' && listItem.userId != userId)) &&
        <div
          style={{ position: 'absolute', bottom: 10, right: mobileView ? 10 : 30 }}
        >
          <img
            onClick={() => moveToTrash(taskItem)}
            style={{ cursor: 'pointer', width: 22 }}
            src={Delete}
          />
        </div>
      } */}
      {/* {taskItem && taskItem.isTrash == "2" &&
        <div
          style={{ position: 'absolute', bottom: 10, right: mobileView ? '75%' : 255, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8, cursor: 'pointer' }}
          onClick={() => { restoreTask(taskItem); setTaskSectionOpen(false); if (mobileView) { rightPaneMobileBack() } }}
        >
          <span style={{ color: colorSchemes.FontGreyColor }}>Restore</span>
          <img
            style={{ cursor: 'pointer', width: 18 }}
            src={Restore}
          />
        </div>
      } */}

      {openImageViewer &&
        <Dialog
          open={openImageViewer}
          onClose={handleCloseImageViewer}
        >
          <ClickAwayListener onClickAway={() => handleCloseImageViewer}>
            {openImageViewer && <ImageViewer itemURL={imgURL.current} fileTypeName={fileTypeName.current} fileName={fileName.current} handleCloseImageViewer={handleCloseImageViewer} fileItem={fileItem.current} mobileView={mobileView} />}
          </ClickAwayListener>
        </Dialog>}


        {showNotify &&
            <NotifyDialog showNotify={showNotify} setShowNotify={setShowNotify} mobileView={mobileView} colorSchemes={colorSchemes} isLightMode={isLightMode} taskItem={taskItem} setNotifyStatus={setNotifyStatus} notifyStatus={notifyStatus} setNotifyOnceDate={setNotifyOnceDate} notifyOnceDate={notifyOnceDate} updateReminder={updateReminder} />
            }



{!mobileView ?
        <>
          {remindMeMenu &&
            <MuiMenu
              id="basic-remind-pane"
              anchorEl={remindMeAnchorEl}
              open={openReminMeMenuList}
              // onClose={handleCloseMenuList}
              MenuListProps={{
                'aria-labelledby': 'basic-remind-pane-menu',
              }}
              onContextMenu={e => {
                e.stopPropagation()
                e.preventDefault()
                setRemindMeMenu(false)
                setRemindMeMenuPosition(null)
                handleCloseRemindMeMenuList()
                setShowCalendar(false)
                setShowSelectTime(false)
              }}
              onClose={e => {
                setRemindMeMenu(false)
                setRemindMeMenuPosition(null)
                handleCloseRemindMeMenuList()
                setShowCalendar(false)
                setShowSelectTime(false)
              }}
              anchorReference='anchorPosition'
              anchorPosition={remindMeMenuPosition}
            >
              <div
                className='closeIconDiv'
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setRemindMeMenu(false)
                  setRemindMeMenuPosition(null)
                  handleCloseRemindMeMenuList()
                }}
              >
                <img src={CloseMenuListIcon} alt='' />
              </div>
              {!showCalendar ?
              <>
                <MuiMenuItem
                  onClick={e => {
                    setShowCalendar(true) 
                  setNotifyOnceDate(getTodayDate())
                    setSelectTimeOption("today")
                    //setMinuteVal()
                    if(getminMinuteValDate()<=41)
                    {
                    setHourVal(getminHourValDate())
                    }
                    else
                    {
                      setHourVal(getminHourValDate()+1)
                    }
                    
                    getminMinuteValDate()==0? setMinuteVal(15): getminMinuteValDate()>0 && getminMinuteValDate()<=10?setMinuteVal(15):getminMinuteValDate()>10 && getminMinuteValDate()<=25?setMinuteVal(30):getminMinuteValDate()>25 && getminMinuteValDate()<=41?setMinuteVal(45):setMinuteVal(0)
                    console.log("getminHourValDate1", getminHourValDate())
                    console.log("getminHourValDate1", getminMinuteValDate())
                  //   e.stopPropagation()
                  // e.preventDefault()
                  // setRemindMeMenu(false)
                  // setRemindMeMenuPosition(null)
                  // handleCloseRemindMeMenuList()
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems ' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',gap:10}}>
                      <img
                        style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                          width: 16,
                          // height:14,
                          cursor: 'pointer'
                        }}
                        src={RemindWatch}
                      />
                      <span>Later Today</span>
                    </div>
                    <span>
                    {getminMinuteValDate()==0? minTodayDateTime.getHours()+':15': getminMinuteValDate()>0 && getminMinuteValDate()<=10?minTodayDateTime.getHours()+':15':getminMinuteValDate()>10 && getminMinuteValDate()<=25?minTodayDateTime.getHours()+':30':getminMinuteValDate()>25 && getminMinuteValDate()<=41?minTodayDateTime.getHours()+':45':(minTodayDateTime.getHours()+1)+':00'}
                    </span>
                  </div>
                </MuiMenuItem>
              
                <MuiMenuItem
                onClick={e => {
                  //   e.stopPropagation()
                  // e.preventDefault()
                  // setRemindMeMenu(false)
                  // setRemindMeMenuPosition(null)
                  setSelectTimeOption("tomorrow")
                  setHourVal(9)
                  setMinuteVal(0)
                  setNotifyOnceDate(getTomorrowDate())
                  setShowCalendar(true)              

                  // handleCloseRemindMeMenuList()
                  }}                  
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems ' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',gap:10}}>
                      <img
                        style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                          width: 16,
                          // height:14,
                          cursor: 'pointer'
                        }}
                        src={RemindWatch}
                      />
                      <span>Tomorrow</span>
                    </div>
                    <span>
                    {moment(TomorrowDateTime).format('ddd')}, 9:00
                    </span>
                  </div>
                </MuiMenuItem>
              
                
              
                <MuiMenuItem
                  onClick={e => {
                  //   e.stopPropagation()
                  // e.preventDefault()
                  // setRemindMeMenu(false)
                  // setRemindMeMenuPosition(null)
                  setHourVal(minTodayDateTime.getHours())
                  setMinuteVal(0)
                  setShowCalendar(true)
                  setNotifyOnceDate(getTomorrowDate())
                    setSelectTimeOption("custom")
                  setTimeout(() => {
                    calRef.current.showPicker()
                  }, 100);

                  // handleCloseRemindMeMenuList()
                  }}
                  style={{
                    padding: 0,
                  }}
                >
                  <div className='settingModalItems '>
                    <>
                      <img
                        style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                          width: 16,
                          // height:14,
                          cursor: 'pointer'
                        }}
                        src={Calendar}
                      />
                      <span>Pick a date & time</span>
                    </>
                    
                  </div>
                </MuiMenuItem>
              

              </>
            :
            <div className='settingModalItems ' style={{flexDirection:'column'}}>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginBottom:10}}>
                <div style={{fontSize:16,fontWeight:'bold',fontFamily:'ManRopeBold'}}>
                  
                </div>
<div
                // className='closeIconDiv'
                onClick={e => {
                  setShowCalendar(false);
                  setShowSelectTime(false)
                }}
              >
                <img src={CloseMenuListIcon} alt='' style={{width:15,height:15}} />
              </div>
              </div>
{/* <input type='date' ref={calRef} style={{width: '90%',padding:5, border: '1px solid grey', borderRadius: 5,marginBottom: 10}}  /> */}
<div style={{padding:'0 10px'}} >
<span style={{color:!mobileView ? '#172D46' : mobileView && isLightMode ? '#172D46' : colorSchemes.FontColor}}>Select Date</span>
<input type='date' ref={calRef} disabled={selectTimeOption=="custom"?false:true}  min={selectTimeOption =="today"?getTodayDate():getTomorrowDate()} value={notifyOnceDate} onChange={(e)=>{setNotifyOnceDate(e.target.value)}} />
{notifyOnceDate == "" &&
<div style={{fontSize:13,fontFamily:'ManRope',color:'red',marginTop:5}}> 
Please Select Date
</div>
}
</div>

<div style={{width: '90%',padding:5, marginBottom: 10,display:'flex',justifyContent:'space-between',alignItems:'center'}}>
  <div>
  <span style={{marginRight:5}}>Hour</span>
<select name="HourVal" value={hourVal} onChange={(e)=>{
  e.target.value==getminHourValDate()? getminMinuteValDate()==0? setMinuteVal(15): getminMinuteValDate()>0 && getminMinuteValDate()<=10?setMinuteVal(15):getminMinuteValDate()>10 && getminMinuteValDate()<=25?setMinuteVal(30):getminMinuteValDate()>25 && getminMinuteValDate()<=41?setMinuteVal(45):setMinuteVal(0):setMinuteVal(0)
  setHourVal(parseInt(e.target.value));
  }}>
{selectTimeOption=="today" && hourData.map((item, index) => ( 
<option value={item.value} disabled={getminMinuteValDate()<=41 && getminHourValDate()<=item.value?false:
getminMinuteValDate()>41 && (getminHourValDate()+1)<=item.value ? false:true}>{item.label}</option>
))}
{selectTimeOption!="today" && hourData.map((item, index) => ( 
<option value={item.value}>{item.label}</option>
))}
      </select>
      </div>
      <div>
      <span style={{marginRight:5}}>Min</span>
      <select name="minuteVal" value={minuteVal} id="minuteVal" onChange={(e)=>{
       
        setMinuteVal(parseInt(e.target.value));
        }}>
{selectTimeOption=="today" && minuteData.map((item, index) => ( 
<option value={item.value} disabled={hourVal==getminHourValDate()?(getminMinuteValDate()+5)<=item.value?false:true:false}>{item.label}</option>
))}
{selectTimeOption!="today" && minuteData.map((item, index) => ( 
<option value={item.value}>{item.label}</option>
))}
      </select>
      </div>
      </div>
      
<div className='add-dialog-buttons' style={{marginTop:0, justifyContent: 'space-between',padding:20,paddingTop:0 }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={(e)=>{ 
                  e.stopPropagation()
                  e.preventDefault()              
                  
                  if(notifyOnceDate!="")
                  {
                  setRemindMeMenu(false)
                  setRemindMeMenuPosition(null)
                  handleCloseRemindMeMenuList()
                  setShowCalendar(false)
                  setShowSelectTime(false)

                  
                  let hval=hourVal.toString()
                  if(parseInt(hourVal)<10)
                  {
                    hval='0'+hourVal
                  }
                  let mval=minuteVal.toString()
                  if(parseInt(minuteVal)<10)
                  {
                    mval='0'+minuteVal
                  }
                  console.log("hour1", hval)
                  console.log("hour2", mval)
                  console.log("hour3", notifyOnceDate)
                  let date=new Date(notifyOnceDate+' '+hval+':'+mval)
                  let dateArray=date.toISOString().split('T')
                  let timeArray=(dateArray[1].split('.'))[0].split(':00')
                  console.log("hour3", dateArray[0])
                  console.log("hour3", timeArray[0])
                  let status='2'
                  let  reminderDate=notifyOnceDate;
                  let remindMeDateOriginal= notifyOnceDate+' '+hval+':'+mval
                  let remindMeDateISO=dateArray[0]+' '+timeArray[0];
                  let remindMeDateISOString= date.toISOString();
                  let remindMeDateISOTime=timeArray[0];
                  let  remindMeDateOriginalTime=hval+':'+mval;
                  updateReminder(status, reminderDate, remindMeDateOriginal, remindMeDateISO, remindMeDateISOString, 
                    remindMeDateISOTime, remindMeDateOriginalTime)
                  //updateReminder("2", )
                }

                  
                  }}>
                Save
              </button>
            </div>
            </div>
}
            </MuiMenu>
          }
        </>
        :
        <SwipeableDrawer
          anchor="bottom"
          open={remindMeMenu}
          onClose={() => {setRemindMeMenu(false);setShowCalendar(false)}}
          onOpen={() => {setRemindMeMenu(true);setShowCalendar(false)}}
          className='mainDrawerContainer'
        /* style={{background:colorSchemes.DrawerBackColor}} */
        >
          <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.DrawerBackColor, color: colorSchemes.FontSemiGrey }} >
            <div className='drawerIcon' style={{background:colorSchemes.WebLeftPaneBg}}>
              <div ></div>
            </div>
            <div className='drawerHeading' style={{background:colorSchemes.WebLeftPaneBg}}>
              <div style={{ width: '20%' }}></div>
              <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                Remind Me
              </div>
              <div className='drawerHeadingDone' onClick={() => {setRemindMeMenu(false);setShowCalendar(false)}}>Done</div>
            </div>
            <ul style={mobileView ? { padding: 0, background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor, paddingBottom: 40 } : {}} >
            {!showCalendar ?
              <>
                
              
              <MuiMenuItem
                  onClick={e => {
                    setShowCalendar(true) 
                  setNotifyOnceDate(getTodayDate())
                    setSelectTimeOption("today")
                    //setMinuteVal()
                    if(getminMinuteValDate()<=41)
                    {
                    setHourVal(getminHourValDate())
                    }
                    else
                    {
                      setHourVal(getminHourValDate()+1)
                    }
                    getminMinuteValDate()==0? setMinuteVal(15): getminMinuteValDate()>0 && getminMinuteValDate()<=10?setMinuteVal(15):getminMinuteValDate()>10 && getminMinuteValDate()<=25?setMinuteVal(30):getminMinuteValDate()>25 && getminMinuteValDate()<=41?setMinuteVal(45):setMinuteVal(0)                   
                    console.log("getminHourValDate1", getminHourValDate())
                    console.log("getminHourValDate1", getminMinuteValDate())
                  //   e.stopPropagation()
                  // e.preventDefault()
                  // setRemindMeMenu(false)
                  // setRemindMeMenuPosition(null)
                  // handleCloseRemindMeMenuList()
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems ' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',gap:10}}>
                      <img
                        style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                          width: 16,
                          // height:14,
                          cursor: 'pointer'
                        }}
                        src={RemindWatch}
                      />
                      <span>Later Today</span>
                    </div>
                    <span>
                    {getminMinuteValDate()==0? minTodayDateTime.getHours()+':15': getminMinuteValDate()>0 && getminMinuteValDate()<=10?minTodayDateTime.getHours()+':15':getminMinuteValDate()>10 && getminMinuteValDate()<=25?minTodayDateTime.getHours()+':30':getminMinuteValDate()>25 && getminMinuteValDate()<=41?minTodayDateTime.getHours()+':45':(minTodayDateTime.getHours()+1)+':00'}
                    </span>
                  </div>
                </MuiMenuItem>
                <MuiMenuItem
                onClick={e => {
                  //   e.stopPropagation()
                  // e.preventDefault()
                  // setRemindMeMenu(false)
                  // setRemindMeMenuPosition(null)
                  setSelectTimeOption("tomorrow")
                  setHourVal(9)
                  setMinuteVal(0)
                  setNotifyOnceDate(getTomorrowDate())
                  setShowCalendar(true)              

                  // handleCloseRemindMeMenuList()
                  }}                  
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems ' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',gap:10}}>
                      <img
                        style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                          width: 16,
                          // height:14,
                          cursor: 'pointer'
                        }}
                        src={RemindWatch}
                      />
                      <span>Tomorrow</span>
                    </div>
                    <span>
                    {moment(TomorrowDateTime).format('ddd')}, 9:00
                    </span>
                  </div>
                </MuiMenuItem>
              
                
              
                <MuiMenuItem
                  onClick={e => {
                  //   e.stopPropagation()
                  // e.preventDefault()
                  // setRemindMeMenu(false)
                  // setRemindMeMenuPosition(null)
                  setHourVal(minTodayDateTime.getHours())
                  setMinuteVal(0)
                  setShowCalendar(true)
                  setNotifyOnceDate(getTomorrowDate())
                    setSelectTimeOption("custom")
                  setTimeout(() => {
                    calRef.current.showPicker()
                  }, 100);

                  // handleCloseRemindMeMenuList()
                  }}
                  style={{
                    padding: 0,
                  }}
                >
                  <div className='settingModalItems '>
                    <>
                      <img
                        style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                          width: 16,
                          // height:14,
                          cursor: 'pointer'
                        }}
                        src={Calendar}
                      />
                      <span>Pick a date & time</span>
                    </>
                    
                  </div>
                </MuiMenuItem>              
                

              </>
            :
            <div className='settingModalItems ' style={{flexDirection:'column'}}>
              {/* <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',marginBottom:10}}>
                <div style={{fontSize:16,fontWeight:'bold',fontFamily:'ManRopeBold'}}>
                  Pick date & time
                </div>
<div
                
                onClick={e => {
                  setShowCalendar(false);
                  setShowSelectTime(false)
                }}
              >
                <img src={CloseMenuListIcon} alt='' style={{width:15,height:15}} />
              </div>
              </div> */}



<div style={{padding:'0 10px', marginTop:20}} >
<span style={{color:!mobileView ? '#172D46' : mobileView && isLightMode ? '#172D46' : colorSchemes.FontColor}}>Select Date</span><br/>
<input type='date' ref={calRef} disabled={selectTimeOption=="custom"?false:true}  min={selectTimeOption =="today"?getTodayDate():getTomorrowDate()} value={notifyOnceDate} onChange={(e)=>{setNotifyOnceDate(e.target.value)}} />
{notifyOnceDate == "" &&
<div style={{fontSize:13,fontFamily:'ManRope',color:'red',marginTop:5}}> 
Please Select Date
</div>
}
</div>

<div style={{width: '90%',padding:5, marginBottom: 10,display:'flex',justifyContent:'space-between',alignItems:'center'}}>
  <div>
  <span style={{marginRight:5}}>Hour</span>
<select name="HourVal" value={hourVal} onChange={(e)=>{
  e.target.value==getminHourValDate()? getminMinuteValDate()==0? setMinuteVal(15): getminMinuteValDate()>0 && getminMinuteValDate()<=10?setMinuteVal(15):getminMinuteValDate()>10 && getminMinuteValDate()<=25?setMinuteVal(30):getminMinuteValDate()>25 && getminMinuteValDate()<=41?setMinuteVal(45):setMinuteVal(0):setMinuteVal(0)
  setHourVal(parseInt(e.target.value));
  }}>
{selectTimeOption=="today" && hourData.map((item, index) => ( 
<option value={item.value} disabled={getminMinuteValDate()<=41 && getminHourValDate()<=item.value?false:
getminMinuteValDate()>41 && (getminHourValDate()+1)<=item.value ? false:true}>{item.label}</option>
))}
{selectTimeOption!="today" && hourData.map((item, index) => ( 
<option value={item.value}>{item.label}</option>
))}
      </select>
      </div>
      <div>
      <span style={{marginRight:5}}>Min</span>
      <select name="minuteVal" value={minuteVal} id="minuteVal" onChange={(e)=>{
       
        setMinuteVal(parseInt(e.target.value));
        }}>
{selectTimeOption=="today" && minuteData.map((item, index) => ( 
<option value={item.value} disabled={hourVal==getminHourValDate()?(getminMinuteValDate()+5)<=item.value?false:true:false}>{item.label}</option>
))}
{selectTimeOption!="today" && minuteData.map((item, index) => ( 
<option value={item.value}>{item.label}</option>
))}
      </select>
      </div>
      </div>
  



<div className='add-dialog-buttons' style={{marginTop:0, justifyContent: 'space-between',padding:20,paddingTop:0 }}>
              <button style={{ background: 'rgb(71,114,250)', color: '#fff', paddingTop: '11px', paddingBottom: '11px', width: 105 }}
                onClick={(e)=>{ 
                  e.stopPropagation()
                  e.preventDefault()
                  

                  if(notifyOnceDate!="")
                  {
                    setRemindMeMenu(false)
                    setRemindMeMenuPosition(null)
                    handleCloseRemindMeMenuList()
                    setShowCalendar(false)
                    setShowSelectTime(false)

                  
                    let hval=hourVal.toString()
                    if(parseInt(hourVal)<10)
                    {
                      hval='0'+hourVal
                    }
                    let mval=minuteVal.toString()
                    if(parseInt(minuteVal)<10)
                    {
                      mval='0'+minuteVal
                    }
                    console.log("hour1", hval)
                    console.log("hour2", mval)
                    console.log("hour3", notifyOnceDate)
                    let date=new Date(notifyOnceDate+' '+hval+':'+mval)
                    let dateArray=date.toISOString().split('T')
                    let timeArray=(dateArray[1].split('.'))[0].split(':00')
                    console.log("hour3", dateArray[0])
                    console.log("hour3", timeArray[0])
                    let status='2'
                    let  reminderDate=notifyOnceDate;
                    let remindMeDateOriginal= notifyOnceDate+' '+hval+':'+mval
                    let remindMeDateISO=dateArray[0]+' '+timeArray[0];
                    let remindMeDateISOString= date.toISOString();
                    let remindMeDateISOTime=timeArray[0];
                    let  remindMeDateOriginalTime=hval+':'+mval;
                    updateReminder(status, reminderDate, remindMeDateOriginal, remindMeDateISO, remindMeDateISOString, 
                      remindMeDateISOTime, remindMeDateOriginalTime)
                }
                  
                  }}>
                Save
              </button>
            </div>
            </div>
}
           </ul>
            {/* <div style={{height:25}}></div> */}
          </div>
        </SwipeableDrawer>
      }





      {!mobileView ?
        <>
          {rightPaneMenu &&
            <MuiMenu
              id="basic-right-pane"
              anchorEl={rightPaneAnchorEl}
              open={openRightPaneMenuList}
              // onClose={handleCloseMenuList}
              MenuListProps={{
                'aria-labelledby': 'basic-right-pane-menu',
              }}
              onContextMenu={e => {
                e.stopPropagation()
                e.preventDefault()
                setRightPaneMenu(false)
                setMenuPosition(null)
                handleCloseRightPaneMenuList()
              }}
              onClose={e => {
                setRightPaneMenu(false)
                setMenuPosition(null)
                handleCloseRightPaneMenuList()
              }}
              anchorReference='anchorPosition'
              anchorPosition={menuPosition}
            >
              <div
                className='closeIconDiv'
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setRightPaneMenu(false)
                  setMenuPosition(null)
                  handleCloseRightPaneMenuList()
                }}
              >
                <img src={CloseMenuListIcon} alt='' />
              </div>
              <>

                <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setRightPaneMenu(false)
                    setMenuPosition(null)
                    handleCloseRightPaneMenuList()
                    setTimeout(() => {
                      if (inputFileRef.current)
                        inputFileRef.current.click()
                      else {
                        setTimeout(() => {
                          inputFileRef.current.click()
                        }, 100)
                      }
                    }, 200)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems '>
                    <>
                      <img
                        style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                          width: 16,
                          // height:14,
                          cursor: 'pointer'
                        }}
                        src={AddFlieIcon}
                      />
                      <span>Add Files</span>
                    </>
                  </div>
                </MuiMenuItem>
                {/* <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setRightPaneMenu(false)
                    setMenuPosition(null)
                    handleCloseRightPaneMenuList()
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems customCursor '>
                    <img
                      style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                        width: 16,
                        height: 16,
                        // cursor: 'pointer'
                      }}
                      src={Calendar}
                    />
                    <span>Set Due Date</span>
                  </div>
                </MuiMenuItem> */}
                {/* <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setRightPaneMenu(false)
                    setMenuPosition(null)
                    handleCloseRightPaneMenuList()
                    if(taskItem.remindMeStatus=='1')
                    {
                      setNotifyOnceDate('')
                    }
                    else if(taskItem.remindMeStatus=='2')
                    {
                      setNotifyOnceDate(taskItem.remindMeDate)
                    }
                    else
                    {
                      setNotifyOnceDate('')
                    }
                    setNotifyStatus(taskItem.remindMeStatus)
                    
                    setShowNotify(true)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems'>
                    <img
                      style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                        width: 16,
                        height: 16,
                        // cursor: 'pointer'
                      }}
                      src={AlarmClock}
                    />
                    <span>Remind Me</span>
                  </div>
                </MuiMenuItem> */}
                <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setRightPaneMenu(false)
                    setMenuPosition(null)
                    handleCloseRightPaneMenuList()
                    setAddSubTaskDiv(true)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems '>
                    <img
                      style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                        width: 16,
                        height: 15,
                        cursor: 'pointer'
                      }}
                      src={AddProjectIcon}
                    />
                    <span>Add Sub Task</span>
                  </div>
                </MuiMenuItem>

              </>
            </MuiMenu>
          }
        </>
        :
        <SwipeableDrawer
          anchor="bottom"
          open={rightPaneMenu}
          onClose={() => setRightPaneMenu(false)}
          onOpen={() => setRightPaneMenu(true)}
          className='mainDrawerContainer'
        /* style={{background:colorSchemes.DrawerBackColor}} */
        >
          <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.DrawerBackColor, color: colorSchemes.FontSemiGrey }} >
            <div className='drawerIcon' style={{background:colorSchemes.WebLeftPaneBg}}>
              <div ></div>
            </div>
            <div className='drawerHeading' style={{background:colorSchemes.WebLeftPaneBg}}>
              <div style={{ width: '20%' }}></div>
              <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                Task Options
              </div>
              <div className='drawerHeadingDone' onClick={() => setRightPaneMenu(false)}>Done</div>
            </div>
            <ul style={mobileView ? { padding: 0, background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor, paddingBottom: 40 } : {}} >
            <MuiMenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setRightPaneMenu(false)
                setMenuPosition(null)
                handleCloseRightPaneMenuList()
                setTimeout(() => {
                  if (inputFileRef.current)
                    inputFileRef.current.click()
                  else {
                    setTimeout(() => {
                      inputFileRef.current.click()
                    }, 100)
                  }
                }, 200)
              }}
              style={{
                padding: 0,
                width: '100%'
              }}
            >
              <div className='settingModalItems '>
                <>
                  <img
                    style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                      width: 16,
                      // height:14,
                      cursor: 'pointer'
                    }}
                    src={AddFlieIcon}
                  />
                  <span>Add Files</span>
                </>
              </div>
            </MuiMenuItem>
            {/* <MuiMenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setRightPaneMenu(false)
                setMenuPosition(null)
                handleCloseRightPaneMenuList()
              }}
              style={{
                padding: 0,
                width: '100%'
              }}
            >
              <div className='settingModalItems customCursor '>
                <img
                  style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                    width: 16,
                    height: 16,
                    // cursor: 'pointer'
                  }}
                  src={Calendar}
                />
                <span>Set Due Date</span>
              </div>
            </MuiMenuItem> */}
            {/* <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setRightPaneMenu(false)
                    setMenuPosition(null)
                    handleCloseRightPaneMenuList()
                    if(taskItem.remindMeStatus=='1')
                    {
                      setNotifyOnceDate('')
                    }
                    else if(taskItem.remindMeStatus=='2')
                    {
                      setNotifyOnceDate(taskItem.remindMeDate)
                    }
                    else
                    {
                      setNotifyOnceDate('')
                    }
                    setNotifyStatus(taskItem.remindMeStatus)                 
                    
                    setShowNotify(true)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems'>
                    <img
                      style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                        width: 16,
                        height: 16,
                        // cursor: 'pointer'
                      }}
                      src={AlarmClock}
                    />
                    <span>Remind Me</span>
                  </div>
                </MuiMenuItem> */}
            {/* <MuiMenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setRightPaneMenu(false)
                setMenuPosition(null)
                handleCloseRightPaneMenuList()
              }}
              style={{
                padding: 0,
                width: '100%'
              }}
            >
              <div className='settingModalItems customCursor'>
                <img
                  style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                    width: 16,
                    height: 16,
                    // cursor: 'pointer'
                  }}
                  src={AlarmClock}
                />
                <span>Remind Me</span>
              </div>
            </MuiMenuItem> */}
            <MuiMenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setRightPaneMenu(false)
                setMenuPosition(null)
                handleCloseRightPaneMenuList()
                setAddSubTaskDiv(true)
              }}
              style={{
                padding: 0,
                width: '100%',
                paddingBottom: 40
              }}
            >
              <div className='settingModalItems '>
                <img
                  style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                    width: 16,
                    height: 15,
                    cursor: 'pointer'
                  }}
                  src={AddProjectIcon}
                />
                <span>Add Sub Task</span>
              </div>
            </MuiMenuItem>
           </ul>
            {/* <div style={{height:25}}></div> */}
          </div>
        </SwipeableDrawer>
      }


      {!mobileView ?
        <>
          {subTaskLeftClicked &&
            <MuiMenu
              id="basic-sub-task"
              anchorEl={subTaskAnchorEl}
              open={openSubTaskMenuList}
              // onClose={handleCloseMenuList}
              MenuListProps={{
                'aria-labelledby': 'basic-sub-task-menu',
              }}
              onContextMenu={e => {
                e.stopPropagation()
                e.preventDefault()
                setSubTaskLeftClicked(false)
                setMenuPosition(null)
                handleCloseSubTaskMenuList()
              }}
              onClose={e => {
                setSubTaskLeftClicked(false)
                setMenuPosition(null)
                handleCloseSubTaskMenuList()
              }}
              anchorReference='anchorPosition'
              anchorPosition={menuPosition}
            >
              <div
                className='closeIconDiv'
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSubTaskLeftClicked(false)
                  setMenuPosition(null)
                  handleCloseSubTaskMenuList()
                }}
              >
                <img src={CloseMenuListIcon} alt='' />
              </div>
              <>

                <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSubTaskLeftClicked(false)
                    setMenuPosition(null)
                    handleCloseSubTaskMenuList()
                    changeTaskStepStatus(subTaskItem)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems '>
                    {subTaskItem.status == "0" ?
                      <>
                        <img
                          style={{
                            width: 16,
                            // height:14,
                            cursor: 'pointer'
                          }}
                          src={CheckCircleFill}
                        />
                        <span>Mark as complete</span>
                      </>
                      :
                      <>
                        <img
                          style={{
                            width: 16,
                            // height:14,
                            cursor: 'pointer'
                          }}
                          src={CheckCircleFill}
                        />
                        <span>Mark as not complete</span>
                      </>
                    }
                  </div>
                </MuiMenuItem>
                <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSubTaskLeftClicked(false)
                    setMenuPosition(null)
                    handleCloseSubTaskMenuList()
                    console.log('test678')
                    setEditSubTaskItem(subTaskItem)
                    if (
                      editSubTaskItem?.id != subTaskItem?.id
                    ) {
                      SubTaskNameInputRef?.current?.blur()
                    }
                    setTimeout(() => {
                      preventRef.current = false;
                      SubTaskNameInputRef?.current?.focus()
                      /* try {
                        let sel: any =
                          window.getSelection()
                        let range =
                          document.createRange()
                  
                        range.setStart(
                          SubTaskNameInputRef
                            ?.current
                            ?.childNodes[0],
                            subTaskItem.taskStepTitle.length
                        )
                        range.collapse(true)
                        sel.removeAllRanges()
                        sel.addRange(range)
                      } catch (ex) {} */
                    }, 100)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems '>
                    <img
                      style={{
                        width: 16,
                        height: 16,
                        cursor: 'pointer'
                      }}
                      src={EditHeadingIcon}
                    />
                    <span>Edit Sub Task</span>
                  </div>
                </MuiMenuItem>
                <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSubTaskLeftClicked(false)
                    setMenuPosition(null)
                    handleCloseSubTaskMenuList()
                    promoteSubTask(subTaskItem)
                    setTaskSectionOpen(false)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div className='settingModalItems '>
                    <img
                      style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                        width: 16,
                        height: 16,
                        cursor: 'pointer'
                      }}
                      src={AddProjectIcon}
                    />
                    <span>Promote to task</span>
                  </div>
                </MuiMenuItem>
                <MuiMenuItem
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSubTaskLeftClicked(false)
                    setMenuPosition(null)
                    handleCloseSubTaskMenuList()
                    deleteSubTask(subTaskItem)
                  }}
                  style={{
                    padding: 0
                  }}
                >
                  <div
                    style={{
                      color: 'red',
                      borderTopWidth: 0.5,
                      borderTopColor: 'lightgray',
                      borderTopStyle: 'solid'
                    }}
                    className='settingModalItems '
                  >
                    <img src={RedDelete} alt='' />
                    <span>Delete subtask</span>
                  </div>
                </MuiMenuItem>
              </>
            </MuiMenu>
          }
        </>
        :
        <SwipeableDrawer
          anchor="bottom"
          open={subTaskLeftClicked}
          onClose={() => setSubTaskLeftClicked(false)}
          onOpen={() => setSubTaskLeftClicked(true)}
          className='mainDrawerContainer'
        /* style={{background:colorSchemes.DrawerBackColor}} */
        >
          <div className='rightListDiv' style={{ position: 'unset', width: 'auto', background: colorSchemes.MiddlePaneBackground, color: colorSchemes.FontColor }} >
            <div className='drawerIcon' style={{background:colorSchemes.WebLeftPaneBg}}>
              <div ></div>
            </div>
            <div className='drawerHeading' style={{background:colorSchemes.WebLeftPaneBg}}>
              <div style={{ width: '20%' }}></div>
              <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
                Step Options
              </div>
              <div className='drawerHeadingDone' onClick={() => setSubTaskLeftClicked(false)}>Done</div>
            </div>
            <MuiMenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setSubTaskLeftClicked(false)
                setMenuPosition(null)
                handleCloseSubTaskMenuList()
                changeTaskStepStatus(subTaskItem)
              }}
              style={{
                padding: 0,
                width: '100%'
              }}
            >
              <div className='settingModalItems '>
                {subTaskItem.status == "0" ?
                  <>
                    <img
                      style={{
                        width: 16,
                        // height:14,
                        cursor: 'pointer'
                      }}
                      src={CheckCircleFill}
                    />
                    <span>Mark as complete</span>
                  </>
                  :
                  <>
                    <img
                      style={{
                        width: 16,
                        // height:14,
                        cursor: 'pointer'
                      }}
                      src={!mobileView ? CheckCircleFill : MobileCircle}
                    />
                    <span>Mark as not complete</span>
                  </>
                }
              </div>
            </MuiMenuItem>
            {/* <MuiMenuItem
                                onClick={e => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  setSubTaskLeftClicked(false)
                                  setMenuPosition(null)
                                  handleCloseSubTaskMenuList()
                                  console.log('test678')
                                  setEditSubTaskItem(subTaskItem)
                                  if (
                                    editSubTaskItem?.id != subTaskItem?.id
                                  ) {
                                    SubTaskNameInputRef?.current?.blur()
                                  }
                                  setTimeout(() => {
                                    preventRef.current = false;
                                    SubTaskNameInputRef?.current?.focus()
                                  }, 100)
                                }}
                                style={{
                                  padding: 0
                                }}
                              >
                                <div className='settingModalItems '>
                                  <img
                                    style={{
                                      width: 16,
                                      height:16,
                                      cursor: 'pointer'
                                    }}
                                    src={EditHeadingIcon}
                                  />
                                  <span>Edit Sub Task</span>
                                </div>
                              </MuiMenuItem> */}
            <MuiMenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setSubTaskLeftClicked(false)
                setMenuPosition(null)
                handleCloseSubTaskMenuList()
                promoteSubTask(subTaskItem)
                setTaskSectionOpen(false)
              }}
              style={{
                padding: 0,
                width: '100%'
              }}
            >
              <div className='settingModalItems '>
                <img
                  style={{filter: !mobileView?'brightness(100%)':mobileView && isLightMode?'brightness(100%)':'brightness(1000%)',
                    width: 16,
                    height: 16,
                    cursor: 'pointer'
                  }}
                  src={AddProjectIcon}
                />
                <span>Promote to task</span>
              </div>
            </MuiMenuItem>
            <MuiMenuItem
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setSubTaskLeftClicked(false)
                setMenuPosition(null)
                handleCloseSubTaskMenuList()
                deleteSubTask(subTaskItem)
              }}
              style={{
                padding: 0,
                width: '100%',
                paddingBottom: 40
              }}
            >
              <div
                style={{
                  color: 'red',
                  // borderTopWidth: 0.5,
                  // borderTopColor: 'lightgray',
                  // borderTopStyle: 'solid'
                }}
                className='settingModalItems '
              >
                <img src={RedDelete} alt='' />
                <span>Delete subtask</span>
              </div>
            </MuiMenuItem>
          </div>
        </SwipeableDrawer>
      }





    </div>
  )
}

export default SideTaskBar
