import { useState, useEffect } from "react";
const useContextMenu = () => {
  const [clicked, setClicked] = useState(false);
  const [projectClicked, setProjectClicked] = useState(false);
  const [listClicked, setListClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });
  useEffect(() => {
    const handleClick = () => setClicked(false);
    const handleProjectClick = () => setProjectClicked(false);
    const handleListClick = () => setListClicked(false);
    document.addEventListener("click", handleClick);
    document.addEventListener("click", handleProjectClick);
    document.addEventListener("click", handleListClick);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("click", handleProjectClick);
      document.removeEventListener("click", handleListClick);
    };
  }, []);
  return {
    clicked,
    setClicked,
    points,
    setPoints,
    projectClicked, setProjectClicked,
    listClicked, setListClicked
  };
};
export default useContextMenu;