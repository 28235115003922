import * as React from "react";
import * as ReactDOM from "react-dom";
import {PanViewer} from "react-image-pan-zoom-rotate";
import BackIcon from '../../assets/completedIcon.png'
import BackArrowImageViewer from '../../assets/backArrowImageViewer.png'
import ImageLogo from '../../assets/imageLogo.png'
import VideoLogo from '../../assets/videoLogo.png'
import CrossIconImage from '../../assets/whiteCrossIconImage.png'
import cloudComputing from '../../assets/cloud-computing.png'
import DownloadsIcon from '../../assets/downloads.png'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import CryptoJS from 'crypto-js'
import { FEKY } from "../Services/Calls";
import { BASE_URL } from "../Services/Calls";

type ReactPanZoomProps = {
  // image: string;
  // alt?: string;
  // ref?: any;
  itemURL?: any;
  fileTypeName?: any;
  handleCloseImageViewer?: any;
  fileName?: any;
  fileItem?: any;
  mobileView?: any;
};

function ImageViewer({itemURL, fileTypeName, fileName, handleCloseImageViewer, fileItem,mobileView }: ReactPanZoomProps){
  const [dx, setDx] = React.useState(0);
  const [dy, setDy] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [flip, setFlip] = React.useState(false);

  const resetAll = () => {
    setDx(0);
    setDy(0);
    setZoom(0);
    setRotation(0);
    setFlip(false);
  };
  const zoomIn = () => {
    setZoom(zoom + 0.1);
  };

  const zoomOut = () => {
    if (zoom >= .2) {
      setZoom(zoom - 0.1);
    }
  };

  const rotateLeft = () => {
    if (rotation === -3) {
      setRotation(0);
    } else {
      setRotation(rotation - 1);
    }
  };

  const flipImage = () => {
    setFlip(!flip);
  };

  const onPan = (dx: number, dy: number) => {
    setDx(dx);
    setDy(dy);
  };

  return (
    <>
    {fileTypeName == 'image' ?
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',width:'100%'}}>
      <div
        style={{
          position: 'absolute',
          // right: '10px',
          zIndex: 2,
          // top: 10,
          userSelect: 'none',
          // borderRadius: 2,
          // background: '#fff',
          // boxShadow: '0px 2px 6px rgba(53, 67, 93, 0.32)',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          bottom: 0,
          gap: 20,
        }}
      >
          <div
          onClick={zoomOut}
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            height: 40,
            width: 40,
            borderBottom: ' 1px solid #ccc',
            borderRadius: 4,
            background: '#fff',
            boxShadow: '0px 2px 6px rgba(53, 67, 93, 0.32)',
          }}
        >
          <svg
            style={{
              height: '100%',
              width: '100%',
              padding: 10,
              boxSizing: 'border-box',
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 12H20"
              stroke="#4C68C1"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div
          onClick={zoomIn}
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            height: 40,
            width: 40,
            borderBottom: ' 1px solid #ccc',
            borderRadius: 4,
            background: '#fff',
            boxShadow: '0px 2px 6px rgba(53, 67, 93, 0.32)',
          }}
        >
          <svg
            style={{
              height: '100%',
              width: '100%',
              padding: 10,
              boxSizing: 'border-box',
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 12H20"
              stroke="#4C68C1"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M12 4L12 20"
              stroke="#4C68C1"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      
      </div>
      <div
        style={{
          position: 'absolute',
          // left: '10px',
          zIndex: 2,
          top: 10,
          userSelect: 'none',
          // borderRadius: 2,
          // background: '#fff',
          // boxShadow: '0px 2px 6px rgba(53, 67, 93, 0.32)',
          display:'flex',
          justifyContent:'space-between',
          width:'100%',
          alignItems:'center'
        }}
      >
        <div
          // onClick={handleCloseImageViewer}
          style={{
            textAlign: 'center',
            // height: 40,
            // width: 40,
            // borderBottom: ' 1px solid #ccc',
            // padding:'5px 15px 5px 2px',
            fontSize:16,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            gap:20
          }}
        >
          <img style={{width:22,cursor: 'pointer',}} src={BackArrowImageViewer} alt="" onClick={handleCloseImageViewer} />
          <img  src={ImageLogo} alt="" />
          <span style={{color:'white'}}>{fileName}</span>
          {/* Back */}
        </div>
       <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:22}}>
      <img style={{width:22,cursor:'pointer'}} src={DownloadsIcon} alt="" 
      onClick={() =>
        {
        let signature = CryptoJS.AES.encrypt(JSON.stringify(fileItem), FEKY).toString();
        console.log("CryptoJS", encodeURIComponent(signature))
        console.log(BASE_URL+"/filedownload?file="+encodeURIComponent(signature));
        console.log('item12',fileItem);
          window.open(BASE_URL+"/filedownload?file="+encodeURIComponent(signature),"_self");
        }
      }
      />
      <img style={{width:18,height:18,cursor:'pointer'}} onClick={handleCloseImageViewer} src={CrossIconImage} alt="" />
       </div>
      </div>
      
      <PanViewer
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
        zoom={zoom}
        setZoom={setZoom}
        pandx={dx}
        pandy={dy}
        onPan={onPan}
        rotation={rotation}
        key={dx}
        // onClick={handleCloseImageViewer}
      >
        {/* <ClickAwayListener onClickAway={handleCloseImageViewer}> */}
        <img
        // onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
          style={{
            transform: `rotate(${rotation * 90}deg) scaleX(${flip ? -1 : 1})`,
            maxWidth: mobileView?'100%':'75%',
            maxHeight: '75%',
            objectFit:'contain',
            height:mobileView?window.innerHeight-150: 700,
          }}
          src={itemURL}
          // alt={alt}
          // ref={ref}
        />
      {/* </ClickAwayListener> */}
      </PanViewer>
    </div>
:
<>
<div
        style={{
          position: 'absolute',
          left: '10px',
          zIndex: 2,
          top: 10,
          userSelect: 'none',
          // borderRadius: 2,
          // background: '#fff',
          // boxShadow: '0px 2px 6px rgba(53, 67, 93, 0.32)',
        }}
      >
        <div
          // onClick={handleCloseImageViewer}
          style={{
            textAlign: 'center',
            // height: 40,
            // width: 40,
            // borderBottom: ' 1px solid #ccc',
            // padding:'5px 15px 5px 2px',
            fontSize:16,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            gap:20
          }}
        >
          <img style={{width:22,cursor: 'pointer',}} src={BackArrowImageViewer} alt="" onClick={handleCloseImageViewer} />
          <img style={{width:16}} src={VideoLogo} alt="" />
          <span style={{color:'white'}}>{fileName}</span>
          {/* Back */}
        </div>
       
      </div>
     <video 
        webkit-playsinline="true" 
              loop
              disablePictureInPicture={true}
              controls
              style={!mobileView? {width: window.innerWidth-100, height:window.innerHeight-100}:{width: '75%'}}
              //controlslist={"nodownload noremoteplayback noplaybackrate foobar"}
            >
              <source
                // src={BASE_URL_IMG + "resources/images/backVideohome.mp4"}
                // src={"../../../src/assets/Website version.mp4"}
                src={itemURL}
                type="video/mp4"
              />
            </video> 
            </>
}
            </>
  );
};

export default ImageViewer