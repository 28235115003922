import React, { useState } from 'react'
import styles from './AuthStyles'
import MainLogo from '../../assets/mainLogo1.png'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, mixPanelTrack, post } from '../Services/Calls';
import { Base64 } from 'js-base64';
import AuthLoader from '../../assets/authLoader.png'
import NickNameIcon from '../../assets/nickName.png'
import NickNameActiveIcon from '../../assets/nickNameActive.png'
import NewMSLogo from '../../assets/newMSLogo.png'
import CharacterWorkingLaptop from '../../assets/CharacterWorkingLaptop.png'
import CharacterCactus from '../../assets/characterCactus.png'
import Cactus from '../../assets/cactus.png'
import Padlock from '../../assets/padlock.png'
import PadlockActive from '../../assets/padlockActive.png'
import RightBlackArrow from '../../assets/rightBlackArrow.png'
import Cookies from '../Cookies/Cookies';
import { useEffect } from 'react';
import { COLOR, COLORDARK } from '../Services/constants/Colors';


interface IProps {
  openAddAccountLogin: any
  setOpenAddAccountLogin: any
  openAddAccountSignup: any
  setOpenAddAccountSignUp: any
  mobileView: any
}

function Login({ 
  openAddAccountLogin,
  setOpenAddAccountLogin,
  openAddAccountSignup,
  setOpenAddAccountSignUp,
  mobileView,
  }: IProps) {
  const navigate = useNavigate();
  const [SignUpShow, setSignUpShow] = useState(false);
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
  const [emailMsg, setEmailMsg] = useState<string>("");
  const [passwordMsg, setPasswordMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [showAlreadyLine, setShowAlreadyLine] = useState(false);
  const [emailInputFocus, setEmailInputFocus] = useState(false);
  const [passwordInputFocus, setPasswordInputFocus] = useState(false);
  const [userData, setuserData]: any = useState({})
  const [isDark, setIsDark]: any = useState(mobileView?1:0);
  const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;

  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form_data: any = e.currentTarget.elements;

    try {

      if (form_data[0].value == "") {
        setEmailMsg("Can't be empty");
      }
      if (form_data[0].value != "") {
        let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!pattern.test(form_data[0].value)) {
          setEmailMsg("Invalid input");
          return;
        }
      }
      if (form_data[1].value == "") {
        setPasswordMsg("Password can't be empty");
      }
      if (form_data[1].value != "") {
        if (form_data[1].value.length < 6) {
          setPasswordMsg("Password requires 6~64 characters long.");
          return;
        }
      }
      if (
        form_data[0].value == "" ||
        form_data[1].value == ""
      ) {
        return;
      }
      let tempEmail = form_data[0].value
      tempEmail = tempEmail.toLowerCase();
      const data = {
        email: Base64.encode(tempEmail),
        password: Base64.encode(form_data[1].value),
      };
      console.log("data123456", data);
      setLoader(true)
      const response = await post(BASE_URL + '/login', data)
      console.log('response23', response)
      if (response.data.status == 'success') {
        setLoader(false)
        if(response.data.data[0].id == "1" || response.data.data[0].id == "2" || response.data.data[0].id == "3" || response.data.data[0].id == "50" ){
        }
        else{
          mixPanelTrack("loginSuccess",{"email":tempEmail})
        }
        let tempGetSwitchData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
        if (response.data.data && response.data.data.length > 0) {
          localStorage.setItem("user", JSON.stringify(response.data.data[0]));

          let tempSwitchData: any = []
          //let tempGetSwitchData = JSON.parse(localStorage.getItem('switchUserData') || '{}')
          if (!localStorage.getItem("switchUserData")) {
            let json = {
              userData: response.data.data[0],
              token: response.data.accessToken
            }
            tempSwitchData.push(json)
            localStorage.setItem("switchUserData", JSON.stringify(tempSwitchData))
          }
          else {
            let switchUserData = [...tempGetSwitchData]
            let status = "0"
            for (let i = 0; i < switchUserData.length; i++) {
              if (switchUserData[i].userData.id == response.data.data[0].id) {
                status = "1"
                break;
              }
            }
            if (status != "1") {
              let json = {
                userData: response.data.data[0],
                token: response.data.accessToken
              }
              switchUserData.push(json)
              localStorage.setItem("switchUserData", JSON.stringify(switchUserData))
            }
          }

        }
        if (response.data.accessToken) {
          localStorage.setItem("token", response.data.accessToken);
        }
        //navigate('/webapp')
        localStorage.setItem("setCookie", "0");
        try{
          localStorage.removeItem("project")
        }
        catch(ex){
          
        }
        if (tempGetSwitchData && tempGetSwitchData.length > 0) {
          // navigate('/webapp')
          // setOpenAddAccountLogin(false)
          window.location.reload();
        }
        else {
          window.location.reload();
        }
      }
      else {
        mixPanelTrack("loginFailed",{"email":tempEmail,"errorMessage":"your email/password is incorrect."})
        setLoader(false)
        setAlreadyExist(true)
        setShowAlreadyLine(true)
        // setErrorMsg('your email/password is incorrect. Please try again.')
      }

    } catch (ex) {
      setLoader(false)
      console.log('response', ex)
    }
  };


  useEffect(() => {
    mixPanelTrack("loginPageView",{"loginPageView":"loginPageView"})
    let queryItem2 = new URLSearchParams(window.location.search).get("c");
    if (queryItem2 && queryItem2 != "") {
      localStorage.setItem("sharecode", queryItem2);
    }
    let user = JSON.parse(localStorage.getItem('user') || '{}')
    setuserData(user)
  }, [])
const divHeight=window.innerHeight-100
  return (
    <>
      {/* {userData && userData.id && localStorage.getItem("token") &&
        <div className='loginTopSwicthView'>
          <div onClick={() => navigate('/webapp')}>
            <div>
              Back to {Base64.decode(userData.email)} account.
            </div>
            <div>
              <img style={{ width: 25 }} src={RightBlackArrow} alt="" />
            </div>
          </div>
        </div>
      } */}
      <div style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor,  height:"100vh", display:"flex", justifyContent:"center"}:{}}>
      <div className={userData && userData.id && localStorage.getItem("token") ? 'container-main-switch' :'container-main'}
      style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:mobileView?"rgba(0,0,0,.85)":colorSchemes.FontBlackColor, margin:mobileView?"0px": "50px auto",     position: "absolute",
      height:localStorage.getItem("token")==null?"100%":"unset",
      msOverflowY: "auto"}:{}}>
        {(localStorage.getItem("token")==null) && mobileView ?
           <div style={{ textAlign: 'center', marginBottom:"5%", padding : "10% 0%", background:"rgba(48, 104, 200, 1)", width:"100%", 
           borderBottomLeftRadius:"20px", borderBottomRightRadius:"20px"}}>
            <img style={{ width: "180px" }} src={NewMSLogo} alt="" />
            </div>:null
        }
      {userData && userData.id && localStorage.getItem("token")? null :
      <>
        {localStorage.getItem("setCookie") == null && <Cookies />}
        </>
    }
        <div>
        {/* {userData && userData.id && localStorage.getItem("token") ?
        <>
        {!mobileView &&
          <h3 style={{ textAlign: 'center', fontWeight: '600'}}>Switch Accounts</h3>
        }
          </>
        :
          <></>
    } */}
    
          <div  className={userData && userData.id && localStorage.getItem("token")?'auth-container-box-switch':'auth-container-box'}
          style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:mobileView?"rgba(0,0,0,.85)":colorSchemes.FontBlackColor, border:mobileView?"none":"1px solid "+colorSchemes.FontGreyColor, height:mobileView?"unset": 600 /* height:window.innerHeight-100 */}:{ height:mobileView?"unset":600/* height:window.innerHeight-100 */}}>
            <div style={{width:(userData && userData.id && localStorage.getItem("token") && !mobileView) || mobileView?"100%": "45%", padding: mobileView?"0px":"40px 50px 32px"}}>
            <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 16, fontSize:mobileView?25: 40,fontFamily:'ManRopeSemiBold' }}>Welcome back</h2>
            <div style={{ textAlign: 'center', marginBottom: 36,  fontSize:mobileView?15:20,fontFamily:'ManRope',color:'rgba(23, 45, 70, 1)'  }}>Welcome now! Please enter your details.</div>
            <form onSubmit={handleLoginSubmit}>

              <div className={emailInputFocus ? 'inputContainerDivActive' : 'inputContainerDiv'}
              style={isDark==1 && emailInputFocus? {borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid #4772fa"}: isDark==1?{borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid "+colorSchemes.FontGreyColor}:{}}>
                <div style={{position: "absolute", marginTop: "-50px", background: "#fff", padding: "0px 5px" ,fontSize: "14px", color: emailInputFocus? '#4772fa' : "#828B94",marginLeft:10}}>Email</div>
                {/* {emailInputFocus ?
                  <img style={{ width: 20 }} src={NickNameActiveIcon} alt="" />
                  :
                  <img style={{ width: 20 }} src={NickNameIcon} alt="" />
                } */}
                <div style={{ width: '100%' }}>
                  <input className='inputFeild' type="text" name="email" placeholder='Enter your email' onChange={() => { setAlreadyExist(false); setShowAlreadyLine(false) }} onFocus={() => { setEmailMsg(""); setErrorMsg(""); setEmailInputFocus(true); setShowAlreadyLine(false) }} onBlur={(e) => {
                    setEmailInputFocus(false);
                    if (e.target.value != "") {
                      let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                      if (!pattern.test(e.target.value)) {
                        setEmailMsg("Invalid input");
                      }
                    }
                  }}
                  style={isDark==1? {backgroundColor:mobileView?"transparent":colorSchemes.DrawerBackColor, color:mobileView?"unset":colorSchemes.FontBlackColor}:{}}/>
                </div>
              </div>
              <div className='errorMsgLine' style={{ minHeight: 30 }}>
                {showAlreadyLine && <>
                  {alreadyExist &&
                    <span style={styles.inputErrorMsg}>Incorrect username or password</span>
                  }
                </>}
                <span style={styles.inputErrorMsg}>{emailMsg}</span>
              </div>

              <div className={passwordInputFocus ? 'inputContainerDivActive' : 'inputContainerDiv'}
              style={isDark==1 && passwordInputFocus? {borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid #4772fa"}: isDark==1?{borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid "+colorSchemes.FontGreyColor}:{}}>
                 <div style={{position: "absolute", marginTop: "-50px", background: "#fff", padding: "0px 5px" ,fontSize: "14px", color: passwordInputFocus? '#4772fa' : "#828B94",marginLeft:10}}>Password</div>
                {/* {passwordInputFocus ?
                  <img style={{ width: 20 }} src={PadlockActive} alt="" />
                  :
                  <img style={{ width: 20 }} src={Padlock} alt="" />
                } */}
                <div style={{ width: '100%' }}>
                  <input className='inputFeild' type="password" name="password" placeholder="Password" maxLength={64} onFocus={() => { setPasswordMsg(""); setErrorMsg(""); setPasswordInputFocus(true); setShowAlreadyLine(true) }} onBlur={(e) => {
                    setPasswordInputFocus(false);
                    if (e.target.value != "") {
                      if (e.target.value.length < 6) {
                        setPasswordMsg("Password requires 6~64 characters long.");
                      }
                    }
                  }}
                  style={isDark==1? {backgroundColor:mobileView?"transparent": colorSchemes.DrawerBackColor, color:mobileView?"unset":colorSchemes.FontBlackColor}:{}}/>
                </div>
              </div>
              <div className='errorMsgLine' /* style={{ minHeight: 30 }} */><span style={styles.inputErrorMsg}>{passwordMsg}</span></div>
              {!localStorage.getItem("token") &&
              <div className='makeLink' style={{ fontSize: 13,marginTop:10,color:'rgba(48, 104, 200, 1)'}} onClick={() => navigate("/login/requestRestPassword")} >Forgot Password</div>
            }

              {loader ?
                <button className='authBtnLoader' style={{marginTop:30}}>
                  Sign In...
                </button>
                :
                <button className='authBtn' type="submit" style={{marginTop:30}}>
                  Sign In
                </button>
              }
            </form>
            
<div style={{ textAlign: 'center', marginTop: 15,display:'flex',justifyContent:'center',alignItems:'center',fontSize:14,fontWeight:400,fontFamily:'ManRope'}}>
  <span>Don’t have an account?</span>
  &nbsp;
            <div className='makeLink' style={{ textAlign: 'center',color:'rgba(48, 104, 200, 1)' }} onClick={() => { if (userData && userData.id && localStorage.getItem("token")) {setOpenAddAccountSignUp(true) } else { navigate("/signup") } }}>
              Sign Up
            </div>
            </div>
            </div>

<div style={{width: '15%',height: '100%',display: (userData && userData.id && localStorage.getItem("token") && !mobileView) || mobileView?'none': 'flex', justifyContent: 'flex-start',alignItems: 'flex-end'}}>
<img style={{/* width:"65%" */ scale:'0.9',marginBottom: '-25px', marginLeft: '-60px' }} src={CharacterCactus} alt="" />
</div>

            <div style={{display: (userData && userData.id && localStorage.getItem("token") && !mobileView) ||mobileView?'none': 'block', backgroundColor:"green", width:"40%", height:"100%", background: "rgba(48, 104, 200, 1)", borderRadius:"40px"}}>
            <div style={{ textAlign: 'center', marginTop:"8%", marginBottom:"5%"}}><img style={{ /* width: 20 */ }} src={NewMSLogo} alt="" /></div>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color:"#ffffff", fontSize: 32 }}>Your Stuff, Your Way,</div>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color:"#ffffff", fontSize: 32 }}>Your Success</div>
            
            {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"flex-end"}} className="blue-box">
            <img style={{width:"65%" }} src={CharacterWorkingLaptop} alt="" />
            <img style={{ height:"70%", marginLeft:"5%"}} src={Cactus} alt="" />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Login
