import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import './HomePage.css'
import styles from '../Navbar/NavStyles'
import LeftArrow from '../../assets/left-arrow.png'
import MenuIcon from '../../assets/menu.png'
import Sort from '../../assets/sort.png'
import Suggestion from '../../assets/question.png'
import SideTaskBar from './SideTaskBar'
import sunnyDay from '../../assets/sunny-day.png'
import Important from '../../assets/star.png'
import Planning from '../../assets/planning.png'
import Assingned from '../../assets/assingned.png'
import Tasks from '../../assets/tasks.png'
import AddTaskIcon from '../../assets/addTaskIcon.png'
import AddProjectIcon from '../../assets/addProjectIcon.png'
import DontAllowCheck from '../../assets/dontAllowCheck.png'
import LogOut from '../../assets/logOut.png'
import Setting from '../../assets/setting.png'
import AddFlieIcon from '../../assets/paper-clip.png'
import DuplicateIcon from '../../assets/duplicate.png'
import ManageAccountIcon from '../../assets/manage-account.png'
import RightWhiteArrow from '../../assets/rightWhiteArrow.png'
import SyncIcon from '../../assets/sync.png'
import Rename from '../../assets/rename.png'
import RedDelete from '../../assets/redDelete.png'
import UnGroupListIcon from '../../assets/ungroupListIcon.png'
import Calendar from '../../assets/calendar.png'
import Bell from '../../assets/bell.png'
import Repeat from '../../assets/repeat.png'
import UnSelect from '../../assets/dry-clean.png'
import Select from '../../assets/check-mark.png'
import AddGroupIcon from '../../assets/addGroupIcon.png'
import Remove from '../../assets/exit.png'
import GroupIcon from '../../assets/groupIcon.png'
import StickyNote from '../../assets/sticky-notes.png'
import Dot from '../../assets/dot.png'
import ThreeDots from '../../assets/three-dots.png'
import More from '../../assets/more.png'

import SharePeople from '../../assets/sharePeople.png'
import Minimize from '../../assets/minimize.png'
import WhiteDots from '../../assets/WhiteDots.png'
import ListIcon from '../../assets/listIcon.png'
import projectPlan from '../../assets/project-plan.png'
import completedArrowIcon from '../../assets/completedIcon.png'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CloseIcon from '../../assets/close.png'
import axios from 'axios'
import { BASE_URL,  decData,  post, postUser } from '../Services/Calls'
import { redirect } from 'react-router-dom'
import { json } from 'stream/consumers'
import Magnifier from '../../assets/magnifier.png'
import ColorPaletteIcon from '../../assets/color-palette.png' /* 03/05 */
import MarkerIcon from '../../assets/marker.png' /* 03/05 */
import useContextMenu from './UseContextMenu'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { transform, transpile } from 'typescript'

function Extra() {
  const {
    clicked,
    setClicked,
    points,
    setPoints,
    projectClicked,
    setProjectClicked,
    listClicked,
    setListClicked
  } = useContextMenu()

  const [taskMenuListpoints, setTaskMenuListpoints] = useState({
    x: 0,
    y: 0
  })

  const [taskLeftClicked, setTaskLeftClicked] = useState(false)
  const [taskCompletedLeftClicked, setTaskCompletedLeftClicked] =
    useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [titleHeading, setTitleHeading] = useState('')
  const [myDaySection, setMyDaySection] = useState(true)
  const [threeDotMain, setThreeDotMain] = useState(false)
  const [taskSectionOpen, setTaskSectionOpen] = useState(false)
  const [taskIndex, setTaskIndex] = useState()
  const [taskItem, setTaskItem]: any = useState()
  const [ImportantSection, setImportantSection] = useState(false)
  const [plannedSection, setPlannedSection] = useState(false)
  const [assignedSection, setAssignedSection] = useState(false)
  const [tasksSection, setTasksSection] = useState(false)

  const [taskDrop, setTaskDrop] = useState(false)
  const [selectStatus, setSelectStatus] = useState(false)
  const [completeTaskShow, setCompleteTaskShow] = useState(false)
  const [settingDivShow, setSettingDivShow] = useState(false)
  const [addProject, setAddProject] = useState(false)
  const [addList, setAddList] = useState(false)
  const [addGroupList, setAddGroupList] = useState(false) /* 09/05 */
  const [moveTaskDivShow, setMoveTaskDivShow] = useState(false) /* 09/05 */
  const [taskInputValue, setTaskInputValue] = useState('')
  const [projectsInputValue, setProjectsInputValue] = useState('')
  const [projectsNewListInputValue, setProjectsNewListInputValue] = useState('')
  const [groupInputValue, setGroupInputValue] = useState('')
  const [countNum, setCountNum] = useState(0)
  const [userId, setUserId] = useState('')
  const [selectedProjectID, setSelectedProjectID] = useState('')
  const [selectedProjectGruopID, setSelectedProjectGruopID] = useState('')
  const [selectedProjectName, setSelectedProjectName] = useState('')
  const [userData, setuserData]: any = useState({})
  const [firstName, setFirstName]: any = useState()
  const [lastName, setLastName]: any = useState()
  const [email, setEmail]: any = useState()

  const [nameLetters, setNameLetters]: any = useState()
  const [dataItem, setDataItem]: any = useState({})
  const [dataItemIndex, setDataItemIndex]: any = useState()
  const [projectData, setProjectData]: any = useState([])
  const [leftMenuOrder, setLeftMenuOrder]: any = useState([])
  const [groupData, setGroupData]: any = useState([])
  const [taskData, setTaskData]: any = useState([])
  const [taskMainData, setTaskMainData]: any = useState([])
  const [editGroup, setEditGroup]: any = useState({})
  const [editList, setEditList]: any = useState({})
  const [completedMenu, setCompletedMenu]: any = useState(false)
  const [colorPalette, setColorPalette]: any = useState(false) /* 03/05 */
  const [currentDate, setCurrentDate]: any = useState('') /* 03/05 */
  const [highlightFrom, setHighlightFrom]: any = useState('')
  const [windowWidth, setWindowWidth]: any = useState('')
  const [windowHeight, setWindowHeight]: any = useState('')
  const [contentEdit, setContentEdit]: any = useState(true)
  const [projectListInput, setProjectListInput]: any = useState('')
  const [filteredResults, setFilteredResults] = useState([]);


  /* 03/05 */
  const [colors, setColors] = useState([
    { id: 1, colorCode: '#FF6900' },
    { id: 2, colorCode: '#FCB900' },
    { id: 3, colorCode: '#7BDCB5' },
    { id: 4, colorCode: '#00D084' },
    { id: 5, colorCode: '#8ED1FC' },
    { id: 6, colorCode: '#0693E3' },
    { id: 7, colorCode: '#ABB8C3' },
    { id: 8, colorCode: '#EB144C' },
    { id: 9, colorCode: '#F78DA7' },
    { id: 10, colorCode: '#9900EF' }
  ])
  /* 03/05 */
  const checked = useRef(false)
  const sideBarTaskNameInput: any = useRef()
  const TitleHeadTaskNameInput: any = useRef()
  const typeName: any = useRef('list')

  const [open, setOpen] = React.useState(false)
  const [menuListOpen, setMenuListOpen] = React.useState(false)
  const [taskMenuListOpen, setTaskMenuListOpen] = React.useState(false)
  const [taskCompletedMenuListOpen, setTaskCompletedMenuListOpen] =
    React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const anchorMenuListRef = React.useRef<HTMLButtonElement>(null)
  const anchorTaskMenuListRef: any = React.useRef<HTMLButtonElement>(null)
  const anchorTaskCompletedMenuListRef: any =
    React.useRef<HTMLButtonElement>(null)
  const anchorCompletedMenuListRef = React.useRef<HTMLImageElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleTaskMenuListToggle = () => {
    setTaskMenuListOpen(prevTaskMenuListOpen => !prevTaskMenuListOpen)
  }
  const handleTaskCompletedMenuListToggle = () => {
    setTaskCompletedMenuListOpen(
      prevTaskCompletedMenuListOpen => !prevTaskCompletedMenuListOpen
    )
  }

  const handleMenuListToggle = () => {
    setMenuListOpen(prevMenuListOpen => !prevMenuListOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }
  const handleMenuListClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorMenuListRef.current &&
      anchorMenuListRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setMenuListOpen(false)
  }
  const handleTaskMenuListClose: any = (
    event: Event | React.SyntheticEvent
  ) => {
    if (
      anchorTaskMenuListRef.current &&
      anchorTaskMenuListRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setTaskMenuListOpen(false)
  }
  const handleTaskCompletedMenuListClose: any = (
    event: Event | React.SyntheticEvent
  ) => {
    if (
      anchorTaskCompletedMenuListRef.current &&
      anchorTaskCompletedMenuListRef.current.contains(
        event.target as HTMLElement
      )
    ) {
      return
    }
    setTaskCompletedMenuListOpen(false)
  }

  // function handleListKeyDown (event: React.KeyboardEvent) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault()
  //     setOpen(false)
  //     setMenuListOpen(false)
  //     setTaskMenuListOpen(false)
  //     setTaskCompletedMenuListOpen(false)
  //   } else if (event.key === 'Escape') {
  //     setOpen(false)
  //     setMenuListOpen(false)
  //     setTaskMenuListOpen(false)
  //     setTaskCompletedMenuListOpen(false)
  //   }
  // }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }
    prevOpen.current = open
  }, [open])
  const prevMenuListOpen = React.useRef(menuListOpen)
  React.useEffect(() => {
    if (prevMenuListOpen.current === true && menuListOpen === false) {
      anchorMenuListRef.current!.focus()
    }
    prevMenuListOpen.current = menuListOpen
  }, [menuListOpen])
  const prevTaskMenuListOpen = React.useRef(taskMenuListOpen)
  React.useEffect(() => {
    if (prevTaskMenuListOpen.current === true && taskMenuListOpen === false) {
      anchorTaskMenuListRef.current?.focus()
    }
    prevTaskMenuListOpen.current = taskMenuListOpen
  }, [taskMenuListOpen])
  const prevTaskCompletedMenuListOpen = React.useRef(taskCompletedMenuListOpen)
  React.useEffect(() => {
    if (
      prevTaskCompletedMenuListOpen.current === true &&
      taskCompletedMenuListOpen === false
    ) {
      anchorTaskCompletedMenuListRef.current?.focus()
    }
    prevTaskCompletedMenuListOpen.current = taskCompletedMenuListOpen
  }, [taskCompletedMenuListOpen])

  //   const [items, setItems]: any = useState(taskData)
  const rearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr]

    const [removed] = arrCopy.splice(sourceIndex, 1)

    arrCopy.splice(destIndex, 0, removed)

    return arrCopy
  }

  // const onDragEnd = result => {
  //   console.log('result456', result)
  //   const { source, destination } = result

  //   if (!destination) {
  //     return
  //   }

  //   let data = taskData
  //   for (let i = 0; i < data.length; i++) {
  //     if (result.draggableId == data[i].id) {
  //       if (destination.droppableId == 1) {
  //         data[i].status = 0
  //         data[i].category = 1
  //       } else {
  //         data[i].status = 1
  //         data[i].category = 2
  //       }
  //     }
  //   }
  //   console.log('data123', data)
  //   setTaskData(data)

  //   if (destination.droppableId !== source.droppableId) {
  //     setTaskData(items =>
  //       items.map(
  //         item =>
  //           item.id === parseInt(result.draggableId)
  //             ? {
  //                 ...item,
  //                 category: parseInt(result.destination.droppableId)
  //               }
  //             : item,
  //         console.log('task9211')
  //       )
  //     )
  //   } else {

  //     setTaskData(rearangeArr(taskData, source.index, destination.index))
  //   }
  //   console.log('taskData123', taskData)
  // }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    // console.log('result000',list)
    const removed = result.splice(startIndex, 1)
    console.log('removed000', removed)
    result.splice(endIndex, 0, removed.pop())
    console.log('result000', result)

    return result
  }
  const reorderProjectList = (list, startIndex, endIndex) => {
    const result = list
    console.log('result998899', list)
    const removed = result.splice(startIndex, 1)
    console.log('removed000', removed)
    result.splice(endIndex, 0, removed.pop())
    console.log('result000', result)

    return result
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    console.log(
      'source, destination, droppableSource, droppableDestination',
      source,
      destination,
      droppableSource,
      droppableDestination
    )

    var destinationProjectGroup = droppableDestination.droppableId.split('-')
    var destinationProjectGroupId = destinationProjectGroup[1]
    console.log('destinationProjectGroupId', destinationProjectGroupId)
    const sourceClone = source
    const destClone = destination
    const removed = sourceClone.splice(droppableSource.index, 1)
    removed[0].projectGroupId = destinationProjectGroupId
    destClone.splice(droppableDestination.index, 0, removed[0])

    const result: any = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  function onDragMainEnd(result) {
    console.log('result456456', result)
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId

    if (sInd === dInd) {
      const items = reorder(taskData[sInd], source.index, destination.index)
      const newState: any = [...taskData]
      newState[sInd] = items
      /* console.log('newState[sInd]', newState[sInd])
      console.log('newSate45', newState) */
      setTaskData(newState)

      /*     console.log('newSate45', taskMainData) */
      setTaskMainData(newState[0])
      updateTaskOrder(newState[0])
    } else {
      /*  const result = move(taskData[sInd], taskData[dInd], source, destination)
      const newState = [...taskData]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      console.log('newState852', newState)
      console.log(
        'newState.filter(group => group.length)',
        newState.filter(group => group.length)
      )
      setTaskData(newState.filter(group => group.length)) */
    }
  }
  function onDragEnd(result) {
    console.log('result456', result)
    const { source, destination, draggableId } = result

    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd: any = source.droppableId
    const dInd: any = destination.droppableId
    const dragId: any = draggableId.split('-')
    /*   console.log('sInd', sInd)
      console.log('dInd', dInd) */

    if (sInd == 0 && dInd == 0) {
      console.log('case-1')
      if (sInd === dInd) {
        const items = reorder(taskData[sInd], source.index, destination.index)
        const newState: any = [...taskData]
        newState[sInd] = items
        /*   console.log('newState[sInd]', newState[sInd])
          console.log('newSate45', newState) */
        setTaskData(newState)

        /*  console.log('newSate45', taskMainData) */
        setTaskMainData(newState[0])
        updateTaskOrder(newState[0])
      } else {
        /*  const result = move(taskData[sInd], taskData[dInd], source, destination)
      const newState = [...taskData]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      console.log('newState852', newState)
      console.log(
        'newState.filter(group => group.length)',
        newState.filter(group => group.length)
      )
      setTaskData(newState.filter(group => group.length)) */
      }
    } else if (sInd == 'mainGroup-1' && dInd == 'mainGroup-1') {
      console.log('case-2')

      const items = reorder(groupData, source.index, destination.index)
      let newState: any = [...groupData]
      newState = items
      setGroupData(newState)
      updateProjectAndProjectGroupOrder(projectData, newState)
      /*  console.log('newSate45', newState) */
    } else if (
      sInd == 'projectGroup-' + userData.defaultProjectID &&
      dInd == 'projectGroup-' + userData.defaultProjectID
    ) {
      console.log('case-3')

  console.log('leftMenuOrder',leftMenuOrder);
       const items = reorder(leftMenuOrder, source.index, destination.index);
       console.log('c3item',items)
       setLeftMenuOrder(items)
       updateLeftMenuOrder(items);
      /* let newState: any = [...projectData]
      newState = items
      setProjectData(newState) */





     // updateProjectAndProjectGroupOrder(newState, groupData)
      /*  console.log('newSate45', newState) */
    } else if (sInd.includes('projectList-') && sInd == dInd) {
      console.log('case-4')
      /*   
         console.log('sInd.includes("projectList-")', sInd.includes('projectList-'), dInd.includes('projectList-')) */
      var sIndId = sInd.split('-')

      if (
        sInd == 'projectList-' + sIndId[1] &&
        dInd == 'projectList-' + sIndId[1]
      ) {
        let newState = [...groupData]
        for (let i = 0; i < newState.length; i++) {
          if (newState[i].id == sIndId[1]) {
            const items = reorder(
              newState[i].project,
              source.index,
              destination.index
            )
            newState[i].project = items
          }
        }
        setGroupData(newState)
        updateProjectAndProjectGroupOrder(projectData, newState)
        /*    console.log('newSate45', newState) */
      }
    } else if (dragId[0] == 'dragprojectListid') {
      console.log('case-5')

      var dIndId = dInd.split('-')
      var sIndId = sInd.split('-')
      /*  console.log('dragprojectListid',dragId[1])
       console.log('dropprojecid',dIndId[1])
       console.log('sourceprojecid',sIndId[1]) */
      let movablaeListItem: any = {}
      let groupIndex: any = -1

      //for remove source
      if (sIndId[0] == 'projectGroup') {
        console.log('case51')
        let newprojectData = [...projectData]
        for (let i1 = 0; i1 < newprojectData.length; i1++) {
          if (newprojectData[i1].id == dragId[1]) {
            movablaeListItem = newprojectData[i1]
            newprojectData.splice(i1, 1)
            setProjectData(newprojectData)
            break
          }
        }
      } else {
        console.log('case52')

        let newgroupData = [...groupData]
        //console.log('newgroupdata',newgroupData)
        for (let i1 = 0; i1 < newgroupData.length; i1++) {
          for (let j = 0; j < newgroupData[i1].project.length; j++) {
            if (newgroupData[i1].project[j].id == dragId[1]) {
              movablaeListItem = newgroupData[i1].project[j]
              /*  console.log('movablaeListItem1',newgroupData[i1].project[j]) */
              newgroupData[i1].project.splice(j, 1)
              setGroupData(newgroupData)
              groupIndex = i1
              break
            }
          }
        }
      }

      /* 
      console.log('movablaeListItem',movablaeListItem); */
      movablaeListItem.projectGroupId = dIndId[1]
      if (dIndId[0] == 'projectGroup') {
        console.log('case53')

        let newprojectData = [...projectData]
        newprojectData.splice(destination.index, 0, movablaeListItem)
        setProjectData(newprojectData)
        return
        updateProjectAndProjectGroupOrder(newprojectData, groupData)
      } else {
        console.log('case54')

        let newgroupData = [...groupData]
        for (let i1 = 0; i1 < newgroupData.length; i1++) {
          if (newgroupData[i1].id == dIndId[1]) {
            newgroupData[i1].project.splice(
              destination.index,
              0,
              movablaeListItem
            )
            setGroupData(newgroupData)
            return
            updateProjectAndProjectGroupOrder(projectData, newgroupData)
            break
          }
        }
      }

      return
      let groupDataIndexArray = {}
      let newgroupData = [...groupData]
      for (let i = 0; i < newgroupData.length; i++) {
        if (newgroupData[i].id == dIndId[1]) {
          console.log('newState[i].project', newgroupData[i].project)
          groupDataIndexArray = newgroupData[i].project
        }
      }
      console.log('groupDataIndexArray', groupDataIndexArray)
      const result = move(projectData, groupDataIndexArray, source, destination)
      console.log('result996', result)
      return
      const newState = [...taskData]
      newState[sInd] = result[sInd]
      newState[dInd] = result[dInd]
      console.log('newState852', newState)
      console.log(
        'newState.filter(group => group.length)',
        newState.filter(group => group.length)
      )
      setTaskData(newState.filter(group => group.length))
    } else if (
      dragId[0] == 'dragid' &&
      (dInd.includes('list-') || dInd.includes('groupListItem-'))
    ) {
      console.log('case-6')
      let taskId = dragId[1]
      let newListId = dInd.split('-')
      console.log('taskId', taskId)
      console.log('newListId', newListId[1])
      let taskData1: any = [...taskData]
      let updateitem = {}
      let updateIndex = -1
      for (let i = 0; i < taskData1[0].length; i++) {
        if (taskData1[0][i].id == taskId) {
          taskData1[0][i].projectId = newListId[1]
          updateitem = taskData1[0][i]
          updateIndex = i
          taskData1[0].splice(i, 1)
          taskData1[0].splice(0, 0, updateitem)
        }
      }

      console.log('updateitem', updateitem)
      setTaskData(taskData1)

      try {
        const response = post(BASE_URL + '/update_task_project.php', updateitem)
        console.log('updateTask', response)
      } catch (ex) {
        console.log('response9211', ex)
      }
    }
  }

  /* 10/05 */
  function onDragStart(result) {
    console.log('result456xyzxyz', result)
    if(result.type == 'list'){
      typeName.current = 'group'
    }
    else{
      typeName.current = 'list'
    }
    const { source, draggableId } = result
    if (!source) {
      return
    }
    const dragId = draggableId
    console.log('dragId', dragId)
    if (dragId.includes('dragprojectid-')) {
      let data = [...groupData]
      let newGroupId = dragId.split('-')
      for (let i = 0; i < data.length; i++) {
        // if(data[i].id == newGroupId[1]){
        // console.log('data[i]',data[i]);
        data[i].open = false
        // }
      }
      setGroupData(data)
    }
  }
  function onDragUpdate(result) {
    console.log('xyxyxyxyxyx', result)
  }
  /* 10/05 */

  function onDragListEnd(result) {
    console.log('result4561', result)
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    if (sInd != dInd) {
      const items = reorder(projectData, source.index, destination.index)
      let newState: any = [...projectData]
      newState = items
      console.log('newState[sInd]', newState)
      console.log('newSate4545', newState)
      setProjectData(newState)
      console.log('newSate45', projectData)
    }
  }

  function onDragProjectEnd(result) {
    console.log('result45612', result)
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    if (sInd != dInd) {
      const items = reorder(groupData, source.index, destination.index)
      let newState: any = [...groupData]
      newState = items
      console.log('newState[sInd]', newState)
      console.log('newSate4545', newState)
      setGroupData(newState)
      console.log('newSate45', groupData)
    }
  }

  function onDragProjectListEnd(result) {
    console.log('result45612346', result)
    const { source, destination } = result
    // dropped outside the list
    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId
    console.log('sInd', sInd)
    console.log('dInd', dInd)
    if (sInd != dInd) {
      const items = reorderProjectList(
        groupData[sInd].project,
        source.index,
        destination.index
      )
      let newState: any = [...groupData]
      newState[sInd].project = items
      console.log('newState[sInd].project', newState[sInd].project)
      console.log('newSate45454545', newState)
      setGroupData(newState)
      console.log('newSate45', groupData)
    }
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user') || '{}')
    setuserData(user)
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setEmail(user.email)
    setNameLetters(user.firstName.charAt(0) + user.lastName.charAt(0))
    let userId = user.id
    console.log('user12', user)
    setUserId(userId)
    // setSelectedProjectID(user.defaultProjectID)
    getProjectWithGroup(userId, 0)
    // getTask(userId)
    // 03/05
    const current = new Date()
    const todayDate = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`
    console.log('todayDate', todayDate)
    setCurrentDate(todayDate)
    // 03/05
    console.log('windowWidth', window.innerWidth)
    console.log('windowHeight', window.innerHeight)
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)

    if (user.defaultProjectID && user.defaultProjectID == '' && user.defaultProjectID == null) {
      LogOutFun()
    }

    if (localStorage.getItem('project') && localStorage.getItem('project') != null) {
      let project = JSON.parse(localStorage.getItem('project') || '{}')
      setSelectedProject(project.id, project.projectName)
      // setTitleHeading(project.projectName)
    }

  }, [])

  function LogOutFun() {
    var myCookie = localStorage.getItem("setCookie");
    var Intro = localStorage.getItem("intro");
    localStorage.clear();
    if (myCookie != null) {
      localStorage.setItem("setCookie", myCookie);
    }
    if (Intro != null) {
    localStorage.setItem("intro", Intro);
    }
    window.location.reload()
  }

  // function menuToggle () {
  //   if (menuOpen) {
  //     setMenuOpen(false)
  //   } else {
  //     setMenuOpen(true)
  //   }
  // }


  async function updateTaskOrder(taskData) {
    let taskOrderIds: any = ''
    let completeTaskOrderIds: any = ''
    for (let i = 0; i < taskData.length; i++) {
      if (selectedProjectID == taskData[i].projectId) {
        if (parseInt(taskData[i].taskStatus) == 0) {
          if (taskOrderIds != '') taskOrderIds += ','
          taskOrderIds += taskData[i].id
        } else if (parseInt(taskData[i].taskStatus) == 1) {
          if (completeTaskOrderIds != '') completeTaskOrderIds += ','
          completeTaskOrderIds += taskData[i].id
        }
      }
    }

    console.log('Order of tasks Ids', taskOrderIds)
    console.log('Order of complete Task Ids', completeTaskOrderIds)
    console.log('Order of projectId', selectedProjectID)
    let config = {
      projectID: selectedProjectID,
      taskOrder: taskOrderIds,
      completedTaskOrder: completeTaskOrderIds
    }

    try {
      const apiData = await post(BASE_URL + '/change_task_order.php', config)
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function updateProjectAndProjectGroupOrder(pdata, gdata) {
    let projectOrderIds: any = ''
    let groupOrderIds: any = ''

    for (let i = 0; i < pdata.length; i++) {
      if (projectOrderIds != '') projectOrderIds += ','
      projectOrderIds += pdata[i].id
    }
    let groupProjectOrder = [
      { id: userData.defaultProjectID, orderIds: projectOrderIds }
    ]

    for (let i = 0; i < gdata.length; i++) {
      if (groupOrderIds != '') groupOrderIds += ','
      groupOrderIds += gdata[i].id

      let pOrderIds = ''
      for (let j = 0; j < gdata[i].project.length; j++) {
        if (pOrderIds != '') pOrderIds += ','
        {
          console.log('asdfgh', gdata[i])
          pOrderIds += gdata[i].project[j].id
        }
      }
      if (pOrderIds != '')
        groupProjectOrder.push({ id: gdata[i].id, orderIds: pOrderIds })
    }

    console.log('pdataOrder', groupProjectOrder)
    console.log('gdataOrder', groupOrderIds)
    let config = {
      userId: userData.id,
      groupProjectOrder: groupProjectOrder,
      groupOrderIds: groupOrderIds
    }

    try {
      const apiData = await post(
        BASE_URL + '/change_group_and_project_order.php',
        config
      )
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function updateLeftMenuOrder(leftPanelOrder) {
    
    let a="[";
    for(let i=0;i<leftPanelOrder.length;i++)
    {
      a+="{'id':"+leftPanelOrder[i].id+",'type':'"+leftPanelOrder[i].type+"'}";
    }
    a+="]";
    let config = {
      userId: userData.id,
      leftPanelOrder: JSON.stringify(leftPanelOrder),
      
    }
    try {
      const apiData = await post(
        BASE_URL + '/changeleftpanelorder',
        config
      )
      console.log('apiData', apiData)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  function changeStatus(item: any) {
    // getTask(userId);
    if (item.taskStatus == '0') {
      item.taskStatus = '1'
    } else {
      item.taskStatus = '0'
    }
    let taskId = item.id
    let taskStatus = item.taskStatus
    completeTask(taskId, taskStatus)
    console.log('itemData', item)
    let itemData = item
    console.log('itemData1', itemData)

    let data: any = [...taskData]
    console.log('1234', data.length)

    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == itemData.id) {
        data[0][i].taskStatus = itemData.taskStatus
        let newtaskdata: any = []
        if (itemData.taskStatus == 0)
          newtaskdata = rearangeArr(data[0], i, data[0].length - 1)
        else newtaskdata = rearangeArr(data[0], i, 0)

        setTaskData([newtaskdata])
        updateTaskOrder(newtaskdata)
      }
    }

    CompletedStatusCount(data[0])
  }

  async function addListIntoProject(currentgroupData) {
    let allgroupData = [...groupData]

    console.log('test1', allgroupData)
    let config = {
      userId: userId,
      projectName:
        projectsNewListInputValue != ''
          ? projectsNewListInputValue
          : 'Untitled List',
      projectGroupId: currentgroupData.id
    }
    console.log('test2', config)

    try {
      // console.log('config', config)
      const apiData = await post(BASE_URL + '/addproject', config)
      if (apiData.data.status == 'success') {
        console.log('apiData', apiData.data.data[0])
        for (let i = 0; i < allgroupData.length; i++) {
          if (allgroupData[i].id == currentgroupData.id) {
            allgroupData[i].project.push(apiData.data.data[0])
            setGroupData(allgroupData)
          }
        }
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function renameList(editListData: any, i1) {
    setEditList({})
    if (editListData.projectGroupId == '0') {
      let allListData = [...projectData]
      allListData[i1].projectName = editListData.projectName
      setProjectData(allListData)
    } else {
      console.log('editListData', editListData)
      let allGroupData = [...groupData]
      for (let i = 0; i < allGroupData.length; i++) {
        if (allGroupData[i].id == editListData.projectGroupId) {
          for (let j = 0; j < allGroupData[i].project.length; j++) {
            if (
              allGroupData[i].project[j].projectGroupId ==
              editListData.projectGroupId
            ) {
              allGroupData[i].project[i1].projectName = editListData.projectName
              setGroupData(allGroupData)
              break
            }
          }
        }
      }
    }

    try {
      const response = await post(
        BASE_URL + '/update_project.php',
        editListData
      )
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function renameGroup(editGroupData, i1) {
    console.log('editGroupData', editGroupData)
    setEditGroup({})
    let allgroupData = [...groupData]

    /*   allgroupData[i1].groupName = editGroupData.groupName
      setGroupData(allgroupData) */

    if (i1 != -1) {
      allgroupData[i1].groupName = editGroupData.groupName
      setGroupData(allgroupData)
    } else {
      for (let i = 0; i < allgroupData.length; i++) {
        if ((allgroupData[i].id = editGroupData.id)) {
          allgroupData[i].groupName = editGroupData.groupName
        }
      }
      setGroupData(allgroupData)
    }

    try {
      const response = await post(
        BASE_URL + '/update_project_group.php',
        editGroupData
      )
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
    setEditGroup({})
  }

  const taskDeleteItemRef: any = useRef({})

  function cheboxFun() {
    if (checked.current == true) {
      checked.current = false
    } else {
      checked.current = true
    }
    console.log('checked', checked)
  }

  const addDialog = ({ onClose }) => {
    const handleClickedNo = () => {
      // alert('clicked no')
      onClose()
    }
    const handleClickedYes = () => {
      onClose()
      deleteTask(taskDeleteItemRef.current)
      // 03/05
      console.log('checked', checked)
      if (checked.current == true) {
        localStorage.setItem('date', currentDate)
      }
      // 03/05
    }
    return (
      <div className='add-dialog'>
        <p>Are you sure you want to delete this task?</p>
        <div className='add-dialog-buttons'>
          <button onClick={handleClickedNo}>Cancle</button>
          <button onClick={handleClickedYes}>Delete Task</button>
        </div>
        <div className='confirmAlertCheckBoxDiv'>
          <div className='dontCheckBox'>
            <input type='checkbox' onClick={() => cheboxFun()} /> {/* 03/05 */}
          </div>{' '}
          Don't confirm
        </div>
      </div>
    )
  }

  const handleDeleteConfirm = (taskForDetele: any) => {
    taskDeleteItemRef.current = taskForDetele
    confirmAlert({ customUI: addDialog })
    /* confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure you want to delete this task?',
      buttons: [
        {
          label: 'Cancle'
          // onClick: () => deleteTask()
        },
        {
          label: 'Delete Task',
          onClick: () => {
            deleteTask(taskForDetele)
          }
        }
      ],
      childrenElement:() => {
      return(
        <div className='confirmAlertCheckBoxDiv'>
          <div className='dontCheckBox' onClick={()=>confirmCheckBox()}>
          {dontConfirm.current ?
            <img style={{width:11}} src={DontAllowCheck} alt="" />
            :
            <div>

            </div>
          }
            </div> Don't confirm
        </div>
      )
      },

    }) */
  }

  const handleDeleteAllConfirm = () => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure you want to delete All Completed task.',
      buttons: [
        {
          label: 'Cancle'
          // onClick: () => deleteTask()
        },
        {
          label: 'Delete Task',
          onClick: () => {
            deleteAllCompletedTask()
          }
        }
      ]
    })
  }

  async function deleteAllCompletedTask() {
    const json = {
      projectId: selectedProjectID
    }
    try {
      let data: any = [...taskMainData]
      console.log('data', data)
      let deletei: any = []
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].projectId == selectedProjectID &&
          data[i].taskStatus == '1'
        ) {
          deletei.push(i)
          data.splice(i, 1)
          i--
        }
      }

      if (taskItem.taskStatus == '1') setTaskSectionOpen(false)
      setTaskMainData(data)
      setTaskData([data])

      const response = await post(BASE_URL + '/delete_completed_task.php', json)
      console.log('response232323', response)

      if (response.data.status == 'success') {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  async function deleteTask(taskForDetele: any) {
    const json = {
      taskid: taskForDetele.id
    }
    try {
      const response = await post(BASE_URL + '/delete_task.php', json)
      console.log('response232323', response)
      console.log('taskItem.index', taskForDetele)
      if (response.data.status == 'success') {
        let data: any = [...taskMainData]
        console.log('data', data)
        let delData: any = []
        let del = -1
        let isOpenSideBar = taskSectionOpen
        if (taskItem && taskForDetele.id == taskItem.id)
          setTaskSectionOpen(false)

        for (let i = 0; i < data.length; i++) {
          if (data[i].id == taskForDetele.id) {
            data.splice(i, 1)
            delData = data[i]
            del = i
          }

          if (
            del != -1 &&
            delData.taskStatus == data[i].taskStatus &&
            selectedProjectID == data[i].projectId &&
            isOpenSideBar &&
            taskItem &&
            taskForDetele.id == taskItem.id
          ) {
            console.log('delData.taskStatus4545')
            setTaskItem(data[i])
            setTaskSectionOpen(true)
            break
          }
        }

        console.log('finaldata', data)
        setTaskMainData(data)
        setTaskData([data])
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function duplicateTask(item) {
    try {
      const data = {
        taskId: item.id
      }

      const response = await post(BASE_URL + '/duplicate_task.php', data)
      console.log('response23', response)
      if (response.data.status == 'success') {
        getTask(userId)

        /* 
             let data: any = [...taskData]
             console.log('1234', data.length)
              data.push(response.data.data[0])
         
                new  newtaskdata = rearangeArr(data[0], data[0].length - 1, 0)
         
            
        
         */
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  function addTaskToList() {
    console.log('taskInputValue', taskInputValue)
    if (taskInputValue != '') {
      postTask()

      // let json: any = {
      //   id: taskData.length + 1,
      //   taskTitle: taskInputValue,
      //   task: [],
      //   status: 0,
      //   category: 1
      // }
      // let data = [...taskData]
      // data.push(json)
      // setTaskData(data)
      // console.log('data', data)
      // console.log('taskData456', taskData)
      setTaskInputValue('')
    }
  }
  async function addProjectToList() {
    console.log('projectsInputValue', projectsInputValue)
    if (projectsInputValue != '') {
      let config = {
        userId: userId,
        projectName: projectsInputValue
      }
      try {
        // console.log('config', config)
        const apiData = await post(BASE_URL + '/addproject', config)
        console.log('apiData', apiData)
        if (apiData.data.status == 'success') {
          setProjectsInputValue('')
          getProjectWithGroup(userId, 0)
        } else {
        }
      } catch (ex) {
        console.log('response9211', ex)
      }
    }
  }
  async function setSelectedProject(projectId, projectname) {
    setSelectedProjectID(projectId)
    setSelectedProjectName(projectname)
  }

  async function addGroups() {
    let config = {
      userId: userId,
      groupName: groupInputValue
    }
    try {
      console.log('config', config)
      const apiData = await post(BASE_URL + '/add_project_group.php', config)
      console.log('apiData', apiData)
      if (apiData.data.status == 'success') {
        getProjectWithGroup(userId, 1)
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  function openGroup(item) {
    console.log('item', item)
    if (item.open) {
      item.open = false
    } else {
      item.open = true
    }
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].open = item.open
      }
    }
    setGroupData(data)
    console.log('item445', data)
  }
  function openGroupOnNewListAdd(item) {
    if (item.open) {
      item.open = true
    } else {
      item.open = true
    }
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].open = item.open
      }
    }
    setGroupData(data)
    console.log('item445', item)
  }
  function addProjectNewList(item) {
    if (item.addNewList) {
      item.addNewList = false
      setAddGroupList(false)
    } else {
      item.addNewList = true
      setAddGroupList(true)
    }
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].addNewList = item.addNewList
      }
    }
    setGroupData(data)
    console.log('item445', item)
  }
  function openRightList(item) {
    console.log('item1010', item)
  }

  function deleteProject(item: any) {
    let mainData = [...projectData]
    for (let i = 0; i < mainData.length; i++) {
      if (mainData[i].id == item.id) {
        // console.log(
        //   'taskItem.task[i].slice(0,1)',
        //   mainData[i].splice(i, 1)
        // )
        // console.log('taskItem123', mainData[i])
        mainData.splice(i, 1)
      }
    }
    setProjectData(mainData)
  }

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setTaskInputValue(event.target.value)
  }
  const handleChangeForProject = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setProjectsInputValue(event.target.value)
  }
  const handleChangeForProjectNewList = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setProjectsNewListInputValue(event.target.value)
  }

  const handleChangeForGroup = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setGroupInputValue(event.target.value)
  }

  const handleKeyDown = (event: { key: any }) => {
    if (event.key === 'Enter') {
      addTaskToList()
      console.log('User pressed: ', event.key)
    }
  }
  const handleKeyDownForProject = (event: { key: any; target: any }) => {
    if (event.key === 'Enter') {
      if (event.target.value != '') {
        addProjectToList()
        setProjectsInputValue('')
        setAddList(false)
        console.log('User pressed: ', event.key)
      }
    }
  }
  const handleKeyDownForProjectNewList = (
    event: { key: any; target: any },
    item
  ) => {
    if (event.key === 'Enter') {
      if (event.target.value != '') {
        addListIntoProject(item)
        setProjectsNewListInputValue('')
        item.addNewList = false
        console.log('User pressed: ', event.key)
      }
    }
  }
  const handleBlurProjectNewList = (event: any, item) => {
    if (event.target.value != '') {
      addListIntoProject(item)
      setProjectsNewListInputValue('')
      item.addNewList = false
    } else {
      setProjectsNewListInputValue('')
      item.addNewList = false
    }
  }
  const handleBlurForList = (event: any) => {
    if (event.target.value != '') {
      addProjectToList()
      setProjectsInputValue('')
      setAddList(false)
    } else {
      setProjectsInputValue('')
      setAddList(false)
    }
  }

  const handleBlurForProject = (event: any) => {
    if (event.target.value != '') {
      addGroups()
      setGroupInputValue('')
      setAddProject(false)
    } else {
      setGroupInputValue('')
      setAddProject(false)
    }
  }
  const handleKeyDownForGroup = (event: { key: any; target: any }) => {
    if (event.key === 'Enter') {
      if (event.target.value != '') {
        addGroups()
        setGroupInputValue('')
        setAddProject(false)
        console.log('User pressed: ', event.target.value)
      }

      //sideMenuUpperDivRef.current.scrollTop = sideMenuUpperDivRef.current.scrollHeight;
      //sideMenuUpperDivRef.current?.scrollIntoView();
      /* let valule:any= document.getElementsByClassName('sideMenuUpperDiv');
      valule.scrollTo(0,0); */
    }
  }

  function openTask(item: any, index: any) {
    if (taskSectionOpen) {
      setTaskSectionOpen(false)
    } else {
      setTaskSectionOpen(true)
      setTaskIndex(index)
      console.log('taskIndex', taskIndex)
      console.log('index', index)
      setTaskItem(item)
    }
  }

  function statusCount(item: any) {
    let mainData = [...item.task]
    let count = 0
    for (let i = 0; i < mainData.length; i++) {
      if (mainData[i].status == 1) {
        count++
      }
    }
    return count
  }
  function getTaskCountOfProject(projectID: any) {
    let count = 0
    if (taskData.length > 0) {
      let taskdata1 = [...taskData]

      for (let i = 0; i < taskdata1[0].length; i++) {
        if (parseInt(taskdata1[0][i].projectId) == parseInt(projectID)) count++
      }
    }

    return count
  }
  function getUndoneTaskCountOfProject(projectID: any) {
    let count = 0
    if (taskData.length > 0) {
      let taskdata1 = [...taskData]

      for (let i = 0; i < taskdata1[0].length; i++) {
        if (
          parseInt(taskdata1[0][i].projectId) == parseInt(projectID) &&
          taskdata1[0][i].taskStatus == '0'
        )
          count++
      }
    }

    return count
  }

  function completeTaskList() {
    if (completeTaskShow) {
      setCompleteTaskShow(false)
    } else {
      setCompleteTaskShow(true)
    }
  }

  const postTask = async () => {
    // for get task order
    let taskOrderIds: any = ''
    let completeTaskOrderIds: any = ''
    for (let i = 0; i < taskData[0].length; i++) {
      if (selectedProjectID == taskData[0][i].projectId) {
        if (parseInt(taskData[0][i].taskStatus) == 0) {
          if (taskOrderIds != '') taskOrderIds += ','
          taskOrderIds += taskData[0][i].id
        } else if (parseInt(taskData[0][i].taskStatus) == 1) {
          if (completeTaskOrderIds != '') completeTaskOrderIds += ','
          completeTaskOrderIds += taskData[0][i].id
        }
      }
    }

    console.log('Order of tasks Ids', taskOrderIds)
    console.log('Order of complete Task Ids', completeTaskOrderIds)

    // for get task order

    try {
      const data = {
        title: taskInputValue,
        userid: userId,
        taskorder: 0,
        projectId: selectedProjectID,
        taskOrderIds: taskOrderIds,
        completedTaskOrderIds: completeTaskOrderIds
      }
      console.log('data123456', data)
      const response = await post(BASE_URL + '/add_task.php', data)
      console.log('response23', response)
      if (response.data.status == 'success') {
        getTask(userId)
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const getTask = async userId => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post(BASE_URL + '/get_tasks.php', config)
      console.log('apiData4556', apiData)
      if (apiData.data.status == 'success') {
        console.log('apiData.data.data', apiData.data.data)
        CompletedStatusCount(apiData.data.data)
        setTaskData([apiData.data.data])
        setTaskMainData(apiData.data.data)
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  const getProjectWithGroup = async (userId, isScroll) => {
    console.log('taskId2311', userId)
    let config = {
      userid: userId
    }
    try {
      const apiData = await post('https://www.managestuff.com/api/get_project_with_group1.php',config)
      console.log('apiData1212122222', apiData)
      if (apiData.data.status == 'success') {
        let lpo:any=[];
      if(apiData.data.leftPanelOrder!="")
      {
       
        for(let i=0;i<apiData.data.projects.length;i++)
        {
          let item={id:apiData.data.projects[i].id, type: 'project'};
          lpo.push(item)
        }
        for(let i=0;i<apiData.data.projectsGroup.length;i++)
        {
          let item1={id:apiData.data.projectsGroup[i].id, type: 'projectGroup'}
          lpo.push(item1)
        }
      }
      else
     {
      console.log('aaaa',apiData.data.leftPanelOrder)
      lpo=apiData.data.leftPanelOrder
    }


        setProjectData(apiData.data.projects)
        setGroupData(apiData.data.projectsGroup)



        //for tresting
        // let lmo = [
        //   { id: 224, type: "project" },
        //   { id: 141, type: "projectGroup" },
        //   { id: 140, type: "projectGroup" },
        //   { id: 225, type: "project" },
        //   { id: 19, type: "project" },
        //   { id: 142, type: "projectGroup" },
        //   { id: 18, type: "project" },
        // ];

        setLeftMenuOrder(lpo);
        //end for testing


        let user = JSON.parse(localStorage.getItem('user') || '{}')

        // setSelectedProject(user.defaultProjectID, 'Tasks')
        if (isScroll == 1) {
          setTimeout(() => {
            sideMenuUpperDivRef.current.scrollTop =
              sideMenuUpperDivRef.current.scrollHeight + 50
          }, 200)
        }
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function unGroupProject(item) {
    console.log('item12', item)
    const json = {
      projectgroupid: item.id
    }
    try {
      console.log('json', json)

      const response = await post(BASE_URL + '/ungroup_project_group.php', json)
      console.log('response232323', response)
      if (response.data.status == 'success') {
        let data: any = [...groupData]
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == item.id) {
            let list1: any = [...projectData]
            for (let j = 0; j < data[i].project.length; j++) {
              data[i].project[j].projectGroupId = 0
              list1.push(data[i].project[j])
            }
            setProjectData(list1)
            data[i].project = []
            setGroupData(data)
          }
        }
        setGroupData(data)
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function deleteGroupUnderList(item) {
    console.log('item', item)
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'First you need to remove all the list under the project.',
      buttons: [
        {
          label: 'OK'
          // onClick: () => alert('Click No'),
        }
      ]
    })
  }
  async function deleteGroup(item) {
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure you want to delete this Group.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteGroup1(item)
        },
        {
          label: 'No'
          // onClick: () => alert('Click No'),
        }
      ]
    })
  }
  async function deleteGroup1(item) {
    console.log('item12', item)
    const json = {
      projectgroupid: item.id
    }
    try {
      console.log('json', json)
      const response = await post(BASE_URL + '/delete_project_group.php', json)
      console.log('response232323', response)
      if (response.data.status == 'success') {
        let data: any = [...groupData]
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == item.id) {
            data.splice(i, 1)
          }
        }
        setGroupData(data)
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function deleteProjectListItem(item) {
    console.log('item12', item)
    const json = {
      projectid: item.id
    }
    try {
      console.log('json', json)

      const response = await post(BASE_URL + '/deleteproject', json)
      console.log('response232323', response)
      if (response.data.status == 'success') {
        if (item.projectGroupId == '0') {
          let data: any = [...projectData]
          for (let i = 0; i < data.length; i++) {
            if (data[i].id == item.id) {
              if (item.id == selectedProjectID) {
                if (i + 1 == data.length) {
                  setSelectedProject(data[i - 1].id, data[i - 1].projectName)
                  // setTitleHeading(data[i - 1].projectName)
                } else {
                  setSelectedProject(data[i + 1].id, data[i + 1].projectName)
                  // setTitleHeading(data[i + 1].projectName)
                }
              }
              data.splice(i, 1)
            }
          }
          setProjectData(data)
        } else {
          let data: any = [...groupData]
          for (let i = 0; i < data.length; i++) {
            console.log('setgroupdata', data[i].id + ',' + item.projectGroupId)
            if (data[i].id == item.projectGroupId) {
              for (let j = 0; j < data[i].project.length; j++) {
                if (data[i].project[j].id == item.id) {
                  data[i].project.splice(j, 1)
                }
              }
            }
          }
          setGroupData(data)
          console.log('setgroupdata', data)
        }
      } else {
      }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  function CompletedStatusCount(value) {
    let mainData: any = [...value]
    let count = 0
    console.log('selectedProjectID', selectedProjectID)
    for (let i = 0; i < mainData.length; i++) {
      if (mainData[i].taskStatus == '1') {
        count++
      }
    }
    console.log('mainData', mainData)
    console.log('counttest', count)
    // return count
    setCountNum(count)
  }

  function getCompletedCount() {
    let mainData: any = taskMainData
    let count = 0
    // console.log('selectedProjectID', selectedProjectID)
    for (let i = 0; i < mainData.length; i++) {
      if (
        mainData[i].taskStatus == '1' &&
        mainData[i].projectId == selectedProjectID
      ) {
        count++
      }
    }

    return count
  }

  const completeTask = async (taskId, taskStatus) => {
    console.log('taskId2311', taskId)
    let config = {
      taskid: taskId,
      status: taskStatus
    }
    try {
      const apiData = await post(BASE_URL + '/complete_task.php', config)
      console.log('apiData', apiData)
      // if (apiData.data.status == 'success') {
      //   console.log('apiData.data.data', apiData.data.data)
      //   setTaskData([apiData.data.data])
      // }
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  const inputFileRef: any = useRef()

  const handleProjectDeleteConfirm = item => {
    console.log('item4556', item)

    let undoneTaskCount = getUndoneTaskCountOfProject(item.id)
    if (undoneTaskCount > 0) {
      confirmAlert({
        // title: 'Confirm to submit',
        message:
          'All tasks must be marked as done/completed before a list can be deleted',
        buttons: [
          {
            label: 'OK'
            // onClick: () => alert('Click No'),
          }
        ]
      })
    } else if (item.isDefaultProject == '0') {
      confirmAlert({
        // title: 'Confirm to submit',
        message: 'Default List can not be Deleted',
        buttons: [
          {
            label: 'OK'
            // onClick: () => alert('Click No'),
          }
        ]
      })
    } else {
      confirmAlert({
        // title: 'Confirm to submit',
        message: 'Are you sure you want to delete this List.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteProjectListItem(item)
          },
          {
            label: 'No'
            // onClick: () => alert('Click No'),
          }
        ]
      })
    }
  }

  async function handleChangeProjectName(e) {
    let data = [...projectData]
    let editList: any = {}
    let index = -1
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == selectedProjectID) {
        editList = data[i]
        index = i
        break
      }
    }

    editList.projectName = e.target.innerText
    console.log('editList45', editList)
    console.log('editList45', index)
    renameList(editList, index)
    // updateTask(taskItem)
  }
  const handleProjectNameKeyDown = e => {
    if (e.keyCode === 13) {
      e.target.blur()
      let data = [...projectData]
      let editList: any = {}
      let index = -1
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == selectedProjectID) {
          editList = data[i]
          index = i
          break
        }
      }

      editList.projectName = e.target.innerText
      console.log('editList45', editList)
      console.log('editList45', index)
      renameList(editList, index)
    }
  }

  const sideMenuUpperDivRef: any = useRef()

  async function groupHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem', dataItem)
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == dataItem.id) {
        data[i].color = item.colorCode
      }
    }
    setGroupData(data)
    try {
      const response = await post(
        BASE_URL + '/update_project_group.php',
        dataItem
      )
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function listHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem23', dataItem)
    let data = [...projectData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == dataItem.id) {
        data[i].color = item.colorCode
      }
    }
    console.log('data1010', data)
    setProjectData(data)
    try {
      const response = await post(BASE_URL + '/update_project.php', dataItem)
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function projectListHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem', dataItem)
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].project.length; j++) {
        if (data[i].project[j].id == dataItem.id) {
          data[i].project[j].color = item.colorCode
        }
      }
    }
    setGroupData(data)
    try {
      const response = await post(BASE_URL + '/update_project.php', dataItem)
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function taskHighlightColor(item) {
    console.log('highlightFrom', highlightFrom)
    console.log('taskData123', taskData)

    console.log('item', item)
    setColorPalette(false)
    console.log('dataitem', dataItem)
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == dataItem.id) {
        data[0][i].color = item.colorCode
      }
    }
    setTaskData(data)
    setTaskMainData(data[0])
    try {
      const response = await post(BASE_URL + '/update_task.php', dataItem)
      console.log('response232323', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  async function handleChangeTaskName(e, item) {
    e.target.blur()
    forEditTextTaskName(item)
    let data: any = [...taskMainData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == taskItem.id) {
        data[i].taskName = e.target.innerText
      }
      // data[i].editTextTaskName = false
    }
    setTaskMainData(data)
    setTaskData([data])

    updateTask(taskItem)
  }
  const handleTaskNameKeyDown = (e, item) => {
    if (e.keyCode === 13) {
      e.target.blur()
      item.editTextTaskName = false
    }
  }

  async function updateTask(taskItem) {
    try {
      const response = await post(BASE_URL + '/update_task.php', taskItem)
      console.log('updateTask', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }

  function forEditTextTaskName(item) {
    if (item.editTextTaskName) {
      item.editTextTaskName = false
    } else {
      item.editTextTaskName = true
    }
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].id == item.id) {
        data[0][i].editTextTaskName = true
        console.log('data[0][i]', data[0][i])
      } else {
        data[0][i].editTextTaskName = false
      }
    }
    console.log('data45', data)

    setTaskData(data)
    setTaskMainData(data[0])
  }
  function forEditTextTaskNameFalse(item) {
    if (item.editTextTaskName) {
      item.editTextTaskName = false
    }
    let data = [...taskData]
    for (let i = 0; i < data[0].length; i++) {
      data[0][i].editTextTaskName = false
    }
    console.log('data45', data)
    setTaskData(data)
    setTaskMainData(data[0])
  }

  /* 09/05 */
  async function moveTaskToList(groupItem, dataItem) {
    console.log('groupItem', groupItem)
    console.log('dataItem', dataItem)
    let taskData1: any = [...taskData]
    let updateitem = dataItem
    let updateIndex = -1
    for (let i = 0; i < taskData1[0].length; i++) {
      if (taskData1[0][i].id == dataItem.id) {
        taskData1[0][i].projectId = groupItem.id
        updateitem = taskData1[0][i]
        updateIndex = i
        taskData1[0].splice(i, 1)
        taskData1[0].splice(0, 0, updateitem)
      }
    }

    console.log('updateitem', updateitem)
    setTaskData(taskData1)
    try {
      const response = post(BASE_URL + '/update_task_project.php', updateitem)
      console.log('updateTask', response)
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  /* 09/05 */

  function handleDimension(pageX, pageY) {
    // console.log('pageX',pageX)
    // console.log('pageY',pageY)
    // console.log('WindowWidth',windowWidth)
    // console.log('windowHeight',windowHeight)
    let totalForWidth = windowWidth - 240
    let totalForHeight = windowHeight - 310
    if (pageY > totalForHeight && pageX > totalForWidth) {
      setTaskMenuListpoints({
        x: totalForWidth,
        y: totalForHeight
      })
    } else if (pageX > totalForWidth) {
      setTaskMenuListpoints({
        x: totalForWidth,
        y: pageY
      })
    } else if (pageY > totalForHeight) {
      setTaskMenuListpoints({
        x: pageX,
        y: totalForHeight
      })
    } else {
      setTaskMenuListpoints({
        x: pageX,
        y: pageY
      })
    }
  }

  // const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
  })
  const getListStyle = isDraggingOver => ({
    borderBottom: isDraggingOver
      ? '2px solid #787878'
      : '2px solid transparent',
    borderTop: isDraggingOver ? '2px solid #787878' : '2px solid transparent',
    transition: isDraggingOver ? 'none' : 'none',
    background: isDraggingOver ? '#eaeaea' : 'transparent',
    height: 35
  })

  function mouseEnter(item) {
    let data = [...projectData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isShowThreeDots = true
      }
    }
    setProjectData(data)
  }

  function mouseLeave(item) {
    let data = [...projectData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        data[i].isShowThreeDots = false
      }
    }
    setProjectData(data)
  }

  function mouseEnterForGroupList(item, pitem) {
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        for (let j = 0; j < data[i].project.length; j++) {
          if (data[i].project[j].id == pitem.id) {
            data[i].project[j].isShowThreeDots = true
          }
        }
      }
    }
    setGroupData(data)
  }

  function mouseLeaveForGroupList(item, pitem) {
    let data = [...groupData]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == item.id) {
        for (let j = 0; j < data[i].project.length; j++) {
          if (data[i].project[j].id == pitem.id) {
            data[i].project[j].isShowThreeDots = false
          }
        }
      }
    }
    setGroupData(data)
  }


  const searchProject = (searchValue) => {
    setProjectListInput(searchValue)
    let data: any = [...projectData];
    if (projectListInput !== '') {
      const filteredData: any = data.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(projectListInput.toLowerCase())
      })
      console.log('filteredData', filteredData)
      setFilteredResults(filteredData)
    }
    else {
      setFilteredResults(data)
    }
  }

  return (
    <div>
      {/* <Navbar /> */}

      <div style={{ display: 'flex' }}>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
        >
          <div style={{ minWidth: 280, height: '100%' }}>
            <div style={styles.mainMenuDiv}>
              <div className='sideMenuUpperDiv'>
                <div style={{ paddingLeft: 8 }}>
                  <div className='MainProfileDiv'>
                    <div style={{ width: '15%' }}>
                      <div className='userAvtarDiv'>
                        {/* {firstName.charAt(0)}{lastName.charAt(0)} */}
                        {nameLetters}
                      </div>
                    </div>
                    <div className='userDetailDiv'>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: '#464645'
                        }}
                      >
                        {firstName} {lastName}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: 12 }}>{email}</div>
                        <div>
                          <Button
                            ref={anchorRef}
                            id='composition-button'
                            aria-controls={
                              open ? 'composition-menu' : undefined
                            }
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup='true'
                            onClick={handleToggle}
                            className='settingButton'
                          >
                            <img
                              style={{
                                width: 16,
                                height: 16
                              }}
                              src={Setting}
                              alt=''
                            />
                          </Button>
                          <Popper
                            style={{ zIndex: 999 }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement='bottom'
                            transition
                            disablePortal
                            className='popperWidth'
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === 'bottom'
                                      ? 'left top'
                                      : 'left bottom'
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={open}
                                      id='composition-menu'
                                      aria-labelledby='composition-button'
                                    // onKeyDown={handleListKeyDown}
                                    >
                                      <div
                                        className='closeIconDiv'
                                        onClick={handleClose}
                                      >
                                        <img src={CloseMenuListIcon} alt='' />
                                      </div>
                                      <MenuItem
                                        className='customCursor'
                                        onClick={handleClose}
                                        style={{ padding: 0 }}
                                      >
                                        <div className='settingModalItems customCursor'>
                                          <img src={ManageAccountIcon} alt='' />
                                          <span>Manage accounts</span>
                                        </div>
                                      </MenuItem>
                                      <MenuItem
                                        className='customCursor'
                                        onClick={handleClose}
                                        style={{ padding: 0 }}
                                      >
                                        {' '}
                                        <div className='settingModalItems customCursor'>
                                          <img src={Setting} alt='' />
                                          <span>Settings</span>
                                        </div>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={handleClose}
                                        style={{ padding: 0 }}
                                      >
                                        <div
                                          className='settingModalItems'
                                          onClick={() => LogOutFun()}
                                        >
                                          <img
                                            style={{ cursor: 'pointer' }}
                                            src={LogOut}
                                          />
                                          <span>Log out</span>
                                        </div>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      ...styles.menuItems,
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      marginTop: 15
                    }}
                  >
                    <input
                      style={styles.searchBarInput}
                      className='searchBarInputCss'
                      type='text'
                      name='name'
                      placeholder='Search....'
                    />
                    <img style={styles.searchBarIcon} src={Magnifier} />
                  </div>
                </div>

                <div
                  className='projectWithGroup'
                  style={{ height: window.innerHeight - 225 }}
                  ref={sideMenuUpperDivRef}
                >


                      <Droppable 
                          key={'projectGroup-' + userData.defaultProjectID}
                          droppableId={`${'projectGroup-' + userData.defaultProjectID
                         
                            }`}
                            type={typeName.current}   
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              // style={{backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey'}}
                              {...provided.droppableProps}
                            > 





                  {leftMenuOrder &&
                    leftMenuOrder.length > 0 &&
                    leftMenuOrder.map((lmo: any, lmoi: any) => (
                      <>
                      <Draggable
                                        key={`drag-list-id-${lmo.id}`}
                                        draggableId={`dragprojectListid-${lmo.id}`}
                                        index={lmoi}
                                        type={typeName.current}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          // style={getItemStyle(
                                          //   snapshot.isDragging,
                                          //   provided.draggableProps.style
                                          // )}
                                          > 
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      {lmo.type == "project" &&
                      
                            <>
                              {projectData &&
                                projectData.length > 0 &&
                                projectData.map((item: any, i: any) => (
                                  <>
                                    {lmo.id == item.id &&
                                     /*  <Draggable
                                        key={`drag-list-id-${item.id}`}
                                        draggableId={`dragprojectListid-${item.id}`}
                                        index={i}
                                       
                                      >
                                        {(providedlmo, snapshot) => (
                                          <div
                                            ref={providedlmo.innerRef}
                                            {...providedlmo.draggableProps}
                                            {...providedlmo.dragHandleProps}
                                          // style={getItemStyle(
                                          //   snapshot.isDragging,
                                          //   provided.draggableProps.style
                                          // )}
                                          > */
                                            <Droppable
                                              key={'list-' + item.id}
                                              droppableId={`${'list-' + item.id}`}
                                              style={{ transition: 'none' }}
                                              type={"task"}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  // style={{backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey'}}
                                                  style={getListStyle(
                                                    snapshot.isDraggingOver
                                                  )}
                                                  {...provided.droppableProps}
                                                >
                                                  <>
                                                    <div
                                                      style={{ background: item.color }}
                                                      onClick={() => {
                                                        setSelectedProject(
                                                          item.id,
                                                          item.projectName
                                                        )
                                                        // setTitleHeading(item.projectName)
                                                        setSelectedProjectGruopID('')
                                                        localStorage.setItem('project', JSON.stringify(item));
                                                      }}
                                                      className={
                                                        selectedProjectID == item.id
                                                          ? 'addTaskStepsActive'
                                                          : 'addTaskSteps'
                                                      }
                                                      onContextMenu={e => {
                                                        e.preventDefault()
                                                        setClicked(false)
                                                        setProjectClicked(false)
                                                        setListClicked(true)
                                                        setPoints({
                                                          x: e.pageX,
                                                          y: e.pageY
                                                        })
                                                        setDataItem(item)
                                                      }}
                                                      onMouseEnter={() =>
                                                        mouseEnter(item)
                                                      }
                                                      onMouseLeave={() =>
                                                        mouseLeave(item)
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          snapshot.isDraggingOver
                                                            ? 'taskStepTitleRowForBorder'
                                                            : 'taskStepTitleRow'
                                                        }
                                                      >
                                                        <img
                                                          // onClick={() => deleteProject(item)}
                                                          style={{
                                                            width: 16,
                                                            height: 16,
                                                            cursor: 'pointer'
                                                          }}
                                                          src={ListIcon}
                                                        />
                                                        <div style={{ flex: 1 }}>
                                                          {editList?.id == item.id ? (
                                                            <input
                                                              autoFocus={true}
                                                              style={{ width: 150 }}
                                                              onChange={e => {
                                                                let editListData = {
                                                                  ...editList
                                                                }
                                                                editListData.projectName =
                                                                  e.target.value
                                                                setEditList(editListData)
                                                              }}
                                                              value={decData(
                                                                editList.projectName,
                                                                editList.isEncrypt
                                                              )}
                                                              onBlur={e => {
                                                                console.log('asdfghj', e)
                                                                renameList(editList, i)
                                                              }}
                                                              onKeyDown={(event: any) => {
                                                                if (
                                                                  event.key === 'Enter'
                                                                ) {
                                                                  renameList(editList, i)
                                                                  console.log(
                                                                    'User pressed: ',
                                                                    event.key
                                                                  )
                                                                }
                                                              }}
                                                            />
                                                          ) : (
                                                            decData(
                                                              item.projectName,
                                                              item.isEncrypt
                                                            )
                                                          )}
                                                        </div>
                                                        <div className='listTaskCountdiv'>
                                                          {item.isShowThreeDots ? (
                                                            <div
                                                              className='threeDots'
                                                              onClick={e => {
                                                                e.stopPropagation()
                                                                e.preventDefault()
                                                                setClicked(false)
                                                                setProjectClicked(false)
                                                                setListClicked(true)
                                                                setPoints({
                                                                  x: e.pageX,
                                                                  y: e.pageY
                                                                })
                                                                setDataItem(item)
                                                              }}
                                                            >
                                                              <img
                                                                style={{ width: 20 }}
                                                                src={ThreeDots}
                                                                alt=''
                                                              />
                                                            </div>
                                                          ) : (
                                                            <span>
                                                              {getTaskCountOfProject(
                                                                item.id
                                                              )}
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                       /*      {providedlmo.placeholder}
                                          </div>
                                        )}
                                      </Draggable> */
                                    }
                                  </>
                                ))}
                                </>
                             
                        
                      }





                        {lmo.type == "projectGroup" &&
                          /*  <Droppable
                              key={'mainGroup-' + 1}
                              droppableId={`${'mainGroup-' + 1}`}

                            >
                              {(providedlmo, snapshot) => (
                                <div
                                  ref={providedlmo.innerRef}
                                  // style={getListStyle(snapshot.isDraggingOver)}
                                  {...providedlmo.droppableProps}
                                > */
                              <>
                                {groupData &&
                                  groupData.length > 0 &&
                                  groupData.map((item: any, i: any) => (
                                    <>
                                      {item.id == lmo.id &&
                                      /*   <Draggable
                                         
                                          key={`drag-project-id-${item.id}`}
                                          draggableId={`dragprojectid-${item.id}`}
                                          index={i}
                                        >
                                          {(providedlmo, snapshot) => (
                                            <div
                                              ref={providedlmo.innerRef}
                                              {...providedlmo.draggableProps}
                                              {...providedlmo.dragHandleProps}
                                            // style={getItemStyle(
                                            //   snapshot.isDragging,
                                            //   provided.draggableProps.style
                                            // )}
                                            > */
                                              <Droppable
                                                key={'group-' + item.id}
                                                droppableId={`${'group-' + item.id}`}
                                                type={"group"}
                                              >
                                                {(provided1111, snapshot) => (
                                                  <div
                                                    ref={provided1111.innerRef}
                                                   
                                                    {...provided1111.droppableProps}
                                                  >
                                                    <div>
                                                      <div
                                                        onClick={() => {
                                                          openGroup(item)
                                                          setSelectedProjectGruopID(
                                                            item.id
                                                          )
                                                          setSelectedProjectID('')
                                                          setSelectedProjectName('')
                                                          // setTitleHeading('')
                                                          setTaskSectionOpen(false)
                                                        }}
                                                        className={
                                                          selectedProjectGruopID ==
                                                            item.id
                                                            ? 'addTaskStepsActive'
                                                            : 'addTaskSteps'
                                                        }
                                                        style={{
                                                          background:
                                                            item.color != 'transparent'
                                                              ? item.color
                                                              : selectedProjectGruopID ==
                                                                item.id
                                                                ? '#ECF1FF'
                                                                : item.color
                                                        }} /* 03/05 */
                                                        // onContextMenu={(e) => {
                                                        //   e.preventDefault(); // prevent the default behaviour when right clicked
                                                        //   console.log("Right Click");
                                                        //   openRightList(item)
                                                        // }}
                                                        onContextMenu={e => {
                                                          e.preventDefault()
                                                          setProjectClicked(false)
                                                          setListClicked(false)
                                                          setClicked(true)
                                                          setPoints({
                                                            x: e.pageX,
                                                            y: e.pageY
                                                          })
                                                          setDataItem(item)
                                                          openRightList(item)
                                                          // console.log("Right Click", e.pageX, e.pageY);
                                                        }}
                                                      >
                                                        <div className='taskStepTitleRow'>
                                                          <img
                                                            style={{
                                                              width: 16,
                                                              height: 16,
                                                              cursor: 'pointer'
                                                            }}
                                                            src={GroupIcon}
                                                          />
                                                          <div className='groupTitleRow'>
                                                            <div>
                                                              {editGroup?.id ==
                                                                item.id ? (
                                                                <input
                                                                  style={{ width: 150 }}
                                                                  onChange={e => {
                                                                    let editGroupData = {
                                                                      ...editGroup
                                                                    }
                                                                    editGroupData.groupName =
                                                                      e.target.value
                                                                    setEditGroup(
                                                                      editGroupData
                                                                    )
                                                                  }}
                                                                  value={decData(
                                                                    editGroup.groupName,
                                                                    editGroup.isEncrypt
                                                                  )}
                                                                  onBlur={e => {
                                                                    console.log(
                                                                      'asdfghj',
                                                                      e
                                                                    )
                                                                    renameGroup(
                                                                      editGroup,
                                                                      i
                                                                    )
                                                                  }}
                                                                  onKeyDown={(
                                                                    event: any
                                                                  ) => {
                                                                    if (
                                                                      event.key ===
                                                                      'Enter'
                                                                    ) {
                                                                      renameGroup(
                                                                        editGroup,
                                                                        i
                                                                      )
                                                                      console.log(
                                                                        'User pressed: ',
                                                                        event.key
                                                                      )
                                                                    }
                                                                  }}
                                                                  autoFocus={true}
                                                                />
                                                              ) : (
                                                                decData(
                                                                  item.groupName,
                                                                  item.isEncrypt
                                                                )
                                                              )}
                                                            </div>
                                                            <img
                                                              className={
                                                                item.open
                                                                  ? 'groupDownArrow'
                                                                  : 'groupUpArrow'
                                                              }
                                                              src={completedArrowIcon}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {provided1111.placeholder}
                                                  </div>
                                                )}
                                              </Droppable>
                                             /*  {providedlmo.placeholder}
                                            </div>
                                          )}
                                        </Draggable> */
                                      }
                                      <>
                                        {item?.open && item.id == lmo.id && (
                                          <>
                                            {item.project.length > 0 ? null : (
                                              <>
                                                {item.addNewList && addGroupList ? (
                                                  <div
                                                    className='groupLists newListInputBorders'
                                                    onBlur={() => {
                                                      setAddGroupList(false)
                                                      item.addNewList = false
                                                    }}
                                                  >
                                                    <div className='taskStepTitleRow'>
                                                      <img
                                                        style={{
                                                          width: 16,
                                                          height: 16,
                                                          cursor: 'pointer'
                                                        }}
                                                        src={AddProjectIcon}
                                                      />

                                                      <div style={{ flex: 1 }}>
                                                        <input
                                                          className='addProjectNewListInput'
                                                          type='text'
                                                          name='name'
                                                          placeholder='Untitled List'
                                                          onBlur={e => {
                                                            handleBlurProjectNewList(
                                                              e,
                                                              item
                                                            )
                                                          }}
                                                          autoComplete='off'
                                                          autoFocus={true}
                                                          value={
                                                            projectsNewListInputValue
                                                          }
                                                          onChange={
                                                            handleChangeForProjectNewList
                                                          }
                                                          // onKeyDown={handleKeyDownForProjectNewList}
                                                          onKeyDown={e => {
                                                            handleKeyDownForProjectNewList(
                                                              e,
                                                              item
                                                            )
                                                          }}
                                                        />
                                                      </div>
                                                      {projectsNewListInputValue.length >
                                                        0 && (
                                                          <div
                                                            className='addBtn'
                                                            onClick={() => {
                                                              addListIntoProject(item)
                                                              setProjectsNewListInputValue(
                                                                ''
                                                              )
                                                              item.addNewList = false
                                                            }}
                                                          >
                                                            Add
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className='groupLists newListInputBordersTransparent'
                                                    onClick={() => {
                                                      addProjectNewList(item)
                                                    }}
                                                  >
                                                    <div className='taskStepTitleRow'>
                                                      <img
                                                        // onClick={() => deleteProject(pitem)}
                                                        style={{
                                                          width: 16,
                                                          height: 16,
                                                          cursor: 'pointer'
                                                        }}
                                                        src={AddProjectIcon}
                                                      />

                                                      <div style={{ flex: 1 }}>
                                                        New list
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            )}

                                            <Droppable
                                              key={'projectList-' + item.id}
                                              droppableId={`${'projectList-' + item.id
                                                }`}
                                                type={"list"}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                 
                                                  {...provided.droppableProps}
                                                >
                                                  {item.project &&
                                                    item.project.length > 0 &&
                                                    item.project.map(
                                                      (pitem: any, i: any) => (
                                                        <Draggable
                                                         
                                                          key={`drag-projectList-id-${pitem.id}`}
                                                          draggableId={`dragprojectListid-${pitem.id}`}
                                                          index={i}
                                                          type={"list"}
                                                        >
                                                          {(provided, snapshot) => (
                                                            <div
                                                              ref={provided.innerRef}
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                            // style={getItemStyle(
                                                            //   snapshot.isDragging,
                                                            //   provided.draggableProps.style
                                                            // )}
                                                            >
                                                              <Droppable
                                                                key={
                                                                  'groupListItem-' +
                                                                  pitem.id
                                                                }
                                                                droppableId={`${'groupListItem-' +
                                                                  pitem.id
                                                                  }`}
                                                             type={`task`}
                                                              >
                                                                {(
                                                                  provided,
                                                                  snapshot
                                                                ) => (
                                                                  <div
                                                                    ref={
                                                                      provided.innerRef
                                                                    }
                                                                    // style={getListStyle(snapshot.isDraggingOver)}
                                                                    style={getListStyle(
                                                                      snapshot.isDraggingOver
                                                                    )}
                                                                    {...provided.droppableProps}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        background:
                                                                          pitem.color
                                                                      }}
                                                                      className={
                                                                        selectedProjectID ==
                                                                          pitem.id
                                                                          ? 'groupListsActive'
                                                                          : 'groupLists'
                                                                      }
                                                                      onClick={() => {
                                                                        setSelectedProject(
                                                                          pitem.id,
                                                                          pitem.projectName
                                                                        )
                                                                        // setTitleHeading(
                                                                        //   pitem.projectName
                                                                        // )
                                                                        setSelectedProjectGruopID(
                                                                          ''
                                                                        )
                                                                      }}
                                                                      onContextMenu={e => {
                                                                        e.preventDefault()
                                                                        setClicked(
                                                                          false
                                                                        )
                                                                        setListClicked(
                                                                          false
                                                                        )
                                                                        setProjectClicked(
                                                                          true
                                                                        )
                                                                        setPoints({
                                                                          x: e.pageX,
                                                                          y: e.pageY
                                                                        })
                                                                        setDataItem(
                                                                          pitem
                                                                        )
                                                                      }}
                                                                      onMouseEnter={() =>
                                                                        mouseEnterForGroupList(
                                                                          item,
                                                                          pitem
                                                                        )
                                                                      }
                                                                      onMouseLeave={() =>
                                                                        mouseLeaveForGroupList(
                                                                          item,
                                                                          pitem
                                                                        )
                                                                      }
                                                                    >
                                                                      <div
                                                                        className={
                                                                          snapshot.isDraggingOver
                                                                            ? 'taskStepTitleRowForBorder'
                                                                            : 'taskStepTitleRow'
                                                                        }
                                                                      >
                                                                        <img
                                                                          // onClick={() => deleteProject(pitem)}
                                                                          style={{
                                                                            width: 16,
                                                                            height: 16,
                                                                            cursor:
                                                                              'pointer'
                                                                          }}
                                                                          src={ListIcon}
                                                                        />

                                                                        <div
                                                                          style={{
                                                                            flex: 1
                                                                          }}
                                                                        >
                                                                          {editList?.id ==
                                                                            pitem.id ? (
                                                                            <input
                                                                              style={{
                                                                                width: 150
                                                                              }}
                                                                              onChange={e => {
                                                                                let editListData =
                                                                                {
                                                                                  ...editList
                                                                                }
                                                                                editListData.projectName =
                                                                                  e.target.value
                                                                                setEditList(
                                                                                  editListData
                                                                                )
                                                                              }}
                                                                              value={decData(
                                                                                editList.projectName,
                                                                                editList.isEncrypt
                                                                              )}
                                                                              onBlur={e => {
                                                                                console.log(
                                                                                  'asdfghj',
                                                                                  e
                                                                                )
                                                                                renameList(
                                                                                  editList,
                                                                                  i
                                                                                )
                                                                              }}
                                                                              onKeyDown={(
                                                                                event: any
                                                                              ) => {
                                                                                if (
                                                                                  event.key ===
                                                                                  'Enter'
                                                                                ) {
                                                                                  renameList(
                                                                                    editList,
                                                                                    i
                                                                                  )
                                                                                  console.log(
                                                                                    'User pressed: ',
                                                                                    event.key
                                                                                  )
                                                                                }
                                                                              }}
                                                                              autoFocus={
                                                                                true
                                                                              }
                                                                            />
                                                                          ) : (
                                                                            decData(
                                                                              pitem.projectName,
                                                                              pitem.isEncrypt
                                                                            )
                                                                          )}
                                                                        </div>
                                                                        <div className='listTaskCountdiv'>
                                                                          {pitem.isShowThreeDots ? (
                                                                            <div
                                                                              className='threeDots'
                                                                              onClick={e => {
                                                                                e.stopPropagation()
                                                                                e.preventDefault()
                                                                                setClicked(
                                                                                  false
                                                                                )
                                                                                setListClicked(
                                                                                  false
                                                                                )
                                                                                setProjectClicked(
                                                                                  true
                                                                                )
                                                                                setPoints(
                                                                                  {
                                                                                    x: e.pageX,
                                                                                    y: e.pageY
                                                                                  }
                                                                                )
                                                                                setDataItem(
                                                                                  pitem
                                                                                )
                                                                              }}
                                                                            >
                                                                              <img
                                                                                style={{
                                                                                  width: 20
                                                                                }}
                                                                                src={
                                                                                  ThreeDots
                                                                                }
                                                                                alt=''
                                                                              />
                                                                            </div>
                                                                          ) : (
                                                                            <span>
                                                                              {getTaskCountOfProject(
                                                                                pitem.id
                                                                              )}
                                                                            </span>
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    {
                                                                      provided.placeholder
                                                                    }
                                                                  </div>
                                                                )}
                                                              </Droppable>
                                                              {provided.placeholder}
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      )
                                                    )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </>
                                        )}
                                      </>
                                    </>
                                  ))}
                                  </>
                               /*  {providedlmo.placeholder}
                              </div>
                            )}
                          </Droppable> */

                        }

{provided.placeholder}
                                          </div>
                                        )}
                                      </Draggable>
                      </>
                    ))}



{provided.placeholder}
                            </div>
                          )}
                        </Droppable> 







                  <>
                    {listClicked && (
                      <div
                        className='rightListDiv'
                        style={{
                          top: points.y,
                          left: points.x
                        }}
                      >
                        <div
                          className='closeIconDiv'
                          onClick={() => setListClicked(false)}
                        >
                          <img src={CloseMenuListIcon} alt='' />
                        </div>
                        <ul>
                          <li
                            onClick={() => {
                              setEditList(dataItem)
                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={Rename} alt='' />
                            Rename List
                          </li>
                          <li className='customCursor'>
                            <img src={Rename} alt='' />
                            Share list
                          </li>
                          <li className='customCursor'>
                            <img
                              style={{
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={AddProjectIcon}
                              alt=''
                            />
                            Move List to
                          </li>
                          {/* <li
                          onClick={() => {
                            setColorPalette(true)
                            setHighlightFrom('list')
                          }}
                        >
                          <img src={MarkerIcon} alt='' />
                          Highlight list
                        </li> */}
                          <li
                            style={{
                              color: 'red',
                              borderTopWidth: 0.5,
                              borderTopColor: 'lightgray',
                              borderTopStyle: 'solid'
                            }}
                            onClick={() => handleProjectDeleteConfirm(dataItem)}
                          >
                            <img src={RedDelete} alt='' />
                            Delete list
                          </li>
                        </ul>
                      </div>
                    )}
                  </>

                  <>
                    {projectClicked && (
                      <div
                        className='rightListDiv'
                        style={{
                          top: points.y,
                          left: points.x
                        }} /* top={points.y} left={points.x} */
                      >
                        <div
                          className='closeIconDiv'
                          onClick={() => setProjectClicked(false)}
                        >
                          <img src={CloseMenuListIcon} alt='' />
                        </div>
                        <ul>
                          <li
                            onClick={() => {
                              setEditList(dataItem)

                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={Rename} alt='' />
                            Rename List
                          </li>
                          <li className='customCursor'>
                            <img src={Rename} alt='' />
                            Share list
                          </li>
                          <li className='customCursor'>
                            <img
                              style={{
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={AddProjectIcon}
                              alt=''
                            />
                            Move List to
                          </li>
                          {/* <li
                          onClick={() => {
                            setColorPalette(true)
                            setHighlightFrom('pitem')
                          }}
                        >
                          <img src={MarkerIcon} alt='' />
                          Highlight list
                        </li> */}
                          <li
                            style={{
                              color: 'red',
                              borderTopWidth: 0.5,
                              borderTopColor: 'lightgray',
                              borderTopStyle: 'solid'
                            }}
                            onClick={() => handleProjectDeleteConfirm(dataItem)}
                          >
                            <img src={RedDelete} alt='' />
                            Delete list
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                  <>
                    {colorPalette && (
                      <ClickAwayListener
                        onClickAway={e => {
                          e.preventDefault()
                          setColorPalette(false)
                        }}
                      >
                        <div
                          className='rightListDiv'
                          style={{
                            top: points.y,
                            left: points.x
                          }} /* top={points.y} left={points.x} */
                        >
                          <div
                            className='closeIconDiv'
                            onClick={() => setColorPalette(false)}
                          >
                            <img src={CloseMenuListIcon} alt='' />
                          </div>
                          <div className='highlightColorDiv'>
                            <span>Choose highlight color</span>
                            <div className='mainColorDiv'>
                              {colors &&
                                colors.map((item, index) => (
                                  <div className='colorUpperDiv'>
                                    <div
                                      className='colorDiv'
                                      style={{ background: item.colorCode }}
                                      onClick={() => {
                                        if (highlightFrom == 'list') {
                                          listHighlightColor(item)
                                        }
                                        if (highlightFrom == 'pitem') {
                                          projectListHighlightColor(item)
                                        }
                                        if (highlightFrom == 'project') {
                                          groupHighlightColor(item)
                                        }
                                        if (highlightFrom == 'task') {
                                          taskHighlightColor(item)
                                        }
                                      }}
                                    ></div>
                                  </div>
                                ))}
                              <div className='colorUpperDiv'>
                                <div
                                  className='colorDiv'
                                  style={{
                                    background: 'transparent',
                                    borderWidth: 1,
                                    borderColor: '#000',
                                    borderStyle: 'solid'
                                  }}
                                  onClick={() => {
                                    if (highlightFrom == 'list') {
                                      listHighlightColor({
                                        id: 11,
                                        colorCode: 'transparent'
                                      })
                                    }
                                    if (highlightFrom == 'pitem') {
                                      projectListHighlightColor({
                                        id: 11,
                                        colorCode: 'transparent'
                                      })
                                    }
                                    if (highlightFrom == 'project') {
                                      groupHighlightColor({
                                        id: 11,
                                        colorCode: 'transparent'
                                      })
                                    }
                                    if (highlightFrom == 'task') {
                                      taskHighlightColor({
                                        id: 11,
                                        colorCode: 'white'
                                      })
                                    }
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ClickAwayListener>
                    )}
                  </>
                  <>
                    {clicked && (
                      <div
                        className='rightListDiv'
                        style={{
                          top: points.y,
                          left: points.x
                        }} /* top={points.y} left={points.x} */
                      >
                        <div
                          className='closeIconDiv'
                          onClick={() => setClicked(false)}
                        >
                          <img src={CloseMenuListIcon} alt='' />
                        </div>
                        <ul>
                          <li
                            onClick={() => {
                              console.log('projectname1', dataItem)
                              addListIntoProject(dataItem)
                              openGroupOnNewListAdd(dataItem)
                            }}
                          >
                            <img
                              style={{
                                width: 14,
                                marginLeft: 3,
                                marginRight: 2
                              }}
                              src={AddProjectIcon}
                              alt=''
                            />
                            New list to project
                          </li>
                          <li
                            onClick={() => {
                              setEditGroup(dataItem)
                              console.log('projectname', dataItem)
                            }}
                          >
                            <img src={Rename} alt='' />
                            Rename project
                          </li>
                          {/* <li
                          onClick={() => {
                            console.log('projectname1', dataItem)
                            setColorPalette(true)
                            setHighlightFrom('project')
                          }}
                        >
                          <img
                            src={MarkerIcon}
                            alt=''
                          />
                          Highlight project
                        </li> */}
                          {dataItem.project && dataItem.project.length > 0 ? (
                            <li
                              style={{
                                color: 'red',
                                borderTopWidth: 0.5,
                                borderTopColor: 'lightgray',
                                borderTopStyle: 'solid'
                              }}
                              // onClick={() => unGroupProject(dataItem)}
                              onClick={() => deleteGroupUnderList(dataItem)}
                            >
                              {/* <img src={UnGroupListIcon} alt='' /> */}
                              {/* Unproject Lists */}
                              <img src={RedDelete} alt='' />
                              Delete project
                            </li>
                          ) : (
                            <li
                              style={{
                                color: 'red',
                                borderTopWidth: 0.5,
                                borderTopColor: 'lightgray',
                                borderTopStyle: 'solid'
                              }}
                              onClick={() => deleteGroup(dataItem)}
                            >
                              <img src={RedDelete} alt='' />
                              Delete project
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                  {/* 03/05 */}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  borderTopWidth: 0.5,
                  borderTopStyle: 'solid',
                  borderTopColor: 'lightgray',
                  position: 'absolute',
                  bottom: 0,
                  flexDirection: 'column'
                }}
              >
                {addList && (
                  <div
                    className='addTaskDrop'
                    style={{ marginLeft: 0, marginRight: 0, paddingRight: 0 }}
                  >
                    <img
                      style={{
                        width: 15,
                        height: 15,
                        cursor: 'pointer',
                        marginRight: 6
                      }}
                      src={AddProjectIcon}
                    />
                    <input
                      className='addProjectInput'
                      type='text'
                      name='name'
                      placeholder='Untitled List'
                      onBlur={handleBlurForList}
                      autoComplete='off'
                      autoFocus={true}
                      value={projectsInputValue}
                      onChange={handleChangeForProject}
                      onKeyDown={handleKeyDownForProject}
                    />
                    {projectsInputValue.length > 0 && (
                      <div
                        className='addBtn'
                        onClick={() => {
                          addProjectToList()
                          setProjectsInputValue('')
                          setAddList(false)
                        }} /* onClick={() => { console.log('test11221122') }} */
                      >
                        Add
                      </div>
                    )}
                  </div>
                )}
                {addProject && (
                  <div
                    className='addTaskDrop'
                    style={{ marginLeft: 0, marginRight: 0, paddingRight: 0 }}
                  >
                    <img
                      style={{
                        width: 16,
                        height: 16,
                        cursor: 'pointer',
                        marginRight: 6
                      }}
                      src={GroupIcon}
                    />
                    <input
                      className='addProjectInput'
                      type='text'
                      name='name'
                      placeholder='Untitled Project'
                      onBlur={handleBlurForProject}
                      autoComplete='off'
                      autoFocus={true}
                      value={groupInputValue}
                      onChange={handleChangeForGroup}
                      onKeyDown={handleKeyDownForGroup}
                    />
                    {groupInputValue.length > 0 && (
                      <div
                        className='addBtn'
                        onClick={() => {
                          addGroups()
                          setGroupInputValue('')
                          setAddProject(false)
                        }}
                      >
                        Add
                      </div>
                    )}
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    borderTopWidth: 0.5,
                    borderTopStyle: 'solid',
                    borderTopColor: 'lightgray',
                    justifyContent: 'space-between',
                    minWidth: 260,
                    alignItems: 'center'
                  }}
                >
                  <div
                    className='newListAndGroup'
                    style={{
                      marginTop: 4,
                      marginLeft: 0,
                      marginRight: 0,
                      paddingLeft: 16
                    }}
                    onClick={() => setAddList(true)}
                  >
                    <img
                      style={{ width: 14, height: 14, cursor: 'pointer' }}
                      src={AddProjectIcon}
                    />
                    <div style={{ color: '#646464', fontSize: 14 }}>
                      New List
                    </div>
                    {/* <input
                    className='addProjectInput'
                    type='text'
                    name='name'
                    placeholder='New List'
                    autoComplete='off'
                    value={projectsInputValue}
                    onChange={handleChangeForProject}
                    onKeyDown={handleKeyDownForProject}
                  /> */}
                    {/* {taskStepInputValue.length > 0 && <div className='addBtn'>Add</div>} */}
                  </div>
                  <div
                    className='newListAndGroup'
                    onClick={() => {
                      /* addGroups(); */ setAddProject(true)
                    }}
                    style={{
                      marginTop: 4,
                      marginLeft: 0,
                      marginRight: 0,
                      width: '15%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <img
                      style={{ width: 20, height: 20, cursor: 'pointer' }}
                      src={AddGroupIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DragDropContext>
       
      </div>
    </div>
  )
}

export default Extra;


