import React, { useEffect, useState } from 'react'
import styles from './AuthStyles'
import './Auth.css'
import { BASE_URL, encData, mixPanelTrack, post } from '../Services/Calls';
import { useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import LeftArrow from '../../assets/left-arrow.png' 
import RegisterSuccessIcon from '../../assets/registerSuccess.png' 
import AuthLoader from '../../assets/authLoader.png' 
import NickNameIcon from '../../assets/nickName.png' 
import NickNameActiveIcon from '../../assets/nickNameActive.png' 
import EmailIcon from '../../assets/emailIcon.png' 
import EmailActiveIcon from '../../assets/emailActiveIcon.png' 
import Padlock from '../../assets/padlock.png' 
import PadlockActive from '../../assets/padlockActive.png' 
import CodeIcon from '../../assets/code.png' 
import CodeActiveIcon from '../../assets/codeActive.png' 
import NewMSLogo from '../../assets/newMSLogo.png'
import CharacterWorkingLaptop from '../../assets/CharacterWorkingLaptop.png'
import CharacterCactus from '../../assets/characterCactus.png'
import GoogleIcon from '../../assets/googleIcon.png' 
import AppleIcon from '../../assets/appleIcon.png' 
import FacebookIcon from '../../assets/facebookIcon.png' 
import TwitterIcon from '../../assets/twitterIcon.png' 
import Cookies from '../Cookies/Cookies';
import RightBlackArrow from '../../assets/rightBlackArrow.png'
import { COLOR, COLORDARK } from '../Services/constants/Colors';

interface IProps {
  openAddAccountLogin: any
  setOpenAddAccountLogin: any
  openAddAccountSignup: any
  setOpenAddAccountSignUp: any
  mobileView: any
}

function SignUp ({ 
  openAddAccountLogin,
  setOpenAddAccountLogin,
  openAddAccountSignup,
  setOpenAddAccountSignUp,
  mobileView
  }: IProps) {
  const navigate = useNavigate();
  const [signUpData, setSignUpData]:any = useState({});
  const [firstNameMsg, setFirstNameMsg] = useState<string>("");
  const [lastNameMsg, setLastNameMsg] = useState<string>("");
  const [emailMsg, setEmailMsg] = useState<string>("");
  const [passwordMsg, setPasswordMsg] = useState<string>("");
  const [confirmPasswordMsg, setConfirmPasswordMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [otpDivShow, setOtpDivShow] = useState(false);
  const [registerSuccessDivShow, setRegisterSuccessDivShow] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [authCodeMsg, setAuthCodeMsg] = useState<string>("");

  const [loader, setLoader] = useState(false);
  const [isDark, setIsDark]: any = useState(mobileView?1:0);
  const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;
  const [nickNameInputFocus, setNickNameInputFocus] = useState(false);
  const [emailInputFocus, setEmailInputFocus] = useState(false);
  const [passwordInputFocus, setPasswordInputFocus] = useState(false);
  const [codeInputFocus, setCodeInputFocus] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [showAlreadyLine, setShowAlreadyLine] = useState(false);
  const [userData, setuserData]: any = useState({})






async function sendEmailConfirmation(userId,email){
  const data = {
    userid: userId,
    email: email
  };
  const response1 = await post(BASE_URL + '/sendemailconfirmationlink', data)
  if(response1.data.status == 'success'){
  }
}


  const handleSignUpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const form_data:any = e.currentTarget.elements;
    
    try {
      if (form_data[0].value == "") {
        setFirstNameMsg("Name can't be empty");
      }
      if (form_data[1].value == "") {
        setEmailMsg("Email address can't be empty");
      }
      if (form_data[1].value != "") {
        let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!pattern.test(form_data[1].value)) {
        setEmailMsg("Invalid email format");
        return;
        }
      }
      if (form_data[2].value == "") {
        setPasswordMsg("Password can't be empty");
      }
      if (form_data[2].value != "") {
        if(form_data[2].value.length < 6){
        setPasswordMsg("Password requires 6~64 characters long.");
        return;
      }
      }
      if (form_data[3].value == "") {
        setAuthCodeMsg("Auth code can't be empty");
      }
      if (
        form_data[0].value == "" ||
        form_data[1].value == "" ||
        form_data[2].value == "" ||
        form_data[3].value == "" 
      ) {
        return;
      }
     

      let splitName = form_data[0].value.split(' ')
      console.log('splitName',splitName)
      let getLastname = ""
      if(splitName && splitName.length>1){
        getLastname=splitName[1]
      }

      let tempEmail=form_data[1].value;
      tempEmail=tempEmail.toLowerCase();
      console.log('tempEmail',tempEmail)
      const data = {
        firstName: Base64.encode(splitName[0]),
        lastName: Base64.encode(getLastname),
        email: Base64.encode(tempEmail),
        password: Base64.encode(form_data[2].value),
        code: form_data[3].value,
      };
      console.log("data123456", data);
      setEmailAddress(data.email)
      setLoader(true)
      const response = await post(BASE_URL + '/registerv2', data)
      console.log('response23',response)
      if(response.data.status == 'success'){
        setLoader(false)
    mixPanelTrack("signUpSuccess",{"firstName": splitName[0],
      "lastName":getLastname,
      "email": tempEmail})
        let tempGetSwitchData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
        if(response && response.data && response.data.data && response.data.data.length>0){
          localStorage.setItem("user", JSON.stringify(response.data.data[0]));


          let tempSwitchData: any = []
          //let tempGetSwitchData = JSON.parse(localStorage.getItem('switchUserData') || '{}')
          if (!localStorage.getItem("switchUserData")) {
            let json = {
              userData: response.data.data[0],
              token: response.data.accessToken
            }
            tempSwitchData.push(json)
            localStorage.setItem("switchUserData", JSON.stringify(tempSwitchData))
          }
          else {
            let switchUserData = [...tempGetSwitchData]
            let status = "0"
            for (let i = 0; i < switchUserData.length; i++) {
              if (switchUserData[i].userData.id == response.data.data[0].id) {
                status = "1"
                break;
              }
            }
            if (status != "1") {
              let json = {
                userData: response.data.data[0],
                token: response.data.accessToken
              }
              switchUserData.push(json)
              localStorage.setItem("switchUserData", JSON.stringify(switchUserData))
            }
          }

          if(response && response.data && response.data.accessToken){
            localStorage.setItem("token", response.data.accessToken);
          }
          // sendEmailConfirmation(response.data.data[0].id,response.data.data[0].email)
        }
          
        localStorage.setItem("setCookie", "0");
        try{
        localStorage.removeItem("project")
      }
      catch(ex){
        
      }
        if (tempGetSwitchData && tempGetSwitchData.length > 0) {
          // navigate('/webapp')
          window.location.reload();
        }
        else {
          window.location.reload();
        }
        
      }
      else{
        mixPanelTrack("signUpFailed",{"firstName": splitName[0],
      "lastName":getLastname,
      "email": tempEmail,
      "errorMessage": response.data.message,
    })
        setErrorMsg(response.data.message)
        if(response.data.message == 'Your auth code is invalid.'){
          setAuthCodeMsg('Your auth code is invalid.')
        }
        if(response.data.message == 'Email ID already exists.'){
           setAlreadyExist(true)
           setShowAlreadyLine(true)
        }
        setLoader(false)
      }

    } catch (ex) {
      console.log('response',ex)
      setLoader(false)
    }
  };
  
  useEffect(() => {
    mixPanelTrack("signUpView",{"signUpView":"signUpView"})
    let user = JSON.parse(localStorage.getItem('user') || '{}')
    setuserData(user)
  }, [])

  return (
    <>
     {/* {userData && userData.id && localStorage.getItem("token") &&
        <div className='loginTopSwicthView'>
          <div onClick={() => navigate('/webapp')}>
            <div>
              Back to {Base64.decode(userData.email)} account.
            </div>
            <div>
              <img style={{ width: 25 }} src={RightBlackArrow} alt="" />
            </div>
          </div>
        </div>
      } */}
      <div style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor,  height:"100vh", display:mobileView?"unset":"flex", justifyContent:"center"}:{}}>
    <div className={userData && userData.id && localStorage.getItem("token") ? 'container-main-switch' :'container-main'}
    style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:mobileView?"unset":colorSchemes.FontBlackColor, margin:mobileView?"unset":"50px auto", position: (userData && userData.id && localStorage.getItem("token") && !mobileView) || (userData && userData.id && localStorage.getItem("token") && mobileView)?"unset":"absolute",
    height: localStorage.getItem("token")==null?"100%":"unset",
    overflowY: "auto", paddingBottom:"10%"}:{}} >
    {userData && userData.id && localStorage.getItem("token")? null :
      <>
        {localStorage.getItem("setCookie") == null && <Cookies />}
        </>
    }
      {/* {userData && userData.id && localStorage.getItem("token") ?
        <>
        {!mobileView &&
          <h3 style={{ textAlign: 'center', fontWeight: '600'}}>Switch Accounts</h3>
        }
          </>
        :
        null
          // <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 36 }}>ManageStuff</h2>
    } */}
    {(localStorage.getItem("token")==null) && mobileView ?
           <div style={{ textAlign: 'center', marginBottom:"5%", padding : "10% 0%", background:"rgba(48, 104, 200, 1)", width:"100%", 
           borderBottomLeftRadius:"20px", borderBottomRightRadius:"20px"}}>
            <img style={{ width: "180px" }} src={NewMSLogo} alt="" />
            </div>:null
        }
       <div className={userData && userData.id && localStorage.getItem("token")?'auth-container-box-switch':'auth-container-box'} 
       style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:mobileView?"unset": colorSchemes.FontBlackColor, border:mobileView?"unset": "1px solid "+colorSchemes.FontGreyColor, height:mobileView?"unset": 600,width:mobileView?"unset":920}:{height:mobileView?"unset":600,width:mobileView?"unset":920}}>
        
{!registerSuccessDivShow ? 
<>

<div style={{width:mobileView?"100%": "45%", padding: mobileView?"unset":"40px 50px 32px"}}>
            <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 10, fontSize:mobileView?20: 32,fontFamily:'ManRopeSemiBold' }}>Get Started</h2>
            <div style={{ textAlign: 'center', marginBottom: 26,  fontSize:mobileView?15:16,fontFamily:'ManRope',color:'rgba(23, 45, 70, 1)'  }}>Welcome now! Please enter your details.</div>

        <form onSubmit={handleSignUpSubmit}>
           <div className={nickNameInputFocus?'inputContainerDivActive':'inputContainerDiv'}
           style={isDark==1 && nickNameInputFocus? {borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid #4772fa"}: isDark==1?{borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid "+colorSchemes.FontGreyColor}:{}}>
              <div style={{position: "absolute", marginTop: "-50px", background: "#fff", padding: "0px 5px" ,fontSize: "14px", color: emailInputFocus? '#4772fa' : "#828B94",marginLeft:10}}>Username*</div>
           {/* {nickNameInputFocus?
<img style={{width:20}} src={NickNameActiveIcon} alt="" />
:
<img style={{width:20}} src={NickNameIcon} alt="" />
} */}
<div style={{width:'100%'}}>
    <input className='inputFeild' type="text" name="Nickname" placeholder='Enter your username' maxLength={30} onFocus={() => {setFirstNameMsg("");setErrorMsg("");setNickNameInputFocus(true);setShowAlreadyLine(true)}} onBlur={()=>setNickNameInputFocus(false)}
    style={isDark==1? {backgroundColor:mobileView?"transparent":colorSchemes.DrawerBackColor, color:mobileView?"unset":colorSchemes.FontBlackColor}:{}} />
    {/* <span style={styles.inputErrorMsg}>{firstNameMsg}</span> */}
</div>
           </div>
           <div className='errorMsgLine' style={{minHeight:30}}><span style={styles.inputErrorMsg}>{firstNameMsg}</span></div>

           <div className={emailInputFocus?'inputContainerDivActive':'inputContainerDiv'}
           style={isDark==1 && emailInputFocus? {borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid #4772fa"}: isDark==1?{borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid "+colorSchemes.FontGreyColor}:{}}>
              <div style={{position: "absolute", marginTop: "-50px", background: "#fff", padding: "0px 5px" ,fontSize: "14px", color: emailInputFocus? '#4772fa' : "#828B94",marginLeft:10}}>Email*</div>
           {/* {emailInputFocus?
<img style={{width:20}} src={EmailActiveIcon} alt="" />
:
<img style={{width:20}} src={EmailIcon} alt="" />
} */}
<div style={{width:'100%'}}>
<input className='inputFeild' type="text" name="email" placeholder='Enter your email' onChange={()=>{setAlreadyExist(false);setShowAlreadyLine(false)}} onFocus={() => {setEmailMsg("");setErrorMsg("");setEmailInputFocus(true);setShowAlreadyLine(false)}} onBlur={(e)=>{setEmailInputFocus(false);
if (e.target.value != "") {
  let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  if (!pattern.test(e.target.value)) {
  setEmailMsg("Invalid email format");
  }
}
}} 
style={isDark==1? {backgroundColor:mobileView?"transparent":colorSchemes.DrawerBackColor, color:mobileView?"unset":colorSchemes.FontBlackColor}:{}} />
</div>
           </div>
           <div className='errorMsgLine' style={{minHeight:30}}>
             {showAlreadyLine && <>
             {alreadyExist && 
             <>
              {userData && userData.id && localStorage.getItem("token") ?
               <span style={styles.inputErrorMsg}>This email address is already registered.</span> 
               :
             <span style={styles.inputErrorMsg}>This email address is already registered, please <span className='makeLink' style={{fontSize:13}} onClick={() => navigate("/login")}>Sign In</span> or <span className='makeLink' style={{fontSize:13}} onClick={() => navigate("/login/requestRestPassword")}>reset password</span>.</span>
}
             </>
                         }
                          </>}
            <span style={styles.inputErrorMsg}>{emailMsg}</span>
             </div>

           <div className={passwordInputFocus?'inputContainerDivActive':'inputContainerDiv'}
           style={isDark==1 && passwordInputFocus? {borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid #4772fa"}: isDark==1?{borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid "+colorSchemes.FontGreyColor}:{}}>
             <div style={{position: "absolute", marginTop: "-50px", background: "#fff", padding: "0px 5px" ,fontSize: "14px", color: emailInputFocus? '#4772fa' : "#828B94",marginLeft:10}}>Password</div>
           {/* {passwordInputFocus?
<img style={{width:20}} src={PadlockActive} alt="" />
:
<img style={{width:20}} src={Padlock} alt="" />
} */}
<div style={{width:'100%'}}>
<input className='inputFeild' type="password" name="password" placeholder="Password"  maxLength={64} onFocus={() => {setPasswordMsg("");setErrorMsg("");setPasswordInputFocus(true);setShowAlreadyLine(true)}} onBlur={(e)=>{setPasswordInputFocus(false);
  if (e.target.value != "") {
        if(e.target.value.length < 6){
        setPasswordMsg("Password requires 6~64 characters long.");
      }}
}}
style={isDark==1? {backgroundColor:mobileView?"transparent":colorSchemes.DrawerBackColor, color:mobileView?"unset":colorSchemes.FontBlackColor}:{}}/>
</div>
           </div>
           <div className='errorMsgLine' style={{minHeight:30}}><span style={styles.inputErrorMsg}>{passwordMsg}</span></div>

           <div className={codeInputFocus?'inputContainerDivActive':'inputContainerDiv'}
           style={isDark==1 && codeInputFocus? {borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid #4772fa"}: isDark==1?{borderBottom:mobileView?"1px solid rgba(208, 213, 221, 1)":"2px solid "+colorSchemes.FontGreyColor}:{}}>
             <div style={{position: "absolute", marginTop: "-50px", background: "#fff", padding: "0px 5px" ,fontSize: "14px", color: emailInputFocus? '#4772fa' : "#828B94",marginLeft:10}}>Auth Code*</div>
           {/* {codeInputFocus?
<img style={{width:20}} src={CodeActiveIcon} alt="" />
:
<img style={{width:20}} src={CodeIcon} alt="" />
} */}
<div style={{width:'100%'}}>
<input className='inputFeild' type="number" name="otp" placeholder='Enter Auth Code Here...' onFocus={() => {setAuthCodeMsg("");setErrorMsg("");setCodeInputFocus(true);setShowAlreadyLine(true)}} onBlur={()=>setCodeInputFocus(false)}
style={isDark==1? {backgroundColor:mobileView?"transparent":colorSchemes.DrawerBackColor, color:mobileView?"unset":colorSchemes.FontBlackColor}:{}}/>
</div>
           </div>
           <div className='errorMsgLine' style={{minHeight:30}}><span style={styles.inputErrorMsg}>{authCodeMsg}</span></div>
           {userData && userData.id && localStorage.getItem("token") ?
    null :
        <div className='IAgreeLine' style={isDark==1? {color:mobileView?"unset":colorSchemes.FontBlackColor, marginBottom:"20px"}:{marginBottom:"20px"}}>
          I agree to the <span className='makeLink' style={{fontSize:12}}>Terms</span> of service and <span className='makeLink' style={{fontSize:12}}>Privacy</span> & <span className='makeLink' style={{fontSize:12}}>Security</span> Policy.
       <span style={{color:'#ff3180',opacity:.6}}> By signing up and continuing to use this website, you consent to the use of cookies in accordance with our </span> <span className='makeLink' style={{fontSize:12}}>Cookie Policy.</span>
        </div>
  }
    {loader ?
    <button className='authBtnLoader' style={{marginTop:0}}>
    Sign Up...
    </button>
  :
        <button className='authBtn' type="submit" style={{marginTop:0}}>
        Sign Up
        </button>
}

</form>


<div style={{textAlign:'center',marginTop:userData && userData.id && localStorage.getItem("token")?10:10,fontSize:14}}>
Do you have an account? <span className='makeLink' onClick={() =>{ if (userData && userData.id && localStorage.getItem("token")) { setOpenAddAccountSignUp(false) } else { navigate("/login") } }}>Sign In</span>
</div>
</div>

<div style={{width: '15%',height: '100%',display: (userData && userData.id && localStorage.getItem("token") && !mobileView) || mobileView?'none': 'flex', justifyContent: 'flex-start',alignItems: 'flex-end'}}>
<img style={{/* width:"65%" */ scale:'0.9',marginBottom: '-25px', marginLeft: '-60px' }} src={CharacterCactus} alt="" />
</div>

            <div style={{display: (userData && userData.id && localStorage.getItem("token") && !mobileView) || mobileView?'none': 'block', backgroundColor:"green", width:"40%", height:"100%", background: "rgba(48, 104, 200, 1)", borderRadius:"40px"}}>
            <div style={{ textAlign: 'center', marginTop:"8%", marginBottom:"5%"}}><img style={{ /* width: 20 */ }} src={NewMSLogo} alt="" /></div>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color:"#ffffff", fontSize: 32 }}>Your Stuff, Your Way,</div>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color:"#ffffff", fontSize: 32 }}>Your Success</div>
            
            </div>
</>

:
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',gap:50}}>
  <img src={RegisterSuccessIcon} alt="" />
  <h2>Registration Successful!</h2>
  <button style={{...styles.Button,marginTop:0,padding:'10px',fontSize:18}} onClick={() => navigate("/login")}>
       Go To Login
        </button>
</div>
}

       </div>
    </div>
    </div>
    </>
  )
}

export default SignUp
