import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css'
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
/* import Login from '../Auth/Login';
import SignUp from '../Auth/SignUp'; */
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Mic from '../../assets/mic.png'
import Attachment from '../../assets/attachment.png'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import AuthLoader from '../../assets/authLoader.png'
import PlayAudio from '../../assets/playbutton.png'
import DownloadAudio from '../../assets/downloading.png'
//import { setTimeout } from 'timers';
import { BASE_URL, decData, encData, mixPanelTrack, post, postUser } from '../Services/Calls'


interface IProps {
  aiDialog: any
  setAIDialog: any
  userId: any
  getProjectWithGroup: any
  projectData: any
  userData: any
  colorSchemes: any
  isDark: any
  mobileView: any
  sendSocketMessage: any
  isLightMode: any
  setTaskInputValue: any
  groupData: any
  taskData:any
  postTask: any
  moveTaskToList: any
  addListIntoProjectForAI: any 
  updategroupprojectOrdersForAI:any
  updategroupOrdersForAI:any
  updateTaskOrderForAI: any
  addGroupsForAI: any

}


function AIDialogNew(
  {
    aiDialog,
    setAIDialog,
    userId,
    getProjectWithGroup,
    projectData,
    userData,
    colorSchemes,
    isDark,
    mobileView,
    sendSocketMessage,
    isLightMode,
    setTaskInputValue,
    groupData,
    taskData,
    postTask,
    moveTaskToList,
    addListIntoProjectForAI,
    updategroupprojectOrdersForAI,
    updategroupOrdersForAI,
    updateTaskOrderForAI,
    addGroupsForAI,
  }: IProps
) {
  enum MODEL {
    GPT3_TURBO = 'gpt-3.5-turbo',
    GPT3 = 'gpt-3',
    TTS1 = 'tts-1',
  }
  interface OpenAIFetchProps {
    choices: [
      {
        message: {
          content: string;
          role: string;
        }
        index: number;
      }
    ];
    created: number;
    id: string;
    model: string;
    object: string;
  }
  const SPEECH_LANGUAGE = 'en-US';


  const [transcription, setTranscription] = useState('');
  const [isListening, setIsListening] = useState(false);
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [isSupported, setIsSupported] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const firstTimeApp = useRef(true);


  const handleListen = () => {
    SpeechRecognition.startListening({ language: SPEECH_LANGUAGE/* , continuous: true */ });
    setTranscription(transcript);

  }




  const aiProcess = async(userInput) => {
  //  userInput = "add tomatoes22 to the p12 list";
    if (userInput != "") {
      if (userInput.includes("add ") && userInput.includes(" to the ")) {
        let substring = userInput.split(" to the ");

        let taskNameArray = substring[0].split(' ');
        taskNameArray = taskNameArray.filter(function (a) { return a !== 'add' });
        let taskName = taskNameArray.join(" ")
        console.log("taskName", taskName)

        let listNameArray = substring[1].split(' ');
        listNameArray = listNameArray.filter(function (a) { return a !== 'list' && a !== 'the' });
        let listName = listNameArray.join("")
        console.log("listname", listName)

        console.log('projectdata', projectData)
        console.log('groupData', groupData)


        let selectedListId: any = [];



        for (let j = 0; j < groupData.length; j++) {
          for (let i = 0; i < groupData[j].project.length; i++) {
            let lname = decData(groupData[j].project[i].projectName, '1');
            lname = lname.replace(' ', '');
            lname = lname.replace('-', '');
            if (lname.toLocaleLowerCase() == listName.toLocaleLowerCase()) {
              selectedListId.push({ 'groupId': groupData[j].id, 'projectId': groupData[j].project[i].id })
            }
          }
        }


        if (selectedListId.length == 1) {
          const groupBox = document.getElementById('group' + selectedListId[0].groupId);
          if (groupBox != null)
          {
            let isOpen=false;
            for (let j = 0; j < groupData.length; j++) {
              if(groupData[j].id==selectedListId[0].groupId && groupData[j].open)
              {
                isOpen=true;
              }
            }
            if(isOpen)
            {

            }
            else
            {
            groupBox.click()
            }
          }
          setTimeout(() => {
            const box = document.getElementById('list' + selectedListId[0].projectId);
            if (box != null)
              box.click()
            setTimeout(() => {
              postTask(taskName, selectedListId[0].projectId)
              setTaskInputValue('')
            }, 1000);
          }, 500);

        }
        else if(selectedListId.length>1)
        {
          setMessage("Multiple list found with list name "+listNameArray.join(" "))
        }
        else if(selectedListId.length==0)
        {
          setMessage("No list found with list name "+listNameArray.join(" "))
        }


        


      }
      else if (userInput.includes("move ") && userInput.includes(" to the ")) {
        let substring = userInput.split(" to the ");

        let taskNameArray = substring[0].split(' ');
        taskNameArray = taskNameArray.filter(function (a) { return a !== 'move' });
        let taskName = taskNameArray.join(" ")
        console.log("taskName", taskName)

        let listNameArray = substring[1].split(' ');
        listNameArray = listNameArray.filter(function (a) { return a !== 'list' && a !== 'the' });
        let listName = listNameArray.join("")
        console.log("listname", listName)

        console.log('projectdata', projectData)
        console.log('groupData', groupData)


        let selectedListId: any = [];
        let selectedListData: any = {};



        for (let j = 0; j < groupData.length; j++) {
          for (let i = 0; i < groupData[j].project.length; i++) {
            let lname = decData(groupData[j].project[i].projectName, '1');
            lname = lname.replace(' ', '');
            lname = lname.replace('-', '');
            if (lname.toLocaleLowerCase() == listName.toLocaleLowerCase()) {
              selectedListData=groupData[j].project[i];
              selectedListId.push({ 'groupId': groupData[j].id, 'projectId': groupData[j].project[i].id })
            }
          }
        }


        if (selectedListId.length == 1) {
          let tempTaskItem:any=[];
          for (let j = 0; j < taskData[0].length; j++) {
              let name = decData(taskData[0][j].taskName, '1');
              name = name.replace(' ', '');
              name = name.replace('-', '');
              if (name.toLocaleLowerCase() == taskName.toLocaleLowerCase()) {
                tempTaskItem.push(taskData[0][j])
              }
            
          }
          if(tempTaskItem.length==1)
          {
          const groupBox = document.getElementById('group' + selectedListId[0].groupId);
          if (groupBox != null)
          {
            let isOpen=false;
            for (let j = 0; j < groupData.length; j++) {
              if(groupData[j].id==selectedListId[0].groupId && groupData[j].open)
              {
                isOpen=true;
              }
            }
            if(isOpen)
            {

            }
            else
            {
            groupBox.click()
            }

          }
            //groupBox.click()
          setTimeout(() => {
            const box = document.getElementById('list' + selectedListId[0].projectId);
            if (box != null)
              box.click()
            setTimeout(() => {
              moveTaskToList(selectedListData, tempTaskItem[0])
              //postTask(taskName, selectedListId[0].projectId)
              setTaskInputValue('')
            }, 1000);
          }, 500);
        }

          else if(tempTaskItem.length>1)
        {
          setMessage("Multiple task found with this name "+taskName )
        }
        else if(tempTaskItem.length==0)
        {
          setMessage("No task found with this name "+taskName)
        }

        }
        else if(selectedListId.length>1)
        {
          setMessage("Multiple list found with list name "+listNameArray.join(" "))
        }
        else if(selectedListId.length==0)
        {
          setMessage("No list found with list name "+listNameArray.join(" "))
        }


        


      }
      else if (userInput.includes("create ") && userInput.includes(" to the ")) {
        let substring = userInput.split(" to the ");

        let listNameArray = substring[0].split(' ');
        listNameArray = listNameArray.filter(function (a) { return a !== 'create' && a !== 'list' });
        let listName = listNameArray.join(" ")
        console.log("listName", listName)

        let folderNameArray = substring[1].split(' ');
        folderNameArray = folderNameArray.filter(function (a) { return a !== 'folder' && a !== 'the' });
        let folderName = folderNameArray.join("")
        console.log("folder", folderName)

        console.log('projectdata', projectData)
        console.log('groupData', groupData)


        let selectedListId: any = [];
        let selectedFolderId: any = [];
        let selectedFolderData: any = [];



        for (let j = 0; j < groupData.length; j++) {
            let lname = decData(groupData[j].groupName, '1');
            lname = lname.replace(' ', '');
            lname = lname.replace('-', '');
            if (lname.toLocaleLowerCase() == folderName.toLocaleLowerCase()) {
              selectedFolderData.push(groupData[j]);
            }
          
        }
        console.log("selectedFolderData", selectedFolderData)


        if (selectedFolderData.length == 1) {
          const groupBox = document.getElementById('group' + selectedFolderData[0].id);
          if (groupBox != null)
          {
            let isOpen=false;
            for (let j = 0; j < groupData.length; j++) {
              if(groupData[j].id==selectedFolderData[0].id && groupData[j].open)
              {
                isOpen=true;
              }
            }
            if(isOpen)
            {

            }
            else
            {
            groupBox.click()
            }

          }
            //groupBox.click()
          setTimeout(() => { 
            addListIntoProjectForAI(selectedFolderData[0], listName, 0)
              //postTask(taskName, selectedListId[0].projectId)
              setTaskInputValue('')
          }, 500);
        
        }
        else if(selectedFolderData.length>1)
        {
          setMessage("Multiple folder found with name "+folderNameArray.join(" "))
        }
        else if(selectedFolderData.length==0)
        {
          setMessage("No folder found with name "+folderNameArray.join(" "))
        }


        


      }
      else if (userInput.includes("create ") && userInput.includes(" folder")) {
        let substring = userInput.split(" folder");

        let folderNameArray = substring[0].split(' ');
        folderNameArray = folderNameArray.filter(function (a) { return a !== 'create' && a !== 'folder' });
        let folderName = folderNameArray.join(" ")
        console.log("folderName", folderName)
        

        console.log('projectdata', projectData)
        console.log('groupData', groupData)

        if(folderName.trim()!="")
        {
        setLoading(true)
        await addGroupsForAI(folderName);
        setLoading(false)
        setTaskInputValue('')
        setAIDialog(false)
        }


      }
      else if ((userInput.includes("short ") || userInput.includes("sort ")) && userInput.includes(" folders by ")) {
        let substring = userInput.split(" folders by ");

        /* let taskNameArray = substring[0].split(' ');
        taskNameArray = taskNameArray.filter(function (a) { return a !== 'move' });
        let taskName = taskNameArray.join(" ")
        console.log("taskName", taskName) */
        let sortByArray = substring[1].split(' ');
        sortByArray = sortByArray.filter(function (a) { return a !== 'by' && a !== 'the' && a !== 'folder' && a !== 'folders' });
        let sortByName = sortByArray.join("")
        console.log("sortByName", sortByName)

        console.log('projectdata', projectData)
        console.log('groupData', groupData)


       
        let selectedFolderData: any = [];


        if(groupData.length==0)
        {
          setMessage("No folders found.")
        return;
          
        }

        for (let j = 0; j < groupData.length; j++) {
            let name = decData(groupData[j].groupName, '1');
            let tempItem=  {...groupData[j]} 
              tempItem.sortName = name;
              selectedFolderData.push(tempItem)
          
        }


        if (selectedFolderData.length >0) {
          let tempFolderItem:any=[...selectedFolderData];          
          
         
          console.log("tempListItem1", tempFolderItem)
          let  tempFolderItem2:any=[];
          if(sortByName.toLocaleLowerCase()=='ascending')
          {
            tempFolderItem2=tempFolderItem.sort((a, b) => (a.sortName.localeCompare(b.sortName)));
          }
          else if(sortByName.toLocaleLowerCase()=='descending')
          {
            tempFolderItem2=tempFolderItem.sort((a, b) => (b.sortName.localeCompare(a.sortName)));
          }
          if(tempFolderItem2.length>0)
          {
         //let  tempTaskItem2=tempTaskItem.sort((a, b) => (b.sortName.localeCompare(a.sortName)));
          console.log("tempTaskItem2", tempFolderItem2)
            setLoading(true)
           await updategroupOrdersForAI(tempFolderItem2)
           setLoading(false)
           setAIDialog(false)
           setTaskInputValue('')          
        }
        else
        {
          setMessage("Command is not in proper format.")
        } 

        }
        else if(selectedFolderData.length>1)
        {
          setMessage("No folders found.")
        }


        


      }
      else if ((userInput.includes("short ") || userInput.includes("sort ")) && userInput.includes(" list by ")) {
        let substring = userInput.split(" list by ");

        /* let taskNameArray = substring[0].split(' ');
        taskNameArray = taskNameArray.filter(function (a) { return a !== 'move' });
        let taskName = taskNameArray.join(" ")
        console.log("taskName", taskName) */

        let folderNameArray = substring[0].split(' ');
        folderNameArray = folderNameArray.filter(function (a) { return a !== 'list' && a !== 'the' && a !== 'short' && a !== 'sort' && a !== 'folder' });
        let folderName = folderNameArray.join("")
        console.log("listname", folderName)

        let sortByArray = substring[1].split(' ');
        sortByArray = sortByArray.filter(function (a) { return a !== 'by' && a !== 'the' && a !== 'list' });
        let sortByName = sortByArray.join("")
        console.log("sortByName", sortByName)

        console.log('projectdata', projectData)
        console.log('groupData', groupData)


       
        let selectedFolderData: any = [];



        for (let j = 0; j < groupData.length; j++) {
            let lname = decData(groupData[j].groupName, '1');
            lname = lname.replace(' ', '');
            lname = lname.replace('-', '');
            if (lname.toLocaleLowerCase() == folderName.toLocaleLowerCase()) {
              selectedFolderData.push(groupData[j])
            }
          
        }


        if (selectedFolderData.length == 1) {
          let tempListItem:any=[];
          
          for (let j = 0; j < selectedFolderData[0].project.length; j++) {
           
              let name = decData(selectedFolderData[0].project[j].projectName, '1');
              let tempItem=  {...selectedFolderData[0].project[j]} 
              tempItem.sortName = name;       
              tempListItem.push(tempItem)
            
          }
          if(tempListItem.length==0)
          {
            setMessage("No list found in folder.")
            return;
          }
          console.log("tempListItem1", tempListItem)
          let  tempListItem2:any=[];
          if(sortByName.toLocaleLowerCase()=='ascending')
          {
            tempListItem2=tempListItem.sort((a, b) => (a.sortName.localeCompare(b.sortName)));
          }
          else if(sortByName.toLocaleLowerCase()=='descending')
          {
            tempListItem2=tempListItem.sort((a, b) => (b.sortName.localeCompare(a.sortName)));
          }
          if(tempListItem2.length>0)
          {
         //let  tempTaskItem2=tempTaskItem.sort((a, b) => (b.sortName.localeCompare(a.sortName)));
          console.log("tempTaskItem2", tempListItem2)
          const groupBox = document.getElementById('group' + selectedFolderData[0].id);
          if (groupBox != null)
          {
            let isOpen=false;
            for (let j = 0; j < groupData.length; j++) {
              if(groupData[j].id==selectedFolderData[0].id && groupData[j].open)
              {
                isOpen=true;
              }
            }
            if(isOpen)
            {

            }
            else
            {
            groupBox.click()
            }

          }
            //groupBox.click()
            setLoading(true)
           await updategroupprojectOrdersForAI(tempListItem2, selectedFolderData[0].id)
           setLoading(false)
           setTaskInputValue('')
           setAIDialog(false)          
        }
        else
        {
          setMessage("Command is not in proper format.")
        } 

        }
        else if(selectedFolderData.length>1)
        {
          setMessage("Multiple folders found with name "+folderNameArray.join(" "))
        }
        else if(selectedFolderData.length==0)
        {
          setMessage("No folder found with name "+folderNameArray.join(" "))
        }


        


      }
      else if ((userInput.includes("short ") || userInput.includes("sort ")) && userInput.includes(" by ")) {
        let substring = userInput.split(" by ");

        /* let taskNameArray = substring[0].split(' ');
        taskNameArray = taskNameArray.filter(function (a) { return a !== 'move' });
        let taskName = taskNameArray.join(" ")
        console.log("taskName", taskName) */

        let listNameArray = substring[0].split(' ');
        listNameArray = listNameArray.filter(function (a) { return a !== 'list' && a !== 'the' && a !== 'short' && a !== 'sort' });
        let listName = listNameArray.join("")
        console.log("listname", listName)

        let sortByArray = substring[1].split(' ');
        sortByArray = sortByArray.filter(function (a) { return a !== 'by' && a !== 'the' });
        let sortByName = sortByArray.join("")
        console.log("sortByName", sortByName)

        console.log('projectdata', projectData)
        console.log('groupData', groupData)


        let selectedListId: any = [];
        let selectedListData: any = {};



        for (let j = 0; j < groupData.length; j++) {
          for (let i = 0; i < groupData[j].project.length; i++) {
            let lname = decData(groupData[j].project[i].projectName, '1');
            lname = lname.replace(' ', '');
            lname = lname.replace('-', '');
            if (lname.toLocaleLowerCase() == listName.toLocaleLowerCase()) {
              selectedListData=groupData[j].project[i];
              selectedListId.push({ 'groupId': groupData[j].id, 'projectId': groupData[j].project[i].id })
            }
          }
        }


        if (selectedListId.length == 1) {
          let tempTaskItem:any=[];
          for (let j = 0; j < taskData[0].length; j++) {
            if(taskData[0][j].projectId == selectedListId[0].projectId)
            {
              let name = decData(taskData[0][j].taskName, '1');
              let tempItem=  {...taskData[0][j]} 
              tempItem.sortName = name;       
                tempTaskItem.push(tempItem)
            }
            
          }
          if(tempTaskItem.length==0)
          {
            setMessage("No task found in list.")
            return;
          }
          console.log("tempTaskItem1", tempTaskItem)
          let  tempTaskItem2:any=[];
          if(sortByName.toLocaleLowerCase()=='ascending')
          {
            tempTaskItem2=tempTaskItem.sort((a, b) => (a.sortName.localeCompare(b.sortName)));
          }
          else if(sortByName.toLocaleLowerCase()=='descending')
          {
            tempTaskItem2=tempTaskItem.sort((a, b) => (b.sortName.localeCompare(a.sortName)));
          }
          if(tempTaskItem2.length>0)
          {
         //let  tempTaskItem2=tempTaskItem.sort((a, b) => (b.sortName.localeCompare(a.sortName)));
          console.log("tempTaskItem2", tempTaskItem2)
          const groupBox = document.getElementById('group' + selectedListId[0].groupId);
          if (groupBox != null)
          {
            let isOpen=false;
            for (let j = 0; j < groupData.length; j++) {
              if(groupData[j].id==selectedListId[0].groupId && groupData[j].open)
              {
                isOpen=true;
              }
            }
            if(isOpen)
            {

            }
            else
            {
            groupBox.click()
            }

          }
            //groupBox.click()
            setLoading(true)
           await updateTaskOrderForAI(tempTaskItem2, selectedListId[0].projectId)
           setLoading(false)
              setTaskInputValue('')
              setAIDialog(false)
          setTimeout(() => {
            const box = document.getElementById('list' + selectedListId[0].projectId);
            if (box != null)
              box.click()
            /* setTimeout(() => {
              moveTaskToList(selectedListData, tempTaskItem[0])
              //postTask(taskName, selectedListId[0].projectId)
              setTaskInputValue('')
            }, 1000); */
          }, 500);
        }
        else
        {
          setMessage("Command is not in proper format.")
        } 

        }
        else if(selectedListId.length>1)
        {
          setMessage("Multiple list found with list name "+listNameArray.join(" "))
        }
        else if(selectedListId.length==0)
        {
          setMessage("No list found with list name "+listNameArray.join(" "))
        }


        


      }


      else {
        setTaskInputValue(userInput);
        setAIDialog(false)
      }


    }

  };

  const handleStopListen = () => {
    let a = transcript
    setTranscription(transcript);
    console.log("transcript", transcript)
    resetTranscript();
    SpeechRecognition.stopListening();
    /*  if(a!='') */
    aiProcess(a)
    // setTaskInputValue(a)
    //setAIDialog(false)
  }

  const toggleListen = () => {
    setIsListening(!isListening);

    if (!isListening) {
      setMessage('')
      handleListen();
    } else {
      handleStopListen();
      //fetchGPT().then(handleTextToSpeech);
    }
  }













  /* 
    useEffect(() => {
     if(transcript!='')
     setTaskInputValue(transcript)
    }, [transcript]); */


  useEffect(() => {
    setIsSupported(SpeechRecognition.browserSupportsSpeechRecognition());

  }, []);
  useEffect(() => {
    console.log('listening', listening)

    if (!listening && !firstTimeApp.current) {
      console.log('test')
      setIsListening(false);
      handleStopListen()
    }
    if (firstTimeApp.current) {
      firstTimeApp.current = false;
      toggleListen()

    }
  }, [listening]);

  return (
    <div>
      <Dialog
        open={aiDialog}
        onClose={() => { setAIDialog(false); }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <ClickAwayListener onClickAway={() => { setAIDialog(false); }}>
          <div className='switchDialogMainDivNew' style={{ textAlign: 'center', background: !mobileView ? colorSchemes.DrawerBackColor : mobileView && isLightMode ? colorSchemes.DrawerBackColor : colorSchemes.MiddlePaneBackground, maxHeight: "90vh" }}>

            <div style={{ width: "100%", display: "flex", alignItems: "center", padding: "20px 20px", background: !mobileView ? "rgba(233, 236, 244, 1)" : mobileView && isLightMode ? "rgba(233, 236, 244, 1)" : "rgba(23, 45, 70, 0.9)" }}>
              <div style={{ width: "70%", textAlign: "left" }}>
                <h4 style={{ color: !mobileView ? '#172D46' : mobileView && isLightMode ? '#172D46' : colorSchemes.FontColor/* colorSchemes.FontBlackColor */, margin: 0, fontSize: "20px", fontFamily: "ManRopeBold" }}>AI</h4></div>
              <div style={{ textAlign: 'end', width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <img style={{ width: 12, cursor: 'pointer' }} onClick={() => { setAIDialog(false); }} src={CloseMenuListIcon} alt='' />
              </div>

            </div>




            <div className="manageAccountContainer" style={{ marginTop: 0 }}>
              {!isSupported &&
                <p>Sorry, your browser does not support speech recognition.</p>
              }


              <div style={{ padding: "20px 20px 0px 20px", display: 'flex', width: "100%", alignItems: "center", justifyContent: "center" }}>

                <div className='mic' onClick={toggleListen} style={{ cursor: 'pointer' }}>
                  <img style={{ width: isLightMode ? 28 : 18, cursor: 'pointer' }} src={Mic} alt="" />
                </div>

              </div>

              <>
                {listening && isListening
                  ? <h6 className="font-sans font-semibold text-2xl animate-pulse duration-700" style={{ textAlign: "center", marginTop: "20px" }}>Listening...
                    {/* <span className='stopButton' onClick={toggleListen}>Stop Listen</span> */}
                  </h6>
                  : <><h4 className="font-sans font-semibold text-2xl" style={{ textAlign: "center", marginTop: "20px" }}>
                  </h4>
                  </>
                }
                {transcription != '' && <div className='queDiv'> <p className="quePara" style={{ textAlign: "center", marginTop: "20px" }}>{transcription}</p>
                </div>}
                {transcript != '' && <div className='queDiv'> <p className="quePara" style={{ textAlign: "center", marginTop: "20px" }}>{transcript}</p>
                </div>}

                {loading &&
                <div style={{ height: 125, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 8 }}>
                  {isLightMode == 1 ?
                    <img className="loading-spinner" style={{ width: 40, height: 40 }} src={AuthLoader} alt="" />
                    :
                    <img className="loading-spinner" style={{ width: 40, height: 40 }} src={AuthLoader} alt="" />
                  }
                  <div style={{ fontSize: 16, fontWeight: 600, color: "#000" }}>Processing your request...</div>
                </div>
              }

{message !='' &&
<div style={{padding: 20, textAlign: 'center'}}>
  {message}
</div>
}


              </>









            </div>


          </div>
        </ClickAwayListener>
      </Dialog>
    </div>
  );
}

export default AIDialogNew;
