import React, { useEffect, useRef, useState } from 'react';
import './InviteLink.css';
import { BrowserRouter, Routes, Route, redirect, Navigate, useNavigate } from "react-router-dom";
import { Base64 } from 'js-base64';
import { BASE_URL, post } from '../Services/Calls';
function NotInterested() {

  const navigate = useNavigate();
    const [codeData, setCodeData]:any = useState({})
    const [queryForCode, setQueryForCode] = useState<string | null>("");
  useEffect(() => {
    let queryItem2 = new URLSearchParams(window.location.search).get("c");
    let queryItem1 = new URLSearchParams(window.location.search).get("e");
    if(queryItem2 && queryItem2!="" && queryItem1 && queryItem1!="")
    {
      
      try
      {
      console.log("code", queryItem2)
      let tempCode:any = queryItem2
      let code:any= Base64.decode(tempCode);
      console.log("code", JSON.parse(code))
      let item=JSON.parse(code);
      

      let emailCode=Base64.decode(queryItem1);
      let emailList=emailCode.split(',');
      let emailString="";
      for(let i=0;i<emailList.length;i++)
      {
        if(i==0)
        {
          emailString="'"+Base64.encode(emailList[i])+"'"
        }
        else
        {
          emailString=emailString+",'"+Base64.encode(emailList[i])+"'"
        }
      }
      console.log("emailString", emailString)
      item.emailString=emailString;
      console.log("code45", item)
      setCodeData(item);
      postShareStatus(item);
     
      //item.toUserEmail=Base64.decode(email);
      //item.toUserName=Base64.decode(user.firstName)+" "+Base64.decode(user.lastName);
      }
      catch(ex)
      {

      }
      
      //I have tried at my end but not able to reproduce this issue Can you please recheck? Are you still reproduce this so please record all steps so I can reproduce this issue at my end and can findByText. 
      
    }
  
  }, []);
  //select fromUserId from share where id in(SELECT id FROM `share` WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=350) or id in(SELECT id FROM `share` WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectGroupId=171);
     
  //select toUserId from share where id in(SELECT id FROM `share` WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=350) or id in(SELECT id FROM `share` WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectGroupId=171)
  //select * from user where id not in(3) and ( id in(select fromUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=350) or id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectGroupId=171)) or id in(select toUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=350) or id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectGroupId=171)));
  //select * from user where id not in(1) and ( id in(select fromUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=752) or id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectGroupId=171)) or id in(select toUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=752) or id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectGroupId=171)));

  //select * from user where id not in(3) and ( id in(select fromUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=350)) or id in(select toUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=3) and projectId=350))); 
  
  
//select * from user where id not in(50) and ( id in(select fromUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=50) and projectId=752) or id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=50) and projectGroupId=171)) or id in(select toUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=50) and projectId=752) or id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=50) and projectGroupId=171)));

//select * from user where id not in(50) and ( id in(select fromUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=50) and projectId=350)) or id in(select toUserId from share where id in(SELECT id FROM share WHERE isAccept=0 and status=0 and (fromUserId=1 or fromUserId=50) and projectId=350)));
 
//(taskId, projectId, projectGroupId, userId, type, subType, taskName, userName)
const postShareStatus = async (data:any) => {

    try {
      const response = await post(BASE_URL + '/updatenotinterested', data)
      console.log('response23', response)      
    } catch (ex) {
      console.log('response9211', ex)
    }
  }
  return (
  <div className="inviteMainContainer">
<div className="inviteMainInnerContainer">
{/* <div style={{marginBottom: '0.5rem',color: 'rgba(25,25,25,.6)',textAlign:'center'}}>{codeData && codeData.fromUserEmail?codeData.fromUserEmail:""} invites you to join this shared 
{codeData && codeData.type && codeData.type=="project"?"list":"project"}</div> */} 
<div style={{textAlign:'center',fontSize:"1.5em",color: "#4772fa",lineHeight: '2.5rem',fontWeight:600}}>
{codeData && codeData.type ?"Thanks for your feedback, We have updated your feedback in our system.":"Link Expired."}
</div>
{/* <div className="joinBtn" onClick={() => { 
    let queryForCodeTemp:any=queryForCode;
    localStorage.setItem("codeData", queryForCodeTemp);
    if(!localStorage.getItem("token"))
    {
        navigate('/Login')
    }
    else if(localStorage.getItem("token"))
    {
        navigate('/webapp')
        window.location.reload();
    }
}}>
    Join
</div> */}
</div>
  </div>
  );
}

export default NotInterested;
