import React, { useEffect, useRef, useState } from 'react';
import './InviteLink.css';
import { BrowserRouter, Routes, Route, redirect, Navigate, useNavigate } from "react-router-dom";
import InviteAvatar from '../../assets/inviteAvatar.png'
import { Base64 } from 'js-base64';
import { COLOR, COLORDARK } from '../Services/constants/Colors';
function InviteLink() {

  const navigate = useNavigate();
    const [shareCodeData, setShareCodeData]:any = useState({})
    const [queryForShareCode, setQueryForShareCode] = useState<string | null>("");

    const [mobileView, setMobileView]: any = useState<boolean>(
      window.innerWidth <= 768
    );
    const [isDark, setIsDark]: any = useState(mobileView ? 1 : 0);
  const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768)
        setMobileView(true);
      else
        setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  useEffect(() => {
    let queryItem2 = new URLSearchParams(window.location.search).get("c");
    if(queryItem2 && queryItem2!="")
    {
      
      try
      {
      console.log("sharecode", queryItem2)
      let tempShareCode:any = queryItem2
      setQueryForShareCode(queryItem2)
      let code:any= Base64.decode(tempShareCode);
      console.log("sharecode", JSON.parse(code))
      let item=JSON.parse(code);
      //item.toUserEmail=Base64.decode(email);
      //item.toUserName=Base64.decode(user.firstName)+" "+Base64.decode(user.lastName);
      console.log("sharecode", item)

      setShareCodeData(item);
      }
      catch(ex)
      {

      }
      
      
      
    }
  
  }, []);


  return (
    <div style={{background:colorSchemes.MainBackGroundColor,height:'100vh'}}>
  <div className="inviteMainContainer">
{shareCodeData && shareCodeData.fromUserEmail &&
<div className="inviteMainInnerContainer" style={{background:colorSchemes.MainBackGroundColor,color:colorSchemes.FontBlackColor}}>
    <img className="inviteAvatar" src={InviteAvatar} alt="" />
<div style={{marginBottom: '0.5rem',color:colorSchemes.FontSemiGrey,textAlign:'center'}}>{shareCodeData && shareCodeData.fromUserEmail?shareCodeData.fromUserEmail:""} invites you to join this shared 
{shareCodeData && shareCodeData.type && shareCodeData.type=="project"?"list":"project"}</div> 
<div style={{textAlign:'center',fontSize:"1.75em",color: "#4772fa",lineHeight: '2.5rem',fontWeight:600}}>
{shareCodeData && shareCodeData.pname ?shareCodeData.pname:""}
</div>
<div className="joinBtn" onClick={() => { 
    let queryForShareCodeTemp:any=queryForShareCode;
    localStorage.setItem("sharecode", queryForShareCodeTemp);
    if(!localStorage.getItem("token"))
    {
        navigate('/Login')
    }
    else if(localStorage.getItem("token"))
    {
        navigate('/webapp')
        window.location.reload();
    }
}}>
    Join
</div>
</div>}
  </div>
  </div>
  );
}

export default InviteLink;
