import React, { useEffect, useRef, useState } from 'react';
import './HelpSupport.css'
import styles from '../Auth/AuthStyles'
import { BrowserRouter, Routes, Route, redirect, Navigate, useNavigate } from "react-router-dom";
import FaqAddicon from '../../assets/faqAddicon.png'
import FaqCrossicon from '../../assets/faqCrossicon.png'
import FaqContacticon from '../../assets/faqContacticon.png'
import FaqMailIcon from '../../assets/faqMailIcon.png'
import FbIcon from '../../assets/fbIcon.png'
import TwitIcon from '../../assets/twitIcon.png'
import InstaIcon from '../../assets/instaicon.png'
import LinkedInIcon from '../../assets/linkedInIcon.png'
import Rightpaneback from '../../assets/rightpaneback.png'
import { BASE_URL, post } from '../Services/Calls';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide, { SlideProps } from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';



function HelpSupport() {
    const navigate = useNavigate();
    const [faqItemOpen1, setFaqItemOpen1] = useState(false)
    const [faqItemOpen2, setFaqItemOpen2] = useState(false)
    const [faqItemOpen3, setFaqItemOpen3] = useState(false)
    const [faqItemOpen4, setFaqItemOpen4] = useState(false)
    const [loader, setLoader] = useState(false);

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [msg, setMsg] = useState("")
    const [nameErrorMsg, setNameErrorMsg] = useState("")
    const [emailErrorMsg, setEmailErrorMsg] = useState("")
    const [multiSnackbarMsg, setMultiSnackbarMsg]: any = useState('')

    const [mobileView, setMobileView]: any = useState<boolean>(
        window.innerWidth <= 768
    );

    useEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 768)
                setMobileView(true);
            else
                setMobileView(false);
        }
        window.addEventListener("resize", updateSize);
    }, [mobileView]);

    useEffect(() => {

        window.scroll(0, 0);

    }, []);


    async function onSupprotSubmit() {

        console.log('name', name);
        console.log('email', email);
        console.log('msg', msg);
        try {
            if (name == "") {
                setNameErrorMsg("Name can't be empty");
                return;
            }
            if (email == "") {
                setEmailErrorMsg("Email address can't be empty");
                return;
            }
            if (email != "") {
                let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!pattern.test(email)) {
                    setEmailErrorMsg("Invalid email format");
                    return;
                }
            }
            const data = {
                name: name,
                email: email,
                message: msg,
            };
            console.log("data123456", data);
            setLoader(true)
            const response = await post(BASE_URL + '/support', data)
            console.log('response23', response)
            if (response.data.status == 'success') {
                setMultiSnackbarMsg('Thank You For Contacting Us !')
                handleClickMultiSnackBar(SlideTransition)
                setEmail("")
                setName("")
                setMsg("")
                setLoader(false)
                const responseSendSupportEmail = await post(BASE_URL + '/sendsupportemail', data)
                console.log('responseSendSupportEmail', responseSendSupportEmail)
            }
            else {
                setLoader(false)
            }

        } catch (ex) {
            console.log('response', ex)
            setLoader(false)
        }




    }


    function SlideTransition(props: SlideProps) {
        return <Slide {...props} direction="up" />;
      }
    
    
      function handleClickMultiSnackBar(Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>,) {
        setOpenMultiSnackBar({
          open: true,
          Transition,
        });
      };
    
      const [openMultiSnackBar, setOpenMultiSnackBar] = React.useState<{ open: boolean; Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>; }>({
        open: false,
        Transition: Fade,
      });
    
      function handleCloseMultiSnackBar() {
        setOpenMultiSnackBar({ ...openMultiSnackBar, open: false, });
      };



    return (
        <div className="helpSupportMainContainerDiv" style={{ background: '#fff' }}>

<Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openMultiSnackBar.open}
        onClose={handleCloseMultiSnackBar}
        TransitionComponent={openMultiSnackBar.Transition}
        message={multiSnackbarMsg}
        key={openMultiSnackBar.Transition.name}
        autoHideDuration={3000}
        className='InviteSnackBar1'
      />

            {mobileView &&
                <div className='middlePaneTopDiv'>
                    {/* <div style={{width:40,display:'flex',justifyContent:'center',alignItems:'center'}} onClick={() => { middlePaneMobileBack() }}>
                            <img style={{width:24}} src={MenuMiddlePane} />
                            </div> */}
                    <div style={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { navigate('/webapp') }}>
                        <img style={{ width: 24 }} src={Rightpaneback} />
                    </div>
                    <div style={{ fontSize: 20, fontWeight: 700, fontFamily: 'ManRopeBold', color: '#fff' }}>
                        Help
                    </div>
                    <div style={{ width: 24 }}>
                        {/* <img style={{ width: 32 }} src={AvatarMiddlePane} /> */}
                    </div>
                </div>
            }
            <div className="helpSupportMainDiv">
                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: mobileView ? 20 : 24, fontFamily: 'ManRopeBold', marginBottom: 5, color: '#172D46' }}>
                    Help & Support
                </div>
                <div style={{ textAlign: 'center', fontWeight: 400, fontSize: mobileView ? 14 : 20, fontFamily: 'ManRope', color: '#172D46' }}>
                    Frequently Asked Questions
                </div>


                <div style={{ marginTop: 24, marginBottom: 24 }}>

                    <div className='faqItemDiv' onClick={() => { setFaqItemOpen1(!faqItemOpen1) }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: mobileView ? 22 : 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                    01
                                </div>
                                <div style={{ fontWeight: 700, fontSize: mobileView ? 16 : 28, fontFamily: 'ManRopeBold', color: '#172D46', width: '100%' }}>
                                    Alright, but what exactly do you do?
                                </div>
                            </div>
                            {faqItemOpen1 ?
                                <div className='faqCrossIconDiv'>
                                    <img src={FaqCrossicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                                :
                                <div className='faqAddIconDiv'>
                                    <img src={FaqAddicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                            }
                        </div>
                        {faqItemOpen1 &&
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                </div>
                                <div style={{ fontWeight: 400, fontSize: mobileView ? 14 : 18, fontFamily: 'ManRope', color: '#828B94', width: '100%' }}>
                                    Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                </div>
                            </div>
                        }
                    </div>

                    <div className='faqItemDiv' onClick={() => { setFaqItemOpen2(!faqItemOpen2) }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: mobileView ? 22 : 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                    02
                                </div>
                                <div style={{ fontWeight: 700, fontSize: mobileView ? 16 : 28, fontFamily: 'ManRopeBold', color: '#172D46', width: '100%' }}>
                                    I don't need a brand strategist but I need help executing an upcoming campaign. Can we still work together?
                                </div>
                            </div>
                            {faqItemOpen2 ?
                                <div className='faqCrossIconDiv'>
                                    <img src={FaqCrossicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                                :
                                <div className='faqAddIconDiv'>
                                    <img src={FaqAddicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                            }
                        </div>
                        {faqItemOpen2 &&
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: mobileView ? 22 : 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                </div>
                                <div style={{ fontWeight: 400, fontSize: mobileView ? 14 : 18, fontFamily: 'ManRope', color: '#828B94', width: '100%' }}>
                                    Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                </div>
                            </div>
                        }
                    </div>

                    <div className='faqItemDiv' onClick={() => { setFaqItemOpen3(!faqItemOpen3) }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: mobileView ? 22 : 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                    03
                                </div>
                                <div style={{ fontWeight: 700, fontSize: mobileView ? 16 : 28, fontFamily: 'ManRopeBold', color: '#172D46', width: '100%' }}>
                                    Are your rates competitive?
                                </div>
                            </div>
                            {faqItemOpen3 ?
                                <div className='faqCrossIconDiv'>
                                    <img src={FaqCrossicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                                :
                                <div className='faqAddIconDiv'>
                                    <img src={FaqAddicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                            }
                        </div>
                        {faqItemOpen3 &&
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                </div>
                                <div style={{ fontWeight: 400, fontSize: mobileView ? 14 : 18, fontFamily: 'ManRope', color: '#828B94', width: '100%' }}>
                                    Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                </div>
                            </div>
                        }
                    </div>

                    <div className='faqItemDiv' onClick={() => { setFaqItemOpen4(!faqItemOpen4) }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: mobileView ? 22 : 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                    04
                                </div>
                                <div style={{ fontWeight: 700, fontSize: mobileView ? 16 : 28, fontFamily: 'ManRopeBold', color: '#172D46', width: '100%' }}>
                                    Why do you have a monthly project cap?
                                </div>
                            </div>
                            {faqItemOpen4 ?
                                <div className='faqCrossIconDiv'>
                                    <img src={FaqCrossicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                                :
                                <div className='faqAddIconDiv'>
                                    <img src={FaqAddicon} style={{ width: 15, height: 15 }} alt="" />
                                </div>
                            }
                        </div>
                        {faqItemOpen4 &&
                            <div className='faqItemInnerDiv'>
                                <div style={{ textAlign: 'center', fontWeight: 700, fontSize: 32, fontFamily: 'ManRopeBold', color: '#828B94', width: 50 }}>
                                </div>
                                <div style={{ fontWeight: 400, fontSize: mobileView ? 14 : 18, fontFamily: 'ManRope', color: '#828B94', width: '100%' }}>
                                    Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.
                                </div>
                            </div>
                        }
                    </div>


                </div>


            </div>
            <div className="confussionMainDiv">
                <div className="confussionBgImgDiv">
                    <div>

                        <div style={{ fontSize: mobileView ? 24 : 32, fontWeight: 700, fontFamily: 'ManRopeBold', marginTop: mobileView ? 0 : 24, marginBottom: mobileView ? 10 : 24, color: '#fff' }}>
                            Have a confusion? <br />
                            Let’s discuss
                        </div>
                        <div style={{ fontSize: mobileView ? 14 : 20, fontWeight: 400, fontFamily: 'ManRope', marginTop: mobileView ? 10 : 24, marginBottom: 24, color: '#fff' }}>
                            Thank you for getting in touch! <br />
                            Fill the form, have a great day!
                        </div>
                        <div className="supportInfoDiv">
                            <img src={FaqContacticon} style={{ width: 48 }} alt="" />
                            <div style={{ color: '#fff' }}>
                                <p>Customer Support: info@mail.com </p>
                                <p>Technical Support:support@mail.com</p>
                            </div>
                        </div>
                        <div className="supportInfoDiv">
                            <img src={FaqMailIcon} style={{ width: 48 }} alt="" />
                            <div style={{ color: '#fff' }}>
                                <p>Main Office: +880 123 456 789</p>
                                <p>Customer Supprort: +880 123 456 789</p>
                            </div>
                        </div>
                        <div className='socialIconDiv' style={{ marginTop: 40 }}>
                            <img src={FbIcon} style={{ width: 40 }} alt="" />
                            <img src={TwitIcon} style={{ width: 40 }} alt="" />
                            <img src={InstaIcon} style={{ width: 40 }} alt="" />
                            <img src={LinkedInIcon} style={{ width: 40 }} alt="" />
                        </div>
                    </div>
                    <div className="sendSupportMsgDiv">

                        <div className="sendMsghead">Send us a message!</div>

                        <input style={{ marginTop: 40 }} className='supportSendMsgInput'
                            type='text'
                            name='name'
                            placeholder='Name'
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            onFocus={(e) => { setNameErrorMsg("") }}
                        />
                        <div className='errorMsgLine' style={{ minHeight: 30 }}><span style={styles.inputErrorMsg}>{nameErrorMsg}</span></div>
                        <input className='supportSendMsgInput'
                            type='email'
                            name='email'
                            placeholder='Email'
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            onFocus={(e) => { setEmailErrorMsg("") }}
                        />
                        <div className='errorMsgLine' style={{ minHeight: 30 }}><span style={styles.inputErrorMsg}>{emailErrorMsg}</span></div>
                        <input className='supportSendMsgInput'
                            type='text'
                            name='message'
                            placeholder='Message (optional)'
                            value={msg}
                            onChange={(e) => { setMsg(e.target.value) }}
                        />

                        {loader ?
                            <div className="supportSendBtn authBtnLoader" style={{opacity:0.7}} /* onClick={()=>{onSupprotSubmit()}} */>
                                Send...
                            </div>
                            :
                            <div className="supportSendBtn authBtn" onClick={() => { onSupprotSubmit() }}>
                                Send
                            </div>
                        }

                    </div>
                </div>
            </div>

        </div>
    );
}

export default HelpSupport;

